import React, { Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

const Pergunta = ({
	numero,
	superLabel,
	label,
	observacoes,
	required,
	name,
	value,
	valueAlteradaRevisor,
	valueAlteradaRt,
	options,
	readOnly,
	disabled: disabledProps,
	onChangeHandler,
	radioStack,
	hasExternalLink,
	externalLink,
	externalLinkText,
	warning,
	errors
}) => {
	/* REDUX */

	const onChangeRadio = e => {
		const { name, value: codigo } = e.target;
		onChangeHandler(codigo, name);
	};
	const resposta = options.reduce((acc, o) => {
		const codigo = typeof o === 'object' ? o.codigo : o;
		const descricao = typeof o === 'object' ? o.descricao : o;
		return value === codigo ? descricao : acc;
	}, null);

	const getDescricao = useCallback((options, valor) => options.find(o => o.codigo === valor)?.descricao || valor, []);

	return readOnly & !resposta ? null : (
		<>
			<div className={`${readOnly ? 'row container-resposta-ap' : ''}`}>
				<div>
					<span className="cs-numero">{numero}</span>
					{superLabel && <div className="cs-super-label">{superLabel}</div>}
					<div className="cs-label">
						{label}
						{required && <span className="required">*</span>}
						{observacoes && <div className="cs-observacoes">{observacoes}</div>}
					</div>
				</div>
				{readOnly && <>{readOnly && <span className="resposta-ap">{resposta || 'não respondido'}</span>}</>}
			</div>
			{!readOnly && (
				<div className="row linha-opcoes-resposta">
					<div className="form-group col-md-12">
						{options.map(o => {
							const codigo = typeof o === 'object' ? o.codigo : o;
							const descricao = typeof o === 'object' ? o.descricao : o;
							const descricaoComplementar = typeof o === 'object' ? o.descricaoComplementar : o;
							const observacao = o.observacao;
							const disabled = typeof o === 'object' ? o.disabled || false : false;
							const defaultValue = typeof o === 'object' ? o.defaultValue || false : false;
							if (defaultValue && !value) {
								onChangeRadio({ target: { name, value: codigo } });
							}
							return (
								<Fragment key={codigo}>
									<div
										className={`form-check ${radioStack ? '' : 'form-check-inline'}`}
										key={codigo}
										style={radioStack ? { marginBottom: '7px' } : {}}
									>
										<input
											type="radio"
											value={codigo}
											id={`radio-${name}-${codigo}`}
											name={name}
											onClick={onChangeRadio}
											readOnly={readOnly}
											disabled={disabled || disabledProps}
											onChange={() => false}
											checked={value === codigo}
											className="form-check-input"
										/>
										<label htmlFor={`radio-${name}-${codigo}`} className="form-check-label">
											<span>{descricao}</span>
											{descricaoComplementar && <div style={{ fontSize: 'small' }}>{descricaoComplementar}</div>}
										</label>
									</div>
									{observacao && value === codigo && (
										<div style={{ marginTop: '5px' }}>
											Observação:{' '}
											<span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red' }}>{observacao}</span>
										</div>
									)}
								</Fragment>
							);
						})}
						{valueAlteradaRevisor && (
							<div className="cs-pergunta-warning">
								Valor alterado pelo revisor de {getDescricao(options, valueAlteradaRevisor.de)} para{' '}
								{getDescricao(options, valueAlteradaRevisor.para)}
							</div>
						)}
						{valueAlteradaRt && (
							<div className="cs-pergunta-warning">
								Valor alterado pelo RT de {getDescricao(options, valueAlteradaRt.de)} para{' '}
								{getDescricao(options, valueAlteradaRt.para)}
							</div>
						)}
						{size(errors) > 0 && <ErrorMessages errorList={get(errors, name, false)} />}
						{hasExternalLink && externalLink && (
							<>
								<a href={externalLink} target="_blank" rel="noopener noreferrer">
									{externalLinkText}
									<i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
								</a>
							</>
						)}
						{size(warning) > 0 && (
							<div className="cs-pergunta-warning">
								<i className="fa fa-exclamation-triangle" />
								<span>{warning}</span>
							</div>
						)}
					</div>
				</div>
			)}{' '}
		</>
	);
};
Pergunta.displayName = 'Pergunta';

Pergunta.propTypes = {
	numero: PropTypes.string,
	superLabel: PropTypes.string,
	label: PropTypes.string,
	observacoes: PropTypes.string,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.string,
	valueAlteradaRevisor: PropTypes.object,
	valueAlteradaRt: PropTypes.object,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	radioStack: PropTypes.bool,
	hasExternalLink: PropTypes.bool,
	externalLink: PropTypes.string,
	externalLinkText: PropTypes.string,
	warning: PropTypes.string,
	errors: PropTypes.any
};
export default Pergunta;
