import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import actions from 'containers/Form/actions';

import { isDebug } from 'utils/tools';

let i = 0;

// eslint-disable-next-line no-unused-vars
const debugLog = (...parms) => isDebug && console.debug('(trk-useSaveImutableState):', ++i, ...parms);
/**
 * Como useState, mas armazena dados na store. Exige que o componente que usa este hook use o Redux.
 * ex:
 * const withReducer = injectReducer({ key: 'licenciamento', reducer });
 * const withSaga = injectSaga({ key: 'licenciamento', saga });
 * export default compose(withSaga, withReducer)(Tracker);

 * @param {String} property key para salvar na store
 * @param {Array<String>} path Array com path para store. Default para []
 * @param {String} idProperty usada pelo useMutableState para diferenciar cada objeto. Default para 'id'
 * @returns {any, function} [estado, setEstado]
 */
const useSaveImutableState = (property, value, path = ['licenciamnento']) => {
	/* REDUX HOOKS */
	const dispatch = useDispatch();

	const save = useCallback(
		novoValor => {
			const pathSet = [...path.slice(1), property];
			dispatch(actions.setPersistentState({ path: pathSet, value: novoValor }));
		},
		[dispatch, path, property]
	);

	useEffect(() => {
		if (value) {
			save(value);
		}
	}, [save, value]);

	return save;
};

export default useSaveImutableState;
