import { get, unset } from 'lodash';

import { maiorQue } from 'utils/tools';

import { includesTipoForm } from './InfosProjetoAP';
import { UAP_ENQUADRAMENTOS_ENUM } from './TabelaAreasAP/constantsAP';

const {
	MULTIFAMILIAR,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	UNIFAMILIAR_AUTONOMA,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO,
	HABITACAO_COLETIVA
} = UAP_ENQUADRAMENTOS_ENUM;

/**
 * Limpa campos ocultos/não utilizados.
 *
 * @param {object} data
 * @param {string} tipoForm
 *
 * @returns {object} Dados corrigidos
 */
export function limparForm(data, tipoForm) {
	let formdados = { ...data };
	const restricoesData = get(data, 'restricoesAdministrativas', data);

	const formMultifamiliar = includesTipoForm([MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const formUnifamiliarAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm);
	const formUnifamiliarUmaOuDuasEconomias = [UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO, HABITACAO_COLETIVA].includes(
		tipoForm
	);

	const delEdificacaoUltrapassaAlturaDECEA = formdados.dmiRestricaoAlturaProximidadeAeroporto === 'nao';
	const delRestricaoAreaNaoEdificavel = formdados.dmiRestricaoPluvial === 'nao';
	const delPrevistoConstrucaoAreaNaoEdificavel = formdados.restricaoAreaNaoEdificavel === 'nao';
	const delQuotaALP = get(restricoesData, 'restricaoALPdoCondominio') === 'nao';
	const delPotencialConstrutivoImovelInventariado = !['imoveltombado', 'imovelinventariadodeestruturacao'].includes(
		get(restricoesData, 'predioTombadoInventariado')
	);
	const delRestricaoTracadoViarioPDDUA = formdados.restricaoTracadoViarioPDDUA === 'nao';
	const delDmiRestricaoAPP = formdados.dmiRestricaoAPP === 'nao';
	const delDmiRestricaoAreaVerde = formdados.dmiRestricaoAreaVerde === 'nao';
	const delDmiRestricaoAreaEscolar = formdados.dmiRestricaoAreaEscolar === 'nao';

	// restrições em comum
	if (delEdificacaoUltrapassaAlturaDECEA) {
		delete formdados.edificacaoUltrapassaAlturaDECEA;
	}
	if (delRestricaoAreaNaoEdificavel) {
		delete formdados.restricaoAreaNaoEdificavel;
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
	}
	if (delPrevistoConstrucaoAreaNaoEdificavel) {
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
		delete formdados.previstoMuroEmAreaNaoEdificavel;
	}
	if (delRestricaoTracadoViarioPDDUA) {
		delete formdados.edificacaoIncideTracadoViarioPDDUA;
		delete formdados.possuiEstudoPDDUA;
	}
	if (delDmiRestricaoAPP) {
		delete formdados.construcaoSobreAppApan;
	}
	if (delDmiRestricaoAreaVerde) {
		delete formdados.restricaoEdificacaoAreaVerde;
	}
	if (delDmiRestricaoAreaEscolar) {
		delete formdados.projetoIncideSobreEscola;
	}

	// restrições específicas conforme tipo do form
	formdados = limparInfosTerreno(formdados, tipoForm);
	if (formUnifamiliarAutonoma) {
		if (delQuotaALP) {
			delete formdados.quotaALP;
		}
	} else if (formUnifamiliarUmaOuDuasEconomias) {
		formdados = limpaFormUnifamiliarUmaOuDuasEconomias(formdados);
		formdados = limparInfosProjeto(formdados);
	} else if (formMultifamiliar) {
		const totalQtdVagasCondominiais = get(formdados, 'infosProjeto.totalQtdVagasCondominiais.value');
		if (maiorQue(400, totalQtdVagasCondominiais)) {
			unset(formdados, 'infosProjeto.projetoPossuiEvu2GrauValido');
		}
	}
	get(formdados, 'planoDiretor.dadosSubunidades', []).forEach(subunidade => {
		if (subunidade.possuiAlturaDivisa === 'nao' && subunidade.alturaDivisaRegimeUrbanisticoAnexo) {
			delete subunidade.alturaDivisaRegimeUrbanisticoAnexo;
		}
		if (subunidade.possuiAlturaBase === 'nao' && subunidade.alturaBaseRegimeUrbanisticoAnexo) {
			delete subunidade.alturaBaseRegimeUrbanisticoAnexo;
		}
	});
	if (formdados.infosProjeto?.projetoImpactoUrbano === 'nao' && formdados.infosProjeto?.projetoViabilidaUrbanistico) {
		delete formdados.infosProjeto.projetoViabilidaUrbanistico;
	}
	if (formdados.infosProjeto?.atividadeSaude === 'nao' && formdados.infosProjeto?.atividadeSaudePossuiVagaAmbulancia) {
		delete formdados.infosProjeto.atividadeSaudePossuiVagaAmbulancia;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalBaseProjeto) {
		delete formdados.infosProjeto.totalBaseProjeto;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalCorpoProjeto) {
		delete formdados.infosProjeto.totalCorpoProjeto;
	}
	if (formdados.infosProjeto?.possuiVagaCargaDescarga === 'nao' && formdados.infosProjeto?.qtdVagasCargaDescarga) {
		delete formdados.infosProjeto.qtdVagasCargaDescarga;
	}
	if (delPotencialConstrutivoImovelInventariado) {
		delete formdados?.infosProjeto?.planilhaIndicesConstrutivos?.potencialConstrutivoImovelInventariado;
	}

	return formdados;
}

const limparInfosTerreno = (data, tipoForm) => {
	let formdados = data;

	const possuiAreaDeMenorPoligono = formdados?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = formdados?.possuiAreaAtingida === 'sim';
	const possuiAreaMatricula3000 = formdados?.areaMatricula3000 === 'sim';
	const possuiAreaAtingidaDeMenorPoligono = formdados?.possuiAreaAtingidaDeMenorPoligono === 'sim';

	if (!possuiAreaDeMenorPoligono) {
		delete formdados.areaMenorPoligonoLote;
		delete formdados.areaMenorPoligonoLoteMais5;
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}
	if (!possuiAreaAtingida) {
		delete formdados.areaAtingidaMatricula;
		delete formdados.areaRemanescenteMatricula;
	}

	if (!possuiAreaAtingida || !possuiAreaDeMenorPoligono || !possuiAreaAtingidaDeMenorPoligono) {
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}

	if (!includesTipoForm([MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL, HABITACAO_COLETIVA], tipoForm)) {
		delete formdados.areaMatricula3000;
		delete formdados.oriundoParcelamentoSolo;
	}

	if (!possuiAreaMatricula3000) {
		delete formdados.oriundoParcelamentoSolo;
	}

	return formdados;
};

const limpaFormUnifamiliarUmaOuDuasEconomias = data => {
	let formdados = data;

	const delEdificacaoIncideTracadoViarioPDDUA = formdados?.restricaoTracadoViarioPDDUA === 'nao';
	const delPossuiEstudoPDDUA = formdados?.edificacaoIncideTracadoViarioPDDUA === 'nao';
	const delRestricaoEdificacaoAreaVerde = formdados?.dmiRestricaoAreaVerde === 'nao';
	const delProjetoIncideSobreEscola = formdados?.dmiRestricaoAreaEscolar === 'nao';

	if (delPossuiEstudoPDDUA) {
		delete formdados.possuiEstudoPDDUA;
	}
	if (delEdificacaoIncideTracadoViarioPDDUA) {
		delete formdados.edificacaoIncideTracadoViarioPDDUA;
		delete formdados.possuiEstudoPDDUA;
	}
	if (delRestricaoEdificacaoAreaVerde) {
		delete formdados.restricaoEdificacaoAreaVerde;
	}
	if (delProjetoIncideSobreEscola) {
		delete formdados.projetoIncideSobreEscola;
	}

	return formdados;
};

const limparInfosProjeto = data => {
	let formdados = data;
	const isProjetoPossuiBaseCorpo =
		formdados.projetoPossuiBaseCorpo === 'sim' ? true : formdados.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete formdados.taxaOcupacaoTotal;
		delete formdados.taxaOcupacaoTotalPermitido;
	} else if (isProjetoPossuiBaseCorpo === false) {
		delete formdados.totalBaseProjeto;
		delete formdados.totalPermitidoBase;
		delete formdados.totalCorpoProjeto;
		delete formdados.totalPermitidoCorpo;
	}
	return formdados;
};
