import { get, isNil, size, forEach, isEmpty } from 'lodash';

import { ATIVIDADES_EVU } from 'containers/PlanoDiretor/PlanoDiretorConstantes';
import { MSG_ERROR_LEI_ESPECIFICA } from 'containers/PlanoDiretor/PlanoDiretorRegimeUrbanistico';

import { maiorQue, multiplica, soma, trocaPontoPorVirgula } from 'utils/tools';

import { INFOSPROJETO_CONTROLNAME } from './InfosProjetoLE';
import { METAFORMLE, LE_Enquadramentos, ITEM_HABITACAO_COLETIVA } from './MetadataLE';
import { TIPOS_METRAGENS_ENUM } from './TabelaAreasLE';

let vetorObrigatorios = [];
let camposOcultos = [];
let isQuartoDistrito = false;

/**
 * Valida o form conforme regras de negócio.
 *
 * @param {object} data
 * @param {string} tipoForm
 * @returns {formvalidado}
 *
 * @typedef formValidado
 * @property {string[]} validacao Array de erros
 * @property {boolean} isBloqueante Determina se algum dos erros bloqueia o envio do form
 */
export function validarForm(data, tipoForm, formulario) {
	const {
		UNIFAMILIAR_AUTONOMA,
		UNIFAMILIAR_01_ECONO,
		UNIFAMILIAR_02_ECONO,
		UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
		NAO_RESIDENCIAL
	} = LE_Enquadramentos;

	const isUnifamiliarAutonoma = tipoForm === UNIFAMILIAR_AUTONOMA;
	const isUnifamiliarUmaOuDuasEconomias = [
		LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
		LE_Enquadramentos.UNIFAMILIAR_02_ECONO
	].includes(tipoForm);
	const isUnifamiliarUmaEconomia = tipoForm === UNIFAMILIAR_01_ECONO;
	const isNaoResidenciais = [NAO_RESIDENCIAL, UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES].includes(tipoForm);
	const isNaoResidencial = NAO_RESIDENCIAL === tipoForm;
	const isUnifamiliarDuasEconomias = tipoForm === UNIFAMILIAR_02_ECONO;
	const isUnifamiliarAteDuasEconomiasComNR = tipoForm === UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES;

	isQuartoDistrito = get(formulario, 'formData.data.enderecoCdlList', []).some(cdl => cdl.regiao === 'quarto_distrito');

	let formdados = { ...data };
	let validacao = [];
	let isBloqueante = false;

	let errosObrigatorios = [];
	vetorObrigatorios = [];

	// 4 distrito tem menos validacoes
	errosObrigatorios = validaCamposObrigatorios(formdados, tipoForm, errosObrigatorios, validacao);
	if (size(errosObrigatorios)) {
		isBloqueante = true;
		adicionaErros(validacao, errosObrigatorios);
	}

	if (isQuartoDistrito) {
		const terrenoValidado = validarInfosTerreno(formdados);
		adicionaErros(validacao, terrenoValidado.erros);
		isBloqueante = isBloqueante || terrenoValidado.isBloqueante;
		return { validacao, isBloqueante };
	}

	const { erros: errosSubunidaes, isBloqueante: bloqSubunidades } = validarSubUnidades(formdados, tipoForm);
	adicionaErros(validacao, errosSubunidaes);
	isBloqueante = isBloqueante || bloqSubunidades;

	if (!isUnifamiliarAutonoma) {
		const validInfosProjeto = validaInfosProjeto(formdados, tipoForm);
		adicionaErros(validacao, validInfosProjeto.erros);
		isBloqueante = isBloqueante || validInfosProjeto.isBloqueante;

		const isHabitacaoColetiva = get(formdados, 'infosProjeto.projetoAtividade.item') === ITEM_HABITACAO_COLETIVA;
		if ((isUnifamiliarUmaEconomia && isHabitacaoColetiva) || isNaoResidenciais) {
			// (IA)
			const limite = get(formdados, 'infosProjeto.indiceAproveitamentoTotalPermitido.value', '0');
			// (IA) total permitido * 50%
			const limite50 = multiplica(limite, '0.5');
			const totAd = get(formdados, `detalhesAreas.totais.${TIPOS_METRAGENS_ENUM.adensavel}.total`, '0');
			const totNAd = get(formdados, `detalhesAreas.totais.${TIPOS_METRAGENS_ENUM.naoAdensavel50}.total`, '0');

			if (maiorQue(totAd, limite)) {
				adicionaErros(validacao, [
					{
						campo: 'totalAdensavel',
						message: 'A Área total Ad (m²) não pode ser superior ao Índice de Aproveitamento (IA) total permitido (m²)'
					}
				]);
				isBloqueante = true;
			}
			if (maiorQue(totNAd, limite50)) {
				adicionaErros(validacao, [
					{
						campo: 'totalNaoAdensavel',
						message:
							'A Área total Nad (m²) não pode ser superior ao Índice de Aproveitamento (IA) total permitido (m²) * 50%'
					}
				]);
				isBloqueante = true;
			}
		}
	}

	if (isUnifamiliarAutonoma) {
		const validInfos = validaInfosDispositivosControle(formdados, tipoForm);
		adicionaErros(validacao, validInfos);
		isBloqueante = isBloqueante || validInfos.length > 0;

		if (maiorQue(formdados?.infosProjeto?.taxaOcupacaoProjeto?.value, formdados?.quotaTaxaOcupacao?.value)) {
			isBloqueante = true;
			adicionaErros(validacao, [
				{
					campo: 'taxaOcupacaoProjeto',
					message:
						'A Taxa de Ocupação do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação - TO (m²) conforme a aprovação do condomínio.'
				}
			]);
		}
		if (maiorQue(formdados?.detalhesAreas?.totais?.mtAdensavel.total, formdados?.quotaIndiceAproveitamento?.value)) {
			isBloqueante = true;
			adicionaErros(validacao, [
				{
					campo: 'totalAdensavel',
					message:
						'O Índice de Aproveitamento - IA do projeto deve ser menor ou igual ao Índice de Aproveitamento - IA* para a Unidade Autônoma conforme a aprovação do condomínio'
				}
			]);
		}

		if (isNil(get(formdados, 'infosProjeto.taxaOcupacaoProjeto.value'))) {
			adicionaErros(validacao, [
				{ campo: 'taxaOcupacaoProjeto', message: 'Taxa de Ocupação do Projeto (m²) deve ser informado.' }
			]);
		}

		if (size(get(formdados, 'infosProjeto.tipoConstrucao')) < 1) {
			adicionaErros(validacao, [{ campo: 'tipoConstrucao', message: 'Tipo de material deve ser informado.' }]);
		}
	}

	if (isUnifamiliarUmaOuDuasEconomias) {
		const terrenoValidado = validarInfosTerreno(formdados);
		adicionaErros(validacao, terrenoValidado.erros);
		isBloqueante = isBloqueante || terrenoValidado.isBloqueante;
	}

	// validar tabela areas/planilha de areas
	// planilha de áreas not null
	if (!formdados?.detalhesAreas?.totais?.total || formdados?.detalhesAreas?.totais?.total === '0.00') {
		isBloqueante = true;
		adicionaErros(validacao, [
			{ campo: 'planilhaAreas', message: 'É necessário preencher ao menos um valor na planilha de áreas.' }
		]);
	}

	const projeto = get(formdados, [INFOSPROJETO_CONTROLNAME, 'projetoNovoOuAumento']);
	const isProjetoNovo = projeto === 'novo';
	let valPermanecer = 0;
	let totalDemolir = 0;

	if (!isProjetoNovo && (isUnifamiliarAutonoma || isUnifamiliarUmaEconomia || isNaoResidencial)) {
		totalDemolir = get(formdados, 'detalhesAreas.totaisManuais.areaTotalDemolir.value');
		valPermanecer = get(formdados, 'detalhesAreas.totais.areaExistenteTotalPermanecerDemolir');
	} else if (!isProjetoNovo && isUnifamiliarDuasEconomias) {
		totalDemolir = get(formdados, 'detalhesAreas.totaisManuais.areaTotalDemolirEconomia0102.value');
		valPermanecer = get(formdados, 'detalhesAreas.totais.areaExistenteTotalPermanecerDemolirEconomia0102');
	} else if (!isProjetoNovo && isUnifamiliarAteDuasEconomiasComNR) {
		totalDemolir = get(formdados, 'detalhesAreas.totaisManuais.areaTotalDemolirUnifamiliarNaoResidencial.value');
		valPermanecer = get(formdados, 'detalhesAreas.totais.areaExistenteTotalPermanecerDemolir');
	}

	const resultado = soma(valPermanecer, totalDemolir);

	if (!isProjetoNovo && resultado === '0.00') {
		adicionaErros(validacao, [
			{ campo: 'planilhaAreas', message: 'A soma EXISTENTE + DEMOLIR tem que ser diferente de ZERO (0).' }
		]);
	} else if (isProjetoNovo && resultado !== '0.00') {
		adicionaErros(validacao, [
			{ campo: 'planilhaAreas', message: 'A soma EXISTENTE + DEMOLIR tem que ser igual a ZERO (0).' }
		]);
	}

	return { validacao, isBloqueante };
}

/**
 * ÁREA DO TERRENO OBJETO DO PROJETO
 * @param {*} data
 * @returns
 */
function validarInfosTerreno(data) {
	let erros = [];
	let isBloqueante = false;
	let formdados = data;

	// InfosTerrenos
	const possuiAreaDeMenorPoligono = formdados?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = formdados?.possuiAreaAtingida === 'sim';

	if (maiorQue(formdados?.areaMenorPoligonoLote?.value, formdados?.areaTotalMatriculaLote?.value)) {
		// erro
		erros.push({
			campo: 'areaMenorPoligonoLote',
			message: 'A área do menor poligono não pode ser maior que a área da matrícula do lote'
		});
	}
	if (maiorQue(formdados?.areaAtingidaMatricula?.value, formdados?.areaTotalMatriculaLote?.value)) {
		// erro
		erros.push({
			campo: 'areaAtingidaMatricula',
			message: 'A área do atingida da matrícula não pode ser maior que a área da matrícula do lote'
		});
	}
	if (possuiAreaDeMenorPoligono) {
		if (maiorQue(formdados?.areaAtingidaMenorPoligonal?.value, formdados?.areaMenorPoligonoLote?.value)) {
			// erro
			erros.push({
				campo: 'areaAtingidaMenorPoligonal',
				message: 'A área atingida da menor poligonal não pode ser maior que a área do menor poligono'
			});
		}
	}
	if (possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		formdados.areaAplicacaoRU = formdados.areaMenorPoligonoLoteMais5;
		// verificação se área do menor polígono não é maior que a área do terreno
		if (maiorQue(formdados?.areaMenorPoligonoLote?.value, formdados.areaTotalMatriculaLote?.value)) {
			// erro
			erros.push({
				campo: 'areaMenorPoligonoLote',
				message: 'A área atingida do menor poligono não pode ser maior que a área da matrícula do lote'
			});
		}
	}

	return { erros, isBloqueante };
}

/**
 * valida campos obrigatorios, é diferente para o 4distrito
 * @param {*} data
 * @param {*} tipoForm
 * @param {*} errosNovos
 * @param {*} errosAtuais
 * @returns
 */
function validaCamposObrigatorios(data, tipoForm, errosNovos, errosAtuais) {
	errosNovos = adicionaErros(errosNovos, errosAtuais);

	camposOcultos = [];

	if (isQuartoDistrito) {
		iteraMETAFORMLE(METAFORMLE.INFOS_TERRENO, tipoForm, data);
	} else {
		// ÁREA DO TERRENO OBJETO DO PROJETO
		iteraMETAFORMLE(METAFORMLE.INFOS_TERRENO, tipoForm, data);
		// PROJETO
		iteraMETAFORMLE(METAFORMLE.INFOS_PROJETO, tipoForm, data[INFOSPROJETO_CONTROLNAME] || {});
		// iteraMETAFORMLE(METAFORMLE.INFOS_QUOTAS, tipoForm, data);
		// iteraMETAFORMLE(METAFORMLE.INFOS_ALTURAS_MAXIMAS, tipoForm, data);

		iteraMETAFORMLE(METAFORMLE.INFOS_AMBIENTAIS, tipoForm, data);

		// campos das restricoes que nao sao "sim" ou "nao"
		if (data?.classificacaoEdificacao === 'tombado') {
			if (isNil(data?.possuiEvuTombado)) {
				vetorObrigatorios.push({
					campo: 'possuiEvuTombado',
					message:
						'Campo "O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA? (Tombado)" deve ser informado'
				});
			}
		}
		if (data?.classificacaoEdificacao === 'estruturacao') {
			if (isNil(data?.possuiEvuEstruturacao)) {
				vetorObrigatorios.push({
					campo: 'possuiEvuEstruturacao',
					message:
						'Campo "O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA? (Estruturação)" deve ser informado'
				});
			}
		}
		if (data?.classificacaoEdificacao === 'compatibilizacao') {
			if (isNil(data?.terrenoPossuiRuDefinido)) {
				vetorObrigatorios.push({
					campo: 'terrenoPossuiRuDefinido',
					message: 'Campo "O terreno possui regime urbanístico definido?" deve ser informado'
				});
			}
			if (isNil(data?.possuiEvuCompatibilizacao)) {
				vetorObrigatorios.push({
					campo: 'possuiEvuCompatibilizacao',
					message:
						'Campo "O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido conforme Art. 159 do PDDUA? (Compatibilização)" deve ser informado'
				});
			}
		}
	}

	for (let index in vetorObrigatorios) {
		errosNovos.push({
			campo:
				vetorObrigatorios[index].campo === 'projetoAtividade' ? 'atividadeAnexo52' : vetorObrigatorios[index].campo,
			message: vetorObrigatorios[index].label
				? `Campo "${vetorObrigatorios[index].label}" deve ser informado.`
				: 'Esse campo é obrigatório e não foi informado'
		});
	}

	return errosNovos;
}

const validaInfosDispositivosControle = (data, tipoForm) => {
	const erros = [];

	if (tipoForm === LE_Enquadramentos.UNIFAMILIAR_AUTONOMA) {
		// QUOTAS
		const quotaIndiceAproveitamento = get(data, 'quotaIndiceAproveitamento.value');
		const quotaTaxaOcupacao = get(data, 'quotaTaxaOcupacao.value');
		const quotaALP = get(data, 'quotaALP.value');
		// ALTURAS
		const alturaMaximaRegimeUrbanistico = get(data, 'alturaMaximaRegimeUrbanistico.value');
		const alturaMaximaAeroportoAerodromo = get(data, 'alturaMaximaAeroportoAerodromo.value');
		// REGRA EXTRA
		const restricoesData = get(data, 'restricoesAdministrativas', data);
		const showALP = get(restricoesData, 'restricaoALPdoCondominio') === 'sim';

		if (showALP && (isNil(quotaALP) || isEmpty(quotaALP))) {
			erros.push({
				campo: 'quotaALP',
				message: 'Área Livre Permeável - ALP (m²) deve ser informado.'
			});
		}
		if (isNil(quotaIndiceAproveitamento) || isEmpty(quotaIndiceAproveitamento)) {
			erros.push({
				campo: 'quotaIndiceAproveitamento',
				message: 'Índice de Aproveitamento - IA deve ser informado.'
			});
		}
		if (isNil(quotaTaxaOcupacao) || isEmpty(quotaTaxaOcupacao)) {
			erros.push({
				campo: 'quotaTaxaOcupacao',
				message: 'Taxa de Ocupação - TO (m²) deve ser informado.'
			});
		}
		if (isNil(alturaMaximaRegimeUrbanistico) || isEmpty(alturaMaximaRegimeUrbanistico)) {
			erros.push({
				campo: 'alturaMaximaRegimeUrbanistico',
				message: 'Altura máxima definida pelo Regime Urbanístico (m) deve ser informado.'
			});
		}
		if (isNil(alturaMaximaAeroportoAerodromo) || isEmpty(alturaMaximaAeroportoAerodromo)) {
			erros.push({
				campo: 'alturaMaximaAeroportoAerodromo',
				message: 'Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m) deve ser informado.'
			});
		}
	}

	return erros;
};

const validaInfosProjeto = (data, tipoForm) => {
	const erros = [];
	const formdados = get(data, [INFOSPROJETO_CONTROLNAME], {});

	const isUnifamiliarENaoResidencial = LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES === tipoForm;
	const isNaoResidenciais = [
		LE_Enquadramentos.NAO_RESIDENCIAL,
		LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	].includes(tipoForm);
	const isNaoResidencial = LE_Enquadramentos.NAO_RESIDENCIAL === tipoForm;

	const cienciaRestricoes = get(formdados, 'projetoAtendeRestricoesAtividades');
	const restricoesViabilidade = get(formdados, 'projetoViabilidaUrbanistico');
	const projetoImpactoUrbano = get(formdados, 'projetoImpactoUrbano');

	if (size(get(formdados, 'tipoConstrucao')) < 1) {
		erros.push({
			campo: 'tipoConstrucao',
			message: 'Tipo de material deve ser informado.'
		});
	}

	if (isNaoResidenciais && (!cienciaRestricoes || cienciaRestricoes === 'nao')) {
		// adicionado num campo que não está na tela por que a mensagem já
		// está automaticamente na tela na seleção da opção não
		erros.push({
			campo: 'projetoAtendeRestricoesAtividades2',
			message:
				'A(s) atividade(s) do projeto deve(m) estar de acordo com os Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA)'
		});
	}

	if (isNaoResidenciais && restricoesViabilidade === 'nao') {
		// adicionado num campo que não está na tela por que a mensagem já
		// está automaticamente na tela na seleção da opção não
		erros.push({
			campo: 'projetoViabilidaUrbanistico2',
			message:
				'Devido a atividade estar listada no ANEXO 11 e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).'
		});
	}

	if (isNaoResidenciais && !projetoImpactoUrbano) {
		erros.push({
			campo: 'projetoImpactoUrbano',
			message: 'Selecione uma opção.'
		});
	}

	if (isNaoResidenciais && !restricoesViabilidade && projetoImpactoUrbano === 'sim') {
		erros.push({
			campo: 'projetoViabilidaUrbanistico',
			message: 'Selecione uma opção.'
		});
	}

	const numeroEconomiasResidencial = get(formdados, 'numeroEconomiasResidencial.value');
	if (
		isUnifamiliarENaoResidencial &&
		(maiorQue(numeroEconomiasResidencial, 2) ||
			numeroEconomiasResidencial === 0 ||
			numeroEconomiasResidencial === '0' ||
			!numeroEconomiasResidencial)
	) {
		erros.push({
			campo: 'numeroEconomiasResidencial',
			message:
				'Número de Economias - Residencial: só é permitido informar 1 ou 2 economias residenciais (habitação unifamiliar até 2 economias).'
		});
	}

	const numeroEconomiasNaoResidencial = get(formdados, 'numeroEconomiasNaoResidencial.value');
	if (
		isNaoResidenciais &&
		(numeroEconomiasNaoResidencial === 0 || numeroEconomiasNaoResidencial === '0' || !numeroEconomiasNaoResidencial)
	) {
		erros.push({
			campo: 'numeroEconomiasNaoResidencial',
			message: 'Número de Economias - Não Residencial: deve ser informada ao menos 1 economia não residencial.'
		});
	}

	if (isNaoResidencial && parseInt(numeroEconomiasNaoResidencial) === 1 && !formdados?.trintaPorcentoIa) {
		erros.push({
			campo: 'trintaPorcentoIa',
			message:
				'O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA? deve ser informado.'
		});
	}

	const isProjetoPossuiBaseCorpo =
		formdados.projetoPossuiBaseCorpo === 'sim' ? true : formdados.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete erros.taxaOcupacaoTotalPermitido;
		delete erros.taxaOcupacaoTotalPermitido;

		let valInformado = get(formdados, ['totalBaseProjeto', 'value']);
		let valLimite = get(formdados, ['totalPermitidoBase', 'value']);
		if (isNil(valInformado) || valInformado === '0.00') {
			erros.push({
				campo: 'totalBaseProjeto',
				message: 'A Taxa de Ocupação (TO) total da base do projeto (m²) deve ser informada'
			});
		}
		if (maiorQue(valInformado, valLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
			erros.push({
				campo: 'totalBaseProjeto',
				message:
					'A Taxa de Ocupação (TO) total da base do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para a base (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
			});
		}
		valInformado = get(formdados, ['totalCorpoProjeto', 'value']);
		valLimite = get(formdados, ['totalPermitidoCorpo', 'value']);
		if (isNil(valInformado) || valInformado === '0.00') {
			erros.push({
				campo: 'totalCorpoProjeto',
				message: 'A Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser informada'
			});
		}
		if (maiorQue(valInformado, valLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
			erros.push({
				campo: 'projetoPossuiEvuValido',
				message:
					'A Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para o corpo (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
			});
		}
	}

	const alturaMaximaAeroportoAerodromo = get(formdados, 'alturaMaximaAeroportoAerodromo.value');
	const alturaTotal = get(formdados, 'alturaTotalProjeto.value');

	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		(get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'nao' ||
			(get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'sim' &&
				get(data, 'edificacaoUltrapassaAlturaDECEA') === 'nao'))
	) {
		erros.push({
			campo: 'alturaTotalProjeto',
			message:
				'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão "Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		});
	}
	const toInformado = get(formdados, ['taxaOcupacaoTotal', 'value']);
	const toLimite = get(formdados, ['taxaOcupacaoTotalPermitido', 'value']);
	if (isNil(toInformado) || toInformado === '0.00') {
		erros.push({
			campo: 'taxaOcupacaoTotal',
			message: 'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser informada'
		});
	}
	if (maiorQue(toInformado, toLimite) && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
		erros.push({
			campo: 'taxaOcupacaoTotal',
			message:
				'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
		});
	}

	const valLimiteLote = get(data, ['areaTotalMatriculaLote', 'value']);
	if (maiorQue(toInformado, valLimiteLote)) {
		erros.push({
			campo: 'taxaOcupacaoTotal',
			message:
				'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Área total da(s) matrícula(s) do(s) lote(s) (m²).'
		});
	}

	return { erros, isBloqueante: erros.length > 0 };
};

const validarSubUnidades = (data, tipoform) => {
	const erros = [];
	const dadosSubUnidades = get(data, 'planoDiretor.dadosSubunidades', []);
	const certificadoSustentavel = get(data, 'certificadoSustentavel');

	const isNaoResidencial = tipoform === LE_Enquadramentos.NAO_RESIDENCIAL;
	const isUniFamiliarNaoResidencial = tipoform === LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES;
	const isUnidadeAutonoma = tipoform === LE_Enquadramentos.UNIFAMILIAR_AUTONOMA;
	const isUnifamiliarUmaOuDuas = [
		LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
		LE_Enquadramentos.UNIFAMILIAR_02_ECONO
	].includes(tipoform);

	if (!isUnidadeAutonoma) {
		if (dadosSubUnidades.length === 0) {
			erros.push({
				campo: 'planoDiretorVazio',
				message: 'Plano diretor deve ser preenchido'
			});
		}
		dadosSubUnidades.forEach(sub => {
			const subnumero = sub?.subunidade?.subunidade;
			const nome = `Subunidade ${subnumero}`;

			if (sub?.subunidade?.atividade === '99.99' && isNil(sub?.possuiRuDefinidoPorLegislacao)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiRuDefinidoPorLegislacao',
					message: `Este projeto possui regime urbanístico definido no terreno por legislação? deve ser informada na ${nome}`
				});
			}

			if (sub?.possuiRuDefinidoPorLegislacao === 'nao') {
				erros.push({
					idSub: sub?.id,
					message: MSG_ERROR_LEI_ESPECIFICA
				});
			}

			if (isNil(sub?.possuiAlturaDivisa)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAlturaDivisa',
					message: `O projeto possui altura de divisa? deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.alturaProjeto)) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaProjeto',
					message: `Altura do projeto conforme o Art. 112, §1º, III do PDDUA (m) deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.contemplaAcomodTelhadoSotaoMezanino)) {
				erros.push({
					idSub: sub?.id,
					campo: 'contemplaAcomodTelhadoSotaoMezanino',
					message: `O projeto contempla o benefício do Art. 113, Inc. II, alínea "c" (acomodação do telhado) e/ou "e" (sótão ou mezanino, etc.) do PDDUA? deve ser informada na ${nome}`
				});
			}

			if (!isUnifamiliarUmaOuDuas && isNil(sub?.contemplaRooftops)) {
				erros.push({
					idSub: sub?.id,
					campo: 'contemplaRooftops',
					message: `O projeto contempla a existência de rooftops nos termos do Decreto Municipal nº 20.746/20? deve ser informada na ${nome}`
				});
			}

			if (
				sub?.possuiAlturaDivisa &&
				sub?.possuiAlturaDivisa === 'sim' &&
				(isNil(sub?.alturaDivisaRegimeUrbanisticoAnexo) ||
					parseFloat(sub?.alturaDivisaRegimeUrbanisticoAnexo?.value) === 0.0)
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaDivisaRegimeUrbanisticoAnexo',
					message: `A altura de divisa deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.possuiAlturaBase) && !sub?.hideAlturaBase) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAlturaBase',
					message: `O projeto possui altura de base? deve ser informada na ${nome}`
				});
			}

			if (
				sub?.possuiAlturaBase &&
				sub?.possuiAlturaBase === 'sim' &&
				(isNil(sub?.alturaBaseRegimeUrbanisticoAnexo) ||
					parseFloat(sub?.alturaBaseRegimeUrbanisticoAnexo?.value) === 0.0)
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaBaseRegimeUrbanisticoAnexo',
					message: `A altura de base deve ser informada na ${nome}`
				});
			}

			if (isNil(sub?.possuiAnexo72)) {
				erros.push({
					idSub: sub?.id,
					campo: 'possuiAnexo72',
					message: `O terreno está listado no Anexo 7.2 do Plano Diretor? deve ser informada na ${nome}`
				});
			}

			if (isNaoResidencial || isUniFamiliarNaoResidencial) {
				forEach(sub.dadosAtividades, a => {
					const atvItem = get(a, 'atividadeAnexo52.item');
					const atvName = get(a, 'atividadeAnexo52.atv');
					const atv = `${atvItem}${atvName}`;
					const isIndustriasInterferenciaAmbiental = ATIVIDADES_EVU.includes(
						get(a, 'atividadeAnexo52.item', '').trim()
					);
					if (isIndustriasInterferenciaAmbiental) {
						if (isNil(a?.estudoViabilidadeUrbanisticaAprovado)) {
							erros.push({
								campo: 'estudoViabilidadeUrbanisticaAprovado',
								message: `A atividade possui Estudo de Viabilidade Urbanística aprovado e válido? deve ser informada na ${nome}, ${atv}`
							});
						}
						if (a?.estudoViabilidadeUrbanisticaAprovado === ' nao') {
							// 		erros.push({
							// 			campo: 'estudoViabilidadeUrbanisticaAprovado',
							// 			message: `Devido a necessidade de Estudo de Viabilidade Urbanística prévio, esta atividade ${atv} não se enquadra na modalidade de Licenciamento Expresso`
							// 		});
							erros.push({
								campo: 'estudoViabilidadeUrbanisticaAprovado',
								message: `Devido a atividade ${
									size(atv) > 0 ? `(${atv})` : ''
								} NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).`
							});
						}
					}
				});
			}
		});

		// Valida areas de aplicacao do regime urbanistico e alturas divisa e base
		let somaAreasAtingidas = 0;
		dadosSubUnidades.forEach(sub => {
			somaAreasAtingidas = soma(sub.areaTerrenoAtingida?.value || 0, somaAreasAtingidas);
			// somaAreasAtingidas += parseFloat(sub.areaTerrenoAtingida?.value || 0);

			const desabilitarConferenciaAltura =
				get(sub, 'contemplaAcomodTelhadoSotaoMezanino') === 'sim' || get(sub, 'contemplaRooftops') === 'sim';

			if (
				parseFloat(get(sub, 'alturaBaseRegimeUrbanisticoAnexo.value')) >
					parseFloat(get(sub, 'baseRegimeUrbanisticoAnexo.codigo')) &&
				get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim'
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaBaseRegimeUrbanisticoAnexo',
					message: `A altura da base deve ser menor ou igual ao limite do Plano Diretor (${sub?.baseRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
				});
			}
			if (
				parseFloat(get(sub, 'alturaDivisaRegimeUrbanisticoAnexo.value')) >
					parseFloat(get(sub, 'divisaRegimeUrbanisticoAnexo.codigo')) &&
				get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim'
			) {
				erros.push({
					idSub: sub?.id,
					campo: 'alturaDivisaRegimeUrbanisticoAnexo',
					message: `A altura da divisa deve ser menor ou igual ao limite do Plano Diretor (${sub?.divisaRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
				});
			}

			if (!desabilitarConferenciaAltura && get(data, 'infosProjeto.projetoPossuiEvuValido') !== 'sim') {
				let limiteRU = get(sub, 'alturaRegimeUrbanisticoAnexo.codigo');
				if (certificadoSustentavel) {
					limiteRU = multiplica(soma(certificadoSustentavel.valor, '1'), limiteRU.codigo);
				}
				if (maiorQue(get(sub, 'alturaProjeto.value'), limiteRU)) {
					erros.push({
						idSub: sub?.id,
						campo: 'alturaProjeto',
						message: `A altura do projeto deve ser menor ou igual ao limite do Plano Diretor (${trocaPontoPorVirgula(
							limiteRU
						)} m), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.`
					});
				}
			}
		});

		if (somaAreasAtingidas !== data?.areaAplicacaoRU?.value) {
			erros.push({
				campo: 'areaTerrenoAtingida',
				message:
					'A soma das áreas de todas as subunidades deve ser igual a Área para aplicação do Regime Urbanístico (m²)'
			});
		}
	}

	return { erros, isBloqueante: erros.length > 0 };
};

/**
 * Itera o objeto METAFORMLE recursivamente, verificando campos required que não estão ocultos
 *
 * @param {object} meta Objeto aninhado de METAFORMLE
 * @param {string} tipoForm Tipo do form
 * @param {object} data localdata
 */
const iteraMETAFORMLE = (meta, tipoForm, data) => {
	Object.keys(meta).forEach(key => {
		const isObjetoIteravel = typeof meta[key] === 'object' && !isNil(meta[key].label);
		if (isObjetoIteravel) {
			const formPossuiCampo = meta[key].tipoform.includes(tipoForm);
			const isRequired = meta[key]?.required === true;

			if (formPossuiCampo && isRequired) {
				const possuiSubCampos = !isNil(meta[key]?.subcampos) && size(meta[key]?.subcampos) > 0;
				const possuiSubCamposOcultos = possuiSubCampos && (data[key] === 'nao' || data[key] === undefined);

				const isNaoInformado = isNil(data[key]) || isEmpty(data[key]);
				const isVisivel = !camposOcultos.includes(meta[key].name);

				if (possuiSubCamposOcultos) {
					for (let index in meta[key].subcampos) {
						camposOcultos.push(meta[key].subcampos[index]);
					}
				}

				if (isNaoInformado && isVisivel) {
					vetorObrigatorios.push({ campo: meta[key].name, label: meta[key].label });
				}
			}
			iteraMETAFORMLE(meta[key], tipoForm, data);
		}
	});
};

const adicionaErros = (errosAtuais, ...errosNovos) => {
	errosNovos.forEach(es => errosAtuais.push(...es));
	// for (let erro in errosNovos) {
	// 	errosAtuais.push(errosNovos[erro]);
	// }

	return errosAtuais;
};
