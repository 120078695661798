import React from 'react';

import { ReactReduxContext } from 'react-redux';

import hoistNonReactStatics from 'hoist-non-react-statics';
import uuid from 'uuid/v1';

import getInjectors from 'config/sagaInjectors';

const injectSaga =
	({ saga, mode }) =>
	WrappedComponent => {
		class InjectSaga extends React.Component {
			static WrappedComponent = WrappedComponent;

			static contextType = ReactReduxContext;

			static displayName = `withSaga(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

			uuid = uuid();

			UNSAFE_componentWillMount() {
				const { injectSaga } = this.injectors;

				injectSaga(this.uuid, { saga, mode }, this.props);
			}

			componentWillUnmount() {
				const { ejectSaga } = this.injectors;

				ejectSaga(this.uuid);
			}

			injectors = getInjectors(this.context.store);

			render() {
				return <WrappedComponent {...this.props} />;
			}
		}

		return hoistNonReactStatics(InjectSaga, WrappedComponent);
	};

export default injectSaga;
