import React, { Fragment, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { get, isEmpty, isNil, trim } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import TemplateField from '../TemplateField';

const FieldList = props => {
	const [erros, setErros] = useState([]);
	// props como se fosse um TemplateField
	const { controlName, attributes, onErrorHandler, onChangeHandler } = props;
	// attributes do TemplateField
	const {
		metaFields,
		value = [{}],
		label,
		required,
		readOnly,
		disabled,
		disabledBtns,
		placeholder,
		btnAddLabel = 'Adicionar Outro',
		btnRemoveLabel = 'Remover',
		ignoreInnerContainer
	} = attributes;

	const localChange = useCallback(
		changes => {
			const { key: control, value: innerValues = {} } = changes;
			const { pos, key: metaKey, value: metaValue } = innerValues;
			const newControlValue = [...value];
			if (metaKey === 'add') {
				newControlValue.push({});
			} else if (metaKey === 'remove') {
				newControlValue.splice(pos, 1);
				setErros([]);
			} else if (metaKey !== 'error') {
				const metaControl = newControlValue[pos];
				metaControl[metaKey] = metaValue;
			}
			onChangeHandler && onChangeHandler({ key: control, value: newControlValue });
		},
		[value, onChangeHandler]
	);

	const localErrorChange = useCallback(
		changes => {
			const { value: innerValues = {} } = changes;
			const { pos, key: metaKey, value: metaValue } = innerValues;

			for (let index = 0; index <= pos; index++) {
				if (index === pos) {
					const objError = erros[pos] || {};
					objError[metaKey] = metaValue;
					erros[pos] = objError;
				} else {
					erros[index] = null;
				}
			}
			setErros(erros.slice());
			onErrorHandler && onErrorHandler();
		},
		[erros, onErrorHandler]
	);
	return (
		<>
			{label && (
				<div className="row">
					<div className="form-group col-md-12">
						<label className="control-label">
							{label}
							{required && <span className="required">*</span>}
						</label>
					</div>
				</div>
			)}
			{placeholder && <span className="text-info">{placeholder}</span>}
			<div name="fieldList" className={ignoreInnerContainer ? '' : 'border mb-2 rounded'}>
				{value.map((val, vi) => (
					<Fragment key={vi}>
						<div className={ignoreInnerContainer ? 'row' : 'clearfix row row-cols-12 col-12'}>
							{Object.keys(metaFields).map(metakey => {
								const metaAttrs = { ...{ readOnly, disabled, required }, ...metaFields[metakey], value: val[metakey] };
								return (
									<div className={`form-group col-md-${metaAttrs.columns || 12}`} key={metakey}>
										<TemplateField
											controlName={metakey}
											attributes={metaAttrs}
											onErrorHandler={e => {
												localErrorChange({ key: controlName, value: { pos: vi, key: metakey, value: e[metakey] } });
											}}
											onChangeHandler={({ key, value }) => {
												localChange({ key: controlName, value: { pos: vi, key, value } });
											}}
										></TemplateField>
										<ErrorMessages errorList={get(erros, `${vi}.${metakey}`)} />
									</div>
								);
							})}
							{vi > 0 && !disabled && (
								<button
									type="button"
									className="btn btn-danger float-right btn-sm ml-2 mb-2"
									onClick={() => localChange({ key: controlName, value: { pos: vi, key: 'remove' } })}
									disabled={disabled}
								>
									{btnRemoveLabel} <i className="fa fa-trash" aria-hidden="true" />
								</button>
							)}
						</div>
					</Fragment>
				))}
				{!disabled && !disabledBtns && (
					<button
						className="btn btn-secondary btn-sm mb-2 ml-2"
						type="button"
						onClick={() => localChange({ key: controlName, value: { key: 'add' } })}
						disabled={disabled}
					>
						{btnAddLabel} <i className="fa fa-plus" aria-hidden="true" />
					</button>
				)}
			</div>
		</>
	);
};

export function validate({ value, metaFields = {}, label, required }) {
	let isValueInformado = false;
	const errorList = [];

	Object.keys(metaFields)
		.filter(mfKey => required || metaFields[mfKey].required === true)
		.forEach(mfKey => {
			const { label: innerLabel } = metaFields[mfKey];
			isValueInformado =
				isNil(value) || isEmpty(value)
					? false
					: value.every(v => {
							const keysReq = Object.keys(v).filter(k => k.startsWith(mfKey));
							return keysReq.length === 0 ? false : keysReq.every(k => valueInformado(v[k]));
					  });
			if (isValueInformado === false) {
				errorList.push(`Campo ${label || ''}/${innerLabel} é obrigatório e seu valor não foi informado`);
			}
		});

	return errorList;
}

/** valida se o value foi informado */
function valueInformado(value) {
	// trata endereco sem numero
	if (value && value.enderecoFormatado && !value.numero && value.numero !== 0) {
		return false;
	}

	return isNil(value)
		? false
		: Number.isFinite(value)
		? true
		: typeof value === 'boolean'
		? value
		: typeof value === 'string'
		? !isEmpty(trim(value))
		: !isEmpty(value);
}

FieldList.displayName = 'FieldList';
FieldList.propTypes = {
	controlName: PropTypes.string,
	attributes: PropTypes.object,
	onErrorHandler: PropTypes.func,
	onChangeHandler: PropTypes.func
};
export default FieldList;
