import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useStore } from 'react-redux';

import { get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { EMAIL_REGEX } from 'utils/constants';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const EmailField = ({ label, required, placeHolder, name, value, readOnly, disabled, onChangeHandler, isInvalid }) => {
	const store = useStore();
	const [userEmail, setUserEmail] = useState('');
	const showAvisoEmailsDiferentes = EMAIL_REGEX.test(value) && name === 'emailRespTecnico' && value !== userEmail;

	useEffect(() => {
		const obtainUserEmail = async () => {
			if (store && get(store, 'kc.authenticated') && userEmail === '') {
				const userKC = await store.kc.loadUserInfo();
				setUserEmail(userKC.email);
			}
		};
		obtainUserEmail();
	}, [store, userEmail]);

	const onChangeText = e => {
		const { value } = e.target;
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};
	return (
		<>
			<label className="control-label">
				{label}
				{required && <span className="required">*</span>}
			</label>
			<input
				className={
					isInvalid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value}
				onChange={onChangeText}
				readOnly={readOnly}
				disabled={disabled}
				required={required}
			/>
			{showAvisoEmailsDiferentes && (
				<>
					<ErrorMessages
						errorList={[
							'O e-mail de login é diferente do e-mail cadastrado no requerimento, você deseja continuar com o cadastro? O e-mail de cadastro no requerimento será o canal de contato ao longo da tramitação da solicitação.'
						]}
						type={'warning'}
					/>
				</>
			)}
		</>
	);
};
EmailField.displayName = 'EmailField';

EmailField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default EmailField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (size(value) === 0 || !EMAIL_REGEX.test(value)) {
			errors.push('Insira um email válido');
		}
	}
	return errors;
};
