import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';
import { useStore } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get, size } from 'lodash';

/* eslint-disable react-hooks/exhaustive-deps */
const Username = ({ history }) => {
	const store = useStore();

	const [user, setUser] = useState({});
	const [logoutFunction, setLogoutFunction] = useState(null);

	useEffect(() => {
		const obtainUser = async () => {
			if (store && get(store, 'kc.authenticated') && !user.name) {
				const userKC = await store.kc.loadUserInfo();
				setUser(userKC);
				const logout = store.kc.logout;
				setLogoutFunction({ logout });
			}
		};
		obtainUser();
	}, []);

	const login = () => {
		if (store && store.kc) {
			store.kc.login();
		}
	};

	const handleLogout = () => {
		setUser({});
	};

	const hadleProfile = () => {
		history.push('/perfil');
	};

	useEffect(() => {
		if (size(user) === 0 && logoutFunction) {
			history.push('/');
			logoutFunction.logout();
		}
	}, [user]);

	return get(store, 'kc.authenticated') && user.name ? (
		<span className="float-right">
			<Dropdown as="button" className="btn-link btn-icon header-btn" style={{ padding: '4px' }}>
				<Dropdown.Toggle as="span" className="username-barra-topo" id="dropdown-basic">
					{user.name}
				</Dropdown.Toggle>
				<Dropdown.Menu as="ul">
					<Dropdown.Item as="li" onClick={hadleProfile}>
						Meus dados
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<button
				type="button"
				onClick={handleLogout}
				className="btn-link btn-icon header-btn pull-right"
				style={{ marginTop: '-4px', fontWeight: 'normal' }}
				title="Sair do sistema"
			>
				Sair
			</button>
		</span>
	) : (
		<button
			type="button"
			onClick={login}
			className="btn-link btn-icon header-btn pull-right btn-username-barra-topo"
			style={{ marginTop: '-4px', fontWeight: 'normal' }}
			title="entrar com login e senha"
		>
			<i className="fa fa-sign-in" style={{ fontSize: '22px', fontWeight: 'normal', marginRight: '8px' }} />
			Login
		</button>
	);
};
Username.displayName = 'Username';

Username.propTypes = {
	history: PropTypes.any
};

export default withRouter(Username);
