import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { size, get, set, forEach, defaultTo, difference, last } from 'lodash';

import { NumberField, TextAreaField } from 'containers/Form/metadata-template/fields';

import { diferenca, trocaPontoPorVirgula, soma } from 'utils/tools';

import { INFOSPROJETO_CONTROLNAME } from './InfosProjetoLE';
import { ITEM_HABITACAO_COLETIVA, LE_Enquadramentos } from './MetadataLE';

/**
 * @typedef {object} NumberFieldValue
 * @property {string} value
 * @property {boolean} checkedForZero
 * @property {string} subTotal
 *
 *
 * @typedef {object} InfosAreas
 * @property {NumberFieldValue} mtAdensavel área adensável
 * @property {NumberFieldValue} mtNaoAdensavel área não adensável 50%
 * @property {NumberFieldValue} mtIsenta área isenta
 * @property {string} subTotal somatorio das áreas ad, nad, isenta
 *
 * @typedef {object} Pavimento
 * @property {string} descricao
 * @property {InfosAreas} permanecer
 * @property {InfosAreas} demolir
 * @property {InfosAreas} construir
 * @property {InfosAreas} regularizar
 * @property {string} total somatório dos totais ConstruirRegularizar e PermanecerDemolir
 * @property {string} totalConstruirRegularizar somatório dos sub-totais construir e regularizar
 * @property {string} totalArea somatório dos sub-totais permancer, construir, regularizar menos/diferença do sub-total demolir
 * @property {string} totalPermanecer diferença entre os sub-totais permanecer e demolir
 * @property {object} mtAdensavel
 * @property {string} mtAdensavel.total
 * @property {object} mtNaoAdensavel
 * @property {string} mtAdensavel.total
 * @property {object} mtIsenta
 * @property {string} mtAdensavel.total
 *
 * @typedef {object} Economia
 * @property {string} nomeEconomia
 * @property {Pavimento[]} pavimentos
 *
 * @typedef {object} DetalhesAreas
 * @property {Pavimento} totais
 * @property {Economia[]} economias tipos de pavimentos ou quantidade de economias
 *
 */

export const TIPOS_AREAS_ENUM = {
	permanecer: 'permanecer',
	demolir: 'demolir',
	construir: 'construir',
	regularizar: 'regularizar'
};
const TIPOS_AREAS = [TIPOS_AREAS_ENUM.permanecer, TIPOS_AREAS_ENUM.construir, TIPOS_AREAS_ENUM.regularizar];
export const TIPOS_METRAGENS_ENUM = {
	adensavel: 'mtAdensavel',
	isenta: 'mtIsenta',
	naoAdensavel50: 'mtNaoAdensavel'
};

const TIPOS_METRAGENS = [
	TIPOS_METRAGENS_ENUM.adensavel,
	TIPOS_METRAGENS_ENUM.naoAdensavel50,
	TIPOS_METRAGENS_ENUM.isenta
];

export const TIPOS_ECONOMIAS_ENUM = { residencial: 'residencial', naoResidencial: 'naoResidencial' };

const TIPOS_ECONOMIAS = [TIPOS_ECONOMIAS_ENUM.residencial, TIPOS_ECONOMIAS_ENUM.naoResidencial];

/**
 * componente local
 */
function AreaCell({ value, name, placeHolder, onChangeHandler, readOnly }) {
	return (
		<td className="cell-input">
			<NumberField
				placeHolder={placeHolder}
				value={defaultTo(value, {})}
				title={get(value, 'value')}
				decimals={2}
				maxSize="10"
				name={name}
				onChangeHandler={onChangeHandler}
				readOnly={readOnly}
			/>
		</td>
	);
}
AreaCell.displayName = 'AreaCell';
AreaCell.propTypes = {
	value: PropTypes.object,
	name: PropTypes.string,
	placeHolder: PropTypes.string,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool
};

/**
 * componente local
 */
function AreasPavimento({
	pavimento,
	economia,
	namePrefix,
	economiaPrefix,
	totalNamePrefix,
	onChangeHandler,
	data: localData,
	readOnly,
	hideAreas,
	/** transforma em readonly um tipo inteiro de metragem (permanecer, construir, etc) */
	readOnlyMetragens,
	/** transforma em readonly um tipo área (adensavel, isenta, etc) */
	readOnlyAreas,
	isNaoResidencial
}) {
	// celulas da tabela
	const cells = useMemo(() => {
		const AREAS = difference(TIPOS_AREAS, hideAreas);

		return AREAS.map(area => {
			/** somente leitura por alguma regra de negócio local */
			let readonlyRuleArea = false,
				readonlyRuleMt = false;

			if (readOnlyAreas && Array.isArray(readOnlyAreas)) {
				readonlyRuleArea = readOnlyAreas.includes(area);
			}

			return TIPOS_METRAGENS.map(metragem => {
				if (readOnlyMetragens && Array.isArray(readOnlyMetragens)) {
					//voltamos atras, OP # 59532
					// Libera todas areas do existente a permanecer sempre que for aumento/diminuicao
					/* if (!isProjetoNovo && area === TIPOS_AREAS_ENUM.permanecer) {
						readonlyRuleMt = false;
					} else {
						readonlyRuleMt = readOnlyMetragens.includes(metragem);
					} */
					readonlyRuleMt = readOnlyMetragens.includes(metragem);
				}

				if (readonlyRuleArea || readonlyRuleMt) {
					set(pavimento, [area, metragem, 'value'], '0');
				}

				return (
					<AreaCell
						key={`${namePrefix}.${area}.${metragem}`}
						placeHolder="0,00"
						value={get(pavimento, [area, metragem])}
						name={`${namePrefix}.${area}.${metragem}`}
						onChangeHandler={onChangeHandler}
						readOnly={readOnly || readonlyRuleArea || readonlyRuleMt}
					/>
				);
			});
		});
	}, [hideAreas, namePrefix, onChangeHandler, pavimento, readOnly, readOnlyAreas, readOnlyMetragens]);

	const qtdPavimentos = get(economia, 'pavimentos.length', 0);
	const isPrimeiraCelula = last(namePrefix) === '0';
	const rowSpan = isNaoResidencial ? qtdPavimentos : 1;
	const totalPrefix = isNaoResidencial ? economiaPrefix : namePrefix;
	const hidden = isNaoResidencial ? !isPrimeiraCelula : false;

	return (
		<>
			{cells}
			<td className="subtotal-aumento">
				{trocaPontoPorVirgula(get(localData, `${totalNamePrefix}.${namePrefix}.subTotalAumento`, '0,00'))}
			</td>
			<td className="subtotal-pavimento" rowSpan={rowSpan} hidden={hidden}>
				{trocaPontoPorVirgula(get(localData, `${totalNamePrefix}.${totalPrefix}.total`, '0,00'))}
			</td>
		</>
	);
}
AreasPavimento.displayName = 'AreasPavimento';
AreasPavimento.propTypes = {
	totalNamePrefix: PropTypes.string,
	pavimento: PropTypes.object,
	economia: PropTypes.object,
	namePrefix: PropTypes.string,
	economiaPrefix: PropTypes.string,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	readOnlyAreas: PropTypes.arrayOf(PropTypes.string),
	readOnlyMetragens: PropTypes.arrayOf(PropTypes.string),
	hideAreas: PropTypes.arrayOf(PropTypes.string),
	isProjetoNovo: PropTypes.bool,
	isNaoResidencial: PropTypes.bool
};

function AreasPavimentoResidencialNaoResidencial({
	pavimento,
	economia,
	namePrefix,
	totalNamePrefix,
	economiaPrefix,
	onChangeHandler,
	data: localData,
	readOnly,
	hideAreas,
	/** transforma em readonly um tipo inteiro de metragem (permanecer, construir, etc) */
	readOnlyMetragens,
	/** transforma em readonly um tipo área (adensavel, isenta, etc) */
	readOnlyAreas,
	isProjetoNovo
}) {
	// const subunidades = get(localData, 'planoDiretor.dadosSubunidades', []);
	// let isHabitacaoColetiva;
	// subunidades.forEach(sub => {
	// 	const atividades = get(sub, 'dadosAtividades', []);
	// 	isHabitacaoColetiva = atividades.some(ativ => get(ativ, 'atividadeAnexo52.item') === '1.2.');
	// });

	// celulas da tabela
	const cells = useMemo(() => {
		const AREAS = difference(TIPOS_AREAS, hideAreas);

		return AREAS.map(area => {
			/** somente leitura por alguma regra de negócio local */
			let readonlyRuleArea = false,
				readonlyRuleMt = false;

			if (readOnlyAreas && Array.isArray(readOnlyAreas)) {
				readonlyRuleArea = readOnlyAreas.includes(area);
			}

			return TIPOS_ECONOMIAS.flatMap(econo =>
				TIPOS_METRAGENS.map(metragem => {
					readonlyRuleMt = false;
					// volta a LIBERAR areas ad e nad para residencial... OP #59532
					if (readOnlyMetragens && Array.isArray(readOnlyMetragens)) {
						//Libera todas areas do existente a permanecer sempre que for aumento/diminuicao
						if (!isProjetoNovo && area === TIPOS_AREAS_ENUM.permanecer) {
							readonlyRuleMt = false;
						} else {
							readonlyRuleMt = readOnlyMetragens.includes(metragem);
						}
					}

					// desabilita areas ad e nad para residencial
					// if (
					// 	econo === TIPOS_ECONOMIAS_ENUM.residencial &&
					// 	(metragem === TIPOS_METRAGENS_ENUM.adensavel || metragem === TIPOS_METRAGENS_ENUM.naoAdensavel50) &&
					// 	!isHabitacaoColetiva
					// ) {
					// 	readonlyRuleMt = true;
					// }

					if (readonlyRuleArea || readonlyRuleMt) {
						set(pavimento, [area, econo, metragem, 'value'], '0');
					}

					return (
						<AreaCell
							key={`${namePrefix}.${area}.${econo}.${metragem}`}
							placeHolder="0,00"
							value={get(pavimento, [area, econo, metragem])}
							name={`${namePrefix}.${area}.${econo}.${metragem}`}
							onChangeHandler={onChangeHandler}
							readOnly={readOnly || readonlyRuleArea || readonlyRuleMt}
						/>
					);
				})
			);
		});
	}, [hideAreas, readOnlyAreas, readOnlyMetragens, namePrefix, pavimento, onChangeHandler, readOnly, isProjetoNovo]);

	const qtdPavimentos = get(economia, 'pavimentos.length', 0);
	const isPrimeiraCelula = last(namePrefix) === '0';

	return (
		<>
			{cells}
			<td className="subtotal-aumento">
				{trocaPontoPorVirgula(get(localData, `${totalNamePrefix}.${namePrefix}.subTotalConstruirRegularizar`, '0,00'))}
			</td>
			<td className="subtotal-pavimento" rowSpan={qtdPavimentos} hidden={!isPrimeiraCelula}>
				{trocaPontoPorVirgula(get(localData, `${totalNamePrefix}.${economiaPrefix}.total`, '0,00'))}
			</td>
		</>
	);
}
AreasPavimentoResidencialNaoResidencial.displayName = 'AreasPavimentoResidencialNaoResidencial';
AreasPavimentoResidencialNaoResidencial.propTypes = {
	totalNamePrefix: PropTypes.string,
	pavimento: PropTypes.object,
	economia: PropTypes.object,
	namePrefix: PropTypes.string,
	economiaPrefix: PropTypes.string,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	readOnlyAreas: PropTypes.arrayOf(PropTypes.string),
	readOnlyMetragens: PropTypes.arrayOf(PropTypes.string),
	hideAreas: PropTypes.arrayOf(PropTypes.string),
	isProjetoNovo: PropTypes.bool
};

/**
 * componente local
 */
function RowsTotais({ totais, colSpan = '3', colSpanPermanecer = 3, colSpanConstruir = 6 }) {
	const AREAS = useMemo(() => TIPOS_AREAS, []);

	/** react elements com as celulas da tabela da linha sub totais*/
	const subTotaisCells = useMemo(() => {
		const cellsSubTotais = AREAS.map(keyOperacao =>
			TIPOS_METRAGENS.map(keyMetragem => {
				const tval = get(totais, [keyOperacao, keyMetragem, 'subTotal'], '0.00');
				return (
					<td key={`${keyOperacao}.${keyMetragem}`} className="subtotais">
						{trocaPontoPorVirgula(tval)}
					</td>
				);
			})
		);

		return (
			<>
				{cellsSubTotais}
				<td className="subtotal-aumento">{trocaPontoPorVirgula(get(totais, ['totalAumento'], '0,00'))}</td>
				<td className="subtotal-pavimento realce">{trocaPontoPorVirgula(get(totais, ['totalArea'], '0,00'))}</td>
			</>
		);
	}, [AREAS, totais]);

	/** react elements com as celulas da tabela da linha sub total*/
	// eslint-disable-next-line no-unused-vars
	const subTotalCells = useMemo(() => {
		const cellsTotais = AREAS.map((keyOperacao, i) => (
			<td colSpan={TIPOS_METRAGENS.length} key={`${keyOperacao}_${i}`} className="subtotal">
				{trocaPontoPorVirgula(get(totais, [keyOperacao, 'subTotal'], '0,00'))}
			</td>
		));

		return (
			<>
				{cellsTotais}
				<td className="subtotal" title="demolir">
					{trocaPontoPorVirgula(get(totais, [TIPOS_AREAS_ENUM.demolir, 'subTotal'], '0,00'))}
				</td>
				<td className="subtotal" title="permanecer">
					{trocaPontoPorVirgula(get(totais, [TIPOS_AREAS_ENUM.permanecer, 'subTotal'], '0,00'))}
				</td>
			</>
		);
	}, [AREAS, totais]);

	/** react elements com as celulas da tabela da linha total*/
	const totalCells = useMemo(() => {
		const totalDP = trocaPontoPorVirgula(get(totais, ['totalPermanecer'], '0,00'));
		const totalCR = trocaPontoPorVirgula(get(totais, ['totalConstruirRegularizar'], '0,00'));
		const totalFinal = trocaPontoPorVirgula(get(totais, ['total'], '0,00'));
		return (
			<>
				<td className="total-geral" colSpan={colSpanPermanecer}>
					{totalDP}
				</td>
				<td className="total-geral" colSpan={colSpanConstruir}>
					{totalCR}
				</td>
				<td className="total-geral realce" colSpan="2">
					{totalFinal}
				</td>
			</>
		);
	}, [colSpanConstruir, colSpanPermanecer, totais]);

	return (
		<>
			<tr>
				<td className="subtotais celula-subtotais" colSpan={colSpan}>
					Sub-Totais (m²)
				</td>
				{subTotaisCells}
			</tr>
			<tr>
				<td className="total-geral celula-total" colSpan={colSpan}>
					<strong>Total (m²)</strong>
				</td>
				{totalCells}
			</tr>
		</>
	);
}
RowsTotais.displayName = 'RowsTotais';
RowsTotais.propTypes = {
	colSpan: PropTypes.string,
	totais: PropTypes.object,
	rowSpan: PropTypes.string,
	hideDemolir: PropTypes.bool,
	colSpanPermanecer: PropTypes.number,
	colSpanConstruir: PropTypes.number
};

/**
 * componente local
 */
function RowsTotaisResidencialNaoResidencial({ totais, colSpan = '3', colSpanPermanecer = 6, colSpanConstruir = 12 }) {
	const AREAS = useMemo(() => TIPOS_AREAS, []);

	/** react elements com as celulas da tabela da linha sub totais*/
	const subTotaisCells = useMemo(() => {
		const cellsSubTotais = AREAS.map(area =>
			TIPOS_ECONOMIAS.flatMap(resid =>
				TIPOS_METRAGENS.map(metragem => {
					const tval = get(totais, [area, resid, metragem, 'subTotal'], '0.00');
					return (
						<td key={`${area}.${resid}.${metragem}`} className="subtotais">
							{trocaPontoPorVirgula(tval)}
						</td>
					);
				})
			)
		);

		return (
			<>
				{cellsSubTotais}
				<td className="subtotal-aumento">{trocaPontoPorVirgula(get(totais, ['totalConstruirRegularizar'], '0,00'))}</td>
				<td className="subtotal-pavimento realce">{trocaPontoPorVirgula(get(totais, ['totalArea'], '0,00'))}</td>
			</>
		);
	}, [AREAS, totais]);

	/** react elements com as celulas da tabela da linha sub total*/
	const subTotalCells = useMemo(() => {
		const cellsTotais = AREAS.map((area, i) =>
			TIPOS_ECONOMIAS.map(eco => (
				<td colSpan={3} key={`${area}.${i}.${eco}`} className="subtotais">
					{trocaPontoPorVirgula(get(totais, [area, eco, 'subTotal'], '0,00'))}
				</td>
			))
		);

		return (
			<>
				{cellsTotais}
				<td colSpan={2} className="subtotais"></td>
			</>
		);
	}, [AREAS, totais]);

	/** react elements com as celulas da tabela da linha total*/
	const totalCells = useMemo(() => {
		const totalDP = trocaPontoPorVirgula(get(totais, ['totalPermanecer'], '0,00'));
		const totalCR = trocaPontoPorVirgula(get(totais, ['totalConstruirRegularizar'], '0,00'));
		const totalFinal = trocaPontoPorVirgula(get(totais, ['total'], '0,00'));
		return (
			<>
				<td className="total-geral" colSpan={colSpanPermanecer}>
					{totalDP}
				</td>
				<td className="total-geral" colSpan={colSpanConstruir}>
					{totalCR}
				</td>
				<td className="total-geral realce" colSpan="2">
					{totalFinal}
				</td>
			</>
		);
	}, [colSpanConstruir, colSpanPermanecer, totais]);

	return (
		<>
			<tr>
				<td className="subtotais celula-subtotais" colSpan={colSpan} rowSpan="2">
					Sub-Totais (m²)
				</td>
				{subTotaisCells}
			</tr>
			<tr>{subTotalCells}</tr>
			<tr>
				<td className="total-geral celula-total" colSpan={colSpan}>
					<strong>Total (m²)</strong>
				</td>
				{totalCells}
			</tr>
		</>
	);
}
RowsTotaisResidencialNaoResidencial.displayName = 'RowsTotaisResidencialNaoResidencial';
RowsTotaisResidencialNaoResidencial.propTypes = {
	colSpan: PropTypes.string,
	totais: PropTypes.object,
	rowSpan: PropTypes.string,
	hideDemolir: PropTypes.bool,
	colSpanPermanecer: PropTypes.number,
	colSpanConstruir: PropTypes.number
};

export function TabelaAreasLE({ data: localData, name, onChangeHandler, tipoForm, isProjetoNovo }) {
	/** @type {DetalhesAreas} */
	const detalhesAreas = get(localData, name, {});
	// tipos forms
	const { UNIFAMILIAR_02_ECONO, UNIFAMILIAR_01_ECONO, UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES, NAO_RESIDENCIAL } =
		LE_Enquadramentos;
	// tipo areas
	const { demolir, permanecer } = TIPOS_AREAS_ENUM;
	// tipo metragens
	const { adensavel, naoAdensavel50 } = TIPOS_METRAGENS_ENUM;

	/** libera adicionar 'n' economias */
	const isMaisDeUmaEconomias = UNIFAMILIAR_02_ECONO === tipoForm;

	const isNaoResidencial = UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES === tipoForm || NAO_RESIDENCIAL === tipoForm;

	/** coluna demolir talvez possa ser removida mas como tudo pode mudar... melhor só esconder */
	const hideDemolir = true;

	const isUnifamiliarUmOuDois = [
		UNIFAMILIAR_01_ECONO,
		UNIFAMILIAR_02_ECONO,
		UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	].includes(tipoForm);

	const isUnifamiliarDuasEconomiasNaoResidencial = tipoForm === UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES;
	const isUniFamiliarDuasEconomias = tipoForm === UNIFAMILIAR_02_ECONO;

	// metragens que devem ficar readonly na tabela
	const metragensReadonly =
		isUnifamiliarUmOuDois && !(localData?.infosProjeto?.projetoAtividade?.item === ITEM_HABITACAO_COLETIVA)
			? [adensavel, naoAdensavel50]
			: NAO_RESIDENCIAL === tipoForm && get(localData, `${INFOSPROJETO_CONTROLNAME}.trintaPorcentoIa`) === 'sim'
				? [naoAdensavel50]
				: UNIFAMILIAR_01_ECONO === tipoForm && get(localData, `${INFOSPROJETO_CONTROLNAME}.trintaPorcentoIa`) === 'sim'
					? [naoAdensavel50]
					: undefined;

	// areas que devem ficar readonly na tabela
	const areasReadonly = isProjetoNovo ? [permanecer] : undefined;

	// states
	const [tabelaRecalculada, setTabelaRecalculada] = useState(true);
	const [tipoFormAnterior, setTipoFormAnterior] = useState(tipoForm);
	const [isProjetoNovoAnterior, setIsProjetoNovoAnterior] = useState(isProjetoNovo);

	/** calcula os totais por linha/coluna */
	const calcularTotais = useCallback(
		detalhes => {
			// areas somente construir e regularizar
			const AREAS_CONSTRUIR_REGULARIZAR = [TIPOS_AREAS_ENUM.construir, TIPOS_AREAS_ENUM.regularizar];
			// areas somente permanecer e demolir
			const AREAS_PERMANCER_DEMOLIR = [TIPOS_AREAS_ENUM.permanecer, TIPOS_AREAS_ENUM.demolir];

			// subtotais para cada
			// tipoPavimento e pavimento
			// produz a linha 'sub-totais'
			// e as colunas 'sub-total aumento', 'total'
			const tempTotais = {};
			forEach(get(detalhes, ['economias']), (econo, econoIndex) => {
				// somatorio permanecerDemolir da economia
				const pathEconomia = ['totais', 'economias', econoIndex];
				set(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir'], '0');
				// somatorio construir/regularizar da economia
				set(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar'], '0');

				forEach(get(econo, 'pavimentos'), (pav, pavIndex) => {
					// properties path caminho
					const pathPavimento = ['totais', 'economias', econoIndex, 'pavimentos', pavIndex];

					// calcula os subtotais de cada coluna
					// linha: sub-totais
					// coluna total
					if (isUnifamiliarDuasEconomiasNaoResidencial) {
						TIPOS_AREAS.forEach(area =>
							TIPOS_ECONOMIAS.forEach(resid =>
								TIPOS_METRAGENS.forEach(metragem => {
									const valInformado = get(pav, [area, resid, metragem, 'value'], 0);
									const valCalculandoSubTotal = get(tempTotais, ['totais', area, resid, metragem, 'subTotal'], 0);
									const subtot = soma(valInformado, valCalculandoSubTotal);
									// detalhesAreas.totais.permanecer.residencial.mtAdensavel.subTotal = valor;
									set(tempTotais, ['totais', area, resid, metragem, 'subTotal'], subtot);

									// aproveita o loop e calcula a coluna Total
									const valCalculandoTotal = get(tempTotais, [...pathPavimento, 'total'], 0);
									let valTotalColuna;
									if (area === TIPOS_AREAS_ENUM.demolir) {
										valTotalColuna = diferenca(valCalculandoTotal, valInformado);
									} else {
										valTotalColuna = soma(valInformado, valCalculandoTotal);
									}
									// detalhesAreas.totais.economias[0].pavimentos[0].total = valor;
									set(tempTotais, [...pathPavimento, 'total'], valTotalColuna);
								})
							)
						);
					} else {
						TIPOS_AREAS.forEach(area =>
							TIPOS_METRAGENS.forEach(metragem => {
								const valInformado = get(pav, [area, metragem, 'value'], 0);
								const valCalculandoSubTotal = get(tempTotais, ['totais', area, metragem, 'subTotal'], 0);
								const subtot = soma(valInformado, valCalculandoSubTotal);
								// detalhesAreas.totais.permanecer.mtAdensavel.subTotal = valor;
								set(tempTotais, ['totais', area, metragem, 'subTotal'], subtot);

								// aproveita o loop e calcula a coluna Total
								const valCalculandoTotal = get(tempTotais, [...pathPavimento, 'total'], 0);
								let valTotalColuna;
								if (area === TIPOS_AREAS_ENUM.demolir) {
									valTotalColuna = diferenca(valCalculandoTotal, valInformado);
								} else {
									valTotalColuna = soma(valInformado, valCalculandoTotal);
								}
								// detalhesAreas.totais.economias[0].pavimentos[0].total = valor;
								set(tempTotais, [...pathPavimento, 'total'], valTotalColuna);
							})
						);
					}

					// soma apenas construir e regularizar
					// coluna sub-total [aumento]
					if (isUnifamiliarDuasEconomiasNaoResidencial) {
						AREAS_CONSTRUIR_REGULARIZAR.forEach(area =>
							TIPOS_ECONOMIAS.forEach(resid =>
								TIPOS_METRAGENS.forEach(metragem => {
									const valInformado = get(pav, [area, resid, metragem, 'value'], 0);
									let subtot = '0.0';
									// geral por economia
									const valTempEcono = get(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar'], 0);
									subtot = soma(valInformado, valTempEcono);
									set(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar'], subtot);
									// somatorio de cada pavimento
									const valConstReg = get(tempTotais, [...pathPavimento, 'subTotalConstruirRegularizar'], 0);
									subtot = soma(valInformado, valConstReg);
									set(tempTotais, [...pathPavimento, 'subTotalConstruirRegularizar'], subtot);
									// aumento do pavimento
									const valAumento = get(tempTotais, [...pathPavimento, 'subTotalAumento'], 0);
									subtot = isProjetoNovo ? '0.0' : soma(valInformado, valAumento);
									set(tempTotais, [...pathPavimento, 'subTotalAumento'], subtot);
									// aumento por economia
									const valAumentoEcono = get(tempTotais, [...pathEconomia, 'subTotalAumento'], 0);
									subtot = isProjetoNovo ? '0.0' : soma(valInformado, valAumentoEcono);
									set(tempTotais, [...pathEconomia, 'subTotalAumento'], subtot);
								})
							)
						);
					} else {
						AREAS_CONSTRUIR_REGULARIZAR.forEach(area => {
							TIPOS_METRAGENS.forEach(metragem => {
								const valInformado = get(pav, [area, metragem, 'value'], 0);
								let subtot = '0.0';
								// somatorio de cada pavimento
								const valConstReg = get(tempTotais, [...pathPavimento, 'subTotalConstruirRegularizar'], 0);
								subtot = soma(valInformado, valConstReg);
								set(tempTotais, [...pathPavimento, 'subTotalConstruirRegularizar'], subtot);
								// coluna aumento do pavimento
								const valAumento = get(tempTotais, [...pathPavimento, 'subTotalAumento'], 0);
								subtot = isProjetoNovo ? '0.0' : soma(valInformado, valAumento);
								set(tempTotais, [...pathPavimento, 'subTotalAumento'], subtot);
								// somatorio por economia
								const valConstRegEcono = get(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar'], 0);
								subtot = soma(valInformado, valConstRegEcono);
								set(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar'], subtot);
								// aumento por economia
								const valAumentoEcono = get(tempTotais, [...pathEconomia, 'subTotalAumento'], 0);
								subtot = isProjetoNovo ? '0.0' : soma(valInformado, valAumentoEcono);
								set(tempTotais, [...pathEconomia, 'subTotalAumento'], subtot);
							});
						});
					}

					// soma e diferenca entre permanecer e demolir
					// não exibe, apenas cálculo auxiliar para a tabela resumo
					if (isUnifamiliarDuasEconomiasNaoResidencial) {
						AREAS_PERMANCER_DEMOLIR.forEach(area => {
							TIPOS_ECONOMIAS.forEach(resid =>
								TIPOS_METRAGENS.forEach(metragem => {
									const valInformado = get(pav, [area, resid, metragem, 'value'], 0);
									const valTemp = get(tempTotais, [...pathPavimento, 'subTotalPermanecer'], 0);
									let subtot;
									if (area === TIPOS_AREAS_ENUM.demolir) {
										subtot = diferenca(valTemp, valInformado);
									} else {
										subtot = soma(valInformado, valTemp);
									}
									// detalhesAreas.totais.economias[0].pavimentos[0].subTotalPermanecer = valor;
									set(tempTotais, [...pathPavimento, 'subTotalPermanecer'], subtot);
									// geral por economia
									const valTempEcono = get(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir'], 0);
									subtot = soma(valTempEcono, valInformado);
									set(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir'], subtot);
								})
							);
						});
					} else {
						AREAS_PERMANCER_DEMOLIR.forEach(area => {
							TIPOS_METRAGENS.forEach(metragem => {
								const valInformado = get(pav, [area, metragem, 'value'], 0);
								const valTemp = get(tempTotais, [...pathPavimento, 'subTotalPermanecer'], 0);
								let subtot;
								if (area === TIPOS_AREAS_ENUM.demolir) {
									subtot = diferenca(valTemp, valInformado);
								} else {
									subtot = soma(valInformado, valTemp);
								}
								// detalhesAreas.totais.economias[0].pavimentos[0].subTotalPermanecer = valor;
								set(tempTotais, [...pathPavimento, 'subTotalPermanecer'], subtot);
								// geral por economia
								const valTempEcono = get(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir'], 0);
								subtot = soma(valTempEcono, valInformado);
								set(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir'], subtot);
							});
						});
					}
					// somatorio do aumento
					const valSubAumento = get(tempTotais, [...pathPavimento, 'subTotalAumento'], 0);
					const valTotalAumento = get(tempTotais, ['totais', 'totalAumento'], 0);
					const totalAumento = soma(valTotalAumento, valSubAumento);
					set(tempTotais, ['totais', 'totalAumento'], totalAumento);
					// somatório total da coluna total por pavimento
					const valTot = get(tempTotais, [...pathPavimento, 'total'], 0);
					const valTotalArea = get(tempTotais, ['totais', 'totalArea'], 0);
					const totalArea = soma(valTotalArea, valTot);
					set(tempTotais, ['totais', 'totalArea'], totalArea);
				}); // fim foreach pavimento

				// somatorio dos dois subtotais da economia
				const v1 = get(tempTotais, [...pathEconomia, 'subTotalPermanecerDemolir']);
				const v2 = get(tempTotais, [...pathEconomia, 'subTotalConstruirRegularizar']);
				const vt = soma(v1, v2);
				set(tempTotais, [...pathEconomia, 'total'], vt);
			}); // fim foreach economia

			// subtotal por tipo de área (demolir, permanecer, etc)
			let subTotalMtPorArea = '0';
			if (isUnifamiliarDuasEconomiasNaoResidencial) {
				TIPOS_AREAS.forEach(area => {
					let subTotalMtPorEconomia = '0';
					TIPOS_ECONOMIAS.forEach(resid => {
						TIPOS_METRAGENS.forEach(metragem => {
							const subTotal = get(tempTotais, ['totais', area, resid, metragem, 'subTotal'], 0);
							// subtotal por área
							subTotalMtPorArea = soma(subTotal, subTotalMtPorArea);
							// subtotal por area/tipo economia (residential nao residencial)
							subTotalMtPorEconomia = soma(subTotal, subTotalMtPorEconomia);

							// total da metragem (cálculo extra informativo)
							let tempTotalMt = get(tempTotais, ['totais', metragem, 'total'], 0);
							tempTotalMt = soma(subTotal, tempTotalMt);
							set(tempTotais, ['totais', metragem, 'total'], tempTotalMt);
							// total da metragem por tipo residencia
							let tempTotalMtRes = get(tempTotais, ['totais', resid, metragem, 'total'], 0);
							tempTotalMtRes = soma(tempTotalMtRes, subTotal);
							set(tempTotais, ['totais', resid, metragem, 'total'], tempTotalMtRes);
						});
						// subtotal da area por tipo de economia
						// detalhesAreas.totais.permanecer.residencial.total = total
						set(tempTotais, ['totais', area, resid, 'subTotal'], subTotalMtPorEconomia);
						// area total por residencia
						const areaTotalResidencia = get(tempTotais, ['totais', resid, 'total'], 0);
						set(tempTotais, ['totais', resid, 'total'], soma(areaTotalResidencia, subTotalMtPorEconomia));
						// zera para a proxima
						subTotalMtPorEconomia = '0';
					});
					// subtotal da area
					// detalhesAreas.totais.permanecer.subTotal = total
					set(tempTotais, ['totais', area, 'subTotal'], subTotalMtPorArea);
					subTotalMtPorArea = '0';
				});
			} else {
				TIPOS_AREAS.forEach(area => {
					TIPOS_METRAGENS.forEach(metragem => {
						const subTotal = get(tempTotais, ['totais', area, metragem, 'subTotal'], 0);
						// subtotal da área
						subTotalMtPorArea = soma(subTotal, subTotalMtPorArea);
						// total da metragem (cálculo extra informativo)
						let tempTotalMt = get(tempTotais, ['totais', metragem, 'total'], 0);
						tempTotalMt = soma(subTotal, tempTotalMt);
						set(tempTotais, ['totais', metragem, 'total'], tempTotalMt);
					});
					// detalhesAreas.totais.permanecer.subTotal = total
					set(tempTotais, ['totais', area, 'subTotal'], subTotalMtPorArea);
					subTotalMtPorArea = '0';
				});
			}
			// somatório total por grupo de área
			// linha: TOTAL
			// total permanecer e demolir
			const subTotalPerm = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.permanecer, 'subTotal'], 0);
			const subTotalDem = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.demolir, 'subTotal'], 0);
			const tempPD = diferenca(subTotalPerm, subTotalDem);
			set(tempTotais, ['totais', 'totalPermanecer'], tempPD);
			// total construir e regularizar
			const subTotalConst = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.construir, 'subTotal'], 0);
			const subTotalRegu = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.regularizar, 'subTotal'], 0);
			let tempTotalGrupos = soma(subTotalConst, subTotalRegu);
			set(tempTotais, ['totais', 'totalConstruirRegularizar'], tempTotalGrupos);

			// somatorio total geral das areas
			const tempTPD = get(tempTotais, ['totais', 'totalPermanecer'], 0);
			const tempTCR = get(tempTotais, ['totais', 'totalConstruirRegularizar'], 0);
			let tempTotalGeral = soma(tempTPD, tempTCR);
			set(tempTotais, ['totais', 'total'], tempTotalGeral);

			// somatorio de areas existentes (inclui demolir informado manualmente fora da tabela)
			if (!isUniFamiliarDuasEconomias && !isUnifamiliarDuasEconomiasNaoResidencial) {
				const tempTMTD = get(detalhes, ['totaisManuais', 'areaTotalDemolir', 'value'], 0);
				let tempTotExistente = soma(tempTPD, tempTMTD);
				set(tempTotais, ['totais', 'areaExistenteTotalPermanecerDemolir'], tempTotExistente);
			}

			// somatorios para a unifamiliar + nao residencial
			if (isUnifamiliarDuasEconomiasNaoResidencial) {
				const { residencial, naoResidencial } = TIPOS_ECONOMIAS_ENUM;
				// permanecer + demolir
				let subTotal = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.permanecer, residencial, 'subTotal'], 0);
				set(tempTotais, ['totais', residencial, 'totalPermanecer'], subTotal);

				let areaDemolir = get(detalhes, ['totaisManuais', 'areaTotalDemolirResidencial', 'value'], 0);
				const totalExistenteRes = soma(subTotal, areaDemolir);
				set(tempTotais, ['totais', residencial, 'areaExistenteTotalPermanecerDemolir'], totalExistenteRes);

				subTotal = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.permanecer, naoResidencial, 'subTotal']);
				areaDemolir = get(detalhes, ['totaisManuais', 'areaTotalDemolirNaoResidencial', 'value'], 0);
				const totalExistenteNRes = soma(subTotal, areaDemolir);
				set(tempTotais, ['totais', naoResidencial, 'areaExistenteTotalPermanecerDemolir'], totalExistenteNRes);

				const total = soma(totalExistenteNRes, totalExistenteRes);
				set(tempTotais, ['totais', 'areaExistenteTotalPermanecerDemolir'], total);
				// construir regularizar
				let c = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.construir, residencial, 'subTotal']);
				let p = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.regularizar, residencial, 'subTotal']);
				set(tempTotais, ['totais', residencial, 'totalConstruirRegularizar'], soma(c, p));

				c = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.construir, naoResidencial, 'subTotal']);
				p = get(tempTotais, ['totais', TIPOS_AREAS_ENUM.regularizar, naoResidencial, 'subTotal']);
				set(tempTotais, ['totais', naoResidencial, 'totalConstruirRegularizar'], soma(c, p));
			}

			// somatorio de area existente para mais de uma economia
			if (isMaisDeUmaEconomias) {
				// casa 01 existente
				const vp1 = get(tempTotais, ['totais', 'economias', '0', 'subTotalPermanecerDemolir'], '0');
				const vd1 = get(detalhes, ['totaisManuais', 'areaTotalDemolirEconomia01', 'value'], '0');
				const totalExistente = soma(vp1, vd1);
				set(tempTotais, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia01'], totalExistente);
				// casa 02 existente
				const vp2 = get(tempTotais, ['totais', 'economias', '1', 'subTotalPermanecerDemolir'], '0');
				const vd2 = get(detalhes, ['totaisManuais', 'areaTotalDemolirEconomia02', 'value'], '0');
				const totalExistente2 = soma(vp2, vd2);
				set(tempTotais, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia02'], totalExistente2);
				// casa 01 & 02 existente
				const totalExistente0102 = soma(totalExistente, totalExistente2);
				set(tempTotais, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia0102'], totalExistente0102);
			}

			return tempTotais;
		},
		[isMaisDeUmaEconomias, isProjetoNovo, isUniFamiliarDuasEconomias, isUnifamiliarDuasEconomiasNaoResidencial]
	);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}
			const { name: controlName, value } = changes;

			if (controlName === 'errors') return;

			// set novo valor
			set(detalhesAreas, controlName, value);

			//totais
			const { totais } = calcularTotais(detalhesAreas);
			// console.log(totais);
			const tempTotais = detalhesAreas?.totais;
			// change handler
			let tempTable = Object.assign({}, detalhesAreas, { totais: { ...tempTotais, ...totais } });
			onChangeHandler && onChangeHandler({ name, value: tempTable });
		},
		[calcularTotais, detalhesAreas, name, onChangeHandler]
	);

	const addPavimento = useCallback(
		({ tipoPavimentoIndex: index }) => {
			const { economias = [] } = detalhesAreas;
			const economia = economias[index];
			if (economia) {
				const { pavimentos = [] } = economia;
				economia.pavimentos = [...pavimentos, {}];
			}
			localChangeHandler([{ name: 'economias', value: [...economias] }]);
		},
		[detalhesAreas, localChangeHandler]
	);

	const deletePavimento = useCallback(
		({ tipoPavimentoIndex, pavimentoIndex }) => {
			const { economias = [] } = detalhesAreas;
			const economia = economias[tipoPavimentoIndex];
			if (economia) {
				const { pavimentos = [] } = economia;
				pavimentos.splice(pavimentoIndex, 1);
				if (pavimentos.length === 0) {
					economias.splice(tipoPavimentoIndex, 1);
				}
				economia.pavimentos = [...pavimentos];
			}
			localChangeHandler([{ name: 'economias', value: [...economias] }]);
		},
		[detalhesAreas, localChangeHandler]
	);

	const addEconomia = useCallback(
		nomeEconomia => {
			const { economias = [] } = detalhesAreas;
			const novaEconomia = nomeEconomia ? { nomeEconomia: nomeEconomia, pavimentos: [{}] } : { pavimentos: [{}] };
			if (isMaisDeUmaEconomias && economias.length < 2) {
				economias.push(novaEconomia);
				localChangeHandler([{ name: 'economias', value: [...economias] }]);
			} else if (isNaoResidencial || economias.length === 0) {
				economias.push(novaEconomia);
				localChangeHandler([{ name: 'economias', value: [...economias] }]);
			} else if (economias.length === 1) {
				addPavimento({ tipoPavimentoIndex: 0 });
			}
		},
		[addPavimento, detalhesAreas, isMaisDeUmaEconomias, isNaoResidencial, localChangeHandler]
	);

	useEffect(() => {
		if (isProjetoNovo !== isProjetoNovoAnterior) {
			setTabelaRecalculada(false);
			setIsProjetoNovoAnterior(isProjetoNovo);
		}
	}, [isProjetoNovo, isProjetoNovoAnterior]);

	useEffect(() => {
		if (tipoForm !== tipoFormAnterior) {
			setTabelaRecalculada(false);
			setTipoFormAnterior(tipoForm);
		}
	}, [tipoFormAnterior, tipoForm]);

	useEffect(() => {
		if (!tabelaRecalculada) {
			localChangeHandler({});
			setTabelaRecalculada(true);
		}
	}, [tabelaRecalculada, localChangeHandler]);

	const isNomesEconCorretos =
		get(detalhesAreas, 'economias.0.nomeEconomia') === 'Economia 01' &&
		get(detalhesAreas, 'economias.1.nomeEconomia') === 'Economia 02';

	// Forca o preenchimento de 2 economias sempre que for esse o enquadramento
	// Se ja tem economias, utiliza. Se tiver mais de 2, descarta
	useEffect(() => {
		if (isUniFamiliarDuasEconomias && size(detalhesAreas?.economias) < 1) {
			addEconomia('Economia 01');
			addEconomia('Economia 02');
		} else if (isUniFamiliarDuasEconomias && size(detalhesAreas?.economias) === 1) {
			let economiaEditada = { ...detalhesAreas?.economias[0] };
			economiaEditada.nomeEconomia = 'Economia 01';
			let newEconomias = [];
			newEconomias.push(economiaEditada);
			newEconomias.push({ nomeEconomia: 'Economia 02', pavimentos: [{}] });
			localChangeHandler([{ name: 'economias', value: [...newEconomias] }]);
		} else if (isUniFamiliarDuasEconomias && size(detalhesAreas?.economias) > 1 && !isNomesEconCorretos) {
			let economiaEditada1 = { ...detalhesAreas?.economias[0] };
			economiaEditada1.nomeEconomia = 'Economia 01';
			let economiaEditada2 = { ...detalhesAreas?.economias[1] };
			economiaEditada2.nomeEconomia = 'Economia 02';
			let newEconomias = [];
			newEconomias.push(economiaEditada1);
			newEconomias.push(economiaEditada2);
			localChangeHandler([{ name: 'economias', value: [...newEconomias] }]);
		}
	}, [isUniFamiliarDuasEconomias, detalhesAreas, addEconomia, localChangeHandler, isNomesEconCorretos]);

	/**
	 * @param {Economia} econom
	 * @param {number} tpIndex
	 */
	// eslint-disable-next-line react/display-name
	const mapEconomias = (econom = {}, tpIndex) => {
		const { pavimentos = [], nomeEconomia } = econom;
		const qtdPavimentos = size(pavimentos);
		const economiaPrefix = `economias.${tpIndex}`;

		let rows = pavimentos.map((pav, pavIndex) => {
			const isFirstRow = pavIndex === 0;
			const pavimentoPrefix = `economias.${tpIndex}.pavimentos.${pavIndex}`;

			return (
				<tr key={`${tpIndex}_${pavIndex}`}>
					{isMaisDeUmaEconomias && isFirstRow && (
						<>
							<td className="cell-input celula-nome-pavimento" rowSpan={qtdPavimentos}>
								<div className="celula-nome-2eco" rowSpan="2">
									{nomeEconomia}
								</div>
								<button
									type="button"
									title="adicionar pavimento"
									className="btn btn-outline-primary btn-sm btn-tabela-areas"
									onClick={() => addPavimento({ tipoPavimentoIndex: tpIndex })}
								>
									<i className="fa fa-plus"></i> Pavimento
								</button>
							</td>
						</>
					)}
					{isNaoResidencial && isFirstRow && (
						<>
							<td className="cell-input celula-nome-pavimento" rowSpan={qtdPavimentos}>
								<TextAreaField
									placeHolder="Nome do pavimento"
									value={nomeEconomia}
									name={`economias.${tpIndex}.nomeEconomia`}
									onChangeHandler={localChangeHandler}
									rows="2"
									disabled={false}
								/>
								<button
									type="button"
									title="adicionar pavimento"
									className="btn btn-outline-primary btn-sm btn-tabela-areas"
									onClick={() => addPavimento({ tipoPavimentoIndex: tpIndex })}
								>
									<i className="fa fa-plus"></i> Natureza / Espécie
								</button>
							</td>
						</>
					)}
					<td
						className={`cell-button ${isMaisDeUmaEconomias
							? 'celula-excluir-2eco'
							: isNaoResidencial
								? 'celula-excluir'
								: 'celula-excluir-simples'
							}`}
					>
						<button
							type="button"
							title="remover linha"
							className="btn btn-trash-tabela-areas btn-sm"
							onClick={() => deletePavimento({ tipoPavimentoIndex: tpIndex, pavimentoIndex: pavIndex })}
							disabled={isUniFamiliarDuasEconomias && qtdPavimentos === 1}
						>
							<i className="fa fa-trash"></i>
						</button>
					</td>
					<td
						className={`cell-input ${isMaisDeUmaEconomias
							? 'celula-descricao-2eco'
							: isNaoResidencial
								? 'celula-descricao'
								: 'celula-descricao-simples'
							}`}
						colSpan={isMaisDeUmaEconomias || isNaoResidencial ? '1' : '2'}
					>
						<TextAreaField
							value={pav?.descricao}
							name={`${pavimentoPrefix}.descricao`}
							placeHolder={isNaoResidencial ? 'descrição da natureza / espécie' : 'descrição do pavimento'}
							onChangeHandler={localChangeHandler}
							style={
								size(pav?.descricao) < 1
									? { lineHeight: '12px', padding: '0', height: '36px', fontSize: '12px', fontStyle: 'italic' }
									: {}
							}
						/>
					</td>
					{isUnifamiliarDuasEconomiasNaoResidencial ? (
						<AreasPavimentoResidencialNaoResidencial
							data={localData}
							pavimento={pav}
							economia={econom}
							namePrefix={pavimentoPrefix}
							totalNamePrefix={`${name}.totais`}
							economiaPrefix={economiaPrefix}
							onChangeHandler={localChangeHandler}
							readOnlyAreas={isProjetoNovo ? [permanecer] : undefined}
							hideAreas={hideDemolir ? [demolir] : undefined}
							isProjetoNovo={isProjetoNovo}
						/>
					) : (
						<AreasPavimento
							data={localData}
							pavimento={pav}
							economia={econom}
							isNaoResidencial={isNaoResidencial}
							namePrefix={pavimentoPrefix}
							totalNamePrefix={`${name}.totais`}
							economiaPrefix={economiaPrefix}
							onChangeHandler={localChangeHandler}
							readOnlyAreas={areasReadonly}
							readOnlyMetragens={metragensReadonly}
							hideAreas={hideDemolir ? [demolir] : undefined}
							isProjetoNovo={isProjetoNovo}
						/>
					)}
				</tr>
			);
		});

		return rows;
	};

	return (
		<div className="table-responsive">
			<table className="table table-sm table-habitacao" id="table-areas">
				<thead>
					<tr>
						<th
							colSpan="3"
							className="celula-descricao-principal"
							rowSpan={isUniFamiliarDuasEconomias || isUnifamiliarDuasEconomiasNaoResidencial ? 2 : 1}
						>
							Descrição
						</th>
						<th colSpan={isUnifamiliarDuasEconomiasNaoResidencial ? 6 : 3}>Existente a Permanecer (m²)</th>
						<th colSpan={isUnifamiliarDuasEconomiasNaoResidencial ? 6 : 3}>Construir (m²)</th>
						<th colSpan={isUnifamiliarDuasEconomiasNaoResidencial ? 6 : 3}>Regularizar (m²)</th>
						<th className="subtotal-aumento" rowSpan={isUnifamiliarDuasEconomiasNaoResidencial ? 3 : 2}>
							Sub-Total [Aumento] (m²)
						</th>
						<th className="subtotal-pavimento" rowSpan={isUnifamiliarDuasEconomiasNaoResidencial ? 3 : 2}>
							Total (m²)
						</th>
					</tr>
					{isUnifamiliarDuasEconomiasNaoResidencial && (
						<tr>
							<th colSpan="3">Residencial</th>
							<th colSpan="3">Não Residencial</th>
							<th colSpan="3">Residencial</th>
							<th colSpan="3">Não Residencial</th>
							<th colSpan="3">Residencial</th>
							<th colSpan="3">Não Residencial</th>
						</tr>
					)}
					<tr>
						{!isUniFamiliarDuasEconomias && (
							<th colSpan="3" className="celula-adicionar">
								<button
									type="button"
									className="btn btn-outline-primary btn-sm btn-tabela-areas"
									onClick={() => addEconomia()}
								>
									<i className="fa fa-plus"></i> {isMaisDeUmaEconomias ? 'economia' : 'pavimento'}
								</button>
							</th>
						)}
						<th>Ad (m²)</th>
						<th>Nad (m²)</th>
						<th>Isenta (m²)</th>
						<th>Ad (m²)</th>
						<th>Nad (m²)</th>
						<th>Isenta (m²)</th>
						<th>Ad (m²)</th>
						<th>Nad (m²)</th>
						<th>Isenta (m²)</th>
						{isUnifamiliarDuasEconomiasNaoResidencial && (
							<>
								<th>Ad (m²)</th>
								<th>Nad (m²)</th>
								<th>Isenta (m²)</th>
								<th>Ad (m²)</th>
								<th>Nad (m²)</th>
								<th>Isenta (m²)</th>
								<th>Ad (m²)</th>
								<th>Nad (m²)</th>
								<th>Isenta (m²)</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>{detalhesAreas?.economias?.map(mapEconomias)}</tbody>
				<tfoot>
					{isUnifamiliarDuasEconomiasNaoResidencial ? (
						<RowsTotaisResidencialNaoResidencial totais={detalhesAreas?.totais} hideDemolir={hideDemolir} />
					) : (
						<RowsTotais totais={detalhesAreas?.totais} hideDemolir={hideDemolir} />
					)}
				</tfoot>
			</table>
		</div>
	);
}

export default TabelaAreasLE;
TabelaAreasLE.displayName = 'TabelaAreasLE';
TabelaAreasLE.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	name: PropTypes.string,
	tipoForm: PropTypes.string,
	isProjetoNovo: PropTypes.bool
};
