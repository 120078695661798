import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { NumberField } from 'containers/Form/metadata-template/fields';

import { isDebug } from 'utils/tools';

export function NumeroSubfield({ fieldName, label, onChangeHandler, value, errors, required, readOnly, disabled }) {
	return (
		<div className="pergunta metragem">
			<NumberField
				label={label}
				placeHolder={''}
				containerClass="input-group-lic"
				name={fieldName}
				onChangeHandler={onChangeHandler}
				value={value}
				decimals={0}
				maxSize="10"
				required={required}
				readOnly={readOnly}
				disabled={disabled}
			/>
			{isDebug && <div className="debug-message">{fieldName}</div>}
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
NumeroSubfield.displayName = 'LicencasExpressasNumeroSubfield';
NumeroSubfield.propTypes = {
	fieldName: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	onChangeHandler: PropTypes.func,
	localData: PropTypes.object,
	errors: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool
};
