import { camelCase } from 'lodash';

import { TIPOS_LAC, TIPOS_PORTE, TIPOS_POLUICAO, TIPOS_PPCI } from './licenca';
/**
 * calcula o valor em ufms da licenca conforme porte, potencial poluidor e tipo de licença
 * @param {*} param0 formdata
 * @param {*} callback funcao que recebe o resultado
 */
export function obterValorUfm({ porte, potencialPoluidor, tipoLicenca, tipoPPCI }, callback) {
	const porteValue = camelCase(porte);
	let valorUfm = 0;
	let valorFinal = 0;

	// conforme OP #48004
	if (porteValue === TIPOS_PORTE.especialUnico) {
		valorUfm = 100;
	} else {
		//licenca
		switch (tipoLicenca) {
			case TIPOS_LAC.unica: {
				switch (porteValue) {
					case TIPOS_PORTE.minimo:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 40;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 45;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.pequeno:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 90;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 120;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.medio:
					case TIPOS_PORTE.grande:
					case TIPOS_PORTE.excepcional:
					default:
						break;
				}
				break;
			}
			//;unica
			case TIPOS_LAC.previa: {
				switch (porteValue) {
					case TIPOS_PORTE.minimo:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 20;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 20;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 25;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.pequeno:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 32;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 40;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 92;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.medio:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 115;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 165;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 230;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.grande:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 220;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 330;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 380;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.excepcional:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 315;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 380;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 605;
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
				break;
			}
			//;previa
			case TIPOS_LAC.instalacao: {
				switch (porteValue) {
					case TIPOS_PORTE.minimo:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 45;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 55;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 70;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.pequeno:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 90;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 110;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 250;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.medio:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 320;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 455;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 630;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.grande:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 610;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 930;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 1050;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.excepcional:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 900;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 1070;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 1660;
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
				break;
			}
			//;instalacao
			case TIPOS_LAC.operacao: {
				switch (porteValue) {
					case TIPOS_PORTE.minimo:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 25;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 40;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 60;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.pequeno:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 45;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 75;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 220;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.medio:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 160;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 625;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 600;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.grande:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 370;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 800;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 1530;
								break;
							default:
								break;
						}
						break;
					case TIPOS_PORTE.excepcional:
						switch (potencialPoluidor) {
							case TIPOS_POLUICAO.baixo:
								valorUfm = 580;
								break;
							case TIPOS_POLUICAO.medio:
								valorUfm = 1410;
								break;
							case TIPOS_POLUICAO.alto:
								valorUfm = 3050;
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
				break;
			}
			//;operacao
			default:
				break;
		}
	}

	if (tipoPPCI) {
		if (tipoPPCI === TIPOS_PPCI.ppci) {
			valorFinal = valorUfm;
		} else if (tipoPPCI === TIPOS_PPCI.appci) {
			valorFinal = valorUfm * 4;
		}
	}

	callback && callback({ valorUfm: `${valorUfm}`, valorTotal: `${valorFinal}` });
}
