export const DISCARD_WORDS = [
	'ante',
	'apos',
	'ate',
	'com',
	'conforme',
	'contra',
	'consoante',
	'desde',
	'durante',
	'exceto',
	'entre',
	'mediante ',
	'para',
	'perante',
	'por',
	'salvo',
	'sem',
	'sob',
	'sobre',
	'tras',
	'uma',
	'uns',
	'umas',
	'aos',
	'dos',
	'das',
	'dum',
	'duma',
	'duns',
	'dumas',
	'nos',
	'nas',
	'num',
	'numa',
	'nuns',
	'numas',
	'pelo',
	'pela',
	'pelos',
	'pelas'
];

export const ADDRESS_IGNORED_WORDS = [
	'Avenida',
	'Av\\.',
	'Rua',
	'R\\.',
	'Acesso',
	'Alameda',
	'Arroio',
	'Acesso Secundario',
	'Beco',
	'Belvedere',
	'Cais',
	'Ciclovia',
	'Canal',
	'Diretriz',
	'Elevada',
	'Escadaria',
	'Esplanada',
	'Esquina',
	'Estacao',
	'Estrada',
	'ESTR\\.',
	'Galeria',
	'Ilha',
	'Jardim',
	'Lago',
	'Logradouro Especial',
	'Largo',
	'Mercado',
	'Marina',
	'Praça',
	'Parque',
	'Praca',
	'Passagem',
	'Passarela',
	'Passagem de nivel',
	'Passeio',
	'Recanto',
	'Rotula',
	'Terminal',
	'Travessa',
	'Trevo',
	'Travessia',
	'Tunel',
	'Viaduto',
	'de',
	'da',
	'das',
	'do',
	'dos',
	'e'
];

export const DIACRITICS = {
	a: '[aáàãâä]',
	e: '[eéèêë]',
	i: '[iíìîï]',
	o: '[oóòõôö]',
	u: '[uúùûü]',
	c: '[cç]',
	A: '[AÁÀÃÂÄ]',
	E: '[EÉÈÊË]',
	I: '[IÍÌÎÏ]',
	O: '[OÓÒÕÔÖ]',
	U: '[UÚÙÛÜ]',
	C: '[CÇ]'
};
