import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { eq, get, gte as _gte, isEmpty, isEqual, isNil, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { getMomentDate } from 'utils/tools';

import { TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM, TIPO_FORMULARIO_TESTE } from '../Detalhe';
import TemplateFieldSet from './TemplateFieldSet';

import './TemplateForm.scss';

/**
 * Ponto de entrada para processar um objeto/form
 * da coleção de metadata do licenciamento
 *
 * @param {*} props
 */
export function TemplateForm({
	fieldsets = [],
	readOnly: formReadOnly,
	disabled: formDisabled,
	disabled4D,
	onChangeHandler,
	submitLabel = 'Avançar',
	onSubmit,
	validateForm,
	formData = {},
	customErrors,
	consultaPublicaExpedientes,
	usuarioInterno,
	obterDadosProjetosLicencasExpediente,
	type
}) {
	// states
	const [fieldSetErrors, setErrors] = useState({});

	/** handler para erros no form */
	const formErrorHandler = useCallback(param => {
		setErrors(data => ({ ...data, ...param }));
	}, []);

	/** handler para inputs */
	const formHandler = useCallback(
		param => {
			onChangeHandler && onChangeHandler(param);
		},
		[onChangeHandler]
	);

	// Validação campos não. form certidao averbação.
	function validateCannotBeNo(field) {
		if (field.value === 'nao') {
			return ['Não é possível emitir a certidão devido ao não atendimento ao Art. 247-A da Lei nº 6.015/1973'];
		}
		return [];
	}

	/** add o handler para sameAs. Validar o valor de um field comparando com outro */
	const addSameAsHandler = useCallback(
		(field, formFields) => {
			/** handler para field com dependencia em outro field do form */
			const onSameAsInputHandler = change => {
				const { key, value } = change;
				const otherField = formFields[key].sameAs;
				const { value: otherValue, label } = formFields[otherField];
				if (!eq(value, otherValue)) {
					formErrorHandler({
						[key]: [`O valor informado deve ser igual ao ${label}`]
					});
				}
				formHandler(change);
			};
			if (field && field.sameAs) {
				field.onSameAsChangeHandler = onSameAsInputHandler;
			}
		},
		[formErrorHandler, formHandler]
	);

	/** processa as opções de um field date/daterange */
	const addDateOptions = useCallback((field, formFields) => {
		/** fn auxiliar */
		function extractDisableValue(disable) {
			if (disable === 'today') {
				return getMomentDate();
			} else if (typeof disable === 'object') {
				const { field: other, constant } = disable;
				if (!isNil(other)) {
					return getMomentDate(formFields[other].value);
				} else if (!isNil(constant)) {
					return getMomentDate().add(constant.value, constant.unit);
				}
			}
		}
		if (field && ['date', 'dateRange'].includes(field.type)) {
			const { disableBefore, disableAfter } = field;
			if (disableBefore && disableAfter) {
				const db = extractDisableValue(disableBefore);
				const da = extractDisableValue(disableAfter);
				field.disabledDate = day => (day.isBefore(db) || day.isAfter(da) ? true : false);
			} else if (disableBefore) {
				const db = extractDisableValue(disableBefore);
				field.disabledDate = day => day.isBefore(db);
			} else if (disableAfter) {
				const da = extractDisableValue(disableAfter);
				field.disabledDate = day => day.isAfter(da);
			}
		} else if (field.type === 'fieldList') {
			Object.keys(field.metaFields).forEach(k => addDateOptions(field.metaFields[k]));
		}
	}, []);

	/** add o comportamento dinamico de hide (não render) no field */
	const addHideBehavior = useCallback((field, formFields, cb) => {
		if (field && field.showIf) {
			const { showIf } = field;
			const { any, gte, eq, inverse = false } = showIf;
			let { field: dependOnPaths } = showIf;
			dependOnPaths = /\./.test(dependOnPaths)
				? dependOnPaths.replace(/\./, '.value.').split('.')
				: [dependOnPaths, 'value'];
			const resultSet = new Set();

			/**
			 *
			 * @param {string[]} paths
			 * @param {*} fObj
			 */
			const findValuesDeep = (paths, fObj) => {
				while (paths.length) {
					const path = paths.shift();
					fObj = get(fObj, path);

					// ultimo path, é o valor do field
					if (!paths.length && !isNil(fObj)) {
						resultSet.add(fObj);
						break;
					}
					if (Array.isArray(fObj)) {
						fObj.forEach(v => {
							findValuesDeep([...paths], v);
						});
						// na volta ao 'root' nao precisa continuar os paths
						break;
					}
				}
			};
			findValuesDeep(dependOnPaths, formFields);
			let hasValue = false;
			const valorOutroField = resultSet.size === 1 ? [...resultSet][0] : [...resultSet];
			// se algum valor do outro field estiver
			// no array de possiveis valores
			if (!isNil(any)) {
				if (Array.isArray(valorOutroField)) {
					hasValue = valorOutroField.some(value => any.includes(value));
				} else if (typeof valorOutroField !== 'object') {
					hasValue = any.includes(valorOutroField);
				}
			} else if (!isNil(gte)) {
				hasValue = _gte(valorOutroField, +gte);
			} else if (!isNil(eq)) {
				if (Array.isArray(valorOutroField)) {
					hasValue = Array.isArray(eq) ? isEqual(valorOutroField, eq) : isEqual(valorOutroField, [eq]);
				} else {
					hasValue = isEqual(valorOutroField, eq);
				}
			}
			field.hide = inverse ? !!hasValue : !hasValue;
			if (field.hide) {
				delete field.value;
				cb && cb(field.hide);
			}
		}
	}, []);

	/** add o comportamento dinamico de required no field */
	const addRequiredBehavior = useCallback((field, formFields) => {
		if (field && field.requiredIf && !field.hide) {
			const { requiredIf } = field;
			const { field: dependOn, any, gte, eq } = requiredIf;
			let hasValue = false;
			const valorOutroField = formFields[dependOn].value;
			// se algum valor do outro field estiver
			// no array de possiveis valores
			if (!isNil(any)) {
				if (Array.isArray(valorOutroField)) {
					hasValue = valorOutroField.some(value => any.includes(value));
				} else if (typeof valorOutroField !== 'object') {
					hasValue = any.includes(valorOutroField);
				}
			} else if (!isNil(gte)) {
				hasValue = _gte(valorOutroField, +gte);
			} else if (!isNil(eq)) {
				if (Array.isArray(valorOutroField)) {
					hasValue = Array.isArray(eq) ? isEqual(valorOutroField, eq) : isEqual(valorOutroField, [eq]);
				} else {
					hasValue = isEqual(valorOutroField, eq);
				}
			}
			field.required = hasValue;
		}
	}, []);

	// useMemo executa durante o render processos onerosos se as dependencias mudarem
	/** monta um objeto com todos os fields desse form */
	const formFields = useMemo(() => {
		// enable e readonly para o form todo
		// aplica em cada fieldset ou mantem a propriedade do fieldset
		fieldsets.forEach(fs => {
			fs.disabled = isNil(fs.disabled) ? formDisabled : fs.disabled;
			fs.readOnly = isNil(fs.readOnly) ? formReadOnly : fs.readOnly;
		});
		// monta um objeto com todos os fields desse form
		const ffs = fieldsets
			.filter(fsts => !isNil(fsts.fields))
			.map(fsts => fsts.fields)
			.reduce((form, fields) => {
				if (usuarioInterno && fields['identificacao']) {
					delete fields['identificacao'];
				}

				return Object.assign(form, fields);
			}, {});

		// seta os valores iniciais
		Object.keys(ffs).forEach(name => {
			ffs[name].value = formData[name];
		});

		// add handlers
		Object.keys(ffs).forEach(name => {
			addHideBehavior(ffs[name], ffs, hidden => {
				if (hidden) {
					delete formData?.[name];
					delete customErrors?.[name];
				}
			});
			addSameAsHandler(ffs[name], ffs);
			addDateOptions(ffs[name], ffs);
			addRequiredBehavior(ffs[name], ffs);
		});

		return ffs;
	}, [
		addDateOptions,
		addHideBehavior,
		addRequiredBehavior,
		addSameAsHandler,
		customErrors,
		fieldsets,
		formData,
		formDisabled,
		formReadOnly,
		usuarioInterno
	]);

	/**
	 * adiciona documentos extras conforme ids vinculados ao field metadata
	 * @returns {string[]} array de ids de documentos
	 */
	const addDocumentosExtras = useCallback(() => {
		const extrasDocs = [];

		Object.keys(formFields).forEach(key => {
			const { documentsRequired, value } = formFields[key];
			if (!isNil(documentsRequired) && !isEmpty(documentsRequired)) {
				documentsRequired.forEach(dr => {
					const {
						document,
						ifValue: { eq }
					} = dr;
					if (!isNil(eq) && isEqual(value, eq)) {
						extrasDocs.push(document);
					}
				});
			}
		});

		return extrasDocs;
	}, [formFields]);

	const validateSubmit = useCallback(() => {
		let tudoOk = true;
		let errorList = [];
		let outrosErros = {};

		Object.keys(formFields).forEach(key => {
			const { label, value, required, hide, hidden, type } = formFields[key];
			//Respostas não das perguntas do form Certidao de averbação
			if (formFields[key].validation === 'respostasNao') {
				const errors = validateCannotBeNo(formFields[key]);
				if (errors.length !== 0) {
					tudoOk = false;
					errorList.push(...errors);
					outrosErros[key] = errors;
				}
			}
			// validar requireds genericos
			if (required === true && !hide && !hidden) {
				const isValueInformado = valueInformado(value, type);
				if (isValueInformado === false) {
					if (size(fieldSetErrors[key]) === 0) {
						errorList.push(`Campo "${label}" é obrigatório e seu valor não foi informado`);
						outrosErros[key] = [`Campo ${label} obrigatório`];
						tudoOk = false;
					}
				}
			}
			// validar fields com logicas proprias
			// ver a function em `TemplateField`
			if (!hide && !hidden && formFields[key].validate && typeof formFields[key].validate === 'function') {
				const errosFields = formFields[key].validate(formFields[key]);
				if (errosFields.length !== 0) {
					tudoOk = false;
					errorList.push(...errosFields);
					outrosErros[key] = errosFields;
				}
			}
			if (size(customErrors) > 0 && !formReadOnly) {
				tudoOk = false;
				if (Array.isArray(customErrors)) {
					outrosErros.send = customErrors;
				} else {
					outrosErros = Object.assign(outrosErros, customErrors);
				}
			}
		});
		return [tudoOk, errorList, outrosErros];
	}, [customErrors, fieldSetErrors, formFields, formReadOnly]);

	// handler para submit do form
	const submit = useCallback(
		options => {
			const noValidate = get(options, 'noValidate') === true;

			if (noValidate) {
				onSubmit && onSubmit([true, [], {}]);
				return;
			}
			// validacao basica de campos
			let [tudoOk, errorList, outrosErros] = validateSubmit();
			let externalErrors = {};

			// se foi fornecida funcao customizada de validacao
			// por convencao manter como ultimo elemento do callback
			if (!isNil(validateForm) && typeof validateForm === 'function') {
				const validatedForm = validateForm(formFields);
				// por convencao, validated deve ser um array [boolean, string[]]
				if (validatedForm !== undefined && Array.isArray(validatedForm)) {
					let validateds = null;

					if (Array.isArray(validatedForm[0])) {
						validateds = validatedForm;
					} else {
						validateds = [validatedForm];
					}

					validateds.forEach(v => {
						let [valOk, valErrors, valProperty] = v;
						tudoOk = tudoOk && !!valOk;
						if (size(valErrors) && Array.isArray(valErrors)) {
							errorList = [...errorList, ...valErrors];
						}
						if (size(valProperty) > 0) {
							if (!externalErrors[valProperty]) {
								externalErrors[valProperty] = [];
							}
							externalErrors[valProperty].push(...valErrors);
						}
					});
				}
			}

			const result = [tudoOk, errorList, Object.assign({}, externalErrors, outrosErros)];

			// se tudo ok, processo o metadata em busca de documentos extras
			if (tudoOk) {
				const edocs = addDocumentosExtras();
				result.push({ outrosDocumentos: edocs });
			}

			// submit
			// params do submit [tudo ok, lista de erros, obj com extras]
			onSubmit && onSubmit(result);
		},
		[addDocumentosExtras, formFields, onSubmit, validateForm, validateSubmit]
	);

	return (
		<form className="p-3 template-form" autoComplete="off" acceptCharset="ISO-8859-1" noValidate>
			{fieldsets
				.filter(fieldset => {
					if (usuarioInterno) {
						return !fieldset.isHiddenInAdmin;
					}
					return true;
				})
				.map((fieldset, index) => (
					<TemplateFieldSet
						fieldSet={fieldset}
						onErrorHandler={formErrorHandler}
						onChangeHandler={formHandler}
						errorFields={fieldSetErrors}
						usuarioInterno={usuarioInterno}
						consultaPublicaExpedientes={consultaPublicaExpedientes}
						key={index}
						obterDadosProjetosLicencasExpediente={obterDadosProjetosLicencasExpediente}
						customErrors={customErrors}
					></TemplateFieldSet>
				))}
			{size(customErrors) > 0 &&
				(Array.isArray(customErrors) ? (
					<ErrorMessages errorList={customErrors}></ErrorMessages>
				) : (
					<>
						<ErrorMessages
							errorList={['Problemas na submissão. Verifique os erros no formulário acima.']}
						></ErrorMessages>
						{[TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM, TIPO_FORMULARIO_TESTE].includes(type) && (
							<ul>
								{Object.keys(customErrors).map(key => (
									<li key={key} style={{ display: 'flex', gap: '10px' }}>
										<ErrorMessages errorList={customErrors[key]} showLink={true} />
									</li>
								))}
							</ul>
						)}
					</>
				))}
			{disabled4D && (
				<>
					<table>
						<tbody>
							<tr className="warning-message" style={{ marginBottom: '25px' }}>
								<td style={{ verticalAlign: 'middle' }}>{/* <i className="fa fa-exclamation-triangle" /> */}</td>
								<td style={{ paddingLeft: '10px' }}>
									<span>
										"Não é possível submeter o formulário devido à(s) observação(ões) na(s) pergunta(s) acima."
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</>
			)}

			<div className="cjto-botoes">
				{(formReadOnly || formDisabled) && get(formFields, 'identificacao') ? (
					<button type="button" className="btn btn-primary" onClick={() => submit({ noValidate: true })}>
						Atualizar identificador
					</button>
				) : (
					<button
						type="button"
						disabled={formReadOnly || formDisabled || disabled4D}
						className="btn btn-primary"
						onClick={e => {
							submit();
							const button = e.target;
							if (button) {
								setTimeout(() => {
									button.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
								}, 200);
							}
						}}
					>
						{submitLabel}
					</button>
				)}
			</div>
		</form>
	);
}

TemplateForm.displayName = 'TemplateForm';
TemplateForm.propTypes = {
	fieldsets: PropTypes.array,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	disabled4D: PropTypes.bool,
	disabledCA: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	submitLabel: PropTypes.string,
	onSubmit: PropTypes.func,
	validateForm: PropTypes.func,
	formData: PropTypes.object,
	customErrors: PropTypes.any,
	usuarioInterno: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	obterDadosProjetosLicencasExpediente: PropTypes.bool,
	type: PropTypes.string
};
export default TemplateForm;

/** valida se o value foi informado */
const valueInformado = (value, type) => {
	// trata endereco sem numero
	if (value && value.enderecoFormatado && !value.numero && value.numero !== 0) {
		return false;
	}
	if (type === 'number' || type === 'numberField') {
		const nv = get(value, 'value');
		return isNil(nv) ? false : size(nv) > 0 ? isFinite(nv) : false;
	}
	return isNil(value) ? false : Number.isFinite(value) ? true : typeof value === 'boolean' ? value : !isEmpty(value);
};
