import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import gql from 'graphql-tag';
import { size } from 'lodash';

import Selecao from 'components/Selecao';

import apolloClient from 'utils/graphql';
import { accessApi } from 'utils/injectApi';
import { permiteInput } from 'utils/tools';

function EnderecoField({
	value,
	readOnly,
	disabled,
	required,
	className,
	style,
	loading,
	setLoading,
	onChangeHandler
}) {
	const timer = useRef(null);
	const [cdl, setCdl] = useState(null);
	const [logradouro, setLogradouro] = useState(null);
	const [numero, setNumero] = useState(null);
	const [logradourosPesquisados, setlogradourosPesquisados] = useState([]);

	const obtemEndereco = useCallback(
		async (codLogradouro, numero) => {
			try {
				if (codLogradouro && numero) {
					if (parseInt(codLogradouro) !== cdl?.codigoLogradouro || parseInt(numero) !== cdl?.numero) {
						setLoading(true);
						const url = `EXPEDIENTES_API_URL/cdl/${codLogradouro}/${numero}?latlong=true`;
						const response = await accessApi(url, true);
						const cdlAux = response.data;
						cdlAux.id = cdlAux.codigoLogradouro;
						setCdl(cdlAux);
						setLoading(false);
					}
				}
			} catch (e) {
				setCdl(null);
				setLoading(false);
				console.error('erro no servidor', e);
			}
		},
		[cdl, setLoading]
	);

	useEffect(() => {
		if (value && !value.incomplete && !cdl) {
			setCdl(value);
			setLogradouro(value.nomeLogradouro);
			setNumero(value.numero);
		}
	}, [cdl, value]);

	useEffect(() => {
		const buscaCDL = async logradouro => {
			try {
				const queryCDL = gql`
					query CDL($term: String) {
						CdlEnderecoList(term: $term) {
							id
							nomeLogradouro
							enderecoFormatadoCurto
						}
					}
				`;
				if (size(logradouro) > 2) {
					setLoading(true);
					const {
						data: { CdlEnderecoList }
					} = await apolloClient.query({
						query: queryCDL,
						variables: { term: logradouro },
						fetchPolicy: 'network-only'
					});
					setlogradourosPesquisados(CdlEnderecoList);
				} else {
					setlogradourosPesquisados([]);
				}
				setLoading(false);
			} catch (e) {
				setlogradourosPesquisados([]);
				setLoading(false);
				console.error('erro no servidor', e);
			}
		};

		if (logradouro) {
			if (timer.current) {
				clearTimeout(timer.current);
			}
			timer.current = setTimeout(() => buscaCDL(logradouro), 250);
		}
	}, [logradouro, setLoading]);

	useEffect(() => {
		if (numero) {
			if (timer.current) {
				clearTimeout(timer.current);
			}
			if (cdl?.id) {
				timer.current = setTimeout(() => obtemEndereco(cdl.id, numero), 250);
			}
		} else {
			if (cdl?.numero) {
				setCdl(old => ({ id: old.id, enderecoFormatadoCurto: old.nomeLogradouro, nomeLogradouro: old.nomeLogradouro }));
			}
		}
	}, [cdl, numero, obtemEndereco]);

	useEffect(() => {
		let saida = { incomplete: true };
		if (cdl) {
			setLogradouro(cdl.nomeLogradouro);
			if (cdl.numero) {
				saida = cdl;
			}
		}
		onChangeHandler(saida);
	}, [cdl, onChangeHandler]);

	return (
		<div className={className} style={style}>
			<div className="form-group col-md-8">
				<label className="control-label" htmlFor="root_nome">
					Logradouro
				</label>
				<div />
				<Selecao
					className="form-control"
					multiple={false}
					selected={cdl}
					label={'Logradouro'}
					detailInnerClassName={'inner-list-item inner-list-item-input'}
					detailCodigo={''}
					detailDescricao={'nomeLogradouro'}
					autoShowList={false}
					searchTerm={logradouro}
					searchList={logradourosPesquisados}
					searchTermMinLength={3}
					errorList={false}
					onChangeSearchTerm={e => setLogradouro(e.target.value)}
					onBlurSearchTerm={() => false}
					onSelectItem={cdl => (numero ? obtemEndereco(cdl.id, numero) : setCdl(cdl))}
					onUnselect={() => () => setCdl(null)}
					noResetList={true}
					loading={loading}
					required={required}
					placeholder="Informe o logradouro e selecione da lista"
					readOnly={disabled || readOnly}
				/>
			</div>
			<div className="form-group col-md-4">
				<label className="control-label" htmlFor="root_nome">
					Número
					{required ? '*' : ''}
				</label>
				<div className="input-with-icon">
					<input
						className="form-control"
						type="text"
						value={numero || ''}
						onChange={e => {
							if (permiteInput(e.target.value, 'Int')) {
								setNumero(e.target.value);
							}
						}}
						placeholder="apenas números"
						readOnly={readOnly}
						disabled={disabled}
					/>
					{loading && <i className="fa fa-refresh fa-spin" />}
				</div>
			</div>
		</div>
	);
}
EnderecoField.propTypes = {
	value: PropTypes.object,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.object,
	onChangeHandler: PropTypes.func
};
EnderecoField.displayName = 'EnderecoField';

export default EnderecoField;
