import { useContext } from 'react';

import { LayoutContext } from 'components/LayoutContext';

const useMessages = () => {
	const { createMessage, removeMessage } = useContext(LayoutContext);

	return {
		createMessage,
		removeMessage
	};
};
useMessages.displayName = 'useMessages';

export default useMessages;
