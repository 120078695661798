import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { get, set, omit, clone } from 'lodash';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';

import { diferenca, multiplica, maiorQue } from 'utils/tools';

import { includesTipoForm } from './InfosProjetoAP';
import { UAP_ENQUADRAMENTOS_ENUM } from './TabelaAreasAP/constantsAP';

const { MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL, HABITACAO_COLETIVA } = UAP_ENQUADRAMENTOS_ENUM;

const sufixoComentario = 'Comentario';
const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];

export function InfosTerrenoAP({
	tipoForm,
	data: localData,
	dataAnterior,
	onChangeHandler,
	errosSubmissao: errosSubmissaoProps,
	disabled = false,
	changeComentarioHandler = null,
	usuarioInterno = false,
	readOnlyVersao = null
}) {
	const [erros, setErros] = useState();
	const [temComentario, setTemComentario] = useState({});

	const setTemComentarioAux = valores => {
		const { name, value } = valores || {};
		if (temComentario[name] !== value) {
			setTemComentario(oldState => {
				const newState = { ...oldState };
				newState[name] = value;
				return newState;
			});
		}
	};

	const possuiAreaDeMenorPoligono = localData?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = localData?.possuiAreaAtingida === 'sim';
	const possuiAreaAtingidaDeMenorPoligono = localData?.possuiAreaAtingidaDeMenorPoligono === 'sim';
	const areaMatricula3000 = localData?.areaMatricula3000 === 'sim';

	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	if (possuiAreaDeMenorPoligono) {
		const areaMaisCinco = multiplica(localData?.areaMenorPoligonoLote?.value, '1.05');
		localData.areaMenorPoligonoLoteMais5 = { value: areaMaisCinco };
	}
	if (possuiAreaAtingida) {
		const remanescenteMatricula = diferenca(
			localData?.areaTotalMatriculaLote?.value,
			localData?.areaAtingidaMatricula?.value
		);

		if (remanescenteMatricula >= 0) {
			localData.areaRemanescenteMatricula = { value: remanescenteMatricula };
		} else {
			localData.areaRemanescenteMatricula = { value: '0' };
		}

		let rmp = diferenca(localData?.areaMenorPoligonoLote?.value, localData?.areaAtingidaMenorPoligonal?.value);
		if (rmp < 0) {
			rmp = 0;
		}
		const remanescenteMenorPoligonal = multiplica(rmp, '1.05');
		localData.areaRemanescenteMenorPoligonal5 = { value: remanescenteMenorPoligonal };
	}

	/**
	 * Cálculos e regras para aplicação do Regime Urbanístico
	 */
	const todosTemValor = properties => {
		if (!properties.includes('areaTotalMatriculaLote')) {
			properties = ['areaTotalMatriculaLote', ...properties];
		}
		return properties.reduce((acc, prop) => {
			const tem = localData[prop]?.value && localData[prop]?.value !== '0.00';
			return acc && tem;
		}, true);
	};

	// remove valor para só mostrar o cálculo quando todos os campos estiverem preenchidos
	localData.areaAplicacaoRU = {};

	if (!possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		// 1) se não possui "ÁREA DE MENOR POLÍGONO" e não possui "ÁREA ATINGIDA
		// Área para aplicação RU = Área de matrícula do lote
		if (todosTemValor(['areaTotalMatriculaLote'])) {
			localData.areaAplicacaoRU = { ...localData.areaTotalMatriculaLote };
		}
	} else if (possuiAreaDeMenorPoligono && !possuiAreaAtingida) {
		// 2) Se possui "ÁREA DO MENOR POLÍGONO" e não possui "ÁREA ATINGIDA"
		// Área para aplicação RU = Área do menor polígono do lote + 5%
		if (todosTemValor(['areaMenorPoligonoLote'])) {
			localData.areaAplicacaoRU = { ...localData.areaMenorPoligonoLoteMais5 };
		}
	} else if (!possuiAreaDeMenorPoligono && possuiAreaAtingida) {
		// 3) Se não possui "ÁREA DE MENOR POLÍGONO" e possui "ÁREA ATINGIDA"
		// Área para aplicação RU = "ÁREA REMANESCENTE DA MATRÍCULA"
		if (todosTemValor(['areaAtingidaMatricula'])) {
			localData.areaAplicacaoRU = { ...localData.areaRemanescenteMatricula };
		}
	} else if (possuiAreaDeMenorPoligono && possuiAreaAtingida) {
		// 4) Se possui "ÁREA DE MENOR POLÍGONO" e possui "ÁREA ATINGIDA", duas possibilidades:
		// 4.1) Se possui "ÁREA ATINGIDA DE MENOR POLIGONAL"
		// Área para aplicação RU = menor valor entre "AREA REMANESCENTE DA MATRÍCULA" e "ÁREA REMANESCENTE DO MENOR POLÍGONO + 5%"
		// 4.2) Se não possui "ÁREA ATINGIDA DE MENOR POLIGONAL"
		// Área para aplicação RU = "ÁREA MENOR POLÍGONO DO LOTE" - "ÁREA ATINGIDA DA MATRÍCULA" + 5%
		if (possuiAreaAtingidaDeMenorPoligono) {
			if (todosTemValor(['areaMenorPoligonoLote', 'areaAtingidaMatricula', 'areaAtingidaMenorPoligonal'])) {
				const areaRemanescenteMatricula = localData?.areaRemanescenteMatricula?.value || '0';
				const areaRemanescenteMenorPoligonal5 = localData?.areaRemanescenteMenorPoligonal5?.value || '0';
				if (parseFloat(areaRemanescenteMenorPoligonal5) < parseFloat(areaRemanescenteMatricula)) {
					localData.areaAplicacaoRU = { value: areaRemanescenteMenorPoligonal5 };
				} else {
					localData.areaAplicacaoRU = { value: areaRemanescenteMatricula };
				}
			}
		} else {
			if (todosTemValor(['areaMenorPoligonoLote', 'areaAtingidaMatricula'])) {
				let value = diferenca(localData.areaMenorPoligonoLote?.value, localData.areaAtingidaMatricula?.value);
				value = value * 1.05;
				value = value.toFixed(2);
				localData.areaAplicacaoRU = { value };
			}
		}
	}

	// limitado à área total da matrícula do lote
	if (maiorQue(localData.areaAplicacaoRU?.value, localData.areaTotalMatriculaLote?.value)) {
		localData.areaAplicacaoRU.value = localData.areaTotalMatriculaLote?.value;
	}

	const localChangeHandler = event => {
		const [valid] = event;
		const { name, value } = valid;
		let err = clone(erros);
		if (
			name &&
			value?.value &&
			['areaMenorPoligonoLote', 'areaAtingidaMatricula', 'areaAtingidaMenorPoligonal'].includes(name)
		) {
			if (maiorQue(value.value, localData.areaTotalMatriculaLote?.value)) {
				set(err, name, ['O valor não pode ser maior do que a área total da matrícula do lote']);
			} else {
				err = omit(err, [name]);
			}
		} else if (name && value?.value && name === 'areaTotalMatriculaLote') {
			if (localData.areaAtingidaMatricula?.value) {
				if (maiorQue(localData.areaAtingidaMatricula?.value, value.value)) {
					set(err, 'areaAtingidaMatricula', ['O valor não pode ser maior do que a área total da matrícula do lote']);
				} else {
					err = omit(err, 'areaAtingidaMatricula');
				}
			}
			if (localData.areaMenorPoligonoLote?.value) {
				if (maiorQue(localData.areaMenorPoligonoLote?.value, value.value)) {
					set(err, 'areaMenorPoligonoLote', ['O valor não pode ser maior do que a área total da matrícula do lote']);
				} else {
					err = omit(err, 'areaMenorPoligonoLote');
				}
			}
			if (localData.areaAtingidaMenorPoligonal?.value) {
				if (maiorQue(localData.areaAtingidaMenorPoligonal?.value, value.value)) {
					set(err, 'areaAtingidaMenorPoligonal', [
						'O valor não pode ser maior do que a área total da matrícula do lote'
					]);
				} else {
					err = omit(err, 'areaAtingidaMenorPoligonal');
				}
			}
		}

		if (name === 'areaAtingidaMenorPoligonal' && possuiAreaDeMenorPoligono && possuiAreaAtingida) {
			if (maiorQue(value.value, localData.areaAtingidaMatricula?.value)) {
				set(err, 'areaAtingidaMenorPoligonal', [
					'A área atingida da menor poligonal não pode ser maior do que a área atingida da matrícula'
				]);
			} else {
				err = omit(err, 'areaAtingidaMenorPoligonal');
			}
		}

		setErros(err);
		onChangeHandler([valid]);
	};

	return (
		<fieldset>
			<legend>2. Área do terreno objeto do Projeto</legend>
			<div>
				<div
					className={`pergunta metragem ${
						temComentario[`areaTotalMatriculaLote${sufixoComentario}`]
							? 'resposta-com-comentario pin-comentario'
							: usuarioInterno
							? 'resposta-com-comentario'
							: ''
					}`}
				>
					<NumberFieldLE
						name="areaTotalMatriculaLote"
						label="Área total da(s) matrícula(s) do(s) lote(s) (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={localChangeHandler}
						value={localData.areaTotalMatriculaLote}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['areaTotalMatriculaLote']}
						dataAtual={localData['areaTotalMatriculaLote']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`areaTotalMatriculaLote${sufixoComentario}`}
						valueComentario={localData[`areaTotalMatriculaLote${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`areaTotalMatriculaLote${sufixoComentario}`]}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['areaTotalMatriculaLote']) ||
							(errosSubmissao || []).filter(e => (e.campo === 'areaTotalMatriculaLote' ? e.message : null))
						}
					/>
				</div>
				<div
					className={`pergunta ${
						temComentario[`possuiAreaDeMenorPoligono${sufixoComentario}`]
							? 'resposta-com-comentario pin-comentario'
							: usuarioInterno
							? 'resposta-com-comentario'
							: ''
					}`}
				>
					<RadioFieldLE
						name="possuiAreaDeMenorPoligono"
						label="O terreno possui área de menor polígono?"
						options={OPT_SIM_NAO}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.possuiAreaDeMenorPoligono}
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['possuiAreaDeMenorPoligono']}
						dataAtual={localData['possuiAreaDeMenorPoligono']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`possuiAreaDeMenorPoligono${sufixoComentario}`}
						valueComentario={localData[`possuiAreaDeMenorPoligono${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`possuiAreaDeMenorPoligono${sufixoComentario}`]}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['possuiAreaDeMenorPoligono']) ||
							(errosSubmissao || []).filter(e => (e.campo === 'possuiAreaDeMenorPoligono' ? e.message : null))
						}
					/>
				</div>
				{possuiAreaDeMenorPoligono && (
					<>
						<div
							className={`pergunta metragem ${
								temComentario[`areaMenorPoligonoLote${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="areaMenorPoligonoLote"
								label="Área do menor polígono do lote (m²)"
								placeHolder="metragem m²"
								required={true}
								onChangeHandler={localChangeHandler}
								value={localData.areaMenorPoligonoLote}
								decimals={2}
								maxSize="10"
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['areaMenorPoligonoLote']}
								dataAtual={localData['areaMenorPoligonoLote']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`areaMenorPoligonoLote${sufixoComentario}`}
								valueComentario={localData[`areaMenorPoligonoLote${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`areaMenorPoligonoLote${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaMenorPoligonoLote']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'areaMenorPoligonoLote' ? e.message : null))
								}
							/>
						</div>
						<div
							className={`pergunta metragem ${
								temComentario[`areaMenorPoligonoLoteMais5${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="areaMenorPoligonoLoteMais5"
								label="Área do menor polígono do lote +5% (m²)"
								placeHolder=""
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.areaMenorPoligonoLoteMais5}
								decimals={2}
								maxSize="10"
								readOnly={true}
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['areaMenorPoligonoLoteMais5']}
								dataAtual={localData['areaMenorPoligonoLoteMais5']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`areaMenorPoligonoLoteMais5${sufixoComentario}`}
								valueComentario={localData[`areaMenorPoligonoLoteMais5${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`areaMenorPoligonoLoteMais5${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaMenorPoligonoLoteMais5']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'areaMenorPoligonoLoteMais5' ? e.message : null))
								}
							/>
						</div>
					</>
				)}
				<div
					className={`pergunta  ${
						temComentario[`possuiAreaAtingida${sufixoComentario}`]
							? 'resposta-com-comentario pin-comentario'
							: usuarioInterno
							? 'resposta-com-comentario'
							: ''
					}`}
				>
					<RadioFieldLE
						name="possuiAreaAtingida"
						label="O terreno possui área atingida?"
						options={OPT_SIM_NAO}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.possuiAreaAtingida}
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['possuiAreaAtingida']}
						dataAtual={localData['possuiAreaAtingida']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`possuiAreaAtingida${sufixoComentario}`}
						valueComentario={localData[`possuiAreaAtingida${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`possuiAreaAtingida${sufixoComentario}`]}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['possuiAreaAtingida']) ||
							(errosSubmissao || []).filter(e => (e.campo === 'possuiAreaAtingida' ? e.message : null))
						}
					/>
				</div>
				{possuiAreaAtingida && (
					<>
						<div
							className={`pergunta metragem ${
								temComentario[`areaAtingidaMatricula${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="areaAtingidaMatricula"
								label="Área atingida da matrícula (m²)"
								placeHolder="metragem m²"
								required={true}
								onChangeHandler={localChangeHandler}
								value={localData.areaAtingidaMatricula}
								decimals={2}
								maxSize="10"
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['areaAtingidaMatricula']}
								dataAtual={localData['areaAtingidaMatricula']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`areaAtingidaMatricula${sufixoComentario}`}
								valueComentario={localData[`areaAtingidaMatricula${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`areaAtingidaMatricula${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaAtingidaMatricula']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'areaAtingidaMatricula' ? e.message : null))
								}
							/>
						</div>
						<div
							className={`pergunta metragem ${
								temComentario[`areaRemanescenteMatricula${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="areaRemanescenteMatricula"
								label="Área remanescente da matrícula (m²)"
								placeHolder=""
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.areaRemanescenteMatricula}
								decimals={2}
								maxSize="10"
								readOnly={true}
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['areaRemanescenteMatricula']}
								dataAtual={localData['areaRemanescenteMatricula']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`areaRemanescenteMatricula${sufixoComentario}`}
								valueComentario={localData[`areaRemanescenteMatricula${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`areaRemanescenteMatricula${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaRemanescenteMatricula']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'areaRemanescenteMatricula' ? e.message : null))
								}
							/>
						</div>
						{possuiAreaDeMenorPoligono && (
							<div
								className={`pergunta  ${
									temComentario[`possuiAreaAtingidaDeMenorPoligono${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<RadioFieldLE
									name="possuiAreaAtingidaDeMenorPoligono"
									label="O terreno possui área atingida de menor polígono?"
									options={OPT_SIM_NAO}
									required={true}
									onChangeHandler={onChangeHandler}
									value={localData.possuiAreaAtingidaDeMenorPoligono}
									disabled={disabled}
								/>
								<HistoricoRespostaAnterior
									dataAnterior={dataAnterior['possuiAreaAtingidaDeMenorPoligono']}
									dataAtual={localData['possuiAreaAtingidaDeMenorPoligono']}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`possuiAreaAtingidaDeMenorPoligono${sufixoComentario}`}
									valueComentario={localData[`possuiAreaAtingidaDeMenorPoligono${sufixoComentario}`]}
									onChangeComentarioHandler={
										typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
									}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`possuiAreaAtingidaDeMenorPoligono${sufixoComentario}`]}
								/>
								<ErrorMessages
									errorList={
										get(erros, ['possuiAreaAtingidaDeMenorPoligono']) ||
										(errosSubmissao || []).filter(e => e.campo === 'possuiAreaAtingidaDeMenorPoligono')
									}
								/>
							</div>
						)}
						{possuiAreaAtingidaDeMenorPoligono && (
							<>
								<div
									className={`pergunta metragem ${
										temComentario[`areaAtingidaMenorPoligonal${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<NumberFieldLE
										name="areaAtingidaMenorPoligonal"
										label="Área atingida da menor poligonal (m²)"
										placeHolder="metragem m²"
										required={true}
										onChangeHandler={localChangeHandler}
										value={localData.areaAtingidaMenorPoligonal}
										decimals={2}
										maxSize="10"
										disabled={disabled}
									/>
									<HistoricoRespostaAnterior
										dataAnterior={dataAnterior['areaAtingidaMenorPoligonal']}
										dataAtual={localData['areaAtingidaMenorPoligonal']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`areaAtingidaMenorPoligonal${sufixoComentario}`}
										valueComentario={localData[`areaAtingidaMenorPoligonal${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`areaAtingidaMenorPoligonal${sufixoComentario}`]}
									/>
									<ErrorMessages
										errorList={
											get(erros, ['areaAtingidaMenorPoligonal']) ||
											(errosSubmissao || []).filter(e => (e.campo === 'areaAtingidaMenorPoligonal' ? e.message : null))
										}
									/>
								</div>
								<div
									className={`pergunta metragem ${
										temComentario[`areaRemanescenteMenorPoligonal5${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<NumberFieldLE
										name="areaRemanescenteMenorPoligonal5"
										label="Área remanescente do menor polígono +5% (m²)"
										placeHolder=""
										required={true}
										onChangeHandler={onChangeHandler}
										value={localData.areaRemanescenteMenorPoligonal5}
										decimals={2}
										maxSize="10"
										readOnly={true}
										disabled={disabled}
									/>
									<HistoricoRespostaAnterior
										dataAnterior={dataAnterior['areaRemanescenteMenorPoligonal5']}
										dataAtual={localData['areaRemanescenteMenorPoligonal5']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`areaRemanescenteMenorPoligonal5${sufixoComentario}`}
										valueComentario={localData[`areaRemanescenteMenorPoligonal5${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`areaRemanescenteMenorPoligonal5${sufixoComentario}`]}
									/>
									<ErrorMessages
										errorList={
											get(erros, ['areaRemanescenteMenorPoligonal5']) ||
											(errosSubmissao || []).filter(e =>
												e.campo === 'areaRemanescenteMenorPoligonal5' ? e.message : null
											)
										}
									/>
								</div>
							</>
						)}
					</>
				)}
				{includesTipoForm([MULTIFAMILIAR, MULTIFAMILIAR_NAO_RESIDENCIAL, HABITACAO_COLETIVA], tipoForm) && (
					<>
						<div
							className={`pergunta ${
								temComentario[`areaMatricula3000${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name="areaMatricula3000"
								label="O terreno possui matrícula acima de 3.000,00 m²?"
								options={[
									{ codigo: 'sim', descricao: 'Sim', warning: 'Observar Art. 155, inciso II do PDDUA.' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.areaMatricula3000}
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['areaMatricula3000']}
								dataAtual={localData['areaMatricula3000']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`areaMatricula3000${sufixoComentario}`}
								valueComentario={localData[`areaMatricula3000${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`areaMatricula3000${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['areaMatricula3000']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'areaMatricula3000' ? e.message : null))
								}
							/>
						</div>
					</>
				)}
				{areaMatricula3000 && (
					<>
						<div
							className={`pergunta ${
								temComentario[`oriundoParcelamentoSolo${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name="oriundoParcelamentoSolo"
								label="O terreno é oriundo de parcelamento do solo?"
								options={[
									{
										codigo: 'sim',
										descricao: 'Sim'
									},
									{ codigo: 'nao', descricao: 'Não' }
								]}
								required={true}
								onChangeHandler={onChangeHandler}
								value={localData.oriundoParcelamentoSolo}
								disabled={disabled}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={dataAnterior['oriundoParcelamentoSolo']}
								dataAtual={localData['oriundoParcelamentoSolo']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`oriundoParcelamentoSolo${sufixoComentario}`}
								valueComentario={localData[`oriundoParcelamentoSolo${sufixoComentario}`]}
								onChangeComentarioHandler={
									typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null
								}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`oriundoParcelamentoSolo${sufixoComentario}`]}
							/>
							<ErrorMessages
								errorList={
									get(erros, ['oriundoParcelamentoSolo']) ||
									(errosSubmissao || []).filter(e => (e.campo === 'oriundoParcelamentoSolo' ? e.message : null))
								}
							/>
						</div>
					</>
				)}
				<div
					className={`pergunta metragem ${
						temComentario[`areaAplicacaoRU${sufixoComentario}`]
							? 'resposta-com-comentario pin-comentario'
							: usuarioInterno
							? 'resposta-com-comentario'
							: ''
					}`}
				>
					<NumberFieldLE
						name="areaAplicacaoRU"
						label="Área para aplicação do Regime Urbanístico (m²)"
						placeHolder=""
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.areaAplicacaoRU}
						decimals={2}
						maxSize="10"
						readOnly={true}
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['areaAplicacaoRU']}
						dataAtual={localData['areaAplicacaoRU']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`areaAplicacaoRU${sufixoComentario}`}
						valueComentario={localData[`areaAplicacaoRU${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`areaAplicacaoRU${sufixoComentario}`]}
					/>
					<ErrorMessages
						errorList={
							get(erros, ['areaAplicacaoRU']) ||
							(errosSubmissao || []).filter(e => (e.campo === 'areaAplicacaoRU' ? e.message : null))
						}
					/>
				</div>
			</div>
		</fieldset>
	);
}

InfosTerrenoAP.displayName = 'InfosTerrenoAP';
InfosTerrenoAP.propTypes = {
	tipoForm: PropTypes.string,
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	onChangeHandler: PropTypes.func,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	disabled: PropTypes.bool,
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any
};
