import { size, trim, get } from 'lodash';

import { verificaEnquadramentoDefinicao } from 'utils/tools';

import {
	E_DEMOLICAO_PARCIAL,
	E_DEMOLICAO_TOTAL,
	E_DESMEMBRAMENTO_UNIFICACAO,
	E_DESMEMBRA_UNIFICA_RECICLA_USO_PARCIAL,
	E_RECICLAGEM_USO_TOTAL,
	E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL,
	E_RECICLAGEM_USO_PARCIAL
} from '.';

export const validarLimpar = (data, definicao) => {
	let errors = {};

	if (verificaEnquadramentoDefinicao([E_DEMOLICAO_PARCIAL, E_DEMOLICAO_TOTAL], definicao)) {
		if (size(data?.tiposMaterial) === 0) {
			errors.tiposMaterial = ['Você deve adicionar ao menos um "Tipo de material".'];
		} else {
			(data?.tiposMaterial || []).forEach((tm, i) => {
				if (size(tm.tipoConstrucao) === 0) {
					errors[`tipoConstrucao${i}`] = ['Você deve informar o tipo de construção'];
				}
				if (
					(!tm.areaDemolicaoRegular ||
						tm.areaDemolicaoRegular.value === 0 ||
						tm.areaDemolicaoRegular.value === '0.00' ||
						tm.areaDemolicaoRegular.value === '0,00') &&
					(!tm.areaDemolicaoIrregular ||
						tm.areaDemolicaoIrregular.value === 0 ||
						tm.areaDemolicaoIrregular.value === '0.00' ||
						tm.areaDemolicaoIrregular.value === '0,00')
				) {
					const errorMsg = 'Você deve informar pelo menos uma das áreas de demolição (regular ou irregular)';
					errors[`areaDemolicaoRegular${i}`] = [errorMsg];
					errors[`areaDemolicaoIrregular${i}`] = [errorMsg];
					delete tm.areaDemolicaoRegular;
					delete tm.areaDemolicaoIrregular;
				}

				// if (tipoForm === E_DEMOLICAO_PARCIAL) {
				// 	if (
				// 		!tm.areaPermanecerRegular ||
				// 		tm.areaPermanecerRegular.value === 0 ||
				// 		tm.areaPermanecerRegular.value === '0.00' ||
				// 		tm.areaPermanecerRegular.value === '0,00'
				// 	) {
				// 		errors[`areaPermanecerRegular${i}`] = ['Você deve informar a área à permanecer regular'];
				// 	}
				// }
			}); // each
		}
	}

	if (
		verificaEnquadramentoDefinicao(
			[
				E_RECICLAGEM_USO_PARCIAL,
				E_RECICLAGEM_USO_TOTAL,
				E_DESMEMBRAMENTO_UNIFICACAO,
				E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL,
				E_DESMEMBRA_UNIFICA_RECICLA_USO_PARCIAL
			],
			definicao
		)
	) {
		// if (E_RECICLAGEM_USO_TOTAL === tipoForm) {
		// 	if (!data?.areaReciclar) {
		// 		errors.areaReciclar = ['O campo "Área a reciclar" é obrigatório'];
		// 	}
		// }
		if (verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_PARCIAL], definicao)) {
			if (!data?.areaReciclar) {
				errors.areaReciclar = ['O campo "Área a reciclar" é obrigatório'];
			}

			// if (!data?.numeroEconomias) {
			// 	errors.numeroEconomias = ['O campo "Número de economias" é obrigatório'];
			// }

			if (size(trim(get(data, 'planoDiretor.dadosSubunidades'))) === 0) {
				errors.planoDiretor = ['O campo "Plano diretor" é obrigatório, com pelo menos uma subunidade'];
			} else {
				(data?.planoDiretor?.dadosSubunidades || []).forEach(su => {
					if (size(trim(get(su, 'dadosAtividades'))) === 0) {
						errors.planoDiretor = [
							'O campo "Plano diretor" é obrigatório, com pelo menos uma atividade em cada subunidade selecionada'
						];
					} else {
						// é multifamiliar?
						if (get(su, 'dadosAtividades', []).some(atv => get(atv, 'atividadeAnexo52.item') === '1.3.')) {
							if (!data?.areaTotalMatriculaLote) {
								errors.areaTotalMatriculaLote = ['O campo é obrigatório'];
							}
							if (!data?.numeroEconomiasTotais) {
								errors.numeroEconomiasTotais = ['O campo é obrigatório'];
							}
							// obrigatoriedade removida na história 61498 item 1.5
							// if (!data?.quotaIdealTotalProjeto) {
							// 	errors.quotaIdealTotalProjeto = ['O campo é obrigatório'];
							// }
							// if (!data?.atendeQuotaIdeal) {
							// 	errors.atendeQuotaIdeal = ['O campo é obrigatório'];
							// }
						}
					}
				});
			}
		}
		if (
			verificaEnquadramentoDefinicao(
				[E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL, E_DESMEMBRA_UNIFICA_RECICLA_USO_PARCIAL],
				definicao
			)
		) {
			// if (!data?.areaReciclar) {
			// 	errors.areaReciclar = ['O campo "Área a reciclar" é obrigatório'];
			// }

			// if (!data?.numeroEconomias) {
			// 	errors.numeroEconomias = ['O campo "Número de economias" é obrigatório'];
			// }

			if (size(trim(get(data, 'planoDiretor.dadosSubunidades'))) === 0) {
				errors.planoDiretor = ['O campo "Plano diretor" é obrigatório, com pelo menos uma subunidade'];
			} else {
				(data?.planoDiretor?.dadosSubunidades || []).forEach(su => {
					if (size(trim(get(su, 'dadosAtividades'))) === 0) {
						errors.planoDiretor = [
							'O campo "Plano diretor" é obrigatório, com pelo menos uma atividade em cada subunidade selecionada'
						];
					}
				});
			}
			if (!data?.termoQuantoAreaOcupacaoIntensiva) {
				errors.termoQuantoAreaOcupacaoIntensiva = ['É necessário concordar com o termo acima'];
			}
		}
		if (
			verificaEnquadramentoDefinicao([E_DESMEMBRAMENTO_UNIFICACAO, E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL], definicao)
		) {
			if (!data?.l6Option) {
				errors.l6Option = ['Você deve selecionar esta opção'];
			} else {
				if (data?.l6Option.indexOf('economias') > -1) {
					if (!data?.numeroEconomiasAtuais && !data?.numeroEconomiasPropostas) {
						errors.numeroEconomiasPropostas = ['Você deve informar ao menos um dos campos de número de economias'];
					}
					if (verificaEnquadramentoDefinicao([E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL], definicao)) {
						if (!data?.economiasDesmembrarUnificar && !data?.economiasDesmembradasUnificadas) {
							errors.economiasDesmembradasUnificadas = [
								'Você deve informar ao menos um dos campos de descrição deeconomias para desmembramento ou unificação'
							];
						}
					}
				}
				if (data?.l6Option.indexOf('boxes') > -1) {
					if (!data?.numeroBoxesAtuais && !data?.numeroBoxesPropostos) {
						errors.numeroBoxesPropostos = ['Você deve informar ao menos um dos campos de número de boxes'];
					}
					if (verificaEnquadramentoDefinicao([E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL], definicao)) {
						if (!data?.boxesDesmembrarUnificar && !data?.boxesDesmembradosUnificados) {
							errors.boxesDesmembradosUnificados = [
								'Você deve informar ao menos um dos campos de descrição de boxes para desmembramento ou unificação'
							];
						}
					}
				}
			}
		}
	}

	if (
		verificaEnquadramentoDefinicao(
			[
				E_RECICLAGEM_USO_PARCIAL,
				E_RECICLAGEM_USO_TOTAL
				//E_DESMEMBRAMENTO_UNIFICACAO,
				//E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL
			],
			definicao
		)
	) {
		// Coloque a lógica de validação de impactoUrbano e viabilidadeUrbanistica aqui
		const impactoUrbano = data.projetoImpactoUrbano;
		const viabilidadeUrbanistica = data.projetoViabilidaUrbanistico;
		const capConstrutiva = data.capacidadeConstrutiva;
		const reciclagem = data.reciclagemUso;

		// Se impactoUrbano é 'sim', viabilidadeUrbanistica também deve ser 'sim'
		if (impactoUrbano === 'sim' && viabilidadeUrbanistica !== 'sim') {
			errors.projetoViabilidaUrbanistico = ['É necessário marcar "Sim" para viabilidade urbanística.'];
		}

		// Se impactoUrbano não está marcado
		if (!impactoUrbano) {
			errors.projetoImpactoUrbano = ['Este campo é obrigatório.'];
		}

		// Se capacidadeConstrutiva é 'sim', reciclagem também deve ser 'sim'
		if (capConstrutiva === 'nao' && reciclagem !== 'sim') {
			errors.reciclagemUso = ['É necessário marcar "Sim" para RECICLAGEM DE USO.'];
		}
		// Se capacidadeConstrutiva não está marcado
		if (!capConstrutiva) {
			errors.capacidadeConstrutiva = ['Este campo é obrigatório.'];
		}
	}

	return errors;
};
