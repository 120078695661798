import React from 'react';
import { useCallback } from 'react';

import PropTypes from 'prop-types';

import { NumberField } from 'containers/Form/metadata-template/fields';

import { METAVISTORIA } from '.';

export function ListaEconomias({ readOnly, onChange, lista: listaEconomias = [{}] }) {
	const localChange = useCallback(
		changes => {
			const { name, value, pos } = changes;
			// handle array de informacoes
			if (name === METAVISTORIA.listaEconomias.name) {
				switch (value) {
					case 'add':
						listaEconomias.push({});
						break;
					case 'remove':
						listaEconomias.splice(pos, 1);
						break;
					default:
						break;
				}
			} else {
				// handle fields
				if (name === 'errors') {
					return;
				}
				const oldControl = listaEconomias[pos];
				listaEconomias.splice(pos, 1, { ...oldControl, [name]: value });
			}
			const newvalue = [...listaEconomias];
			onChange && onChange({ name: METAVISTORIA.listaEconomias.name, value: newvalue });
		},
		[listaEconomias, onChange]
	);

	const elementos = listaEconomias.map((le, index) => (
		<div key={index} className="row align-items-center">
			<div className="col">
				<div className="form-group">
					<label className="control-label">Discriminação de Economias</label>
				</div>
			</div>
			<div className="col">
				<div className="form-group">
					{/* <label className="control-label">{METAVISTORIA.infoObraEconomiaInicial.label}</label> */}
					<NumberField
						label={METAVISTORIA.infoObraEconomiaInicial.label}
						name={METAVISTORIA.infoObraEconomiaInicial.name}
						value={le[METAVISTORIA.infoObraEconomiaInicial.name]}
						required={METAVISTORIA.infoObraEconomiaInicial.required}
						onChangeHandler={([valid]) => localChange({ name: valid.name, pos: index, value: valid.value })}
					/>
				</div>
			</div>
			<div className="col">
				<div className="form-group">
					{/* <label className="control-label">{METAVISTORIA.infoObraEconomiaFinal.label}</label> */}
					<NumberField
						label={METAVISTORIA.infoObraEconomiaFinal.label}
						name={METAVISTORIA.infoObraEconomiaFinal.name}
						value={le[METAVISTORIA.infoObraEconomiaFinal.name]}
						required={METAVISTORIA.infoObraEconomiaFinal.required}
						onChangeHandler={([valid]) => localChange({ name: valid.name, pos: index, value: valid.value })}
					/>
				</div>
			</div>
			<div className="col-md-2">
				{!readOnly && listaEconomias.length > 1 && (
					<button
						type="button"
						className="btn btn-danger btn-sm float-right m-1"
						onClick={() => localChange({ name: METAVISTORIA.listaEconomias.name, pos: index, value: 'remove' })}
						disabled={false}
					>
						Remover <i className="fa fa-trash" aria-hidden="true" />
					</button>
				)}
			</div>
		</div>
	));

	return (
		<>
			{elementos}
			{!readOnly && (
				<button
					className="btn btn-sm btn-secondary"
					type="button"
					onClick={() => localChange({ name: METAVISTORIA.listaEconomias.name, value: 'add' })}
					disabled={readOnly}
					style={{ marginBottom: '10px' }}
				>
					{listaEconomias.length >= 1 ? 'Adicionar Intervalo ' : 'Discriminar economias '}
					<i className="fa fa-plus" aria-hidden="true" />
				</button>
			)}
		</>
	);
}

ListaEconomias.displayName = 'ListaEconomias';
ListaEconomias.propTypes = {
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	lista: PropTypes.array
};
