import React, { useEffect, useState } from 'react';
import './style/HistoricoRespostaAnterior.scss';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { size } from 'lodash';

const HistoricoRespostaAnterior = ({ dataAnterior, dataAtual, usuarioInterno = false, msgAlt = null }) => {
	const [respostaAnterior, setRespostaAnterior] = useState(null);
	const [respostaAtual, setRespostaAtual] = useState(null);

	useEffect(() => {
		if (size(dataAnterior) > 0) {
			if (dataAnterior.data) {
				setRespostaAnterior(dataAnterior.data);
			} else if (dataAnterior.value) {
				setRespostaAnterior(dataAnterior.value);
			} else if (typeof dataAnterior === 'object' && !Array.isArray(dataAnterior) && dataAnterior != null) {
				setRespostaAnterior(
					Object.values(dataAnterior)
						.map(tp => tp['descricao'])
						.toString()
				);
			} else {
				setRespostaAnterior(dataAnterior === 'nao' ? 'Não' : dataAnterior === 'sim' ? 'Sim' : dataAnterior);
			}
		}
	}, [dataAnterior]);

	useEffect(() => {
		if (size(dataAtual) > 0) {
			if (dataAtual.data) {
				setRespostaAtual(dataAtual.data);
			} else if (dataAtual.value) {
				setRespostaAtual(dataAtual.value === 'nao' ? 'Não' : dataAtual.value === 'sim' ? 'Sim' : dataAtual.value);
			} else if (typeof dataAtual === 'object' && !Array.isArray(dataAtual) && dataAtual != null) {
				setRespostaAtual(
					Object.values(dataAtual)
						.map(tp => tp['descricao'])
						.toString()
				);
			} else {
				setRespostaAtual(dataAtual === 'nao' ? 'Não' : dataAtual === 'sim' ? 'Sim' : dataAtual);
			}
		}
	}, [dataAtual]);

	return usuarioInterno ? (
		<>
			{respostaAnterior && respostaAnterior !== respostaAtual ? (
				<div className="warning-message hra-wrapper">
					<Tippy placement="top" content={'Este valor foi alterado pelo requerente na última complementação'}>
						<i className="fa fa-exclamation-triangle" title="Esta resposta foi alterada pelo requerente."></i>
					</Tippy>
					&nbsp;
					<strong>{msgAlt ? msgAlt : respostaAnterior}</strong>
				</div>
			) : !respostaAnterior && size(respostaAtual) > 0 ? (
				<div className="info-message hra-wrapper">
					<Tippy
						placement="top"
						content={
							'Este valor foi adicionado pelo requerente ou automaticamente pelo sistema na última complementação'
						}
					>
						<i className="fa fa-exclamation-circle" title="Esta resposta foi adicionada pelo requerente."></i>
					</Tippy>
					&nbsp;
					<strong>{msgAlt ? msgAlt : 'novo'}</strong>
				</div>
			) : null}
		</>
	) : null;
};
HistoricoRespostaAnterior.displayName = 'HistoricoRespostaAnterior';

HistoricoRespostaAnterior.propTypes = {
	dataAnterior: PropTypes.any,
	dataAtual: PropTypes.any,
	usuarioInterno: PropTypes.bool,
	msgAlt: PropTypes.string
};

export default HistoricoRespostaAnterior;
