import React from 'react';

import PropTypes from 'prop-types';

import { isNil } from 'lodash';
import moment from 'moment';
import Calendar from 'rc-calendar';
import ptBR from 'rc-calendar/lib/locale/pt_BR';
import DatePicker from 'rc-calendar/lib/Picker';

import 'rc-calendar/assets/index.css';

const Calendario = props => {
	const calendar = (
		<Calendar
			locale={ptBR}
			dateInputPlaceholder="informe a data"
			formatter={'DD/MM/YYYY'}
			disabledTime={null}
			timePicker={null}
			defaultValue={props.defaultCalendarValue || hoje}
			showDateInput={props.showDateInput || false}
			disabledDate={props.disabledDate || (() => false)}
		/>
	);

	let valor = props.value;
	if (valor && valor.target) {
		valor = valor.target.value;
	}
	if (isNil(valor) || isNil(typeof valor) || valor === '') {
		valor = null;
	} else {
		if (typeof valor === 'number') {
			valor = moment(valor).locale('pt-br');
		} else {
			valor = props.value.locale('pt-br');
		}
	}
	const valorAsString = valor ? valor.format('DD/MM/YYYY') : '';

	return (
		<div className={props.containerClass}>
			<DatePicker
				animation="slide-up"
				disabled={props.disabled || false}
				calendar={calendar}
				value={valor}
				onChange={props.onChange}
				placement={props.placement || 'bottomLeft'}
			>
				{() => (
					<input
						type="text"
						placeholder={props.disabled ? '' : 'informe a data'}
						disabled={props.disabled || false}
						readOnly
						value={valorAsString}
						className={`${props.inputClass}${props.disabled ? '' : ' ant-calendar-picker-input ant-input'}`}
					/>
				)}
			</DatePicker>
			{!props.disabled && (
				<button type="button" aria-label="limpar data" className={props.btnClass} onClick={() => props.onChange('')}>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
			)}
		</div>
	);
};
Calendario.displayName = 'Calendario';
Calendario.propTypes = {
	defaultCalendarValue: PropTypes.object,
	showDateInput: PropTypes.bool,
	disabledDate: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	onChange: PropTypes.func,
	placement: PropTypes.string,
	inputClass: PropTypes.string,
	containerClass: PropTypes.string,
	btnClass: PropTypes.string
};
Calendario.defaultProps = {
	btnClass: 'btn btn-calendar'
};

export default Calendario;
export const dataSemHora = data => data.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
export const ontem = dataSemHora(moment().add(-1, 'days'));
export const hoje = dataSemHora(moment());
export const amanha = dataSemHora(moment().add(1, 'days'));
export const disabledFuture = data => dataSemHora(data || hoje).valueOf() >= amanha.valueOf();
export const disabledPast = data => dataSemHora(data || hoje).valueOf() <= ontem.valueOf();
