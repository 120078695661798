import React from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { formatCodigoEU } from 'utils/tools';

export function InformacoesEUEnderecos(props) {
	const { expediente = {}, enderecos } = props;

	const { licencas, projetosEdificacao } = expediente;
	let mensagem;
	if (!size(licencas) && !size(projetosEdificacao)) {
		mensagem =
			'Não foi identificado projeto ou licença para o Expediente Único informado. Para seguir com a sua tramitação, nas "Informações Projeto/Licença", informe qual é a solicitação e selecione o projeto ou licença objeto da solicitação. Selecione "Outra data" e informe a data do projeto/licença. Se não for constatado projeto/licença ao longo da tramitação, o seu requerimento será indeferido.';
	} else {
		mensagem = ''; //'Foi identificado um projeto/licença para o Expediente Único informado.';
	}

	return (
		<fieldset>
			<legend>Informações de Expediente Único / Endereços</legend>
			<div className="row id-vist id-vist-dinamico">
				<div className="col-auto exp-un">
					<label>Expediente Único: </label>
					<span> {formatCodigoEU(get(expediente, 'codigo'))}</span>
				</div>
			</div>
			<div className="id-vist">
				<label>Endereço(s) da solicitação: </label>
				<div className="endereco">
					{size(enderecos) > 0 && (
						<ul>
							{enderecos.map((item, index) => (
								<li key={index}>{item.enderecoFormatadoCurto}</li>
							))}
						</ul>
					)}
				</div>
			</div>
			{size(mensagem) > 0 && (
				<div className="form-group col-md-12 text text-info">
					<p>{mensagem}</p>
					{/* <p>
						"Selecione o projeto/licença" escolha a opção "Outra data" e informe a data do projeto/licença. Se não for
						constatado projeto/licença, o seu requerimento será indeferido.
					</p> */}
				</div>
			)}
		</fieldset>
	);
}
InformacoesEUEnderecos.displayName = 'InformacoesEUEnderecos';
InformacoesEUEnderecos.propTypes = {
	expediente: PropTypes.object,
	enderecos: PropTypes.array
};
