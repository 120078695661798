import { routerMiddleware } from 'connected-react-router';
import { fromJS } from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createReducer from 'config/createReducer';

import { history } from 'index';

const sagaMiddleware = createSagaMiddleware();

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const configureStore = (initialState = {}) => {
	const middlewares = [sagaMiddleware];

	const enhancers = [applyMiddleware(routerMiddleware(history), ...middlewares)];

	const composeEnhancers =
		isLocalhost && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					shouldHotReload: false
			  })
			: compose;

	const store = createStore(createReducer({}), fromJS(initialState), composeEnhancers(...enhancers));

	store.runSaga = sagaMiddleware.run;
	store.injectedReducers = {};
	store.injectedSagas = {};

	if (module.hot) {
		module.hot.accept('config/createReducer', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
		});
	}

	return store;
};

export default configureStore;
