import React from 'react';

import PropTypes from 'prop-types';

import { isNil, isEmpty } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';

import { NumberField } from 'containers/Form/metadata-template/fields';

import { accessApi } from 'utils/injectApi';

import Loader from './Loader';
import Selecao from './Selecao';

// const CLASS_NAME_FORM_CONTROL = 'form-control';
// const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class AtividadesLicenciaveis extends React.Component {
	constructor(props) {
		super(props);
		const { formData } = this.props;
		this.state = {
			codram: formData.codram,
			descricao: formData.descricao,
			medidaPorte: formData.medidaPorte,
			unidadeMedidaPorte: formData.unidadeMedidaPorte,
			permiteProtocolar: undefined,
			valid: true,
			options: [],
			option: null,
			loading: true
		};
	}

	async componentDidMount() {
		const result = await accessApi('/collections/consema-atividades-licenciaveis', true);
		const options = result.data.sort((o1, o2) => {
			const c1 = parseFloat(o1.codram.replace(',', '.'));
			const c2 = parseFloat(o2.codram.replace(',', '.'));
			return c1 > c2 ? 1 : -1;
		});
		let option = this.state.option;
		if (this.props.formData.codram) {
			option = options.find(o => o.codram === this.props.formData.codram);
		}
		this.setState({ options, option, loading: false });
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.formData.codram !== prevState.codram || nextProps.formData.medidaPorte !== prevState.medidaPorte) {
			const newState = { valid: false }; // !(nextProps.formData.codram === '' || nextProps.formData.medidaPorte === '') };
			return newState;
		}
		return prevState;
	}

	static onValidate(data, errors) {
		const { atividade, option } = data;

		if (this.classificaPorte(atividade?.medidaPorte, option) !== 'protocolar') {
			errors.atividadeEmpreendimento.atividade.addError('Esta atividade não é incidente de licenciamento.');
		}

		return errors;
	}

	static mapeiaLimitesCodram(staticParams) {
		let limites = {
			naoIncidencia: undefined,
			porteMinimo: undefined,
			portePequeno: undefined,
			porteMedio: undefined,
			porteGrande: undefined,
			porteExcepcional: undefined
		};

		let option;
		staticParams !== undefined ? (option = staticParams) : (option = this.state?.option);

		if (option?.naoIncidencia === 'todos os portes') {
			limites.naoIncidencia = 'todos os portes';
			return limites;
		}
		if (option?.porteMinimo === 'Único') {
			limites.porteUnico = true;
			return limites;
		}

		Object.keys(option || {}).forEach(key => {
			let auxString = '';
			switch (key) {
				case 'naoIncidencia':
					if (!isNil(option[key])) {
						if (option[key].startsWith('até ')) {
							limites[key] = ['0,00', option[key].slice(4)];
						} else if (option[key].startsWith('até')) {
							limites[key] = ['0,00', option[key].slice(3)];
						}
					} else {
						limites[key] = null;
					}
					break;
				case 'porteMinimo':
					if (option[key].startsWith('até ')) {
						limites[key] = ['0,00', option[key].slice(4)];
					} else if (option[key].startsWith('até')) {
						limites[key] = ['0,00', option[key].slice(3)];
					} else {
						if (option[key].startsWith('de ')) {
							auxString = option[key].slice(3);
						} else {
							auxString = option[key].slice(2);
						}
						auxString = auxString.split(' ');
						limites[key] = [auxString[0], auxString[2]];
					}
					break;
				case 'portePequeno':
				case 'porteMedio':
				case 'porteGrande':
					if (option[key].startsWith('de ')) {
						auxString = option[key].slice(3);
					} else {
						auxString = option[key].slice(2);
					}
					auxString = auxString.split(' ');
					limites[key] = [auxString[0], auxString[2]];
					break;
				case 'porteExcepcional':
					if (option[key] === 'demais') {
						limites[key] = option[key];
					} else if (!isNil(option[key])) {
						auxString = option[key].slice('acima de '.length);
					}
					break;
				default:
					break;
			}
		});
		return limites;
	}

	classificaPorte(medidaPorte) {
		let permiteProtocolar = AtividadesLicenciaveis.classificaPorte(medidaPorte, this?.state?.option);
		this.setPermiteProtocolar(permiteProtocolar);
		return permiteProtocolar;
	}

	static classificaPorte(medidaPorte, staticParams) {
		let codramObject, limites;
		let tipoPorte = undefined;

		if (staticParams !== undefined) {
			codramObject = staticParams;
			limites = AtividadesLicenciaveis.mapeiaLimitesCodram(codramObject);
		} else {
			codramObject = this?.state?.option;
			limites = this.mapeiaLimitesCodram();
		}

		if (limites.naoIncidencia === 'todos os portes') {
			tipoPorte = 'naoIncidencia';
		} else if (limites?.porteUnico) {
			tipoPorte = 'porteMinimo';
		} else if (
			Array.isArray(limites.porteMinimo) &&
			parseFloat(limites.porteMinimo[0]) <= medidaPorte &&
			parseFloat(limites.porteMinimo[1]) >= medidaPorte
		) {
			tipoPorte = 'porteMinimo';
		} else if (
			Array.isArray(limites.portePequeno) &&
			parseFloat(limites.portePequeno[0]) <= medidaPorte &&
			parseFloat(limites.portePequeno[1]) >= medidaPorte
		) {
			tipoPorte = 'portePequeno';
		} else if (
			Array.isArray(limites.porteMedio) &&
			parseFloat(limites.porteMedio[0]) <= medidaPorte &&
			parseFloat(limites.porteMedio[1]) >= medidaPorte
		) {
			tipoPorte = 'porteMedio';
		} else if (
			Array.isArray(limites.porteGrande) &&
			parseFloat(limites.porteGrande[0]) <= medidaPorte &&
			parseFloat(limites.porteGrande[1]) >= medidaPorte
		) {
			tipoPorte = 'porteGrande';
		} else if (
			Array.isArray(limites.naoIncidencia) &&
			parseFloat(limites.naoIncidencia[0]) <= medidaPorte &&
			parseFloat(limites.naoIncidencia[1]) >= medidaPorte
		) {
			tipoPorte = 'naoIncidencia';
		} else {
			tipoPorte = 'porteExcepcional';
		}

		let permiteProtocolar = codramObject?.competenciaMunicipal.includes(tipoPorte)
			? 'protocolar'
			: tipoPorte === 'naoIncidencia'
			? 'bloquear'
			: 'restringir';

		if (this?.state?.permiteProtocolar !== undefined) {
			this.setPermiteProtocolar(permiteProtocolar);
		}
		return permiteProtocolar;
	}

	onChangeNumberField = obj => {
		if (Array.isArray(obj)) {
			obj.forEach(o => {
				if (o.name !== 'errors') {
					this.setMedidaPorte(o.value.value);
					this.classificaPorte(parseFloat(o.value.value));
				}
			});
		}
	};

	setCodram(codram, descricao, unidadeMedidaPorte) {
		this.setState({ codram, descricao, unidadeMedidaPorte });
		this.props.onChange({ codram, descricao, unidadeMedidaPorte, medidaPorte: this.state.medidaPorte });
	}

	setMedidaPorte(medidaPorte) {
		this.setState({ medidaPorte });
		this.props.onChange({
			codram: this.state.codram,
			descricao: this.state.descricao,
			unidadeMedidaPorte: this.state.unidadeMedidaPorte,
			medidaPorte
		});
	}

	setOption(option) {
		this.setState({ option });
		if (option) {
			this.setCodram(option.codram, option.descricao, option.unidadeMedidaPorte);
		} else {
			this.setCodram(null, null, null);
		}
	}

	setPermiteProtocolar(permiteProtocolar) {
		this.setState({ permiteProtocolar });
	}

	render() {
		const { schema, errorSchema, disabled } = this.props; //, readonly
		const { codram: errorCodram, medidaPorte: errorMedidaPorte } = errorSchema;
		const { options, option, optionSearchTerm } = this.state;
		const requireds = schema.required || [];
		const requiredCodram = requireds.indexOf('codram') > -1;
		const requiredMedidaPorte = requireds.indexOf('medidaPorte') > -1;

		return (
			<>
				{this.state.loading && <Loader />}
				<div className="d-flex flex-wrap">
					<div className="form-group col-md-6" style={{ whiteSpace: 'nowrap' }}>
						<label className="control-label hideable" htmlFor="root_nome">
							CODRAM (
							<a
								href="https://www.sema.rs.gov.br/upload/arquivos/202207/07113333-372-2018-atividades-licenciaveis-compilada.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								Resolução CONSEMA
							</a>
							){requiredCodram ? '*' : ''}
						</label>
						<div />
						<Selecao
							className={'form-control'}
							detailInnerClassName={'title-with-button-white-space-normal'}
							multiple={false}
							selected={option || ''}
							label={'CODRAM'}
							detailCodigo={'codram'}
							detailDescricao={'descricao'}
							autoShowList={true}
							searchTerm={optionSearchTerm}
							searchList={(options || [])
								.filter(o => JSON.stringify(o).indexOf(optionSearchTerm) > -1)
								.filter(o => Object.prototype.hasOwnProperty.call(o, 'competenciaMunicipal'))}
							searchTermMinLength={0}
							errorList={false}
							onChangeSearchTerm={e => this.setState({ optionSearchTerm: e.target.value })}
							onBlurSearchTerm={() => false}
							onSelectItem={item => () => this.setOption(item)}
							onUnselect={() => () => this.setOption(null)}
							loading={false}
							required={true}
							detailModifier={prop => prop}
							placeholder="Selecione a atividade"
							disabled={disabled}
							readOnly={disabled}
						/>
						<ErrorDetail attr={errorCodram} />
					</div>
					<div className="form-group col-md-3">
						<label>Unidade de Medida</label>
						{/* <input type="text" readOnly aria-readonly value={this.state?.option?.unidadeMedidaPorte || ''} className="form-control" /> */}
						<textarea
							type="text"
							readOnly
							aria-readonly
							value={this.state?.option?.unidadeMedidaPorte || ''}
							className="form-control"
						/>
					</div>
					<div className="form-group col-md-3">
						<NumberField
							name="medidaPorte"
							label="Medida de Porte"
							placeHolder="0,00"
							required={requiredMedidaPorte}
							onChangeHandler={e => this.onChangeNumberField(e)}
							value={{ value: this.state.medidaPorte } || {}}
							decimals={2}
							maxSize="10"
							disabled={disabled}
						/>
						<ErrorDetail attr={errorMedidaPorte} />
					</div>
				</div>
				{this.state.permiteProtocolar === 'restringir' && !isNil(this.state.medidaPorte) && isEmpty(errorSchema) && (
					<li className={'error-message'} style={{ listStyle: 'none' }}>
						<i className="fa fa-exclamation-circle" style={{ marginRight: '8px' }} aria-hidden="true" />
						<span style={{ whiteSpace: 'pre-wrap' }}>
							Este licenciamento deve ser realizado no governo do Estado do Rio Grande do Sul através do endereço{' '}
							<a href="https://secweb.procergs.com.br/sra">https://secweb.procergs.com.br/sra</a>.
						</span>
					</li>
				)}
				{this.state.permiteProtocolar === 'bloquear' && !isNil(this.state.medidaPorte) && isEmpty(errorSchema) && (
					<li className={'error-message'} style={{ listStyle: 'none' }}>
						<i className="fa fa-exclamation-circle" style={{ marginRight: '8px' }} aria-hidden="true" />
						<span style={{ whiteSpace: 'pre-wrap' }}>Esta atividade não é incidente de licenciamento.</span>
					</li>
				)}
			</>
		);
	}
}
AtividadesLicenciaveis.displayName = 'AtividadesLicenciaveis';
AtividadesLicenciaveis.propTypes = {
	formData: PropTypes.object,
	onChange: PropTypes.func,
	schema: PropTypes.object,
	errorSchema: PropTypes.object,
	readonly: PropTypes.bool,
	disabled: PropTypes.bool
};

export default AtividadesLicenciaveis;
