import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';

import { map } from 'lodash';
import uuid from 'uuid/v4';

import useLocalStorage from 'custom-hooks/useLocalStorage';

export const LIST_PLACEHOLDER = 'list_placeholder';

export function Breadcrumbs({ items }) {
	const store = useStore();
	const [filtroOrgaoLS] = useLocalStorage('filtroOrgao');

	const [filtroOrgao, setFiltroOrgao] = useState(null);

	useEffect(() => {
		const subscription = store.$filtros.subscribe(filtros => {
			// pega o valor do Subject $filtros da store
			if (filtros?.filtroOrgao) {
				setFiltroOrgao(filtros.filtroOrgao);
			} else {
				// ou do local storage setado pela página da lista de processos
				if (filtroOrgaoLS) {
					setFiltroOrgao(filtroOrgaoLS);
				}
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [filtroOrgaoLS, store]);

	const list = useMemo(
		() => (filtroOrgao ? `SERVIÇOS ${filtroOrgao?.toUpperCase()}` : 'SERVIÇOS URBANÍSTICOS E AMBIENTAIS'),
		[filtroOrgao]
	);

	items = items.map((li, index) => {
		if (typeof li === 'string' && li === LIST_PLACEHOLDER) {
			let state = null;
			if (filtroOrgao) {
				state = { orgao: filtroOrgao };
			}
			return (
				<li key={uuid()}>
					{index === items.length - 1 ? (
						list
					) : (
						<Link
							to={{
								pathname: '/list',
								state
							}}
						>
							{list}
						</Link>
					)}
				</li>
			);
		} else {
			return li;
		}
	});
	return (
		<ul className="breadcrumb">
			<Inicio filter={filtroOrgao} />
			{map(items, i => i)}
		</ul>
	);
}
Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any)
};

export default Breadcrumbs;

function Inicio({ filter }) {
	let output = null;
	switch (filter) {
		case undefined:
		case null:
			output = <li>{<Link to="/">Início</Link>}</li>;
			break;

		default:
			output = (
				<li>
					<a href="https://prefeitura.poa.br/carta-de-servicos">Carta de Serviços</a>
				</li>
			);
			break;
	}
	return output;
}
Inicio.displayName = 'Inicio';
Inicio.propTypes = {
	filter: PropTypes.string
};
