import React from 'react';

import PropTypes from 'prop-types';

import { useStore } from 'react-redux';
import { Route } from 'react-router';

import { get } from 'lodash';

import AccessDenied from 'components/403Page';

const PrivateRoute = ({ component: Component, unauthorizedComponent: UnauthorizedComponent, ...props }) => {
	const store = useStore();

	const isAllowed = roles => {
		let allow = true;
		if (roles) {
			allow = roles.reduce((acc, role) => acc && (store.kc.hasResourceRole(role) || store.kc.hasRealmRole(role)), true);
		}
		return allow;
	};

	if (get(store, 'kc.authenticated')) {
		store.kc.updateToken(5);
		return isAllowed(props.roles) ? (
			<Route {...props} render={innerProps => <Component {...innerProps} />} />
		) : props.unauthorizedComponent ? (
			<Route {...props} render={innerProps => <UnauthorizedComponent {...innerProps} />} />
		) : (
			<Route {...props} component={AccessDenied} />
		);
	} else {
		store.kc.login();
		return null;
	}
};
PrivateRoute.displayName = 'PrivateRoute';
PrivateRoute.contextTypes = {
	store: PropTypes.object
};

PrivateRoute.propTypes = {
	component: PropTypes.func,
	roles: PropTypes.arrayOf(PropTypes.string),
	unauthorizedComponent: PropTypes.func
};

export default PrivateRoute;
