import { useState, useEffect, useCallback } from 'react';

import { RECAPTCHA_SITE_KEY } from 'environments';

function useRecaptchaV3() {
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (window.grecaptcha) {
			setIsReady(true);
			return;
		}

		const script = document.createElement('script');
		script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
		script.async = true;
		script.defer = true;
		script.onload = () => {
			setIsReady(true);
		};
		document.head.appendChild(script);
	}, []);

	const executeRecaptcha = useCallback(
		async action => {
			if (!isReady || !window.grecaptcha) {
				return;
			}
			const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action });
			return token;
		},
		[isReady]
	);

	return executeRecaptcha;
}

export default useRecaptchaV3;
