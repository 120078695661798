import React from 'react';

import PropTypes from 'prop-types';

const EmptyList = ({ msg, children }) =>
	msg || children ? (
		<li className="empty-embed-list">
			<p className="msg">{msg}</p>
			{children}
		</li>
	) : null;
EmptyList.displayName = 'EmptyList';

EmptyList.propTypes = {
	msg: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default EmptyList;
