import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import logo from 'assets/images/logo.svg';

import ErrorMessages from './ErrorMessages';

class ClientError extends React.Component {
	render() {
		const htmlCode = size(this.props.errors) > 0 ? Object.keys(this.props.errors)[0] : '400';
		const errors = this.props.errors ? this.props.errors[htmlCode] : false;
		return (
			<Fragment>
				<div className="error-screen">
					<h1 className="error-header">
						{htmlCode} <span className="error-label">Problemas na Solicitação</span>
					</h1>
					<p>
						Houve um problema de solicitação. Favor entrar em contato com a Procempa informando tudo que está relatado
						nesta página.
					</p>
					<div className="logo">
						<img src={logo} width="320" alt="logo" />
					</div>
					<ErrorMessages errorList={errors} />
				</div>
			</Fragment>
		);
	}
}
ClientError.displayName = 'ClientError';

ClientError.propTypes = {
	errors: PropTypes.any
};

export default ClientError;
