import React, { useCallback, useMemo, useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { get, set } from 'lodash';

import Confirm from 'components/Confirm';
import ErrorMessages from 'components/ErrorMessages';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import { soma, trocaPontoPorVirgula } from 'utils/tools';

import { INFOSPROJETO_CONTROLNAME } from './InfosProjetoLE';
import { LE_Enquadramentos } from './MetadataLE';
import TabelaAreasLE, { TIPOS_ECONOMIAS_ENUM, TIPOS_METRAGENS_ENUM } from './TabelaAreasLE';

import 'tippy.js/dist/tippy.css';

const INFO_TOOLTIP_AREAS =
	'EXISTENTE: Edificação que possui HABITE-SE (carta de habitação) ou edificação comprovadamente existente há mais de 20 (vinte) anos, pelos registros cadastrais do Município ou por documentos comprobatórios; A CONSTRUIR: Quando não há construção nenhuma existente, conforme definição de EXISTENTE, e o requerente pretende licenciar para CONSTRUIR; A REGULARIZAR: ÁREAS aprovadas ou não, que foram executadas, mas não possuem HABITE-SE, ou ainda não se enquadram no Art.159-B do PDDUA, ou seja, não possuem comprovação de existência há mais de 20 anos.';
export function InfosAreasLE({ data: localData, onChangeHandler, tipoForm, errosSubmissao }) {
	const detalhesAreasName = 'detalhesAreas';
	const detalhesAreas = get(localData, detalhesAreasName, {});

	const {
		UNIFAMILIAR_AUTONOMA,
		UNIFAMILIAR_01_ECONO,
		UNIFAMILIAR_02_ECONO,
		UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES,
		NAO_RESIDENCIAL
	} = LE_Enquadramentos;

	const OPT_NOVO_AUMENTO = [
		{ codigo: 'novo', descricao: 'Novo' },
		{ codigo: 'aumento_diminuicao', descricao: 'Aumento/Diminuição' }
	];
	const INFO_TOOLTIP_TIPO_PROJETO =
		'PROJETO NOVO: Quando não há construção nenhuma existente, conforme definição de EXISTENTE, e o requerente pretende licenciar para CONSTRUIR e/ou REGULARIZAR. AUMENTO/DIMINUIÇÃO: Quando já existe uma construção existente, conforme definição de EXISTENTE, e o requerente pretende licenciar mais uma área para CONSTRUIR e/ou REGULARIZAR, inclusive demolir parte da EXISTENTE.';
	const MSG_TIPO_PROJ_NOVO =
		'Novo: Quando não há construção conforme definição de EXISTENTE e pretende-se licenciar para CONSTRUIR e/ou REGULARIZAR. Na planilha de áreas é habilitada somente as áreas CONSTRUIR e/ou REGULARIZAR.';

	const MSG_TIPO_PROJ_AUMENTO_DIMINUICAO =
		'Aumento/Diminuição: Quando já existe uma construção existente, conforme definição de EXISTENTE, e pretende-se licenciar mais uma área a CONSTRUIR e/ou REGULARIZAR, inclusive a DEMOLIR parte da EXISTENTE. Na planilha de áreas é habilitada as áreas EXISTENTE, CONSTRUIR e/ou REGULARIZAR.';

	const infosProjetoData = get(localData, [INFOSPROJETO_CONTROLNAME], {});
	const isProjetoNovo = 'novo' === get(localData, 'infosProjeto.projetoNovoOuAumento', '');
	const isUnidadeAutonoma = tipoForm === UNIFAMILIAR_AUTONOMA;
	const isUnifamiliarUmaEconomia = tipoForm === UNIFAMILIAR_01_ECONO;
	const isUnifamiliarDuasEconomias = tipoForm === UNIFAMILIAR_02_ECONO;
	const isUnifamiliarAteDuasEconomiasComNR = tipoForm === UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES;
	const isNaoResidencial = tipoForm === NAO_RESIDENCIAL;

	const [showConfirm, setShowConfirm] = useState(false);

	const limparTabela = useCallback(() => {
		setShowConfirm(false);
		onChangeHandler && onChangeHandler({ name: detalhesAreasName, value: {} });
	}, [onChangeHandler]);

	const localChangeHandlerProjeto = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name, value } = changes;

			// set dos dados do projeto
			set(localData, [INFOSPROJETO_CONTROLNAME, name], value);
			onChangeHandler && onChangeHandler({ name: INFOSPROJETO_CONTROLNAME, value: { ...infosProjetoData } });
		},
		[infosProjetoData, localData, onChangeHandler]
	);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name: controlName, value } = changes;

			if (controlName === 'errors') return;

			// set dados
			set(detalhesAreas, controlName, value);

			if (isUnifamiliarDuasEconomias) {
				// calculos
				// casa 01 existente
				const vp1 = get(detalhesAreas, ['totais', 'economias', '0', 'subTotalPermanecerDemolir'], '0');
				const vd1 = get(detalhesAreas, ['totaisManuais', 'areaTotalDemolirEconomia01', 'value'], '0');
				const totalExistente = soma(vp1, vd1);
				set(detalhesAreas, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia01'], totalExistente);
				// casa 02 existente
				const vp2 = get(detalhesAreas, ['totais', 'economias', '1', 'subTotalPermanecerDemolir'], '0');
				const vd2 = get(detalhesAreas, ['totaisManuais', 'areaTotalDemolirEconomia02', 'value'], '0');
				const totalExistente2 = soma(vp2, vd2);
				set(detalhesAreas, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia02'], totalExistente2);
				// casa 01 & 02 existente
				const totalExistente0102 = soma(totalExistente, totalExistente2);
				set(detalhesAreas, ['totais', 'areaExistenteTotalPermanecerDemolirEconomia0102'], totalExistente0102);
				// casa 01 & 02 demolir
				const calc2 = soma(vd1, vd2);
				set(detalhesAreas, ['totaisManuais', 'areaTotalDemolirEconomia0102', 'value'], calc2);
			}
			if (isUnidadeAutonoma || isUnifamiliarUmaEconomia || isNaoResidencial) {
				const tempTPD = get(detalhesAreas, ['totais', 'totalPermanecer'], 0);
				const tempTMTD = get(detalhesAreas, ['totaisManuais', 'areaTotalDemolir', 'value'], 0);
				let tempTotExistente = soma(tempTPD, tempTMTD);
				set(detalhesAreas, ['totais', 'areaExistenteTotalPermanecerDemolir'], tempTotExistente);
			}
			if (isUnifamiliarAteDuasEconomiasComNR) {
				// calculos
				// demolir
				const vd1 = get(detalhesAreas, ['totaisManuais', 'areaTotalDemolirResidencial', 'value'], '0');
				const vd2 = get(detalhesAreas, ['totaisManuais', 'areaTotalDemolirNaoResidencial', 'value'], '0');
				const totalDemolir = soma(vd1, vd2);
				set(detalhesAreas, ['totaisManuais', 'areaTotalDemolirUnifamiliarNaoResidencial', 'value'], totalDemolir);
			}

			let tempTable = Object.assign({}, detalhesAreas);
			onChangeHandler && onChangeHandler({ name: detalhesAreasName, value: tempTable });
		},
		[
			detalhesAreas,
			isNaoResidencial,
			isUnidadeAutonoma,
			isUnifamiliarAteDuasEconomiasComNR,
			isUnifamiliarDuasEconomias,
			isUnifamiliarUmaEconomia,
			onChangeHandler
		]
	);

	const resumoMedidas = useMemo(() => {
		let resumo = [];
		if (isUnidadeAutonoma || isUnifamiliarUmaEconomia || isNaoResidencial) {
			resumo = [
				{
					label: 'Área Existente (m²)',
					campo: 'totalExistente',
					valor: get(localData, [detalhesAreasName, 'totais', 'areaExistenteTotalPermanecerDemolir'], '0,00')
				},
				{
					label: 'Área a Permanecer (m²)',
					campo: 'totalPermanecer',
					valor: get(localData, [detalhesAreasName, 'totais', 'totalPermanecer'], '0,00')
				},
				{
					label: 'Área a Demolir (m²)',
					campo: 'totalDemolir',
					valor: get(localData, [detalhesAreasName, 'totaisManuais', 'areaTotalDemolir', 'value'], '0,00')
				},
				{
					label: 'Área a Construir/Regularizar (m²)',
					campo: 'totalConstruirRegularizar',
					valor: get(localData, [detalhesAreasName, 'totais', 'totalConstruirRegularizar'], '0,00')
				},
				{
					label: 'Área total Ad (m²)',
					campo: 'totalAdensavel',
					valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.adensavel, 'total'], '0,00')
				},
				{
					label: 'Área total Nad (m²)',
					campo: 'totalNaoAdensavel',
					valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.naoAdensavel50, 'total'], '0,00')
				},
				{
					label: 'Área total Isenta (m²)',
					campo: 'totalIsenta',
					valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.isenta, 'total'], '0,00')
				},
				{
					label: 'Área total Final (m²)',
					campo: 'totalFinal',
					valor: get(localData, [detalhesAreasName, 'totais', 'total'], '0,00')
				}
			];
		} else if (isUnifamiliarDuasEconomias) {
			resumo = [
				{
					label: 'Área Existente Economia 01 (m²)',
					campo: 'totalExistenteEconomia01',
					valor: get(localData, [detalhesAreasName, 'totais', 'areaExistenteTotalPermanecerDemolirEconomia01'], '0,00')
				},
				{
					label: 'Área Existente Economia 02 (m²)',
					campo: 'totalExistenteEconomia02',
					valor: get(localData, [detalhesAreasName, 'totais', 'areaExistenteTotalPermanecerDemolirEconomia02'], '0,00')
				},
				{
					label: 'Área Existente Economia 01 + 02 (m²)',
					campo: 'totalExistenteEconomia0102',
					valor: get(
						localData,
						[detalhesAreasName, 'totais', 'areaExistenteTotalPermanecerDemolirEconomia0102'],
						'0,00'
					)
				},
				{
					label: 'Área a Permanecer Economia 01 (m²)',
					campo: 'totalPermanecerEconomia01',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '0', 'subTotalPermanecerDemolir'], '0,00')
				},
				{
					label: 'Área a Permanecer Economia 02 (m²)',
					campo: 'totalPermanecerEconomia02',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '1', 'subTotalPermanecerDemolir'], '0,00')
				},
				{
					label: 'Área a Permanecer Economia 01 + 02 (m²)',
					campo: 'totalPermanecerEconomia0102',
					valor: get(localData, [detalhesAreasName, 'totais', 'totalPermanecer'], '0,00')
				},
				{
					label: 'Área a Demolir Economia 01 (m²)',
					campo: 'totalDemolirEconomia01',
					valor: get(localData, [detalhesAreasName, 'totaisManuais', 'areaTotalDemolirEconomia01', 'value'], '0,00')
				},
				{
					label: 'Área a Demolir Economia 02 (m²)',
					campo: 'totalDemolirEconomia02',
					valor: get(localData, [detalhesAreasName, 'totaisManuais', 'areaTotalDemolirEconomia02', 'value'], '0,00')
				},
				{
					label: 'Área a Demolir Economia 01 + 02 (m²)',
					campo: 'totalDemolirEconomia0102',
					valor: get(localData, [detalhesAreasName, 'totaisManuais', 'areaTotalDemolirEconomia0102', 'value'], '0,00')
				},
				{
					label: 'Área a Construir/Regularizar Economia 01 (m²)',
					campo: 'totalConstruirRegularizarEconomia01',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '0', 'subTotalConstruirRegularizar'], '0,00')
				},
				{
					label: 'Área a Construir/Regularizar Economia 02 (m²)',
					campo: 'totalConstruirRegularizarEconomia02',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '1', 'subTotalConstruirRegularizar'], '0,00')
				},
				{
					label: 'Área a Construir/Regularizar Economia 01 + 02 (m²)',
					campo: 'totalConstruirRegularizarEconomia0102',
					valor: get(localData, [detalhesAreasName, 'totais', 'totalConstruirRegularizar'], '0,00')
				},
				{
					label: 'Área total Final Economia 01 (m²)',
					campo: 'totalFinalEconomia01',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '0', 'total'], '0,00')
				},
				{
					label: 'Área total Final Economia 02 (m²)',
					campo: 'totalFinalEconomia02',
					valor: get(localData, [detalhesAreasName, 'totais', 'economias', '1', 'total'], '0,00')
				},
				{
					label: 'Área total Final Economia 01 + 02 (m²)',
					campo: 'totalFinalEconomia0102',
					valor: get(localData, [detalhesAreasName, 'totais', 'totalArea'], '0,00')
				}
			];
		} else if (isUnifamiliarAteDuasEconomiasComNR) {
			resumo = [
				{
					label: 'Área Existente (m²)',
					valores: [
						{
							label: 'Área Existente Residencial',
							campo: 'totalAreaExistenteResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.residencial, 'areaExistenteTotalPermanecerDemolir'],
								'0,00'
							)
						},
						{
							label: 'Área Existente Não Residencial',
							campo: 'totalAreaExistenteNaoResidencial',
							valor: get(
								localData,
								[
									detalhesAreasName,
									'totais',
									TIPOS_ECONOMIAS_ENUM.naoResidencial,
									'areaExistenteTotalPermanecerDemolir'
								],
								'0,00'
							)
						},
						{
							label: 'Área Existente Total',
							campo: 'totalAreaExistente',
							valor: get(localData, [detalhesAreasName, 'totais', 'areaExistenteTotalPermanecerDemolir'], '0,00')
						}
					]
				},
				{
					label: 'Área a Demolir (m²)',
					campo: 'totalAreaDemolir',
					valores: [
						{
							label: 'Área a Demolir Residencial',
							campo: 'totalAreaDemolirResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totaisManuais', 'areaTotalDemolirResidencial', 'value'],
								'0,00'
							)
						},
						{
							label: 'Área a Demolir Não Residencial',
							campo: 'totalAreaDemolirNaoResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totaisManuais', 'areaTotalDemolirNaoResidencial', 'value'],
								'0,00'
							)
						},
						{
							label: 'Área a Demolir Total',
							campo: 'totalAreaDemolirTotal',
							valor: get(
								localData,
								[detalhesAreasName, 'totaisManuais', 'areaTotalDemolirUnifamiliarNaoResidencial', 'value'],
								'0,00'
							)
						}
					]
				},
				{
					label: 'Área a Construir/Regularizar (m²)',
					campo: 'totalConstruirRegularizar',
					valores: [
						{
							label: 'Área a Construir/Regularizar Residencial',
							campo: 'totalConstruirRegularizarResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.residencial, 'totalConstruirRegularizar'],
								'0,00'
							)
						},
						{
							label: 'Área a Construir/Regularizar Não Residencial',
							campo: 'totalConstruirRegularizarNaoResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.naoResidencial, 'totalConstruirRegularizar'],
								'0,00'
							)
						},
						{
							label: 'Área a Construir/Regularizar Total',
							campo: 'totalConstruirRegularizarTotal',
							valor: get(localData, [detalhesAreasName, 'totais', 'totalConstruirRegularizar'], '0,00')
						}
					]
				},
				{
					label: 'Área total Ad (m²)',
					campo: 'totalAdensavel',
					valores: [
						{
							label: 'Área total Ad (m²) Residencial',
							campo: 'totalAdensavelResidencial',
							valor: get(
								localData,
								[
									detalhesAreasName,
									'totais',
									TIPOS_ECONOMIAS_ENUM.residencial,
									TIPOS_METRAGENS_ENUM.adensavel,
									'total'
								],
								'0,00'
							)
						},
						{
							label: 'Área total Ad (m²) Não Residencial',
							campo: 'totalAdensavelNaoResidencial',
							valor: get(
								localData,
								[
									detalhesAreasName,
									'totais',
									TIPOS_ECONOMIAS_ENUM.naoResidencial,
									TIPOS_METRAGENS_ENUM.adensavel,
									'total'
								],
								'0,00'
							)
						},
						{
							label: 'Área total Ad (m²) Total',
							campo: 'totalAdensavelTotal',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.adensavel, 'total'], '0,00')
						}
					]
				},
				{
					label: 'Área total Nad (m²)',
					campo: 'totalNaoAdensavel',
					valores: [
						{
							label: 'Área total Nad (m²) Residencial',
							campo: 'totalNaoAdensavelResidencial',
							valor: get(
								localData,
								[
									detalhesAreasName,
									'totais',
									TIPOS_ECONOMIAS_ENUM.residencial,
									TIPOS_METRAGENS_ENUM.naoAdensavel50,
									'total'
								],
								'0,00'
							)
						},
						{
							label: 'Área total Nad (m²) Não Residencial',
							campo: 'totalNaoAdensavelNaoResidencial',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.naoAdensavel50, 'total'], '0,00')
						},
						{
							label: 'Área total Nad (m²) Total',
							campo: 'totalNaoAdensavelTotal',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.naoAdensavel50, 'total'], '0,00')
						}
					]
				},
				{
					label: 'Área total Isenta (m²)',
					campo: 'totalIsenta',
					valores: [
						{
							label: 'Área total Isenta (m²) Residencial',
							campo: 'totalIsentaResidencial',
							valor: get(
								localData,
								[detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.residencial, TIPOS_METRAGENS_ENUM.isenta, 'total'],
								'0,00'
							)
						},
						{
							label: 'Área total Isenta (m²) Não Residencial',
							campo: 'totalIsentaNaoResidencial',
							valor: get(
								localData,
								[
									detalhesAreasName,
									'totais',
									TIPOS_ECONOMIAS_ENUM.naoResidencial,
									TIPOS_METRAGENS_ENUM.isenta,
									'total'
								],
								'0,00'
							)
						},
						{
							label: 'Área total Isenta (m²)',
							campo: 'totalIsentaTotal',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_METRAGENS_ENUM.isenta, 'total'], '0,00')
						}
					]
				},
				{
					label: 'Área Total (m²)',
					campo: 'totalFinal',
					valores: [
						{
							label: 'Área total Final (m²) Residencial',
							campo: 'totalFinalResidencial',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.residencial, 'total'], '0,00')
						},
						{
							label: 'Área total Final (m²) Não Residencial',
							campo: 'totalFinalNaoResidencial',
							valor: get(localData, [detalhesAreasName, 'totais', TIPOS_ECONOMIAS_ENUM.naoResidencial, 'total'], '0,00')
						},
						{
							label: 'Área total Final',
							campo: 'totalFinalTotal',
							valor: get(localData, [detalhesAreasName, 'totais', 'total'], '0,00')
						}
					]
				}
			];
			resumo.forEach(v => v.valores.forEach(vi => (vi.valor = trocaPontoPorVirgula(vi.valor))));
			return resumo;
		}

		return resumo.map(({ label, campo, valor }) => ({ label, campo, valor: trocaPontoPorVirgula(valor) }));
	}, [
		isNaoResidencial,
		isUnidadeAutonoma,
		isUnifamiliarAteDuasEconomiasComNR,
		isUnifamiliarDuasEconomias,
		isUnifamiliarUmaEconomia,
		localData
	]);

	return (
		<fieldset>
			<legend>
				5. Áreas do Projeto
				<Tippy content={INFO_TOOLTIP_AREAS} delay={100} animation="fade">
					<i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
				</Tippy>
			</legend>

			<div className="pergunta">
				<RadioFieldLE
					name="projetoNovoOuAumento"
					label="Tipo de projeto"
					options={OPT_NOVO_AUMENTO}
					required={true}
					onChangeHandler={localChangeHandlerProjeto}
					value={infosProjetoData.projetoNovoOuAumento}
					tooltip={INFO_TOOLTIP_TIPO_PROJETO}
				/>
				<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'projetoNovoOuAumento')} />
				{infosProjetoData.projetoNovoOuAumento === 'novo' && <p className="observacao-le">{MSG_TIPO_PROJ_NOVO}</p>}
				{infosProjetoData.projetoNovoOuAumento === 'aumento_diminuicao' && (
					<p className="observacao-le">{MSG_TIPO_PROJ_AUMENTO_DIMINUICAO}</p>
				)}
			</div>

			{/* Totais manuais */}
			{!isProjetoNovo && (isUnidadeAutonoma || isUnifamiliarUmaEconomia || isNaoResidencial) && (
				<div className="pergunta metragem">
					<NumberFieldLE
						name="totaisManuais.areaTotalDemolir"
						label="Área a Demolir total (m²)"
						placeHolder="metragem m²"
						required={false}
						onChangeHandler={localChangeHandler}
						value={detalhesAreas?.totaisManuais?.areaTotalDemolir}
						decimals={2}
						maxSize="10"
						readOnly={isProjetoNovo}
					/>
				</div>
			)}
			{!isProjetoNovo && isUnifamiliarDuasEconomias && (
				<>
					<h2 className="legend_secundaria mt-0">Área a Demolir</h2>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirEconomia01"
							label="Economia 01 Área a Demolir total (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirEconomia01}
							decimals={2}
							maxSize="10"
							readOnly={isProjetoNovo}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirEconomia02"
							label="Economia 02 Área a Demolir total (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirEconomia02}
							decimals={2}
							maxSize="10"
							readOnly={isProjetoNovo}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirEconomia0102"
							label="Área a Demolir total da Economia 01 + Economia 02 (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirEconomia0102}
							decimals={2}
							maxSize="10"
							readOnly={true}
						/>
					</div>
				</>
			)}
			{!isProjetoNovo && isUnifamiliarAteDuasEconomiasComNR && (
				<>
					<h2 className="legend_secundaria">Área a Demolir</h2>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirResidencial"
							label="Área a Demolir habitação unifamiliar (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirResidencial}
							decimals={2}
							maxSize="10"
							readOnly={isProjetoNovo}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirNaoResidencial"
							label="Área a Demolir não residencial (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirNaoResidencial}
							decimals={2}
							maxSize="10"
							readOnly={isProjetoNovo}
						/>
					</div>
					<div className="pergunta metragem">
						<NumberFieldLE
							name="totaisManuais.areaTotalDemolirUnifamiliarNaoResidencial"
							label="Área a Demolir total (m²)"
							placeHolder="metragem m²"
							required={false}
							onChangeHandler={localChangeHandler}
							value={detalhesAreas?.totaisManuais?.areaTotalDemolirUnifamiliarNaoResidencial}
							decimals={2}
							maxSize="10"
							readOnly={true}
						/>
					</div>
				</>
			)}
			{/* Tabela de areas */}
			<div className="row">
				<div className="col-12 mt-3">
					<TabelaAreasLE
						data={localData}
						name={detalhesAreasName}
						onChangeHandler={onChangeHandler}
						tipoForm={tipoForm}
						isProjetoNovo={isProjetoNovo}
					/>
					{<span style={{ fontStyle: 'italic' }}>Mova a barra de rolagem horizontal para preencher a planilha</span>}
				</div>
			</div>
			{(errosSubmissao || []).some(e => e.campo === 'planilhaAreas') && (
				<div className="pergunta">
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'planilhaAreas')} />
				</div>
			)}

			{/* Botao de limpeza & confirm dele*/}
			<button type="button" className="btn btn-primary mt-2" onClick={() => setShowConfirm(true)}>
				Limpar tabela <i className="fa fa-eraser"></i>
			</button>
			{showConfirm && (
				<Confirm
					loader={false}
					msg="Você deseja apagar todos os dados da tabela?"
					onConfirm={limparTabela}
					onReject={() => setShowConfirm(false)}
				/>
			)}
			{/* Tabela de resumo */}
			<div className="row">
				<div className="col-12 mt-3">
					<table className="table table-sm table-hover">
						<thead className="thead-dark">
							<tr>
								<th style={{ textAlign: 'center' }} colSpan={isUnifamiliarAteDuasEconomiasComNR ? 4 : 2}>
									Resumo de Áreas
								</th>
							</tr>
							{isUnifamiliarAteDuasEconomiasComNR && (
								<tr>
									<th></th>
									<th>Residencial</th>
									<th>Não Residencial</th>
									<th>Total</th>
								</tr>
							)}
						</thead>
						<tbody>
							{isUnifamiliarAteDuasEconomiasComNR
								? resumoMedidas.map((a, i) => (
										<tr key={new Date().getTime() + i} style={{ verticalAlign: 'text-top' }}>
											<td>{a.label}</td>
											{a.valores.map((r, ii) => (
												<Fragment key={ii}>
													<td>
														{r.valor} m²
														<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === a.campo)} />
													</td>
												</Fragment>
											))}
										</tr>
								  ))
								: resumoMedidas.map((r, i) => (
										<tr key={new Date().getTime() + i} style={{ verticalAlign: 'text-top' }}>
											<td>{r.label}</td>
											<td>
												{r.valor} m²
												<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === r.campo)} />
											</td>
										</tr>
								  ))}
						</tbody>
					</table>
				</div>
			</div>
		</fieldset>
	);
}

InfosAreasLE.displayName = 'InfosAreasLE';
InfosAreasLE.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.array
};
