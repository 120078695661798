import { useEffect } from 'react';
/**
 * useClick
 * @param {function} action - the action to perform on click
 */
export default function useClick(action) {
	useEffect(() => {
		function onClick(e) {
			action(e);
		}
		window.addEventListener('click', onClick);
		return () => window.removeEventListener('click', onClick);
	}, [action]);
}
