import './LicencasExpressas.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import useAxios from 'axios-hooks';
import { size, get, pick, sortBy, isNil } from 'lodash';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS } from 'containers/Form/Detalhe';
import { RadioField } from 'containers/Form/metadata-template/fields';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { EXPEDIENTES_API_URL } from 'environments';

import { sendApi } from 'utils/injectApi';
import { calculaNumeroLinhas, getValueCutDigits, isDebug, verificaEnquadramentoDefinicao } from 'utils/tools';

import { AreaSubfield } from './AreaSubfield';
import { CheckboxSubfield } from './CheckboxSubfield';
import { ExpedienteSubfield } from './ExpedienteSubfield';
import { NumeroSubfield } from './NumeroSubfield';
import { PlanoDiretorSubfield } from './PlanoDiretorSubfield';
import { SelecaoSubfield } from './SelecaoSubfield';
import { TextAreaSubfield } from './TextAreaSubfield';
import { validarLimpar } from './validarLimpar';

// #region CONSTANTS

export const E_DEMOLICAO_PARCIAL = 'demolicaoParcial'; // Demolição parcial
export const E_DEMOLICAO_TOTAL = 'demolicaoTotal'; // Demolição total
export const E_RECICLAGEM_USO_PARCIAL = 'reciclagemUsoParcial'; // reciclagem de uso parcial
export const E_RECICLAGEM_USO_TOTAL = 'reciclagemUsoTotal'; // reciclagem de uso total
export const E_DESMEMBRAMENTO_UNIFICACAO = 'desmembramentoUnificacao'; // FORMULÁRIO DE DESMEMBRAMENTO/UNIFICAÇÃO
export const E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL = 'desmembramentoUnificacaoReciclagemUsoTotal';
export const E_DESMEMBRA_UNIFICA_RECICLA_USO_PARCIAL = 'desmembramentoUnificacaoReciclagemUsoParcial';

// 1.1. - Habitação Unifamiliar
// 1.2. - Habitação Coletiva (pensionatos, Internatos, Conventos, Mosteiros, Seminários e Residenciais Geriátricos)
// 1.3. - Habitação Multifamiliar
const ATIVIDADES_RECICLAGEM_TOTAL = ['1.1.', '1.2.', '1.3.'];
const ATIVIDADES_RECICLAGEM_PARCIAL = [
	'1.1.',
	'1.2.',
	'1.3.',
	'2.1.1.',
	'2.1.2.',
	'2.1.3.',
	'2.2.1.',
	'2.2.2.',
	'2.2.3.',
	'3.1.',
	'3.2.',
	'3.3.',
	'3.4.',
	'4.1.1.',
	'4.1.2.'
];

// #endregion

const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];

const LABEL_IMPACTO_URBANO =
	'A(s) atividade(s) não residencial(ais), conforme Anexo 5.2 do PDDUA, referem-se a Projetos Especiais de Impacto Urbano de 1° ou 2º grau, listadas no Anexo 11 do PDDUA?';

const LABEL_VIABILIDADE_URBANISTICO =
	'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido referente a atividade conforme Art. 159 do PDDUA?';

const LABEL_CAPACIDADE_CONSTRUTIVA =
	'O imóvel existente está dentro da capacidade construtiva (Índice de Aproveitamento e Taxa de Ocupação) em conformidade com a legislação urbanística atual?';

const LABEL_RECICLAGEM_USO =
	'Em se tratando de RECICLAGEM DE USO, o imóvel existente teve sua capacidade construtiva (Índice de Aproveitamento e Taxa de Ocupação) em conformidade com a legislação urbanística vigente na época da sua construção?';

const AVISO_ESTUDO =
	'Devido a atividade estar listada no ANEXO 11 e NÃO TER ESTUDO DE VIABILIDADE URBANÍSTICO (EVU) APROVADO E VÁLIDO, deverá solicitar Estudo Viabilidade Urbanística (EVU) no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';

const AVISO_RECICLAGEM =
	'Conforme Lei 946/22, Art. 12 Para fins de reciclagem de uso, sem aumento de área, ficam isentas do ônus do Solo Criado as edificações já existentes cuja capacidade construtiva esteja em conformidade com a legislação urbanística vigente na época da construção. Devido ao processo necessitar de ÍNDICES CONSTRUTIVOS (SOLO CRIADO), este processo não se enquadra na modalidade de Licença para Obras de Simples Natureza. Deverá ser solicitada a Reciclagem de Uso na Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';
export function LicencasExpressasDocEspecifico({ setShowFormulario, setShowConfirm }) {
	const dispatch = useDispatch();

	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errors, setErrors] = useState();
	const { createMessage } = useMessages();

	/* ESTADOS */
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [tiposConstrucao, setTiposConstrucao] = useState([]);
	const [allowForceSave, setAllowForceSave] = useState(false);

	const [{ data: tiposconstrucao }] = useAxios(`${EXPEDIENTES_API_URL}/cadastros/tipoconstrucao`);

	const definicao = useMemo(() => get(formulario, 'formData.data.enquadramento.definicao'), [formulario]);

	// #region SIDE EFFECTS
	useEffect(() => {
		const previousData = obtemFormularioLicencasExpressasDados(formulario);
		let dataAux = previousData?.dados || {};

		if (dataAux?.definicao !== definicao) {
			dataAux = {};
		}
		if (verificaEnquadramentoDefinicao([E_DEMOLICAO_PARCIAL, E_DEMOLICAO_TOTAL], definicao)) {
			if (size(dataAux.tiposMaterial) === 0) {
				dataAux.tiposMaterial = [{ id: uuid() }];
			}
		}
		setData(dataAux);
		setErrors({});
	}, [dispatch, formulario, setErrors, definicao]);

	useEffect(() => {
		if (tiposconstrucao) {
			let tipos = tiposconstrucao.map(tp => pick(tp, ['_id', 'codigo', 'descricao']));
			tipos = sortBy(tipos, 'descricao');
			tipos.push({ _id: '000', codigo: 'outro', descricao: 'Informar outro' });
			setTiposConstrucao(tipos);
		}
	}, [tiposconstrucao]);
	// #endregion

	// #region HANDLERS
	const obtemTotaisAtualizados = useCallback(tiposMaterial => {
		let areaDemolicaoRegularTotal = null;
		let areaDemolicaoIrregularTotal = null;
		let areaPermanecerTotal = null;

		tiposMaterial.forEach(tm => {
			if (Object.prototype.hasOwnProperty.call(tm, 'areaDemolicaoRegular')) {
				areaDemolicaoRegularTotal = areaDemolicaoRegularTotal || 0;
				if (tm.areaDemolicaoRegular) {
					areaDemolicaoRegularTotal += getValueCutDigits(parseFloat(tm.areaDemolicaoRegular?.value || 0), 2);
				}
			}
			if (Object.prototype.hasOwnProperty.call(tm, 'areaDemolicaoIrregular')) {
				areaDemolicaoIrregularTotal = areaDemolicaoIrregularTotal || 0;
				if (tm.areaDemolicaoIrregular) {
					areaDemolicaoIrregularTotal += getValueCutDigits(parseFloat(tm.areaDemolicaoIrregular?.value || 0), 2);
				}
			}
			if (Object.prototype.hasOwnProperty.call(tm, 'areaPermanecerRegular')) {
				areaPermanecerTotal = areaPermanecerTotal || 0;
				if (tm.areaPermanecerRegular) {
					areaPermanecerTotal += getValueCutDigits(parseFloat(tm.areaPermanecerRegular?.value || 0), 2);
				}
			}
		});
		let totais = [];

		if (!isNil(areaDemolicaoRegularTotal)) {
			areaDemolicaoRegularTotal = areaDemolicaoRegularTotal.toFixed(2).replace(/\./, ',');
			totais.push({ name: 'areaDemolicaoRegularTotal', value: { value: areaDemolicaoRegularTotal } });
		}
		if (!isNil(areaDemolicaoIrregularTotal)) {
			areaDemolicaoIrregularTotal = areaDemolicaoIrregularTotal.toFixed(2).replace(/\./, ',');
			totais.push({ name: 'areaDemolicaoIrregularTotal', value: { value: areaDemolicaoIrregularTotal } });
		}
		if (!isNil(areaPermanecerTotal)) {
			areaPermanecerTotal = areaPermanecerTotal.toFixed(2).replace(/\./, ',');
			totais.push({ name: 'areaPermanecerTotal', value: { value: areaPermanecerTotal } });
		}

		return totais;
	}, []);

	const onChangeHandler = useCallback(
		e => {
			if (Array.isArray(e)) {
				const [valid] = e;
				e = valid;
			}
			const { name, value } = e.target || e;
			if (name === 'errors') {
				return;
			}
			if (data?.[name] !== value) {
				let changes = { [name]: value };
				if (name === 'tiposMaterial') {
					if (Object.prototype.hasOwnProperty.call(data, 'areaDemolicaoRegularTotal')) {
						Object.assign(changes, { areaDemolicaoRegularTotal: null });
					}
					if (Object.prototype.hasOwnProperty.call(data, 'areaDemolicaoIrregularTotal')) {
						Object.assign(changes, { areaDemolicaoIrregularTotal: null });
					}
					if (Object.prototype.hasOwnProperty.call(data, 'areaPermanecerTotal')) {
						Object.assign(changes, { areaPermanecerTotal: null });
					}
					const totais = obtemTotaisAtualizados(value);
					totais.forEach(t => {
						Object.assign(changes, { [t.name]: t.value });
					});
				}
				if (name === 'projetoImpactoUrbano' && value === 'nao') {
					// Se 'projetoImpactoUrbano' for alterado para 'nao', limpa o valor de 'projetoViabilidaUrbanistico'
					if (data?.projetoViabilidaUrbanistico) {
						changes['projetoViabilidaUrbanistico'] = '';
					}
				}
				if (name === 'capacidadeConstrutiva' && value === 'sim') {
					// Se 'projetoImpactoUrbano' for alterado para 'nao', limpa o valor de 'projetoViabilidaUrbanistico'
					if (data?.reciclagemUso) {
						changes['reciclagemUso'] = '';
					}
				}
				setData(old => ({ ...old, ...changes }));
				setAllowForceSave(false);
				setErrors({});
			}
		},
		[data, obtemTotaisAtualizados, setErrors]
	);

	const onChangeTipoMaterialHandler = useCallback(
		(dataAux, property, value, i) => {
			const tiposMaterialAux = [...dataAux.tiposMaterial];
			tiposMaterialAux[i][property] = value;
			onChangeHandler({ name: 'tiposMaterial', value: tiposMaterialAux });
		},
		[onChangeHandler]
	);

	const onRemoveTipoMaterial = useCallback(
		(dataAux, id) => {
			const index = (dataAux.tiposMaterial || []).findIndex(tm => tm.id === id);
			if (index > -1) {
				const tiposMaterialAux = (dataAux.tiposMaterial || []).filter(tm => tm.id !== id);
				onChangeHandler({ name: 'tiposMaterial', value: tiposMaterialAux });
			}
		},
		[onChangeHandler]
	);

	const submit = async operacao => {
		const { draft, forceSave, gerarPdf } = operacao;

		// Adicionando lógica para definir valores padrão para campos específicos se estiverem vazios
		if (verificaEnquadramentoDefinicao([E_DEMOLICAO_PARCIAL, E_DEMOLICAO_TOTAL], definicao)) {
			data.tiposMaterial.forEach((tm, index) => {
				// Verifica e define 'areaPermanecerRegular'
				if (
					!tm.areaPermanecerRegular ||
					!tm.areaPermanecerRegular.value ||
					tm.areaPermanecerRegular.value.trim() === ''
				) {
					onChangeTipoMaterialHandler(data, 'areaPermanecerRegular', { value: '0.00' }, index);
				}

				// Verifica e define 'areaDemolicaoIrregular'
				if (
					!tm.areaDemolicaoIrregular ||
					!tm.areaDemolicaoIrregular.value ||
					tm.areaDemolicaoIrregular.value.trim() === ''
				) {
					onChangeTipoMaterialHandler(data, 'areaDemolicaoIrregular', { value: '0.00' }, index);
				}

				// Verifica e define 'areaDemolicaoRegular'
				if (!tm.areaDemolicaoRegular || !tm.areaDemolicaoRegular.value || tm.areaDemolicaoRegular.value.trim() === '') {
					onChangeTipoMaterialHandler(data, 'areaDemolicaoRegular', { value: '0.00' }, index);
				}
			});
		}

		const errors = validarLimpar(data, definicao, operacao);

		// forceSave é true quando form já tem erros de validação
		// adicionado o teste de draft para desabilitar a possibilidade de gerar pdf com errors
		if (draft && forceSave) {
			salvar({ draft, errors });
			setAllowForceSave(false);
		} else {
			if (size(errors) > 0) {
				setErrors(errors);
				setAllowForceSave(true);
			} else {
				salvar({ draft, gerarPdf });
				setAllowForceSave(false);
			}
		}
	};

	const salvar = async params => {
		const { draft, errors, gerarPdf } = params;
		try {
			setLoading(true);
			const newData = { ...data, definicao };

			if (gerarPdf) {
				Object.keys(newData).forEach(prop => {
					if (newData[`${prop}AlteradaRevisor`]) {
						delete newData[`${prop}AlteradaRevisor`];
					}
				});
			}

			// submete salvamento/geração do pdf
			// para encontrar o fluxo no node-red
			// procure por /processo/:id/:formdocumento
			// o usado aqui é o POST, não o GET
			const fdId = get(formulario, 'formData.id');
			const url = `processo/${fdId}/${EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS}${draft ? '?draft=true' : ''}`;
			const { data: dataResponse } = await sendApi(
				{ url, payload: { form: newData, observacoes: errors }, required: true, label: '' },
				false
			);

			// atualiza dados na store
			dispatch(
				actions.alteraStatusDocumento({
					documento: dataResponse.documento,
					documentoDados: dataResponse.documentoDados
				})
			);
			createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 2);
		} catch (error) {
			createMessage('Problemas no salvamento do documento... verifique a console', 5);
			console.error('erro no servidor', error);
		} finally {
			setLoading(false);
			setShowFormulario(false);
		}
	};
	// #endregion

	// #region AUXILIARES PARA RENDER
	const isDemolicaoTotal = verificaEnquadramentoDefinicao([E_DEMOLICAO_TOTAL], definicao);
	const isDemolicaoParcial = verificaEnquadramentoDefinicao([E_DEMOLICAO_PARCIAL], definicao);
	let hasAtividadesMultifamiliar = false;

	if (verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_TOTAL, E_RECICLAGEM_USO_PARCIAL], definicao)) {
		hasAtividadesMultifamiliar = get(data, 'planoDiretor.dadosSubunidades', []).some(su =>
			get(su, 'dadosAtividades', []).some(atv => get(atv, 'atividadeAnexo52.item') === '1.3.')
		);
	}

	const hasErros = size(errors) > 0;

	const l6Options = useMemo(
		() =>
			['Economias', 'Boxes', 'Economias e Boxes'].map(op => ({
				codigo: op.toLowerCase(),
				descricao: op,
				defaultValue: op.toLowerCase() === 'nenhuma'
			})),
		[]
	);

	const classNameTotais = isDemolicaoTotal ? 'col-12 col-md-6' : 'col-12 col-md-4';
	// #endregion

	/* RENDER */
	return (
		<div className="doc-licencas-expressas">
			{loading && <Loader />}
			<ExpedienteSubfield formulario={formulario} />

			{verificaEnquadramentoDefinicao([E_DEMOLICAO_PARCIAL, E_DEMOLICAO_TOTAL], definicao) && (
				<fieldset>
					<div className="titulo-licencas-expressas">
						{isDemolicaoParcial && <legend>Formulário de Demolição Parcial</legend>}
						{isDemolicaoTotal && <legend>Formulário de Demolição Total</legend>}
					</div>
					<p className="observacoes">
						<b>Área Construída regular:</b> Área com Carta de Habitação.
					</p>
					<p className="observacoes">
						<b>Áreas Construídas Existentes:</b> Áreas comprovadamente existentes há mais de 20 (vinte) anos, pelos
						registros dos cadastros do Município, nos termos do Art. 159-B da L.C. 434/99, atualizada e compilada até a
						L.C. 667/11, incluindo a L.C. 646/10.
					</p>
					<div className="row">
						<div className={`${classNameTotais}`}>
							<AreaSubfield
								fieldName="areaDemolicaoRegularTotal"
								label="Área regular total (m²)"
								value={data?.areaDemolicaoRegularTotal}
								errors={errors?.areaDemolicaoRegularTotal}
								readOnly={true}
								disabled={true}
							/>
						</div>
						<div className={`${classNameTotais}`}>
							<AreaSubfield
								fieldName="areaDemolicaoIrregularTotal"
								label="Área irregular total (m²)"
								value={data?.areaDemolicaoIrregularTotal}
								errors={errors?.areaDemolicaoIrregularTotal}
								readOnly={true}
								disabled={true}
							/>
						</div>
						{isDemolicaoParcial && (
							<div className={`${classNameTotais}`}>
								<AreaSubfield
									fieldName="areaPermanecerTotal"
									label="Área permanecer total (m²)"
									value={data?.areaPermanecerTotal}
									errors={errors?.areaPermanecerTotal}
									readOnly={true}
									disabled={true}
								/>
							</div>
						)}
					</div>
					{(data?.tiposMaterial || []).map((tm, i) => {
						const tam = size(data?.tiposMaterial);
						return (
							<div
								className="row"
								style={{
									borderTop: i > 0 ? '1px solid #dfe2e6' : '0',
									borderBottom: tam > 1 && i + 1 === tam ? '1px solid #dfe2e6' : '0'
								}}
								key={i}
							>
								<div className={isDemolicaoTotal ? 'col-12 col-md-4' : 'col-12 col-md-3'}>
									<SelecaoSubfield
										label="Tipo de material"
										fieldName="tipoConstrucao"
										placeholder="tipo de material"
										value={tm?.tipoConstrucao ? { [tm.tipoConstrucao.codigo]: tm.tipoConstrucao } : null}
										searchList={tiposConstrucao}
										errors={errors?.[`tipoConstrucao${i}`]}
										multiple={false}
										onChangeHandler={({ name, value }) => {
											const auxValue = value[Object.keys(value)[0]];
											return onChangeTipoMaterialHandler(data, name, auxValue, i);
										}}
										loading={size(tiposConstrucao) === 0}
									/>
								</div>
								<div className="col-12 col-md-3">
									<AreaSubfield
										fieldName="areaDemolicaoRegular"
										label="Área a demolir regular (m²)"
										onChangeHandler={array => {
											const { name, value } = array.find(a => a.name !== 'errors') || {};
											if (name) {
												onChangeTipoMaterialHandler(data, name, value, i);
											}
										}}
										value={tm?.areaDemolicaoRegular}
										errors={errors?.[`areaDemolicaoRegular${i}`]}
										required={false}
									/>
								</div>
								<div className="col-12 col-md-3">
									<AreaSubfield
										fieldName="areaDemolicaoIrregular"
										label="Área a demolir irregular (m²)"
										onChangeHandler={array => {
											const { name, value } = array.find(a => a.name !== 'errrors') || {};
											if (name) {
												return onChangeTipoMaterialHandler(data, name, value, i);
											}
										}}
										value={tm?.areaDemolicaoIrregular}
										errors={errors?.[`areaDemolicaoIrregular${i}`]}
										required={false}
									/>
								</div>
								{isDemolicaoParcial && (
									<div className="col-12 col-md-2">
										<AreaSubfield
											fieldName="areaPermanecerRegular"
											label="Área a permanecer regular (m²)"
											onChangeHandler={array => {
												const { name, value } = array.find(a => a.name !== 'errors') || {};
												if (name) {
													onChangeTipoMaterialHandler(data, name, value, i);
												}
											}}
											value={tm?.areaPermanecerRegular}
											errors={errors?.[`areaPermanecerRegular${i}`]}
											required={false}
											readOnly={isDemolicaoTotal}
											tooltip={isDemolicaoTotal ? 'Não há área a permanecer para certidão de demolição total' : ''}
										/>
									</div>
								)}
								<div className="col-12 col-md-1 btn-trash-simples-natureza">
									{size(data?.tiposMaterial) === 1 ? (
										<button type="button" className="btn" disabled style={{ marginBottom: isDebug ? '20px' : '5px' }}>
											<i className="fa fa-trash" style={{ color: '#7d7f80', fontSize: '20px' }} />
										</button>
									) : (
										<button
											type="button"
											className="btn p-2"
											onClick={() => onRemoveTipoMaterial(data, tm.id)}
											style={{ marginBottom: isDebug ? '20px' : '5px' }}
										>
											<i className="fa fa-trash" style={{ color: '#d12f2f', fontSize: '20px' }} />
										</button>
									)}
								</div>
							</div>
						);
					})}
					<div className="cjto-botoes">
						<button
							type="button"
							className="btn btn-primary mr-1 mb-3"
							onClick={() => {
								setAllowForceSave(false);
								setData(old => ({ ...old, tiposMaterial: [...(old?.tiposMaterial || []), { id: uuid() }] }));
							}}
						>
							Adicionar tipo de material
						</button>
					</div>
					{size(errors?.tiposMaterial) > 0 && <ErrorMessages errorList={errors.tiposMaterial} />}
					<div className="col-md-12">
						<label style={{ color: '#7d7f80', fontSize: '14px' }}>Observações </label>
						<textarea
							style={{ marginBottom: '10px', marginLeft: '-5px' }}
							className="form-control"
							type="text"
							placeholder="Observações"
							value={data?.observacoes || ''}
							name="observacoes"
							onChange={onChangeHandler}
							rows={calculaNumeroLinhas(data?.observacoes)}
							readOnly={false}
							disabled={false}
						></textarea>
						{size(errors?.observacoes) > 0 && <ErrorMessages errorList={errors.observacoes} />}
					</div>
				</fieldset>
			)}

			{verificaEnquadramentoDefinicao(
				[E_RECICLAGEM_USO_PARCIAL, E_RECICLAGEM_USO_TOTAL, E_DESMEMBRAMENTO_UNIFICACAO],
				definicao
			) && (
				<fieldset>
					<div className="titulo-licencas-expressas">
						{verificaEnquadramentoDefinicao([E_DESMEMBRAMENTO_UNIFICACAO], definicao) && (
							<legend>Formulário de Desmembramento/Unificação</legend>
						)}
						{verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_PARCIAL], definicao) && (
							<legend>Formulário de Reciclagem de Uso Parcial</legend>
						)}
						{verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_TOTAL], definicao) && (
							<legend>Formulário de Reciclagem de Uso Total</legend>
						)}
					</div>
					{verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_PARCIAL], definicao) && (
						<>
							<div className="row">
								<div className="col-6">
									<AreaSubfield
										fieldName="areaReciclar"
										label="Área a reciclar (m²)"
										onChangeHandler={onChangeHandler}
										value={data?.areaReciclar}
										errors={errors?.areaReciclar}
										required={true}
									/>
								</div>
								{/* <div className="col-6">
									<NumeroSubfield
										fieldName="numeroEconomias"
										label="Nº de economias propostas"
										onChangeHandler={onChangeHandler}
										value={data?.numeroEconomias}
										errors={errors?.numeroEconomias}
										required={true}
									/>
								</div> */}
							</div>
						</>
					)}
					{verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_TOTAL, E_RECICLAGEM_USO_PARCIAL], definicao) && (
						<div className="row">
							<div className="col-12">
								<div className="pergunta">
									<PlanoDiretorSubfield
										formulario={formulario}
										data={data}
										onChangeHandler={onChangeHandler}
										filtroAtividadesPlanoDiretor={
											verificaEnquadramentoDefinicao([E_RECICLAGEM_USO_PARCIAL], definicao)
												? ATIVIDADES_RECICLAGEM_PARCIAL
												: ATIVIDADES_RECICLAGEM_TOTAL
										}
									/>
									{size(errors?.planoDiretor) > 0 && <ErrorMessages errorList={errors.planoDiretor} />}
								</div>
							</div>
						</div>
					)}
					{hasAtividadesMultifamiliar && (
						<div className="row">
							<div className="col-4">
								<AreaSubfield
									fieldName="areaTotalMatriculaLote"
									label="Área total de matrícula do lote (m²)"
									onChangeHandler={onChangeHandler}
									value={data?.areaTotalMatriculaLote}
									errors={errors?.areaTotalMatriculaLote}
									required={true}
								/>
							</div>
							<div className="col-4">
								<NumeroSubfield
									fieldName="numeroEconomiasTotais"
									label="Número de economias totais"
									onChangeHandler={onChangeHandler}
									value={data?.numeroEconomiasTotais}
									errors={errors?.numeroEconomiasTotais}
									required={true}
								/>
							</div>
							<div className="col-4">
								<AreaSubfield
									fieldName="quotaIdealTotalProjeto"
									label="Quota ideal total do projeto"
									onChangeHandler={onChangeHandler}
									value={data?.quotaIdealTotalProjeto}
									errors={errors?.quotaIdealTotalProjeto}
									required={false}
								/>
							</div>
							<div className="col-12 pl-4">
								<div className="pergunta">
									<RadioField
										name="atendeQuotaIdeal"
										label="Atende a quota ideal"
										options={[
											{ codigo: 'sim', descricao: 'Sim' },
											{ codigo: 'nao', descricao: 'Não' }
										]}
										required={false}
										onChangeHandler={onChangeHandler}
										value={data?.atendeQuotaIdeal}
									/>
									{size(errors?.atendeQuotaIdeal) > 0 && <ErrorMessages errorList={errors.atendeQuotaIdeal} />}
								</div>
							</div>
						</div>
					)}

					{verificaEnquadramentoDefinicao([E_DESMEMBRAMENTO_UNIFICACAO], definicao) && (
						<>
							<div className="row">
								<div className="col-12">
									<div className="pergunta">
										<RadioField
											name="l6Option"
											label="Escolha Economias e/ou Boxes"
											options={l6Options}
											required={true}
											onChangeHandler={item =>
												item.name === 'l6Option' ? onChangeHandler({ name: 'l6Option', value: item.value }) : null
											}
											value={data?.l6Option}
										/>
										{size(errors?.l6Option) > 0 && <ErrorMessages errorList={errors.l6Option} />}
									</div>
								</div>
							</div>

							{data?.l6Option?.indexOf('economias') > -1 && (
								<>
									<h4>Economias</h4>
									<div className="row">
										<div className="col-6">
											<NumeroSubfield
												fieldName="numeroEconomiasAtuais"
												label="Nº de economias atuais"
												onChangeHandler={onChangeHandler}
												value={data?.numeroEconomiasAtuais}
												errors={errors?.numeroEconomiasAtuais}
											/>
										</div>
										<div className="col-6">
											<NumeroSubfield
												fieldName="numeroEconomiasPropostas"
												label="Nº de economias propostas"
												onChangeHandler={onChangeHandler}
												value={data?.numeroEconomiasPropostas}
												errors={errors?.numeroEconomiasPropostas}
											/>
										</div>
									</div>
								</>
							)}
							{data?.l6Option?.indexOf('boxes') > -1 && (
								<>
									<h4>Boxes</h4>
									<div className="row">
										<div className="col-6">
											<NumeroSubfield
												fieldName="numeroBoxesAtuais"
												label="Nº de boxes atuais"
												onChangeHandler={onChangeHandler}
												value={data?.numeroBoxesAtuais}
												errors={errors?.numeroBoxesAtuais}
											/>
										</div>
										<div className="col-6">
											<NumeroSubfield
												fieldName="numeroBoxesPropostos"
												label="Nº de boxes propostos"
												onChangeHandler={onChangeHandler}
												value={data?.numeroBoxesPropostos}
												errors={errors?.numeroBoxesPropostos}
											/>
										</div>
									</div>
								</>
							)}
						</>
					)}

					{/* INICIO */}
					<div className="pergunta label-longa">
						<RadioFieldLE
							name="projetoImpactoUrbano"
							label={LABEL_IMPACTO_URBANO}
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={onChangeHandler}
							value={data?.projetoImpactoUrbano}
						/>
						{size(errors?.projetoImpactoUrbano) > 0 && <ErrorMessages errorList={errors.projetoImpactoUrbano} />}

						{data?.projetoImpactoUrbano === 'sim' && (
							<div className="pergunta">
								<RadioFieldLE
									name="projetoViabilidaUrbanistico"
									label={LABEL_VIABILIDADE_URBANISTICO}
									options={OPT_SIM_NAO}
									required={true}
									onChangeHandler={onChangeHandler}
									value={data?.projetoViabilidaUrbanistico}
								/>
								{data?.projetoViabilidaUrbanistico === 'sim' ? null : data?.projetoViabilidaUrbanistico === 'nao' ? (
									<ErrorMessages errorList={[AVISO_ESTUDO]} />
								) : null}
							</div>
						)}
					</div>
					<div className="pergunta label-longa">
						<br />
						<RadioFieldLE
							name="capacidadeConstrutiva"
							label={LABEL_CAPACIDADE_CONSTRUTIVA}
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={onChangeHandler}
							value={data?.capacidadeConstrutiva}
						/>
						{size(errors?.capacidadeConstrutiva) > 0 && <ErrorMessages errorList={errors.capacidadeConstrutiva} />}
						<br />

						{data?.capacidadeConstrutiva === 'nao' && (
							<div className="pergunta">
								<RadioFieldLE
									name="reciclagemUso"
									label={LABEL_RECICLAGEM_USO}
									options={OPT_SIM_NAO}
									required={true}
									onChangeHandler={onChangeHandler}
									value={data?.reciclagemUso}
								/>
								{data?.reciclagemUso === 'sim' ? null : data?.reciclagemUso === 'nao' ? (
									<ErrorMessages errorList={[AVISO_RECICLAGEM]} />
								) : null}
							</div>
						)}
					</div>
					{/* FIM */}

					<div className="col-12 mt-3">
						<label style={{ color: '#7d7f80', fontSize: '14px' }}>Observações </label>
						<textarea
							style={{ marginBottom: '10px', marginLeft: '-5px' }}
							className="form-control"
							type="text"
							placeholder="Observações"
							value={data?.observacoes || ''}
							name="observacoes"
							onChange={onChangeHandler}
							rows={calculaNumeroLinhas(data?.observacoes)}
							readOnly={false}
							disabled={false}
						></textarea>
						{size(errors?.observacoes) > 0 && <ErrorMessages errorList={errors.observacoes} />}
					</div>
				</fieldset>
			)}

			{verificaEnquadramentoDefinicao(
				[E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL, E_DESMEMBRA_UNIFICA_RECICLA_USO_PARCIAL],
				definicao
			) && (
				<fieldset>
					<div className="titulo-licencas-expressas">
						<legend>
							Formulário de Desmembramento/Unificação + Reciclagem de Uso
							{verificaEnquadramentoDefinicao([E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL], definicao)
								? ' Total'
								: ' Parcial'}
						</legend>
					</div>
					{/* economias ou boxes */}
					<div className="row">
						<div className="col-12">
							<div className="pergunta">
								<RadioField
									name="l6Option"
									label="Escolha Economias e/ou Boxes"
									options={l6Options}
									required={true}
									onChangeHandler={item =>
										item.name === 'l6Option' ? onChangeHandler({ name: 'l6Option', value: item.value }) : null
									}
									value={data?.l6Option}
								/>
								{size(errors?.l6Option) > 0 && <ErrorMessages errorList={errors.l6Option} />}
							</div>
						</div>
					</div>
					{data?.l6Option?.indexOf('economias') > -1 && (
						<>
							<h4>Economias</h4>
							<div className="row">
								<div className="col-6">
									<NumeroSubfield
										fieldName="numeroEconomiasAtuais"
										label="Nº de economias atuais"
										onChangeHandler={onChangeHandler}
										value={data?.numeroEconomiasAtuais}
										errors={errors?.numeroEconomiasAtuais}
									/>
								</div>
								<div className="col-6">
									<NumeroSubfield
										fieldName="numeroEconomiasPropostas"
										label="Nº de economias propostas"
										onChangeHandler={onChangeHandler}
										value={data?.numeroEconomiasPropostas}
										errors={errors?.numeroEconomiasPropostas}
									/>
								</div>
							</div>
						</>
					)}
					{data?.l6Option?.indexOf('boxes') > -1 && (
						<>
							<h4>Boxes</h4>
							<div className="row">
								<div className="col-6">
									<NumeroSubfield
										fieldName="numeroBoxesAtuais"
										label="Nº de boxes atuais"
										onChangeHandler={onChangeHandler}
										value={data?.numeroBoxesAtuais}
										errors={errors?.numeroBoxesAtuais}
									/>
								</div>
								<div className="col-6">
									<NumeroSubfield
										fieldName="numeroBoxesPropostos"
										label="Nº de boxes propostos"
										onChangeHandler={onChangeHandler}
										value={data?.numeroBoxesPropostos}
										errors={errors?.numeroBoxesPropostos}
									/>
								</div>
							</div>
						</>
					)}
					{data?.l6Option?.indexOf('economias') > -1 && (
						<>
							<div className="row">
								<div className="col-12">
									<TextAreaSubfield
										fieldName="economiasDesmembrarUnificar"
										label="Identificar economias a desmembrar/unificar"
										value={data?.economiasDesmembrarUnificar}
										qtdMinLinhas={2}
										errors={errors?.economiasDesmembrarUnificar}
										onChangeHandler={onChangeHandler}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<TextAreaSubfield
										fieldName="economiasDesmembradasUnificadas"
										label="Identificar economias desmembradas/unificadas"
										value={data?.economiasDesmembradasUnificadas}
										qtdMinLinhas={2}
										errors={errors?.economiasDesmembradasUnificadas}
										onChangeHandler={onChangeHandler}
									/>
								</div>
							</div>
						</>
					)}
					{data?.l6Option?.indexOf('boxes') > -1 && (
						<>
							<div className="row">
								<div className="col-12">
									<TextAreaSubfield
										fieldName="boxesDesmembrarUnificar"
										label="Identificar boxes a desmembrar/unificar"
										value={data?.boxesDesmembrarUnificar}
										qtdMinLinhas={2}
										errors={errors?.boxesDesmembrarUnificar}
										onChangeHandler={onChangeHandler}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<TextAreaSubfield
										fieldName="boxesDesmembradosUnificados"
										label="Identificar boxes desmembradas/unificadas"
										value={data?.boxesDesmembradosUnificados}
										qtdMinLinhas={2}
										errors={errors?.boxesDesmembradosUnificados}
										onChangeHandler={onChangeHandler}
									/>
								</div>
							</div>
						</>
					)}

					{/* Área a Reciclar */}
					{/* <div className="row">
						<div className="col-6">
							<AreaSubfield
								fieldName="areaReciclar"
								label="Área a reciclar (m²)"
								onChangeHandler={onChangeHandler}
								value={data?.areaReciclar}
								errors={errors?.areaReciclar}
								required={true}
							/>
						</div>
						<div className="col-6">
							<NumeroSubfield
								fieldName="numeroEconomias"
								label="Nº de economias propostas"
								onChangeHandler={onChangeHandler}
								value={data?.numeroEconomias}
								errors={errors?.numeroEconomias}
								required={true}
							/>
						</div>
					</div> */}
					{/* Plano Diretor */}
					<div className="row">
						<div className="col-12">
							<div className="pergunta">
								<PlanoDiretorSubfield formulario={formulario} data={data} onChangeHandler={onChangeHandler} />
								{size(errors?.planoDiretor) > 0 && <ErrorMessages errorList={errors.planoDiretor} />}
							</div>
						</div>
					</div>
					{/* termoQuantoAreaOcupacaoIntensiva (checkbox) */}
					<div className="row">
						<div className="col-12">
							<div className="pergunta text-wrap">
								<CheckboxSubfield
									labelStyle={{ textAlign: 'justify', paddingRight: '0' }}
									label={
										'A(s) atividade(s) não residencial(is) (conforme ANEXO 5.2 do PDDUA) ' +
										'atende(m) a RESTRIÇÃO QUANTO À IMPLANTAÇÃO DE ATIVIDADES NA ÁREA DE OCUPAÇÃO ' +
										'INTENSIVA (ANEXO 5.3) e/ou RESTRIÇÃO QUANTO AOS LIMITES DE PORTE NA ÁREA DE ' +
										'OCUPAÇÃO INTENSIVA (ANEXO 5.4)?*'
									}
									fieldName="termoQuantoAreaOcupacaoIntensiva"
									value={data?.termoQuantoAreaOcupacaoIntensiva}
									onChangeHandler={onChangeHandler}
									errors={errors?.termoQuantoAreaOcupacaoIntensiva}
								/>
							</div>
						</div>
					</div>
					{/* observações */}
					<div className="col-12 mt-3">
						<label style={{ color: '#7d7f80', fontSize: '14px' }}>Observações </label>
						<textarea
							style={{ marginBottom: '10px', marginLeft: '-5px' }}
							className="form-control"
							type="text"
							placeholder="Observações"
							value={data?.observacoes || ''}
							name="observacoes"
							onChange={onChangeHandler}
							rows={calculaNumeroLinhas(data?.observacoes)}
							readOnly={false}
							disabled={false}
						></textarea>
						{size(errors?.observacoes) > 0 && <ErrorMessages errorList={errors.observacoes} />}
					</div>
				</fieldset>
			)}

			<div className="cjto-botoes">
				<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowConfirm(true)}>
					Cancelar
				</button>
				<button
					type="button"
					className="btn btn-secondary mt-3 mr-2"
					onClick={() => submit({ draft: true, forceSave: allowForceSave })}
				>
					{allowForceSave ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
				</button>

				<button
					type="button"
					className="btn btn-primary mt-3"
					onClick={() => submit({ draft: false, forceSave: allowForceSave, gerarPdf: true })}
				>
					Salvar e gerar PDF
				</button>
			</div>
			{hasErros && (
				<div className="alert alert-danger mt-5">
					<p style={{ marginBottom: '0' }}>Existem erros no formulário, verifique acima.</p>
				</div>
			)}
			<ShowDebug data={{ data, errors }} />
		</div>
	);
}
LicencasExpressasDocEspecifico.displayName = 'LicencasExpressasDocEspecifico';
LicencasExpressasDocEspecifico.propTypes = {
	setShowFormulario: PropTypes.func,
	setShowConfirm: PropTypes.func,
	readOnly: PropTypes.bool,
	forAdmin: PropTypes.bool
};
export default LicencasExpressasDocEspecifico;

/**
 * obtem os dados preenchidos do formulário de licenciamento expresso
 * na aba documentos do portal
 * @param {*} formulario
 * @returns
 */
export function obtemFormularioLicencasExpressasDados(formulario) {
	const { documentos, documentosDados } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento?.original);
	return docDados || {};
}
