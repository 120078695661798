import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { isChrome, isOpera } from 'react-device-detect';

import useAxios from 'axios-hooks';
import { sortBy, pick } from 'lodash';
import moment from 'moment';

import { NumberField, TextAreaField, SelectField } from 'containers/Form/metadata-template/fields';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import { EXPEDIENTES_API_URL } from 'environments';

import { EnderecoObraVistoria } from './EnderecoObraVistoria';
import { METAVISTORIA } from './index';

export function InformacoesObraVistoria(props) {
	const { data = {}, onChange } = props;
	// axios
	// eslint-disable-next-line no-unused-vars
	const [{ data: tiposconstrucao, loading: tpLoading, error: tpError }] = useAxios(
		`${EXPEDIENTES_API_URL}/cadastros/tipoconstrucao`
	);
	// manipular os tipos de construção
	const [tiposConstrucao, setTiposConstrucao] = useState([]);
	useEffect(() => {
		if (tiposconstrucao) {
			let tipos = tiposconstrucao.map(tp => pick(tp, ['_id', 'codigo', 'descricao']));
			tipos = sortBy(tipos, 'descricao');
			setTiposConstrucao(tipos);
		}
	}, [tiposconstrucao]);

	return (
		<>
			<fieldset>
				<legend>Informações Sobre a Obra</legend>
				<div>
					<EnderecoObraVistoria {...props} />
				</div>
				<hr
					style={{
						borderWidth: '1px',
						borderColor: '#ccc',
						borderStyle: 'dashed',
						marginTop: '20px',
						marginBottom: '30px'
					}}
				/>
				<div className="pergunta metragem">
					<div className="d-flex">
						<SelectField
							name={METAVISTORIA.tipoConstrucao.name}
							required={METAVISTORIA.tipoConstrucao.required}
							label={METAVISTORIA.tipoConstrucao.label}
							value={data[METAVISTORIA.tipoConstrucao.name]}
							options={tiposConstrucao.map(tp => ({ codigo: tp._id, descricao: tp.descricao }))}
							dataSet={tiposConstrucao}
							dataPath="_id"
							onChangeHandler={onChange}
						/>
					</div>
				</div>
				<div className="pergunta metragem">
					<div className="d-flex">
						<NumberField
							name={METAVISTORIA.qtdPavimentos.name}
							label={METAVISTORIA.qtdPavimentos.label}
							required={METAVISTORIA.qtdPavimentos.required}
							value={data[METAVISTORIA.qtdPavimentos.name]}
							onChangeHandler={onChange}
						/>
					</div>
				</div>
				<div
					// className={data?.[METAVISTORIA.existemBoxes.name] === 'sim' ? 'form-group col-md-12' : 'form-group col-md-12'}
					className="pergunta"
				>
					<RadioFieldLE
						name={METAVISTORIA.existemBoxes.name}
						label={METAVISTORIA.existemBoxes.label}
						required={METAVISTORIA.existemBoxes.required}
						value={data[METAVISTORIA.existemBoxes.name]}
						onChangeHandler={onChange}
						options={[
							{ codigo: 'sim', descricao: 'Sim' },
							{ codigo: 'nao', descricao: 'Não' }
						]}
					/>
				</div>
				{data?.[METAVISTORIA.existemBoxes.name] === 'sim' && (
					<div className="pergunta metragem">
						<div className="d-flex">
							<NumberField
								name={METAVISTORIA.qtdBoxes.name}
								label={METAVISTORIA.qtdBoxes.label}
								required={METAVISTORIA.qtdBoxes.required}
								value={data[METAVISTORIA.qtdBoxes.name]}
								onChangeHandler={onChange}
							/>
						</div>
					</div>
				)}
				<div className="pergunta metragem">
					<div className="d-flex">
						<label className="control-label">
							{METAVISTORIA.dataInicioObra.label} <span className="required">*</span>
						</label>
						{isChrome || isOpera ? (
							<input
								type="month"
								value={
									data[METAVISTORIA.dataInicioObra.name]
										? moment(data[METAVISTORIA.dataInicioObra.name]).format('YYYY-MM')
										: ''
								}
								name={METAVISTORIA.dataInicioObra.name}
								onChange={e => {
									let timestamp = moment(e.target.value, 'YYYY-MM').valueOf();
									onChange && onChange({ name: METAVISTORIA.dataInicioObra.name, value: timestamp });
								}}
								className="form-control"
							></input>
						) : (
							<input
								type="date"
								value={
									data[METAVISTORIA.dataInicioObra.name]
										? moment(data[METAVISTORIA.dataInicioObra.name]).format('YYYY-MM-DD')
										: ''
								}
								name={METAVISTORIA.dataInicioObra.name}
								onChange={e => {
									let timestamp;
									if (e.target.value?.length === 7) {
										timestamp = moment(e.target.value, 'YYYY-MM').valueOf();
									} else {
										timestamp = moment(e.target.value, 'YYYY-MM-DD').valueOf();
									}

									onChange && onChange({ name: METAVISTORIA.dataInicioObra.name, value: timestamp });
								}}
								className="form-control"
							></input>
						)}
					</div>
				</div>
				{/* <div className="form-group col-md-6"> </div> */}
				<div className="pergunta">
					<RadioFieldLE
						name={METAVISTORIA.imovelOcupado.name}
						label={METAVISTORIA.imovelOcupado.label}
						required={METAVISTORIA.imovelOcupado.required}
						value={data[METAVISTORIA.imovelOcupado.name]}
						onChangeHandler={onChange}
						options={[
							{ codigo: 'sim', descricao: 'Sim' },
							{ codigo: 'nao', descricao: 'Não' }
						]}
					/>
				</div>
				{data?.[METAVISTORIA.imovelOcupado.name] === 'sim' && (
					<>
						<div className="pergunta">
							<RadioFieldLE
								name={METAVISTORIA.apenasUmaDataOcupacao.name}
								label={METAVISTORIA.apenasUmaDataOcupacao.label}
								required={METAVISTORIA.apenasUmaDataOcupacao.required}
								value={data[METAVISTORIA.apenasUmaDataOcupacao.name]}
								onChangeHandler={onChange}
								options={[
									{ codigo: 'sim', descricao: 'Sim' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
							/>
						</div>
						{data[METAVISTORIA.apenasUmaDataOcupacao.name] === 'sim' ? (
							<>
								<div className="pergunta metragem">
									<div className="d-flex">
										<label className="control-label">
											{METAVISTORIA.dataOcupacaoObra.label} <span className="required">*</span>
										</label>
										{data && data[METAVISTORIA.dataInicioObra.name] && (
											<>
												{isChrome || isOpera ? (
													<input
														type="month"
														value={
															data[METAVISTORIA.dataOcupacaoObra.name]
																? moment(data[METAVISTORIA.dataOcupacaoObra.name]).format('YYYY-MM')
																: ''
														}
														name={METAVISTORIA.dataOcupacaoObra.name}
														onChange={e => {
															let timestamp = moment(e.target.value, 'YYYY-MM').valueOf();
															onChange && onChange({ name: METAVISTORIA.dataOcupacaoObra.name, value: timestamp });
														}}
														className="form-control"
														min={moment(data[METAVISTORIA.dataInicioObra.name]).format('YYYY-MM')}
														max={moment().format('YYYY-MM')}
													></input>
												) : (
													<input
														type="date"
														value={
															data[METAVISTORIA.dataOcupacaoObra.name]
																? moment(data[METAVISTORIA.dataOcupacaoObra.name]).format('YYYY-MM-DD')
																: ''
														}
														name={METAVISTORIA.dataOcupacaoObra.name}
														onChange={e => {
															let timestamp;
															if (e.target.value?.length === 7) {
																timestamp = moment(e.target.value, 'YYYY-MM').valueOf();
															} else {
																timestamp = moment(e.target.value, 'YYYY-MM-DD').valueOf();
															}
															onChange && onChange({ name: METAVISTORIA.dataOcupacaoObra.name, value: timestamp });
														}}
														className="form-control"
														min={moment(data[METAVISTORIA.dataInicioObra.name]).format('YYYY-MM-DD')}
														max={moment().format('YYYY-MM-DD')}
													></input>
												)}
											</>
										)}
										{data && !data[METAVISTORIA.dataInicioObra.name] && (
											<label style={{ marginTop: '15px', color: 'red' }}>
												É necessário informar a data de início da obra!
											</label>
										)}
									</div>
								</div>
								{/* <div className="form-group col-md-6"> </div> */}
							</>
						) : data[METAVISTORIA.apenasUmaDataOcupacao.name] === 'nao' ? (
							<div className="pergunta">
								<TextAreaField
									label={METAVISTORIA.descricaoOcupacaoEconomias.label}
									required={METAVISTORIA.descricaoOcupacaoEconomias.required}
									value={data[METAVISTORIA.descricaoOcupacaoEconomias.name]}
									name={METAVISTORIA.descricaoOcupacaoEconomias.name}
									placeHolder={METAVISTORIA.descricaoOcupacaoEconomias.placeholder}
									onChangeHandler={onChange}
								/>
							</div>
						) : null}
					</>
				)}
			</fieldset>
		</>
	);
}
InformacoesObraVistoria.displayName = 'InformacoesObraVistoria';
InformacoesObraVistoria.propTypes = {
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	containerRef: PropTypes.any,
	onChange: PropTypes.func
};
