import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import gql from 'graphql-tag';
import { size, trim, get } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';
import Selecao from 'components/Selecao';

import { REGEX_NUMBERS_ONLY } from 'utils/constants';
import apolloClient from 'utils/graphql';
import { normalizedAddress, permiteInput } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class EnderecoCDL extends React.Component {
	static displayName = 'EnderecoCDL';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);

		let cdl = null;

		if (props.formData) {
			if (props.formData.codigoCDL) {
				cdl = {
					id: props.formData.codigoCDL,
					nomeLogradouro: props.formData.logradouro
				};
			}
		}

		this.state = {
			formData: get(props, 'formData'),
			codigoCDL: get(props, 'formData.codigoCDL'),
			logradouro: get(props, 'formData.logradouro'),
			numero: get(props, 'formData.numero'),
			cdl,
			className: CLASS_NAME_FORM_CONTROL
		};
	}

	setLogradouro = e => {
		const term = e.target.value;
		this.setState(() => ({ logradouro: term }));
		this.buscaCDL(term);
		this.props.onChange({ logradouro: term });
	};

	setCDL = cdl => () => {
		this.montaEndereco(cdl.id, this.state.numero);
		this.setEndereco({
			logradouro: cdl.nomeLogradouro,
			logradourosPesquisados: []
		});
	};

	unsetCDL = () => () => {
		this.setEndereco({ logradouro: '', cdl: undefined, codigoCDL: undefined });
	};

	setNumero = e => {
		if (permiteInput(e.target.value, 'int')) {
			if (this.state.cdl && this.state.cdl.id) {
				this.montaEndereco(this.state.cdl.id, e.target.value);
			}
			this.setEndereco({ numero: e.target.value });
		}
	};

	setEndereco = endereco => {
		const obj = { ...this.state, ...endereco };
		this.setState(() => endereco);
		const logradouro = get(obj, 'cdl.nomeLogradouro');
		const numero = get(obj, 'numero');
		const retorno = {
			...obj.formData,
			logradouro: size(logradouro) === 0 ? undefined : logradouro,
			numero: size(numero) === 0 ? undefined : numero,
			codigoCDL: get(obj, 'cdl.id')
		};
		this.props.onChange(retorno);
	};

	buscaCDL = async logradouro => {
		try {
			const queryCDL = gql`
				query CDL($term: String) {
					CdlEnderecoList(term: $term) {
						id
						nomeLogradouro
						enderecoFormatadoCurto
					}
				}
			`;
			if (size(logradouro) > 2) {
				this.setState({ loading: true });
				const {
					data: { CdlEnderecoList }
				} = await apolloClient.query({
					query: queryCDL,
					variables: { term: logradouro },
					fetchPolicy: 'network-only'
				});
				this.setState({ logradourosPesquisados: CdlEnderecoList, loading: false });
			} else {
				this.setState({ logradourosPesquisados: [], loading: false });
			}
		} catch (e) {
			this.setState({ logradourosPesquisados: [], loading: false });
			console.error('erro no servidor', e);
			// yield put(push('/serverUnavailable'));
		}
	};

	montaEndereco = async (codLogradouro, numero) => {
		try {
			const queryCDL = gql`
				query byId($id: String!) {
					CdlEnderecoById(id: $id) {
						id
						nomeLogradouro
						nomeHistorico
						codigoBairro
						nomeBairro
						lado
						nomeBairroHistorico
						numero
						cep
						enderecoFormatado
						enderecoFormatadoCurto
					}
				}
			`;
			this.setState({ loading: true });
			const id = {
				codLogradouro,
				numero
			};
			const query = {
				query: queryCDL,
				variables: { id: JSON.stringify(id) },
				fetchPolicy: 'network-only'
			};
			const {
				data: { CdlEnderecoById }
			} = await apolloClient.query(query);
			this.setEndereco({ cdl: CdlEnderecoById, codigoCDL: CdlEnderecoById.id, loading: false });
		} catch (e) {
			this.setEndereco({ cdl: {}, codigoCDL: undefined, loading: false });
			console.error('erro no servidor', e);
			// yield put(push('/serverUnavailable'));
		}
	};

	isValidLogradouro = () =>
		size(this.state.logradouro) === 0 || size(trim(normalizedAddress(this.state.logradouro))) > 0;
	isValidNumero = () => size(this.state.numero) === 0 || REGEX_NUMBERS_ONLY.test(this.state.numero);

	extractErrors = attr => get(attr, '__errors') || [];

	render() {
		const requireds = get(this.props, 'schema.required') || [];
		const requiredLogradouro = requireds.indexOf('logradouro') > -1;
		const requiredNumero = requireds.indexOf('numero') > -1;
		const {
			codigoCDL: errorCodigoCDL,
			logradouro: errorLogradouro,
			numero: errorNumero
		} = this.props.errorSchema || {};
		const missingCDL = this.extractErrors(errorCodigoCDL) > 0 && this.extractErrors(errorLogradouro) === 0;
		const { cdl, numero, codigoCDL, logradourosPesquisados, logradouro, loading } = this.state;
		const { readonly, disabled } = this.props;
		return (
			<Fragment>
				<div className="form-group  col-md-8">
					<label className="control-label" htmlFor="root_nome">
						Logradouro
						{requiredLogradouro ? '*' : ''}
					</label>
					<div />
					<Selecao
						className={
							this.isValidLogradouro()
								? `${CLASS_NAME_FORM_CONTROL}`
								: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
						}
						multiple={false}
						selected={cdl}
						label={'Logradouro'}
						detailInnerClassName={'inner-list-item inner-list-item-input'}
						detailCodigo={''}
						detailDescricao={'nomeLogradouro'}
						autoShowList={false}
						searchTerm={logradouro}
						searchList={logradourosPesquisados}
						searchTermMinLength={3}
						errorList={false}
						onChangeSearchTerm={this.setLogradouro}
						onBlurSearchTerm={() => false}
						onSelectItem={this.setCDL}
						onUnselect={this.unsetCDL}
						noResetList={true}
						loading={loading}
						required={requiredLogradouro}
						placeholder="Informe o logradouro e selecione da lista"
						readOnly={disabled || readonly}
					/>
					<ErrorDetail attr={errorLogradouro} />
					<input type="hidden" value={codigoCDL || ''} />
					{missingCDL && <ErrorDetail attr={errorCodigoCDL} />}
					<div />
				</div>
				<div className="form-group  col-md-4">
					<label className="control-label" htmlFor="root_nome">
						Número
						{requiredNumero ? '*' : ''}
					</label>
					<div />
					<input
						className={
							this.isValidLogradouro()
								? `${CLASS_NAME_FORM_CONTROL}`
								: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
						}
						type="text"
						value={numero || ''}
						onChange={this.setNumero}
						placeholder="apenas números"
						readOnly={readonly}
						disabled={disabled}
					/>
					<ErrorDetail attr={errorNumero} />
					<div />
				</div>
			</Fragment>
		);
	}
}

export default EnderecoCDL;
