import React, { useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';
import styled from 'styled-components';

import EmbedList from 'components/EmbedList';
import IntlNumberInput from 'components/react-intl-number-input';
import Selecao from 'components/Selecao';
import TooltipIcon from 'components/TooltipIcon';

import { accessApi } from 'utils/injectApi';
import { sortCadastros, numericValue } from 'utils/tools';

import AtividadeEdit from './AtividadeEdit';
import { CODIGO_ATIVIDADE_RESIDENCIAL } from './constants';

const HeaderSubunidade = styled.div`
	background-color: #edeff1;
	width: 100%;
	padding: 10px 0px;
	text-align: center;
	font-weight: bold;
	font-size: 13pt;
	text-transform: uppercase;
	border-bottom: ${props => (props.open ? '1px solid !important;' : '0px')};
	i {
		float: right;
		margin-right: 12px;
		margin-top: 5px;
		cursor: pointer;
	}
`;

const SubunidadeEdit = ({ subunidade, onChange, onRemove, totalEconomias, totalTpcSoloCriado }) => {
	const [anexo6List, setAnexo6List] = useState([]);
	const [anexo71List, setAnexo71List] = useState([]);
	const [term6, setTerm6] = useState('');
	const [term71, setTerm71] = useState('');
	const [show, setShow] = useState(false);

	const [anexo51list, setAnexo51list] = useState([]);
	const [term51, setTerm51] = useState('');
	const [listaErroAnexo51, setListaErroAnexo51] = useState([]);
	const [anexo51selected, setAnexo51selected] = useState([]);

	const possuiAtvResidencialComPeloMenos3Economias = useMemo(
		//só deve ser permitido o preenchimento se residencial (SOMENTE residencial) e 3 ou mais economias
		() =>
			(subunidade.listaAtividades || []).reduce(
				(acc, atividade) =>
					acc ||
					(get(atividade, 'economias') >= 3 &&
						!!atividade.atividadeItens.find(
							item => get(item, 'anexo52.codigo', '').substring(0, 1) === CODIGO_ATIVIDADE_RESIDENCIAL
						)),
				false
			),
		[subunidade.listaAtividades]
	);

	useEffect(() => {
		if (subunidade && subunidade.listaAtividades) {
			// cálculo de total de economias e área nas diversas atividades
			const { economias, area } = (subunidade.listaAtividades || []).reduce(
				(acc, atividade) => ({
					economias: acc.economias + (atividade.economias || 0),
					area: acc.area + (atividade.area || 0)
				}),
				{ economias: 0, area: 0 }
			);
			subunidade.totalEconomias = economias;
			subunidade.totalArea = area;
		}

		if (subunidade && subunidade.anexo6) {
			// Calcula limites de area adensavel e nao adensavel
			if (subunidade.beneficioDos30) {
				subunidade.limiteAreaAdensavel =
					parseFloat(get(subunidade.anexo6, 'ia').replace(',', '.')) * 1.3 * subunidade.area;
				subunidade.limiteAreaNaoAdensavel = 0;
			} else {
				subunidade.limiteAreaAdensavel = parseFloat(get(subunidade.anexo6, 'ia').replace(',', '.')) * subunidade.area;
				subunidade.limiteAreaNaoAdensavel = subunidade.limiteAreaAdensavel * 0.5;
			}
		}

		if (!possuiAtvResidencialComPeloMenos3Economias) {
			subunidade.qi = 0;
		}
	}, [subunidade, possuiAtvResidencialComPeloMenos3Economias]);

	useEffect(() => {
		const obtemAnexo6List = async () => {
			const retorno = (await accessApi('/collections/anexo6/')).data;
			const list = sortCadastros(retorno, 'codigo');
			setAnexo6List(list);
		};
		const obtemAnexo71List = async () => {
			const retorno = (await accessApi('/collections/anexo71/')).data;
			const list = sortCadastros(retorno, 'codigo');
			setAnexo71List(list);
		};
		const obtemAnexo51List = async () => {
			const retorno = (await accessApi('/collections/anexo51/')).data;
			const anexo51list = sortCadastros(retorno, 'codigo');
			setAnexo51list(anexo51list);
		};
		obtemAnexo51List();
		obtemAnexo6List();
		obtemAnexo71List();
	}, []);

	const limiteIA = useMemo(() => {
		const indice = numericValue(get(subunidade, totalTpcSoloCriado > 0 ? 'anexo6.iamaximo' : 'anexo6.ia')) || 0;
		const area = get(subunidade, 'area') || 0;
		return indice * area * (totalEconomias === 1 ? 1.3 : 1);
	}, [subunidade, totalEconomias, totalTpcSoloCriado]);

	useEffect(() => {
		if (size(subunidade) === 0) {
			setShow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (size(subunidade) > 0) {
			if (subunidade.limiteIA !== limiteIA) {
				onChange({ ...subunidade, limiteIA: limiteIA });
			}
		}
	}, [subunidade, limiteIA, onChange]);

	const handleChange = e => {
		const { value, name } = e.target;
		// se anexo51 selecionado for 11, exibe mensagem de alerta sobre projeto especial
		if (name === 'anexo51') {
			if (value) {
				if (value.codigo === '11') {
					setListaErroAnexo51([
						'No caso de seleção do grupamento 11, será necessário a solicitação de projeto especial'
					]);
				}
			} else {
				setListaErroAnexo51([]);
			}
			let aux = anexo51selected;
			aux.push(value);
			setAnexo51selected(aux);
			setAnexo51list(anexo51list.filter(item => item.codigo !== value.codigo));

			onChange({ ...subunidade, anexo51: aux });
		}
	};

	const handleUnselect = e => {
		const { value, name } = e.target;
		if (name === 'anexo51') {
			let aux = anexo51selected;
			aux = aux.filter(item => item.codigo !== value.codigo);
			setAnexo51selected(aux);
			let auxListaSelecionaveis = anexo51list;
			auxListaSelecionaveis.push(value);
			setAnexo51list(sortCadastros(auxListaSelecionaveis, 'codigo'));
			onChange({ ...subunidade, anexo51: aux });
		}
	};

	const adicionaAtividade = () => {
		onChange({ ...subunidade, listaAtividades: [...(subunidade.listaAtividades || []), { atividadeItens: [{}] }] });
	};

	const atualizaAtividade = index => item => {
		onChange({
			...subunidade,
			listaAtividades: (subunidade.listaAtividades || []).map((atividade, i) => (index === i ? item : atividade))
		});
	};

	const removeAtividade = index => () => {
		onChange({
			...subunidade,
			listaAtividades: (subunidade.listaAtividades || []).filter((item, i) => i !== index)
		});
	};

	// AUXILIARES PARA RENDER

	return subunidade ? (
		<>
			<div className="row">
				<HeaderSubunidade onClick={() => setShow(oldState => !oldState)} open={show}>
					{`Subunidade ${subunidade.codigo || '???'}`}
					<i className={`fa fa-angle-${show ? 'up' : 'down'}`} />
					{show && <i className="fa fa-trash" onClick={onRemove} />}
				</HeaderSubunidade>
			</div>
			{show && (
				<>
					<div className="row subunidade">
						<div className="form-group col-4">
							<label>
								<TooltipIcon label="Código da subunidade">Código da subunidade</TooltipIcon>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									precision={0}
									value={subunidade.codigo}
									onChange={(e, value) => onChange({ ...subunidade, codigo: value })}
								/>
							</label>
						</div>
						<div className="form-group col-5">
							<label>
								<TooltipIcon label="Grupamento atividade * (anexo 5.1)">
									Anexo 5.1 PDDUA: Preencher apenas com o código do Grupamento de Ativiadade (GA) e Zona de Uso,
									conforme Anexo 5.1 do PDDUA. Vide orientações complementares no site:
									http://lproweb.procempa.com.br/pmpa/prefpoa/edificapoa/usu_doc/grupativ.pdf
								</TooltipIcon>
							</label>
							<Selecao
								className={'form-control'}
								multiple={true}
								selected={subunidade.anexo51}
								label={'Grupamento de atividade (anexo 5.1'}
								detailCodigo={''}
								detailDescricao={'codigo'}
								autoShowList={true}
								searchTerm={term51}
								searchList={anexo51list.filter(item => new RegExp(term51, 'i').test(item.codigo))}
								searchTermMinLength={1}
								errorList={listaErroAnexo51 || false}
								onChangeSearchTerm={event => setTerm51(event.target.value)}
								onBlurSearchTerm={() => setTerm51('')}
								onSelectItem={item => () => handleChange({ target: { name: 'anexo51', value: item } })}
								onUnselect={item => () => handleUnselect({ target: { name: 'anexo51', value: item } })}
								loading={false}
								required={true}
								placeholder="Selecione a atividade"
							/>
							<a
								href="http://lproweb.procempa.com.br/pmpa/prefpoa/edificapoa/usu_doc/grupativ.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								documentação
							</a>
						</div>

						<div className="form-group col-3">
							<label>
								<TooltipIcon label="Área da Subunidade">
									Quando o imóvel for atingido por mais de uma subunidade (ex. subunidade 2,3, etc.) deverá ser
									informada a área do terreno atingida por cada uma destas subunidades nos campos correspondentes, para
									posterior aplicação dos regimes individualmente.
								</TooltipIcon>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={subunidade.area}
									onChange={(e, value) => onChange({ ...subunidade, area: value })}
								/>
							</label>
						</div>
					</div>

					<div>
						<h3>{`Lista de atividades da subunidade ${subunidade.codigo || ''}`}</h3>
						<EmbedList
							itemList={subunidade.listaAtividades}
							emptyMessage="Nenhuma atividade cadastrada."
							showAddButton={true}
							novoItem={adicionaAtividade}
							novoItemLabel="adicionar atividade"
							itemStyle={{ marginBottom: '8px' }}
						>
							{(item, index) => (
								<AtividadeEdit
									id={`${index + 1}ª Atividade`}
									atividade={item}
									onChange={atualizaAtividade(index)}
									onRemove={removeAtividade(index)}
								/>
							)}
						</EmbedList>
						{(!!subunidade.totalEconomias || !!subunidade.totalArea) && (
							<div className="row atividade" style={{ margin: '0px' }}>
								<div className="form-group col-sm-6">
									<label>Total de Economias</label>
									<IntlNumberInput
										type="text"
										className="form-control"
										locale="pt-BR"
										precision={0}
										value={subunidade.totalEconomias}
										disabled={true}
									/>
								</div>
								<div className="form-group col-sm-6">
									<label>Área Total</label>
									<IntlNumberInput
										type="text"
										className="form-control"
										locale="pt-BR"
										suffix=" m²"
										precision={2}
										value={subunidade.totalArea}
										disabled={true}
									/>
								</div>
							</div>
						)}
					</div>
					<br />
					<div className="row subunidade" style={{ padding: '0px 16px 8px', display: 'flex', flexDirection: 'column' }}>
						<h3>Índice de aproveitamento</h3>
						<div className="row subunidade">
							<div className="form-group col-12">
								<h4>Anexo 6 - Aproveitamento</h4>
								<label>Código da DMWeb</label>
								<Selecao
									className={'form-control'}
									multiple={false}
									selected={subunidade.anexo6}
									label={'Anexo 6'}
									detailCodigo={''}
									detailDescricao={'codigo'}
									autoShowList={true}
									searchTerm={term6}
									searchList={anexo6List.filter(item => new RegExp(term6, 'i').test(item.codigo))}
									searchTermMinLength={1}
									errorList={false}
									onChangeSearchTerm={event => setTerm6(event.target.value)}
									onBlurSearchTerm={() => setTerm6('')}
									onSelectItem={item => () => onChange({ ...subunidade, anexo6: item })}
									onUnselect={() => () => onChange({ ...subunidade, anexo6: null })}
									loading={false}
									required={true}
									placeholder="Selecione o código"
								/>
							</div>
						</div>
						{subunidade.anexo6 && (
							<>
								<table className="table">
									<thead>
										<tr>
											<th>IA</th>
											<th>SC</th>
											<th>TPC</th>
											<th>IA máximo</th>
											<th>Q.I.</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{get(subunidade.anexo6, 'ia')}</td>
											<td>{get(subunidade.anexo6, 'sc')}</td>
											<td>{get(subunidade.anexo6, 'tpc')}</td>
											<td>{get(subunidade.anexo6, 'iamaximo')}</td>
											<td>{get(subunidade.anexo6, 'quotaideal')}</td>
										</tr>
									</tbody>
								</table>
								<div className="row">
									<div className="form-group col-sm-6">
										<TooltipIcon label="Limite de área adensável">
											A área adensável máxima permitida será o resultado da multiplicação do IA (sem TPC) ou IA Máximo
											(com TPC) pela área da Subunidade, acrescidos de 30% para projetos de até uma economia
										</TooltipIcon>
										<IntlNumberInput
											type="text"
											className="form-control"
											locale="pt-BR"
											suffix=" m²"
											precision={2}
											value={subunidade.limiteAreaAdensavel}
											disabled={true}
										/>
									</div>
									<div className="form-group col-sm-6">
										<TooltipIcon label="Limite de área não adensável">
											A área não adensável máximas permitidas será de 50% da máxima adensável
										</TooltipIcon>
										<IntlNumberInput
											type="text"
											className="form-control"
											locale="pt-BR"
											suffix=" m²"
											precision={2}
											value={subunidade.limiteAreaNaoAdensavel}
											disabled={true}
										/>
									</div>
								</div>
								<div className="row">
									<div className="inline-input-radio-checkbox col-sm-12">
										<label>
											<input
												type="checkbox"
												name={'beneficioDos30'}
												onClick={e => onChange({ ...subunidade, beneficioDos30: e.target.checked })}
												onChange={() => false}
												checked={subunidade.beneficioDos30 || false}
											/>{' '}
											<span>{'Incluir benefício dos 30%?'}</span>
										</label>
									</div>
								</div>
							</>
						)}
					</div>

					<div className="row subunidade" style={{ padding: '0px 16px 8px', display: 'flex', flexDirection: 'column' }}>
						<h3>Volumetria</h3>
						<div className="row subunidade">
							<div className="form-group col-12">
								<h4>Anexo 7.1 - Volumetria</h4>
								<label>Código da DMWeb</label>
								<Selecao
									className={'form-control'}
									multiple={false}
									selected={subunidade.anexo71}
									label={'Anexo 7.1'}
									detailCodigo={''}
									detailDescricao={'codigo'}
									autoShowList={true}
									searchTerm={term71}
									searchList={anexo71List.filter(item => new RegExp(term71, 'i').test(item.codigo))}
									searchTermMinLength={1}
									errorList={false}
									onChangeSearchTerm={event => setTerm71(event.target.value)}
									onBlurSearchTerm={() => setTerm71('')}
									onSelectItem={item => () => onChange({ ...subunidade, anexo71: item })}
									onUnselect={() => () => onChange({ ...subunidade, anexo71: null })}
									loading={false}
									required={true}
									placeholder="Selecione o código"
								/>
							</div>
						</div>

						{subunidade.anexo71 && (
							<>
								<table className="table">
									<thead>
										<tr>
											<th>Altura máxima (m)</th>
											<th>Altura divisa (m)</th>
											<th>Altura base (m)</th>
											<th>Taxa de ocupação (%)</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{get(subunidade.anexo71, 'alturamaxima')}</td>
											<td>{get(subunidade.anexo71, 'alturadivisa')}</td>
											<td>{get(subunidade.anexo71, 'alturabase')}</td>
											<td>
												{get(subunidade.anexo71, 'taxaocupacao') && (
													<div>{get(subunidade.anexo71, 'taxaocupacao')}</div>
												)}
												{!get(subunidade.anexo71, 'taxaocupacao') && get(subunidade.anexo71, 'taxaocupacaobase') && (
													<div>
														base {get(subunidade.anexo71, 'taxaocupacaobase')} e corpo{' '}
														{get(subunidade.anexo71, 'taxaocupacaocorpo')}
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
								<div className="row">
									<div className="form-group col-sm-4">
										<label>Altura Máxima</label>
										<IntlNumberInput
											type="text"
											className="form-control"
											locale="pt-BR"
											suffix=" m"
											precision={2}
											value={subunidade.alturaMaxima}
											onChange={(e, value) => onChange({ ...subunidade, alturaMaxima: value })}
										/>
									</div>
									<div className="form-group col-sm-4">
										<label>Altura Divisa</label>
										<IntlNumberInput
											type="text"
											className="form-control"
											locale="pt-BR"
											suffix=" m"
											precision={2}
											value={subunidade.alturaDivisa}
											onChange={(e, value) => onChange({ ...subunidade, alturaDivisa: value })}
										/>
									</div>
									<div className="form-group col-sm-4">
										<label>Altura Base</label>
										<IntlNumberInput
											type="text"
											className="form-control"
											locale="pt-BR"
											suffix=" m"
											precision={2}
											value={subunidade.alturaBase}
											onChange={(e, value) => onChange({ ...subunidade, alturaBase: value })}
										/>
									</div>
								</div>
							</>
						)}
						<div className="row">
							<div className="form-group col-12 col-sm-4">
								<label htmlFor="dgcea">
									<TooltipIcon label="Taxa de Ocupação - Base">
										Informar a Taxa de Ocupação máxima da base da edificação, conforme Anexo 7.1 do PDDUA.
									</TooltipIcon>
								</label>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix="%"
									precision={2}
									value={subunidade.taxaOcupacaoBase}
									onChange={(e, value) => onChange({ ...subunidade, taxaOcupacaoBase: value })}
								/>
							</div>
							<div className="form-group col-12 col-sm-4">
								<label htmlFor="dgcea">
									<TooltipIcon label="Taxa de Ocupação - Corpo">
										Informar a Taxa de Ocupação máxima do corpo da edificação, conforme Anexo 7.1 do PDDUA.
									</TooltipIcon>
								</label>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix="%"
									precision={2}
									value={subunidade.taxaOcupacaoCorpo}
									onChange={(e, value) => onChange({ ...subunidade, taxaOcupacaoCorpo: value })}
								/>
							</div>
							<div className="form-group col-12 col-sm-4">
								<label htmlFor="qi">
									<TooltipIcon label="Quota Ideal (Art.109 PDDUA)">
										{`${
											'Informar o número de unidades residenciais permitidas na zona, quando for o caso, conforme o definido no ' +
											'Anexo 6, em função do que prevê o Art. 109 do PDDUA:.I. Nas edificações residenciais situadas nas UEUs com ' +
											'regime volumétrico código 01; II. Nos condomínios por unidades autônomas de habitação unifamiliar, na Área ' +
											'de Ocupação Intensiva; e III. Em todas as construções, na Área de Ocupação Rarefeita.'
										}${
											!possuiAtvResidencialComPeloMenos3Economias
												? ' O campo está desabilitado por que não há somente atividades residenciais ou existem menos de 3 economias.'
												: ''
										}`}
									</TooltipIcon>
								</label>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={subunidade.qi}
									onChange={(e, value) => onChange({ ...subunidade, qi: value })}
									disabled={!possuiAtvResidencialComPeloMenos3Economias}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	) : null;
};
SubunidadeEdit.displayName = 'SubunidadeEdit';

SubunidadeEdit.propTypes = {
	subunidade: PropTypes.object,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
	totalEconomias: PropTypes.number,
	totalTpcSoloCriado: PropTypes.number
};

export default SubunidadeEdit;
