import { get, pick, startCase } from 'lodash';

import { TIPOS_PORTE } from './licenca';
/**
 * obtem o porte e potencial poluidor conforme o codram selecionado
 * e tamanho do porte do empreendimento
 * @param {*} formdata
 * @param {*} callback
 */
export function obterPotencialPorte(formdata, callback) {
	const codram = get(formdata, 'codram', {});
	const portesCodram = pick(codram, [
		'naoIncidencia',
		'porteMinimo',
		'portePequeno',
		'porteMedio',
		'porteGrande',
		'porteExcepcional'
	]);
	let tipoPorte;
	let medidaInformada = get(formdata, 'medidaPorte.value', '');
	medidaInformada = parseFloat(medidaInformada.replace(/,/g, '.'));

	for (const keyporte in portesCodram) {
		if (!medidaInformada) {
			break;
		}
		/* eslint-disable-next-line no-prototype-builtins */
		if (portesCodram.hasOwnProperty(keyporte)) {
			let valorporte = portesCodram[keyporte];
			if (!valorporte) {
				tipoPorte = undefined;
				continue;
			}
			valorporte = valorporte.trim().replace(/,/g, '.');
			const splits = valorporte.split(' ');
			// demais
			if (splits.length === 1) {
				if (splits[0] === 'demais') {
					tipoPorte = keyporte;
					break;
				}
				if (splits[0] === 'Único') {
					tipoPorte = TIPOS_PORTE.especialUnico;
					break;
				}
			}
			//até numero;
			if (splits.length === 2) {
				const base = parseFloat(splits[1]);
				if (medidaInformada <= base) {
					tipoPorte = keyporte;
					break;
				} else {
					continue;
				}
			}
			//de numero até numero;
			if (splits.length === 4) {
				const base = parseFloat(splits[1]);
				const limite = parseFloat(splits[3]);
				if (medidaInformada >= base && medidaInformada <= limite) {
					tipoPorte = keyporte;
					break;
				} else {
					continue;
				}
			}
		}
	}
	callback &&
		callback({
			porte: startCase(tipoPorte),
			potencialPoluidor: codram.potencialPoluidor
		});
}
