import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import useAxios from 'axios-hooks';
import extenso from 'extenso';
import { capitalize, flatMap, get, omit, size } from 'lodash';
import uuid from 'uuid/v4';

import termometro0 from 'assets/images/termometro_0.png';
import termometro1 from 'assets/images/termometro_1.png';
import termometro2 from 'assets/images/termometro_2.png';
import termometro3 from 'assets/images/termometro_3.png';
import termometro4 from 'assets/images/termometro_4.png';
import termometro5 from 'assets/images/termometro_5.png';
import termometro6 from 'assets/images/termometro_6.png';
import termometro7 from 'assets/images/termometro_7.png';

import Confirm from 'components/Confirm';
import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import DocumentoUpload from 'containers/Form/aba-documentos/DocumentoUpload';
import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL } from 'containers/Form/Detalhe';

import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { API_URL } from 'environments';

import { sendApi } from 'utils/injectApi';
import { isDebug, getUpdatedToken } from 'utils/tools';

import Inputs from './Inputs';
import Pergunta from './Pergunta';
import { Validacao as validacao } from './Validacao';

import './index.scss';

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(CERTIFICACAO-SUSTENTAVEL-DOC)', ++i, ...args);

function DocumentoCertificacaoSustentavel({
	setShowFormulario,
	showFormulario,
	setShowConfirm,
	showConfirm,
	setPosProcessFormulario,
	posProcessFormulario,
	usuarioInterno,
	onChangeHandler,
	removeFile
}) {
	// #region hooks
	/* REDUX */
	const dispatch = useDispatch();

	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errors, setErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const { createMessage } = useMessages();

	const enquadramento = useMemo(() => formulario?.formData?.data?.enquadramento?.definicao, [formulario]);

	/* ESTADOS */
	const [data, setData] = useState(null);
	const [perguntasComArquivosCancelar, setPerguntasComArquivosCancelar] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dimensaoAberta, setDimensaoAberta] = useState(null);
	const [editedEnderecoFinalCs, setEditedEnderecoFinalCs] = useState(false);
	const [editedEmpreendimentoFinalCs, setEditedEmpreendimentoFinalCs] = useState(false);
	const formRef = useRef();
	const { idForm: idFormulario, id: idFormData } = useParams();
	const [confirmaExclusaoDocumentos, setConfirmaExclusaoDocumentos] = useState(null);
	const [forceSave, setForceSave] = useState(false);

	/* QUERIES */
	const [{ data: metadata }] = useAxios(
		`${API_URL}/collections/metadata/${EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}`
	);
	// #endregion

	// atualiza data se mudar o formulário
	useEffect(() => {
		if (metadata && !data) {
			const dd = obtemCertificacaoSustentavelDados(formulario);
			if (dd) {
				setData(dd.dados);
			} else {
				// inicializa perguntas com 'nao'
				const questoes = flatMap(metadata.dimensoes, dimensao => dimensao.questoes);
				const perguntas = flatMap(questoes, questao => questao.perguntas);
				const novosDados = perguntas.reduce((acc, p) => ({ ...acc, [p.id]: 'nao' }), {});
				novosDados.enderecoFinalCs = undefined;
				novosDados.empreendimentoFinalCs = undefined;
				setData(novosDados);
			}
		}
		if (data) {
			if (
				data.empreendimentoFinalCs === undefined &&
				get(formulario?.formData.data, 'nomeProprietario', null) &&
				size(get(formulario?.formData.data, 'nomeProprietario', null)) > 0 &&
				!editedEmpreendimentoFinalCs &&
				editedEnderecoFinalCs
			) {
				setData({ ...data, empreendimentoFinalCs: get(formulario?.formData.data, 'nomeProprietario') });
				setEditedEmpreendimentoFinalCs(true);
			} else if (data.empreendimentoFinalCs !== undefined) {
				setEditedEmpreendimentoFinalCs(true);
			}
			if (
				data.enderecoFinalCs === undefined &&
				size(get(formulario?.formData.data, 'enderecoCdlList', [])) > 0 &&
				!editedEnderecoFinalCs
			) {
				setData({
					...data,
					enderecoFinalCs: get(formulario?.formData.data, 'enderecoCdlList', []).map(e => e.enderecoFormatadoCurto)[0]
				});
				setEditedEnderecoFinalCs(true);
			} else if (data.enderecoFinalCs !== undefined) {
				setEditedEnderecoFinalCs(true);
			}
		}
	}, [data, editedEmpreendimentoFinalCs, editedEnderecoFinalCs, formulario, metadata]);

	useEffect(() => {
		let checkSize = size(errors) === 1;
		// condicional para caso seja erro de geração do doc específico CS (faltou apenas gerá-lo)
		let findDoc = formulario?.documentos?.find(d => d.idDocumento === EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL);
		setHasErrors(!((checkSize && findDoc !== undefined && size(errors[findDoc.id]) > 0) || size(errors) === 0));
	}, [errors, formulario]);

	const errorsDimensoes = useMemo(() => {
		const errorsDimensoes = {};
		if (metadata) {
			metadata.dimensoes.forEach(dimensao => {
				dimensao.questoes.forEach(questao => {
					questao.perguntas.forEach(pergunta => {
						if (errors[pergunta.id]) {
							errorsDimensoes[dimensao.id] = ['ERRO'];
						}
						pergunta.inputs.forEach(input => {
							if (errors[input.id]) {
								errorsDimensoes[dimensao.id] = ['ERRO'];
							}
						});
						const docsNecessarios = pergunta[`documentosProjeto${capitalize(enquadramento)}`] || [];
						docsNecessarios.forEach(doc => {
							if (errors[doc.idDocumento]) {
								errorsDimensoes[dimensao.id] = ['ERRO'];
							}
						});
					});
				});
			});
		}
		return errorsDimensoes;
	}, [enquadramento, errors, metadata]);

	const docEspecifico = useMemo(() => obtemCertificacaoSustentavelDocumento(formulario), [formulario]);

	const documentos = useMemo(() => formulario?.documentos, [formulario]);

	const ehNovo = useCallback(d => d.descricaoOutroDocumento && !d.idDocumentoSei, []);

	// proveniente de uma revisão (alteração de enquadramento, por exemplo)
	const ehRevisado = useCallback(d => d.complementadaEm && !d.idDocumentoSei, []);
	const ehInvalido = useCallback(
		d => {
			const substituto = ((formulario || {}).documentos || []).find(doc => doc.original === d.id);
			return d.invalido && !d.substituido && !(substituto || {}).idDocumentoSei;
		},
		[formulario]
	);
	const ehOpcionalPreenchido = useCallback(
		d => !d.obrigatorio && !d.descricaoOutroDocumento && !d.invalido && d.filename && !d.idDocumentoSei,
		[]
	);
	// const ehSubstituto = useCallback(
	// 	d => {
	// 		const original = ((formulario || {}).documentos || []).find(doc => doc.id === d.original);
	// 		return d.original && !d.idDocumentoSei && original && d.versao > original.versao;
	// 	},
	// 	[formulario]
	// );
	// const ehFromSolicitante = useCallback(d => !d.idDocumentoSei && d.fromSolicitante, []);

	const rascunhoDocsComplementacao = useMemo(
		() => !!get(formulario, 'formData.rascunhoDocsComplementacao'),
		[formulario]
	);

	const qtdInvalidos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehInvalido(d) ? 1 : 0), 0),
		[ehInvalido, formulario]
	);
	const qtdNovos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehNovo(d) || ehRevisado(d) ? 1 : 0), 0),
		[ehNovo, ehRevisado, formulario]
	);
	const qtdOpcionaisPreenchidos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehOpcionalPreenchido(d) ? 1 : 0), 0),
		[ehOpcionalPreenchido, formulario]
	);
	const qtdPerguntasNaoRespondidas = useMemo(
		() => (get(formulario, 'formData.extraInfo') || []).reduce((acc, info) => (acc += info.naoRespondida ? 1 : 0), 0),
		[formulario]
	);

	const emComplementacao = useMemo(
		() =>
			size(get(formulario, 'formData.resultado')) === 0 &&
			!rascunhoDocsComplementacao &&
			!!get(formulario, 'formData.dataComparecimento') &&
			!get(formulario, 'formData.expirado') &&
			// size(formulario.documentos) > 0 &&
			qtdInvalidos + qtdNovos + qtdPerguntasNaoRespondidas + qtdOpcionaisPreenchidos > 0,
		[
			formulario,
			qtdInvalidos,
			qtdNovos,
			qtdOpcionaisPreenchidos,
			qtdPerguntasNaoRespondidas,
			rascunhoDocsComplementacao
		]
	);

	const retiraDocumento = useCallback(payload => dispatch(actions.retiraDocumento(payload)), [dispatch]);

	// me da um código que use alguma api publica e verifique se uma determinada data é feriado no brasil

	const temAlgumDocumento = useCallback(
		(pergunta, temSlot = false) => {
			const docsNecessarios = pergunta[`documentosProjeto${capitalize(enquadramento)}`] || [];

			const temAlgum = docsNecessarios.reduce(
				(acc, d) =>
					acc ||
					(documentos || []).find(
						documento => documento.idDocumento === d.idDocumento && (!!documento.filename || temSlot)
					),
				false
			);

			return temAlgum;
		},
		[documentos, enquadramento]
	);

	const verificaPerguntasSemRespostaComDocumentos = (compararComOriginal = false, temSlot = false) => {
		const dadosOriginais = obtemCertificacaoSustentavelDados(formulario)?.dados;
		const perguntas = metadata.dimensoes
			.reduce((acc, d) => [...acc, ...d.questoes.reduce((acc2, q) => [...acc2, ...q.perguntas], [])], [])
			.filter(p =>
				compararComOriginal && size(dadosOriginais) > 0
					? dadosOriginais[p.id] === 'nao' && data[p.id] !== 'nao'
					: data[p.id] === 'nao'
			)
			.filter(p => temAlgumDocumento(p, temSlot));
		return perguntas;
	};

	const pontuacaoDimensao = useCallback((dimensao, data) => {
		const pontuacao = dimensao.questoes.reduce(
			(acc, q) =>
				acc +
				q.perguntas.reduce((acc2, p) => {
					if (data?.[p.id]) {
						const option = p.options.find(o => o.codigo === data[p.id]);
						return acc2 + (option?.pontuacao || 0);
					}
					return acc2;
				}, 0),
			0
		);
		return pontuacao;
	}, []);

	const pontuacaoCalculada = useMemo(
		() => metadata?.dimensoes.reduce((acc, d) => ({ ...acc, [d.id]: pontuacaoDimensao(d, data) }), {}),
		[data, metadata, pontuacaoDimensao]
	);

	const certificacaoAlmejada = useMemo(
		() => metadata?.dimensoes?.reduce((acc, d) => acc + (pontuacaoCalculada[d.id] >= d.pontuacaoMinima ? 1 : 0), 0),
		[metadata, pontuacaoCalculada]
	);

	const seloAlmejado = useMemo(
		() =>
			certificacaoAlmejada >= 5
				? 'Diamante'
				: certificacaoAlmejada >= 4
				? 'Ouro'
				: certificacaoAlmejada >= 3
				? 'Prata'
				: certificacaoAlmejada >= 2
				? 'Bronze'
				: null,
		[certificacaoAlmejada]
	);
	const salvar = useCallback(
		({ draft, errors, saveOnly, data: localData = data }) => {
			try {
				const execute = async data => {
					setLoading(true);
					const formId = get(formulario, 'formData.id');
					const url = `processo/${formId}/${EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}${
						draft ? '?draft=true' : ''
					}`;
					const newData = { ...data, certificacaoAlmejada, seloAlmejado };
					const objetoEspecifico = preparaObjetoParaTemplatePdf({
						metadata,
						data,
						certificacaoAlmejada,
						seloAlmejado,
						enquadramento,
						documentos: formulario.documentos,
						pontuacaoCalculada
					});
					const { data: dataResponse } = await sendApi(
						{ url, payload: { form: newData, observacoes: errors, objetoEspecifico } },
						false
					);
					return dataResponse;
				};
				execute(localData).then(dataResponse => {
					dispatch(
						actions.alteraStatusDocumento({
							documento: dataResponse.documento,
							documentoDados: dataResponse.documentoDados
						})
					);
					createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 5);
					setLoading(false);
					if (!saveOnly) {
						setShowFormulario(false);
					}
				});
			} catch (error) {
				createMessage('Problemas no salvamento do documento... verifique a console', 3);
				console.error('erro no servidor', error);
				setLoading(false);
			}
		},
		[
			certificacaoAlmejada,
			createMessage,
			data,
			dispatch,
			enquadramento,
			formulario,
			metadata,
			pontuacaoCalculada,
			seloAlmejado,
			setShowFormulario
		]
	);

	const cancelSubmit = () => {
		let perguntasComArquivos = {};
		setPerguntasComArquivosCancelar(null);
		// verifica as perguntas sem respostas com documentos ou slots criados
		const perguntas = verificaPerguntasSemRespostaComDocumentos(true, true);
		if (perguntas.length > 0) {
			perguntasComArquivos = perguntas.reduce((acc, p) => {
				acc[p.id] = p;
				return acc;
			}, {});
			setPerguntasComArquivosCancelar(perguntasComArquivos);
			setPosProcessFormulario(true);
		}
		setShowConfirm(true);
	};

	const submit = async operacao => {
		const { draft } = operacao;
		let errors = {};

		if (!forceSave) {
			errors = validacao({ metadata, data, documentos, enquadramento });
			const perguntas = verificaPerguntasSemRespostaComDocumentos();
			if (perguntas.length > 0) {
				errors = perguntas.reduce((acc, p) => {
					acc[p.id] = ['Há documentos associados a esta pergunta'];
					return acc;
				}, errors);
			}
		}

		if (forceSave && draft) {
			salvar({ draft, errors });
		} else {
			if (size(errors) > 0) {
				setErrors(errors);
				setForceSave(true);
			} else {
				setErrors({});
				salvar({ draft });
			}
		}
	};

	const excluiDocumentosAssociados = useCallback(
		(pergunta, newData, salvarRequerimento = false) => {
			const docsNecessarios = pergunta[`documentosProjeto${capitalize(enquadramento)}`] || [];
			const docsNecessariosIds = docsNecessarios.map(d => d.idDocumento);
			const docsExcluir = (documentos || []).filter(d => docsNecessariosIds.includes(d.idDocumento));
			const idsDocumentos = docsExcluir.map(d => d.id);
			retiraDocumento({ idFormData, idsDocumentos });
			if (salvarRequerimento) {
				salvar({ draft: true, saveOnly: true, data: newData });
			}
		},
		[documentos, enquadramento, idFormData, retiraDocumento, salvar]
	);

	const handleChanges = useCallback(
		(value, name, pergunta, confirmado) => {
			debugLog('name: ', name, 'value: ', value);

			let procede = true;

			if (data?.[name] !== value) {
				if (pergunta) {
					// quando for alteração de "pergunta" ou quando for confirmação de exclusão de documentos
					if (!confirmado) {
						// quando form alteração de pergunta
						if (value === 'nao') {
							const temdoc = temAlgumDocumento(pergunta, true);
							if (temdoc) {
								procede = false; // impede que o valor seja alterado
								setConfirmaExclusaoDocumentos({ value, name, pergunta });
							}
						}
					}
				}

				if (procede) {
					setForceSave(false);
					setData(oldState => {
						let newValue = { ...oldState, [name]: value };
						if (emComplementacao && !usuarioInterno) {
							if (value !== oldState[name]) {
								if (!newValue[`${name}AlteradaRt`]) {
									newValue[`${name}AlteradaRt`] = {
										de: oldState[name]
									};
								}
								newValue[`${name}AlteradaRt`].para = value;
							}
							if (value === newValue[`${name}AlteradaRt`]?.de) {
								delete newValue[`${name}AlteradaRt`];
							}
						}
						if (pergunta && confirmado) {
							excluiDocumentosAssociados(pergunta, newValue, true);
							setConfirmaExclusaoDocumentos(null);
						}
						return newValue;
					});
					if (usuarioInterno) {
						onChangeHandler(value, name);
					}
					setErrors(old => omit(old, [name]));
				}
			}
		},
		[data, emComplementacao, excluiDocumentosAssociados, onChangeHandler, temAlgumDocumento, usuarioInterno]
	);

	useEffect(() => {
		if (!showConfirm && !showFormulario && perguntasComArquivosCancelar && posProcessFormulario) {
			for (let p in perguntasComArquivosCancelar) {
				excluiDocumentosAssociados(perguntasComArquivosCancelar[p], null, false);
			}
			setPerguntasComArquivosCancelar(null);
			setPosProcessFormulario(false);
		}
	}, [
		excluiDocumentosAssociados,
		perguntasComArquivosCancelar,
		posProcessFormulario,
		setPosProcessFormulario,
		showConfirm,
		showFormulario
	]);

	const onDrop = useCallback(
		d => (accepted, rejected) => {
			if (size(rejected) === 0 && size(accepted) === 1) {
				const file = accepted[0];
				if (file.name.length > 100) {
					setErrors({ send: ['O nome do arquivo pode ter no máximo 100 caracteres.'] });
				} else if (accepted[0].size < 100000000) {
					setErrors(omit(errors, [d.idDocumento]));
					getUpdatedToken().then(
						token => {
							const payload = {
								idFormulario,
								idFormData,
								id: d.id,
								idDocumento: d.idDocumento,
								tituloDocumento: d.tituloDocumento,
								obrigatorio: d.obrigatorio,
								extensao: d.extensao,
								ordem: d.ordem,
								docGroup: d.docGroup,
								file,
								token,
								original: d.original,
								complementadaEm: docEspecifico?.complementadaEm ? docEspecifico.complementadaEm : d.complementadaEm,
								owner: d.owner,
								versao: d.versao,
								newDoc: true,
								fromSolicitante: d.fromSolicitante
							};
							dispatch(actions.uploadFile(payload));
						},
						error => {
							setErrors({ send: [error] });
						}
					);
				} else {
					setErrors({ send: ['O tamanho máximo de upload é de 100MB por arquivo.'] });
				}
			} else if (size(rejected) + size(accepted) > 1) {
				setErrors({ send: ['Apenas 1 arquivo pode ser submetido em cada documento'] });
			} else {
				setErrors({
					send: [`Tipo de arquivo inválido. Selecione um arquivo do tipo ${(d.extensao || '').toUpperCase()}`]
				});
			}
		},
		[dispatch, docEspecifico, errors, idFormData, idFormulario]
	);

	const criarMultiDocumentos = useCallback(
		doc => {
			debugLog('criarMultiNovoDocumento');
			const docPai = documentos.find(d => d.id === (doc.docGroup || doc.id));
			const dg = documentos.filter(d => d.docGroup === docPai.id);
			let ordem = (docPai.ordem || 100) + dg.length + 1;
			const newDoc = {
				textoHelp: null,
				ordem: ordem,
				extensao: 'pdf',
				obrigatorio: false,
				idDocumento: `${docPai.idDocumento}-${ordem % 100}`,
				id: uuid(),
				linkHelp: null,
				tituloDocumento: `${docPai.tituloDocumento}-${ordem % 100}`,
				versao: 1,
				docGroup: docPai.id,
				newDoc: true,
				fromSolicitante: true // Para verificação de doc que é criado pelo usuário
			};
			if (docEspecifico.complementadaEm) {
				newDoc.complementadaEm = docEspecifico.complementadaEm;
			}
			if (docEspecifico.owner) {
				newDoc.owner = docEspecifico.owner;
			}
			dispatch(actions.addNewDocument(newDoc));
		},
		[dispatch, docEspecifico, documentos]
	);

	const proximaOrdem = useMemo(() => {
		const maior = formulario?.documentos?.reduce((acc, o) => (o.ordem && o.ordem > acc ? o.ordem : acc), 0) || 0;
		const proxima = maior - (maior % 100) + 100;
		return proxima;
	}, [formulario]);

	const showBotaoAddMultiDocs = paiId => {
		if (!(documentos || []).find(d => d.id === paiId)?.filename) {
			return false; // não mostra se ainda não subiu um pai
		}

		const algumSlotSemDocumento = (documentos || []).filter(d => d.docGroup === paiId).find(d => !d.filename);
		if (algumSlotSemDocumento) {
			return false; // não mostra se ainda não adicionou documentos em todos os slots disponíveis
		}

		return true;
	};

	const temTodosDocumentos = useCallback(
		pergunta => {
			const docsNecessarios = pergunta[`documentosProjeto${capitalize(enquadramento)}`] || [];

			const temTodos = docsNecessarios.reduce(
				(acc, d) => acc && (documentos || []).find(documento => documento.idDocumento === d.idDocumento),
				true
			);

			return !temTodos;
		},
		[documentos, enquadramento]
	);

	const temAlteracaoRevisor = useCallback(
		dimensao =>
			dimensao.questoes.reduce(
				(acc, q) =>
					acc ||
					q.perguntas.reduce((acc2, p) => acc2 || data?.[`${p.id}AlteradaRevisor`], false) ||
					q.perguntas.reduce(
						(acc2, p) =>
							acc2 ||
							data?.[`${p.id}AlteradaRevisor`] ||
							p.inputs
								.filter(input => (usuarioInterno ? true : !input.id.startsWith('observacoes')))
								.reduce((acc3, i) => acc3 || data?.[`${i.id}AlteradaRevisor`], false),
						false
					),
				false
			),
		[data, usuarioInterno]
	);

	const temAlteracaoRt = useCallback(
		dimensao =>
			dimensao.questoes.reduce(
				(acc, q) =>
					acc ||
					q.perguntas.reduce(
						(acc2, p) =>
							acc2 ||
							data?.[`${p.id}AlteradaRt`] ||
							p.inputs.reduce((acc3, i) => acc3 || data?.[`${i.id}AlteradaRt`], false),
						false
					),
				false
			),
		[data]
	);

	const getMotivos = useCallback(
		docs =>
			docs
				.filter(d => d.complementadaEm)
				.reduce((acc, d) => {
					const original = docs.find(dAux => dAux.id === d.original);
					return {
						...acc,
						[d.id]: [
							{
								type: `${get(original, 'motivo') ? 'warning' : 'info'}`,
								message: `${
									get(original, 'motivo')
										? `Arquivo rejeitado pelo revisor: ${
												size(get(original, 'motivo')) > 0 ? get(original, 'motivo') : 'sem motivo'
										  }`
										: 'Novo arquivo complementado'
								}`,
								resolvido: d.filename
							}
						]
					};
				}, {}),
		[]
	);

	return (
		<>
			{loading && <Loader />}
			<div className="form-certificacao-sustentavel" ref={formRef}>
				<>
					{editedEnderecoFinalCs && editedEnderecoFinalCs ? (
						<>
							{metadata?.fieldsets?.map(fset => (
								<div className="nome-endereco-certificado" key={'nome-endereco-certificado'}>
									<h2 className="titulo-certificacao-almejada">{fset.legend}</h2>
									<div className="card-nome-endereco">
										<Inputs
											data={data}
											errors={errors}
											setErrors={setErrors}
											usuarioInterno={usuarioInterno}
											fields={fset.fields.map(i => ({ ...i, obrigatorio: i.obrigatorio || false }))}
											onChangeHandler={handleChanges}
											qtdMinLinhas={1}
											classNameContainer="col-md-6"
										/>
									</div>
								</div>
							))}
						</>
					) : (
						<Loader />
					)}
					<h2 className="titulo-certificacao-almejada" style={{ marginTop: '30px', marginBottom: '30px' }}>
						Dimensões
					</h2>
					{metadata?.dimensoes?.map(dimensao => {
						let dMenor = 1000;
						let dMaior = 0;

						dimensao.questoes.forEach(q =>
							q.perguntas.forEach(p => {
								if (p.numero > dMaior) {
									dMaior = p.numero;
								}
								if (p.numero < dMenor) {
									dMenor = p.numero;
								}
							})
						);

						const calculado = pontuacaoCalculada[dimensao.id];
						const classeObservacoes =
							calculado === 0 ? '' : calculado < dimensao.pontuacaoMinima ? ' nao-atingida' : ' atingida';
						return (
							<div key={dimensao.id} className="card">
								<div
									className="card-header"
									onClick={e => {
										setDimensaoAberta(dimensaoAberta === dimensao.id ? null : dimensao.id);
										e.preventDefault();
										e.stopPropagation();
									}}
								>
									<span>
										<div className="cs-dimensao">{dimensao.dimensao}</div>
										{dimensao.title} ({dMenor}-{dMaior}){' '}
										{size(errorsDimensoes?.[dimensao.id]) > 0 && (
											<span style={{ fontWeight: '700', color: 'red' }} title="Há erros em questões desta dimensão">
												(*)
											</span>
										)}
										{!usuarioInterno && temAlteracaoRevisor(dimensao) && (
											<span
												style={{ fontWeight: '700', color: '#fa970f' }}
												title="Há questões alteradas pelo revisor nesta dimensão"
											>
												(*)
											</span>
										)}
										{usuarioInterno && temAlteracaoRt(dimensao) && (
											<span
												style={{ fontWeight: '700', color: '#fa970f' }}
												title="Há questões alteradas pelo RT nesta dimensao"
											>
												(*)
											</span>
										)}
									</span>
									<span className="observacoes">Pontuação mínima: {dimensao.pontuacaoMinima}</span>
									<span className={`observacoes${classeObservacoes}`}>Pontuação alcançada: {calculado}</span>
								</div>
								{dimensaoAberta === dimensao.id && (
									<div className="card-body">
										{dimensao.questoes?.map(questao => (
											<fieldset key={questao.id}>
												{dimensao.questoes.length > 1 && <legend>{questao.subtitle}</legend>}
												{questao.perguntas.map(pergunta => {
													const disabled = usuarioInterno && temTodosDocumentos(pergunta);
													const options = pergunta.options.map(option => ({
														...option,
														descricao: `${option.descricao} (${option.pontuacao} pontos)`
													}));

													const docProperty = `documentosProjeto${capitalize(enquadramento)}`;
													const idsDocumentos = (pergunta[docProperty] || []).reduce(
														(acc, d) => [...acc, d.idDocumento],
														[]
													);
													let docs = documentos.filter(d => idsDocumentos.includes(d.idDocumento));
													const motivos = getMotivos(docs);
													const docGroups = docs.reduce((acc, d) => [...acc, d.id], []);
													docs = [...docs, ...documentos.filter(d => docGroups.includes(d.docGroup))];
													docs = docs.sort((d1, d2) => (d1.ordem > d2.ordem ? 1 : -1));

													idsDocumentos.forEach((id, i) => {
														if (!docs.find(dAux => dAux.idDocumento === id)) {
															const doc = pergunta[docProperty][i];
															const newDoc = Object.assign(
																{ id: uuid(), versao: 1, ordem: proximaOrdem, extensao: 'pdf' },
																doc
															);
															docs = [...docs, newDoc];
														}
													});

													const maioresOrdens = extrairMaioresOrdens(docs);

													return (
														<div key={pergunta.id} className="pergunta">
															<Pergunta
																superLabel={pergunta.superLabel}
																numero={`${parseInt(pergunta.numero)}`}
																label={`${`${pergunta.label}`}${isDebug ? ` (${pergunta.id})` : ''}`}
																observacoes={pergunta.observacoes}
																required={true}
																name={pergunta.id}
																value={data?.[pergunta.id]}
																valueAlteradaRevisor={usuarioInterno ? null : data?.[`${pergunta.id}AlteradaRevisor`]}
																valueAlteradaRt={usuarioInterno ? data?.[`${pergunta.id}AlteradaRt`] : null}
																readOnly={false}
																disabled={disabled}
																options={options}
																errors={errors}
																onChangeHandler={(value, name) => handleChanges(value, name, pergunta, false)}
																radioStack={!!pergunta.stackedOptions}
																warning={
																	disabled
																		? 'Desabilitado por que documentos obrigatórios ainda não foram fornecidos'
																		: null
																}
															/>
															{data?.[pergunta.id] &&
																data[pergunta.id] !== 'nao' &&
																enquadramento &&
																size(pergunta.inputs) > 0 && (
																	<div key={'perguntas-inputs'} className="form-group">
																		<Inputs
																			data={data}
																			errors={errors}
																			setErrors={setErrors}
																			classNameContainer={'pergunta-input'}
																			usuarioInterno={usuarioInterno}
																			fields={pergunta.inputs.map(i => ({ ...i, obrigatorio: i.obrigatorio || false }))}
																			onChangeHandler={handleChanges}
																		/>
																	</div>
																)}
															{!usuarioInterno &&
																data?.[pergunta.id] &&
																data[pergunta.id] !== 'nao' &&
																enquadramento &&
																docs
																	.filter(d => !emComplementacao || (!d.invalido && !d.substituido))
																	.map((doc, i) => {
																		const docMaiorOrdem = maioresOrdens[doc.docGroup || doc.id] === doc.ordem;

																		const emProcessamento = !doc.idDocumentoSei && !!doc.originalName;
																		const arquivoGerado =
																			!!doc.filename ||
																			formulario.documentos.find(d => d.id === doc.id && d.filename) ||
																			size(get(formulario, 'formData.resultado')) > 0;
																		return (
																			<div key={doc.id} className="form-group col-md-12">
																				<label htmlFor={`inputFile${doc.idDocumento}`}>
																					<span style={{ display: 'flex', justifyContent: 'space-between' }}>
																						<span>
																							{doc.tituloDocumento || doc.tituloDocumentoAdmin}
																							{doc.obrigatorio ? <span className="required">*</span> : ''}
																							{doc.versao && doc.versao !== 1 ? ` (v. ${doc.versao})` : ''}
																						</span>
																						<div style={{ display: 'flex', alignItems: 'center' }}>
																							{isDebug && (
																								<span className="debug-message">
																									({doc.idDocumento} - {doc.ordem})
																								</span>
																							)}
																						</div>
																					</span>
																					{doc.descricaoDocumento && (
																						<span style={{ fontSize: '1.0rem', color: 'grey' }}>
																							{doc.descricaoDocumento}
																						</span>
																					)}
																				</label>

																				<DocumentoUpload
																					key={doc.idDocumento + i}
																					formData={formulario.formData}
																					documento={doc}
																					emProcessamento={emProcessamento}
																					arquivoGerado={arquivoGerado}
																					onDrop={onDrop}
																					removeFile={docMaiorOrdem ? removeFile : null}
																					retiraDocumento={retiraDocumento}
																					procedimentoFormatadoSei={formulario.formData.procedimentoFormatadoSei}
																					usuarioDonoTask={true}
																					emComplementacao={emComplementacao}
																					naoPodeRemoverSlot={!doc.fromSolicitante}
																				/>

																				{showBotaoAddMultiDocs(doc.docPai || doc.id) && docMaiorOrdem && (
																					<button
																						hidden={false}
																						type="button"
																						className="btn btn-link"
																						onClick={() => criarMultiDocumentos(doc)}
																					>
																						<i className="fa fa-plus" style={{ fontSize: '18px' }} />
																						{`  Adicionar outro documento de ${
																							doc.tituloDocumento || doc.tituloDocumentoAdmi
																						}`}
																					</button>
																				)}
																				{size(motivos?.[doc.id]) > 0 && <ErrorMessages errorList={motivos[doc.id]} />}
																				{size(errors?.[doc.id]) > 0 && <ErrorMessages errorList={errors[doc.id]} />}
																				{size(errors?.[doc.idDocumento]) > 0 && (
																					<ErrorMessages errorList={errors[doc.idDocumento]} />
																				)}
																			</div>
																		);
																	})}
														</div>
													);
												})}
											</fieldset>
										))}
									</div>
								)}
							</div>
						);
					})}
					{seloAlmejado ? (
						<>
							<h2 className="titulo-certificacao-almejada">
								Classificação almejada: {seloAlmejado}
								<span> ({extenso(certificacaoAlmejada, { number: { gender: 'f' } })} dimensões)</span>
							</h2>
							{certificacaoAlmejada === 2 && <img src={termometro2} alt="termometro2" />}
							{certificacaoAlmejada === 3 && <img src={termometro3} alt="termometro3" />}
							{certificacaoAlmejada === 4 && <img src={termometro4} alt="termometro4" />}
							{certificacaoAlmejada === 5 && <img src={termometro5} alt="termometro5" />}
							{certificacaoAlmejada === 6 && <img src={termometro6} alt="termometro6" />}
							{certificacaoAlmejada === 7 && <img src={termometro7} alt="termometro7" />}
						</>
					) : (
						<>
							<h2 className="titulo-certificacao-almejada">
								Você precisa atingir a pontuação mínima em ao menos duas dimensões para requerer uma certificação.
							</h2>
							{certificacaoAlmejada === 0 && <img src={termometro0} alt="termometro0" />}
							{certificacaoAlmejada === 1 && <img src={termometro1} alt="termometro1" />}
						</>
					)}
					{!usuarioInterno && (
						<div className="cjto-botoes">
							<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => cancelSubmit()}>
								Cancelar
							</button>
							<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => submit({ draft: true })}>
								{forceSave ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
							</button>
							{seloAlmejado && (
								<button
									type="button"
									className="btn btn-primary mt-3"
									onClick={() => submit({ draft: false })}
									disabled={hasErrors}
								>
									Salvar e gerar PDF
								</button>
							)}
						</div>
					)}
					{hasErrors && (
						<div className="vistoria-card erros">
							<h3 className="font-weight-bold text-danger m-3">(*) Existem erros no formulário</h3>
							{<ErrorMessages errorList={flatMap(errors, v => v)} />}
						</div>
					)}
				</>
			</div>
			{confirmaExclusaoDocumentos && (
				<Confirm
					msg="Confirma exclusão de todos os documentos associados a esta pergunta?"
					onConfirm={() =>
						handleChanges(
							confirmaExclusaoDocumentos.value,
							confirmaExclusaoDocumentos.name,
							confirmaExclusaoDocumentos.pergunta,
							true
						)
					}
					loader={false}
					onReject={() => setConfirmaExclusaoDocumentos(null)}
				/>
			)}
		</>
	);
}
DocumentoCertificacaoSustentavel.displayName = 'DocumentoCertificacaoSustentavel';
DocumentoCertificacaoSustentavel.propTypes = {
	setShowFormulario: PropTypes.func,
	showFormulario: PropTypes.bool,
	setShowConfirm: PropTypes.func,
	showConfirm: PropTypes.bool,
	setPosProcessFormulario: PropTypes.func,
	posProcessFormulario: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	removeFile: PropTypes.func
};
export default DocumentoCertificacaoSustentavel;

export function obtemCertificacaoSustentavelDocumento(formulario) {
	const { documentos } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	return documento;
}

export function obtemCertificacaoSustentavelDados(formulario) {
	const { documentosDados } = formulario || {};
	const documento = obtemCertificacaoSustentavelDocumento(formulario);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento.original);
	return docDados;
}

function extrairMaioresOrdens(documentos) {
	// ordenar documentos em ordem crescente com base no campo ordem
	documentos.sort((a, b) => a.ordem - b.ordem);

	// objeto para armazenar a maior ordem de cada grupo
	const maioresOrdens = {};

	// percorrer documentos em ordem crescente
	for (const documento of documentos) {
		if (!documento.docGroup) {
			// documento não tem docGroup, criar nova entrada no objeto
			maioresOrdens[documento.id] = documento.ordem;
		} else {
			// documento tem docGroup, verificar se já existe uma entrada no objeto para o docGroup
			if (maioresOrdens[documento.docGroup]) {
				// comparar campo ordem do documento com valor armazenado na entrada do docGroup
				const maiorOrdem = maioresOrdens[documento.docGroup];
				if (documento.ordem > maiorOrdem) {
					// atualizar valor armazenado na entrada do docGroup
					maioresOrdens[documento.docGroup] = documento.ordem;
				}
			} else {
				// ainda não há uma entrada no objeto para o docGroup, criar uma nova
				maioresOrdens[documento.docGroup] = documento.ordem;
			}
		}
	}

	return maioresOrdens;
}

/**
 * Este método parte do estado inicial do objeto de metadata e adiciona respostas e documentos às perguntas
 * além de adicionar certificação e selo almejados pelo solicitante. Este objeto é enviado ao backend para
 * criação do PDF que será anexado como documento específico a este requerimento
 */
function preparaObjetoParaTemplatePdf({
	metadata,
	data = {},
	certificacaoAlmejada,
	seloAlmejado,
	enquadramento,
	documentos,
	pontuacaoCalculada
}) {
	let saida = {
		dimensoes: metadata.dimensoes,
		certificacaoAlmejada,
		seloAlmejado,
		tituloTermometro: `Certificação almejada: ${seloAlmejado}`,
		termometroImg:
			certificacaoAlmejada &&
			`http://licenciamento-api{{ambiente}}.procempa.com.br/api/minio/get/imagens/termometro_${certificacaoAlmejada}.png`,
		seloImg:
			seloAlmejado &&
			`http://licenciamento-api{{ambiente}}.procempa.com.br/api/minio/get/imagens/${seloAlmejado.toLowerCase()}.png`
	};
	const docProperty = `documentosProjeto${capitalize(enquadramento)}`;
	saida.dimensoes.forEach(dimensao => {
		dimensao.pontuacaoCalculada = pontuacaoCalculada[dimensao.id];
		dimensao.questoes.forEach(questao => {
			questao.perguntas.forEach(pergunta => {
				pergunta.valuePuro = data[pergunta.id];
				pergunta.value = pergunta.options.find(o => o.codigo === (data[pergunta.id] || 'nao'));
				if (data[pergunta.id] !== 'nao') {
					pergunta.documentos = [];
					pergunta[docProperty].forEach(doc => {
						const docPai = documentos.find(d => d.idDocumento === doc.idDocumento);
						if (docPai) {
							pergunta.documentos.push(docPai);
							const docsFilhos = documentos.filter(d => d.docGroup === docPai.id && !!d.filename);
							if (size(docsFilhos) > 0) {
								pergunta.documentos.push(...docsFilhos);
							}
						}
					});
					pergunta.documentos = pergunta.documentos.map(d => ({ ...d, sizeString: sizeString(d.size || 0) }));
					pergunta.inputs = (pergunta.inputs || []).reduce((acc, input) => {
						if (!data[input.id]) {
							return acc;
						}
						const inputAux = {
							...input,
							textoGrande: ['number', 'area'].includes(input.type) ? false : size(data[input.id]) > 80,
							value: ['number', 'area'].includes(input.type)
								? formatNumber(data[input.id].value, input.type === 'area' ? 2 : 0)
								: data[input.id]
						};
						return [...acc, inputAux];
					}, []);
				}
			});
		});
	});

	// remove perguntas não respondidas ou respondidas com nao
	saida.dimensoes = saida.dimensoes.map(d => ({
		...d,
		questoes: d.questoes.map(q => ({
			...q,
			perguntas: q.perguntas.filter(p => p.value.codigo !== 'nao')
		}))
	}));

	// remove questões que não tem mais perguntas
	saida.dimensoes = saida.dimensoes.map(d => ({ ...d, questoes: d.questoes.filter(q => size(q.perguntas) > 0) }));

	// remove dimensoes que não tem mais questões
	saida.dimensoes = saida.dimensoes.filter(d => size(d.questoes) > 0);

	// remove dimensões que não atingiram a pontuação mínima
	saida.dimensoes = saida.dimensoes.filter(d => d.pontuacaoCalculada >= d.pontuacaoMinima);

	return saida;
}

function sizeString(size) {
	let unidade = 'KB';
	let sizeEmKb = size / 1000;
	if (sizeEmKb >= 1000) {
		sizeEmKb = sizeEmKb / 1000;
		unidade = 'MB';
	}
	const str = sizeEmKb.toLocaleString('pt-br', {
		style: 'decimal',
		minimumIntegerDigits: 1,
		useGrouping: true,
		maximumFractionDigits: 2
	});
	return `${str}${unidade}`;
}

function formatNumber(string, decimais = 0) {
	string = `${string}`;
	string = string || '0';
	string = string.replace(/,/g, '.');
	let numberValue = parseFloat(string);

	const formattedNumber = new Intl.NumberFormat('pt-BR', {
		style: 'decimal',
		minimumFractionDigits: decimais,
		maximumFractionDigits: decimais,
		useGrouping: 'always'
	}).format(numberValue);

	return formattedNumber;
}
