import React, { useState, Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { getValueCutDigits } from 'utils/tools';

import { NOVA_AREA_UDRI } from './PlanilhaIndicesConstrutivos';
import PlanilhaIndicesConstrutivosArea from './PlanilhaIndicesConstrutivosArea';

function PlanilhaIndicesConstrutivosLinha({
	label,
	name,
	value,
	decimals,
	onChangeHandler,
	readOnly,
	locked,
	lockedMessage,
	errors
}) {
	const [showObs, setShowObs] = useState(() =>
		(value?.areas || [{}]).filter(a => !(readOnly || locked) || a.informada).map(a => (size(a.obs) > 0 ? true : false))
	);

	const handleChange = (ind, property) => valorDigitado => {
		const informada = property === 'informada' ? parseFloat(valorDigitado) : value.areas[ind].informada;
		const obs = property === 'obs' ? valorDigitado : value.areas[ind].obs;
		const newAreas = (value?.areas || []).map((vAux, i) => (i === ind ? { informada, obs } : vAux));
		const subtotalAreaCalculada = newAreas.reduce((acc, area) => acc + (area.informada || 0), 0);
		const newValue = {
			...value,
			areas: newAreas,
			subtotalAreaCalculada: getValueCutDigits(subtotalAreaCalculada, 4)
		};
		onChangeHandler(newValue, name);
	};

	const removeLine = useCallback(
		ind => {
			const newAreas = value.areas.filter((o, i) => i !== ind);
			const newShowObs = showObs.filter((o, i) => i !== ind);
			const subtotalAreaCalculada = newAreas.reduce((acc, area) => acc + (area.informada || 0), 0);
			const newValue = { ...value, areas: newAreas, subtotalAreaCalculada };
			setShowObs(newShowObs);
			onChangeHandler(newValue, name);
		},
		[name, onChangeHandler, showObs, value]
	);

	return (
		<>
			{(value?.areas || [])
				.filter(a => !readOnly || a.informada)
				.map((val, ind) => (
					<Fragment key={ind}>
						{ind === 0 && (
							<span
								className="planilha-indices-construtivos-titulos"
								style={{
									gridRow: `span ${
										value.areas.filter(a => !readOnly || a.informada).length +
										showObs.reduce((acc, o) => acc + (o ? 1 : 0), 0)
									}`,
									marginTop: size(label) < 30 ? '14px' : '6px'
								}}
							>
								{label}
							</span>
						)}

						<PlanilhaIndicesConstrutivosArea
							value={val?.informada}
							decimals={decimals}
							onChangeHandler={handleChange(ind, 'informada')}
							readOnly={readOnly || locked}
							warning={lockedMessage}
						/>
						{locked ? (
							<span>&nbsp;</span>
						) : (
							!readOnly && (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{ind === 0 ? ( // value.areas.length - 1
										<button
											type="button"
											className="btn-add-linha"
											onClick={() => {
												setShowObs(old => [...old, false]);
												onChangeHandler({ ...value, areas: [...value.areas, NOVA_AREA_UDRI] }, name);
											}}
										>
											<i className="fa fa-plus" />
											<span>linha</span>
										</button>
									) : (
										<button type="button" className="btn-add-linha" onClick={() => removeLine(ind)}>
											<i className="fa fa-minus" />
											<span>linha</span>
										</button>
									)}
									<button
										type="button"
										className="btn-add-linha"
										style={{
											// color: size(val?.obs) > 0 ? 'rgb(131, 12, 12)' : '#687c80',
											cursor: 'pointer'
										}}
										onClick={() => setShowObs(old => old.map((o, i) => (i === ind ? !o : o)))}
									>
										{size(val?.obs) > 0 ? <strong>observação</strong> : <span>observação</span>}
									</button>{' '}
								</div>
							)
						)}
						{/* segunda linha */}
						{showObs[ind] && (
							<>
								{/* <span className="ap1800-planilha-titulos"></span> */}
								<div
									style={{
										display: 'flex',
										gap: '10px',
										alignItems: 'baseline',
										gridColumn: '1 / span 2',
										textAlign: 'right',
										marginTop: '-20px',
										borderBottom: '1px solid #dedede',
										paddingBottom: '5px'
									}}
								>
									<b style={{ marginLeft: '10px', fontSize: '14px' }}>Observações:</b>
									<input
										className="form-control"
										placeholder={'Observações'}
										type="text"
										value={val?.obs || ''}
										onChange={e => handleChange(ind, 'obs')(e.target.value)}
										readOnly={readOnly || locked}
										disabled={readOnly}
									/>
								</div>

								{!readOnly && <span className="ap1800-planilha-titulos" />}
							</>
						)}
					</Fragment>
				))}
			{size(value?.areas.filter(a => a.informada)) > 1 && (
				<>
					<span className="ap1800-planilha-titulos">
						<strong>subtotal:</strong>
					</span>
					<PlanilhaIndicesConstrutivosArea
						value={value.subtotalAreaCalculada}
						readOnly={true}
						auto={true}
						decimals={decimals}
					/>
					{!readOnly && <span className="ap1800-planilha-titulos">&nbsp;</span>}
				</>
			)}
			{size(errors) > 0 && (
				<div style={{ gridColumn: `1 / span ${readOnly ? '2' : '3'}` }}>
					<ErrorMessages errorList={errors} />
				</div>
			)}
		</>
	);
}
PlanilhaIndicesConstrutivosLinha.displayName = 'PlanilhaIndicesConstrutivosLinha';
PlanilhaIndicesConstrutivosLinha.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	decimals: PropTypes.number,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	locked: PropTypes.bool,
	lockedMessage: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
};

export default PlanilhaIndicesConstrutivosLinha;
