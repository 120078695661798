import styled from 'styled-components';

const Economia = styled.div`
	--backcolor: #f5f5f5;
	--frontcolor: #333;
	--cellheight: 40px;
	--cellwidth: 100px;
	--border-color: #dfe2e6;
	--shadow-color: #b5b5b5;
	--backcolor-destaque: #919191;

	margin: 20px 0px;
	display: flex;
	flex-direction: column;
	border-bottom: 4px solid var(--border-color);

	input.area {
		width: var(--cellwidth);
		padding-right: 5px;
		padding-left: 5px;
		text-align: right;
		border-bottom: 1px solid var(--border-color);
		border-right: 1px solid var(--border-color);
		border-radius: 0;
		border-top: 0;
		border-left: 0;
		background-color: #fff !important;
	}

	.title {
		display: flex;
		justify-content: center;
		flex-grow: 1;

		background-color: var(--backcolor-destaque);
		box-shadow: 0 3px 6px var(--shadow-color);
		z-index: 999;

		position: sticky;
		left: 0;

		padding: 10px;
		color: var(--backcolor);
		font-size: 1.1em;
		font-weight: 700;

		p {
			margin-bottom: 0;
		}

		.fa {
			&:hover {
				opacity: 0.45 !important;
			}
		}
	}

	h1 {
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 20px;
	}

	.corpo {
		overflow-x: auto;
	}

	.header {
		display: flex;
		background-color: var(--backcolor);

		.void {
			display: flex;
			align-items: center;
			justify-content: center;
			position: sticky;
			left: 0;
			/* height: var(--cellheight); */
			min-width: 222px;
			border-right: 1px solid var(--border-color);
			border-left: 1px solid var(--border-color);
			border-bottom: 1px solid var(--border-color);
			background-color: var(--backcolor);
			font-weight: 700;
		}
		.areas {
			display: flex;
			width: fit-content;
			flex-grow: 1;

			.area {
				/* display: flex; */
				display: block;
				align-items: center;
				justify-content: flex-end;
				width: var(--cellwidth);
				padding: 10px;
				background-color: var(--backcolor);
				border-right: 1px solid var(--border-color);
				border-bottom: 1px solid var(--border-color);

				button.btn-link {
					padding: 2px 5px;
					width: 24px;
					float: left;
					margin-right: 2px;
					margin-left: 0;
					text-align: center;
					height: 24px;

					&:hover {
						text-decoration: none;
						background-color: #ddd;
						border-radius: 2px;
					}

					&:focus {
						border-bottom: 0 !important;
						text-decoration: none;
					}
				}

				.fa-pencil-square-o {
					line-height: 21px;
				}

				.fa-trash-o {
					color: #ce4a4a;
				}

				p {
					margin-bottom: 0;
				}
			}
		}

		.total-areas {
			position: sticky;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			/* height: var(--cellheight); */
			background-color: var(--backcolor);
			/* min-width: var(--cellwidth) + 10px; */
			min-width: 100px;
			border-right: 1px solid var(--border-color);
			box-shadow: 0 7px 10px var(--shadow-color);
			font-weight: 700;
		}
	}

	.container-tipo-construcao {
		display: flex;

		.tiposConstrucao {
			display: flex;
			flex-grow: 1;
			.tipo-construcao {
				writing-mode: vertical-rl;
				transform: rotate(180deg);
				border-right: 1px solid var(--border-color);
				border-left: 1px solid var(--border-color);
				line-height: 3rem;
				text-align: center;
				border-top: 1px solid var(--border-color);
				background-color: var(--backcolor);
				position: sticky;
				left: 0;
				width: 50px;
				min-width: 50px;
			}
			.resto-tipo-construcao {
				flex-grow: 1;

				.grupos {
					display: flex;
					flex-direction: column;
					.grupo {
						display: flex;
						background-color: var(--backcolor);

						.titulo-grupo {
							writing-mode: vertical-rl;
							transform: rotate(180deg);
							border-left: 1px solid var(--border-color);
							line-height: 3rem;
							border-top: 1px solid var(--border-color);
							text-align: center;
							background-color: var(--backcolor);
							position: sticky;
							left: 50px;
							width: 50px;
							min-width: 50px;
						}

						.resto-grupo {
							flex-grow: 1;
							.tipos-areas {
								display: grid;
								grid-template-rows: 1fr 1fr 1fr;

								.area {
									display: flex;
									background-color: var(--backcolor);

									.titulo-area {
										display: flex;
										align-items: center;
										justify-content: center;
										white-space: nowrap;
										width: 120px;
										height: var(--cellheight);
										width: 120px;
										padding: 0px 10px;
										border-right: 1px solid var(--border-color);
										border-bottom: 1px solid var(--border-color);
										border-left: 0;
										border-top: 0;
										background-color: var(--backcolor);
										position: sticky;
										left: 100px;
										width: 122px;
									}
									.areas {
										display: flex;
										width: fit-content;
										flex-grow: 1;
									}

									.total-areas {
										position: sticky;
										right: 0;
										display: flex;
										flex-grow: 1;
										height: var(--cellheight) + 1px;
										background-color: var(--backcolor);
										min-width: var(--cellwidth);
										max-width: var(--cellwidth) + 10px;
										box-shadow: 0 7px 10px var(--shadow-color);
										font-weight: 700;
										border-right: 1px solid var(--border-color);
										max-width: 100px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.footer {
		display: flex;
		/* background-color: var(--backcolor); */

		.void {
			display: flex;
			align-items: center;
			justify-content: center;
			position: sticky;
			left: 0;
			height: var(--cellheight);
			min-width: 222px;
			border-right: 1px solid var(--border-color);
			border-left: 1px solid var(--border-color);
			border-bottom: 1px solid var(--border-color);
			background-color: var(--backcolor);
			font-weight: 700;
		}
		.areas {
			display: flex;
			width: fit-content;
			/* flex-grow: 1; */
			background-color: var(----backcolor);

			.area {
				display: flex;
			}
		}

		.total-areas {
			position: sticky;
			right: 0;
			display: flex;
			flex-grow: 1;
			height: var(--cellheight) + 1px;
			background-color: var(--backcolor);
			justify-content: flex-end;
			box-shadow: 0 7px 10px var(--shadow-color);
			font-weight: 700;
			/* min-width: var(--cellwidth); */
		}
	}
`;

export { Economia };
