import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import useAxios from 'axios-hooks';
import { cloneDeep, get, omit, size } from 'lodash';

import Confirm from 'components/Confirm';
import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA } from 'containers/Form/Detalhe';

import useErrors from 'custom-hooks/useErrors';
import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { API_URL } from 'environments';

import { sendApi } from 'utils/injectApi';
import { isDebug, montaURL, formatCodigoEU } from 'utils/tools';

import Inputs from './Inputs';
import { validacao } from './Validacao';

import './index.scss';

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(ADESAO-PRCHPA-DOC-ESPECIFICO)', ++i, ...args);

function DocumentoAdesaoPrchpa({
	setShowFormulario,
	showFormulario,
	setShowConfirm,
	showConfirm,
	setPosProcessFormulario,
	posProcessFormulario,
	usuarioInterno: usuarioAdmin,
	onChangeHandler,
	removeFile
}) {
	// #region hooks
	/* REDUX */
	const dispatch = useDispatch();

	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errors, setErrors] = useErrors();
	const { createMessage } = useMessages();

	/* ESTADOS */
	const usuarioPortal = useMemo(() => !usuarioAdmin, [usuarioAdmin]);
	const [data, setData] = useState();
	const [restricoesMarcadasComSlotsCancelar, setRestricoesMarcadasComSlotsCancelar] = useState(null);
	const [loading, setLoading] = useState(false);
	const { id: idFormData } = useParams();
	const [confirmaExclusaoDocumentos, setConfirmaExclusaoDocumentos] = useState(null);
	const [forceSave, setForceSave] = useState(false);

	/* QUERIES */
	const [{ data: metadata }] = useAxios(`${API_URL}/collections/metadata/${EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA}`);
	// #endregion

	// atualiza data se mudar o formulário
	useEffect(() => {
		if (metadata && !data) {
			const dd = obtemAdesaoPrchpaDados(formulario);
			if (dd) {
				setData(dd.dados);
			} else {
				setData({});
			}
		}
	}, [data, formulario, metadata]);

	const documentos = useMemo(() => formulario?.documentos, [formulario]);

	const ehNovo = useCallback(d => d.descricaoOutroDocumento && !d.idDocumentoSei, []);

	// proveniente de uma revisão (alteração de enquadramento, por exemplo)
	const ehRevisado = useCallback(d => d.complementadaEm && !d.idDocumentoSei, []);
	const ehInvalido = useCallback(
		d => {
			const substituto = ((formulario || {}).documentos || []).find(doc => doc.original === d.id);
			return d.invalido && !d.substituido && !(substituto || {}).idDocumentoSei;
		},
		[formulario]
	);
	const ehOpcionalPreenchido = useCallback(
		d => !d.obrigatorio && !d.descricaoOutroDocumento && !d.invalido && d.filename && !d.idDocumentoSei,
		[]
	);
	// const ehSubstituto = useCallback(
	// 	d => {
	// 		const original = ((formulario || {}).documentos || []).find(doc => doc.id === d.original);
	// 		return d.original && !d.idDocumentoSei && original && d.versao > original.versao;
	// 	},
	// 	[formulario]
	// );
	// const ehFromSolicitante = useCallback(d => !d.idDocumentoSei && d.fromSolicitante, []);

	const rascunhoDocsComplementacao = useMemo(
		() => !!get(formulario, 'formData.rascunhoDocsComplementacao'),
		[formulario]
	);

	const qtdInvalidos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehInvalido(d) ? 1 : 0), 0),
		[ehInvalido, formulario]
	);
	const qtdNovos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehNovo(d) || ehRevisado(d) ? 1 : 0), 0),
		[ehNovo, ehRevisado, formulario]
	);
	const qtdOpcionaisPreenchidos = useMemo(
		() => ((formulario || {}).documentos || []).reduce((acc, d) => (acc += ehOpcionalPreenchido(d) ? 1 : 0), 0),
		[ehOpcionalPreenchido, formulario]
	);
	const qtdPerguntasNaoRespondidas = useMemo(
		() => (get(formulario, 'formData.extraInfo') || []).reduce((acc, info) => (acc += info.naoRespondida ? 1 : 0), 0),
		[formulario]
	);

	const emComplementacao = useMemo(
		() =>
			size(get(formulario, 'formData.resultado')) === 0 &&
			!rascunhoDocsComplementacao &&
			!!get(formulario, 'formData.dataComparecimento') &&
			!get(formulario, 'formData.expirado') &&
			// size(formulario.documentos) > 0 &&
			qtdInvalidos + qtdNovos + qtdPerguntasNaoRespondidas + qtdOpcionaisPreenchidos > 0,
		[
			formulario,
			qtdInvalidos,
			qtdNovos,
			qtdOpcionaisPreenchidos,
			qtdPerguntasNaoRespondidas,
			rascunhoDocsComplementacao
		]
	);

	const salvar = useCallback(
		({ draft, errors, saveOnly, data: localData = data }) => {
			try {
				const salvaDocumentoEspecifico = async data => {
					setLoading(true);
					const formId = get(formulario, 'formData.id');
					const url = `processo/${formId}/${EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA}${draft ? '?draft=true' : ''}`;
					const newData = { ...data };
					const objetoEspecifico = preparaObjetoParaTemplatePdf({
						metadata,
						data,
						documentos: formulario.documentos
					});
					const { data: dataResponse } = await sendApi(
						{ url, payload: { form: newData, observacoes: errors, objetoEspecifico } },
						false
					);
					return dataResponse;
				};
				salvaDocumentoEspecifico(localData)
					.then(dataResponse => {
						if (get(dataResponse, 'ok') === 99) {
							createMessage(`Problemas no salvamento do documento: ${dataResponse.message}`, 5);
						} else {
							dispatch(
								actions.alteraStatusDocumento({
									documento: dataResponse.documento,
									documentoDados: dataResponse.documentoDados
								})
							);
							createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 5);
						}
					})
					.catch(err => {
						console.error('erro pego no catch do execute');
						createMessage('Problemas no salvamento do documento 1... verifique a console', 3);
						console.error('erro no servidor', err);
					})
					.finally(() => {
						setLoading(false);
						if (!saveOnly) {
							setShowFormulario(false);
						}
					});
			} catch (error) {
				createMessage('Problemas no salvamento do documento 2... verifique a console', 3);
				console.error('erro no servidor', error);
				setLoading(false);
				if (!saveOnly) {
					setShowFormulario(false);
				}
			}
		},
		[createMessage, data, dispatch, formulario, metadata, setShowFormulario]
	);

	const temAlgumSlot = useCallback(
		({ field }) => {
			const docsMetadata = field?.documentos || [];
			const temAlgum = docsMetadata.reduce(
				(acc, d) => acc || (documentos || []).find(documento => documento.idDocumento === d.idDocumento),
				false
			);
			return temAlgum;
		},
		[documentos]
	);

	const restricoesMarcadasComSlots = () => {
		const dadosOriginais = obtemAdesaoPrchpaDados(formulario)?.dados;
		const restricoesAdministrativas = metadata.restricoesAdministrativas
			.filter(field => {
				// dados originais
				const documentoDadosMarcado = dadosOriginais?.[field.id];
				const documentoDadosNaoMarcado = !documentoDadosMarcado;

				// dados da tela
				const telaMarcada = data?.[field.id];

				// mudança de estado
				const marcandoRestricao = documentoDadosNaoMarcado && telaMarcada;

				return marcandoRestricao;
			})
			.filter(field => temAlgumSlot({ field }));
		return restricoesAdministrativas;
	};

	const cancelSubmit = () => {
		setRestricoesMarcadasComSlotsCancelar(null);
		// verifica as perguntas sem respostas com documentos ou slots criados
		const fields = restricoesMarcadasComSlots();
		setRestricoesMarcadasComSlotsCancelar(fields);
		setPosProcessFormulario(true);
		setShowConfirm(true);
	};

	const submit = async operacao => {
		const { draft } = operacao;
		const errors = validacao({ metadata, data, documentos: formulario.documentos });

		if (forceSave && draft) {
			salvar({ draft, errors });
		} else {
			if (size(errors) > 0) {
				setErrors(errors);
				setForceSave(true);
			} else {
				if (!draft) {
					metadata.restricoesAdministrativas.forEach(ra => {
						if (!data[ra.id]) {
							const docs = ra.documentos || [];
							const docsIds = docs.map(d => d.idDocumento);
							const slotsToRemove = documentos.filter(d => docsIds.includes(d.idDocumento) && !d.idDocumentoSei);
							dispatch(actions.retiraDocumento({ idFormData, idsDocumentos: slotsToRemove.map(d => d.id) }));
						}
					});
				}
				setErrors({});
				salvar({ draft });
			}
		}
	};

	const handleChanges = useCallback(
		(value, name) => {
			debugLog('name: ', name, 'value: ', value);
			let errorsToRemove = [];

			if (name === 'resetForceSave') {
				setForceSave(false);
				return;
			}

			if (data?.[name] !== value) {
				setForceSave(false);
				setData(oldState => {
					let newValue = { ...oldState, [name]: value };
					errorsToRemove.push(name);

					// campo tinha valor e foi apagado
					if (!value && oldState[name]) {
						// só vai encontrar se for um campo de primeiro nível (não encontra se for subItem)
						const field = metadata?.restricoesAdministrativas.find(ra => ra.id === name) || {};

						// valida se já tem documentos nos slots
						const fieldDocs = field?.documentos?.map(d => d.idDocumento) || [];
						if (documentos.find(d => fieldDocs.includes(d.idDocumento) && d.filename && !d.idDocumentoSei)) {
							setErrors(old => ({ ...old, [field.id]: ['Não é possível desmarcar sem antes remover os documentos'] }));
							return oldState;
						}
						errorsToRemove = documentos
							.filter(d => fieldDocs.includes(d.idDocumento))
							.reduce((acc, d) => {
								acc.push(d.id);
								return acc;
							}, errorsToRemove);
						setErrors(old => omit(old, errorsToRemove));

						// remove subItens ao desmarcar o pai
						if (size(field.subItens) > 0) {
							field.subItens.forEach(si => {
								errorsToRemove.push([si.id]);
								delete newValue[si.id];
							});
						}
					}

					// inclui campos de modificação pelo RT ou pelo Admin
					if (emComplementacao && usuarioPortal) {
						if (value !== oldState[name]) {
							if (!newValue[`${name}AlteradaRt`]) {
								newValue[`${name}AlteradaRt`] = {
									de: oldState[name]
								};
							}
							newValue[`${name}AlteradaRt`].para = value;
						}
						if (value === newValue[`${name}AlteradaRt`]?.de) {
							delete newValue[`${name}AlteradaRt`];
						}
					}
					if (size(errorsToRemove) > 0) {
						errorsToRemove.push('restricoesAdministrativas');
					}

					setErrors(old => omit(old, errorsToRemove));
					if (usuarioAdmin) {
						onChangeHandler(newValue);
					}
					return newValue;
				});
			}
		},
		[data, documentos, emComplementacao, metadata, onChangeHandler, setErrors, usuarioAdmin, usuarioPortal]
	);

	useEffect(() => {
		if (!showConfirm && !showFormulario && restricoesMarcadasComSlotsCancelar && posProcessFormulario) {
			const idsDocumentos = restricoesMarcadasComSlotsCancelar.reduce((acc, field) => {
				const idsDocsField = field.documentos.map(d => d.idDocumento);
				const ids = documentos.reduce((acc2, d) => {
					if (!d.idDocumentoSei && idsDocsField.includes(d.idDocumento)) {
						acc2.push(d.id);
					}
					return acc2;
				}, []);
				acc.push(...ids);
				return acc;
			}, []);
			if (size(idsDocumentos) > 0) {
				dispatch(actions.retiraDocumento({ idFormData, idsDocumentos }));
			}
			setRestricoesMarcadasComSlotsCancelar(null);
			setPosProcessFormulario(false);
		}
	}, [
		dispatch,
		documentos,
		idFormData,
		posProcessFormulario,
		restricoesMarcadasComSlotsCancelar,
		setPosProcessFormulario,
		showConfirm,
		showFormulario
	]);

	const isOkToSubmit = useMemo(() => {
		const restricoes = metadata?.restricoesAdministrativas || [];

		const qtd = restricoes.reduce((acc, ra) => {
			let consideraRestricao = false;
			if (data?.[ra.id]) {
				if (ra.subItens) {
					const qtdSi = ra.subItens.reduce((acc2, si) => acc2 + (data?.[si.id] ? 1 : 0), 0);
					consideraRestricao = qtdSi >= 3;
				} else {
					consideraRestricao = true;
				}
			}
			if (consideraRestricao) {
				const docsNecessarios = ((data?.[ra.id] && ra.documentos) || []).filter(d => d.obrigatorio);
				const temDocsNecessarios = docsNecessarios.reduce((acc, d) => {
					const documentoProcurado = (documentos || []).find(
						documento => documento.idDocumento === d.idDocumento && documento.filename
					);
					const saida = acc && !!documentoProcurado;
					return saida;
				}, true);
				if (!temDocsNecessarios) {
					consideraRestricao = false;
				}
			}
			return consideraRestricao ? acc + 1 : acc;
		}, 0);

		const temTodos = restricoes.reduce((acc, ra) => {
			const docsNecessarios = ((data?.[ra.id] && ra.documentos) || []).filter(d => d.obrigatorio);
			return (
				acc &&
				docsNecessarios.reduce((acc, d) => {
					const documentoProcurado = (documentos || []).find(
						documento => documento.idDocumento === d.idDocumento && documento.filename
					);
					const saida = acc && !!documentoProcurado;
					return saida;
				}, true)
			);
		}, true);

		return temTodos && qtd >= 4;
	}, [data, documentos, metadata]);

	const temAlteracaoRevisor = useCallback(
		dimensao =>
			dimensao.questoes.reduce(
				(acc, q) =>
					acc ||
					q.perguntas.reduce((acc2, p) => acc2 || data?.[`${p.id}AlteradaRevisor`], false) ||
					q.perguntas.reduce(
						(acc2, p) =>
							acc2 ||
							data?.[`${p.id}AlteradaRevisor`] ||
							p.inputs
								.filter(input => (usuarioAdmin ? true : !input.id.startsWith('observacoes')))
								.reduce((acc3, i) => acc3 || data?.[`${i.id}AlteradaRevisor`], false),
						false
					),
				false
			),
		[data, usuarioAdmin]
	);

	const temAlteracaoRt = useCallback(
		dimensao =>
			dimensao.questoes.reduce(
				(acc, q) =>
					acc ||
					q.perguntas.reduce(
						(acc2, p) =>
							acc2 ||
							data?.[`${p.id}AlteradaRt`] ||
							p.inputs.reduce((acc3, i) => acc3 || data?.[`${i.id}AlteradaRt`], false),
						false
					),
				false
			),
		[data]
	);

	const expediente = useMemo(() => {
		const expediente = get(formulario, 'formData.data.expediente');
		return expediente;
	}, [formulario]);

	const enderecos = useMemo(() => {
		const enderecos = get(formulario, 'formData.data.enderecoCdlList');
		return enderecos;
	}, [formulario]);

	const hasErrors = useMemo(() => {
		let checkSize = size(errors) === 1;
		// condicional para caso seja erro de geração do doc específico CS (faltou apenas gerá-lo)
		let findDoc = documentos?.find(d => d.idDocumento === EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA);
		return !((checkSize && findDoc !== undefined && size(errors[findDoc.id]) > 0) || size(errors) === 0);
	}, [documentos, errors]);

	return (
		<>
			{loading && <Loader />}
			{formulario && (
				<>
					<fieldset>
						<legend>Identificação do empreendimento</legend>
						<div className="row id-prchpa">
							<div className="col-auto exp-un">
								<label>Expediente Único </label>
								{size(get(expediente, 'numero')) > 0 ? (
									<a
										href={`${montaURL('expedientes')}/consultapublica/${formulario.formData.data.expediente._id}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<span>{formatCodigoEU(get(expediente, 'numero'), true)}</span>
										<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
									</a>
								) : (
									<span className="nao-informado">Não informado</span>
								)}
							</div>

							<div className="col-auto area-priv">
								<label>
									Área Privativa <small>(unidade privativa/autônoma)</small>
								</label>
								{size(get(expediente, 'codigo')) > 0 ? (
									<span>{get(expediente, 'codigo', '').substr(12, 5)}</span>
								) : (
									<span className="nao-informado">Não informado</span>
								)}
							</div>

							<div className="col enderecos">
								<label>Endereço(s) da solicitação </label>
								{size(enderecos) > 0 ? (
									<div className="endereco">
										<ul>
											{enderecos.map((item, index) => (
												<li key={index}>{item.enderecoFormatadoCurto}</li>
											))}
										</ul>
									</div>
								) : (
									<span className="nao-informado">Não informado</span>
								)}
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend>Restrições administrativas</legend>
						{metadata && (
							<Inputs
								fields={metadata.restricoesAdministrativas}
								data={data}
								readOnly={false}
								disabled={false}
								usuarioInterno={usuarioAdmin}
								temAlteracaoRevisor={temAlteracaoRevisor}
								temAlteracaoRt={temAlteracaoRt}
								emComplementacao={emComplementacao}
								onChangeHandler={handleChanges}
								removeFile={removeFile}
							/>
						)}
						{size(errors?.restricoesAdministrativas) > 0 && (
							<ErrorMessages errorList={errors.restricoesAdministrativas} />
						)}
					</fieldset>
				</>
			)}
			{usuarioPortal && (
				<div className="cjto-botoes">
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={cancelSubmit}>
						Cancelar
					</button>
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => submit({ draft: true })}>
						{forceSave ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
					</button>
					{isOkToSubmit && (
						<button
							type="button"
							className="btn btn-primary mt-3"
							onClick={() => submit({ draft: false })}
							disabled={hasErrors}
						>
							Salvar e gerar PDF
						</button>
					)}
				</div>
			)}
			{hasErrors && (
				<div className="vistoria-card erros">
					<h3 className="font-weight-bold text-danger m-3">(*) Existem erros no formulário</h3>
				</div>
			)}
			<ShowDebug data={(data, metadata)} />
			{confirmaExclusaoDocumentos && (
				<Confirm
					msg="Confirma exclusão de todos os documentos associados a esta pergunta?"
					onConfirm={() =>
						handleChanges(
							confirmaExclusaoDocumentos.value,
							confirmaExclusaoDocumentos.name,
							confirmaExclusaoDocumentos.pergunta,
							true
						)
					}
					loader={false}
					onReject={() => setConfirmaExclusaoDocumentos(null)}
				/>
			)}
		</>
	);
}
DocumentoAdesaoPrchpa.displayName = 'DocumentoAdesaoPrchpa';
DocumentoAdesaoPrchpa.propTypes = {
	setShowFormulario: PropTypes.func,
	showFormulario: PropTypes.bool,
	setShowConfirm: PropTypes.func,
	showConfirm: PropTypes.bool,
	setPosProcessFormulario: PropTypes.func,
	posProcessFormulario: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	removeFile: PropTypes.func
};
export default DocumentoAdesaoPrchpa;

export function obtemAdesaoPrchpaDocumento(formulario) {
	const { documentos } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	return documento;
}

export function obtemAdesaoPrchpaDados(formulario) {
	const { documentosDados } = formulario || {};
	const documento = obtemAdesaoPrchpaDocumento(formulario);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento.original);
	return docDados;
}

/**
 * Este método parte do estado inicial do objeto de metadata e adiciona respostas e documentos às perguntas
 * além de adicionar certificação e selo almejados pelo solicitante. Este objeto é enviado ao backend para
 * criação do PDF que será anexado como documento específico a este requerimento
 */
function preparaObjetoParaTemplatePdf({ metadata, data = {}, documentos }) {
	let saida = {
		restricoesAdministrativas: cloneDeep(metadata.restricoesAdministrativas)
	};
	saida.restricoesAdministrativas.forEach(restricao => {
		restricao.value = !!data[restricao.id];
		if (restricao.value) {
			let docsAux = [];
			restricao.documentos.forEach(doc => {
				const docPai = documentos.find(d => d.idDocumento === doc.idDocumento);
				if (docPai) {
					docsAux.push(docPai);
					const docsFilhos = documentos.filter(d => d.docGroup === docPai.id && !!d.filename);
					if (size(docsFilhos) > 0) {
						docsAux.push(...docsFilhos);
					}
				}
			});
			docsAux = docsAux.map(d => ({ ...d, sizeString: sizeString(d.size || 0) }));
			restricao.documentos = docsAux;
		}
	});

	// remove restrições não marcadas
	saida.restricoesAdministrativas = saida.restricoesAdministrativas.filter(ra => ra.value);

	return saida;
}

function sizeString(size) {
	let unidade = 'KB';
	let sizeEmKb = size / 1000;
	if (sizeEmKb >= 1000) {
		sizeEmKb = sizeEmKb / 1000;
		unidade = 'MB';
	}
	const str = sizeEmKb.toLocaleString('pt-br', {
		style: 'decimal',
		minimumIntegerDigits: 1,
		useGrouping: true,
		maximumFractionDigits: 2
	});
	return `${str}${unidade}`;
}
