import React from 'react';

import PropTypes from 'prop-types';

const Label = ({ id, text, selected, children, onClick, debug }) => (
	<>
		<div onClick={onClick} className={`option-enquadramento${selected ? ' selected' : ''}`}>
			<span>{text}</span>
			{debug && (
				<span style={{ fontSize: '8pt', fontWeight: 'bold', color: '#fa970f', marginRight: '10px' }}> {id}</span>
			)}
			{children}
		</div>
	</>
);
Label.displayName = 'Id';
Label.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	selected: PropTypes.bool,
	debug: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClick: PropTypes.func
};

export default Label;
