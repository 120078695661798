import React, { useState, useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { isImmutable } from 'immutable';
import { get, size, trim } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU } from 'containers/Form/Detalhe';

import useMessages from 'custom-hooks/useMessages';

import { sendApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

import actions from '../Form/actions';

const LABELS = {
	'01': 'Flexibilização da altura da edificação',
	'02': 'Flexibilização da taxa de ocupação',
	'03': 'Flexibilização da área livre permeável',
	'04': 'Flexibilização do recuo para ajardinamento',
	//'05': 'Flexibilização das vagas de estacionamento',
	'06': 'Extensão do regime volumétrico',
	'07': 'Imóvel atingido por traçado do PDDUA (Edificação incidente em área de gravame do traçado viário)',
	'08': 'Imóvel sem regime urbanístico definido',
	'09': 'Edificação em Área de Proteção do Ambiente Natural, com taxa de ocupação máxima de 300m² na zona de conservação',
	'10': 'Atividade vinculada a habitação não prevista no GA ou prevista no Anexo 11.1',
	'11': 'Atividade listada no Anexo 11.1 (folha 1) do PDDUA',
	'11.01': 'Atividade com drive thru',
	'11.02': 'Atividade de hospedagem',
	'11.03': 'Cartódromo fechado',
	'11.04': 'Central de abastecimento alimentício com área adensável entre 5.000m² e 10.000m²',
	'11.05': 'Centro comercial com área adensável entre 5.000m² e 10.000m²',
	'11.06': 'Centro cultural com área adensável entre 750m² e 5.000m²',
	'11.07': 'Centro de eventos com área adensável entre 750m² e 5.000m²',
	'11.08': 'Clube com área adensável até 5000m²',
	'11.09': 'Comércio atacadista com área adensável entre 5.000m² e 30.000m',
	'11.10': 'Crematório em cemitério existente',
	'11.11': 'Depósito com área adensável entre 5.000m² e 30.000m',
	'11.12': 'Entretenimento noturno com área adensável até 750m²',
	'11.13': 'Equipamento administrativo',
	'11.14': 'Equipamento de segurança pública',
	'11.15': 'Equipamento esportivo e de lazer',
	'11.16': 'Estabelecimento de ensino formal',
	'11.17': 'Estação de radiodifusão, de telefonia ou de televisão',
	'11.18': 'Garagem comercial com 100 a 200 vagas',
	'11.19': 'Garagem geral em terreno com área de até 1.000m²',
	'11.20': 'Hospital',
	'11.21':
		'Indústria com interferência ambiental e com área adensável entre 200m²* e 10.000m² (*O Decreto 20.628/20 dispensou de EVU as indústrias de até 300m² de área adensável)',
	'11.22': 'Posto de abastecimento',
	'11.23': 'Supermercado com área adensável entre 500m² e 10.000m²',
	'11.24': 'Templo e local de culto em geral com área adensável até 1.500m²',
	'12': 'Edificação de médio porte (edificação não residencial com área adensável entre 10.000m² e 30.000m² ou com guarda de veículos entre 200 e 400 vagas), conforme Anexo 11.1 (folha 2)',
	'13': 'Implantação de atividade não prevista no grupamento de atividades',
	'14': 'Extensão do regime de atividades',
	'15': 'Aumento de porte',
	//'16': 'Solo criado de grande adensamento',
	'17': 'Imóvel em Área de Interesse Cultural',
	'18': 'Imóvel tombado ou inventariado de estruturação',
	'19': 'Outros',
	'20': 'Descreva (por que você selecionou o item "Outros',
	'21': 'Justificativa (fundamentar a solicitação)',
	'22': 'Amparo legal (informar os artigos do PDDUA que embasam a solicitação)',
	'23': 'Características da atividade pretendida ou empreendimento, área construída, modo de operar, produtos e demais informações pertinentes',
	'24': 'Tipo de veículo, horário e frequência de operação de carga e descarga e/ou embarque e desembarque, se houver',
	'25': 'Horário e/ou turnos de funcionamento',
	'26': 'População estimada'
};

const QUESTOES = {
	flexibilizacaoAlturaEdificacao: { id: '01', title: LABELS['01'], checkbox: true },
	flexibilizacaoTaxaOcupacao: { id: '02', title: LABELS['02'], checkbox: true },
	flexibilizacaoAreaLivrePermeavel: { id: '03', title: LABELS['03'], checkbox: true },
	flexibilizacaoRecuoAjardinamento: { id: '04', title: LABELS['04'], checkbox: true },
	//flexibilizacaoVagasEstacionamento: { id: '05', title: LABELS['05'], checkbox: true },
	extensaoRegimeVolumetrico: { id: '06', title: LABELS['06'], checkbox: true },
	imovelAtindidoTracaoPddua: { id: '07', title: LABELS['07'], checkbox: true },
	imovelSemRegimeUrbanisticoDefinido: { id: '08', title: LABELS['08'], checkbox: true },
	edificacaoOcupandoAte300ZonaConservacao: { id: '09', title: LABELS['09'], checkbox: true },
	atividadeHabitacaoNaoPrevistaGa: { id: '10', title: LABELS['10'], checkbox: true },
	atividadeListadaAnexo111Pddua: { id: '11', title: LABELS['11'], checkbox: true },

	atividadeComDriveThru: { id: '11.01', title: LABELS['11.01'], checkbox: true },
	atividadeHospedagem: { id: '11.02', title: LABELS['11.02'], checkbox: true },
	cartodromoFechado: { id: '11.03', title: LABELS['11.03'], checkbox: true },
	centralAbastAlimEntre5e10mil: { id: '11.04', title: LABELS['11.04'], checkbox: true },
	controComEntre5e10mil: { id: '11.05', title: LABELS['11.05'], checkbox: true },
	centroCultEntre750e5mil: { id: '11.06', title: LABELS['11.06'], checkbox: true },
	centroEventosEntre750e5mil: { id: '11.07', title: LABELS['11.07'], checkbox: true },
	clubeEntre0e5000: { id: '11.08', title: LABELS['11.08'], checkbox: true },
	comercioAtacadEntre5e30mil: { id: '11.09', title: LABELS['11.09'], checkbox: true },
	crematorioCemiterioExistente: { id: '11.10', title: LABELS['11.10'], checkbox: true },
	depositoEntre5e30mil: { id: '11.11', title: LABELS['11.11'], checkbox: true },
	entretNoturnoEntre0e750: { id: '11.12', title: LABELS['11.12'], checkbox: true },
	equipamentoAdministrativo: { id: '11.13', title: LABELS['11.13'], checkbox: true },
	equipamentoSegurancaPublica: { id: '11.14', title: LABELS['11.14'], checkbox: true },
	equipamentoEsporteLazer: { id: '11.15', title: LABELS['11.15'], checkbox: true },
	estabelecimentoEnsinoFormal: { id: '11.16', title: LABELS['11.16'], checkbox: true },
	estacaoRadiodifTelfoniaTelev: { id: '11.17', title: LABELS['11.17'], checkbox: true },
	garagemComercialEntre100e200: { id: '11.18', title: LABELS['11.18'], checkbox: true },
	garagemGeralEntre0e1mil: { id: '11.19', title: LABELS['11.19'], checkbox: true },
	hospital: { id: '11.20', title: LABELS['11.20'], checkbox: true },
	indInterfAmbEntre200e10mil: { id: '11.21', title: LABELS['11.21'], checkbox: true },
	postoAbastecimento: { id: '11.22', title: LABELS['11.22'], checkbox: true },
	supermercadoEntre500e10mil: { id: '11.23', title: LABELS['11.23'], checkbox: true },
	temploLocalCultoEntre0e1500: { id: '11.24', title: LABELS['11.24'], checkbox: true },

	edificacaoMedioPorte: { id: '12', title: LABELS['12'], checkbox: true },
	implantacaoAtividadeNaoPrevistaGa: { id: '13', title: LABELS['13'], checkbox: true },
	extensaoRegimeAtividades: { id: '14', title: LABELS['14'], checkbox: true },
	aumentoPorte: { id: '15', title: LABELS['15'], checkbox: true },
	//soloCriadoGrandeAdensamento: { id: '16', title: LABELS['16'], checkbox: true },
	imovelAreaInteresseCultural: { id: '17', title: LABELS['17'], checkbox: true },
	imovelTombadoOuInventariado: { id: '18', title: LABELS['18'], checkbox: true },
	outros: { id: '19', title: LABELS['19'], checkbox: true },
	outrosDescricao: { id: '20', title: LABELS['20'], textarea: true },
	justificativa: { id: '21', title: LABELS['21'], textarea: true, grupo1: true, grupo2: true },
	amparoLegal: { id: '22', title: LABELS['22'], textarea: true, grupo1: true, grupo2: true },
	caracteristicas: { id: '23', title: LABELS['23'], textarea: true, grupo2: true },
	embarque: { id: '24', title: LABELS['24'], textarea: true, grupo2: true },
	horario: { id: '25', title: LABELS['25'], textarea: true, grupo2: true },
	populacao: { id: '26', title: LABELS['26'], textarea: true, grupo2: true }
};

const QUESTOES_GRUPO_1 = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '16', '17', '18', '19'];
const QUESTOES_GRUPO_2 = ['10', '11', '12', '13', '14', '15'];

const debug = false && isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(ARRAZOADO)', ++i, ...args);

const ArrazoadoEvu = ({ setShowFormulario }) => {
	// #region hooks
	/* REDUX */
	const dispatch = useDispatch();
	// recebe o formulario da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			setFormulario(isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm);
		}
	}, [formularioImm]);
	// recebe o errors da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const errorsImm = useSelector(state => state.getIn(['licenciamento', 'errors']));
	useEffect(() => {
		if (errorsImm) {
			setErrors(isImmutable(errorsImm) ? errorsImm.toJS() : errorsImm);
		}
	}, [errorsImm]);

	/* OUTROS HOOKS */
	const { createMessage } = useMessages();
	const { id } = useParams();

	/* ESTADOS */
	const [formulario, setFormulario] = useState(null);
	const [errors, setErrors] = useState(null);
	const [data, setData] = useState(null);
	const [haItensDoGrupo1Marcados, setHaItensDoGrupo1Marcados] = useState(false);
	const [haItensDoGrupo2Marcados, setHaItensDoGrupo2Marcados] = useState(false);
	const [loading, setLoading] = useState(false);
	// #endregion

	useEffect(() => {
		if (formulario) {
			debugLog('[formulario]', formulario);
			const docDados = obtemArrazoadoDados(formulario);
			setData(docDados);
		}
	}, [formulario]);

	useEffect(() => {
		if (get(data, 'questoes')) {
			const checkeds = Object.keys(get(data, 'questoes') || {})
				.filter(key => data.questoes[key] && data.questoes[key].checked)
				.map(key => data.questoes[key].id);
			const grupo1 = QUESTOES_GRUPO_1.filter(value => checkeds.includes(value));
			const grupo2 = QUESTOES_GRUPO_2.filter(value => checkeds.includes(value));
			setHaItensDoGrupo1Marcados(grupo1.length > 0);
			setHaItensDoGrupo2Marcados(grupo2.length > 0);
		}
	}, [data]);

	const validate = () => {
		let errors = {};
		let atividadeListadaAnexo111PdduaChecked = false;
		let atividadeListadaAnexo111PdduaHasChecked = false;
		Object.keys(get(data, 'questoes') || {})
			.sort((k1, k2) => {
				const q1 = data.questoes[k1];
				const q2 = data.questoes[k2];
				return q1.id > q2.id ? 1 : -1;
			})
			.forEach(key => {
				const questao = data.questoes[key];
				if (questao.textarea) {
					if (size(trim(questao.value)) === 0) {
						if (
							(haItensDoGrupo1Marcados && questao.grupo1) ||
							(haItensDoGrupo2Marcados && questao.grupo2) ||
							(data.questoes.outros.checked && questao.id === '20')
						) {
							errors[key] = ['Campo deve ser preenchido'];
						}
					}
				}
				if (questao.id === QUESTOES.atividadeListadaAnexo111Pddua.id && questao.checked === true) {
					atividadeListadaAnexo111PdduaChecked = true;
				}
				if (
					!atividadeListadaAnexo111PdduaHasChecked &&
					questao.id !== QUESTOES.atividadeListadaAnexo111Pddua.id &&
					questao.id.indexOf('11.') > -1 &&
					questao.checked === true
				) {
					atividadeListadaAnexo111PdduaHasChecked = true;
				}
			});
		if (atividadeListadaAnexo111PdduaChecked && !atividadeListadaAnexo111PdduaHasChecked) {
			errors['atividadeListadaAnexo111Pddua'] = ['Ao menos um campo deve ser preenchido'];
		}
		return errors;
	};

	const limpaCamposDesnecessarios = questoes => {
		const saida = { ...questoes };
		Object.keys(saida).forEach(key => {
			const questao = saida[key];

			const [item, subitem] = questao.id.split('.');
			let itemMarcado = false;
			if (subitem) {
				const itemPrincipal = (Object.keys(data.questoes) || []).map(k => data.questoes[k]).find(q => q.id === item);
				itemMarcado = itemPrincipal.checked;
			}

			if (questao.checkbox) {
				if (subitem && !itemMarcado) {
					questao.checked = false;
				}
			} else if (questao.textarea) {
				if (questao.id === '20') {
					if (!saida.outros.checked) {
						questao.value = null;
					}
				} else if (QUESTOES_GRUPO_2.includes(questao.id)) {
					if (!haItensDoGrupo2Marcados) {
						questao.value = null;
					}
				} else {
					if (QUESTOES_GRUPO_1.includes(questao.id)) {
						if (haItensDoGrupo1Marcados) {
							questao.value = null;
						}
					} else {
						// textareas 21 e 22 devem possuir checkboxes de algum dos grupos 1 ou 2 selecionado
						if (['21', '22'].includes(questao.id) && !haItensDoGrupo1Marcados && !haItensDoGrupo2Marcados) {
							questao.value = null;
						} else if (['23', '24', '25', '26'].includes(questao.id) && !haItensDoGrupo2Marcados) {
							// textareas restantes devem possuir checkboxes do grupo 2 selecionado
							questao.value = null;
						}
					}
				}
			}
		});
		return saida;
	};

	const submit = async ({ draft }) => {
		let errors = validate();
		if (size(errors) > 0) {
			setErrors(errors);
		} else {
			try {
				setErrors(null);
				setLoading(true);
				// limpa itens que não deveriam estar preenchidos
				const questoesLimpo = limpaCamposDesnecessarios(get(data, 'questoes') || {});
				const url = `processo/${id}/${EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU}${draft ? '?draft=true' : ''}`;
				const newData = { ...data, questoes: questoesLimpo };
				const { data: dataApi } = await sendApi({ url, payload: newData }, false);
				dispatch(
					actions.alteraStatusDocumento({
						documento: dataApi.documento,
						documentoDados: dataApi.documentoDados
					})
				);
				setLoading(false);
				setShowFormulario(false);
				createMessage(`Planilha salva${draft ? ' como rascunho' : ''} com sucesso`, 5);
			} catch (error) {
				createMessage('Problemas no salvamento do documento... verifique a console', 3);
				console.error('erro no servidor', error);
			}
		}
	};

	const handleOnChange = e => {
		const { name, checked, value } = e.target;
		if (name) {
			setData(oldState => ({
				...oldState,
				questoes: {
					...(oldState.questoes || {}),
					[name]: oldState.questoes[name].checkbox
						? { ...oldState.questoes[name], checked }
						: { ...oldState.questoes[name], value }
				}
			}));
		}
	};

	return (
		<>
			<div className="container" style={{ overflowY: 'auto', height: '650px' }}>
				<section>
					<h1>Documento Arrazoado EVU</h1>
					<h2>Estudo de Viabilidade Urbanística Projeto Especial de Impacto Urbano de 1º Grau</h2>
					{(() => {
						let currentItemNumber = 0;
						let subitemCounter = 0;

						return Object.keys(get(data, 'questoes') || {})
							.sort((k1, k2) => {
								const q1 = data.questoes[k1];
								const q2 = data.questoes[k2];
								return q1.id > q2.id ? 1 : -1;
							})
							.map(key => {
								const questao = data.questoes[key];
								const [item, subitem] = questao.id.split('.');
								let itemMarcado = false;

								if (!subitem) {
									currentItemNumber++;
									subitemCounter = 0;
								}

								if (subitem) {
									const itemPrincipal = (Object.keys(data.questoes) || [])
										.map(k => data.questoes[k])
										.find(q => q.id === item);
									itemMarcado = itemPrincipal.checked;
									subitemCounter++;
								}

								const displayNumber = subitem ? `${currentItemNumber}.${subitemCounter}` : currentItemNumber.toString();

								return (
									questao &&
									questao.checkbox &&
									(!subitem || (subitem && itemMarcado)) && (
										<div className="container-checkboxes" key={questao.id}>
											<label className={`label-checkbox ${subitem && itemMarcado ? 'subitem' : ''}`}>
												<input
													type="checkbox"
													className="checkbox-input"
													name={key}
													id={key}
													value={key}
													checked={questao.checked}
													onChange={handleOnChange}
												/>
												{displayNumber} - {questao.title}
											</label>
											{size(errors) > 0 && questao.checked && <ErrorMessages errorList={errors[key] || null} />}
										</div>
									)
								);
							});
					})()}
				</section>

				{Object.keys(get(data, 'questoes') || {})
					.sort((k1, k2) => {
						const q1 = data.questoes[k1];
						const q2 = data.questoes[k2];
						return q1.id > q2.id ? 1 : -1;
					})
					.map(key => {
						const questao = data.questoes[key];
						return (
							questao &&
							questao.textarea &&
							((data.questoes.outros.checked && questao.id === '20') ||
								(haItensDoGrupo1Marcados && questao.grupo1) ||
								(haItensDoGrupo2Marcados && questao.grupo2)) && (
								<Fragment key={questao.id}>
									{questao.id === '21' && <h3>Informações obrigatórias:</h3>}
									{haItensDoGrupo2Marcados && questao.id === '23' && (
										<h3>Informações obrigatórias complementares (referentes aos itens 09 a 14):</h3>
									)}
									<section
										style={{
											backgroundColor: 'ghostwhite',
											border: '1px solid',
											padding: '10px',
											marginBottom: '10px'
										}}
									>
										<label>
											{questao.title}
											<textarea name={key} value={questao.value || ''} onChange={handleOnChange} />
										</label>
									</section>
									{size(errors) > 0 && <ErrorMessages errorList={errors[key] || null} />}
								</Fragment>
							)
						);
					})}
			</div>
			<div className="cjto-botoes">
				<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => submit({ draft: true })}>
					Salvar Rascunho
				</button>
				<button type="button" className="btn btn-primary mt-3" onClick={() => submit({ draft: false })}>
					Salvar e gerar PDF
				</button>
			</div>
			{loading && <Loader msg="Enviando dados do documento..." />}{' '}
		</>
	);
};
ArrazoadoEvu.displayName = 'ArrazoadoEvu';
ArrazoadoEvu.propTypes = {
	setShowFormulario: PropTypes.func,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool
};

export default ArrazoadoEvu;

export function obtemArrazoadoDados(formulario) {
	const doc = (formulario.documentos || []).reduce(
		(acc, d) =>
			d.extensao === EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU ? (acc ? (d.versao > acc.versao ? d : acc) : d) : acc,
		null
	);
	let docDados = doc ? (formulario.documentosDados || []).find(docDados => docDados.id === doc.id) : null;
	if (!docDados) {
		docDados = doc ? (formulario.documentosDados || []).find(docDados => docDados.id === doc.original) : null;
	}
	if (!docDados) {
		docDados = {
			questoes: Object.keys(QUESTOES).reduce(
				(acc, key) => ({
					...acc,
					[key]: Object.assign(QUESTOES[key], QUESTOES[key].checkbox ? { checked: false } : { value2: null })
				}),
				{}
			)
		};
	}
	return docDados;
}
