import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';

import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const EditorViewComponent = ({ state, hideClass }) => {
	const [estado, setEstado] = useState(EditorState.createEmpty());

	useEffect(() => {
		if (state) {
			const blocksFromHtml = htmlToDraft(state);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setEstado(editorState);
		}
	}, [state]);

	return (
		<Editor
			editorState={estado}
			readOnly={true}
			toolbarClassName="toolbar-class-name-readonly"
			wrapperClassName="wrapper-class-name-readOnly"
			editorClassName={hideClass ? '' : 'editor-class-name-readOnly'}
		/>
	);
};
EditorViewComponent.displayName = 'EditorViewComponent';
EditorViewComponent.propTypes = {
	state: PropTypes.string,
	hideClass: PropTypes.bool
};

export default EditorViewComponent;
