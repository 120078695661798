import React from 'react';

import { MapField } from 'containers/Form/metadata-template/fields';

function TesteMapa() {
	return <MapField />;
}
TesteMapa.displayName = 'TesteMapa';

export default TesteMapa;
