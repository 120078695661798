import React from 'react';

import PropTypes from 'prop-types';

import 'rc-calendar/assets/index.css';
import ptBR from 'rc-calendar/lib/locale/pt_BR';
import Picker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';

import './CalendarioRange.scss';

function isValidRange(v) {
	return v && v[0] && v[1];
}

function formatMoment(params) {
	return params ? params.format('DD/MM/YYYY') : '';
}

const CalendarioRange = props => {
	const calendar = (
		<RangeCalendar
			locale={ptBR}
			dateInputPlaceholder={['Início', 'Fim']}
			formatter={'DD/MM/YYYY'}
			timePicker={null}
			showToday={false}
			showDateInput={props.showDateInput || false}
			disabledDate={props.disabledDate || (() => false)}
		/>
	);
	return (
		<div className="cal-range">
			<Picker
				animation="slide-up"
				disabled={props.disabled || false}
				calendar={calendar}
				value={props.value}
				onChange={props.onChange}
				placement={props.placement || 'bottomLeft'}
			>
				{({ value }) => (
					<div className="input-group">
						<input
							type="text"
							placeholder={props.disabled ? '' : 'informe o intervalo de datas'}
							disabled={props.disabled || false}
							readOnly
							className={
								props.disabled ? `${props.inputClass}` : `${props.inputClass} ant-calendar-picker-input ant-input`
							}
							value={isValidRange(value) ? `${formatMoment(value[0])} Até ${formatMoment(value[1])}` : ''}
						/>
						{!props.disabled && (
							<div className="input-group-append">
								<button
									type="button"
									aria-label="limpar data"
									className="btn btn-outline-secondary"
									onClick={() => props.onChange([])}
								>
									<i className="fa fa-times" aria-hidden="true" />
								</button>
							</div>
						)}
					</div>
				)}
			</Picker>
		</div>
	);
};
CalendarioRange.displayName = 'CalendarioRange';
CalendarioRange.propTypes = {
	defaultCalendarValue: PropTypes.object,
	showDateInput: PropTypes.bool,
	disabledDate: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	onChange: PropTypes.func,
	placement: PropTypes.string,
	inputClass: PropTypes.string
};

export default CalendarioRange;
