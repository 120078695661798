import React from 'react';

const Footer = () => (
	<footer>
		<div className="container">
			<a href="https://www.procempa.com.br" target="_blank" rel="noopener noreferrer">
				<img src="https://www.procempa.com.br/img/desenvolvimento_procempa.png" alt="procempa" title="procempa" />
			</a>
		</div>
	</footer>
);
Footer.displayName = 'Footer';

export default Footer;
