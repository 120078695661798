import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import Calendar from 'rc-calendar';
import ptBR from 'rc-calendar/lib/locale/pt_BR';
import DatePicker from 'rc-calendar/lib/Picker';

import 'rc-calendar/assets/index.css';

const Calendario = props => {
	const formatter = props.formatter || 'DD/MM/YYYY';

	const calendar = (
		<Calendar
			locale={ptBR}
			dateInputPlaceholder="informe a data"
			formatter={formatter}
			disabledTime={null}
			timePicker={null}
			defaultValue={props.defaultCalendarValue || hoje}
			showDateInput={props.showDateInput || false}
			disabledDate={props.disabledDate || (() => false)}
		/>
	);
	return (
		<Fragment>
			<DatePicker
				getCalendarContainer={props.containerRef ? () => props.containerRef : undefined}
				animation="slide-up"
				disabled={props.disabled || false}
				calendar={calendar}
				value={
					props.value
						? typeof props.value === 'number'
							? moment(props.value).locale('pt-br')
							: props.value.locale('pt-br')
						: null
				}
				onChange={props.onChange}
				placement={props.placement || 'bottomLeft'}
			>
				{() => (
					<input
						type="text"
						placeholder={props.disabled ? '' : 'informe a data'}
						disabled={props.disabled || false}
						readOnly
						className={
							props.disabled ? `${props.inputClass}` : `${props.inputClass} ant-calendar-picker-input ant-input`
						}
						value={
							props.value
								? (typeof props.value === 'number' ? moment(props.value) : props.value)
										.locale('pt-br')
										.format(formatter)
								: ''
						}
					/>
				)}
			</DatePicker>
			{!props.disabled && (
				<button type="button" aria-label="limpar data" className="btn btn-calendar" onClick={() => props.onChange('')}>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
			)}
		</Fragment>
	);
};
Calendario.displayName = 'Calendario';
Calendario.propTypes = {
	defaultCalendarValue: PropTypes.object,
	showDateInput: PropTypes.bool,
	disabledDate: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	onChange: PropTypes.func,
	placement: PropTypes.string,
	inputClass: PropTypes.string,
	formatter: PropTypes.string,
	containerRef: PropTypes.any
};

export default Calendario;
export const dataSemHora = data => data.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
export const ontem = dataSemHora(moment().add(-1, 'days'));
export const hoje = dataSemHora(moment());
export const amanha = dataSemHora(moment().add(1, 'days'));
export const disabledFuture = data => dataSemHora(data || hoje).valueOf() >= amanha.valueOf();
export const disabledPast = data => dataSemHora(data || hoje).valueOf() <= ontem.valueOf();
