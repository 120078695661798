import React, { useState, Fragment, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useQuery } from 'react-apollo';
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router';

import gql from 'graphql-tag';

import EditorView from 'components/EditorView';
import Loader from 'components/Loader';
import withMessage from 'components/WithMessage';

const PAGE_SIZE = 1000;

const Faq = () => {
	const [panel, setPanel] = useState();
	let {
		data,
		loading: loadingData,
		error
	} = useQuery(
		gql`
			query faqList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoFaqList(term: $term, skip: $skip, limit: $limit) {
					id
					titulo
					descricao
					disabled
				}
				count: LicenciamentoFaqCount(term: $term)
			}
		`,
		{
			variables: { limit: PAGE_SIZE },
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		setPanel(false);
	}, []);

	const togglePanel = useCallback(
		name => {
			if (panel === name) {
				setPanel(null);
			} else {
				setPanel(name);
			}
		},
		[panel]
	);

	return (
		<Fragment>
			<div className="container">
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>FAQ - Perguntas Frequentes</li>
					</ul>
				</div>
				<h1 className="page-header row">FAQ - Perguntas Frequentes</h1>
				{data &&
					data.list
						.filter(faq => !faq.disabled)
						.map(faq => {
							const showBody = panel === faq.id;
							return (
								<Card key={faq.id} className={`${showBody ? '' : 'card-borderless'}`}>
									<Card.Header
										className={`${showBody ? 'card-header-selecionado' : ''}`}
										onClick={() => togglePanel(faq.id)}
									>
										<div name="header" className="container-header">
											<div className="flex justify-content-between">
												<i
													className={panel === faq.id ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
													aria-hidden="true"
													style={{ padding: '0 0 0 0.5rem' }}
												></i>
												<span style={{ padding: '0 0 0 1.5rem' }}>{faq.titulo}</span>
											</div>
										</div>
									</Card.Header>
									{showBody && (
										<Card.Body>
											<div className="acordeon-collapse">
												<EditorView state={faq.descricao} hideClass={true} />
											</div>
										</Card.Body>
									)}
								</Card>
							);
						})}
			</div>
			{error ? <Redirect to={'/serverUnavailable'} /> : loadingData && <Loader />}
		</Fragment>
	);
};

Faq.displayName = 'Faq';
Faq.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

export default withMessage(Faq);
