import React, { useState, useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, omit } from 'lodash';

import Loader from 'components/Loader';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';

import {
	Anexo51Field,
	Anexo52Field,
	CheckboxField,
	CurrencyField,
	NumberField,
	RadioField,
	TextAreaField,
	validateAnexo51Field,
	validateAnexo52Field,
	validateCheckboxField,
	validateCurrencyField,
	validateNumberField,
	validateRadioField,
	validateTextAreaField
} from '.';

/* eslint-disable react-hooks/exhaustive-deps */
const TipoLicencaExpressa = ({
	name: objName,
	value,
	containerClass,
	readOnly,
	disabled,
	errorObj,
	tipoLicencaExpressa,
	onChangeHandler,
	fields
}) => {
	const [focusField, setFocusField] = useState(null);
	const [ocorrencia, setOcorrencia] = useState(null);
	const [loading, setLoading] = useState(false);
	const [camposSelecionados, setCamposSelecionados] = useState([]);

	useEffect(() => {
		if (tipoLicencaExpressa) {
			setCamposSelecionados(
				Object.keys(fields).filter(c => fields[c].tiposLicenca.indexOf(parseInt(tipoLicencaExpressa || '', 10)) > -1)
			);
			loadOcorrencia();
		}
	}, []);

	useEffect(() => {
		if (ocorrencia) {
			onChangeHandler({
				name: 'tipoLicencaExpressa',
				value: { ...omit(ocorrencia, ['codigo']), codOcorrenciaLicenca: ocorrencia.codigo }
			});
		}
	}, [ocorrencia]);

	const loadOcorrencia = async () => {
		setLoading(true);
		try {
			const response = await accessApi(
				`${EXPEDIENTES_API_URL}/cadastros/ocorrencias?term=${tipoLicencaExpressa}`,
				true
			);
			const retorno = response.data;
			setOcorrencia(size(retorno) > 0 ? retorno[0] : null);
		} catch (e) {
			setOcorrencia(null);
			console.error('Erro no acesso ao anexo 51: ', e);
		}
		setLoading(false);
	};

	const onChangeInnerFields = obj => {
		if (Array.isArray(obj)) {
			const newValue = { ...value };
			let modified = false;
			obj.forEach(o => {
				if (o.name === 'errors') {
					trataErrors(o.value);
				} else {
					modified = true;
					newValue[o.name] = o.value;
				}
			});
			if (modified) {
				onChangeHandler({ name: objName, value: newValue });
			}
		} else {
			if (obj.name === 'errors') {
				trataErrors(obj.value);
			} else {
				onChangeHandler({ name: objName, value: { ...value, [obj.name]: obj.value } });
			}
		}
	};

	const trataErrors = novosErros => {
		const newErrors = { ...errorObj };
		for (let property in novosErros) {
			newErrors[property] = novosErros[property];
		}
		const cleanErrors = {};
		for (let property in newErrors) {
			if (size(cleanErrors[property]) === 0) {
				cleanErrors[property] = [];
			}
			newErrors[property].forEach(message => {
				if (cleanErrors[property].indexOf(message) === -1) {
					cleanErrors[property].push(message);
				}
			});
		}
		onChangeHandler({ name: 'errors', value: { [objName]: newErrors } });
	};

	return size(camposSelecionados) === 0 ? null : loading ? (
		<Loader msg={`carregando ocorrencia ${tipoLicencaExpressa}`} />
	) : (
		<div className={`${containerClass} tipoLicencaExpressaRowContainerClass`} id="tipoLicencaExpressaContainerClass">
			{camposSelecionados.map((name, i) => (
				<Fragment key={i}>
					{fields[name].type === 'anexo51Field' && (
						<Anexo51Field
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || {}}
							onChangeHandler={obj => onChangeInnerFields(obj, name)}
							containerClass={fields[name].containerClass}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
						/>
					)}
					{fields[name].type === 'anexo52Field' && (
						<Anexo52Field
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || {}}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
						/>
					)}
					{fields[name].type === 'radioField' && (
						<RadioField
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || ''}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
							options={fields[name].options}
						/>
					)}
					{fields[name].type === 'checkboxField' && (
						<CheckboxField
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || (fields[name].options.length === 1 ? false : [])}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
							options={fields[name].options}
						/>
					)}
					{fields[name].type === 'currencyField' && (
						<CurrencyField
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || {}}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							maxSize={`${fields[name].maxSize}`}
							decimals={`${fields[name].decimals}`}
							checkForZero={fields[name].checkForZero}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
						/>
					)}
					{fields[name].type === 'numberField' && (
						<NumberField
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || {}}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							maxSize={`${fields[name].maxSize}`}
							maxValue={`${fields[name].maxValue}`}
							minValue={`${fields[name].minValue}`}
							checkForZero={fields[name].checkForZero}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
						/>
					)}
					{fields[name].type === 'textAreaField' && (
						<TextAreaField
							title={fields[name].title}
							label={fields[name].label}
							required={fields[name].required}
							placeHolder={fields[name].placeHolder}
							name={name}
							value={value[name] || ''}
							onChangeHandler={e => onChangeInnerFields(e, name)}
							containerClass={fields[name].containerClass}
							errorList={(errorObj || {})[name]}
							readOnly={readOnly}
							disabled={disabled}
							onSetFocus={setFocusField}
							hasFocus={focusField === name}
						/>
					)}
				</Fragment>
			))}
		</div>
	);
};

TipoLicencaExpressa.displayName = 'TipoLicencaExpressa';
TipoLicencaExpressa.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorObj: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	tipoLicencaExpressa: PropTypes.string,
	onChangeHandler: PropTypes.func,
	fields: PropTypes.object
};

export const validate = (value, tipoLicencaExpressa, fields) => {
	let errors = {};

	Object.keys(fields)
		.filter(c => fields[c].tiposLicenca.indexOf(parseInt(tipoLicencaExpressa || '', 10)) > -1)
		.forEach(name => {
			const { required, label, minValue, maxValue } = fields[name];
			const valor = (value || {})[name];
			switch (name) {
				case 'anexo51':
					errors = { ...errors, [name]: validateAnexo51Field(valor, required, label) };
					break;

				case 'anexo52':
					errors = { ...errors, [name]: validateAnexo52Field(valor, required, label) };
					break;

				case 'construcaoTemporaria':
					errors = { ...errors, [name]: validateCheckboxField(valor, required, label) };
					break;

				case 'areaTotal':
				case 'areaTotalPermanecer':
				case 'areaTotalDemolir':
				case 'areaObjetoAnalise':
				case 'areaAdensavel':
				case 'areaRegular':
				case 'areaExistente':
				case 'areaExistentePermanecer':
				case 'areaExistenteDemolir':
				case 'areaIrregular':
					errors = { ...errors, [name]: validateCurrencyField(valor, required, label) };
					break;

				case 'vagasEstacionamento':
				case 'economiasExistentes':
				case 'economiasPropostas':
					errors = { ...errors, [name]: validateNumberField(valor, required, label, minValue, maxValue) };
					break;

				case 'tipoMaterial':
					errors = { ...errors, [name]: validateRadioField(valor, required, label) };
					break;

				case 'complemento':
					errors = { ...errors, [name]: validateTextAreaField(valor, required, label) };
					break;

				default:
					break;
			}
		});
	return errors;
};

export default TipoLicencaExpressa;
