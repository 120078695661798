import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { useStore, useDispatch } from 'react-redux';
import { withRouter, Link, useLocation } from 'react-router-dom';

import { replace as replaceRoute } from 'connected-react-router';
import gql from 'graphql-tag';
import { flatMap, get, size } from 'lodash';
import moment from 'moment';
import { compose } from 'redux';

import Breadcrumbs from 'components/Breadcrumbs';
import EstadoRequerimento, { STATUS_DOS_PROCESSOS } from 'components/EstadoRequerimento';
import Loader from 'components/Loader';
import PaginationV2 from 'components/PaginationV2';
import Selecao from 'components/Selecao';
import Truncate from 'components/Truncate';

import useClickOutsideContainer from 'custom-hooks/useClickOutsideContainer';
import useKeypress from 'custom-hooks/useKeypress';
import useLocalStorage from 'custom-hooks/useLocalStorage';
import useMessages from 'custom-hooks/useMessages';

import apolloClient from 'utils/graphql';
import { accessApi } from 'utils/injectApi';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { sortCadastros, isDebug, removeDiacritics, obtainUrlAdmin } from 'utils/tools';

import actions from './actions';
import reducer from './reducer';
import saga from './saga';

const PAGE_SIZE = isDebug ? 1000 : 10;

const debug = isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(LISTA)', ++i, ...args);

/* eslint-disable react-hooks/exhaustive-deps */
const Lista = ({ history }) => {
	const store = useStore();
	const dispatch = useDispatch();
	const { search, state } = useLocation();

	const [filtro, setFiltro] = useState();
	const [filtroOrgaoId, setFiltroOrgaoId] = useState();
	const [, setFiltroOrgao] = useLocalStorage('filtroOrgao');

	const { createMessage } = useMessages();

	const filtroRef = useRef();
	const novoRequerimentoRef = useRef();
	const modalRef = useRef();
	const outsideModal = useClickOutsideContainer(modalRef, true);
	const outside = outsideModal;

	const [searching, setSearching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [newReq, setNewReq] = useState(false);
	const [timer, setTimer] = useState(null);
	const [formularioTerm, setFormularioTerm] = useState('');
	const [formularioSelected, setFormularioSelected] = useState(null);
	const [tipoFormularioList, setTipoFormularioList] = useState([]);
	const [showPopupDupl, setShowPopupDupl] = useState(false);
	const [formDataDuplicar, setFormDataDuplicar] = useState(null);
	const [showPopupRemove, setShowPopupRemove] = useState(false);
	const [formDataRemover, setFormDataRemover] = useState(null);
	const [caseRemover, setCaseRemover] = useState(null);
	const [page, setPage] = useState(0);

	const [filtroEmAndamento, setFiltroEmAndamento] = useState(false);
	const [filtroConcluidos, setFiltroConcluidos] = useState(false);
	const [filtroStatusProcesso, setFiltroStatusProcesso] = useState([]);

	const [alterarOrdenacaoPadrao, setAlterarOrdenacaoPadrao] = useState(false);

	const [optionSearchTerm, setOptionSearchTerm] = useState(null);

	const [formsData, setFormsData] = useState([]);
	const [loadingPendingTasks, setLoadingPendingTasks] = useState(false);

	let [getOrgaos, { data: dataOrgaos }] = useLazyQuery(
		gql`
			query orgaoList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoOrgaoList(term: $term, skip: $skip, limit: $limit) {
					id
					descricao
				}
				count: LicenciamentoOrgaoCount(term: $term)
			}
		`,
		{
			variables: { limit: PAGE_SIZE },
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		const queryParam = new URLSearchParams(search);
		// pega o valor do filtro de órgão da url ou da estado passado pelo link no Breadcrumb
		const filter = queryParam.get('orgao') || state?.orgao;
		if (filter) {
			getOrgaos({ variables: { term: filter } });
		}
	}, []);

	useEffect(() => {
		const queryParam = new URLSearchParams(search);
		// pega o valor do filtro de órgão da url ou da estado passado pelo link no Breadcrumb
		const filter = queryParam.get('orgao') || state?.orgao;
		if (dataOrgaos && filter) {
			const orgao = dataOrgaos.list.find(o => o.descricao.toLowerCase() === filter.toLowerCase());
			if (orgao) {
				if (['SMDE', 'SMAMS', 'SMAMUS'].includes(filter.toUpperCase())) {
					dispatch(replaceRoute('/list'));
					document.title = 'Licenciamento';
				} else {
					setFiltroOrgaoId(orgao.id);
					setFiltroOrgao(filter);
					store.updateFiltros({ filtroOrgaoId: orgao.id, filtroOrgao: filter });
					if (!queryParam.get('orgao')) {
						dispatch(replaceRoute(`/list?orgao=${state.orgao}`));
					}
				}
			} else {
				dispatch(replaceRoute('/list'));
				document.title = 'Licenciamento';
			}
		} else {
			setFiltroOrgaoId(null);
			setFiltroOrgao(null);
		}
	}, [dataOrgaos]);

	const {
		loading: loadingFormularios,
		error: errorFormularios,
		data: dataFormularios
	} = useQuery(
		gql`
			query formularioList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoFormularioList(term: $term, skip: $skip, limit: $limit) {
					id
					nome
					nomesAlternativos
					idOrgao
				}
			}
		`,
		{
			variables: {
				term: JSON.stringify({ $or: [{ disabled: { $exists: false } }, { disabled: false }] }),
				limit: 2000
			},
			fetchPolicy: 'network-only'
		}
	);
	const [removeLicenciamentoFormData, { data: dataMutation, error: errorMutation, loading: loadingMutation }] =
		useMutation(
			gql`
				mutation removeLicenciamentoFormData($id: String!) {
					removeLicenciamentoFormData(id: $id)
				}
			`
		);

	const {
		loading: loadingFormsData,
		error: errorFormsData,
		data: dataFormsData,
		refetch: refetchFormsData
	} = useQuery(
		gql`
			query formsData($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoFormDataList(term: $term, skip: $skip, limit: $limit) {
					id
					idFormulario
					formulario {
						id
						idTipoFormulario
						nome
						linkPesquisaSatisfacao
						processVersion
						idOrgao
						labelEmAndamento
						labelEmComplementacao
						labelDeferido
						labelIndeferido
						labelRecusado
						labelExpirado
						labelAnulado
					}
					dadosFormulario
					data
					userData
					documentos
					usuario
					procedimentoFormatadoSei
					urlConsultaPublicaSei
					dataComparecimento
					createdAt
					resultado
					expirado
					bpmProcessInstance
					dataAsString
					motivoIndeferimento
					extraInfo
					dataCriacaoSei
					rascunhoDocsComplementacao
				}
				count: LicenciamentoFormDataCount(term: $term)
			}
		`,
		{
			variables: { term: 'não obtem nada', skip: 0, limit: PAGE_SIZE }
		}
	);

	useEffect(() => {
		if (dataFormularios) {
			debugLog('dataFormularios', dataFormularios);
			setTipoFormularioList(sortCadastros(get(dataFormularios, 'list'), 'nome'));
		}
	}, [dataFormularios]);

	useEffect(() => {
		if (dataFormsData && user) {
			setFormsData(dataFormsData.list);

			const lista = dataFormsData.list.filter(fd => fd.bpmProcessInstance && !fd.resutlado);
			setLoadingPendingTasks(true);
			Promise.all(
				lista.map(async fd => {
					const url = `bpm/task/pending/${user.username}?includeAssigned=true&vars=taskOrigem&vars=versaoAtual&vars=retornoApi&f=caseId=${fd.bpmProcessInstance}&d=processId&d=rootContainerId`;
					const result = await accessApi(url, '');
					return result.data;
				})
			).then(tasksAux => {
				const tasks = flatMap(tasksAux, t => t);
				let formData = null;
				tasks.forEach(t => {
					formData = lista.find(
						fd =>
							// console.log(fd.bpmProcessInstance, t, fd.bpmProcessInstance === t.caseId);
							fd.bpmProcessInstance === t.caseId
					);
					if (formData) {
						formData.pendingBpmTasks = tasks;
					}
				});
				const formsDataAux = dataFormsData.list.map(fd => (fd.id === formData?.id ? formData : fd));
				setFormsData(formsDataAux);
				setLoadingPendingTasks(false);
			});
		}
	}, [dataFormsData, user]);

	useEffect(() => {
		setTimeout(async () => {
			const user = await obtainUser();
			setUser(user);
			dispatch(actions.setKcUser(user));
		});
	}, [tipoFormularioList]);

	const obtainUser = async () => {
		if (store) {
			if (user?.name) {
				return user;
			} else {
				await store.kc.loadUserInfo();
				await store.kc.loadUserProfile();
				const userInfo = Object.assign({}, store.kc.userInfo, store.kc.profile);
				return userInfo;
			}
		}
		return true;
	};

	const updateFiltro = filtro => {
		setFiltro(filtro);
		dispatch({ type: 'salvar-filtro-pesquisa', payload: filtro });
	};

	const cancelaNovoRequerimento = () => {
		if (newReq) {
			setFormularioSelected(undefined);
			setNewReq(false);
		}
	};

	useKeypress('Escape', cancelaNovoRequerimento);

	useEffect(() => {
		if (outside) {
			cancelaNovoRequerimento();
		}
	}, [outside]);

	const getOrgaoForms = async () => {
		try {
			const queryForms = gql`
				query queryForms($term: String, $skip: Int, $limit: Int) {
					list: LicenciamentoFormularioList(term: $term, skip: $skip, limit: $limit) {
						id
					}
				}
			`;
			const filtro = { idOrgao: filtroOrgaoId };
			const variables = {
				term: JSON.stringify(filtro),
				limit: 2000
			};
			const {
				data: { list }
			} = await apolloClient.query({
				query: queryForms,
				variables,
				fetchPolicy: 'network-only'
			});
			return (list || []).map(i => i.id);
		} catch (e) {
			console.error('erro no servidor', e);
		}
	};

	useEffect(() => {
		if (timer) {
			clearTimeout(timer);
		}
		setSearching(true);

		const updateList = termoFormularioId =>
			setTimer(
				setTimeout(async () => {
					setSearching(false);
					if (user) {
						let termo = {};
						termo = { 'usuario.email': user.email };
						if (size(filtro) > 0) {
							termo.term = filtro;
						}

						if (size(termoFormularioId) > 0) {
							termo = Object.assign(termo || {}, termoFormularioId);
						}

						if (alterarOrdenacaoPadrao) {
							termo.sort = { createdAt: -1 };
						}

						if (filtroConcluidos) {
							termo = Object.assign(termo, { $or: [{ resultado: { $exists: true } }, { expirado: true }] });
						}
						if (filtroEmAndamento) {
							termo = Object.assign(termo, {
								$and: [
									{ resultado: { $exists: false } },
									{ expirado: { $exists: false } },
									{ procedimentoFormatadoSei: { $exists: true } }
								]
							});
						}
						if (size(filtroStatusProcesso) > 0) {
							let filtroStatus = Object.assign(termo, { $or: [] });
							let aux = [];
							if (filtroStatusProcesso.some(i => i.codigo === 'rascunho')) {
								aux = [...filtroStatus.$or];
								aux.push({ procedimentoFormatadoSei: { $exists: false } });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'complementacao')) {
								aux = [...filtroStatus.$or];
								aux.push({
									$and: [
										{ procedimentoFormatadoSei: { $exists: true } },
										{ resultado: null },
										{
											$or: [
												{
													documentos: {
														$elemMatch: {
															invalido: { $eq: true },
															substituido: { $ne: true }
														}
													}
												},
												{
													documentos: {
														$elemMatch: {
															descricaoOutroDocumento: { $ne: null },
															documentoFormatadoSei: null
														}
													}
												},
												{
													extraInfo: {
														$elemMatch: {
															pergunta: { $exists: true },
															resposta: { $exists: false }
														}
													}
												}
											]
										},
										{ expirado: { $ne: true } },
										{ rascunhoDocsComplementacao: { $ne: true } }
									]
								});
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'deferido')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: 'deferido' });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'indeferido')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: 'indeferido' });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'expirado')) {
								aux = [...filtroStatus.$or];
								aux.push({ expirado: true });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'emAnalise')) {
								aux = [...filtroStatus.$or];
								aux.push({
									$and: [
										{ procedimentoFormatadoSei: { $exists: true } },
										{ resultado: null },
										{
											$and: [
												{
													documentos: {
														$not: {
															$elemMatch: {
																invalido: { $eq: true },
																substituido: { $ne: true }
															}
														}
													}
												},
												{
													documentos: {
														$not: {
															$elemMatch: {
																descricaoOutroDocumento: { $ne: null },
																documentoFormatadoSei: null
															}
														}
													}
												},
												{
													extraInfo: {
														$not: {
															$elemMatch: {
																pergunta: { $exists: true },
																resposta: { $exists: false }
															}
														}
													}
												}
											]
										},
										{
											$or: [
												{
													$and: [
														{
															$or: [
																{ 'dadosFormulario.idTipoFormulario': 'licenca-expressa' },
																{ 'dadosFormulario.idTipoFormulario': 'certidao' }
															]
														},
														{
															'data.dataCriacaoEtapa': { $exists: true },
															'data.documentacaoMarcadaOk': null
														}
													]
												},
												{
													$and: [
														{ 'dadosFormulario.idTipoFormulario': { $ne: 'licenca-expressa' } },
														{ 'dadosFormulario.idTipoFormulario': { $ne: 'certidao' } }
													]
												}
											]
										}
									]
								});
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'recusado')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: 'recusado' });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'confirmado')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: 'confirmado' });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'naoconfirmado')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: 'naoconfirmado' });
								aux.push({ resultado: 'naoConfirmado' });
								filtroStatus.$or = aux;
							}
							if (filtroStatusProcesso.some(i => i.codigo === 'concluido')) {
								aux = [...filtroStatus.$or];
								aux.push({ resultado: { $exists: 1 } });
								aux.push({ expirado: true });
								filtroStatus.$or = aux;
							}
						}

						if (Array.isArray(termo.$or) && termo.$or.length === 0) {
							delete termo.$or;
						}

						const variables = {
							term: JSON.stringify(termo),
							skip: page * PAGE_SIZE,
							limit: PAGE_SIZE
						};
						refetchFormsData && refetchFormsData(variables);
					}
				}, 100)
			);

		const busca = async () => {
			let termoFormularioId = null;
			const orgaosForm = await getOrgaoForms();
			if (size(orgaosForm) > 0) {
				termoFormularioId = { idFormulario: { $in: orgaosForm } };
			}
			return termoFormularioId;
		};

		if (size(filtroOrgaoId) > 0) {
			busca().then(termoFormularioId => {
				updateList(termoFormularioId);
			});
		} else {
			updateList();
		}
	}, [
		filtro,
		filtroOrgaoId,
		user,
		page,
		filtroConcluidos,
		filtroEmAndamento,
		filtroStatusProcesso,
		alterarOrdenacaoPadrao
	]);

	useEffect(() => {
		if (filtroRef && filtroRef.current) {
			filtroRef.current.focus();
		}
	}, [loadingFormsData]);

	useEffect(() => {
		if (dataMutation) {
			setLoading(false);
			refetchFormsData();
		}
	}, [dataMutation]);

	useEffect(() => {
		if (filtroRef.current) {
			if (newReq) {
				filtroRef.current.disabled = true;
			} else {
				filtroRef.current.disabled = false;
			}
		}
	}, [newReq]);

	// const algumDocumentoInvalido = documentos => documentos.reduce((acc, fd) => acc || fd.invalido, false);

	const excluirProcessoRascunho = id => {
		setLoading(true);
		removeLicenciamentoFormData({ variables: { id } });
	};

	const isLoading = () => loadingFormularios || loadingFormsData || loadingMutation || loading;

	useEffect(() => {
		if (errorFormularios) {
			setLoading(false);
			console.error('Erro na query de tipos de formulários: ', errorFormularios);
			history.push('/serverUnavailable');
		}
	}, [errorFormularios]);

	useEffect(() => {
		if (errorFormsData) {
			setLoading(false);
			console.error('Erro na query de formsData: ', errorFormsData);
			history.push('/serverUnavailable');
		}
	}, [errorFormsData]);

	useEffect(() => {
		if (errorMutation) {
			setLoading(false);
			createMessage(`Erro na exclusão do formData: ${errorMutation}`, 5);
		}
	}, [errorMutation]);

	const setNovoRequerimentoFocus = () => {
		if (novoRequerimentoRef.current) {
			novoRequerimentoRef.current.focus();
		}
	};

	const duplicar = () => {
		dispatch(actions.duplicarRequerimento({ idFormData: formDataDuplicar, user }));
		cancelarDuplicacao();
	};

	const confirmarDuplicacao = (e, idFormData) => {
		e.stopPropagation();
		e.preventDefault();
		setFormDataDuplicar(idFormData);
		setShowPopupDupl(true);
	};

	const cancelarDuplicacao = () => {
		setFormDataDuplicar(null);
		setShowPopupDupl(false);
	};

	const removeCaseFormData = () => {
		if (caseRemover) {
			accessApi(`bpm/processInstance/${caseRemover}`, false, { method: 'DELETE' })
				.then(() => {
					excluirProcessoRascunho(formDataRemover);
					cancelarRemocao();
				})
				.catch(err => {
					console.error(err);
					cancelarRemocao();
				});
		} else {
			excluirProcessoRascunho(formDataRemover);
			cancelarRemocao();
		}
	};

	const confirmarRemocao = (e, idFormData, caseId) => {
		e.stopPropagation();
		e.preventDefault();
		setFormDataRemover(idFormData);
		setCaseRemover(caseId);
		setShowPopupRemove(true);
	};

	const cancelarRemocao = () => {
		setFormDataDuplicar(null);
		setCaseRemover(null);
		setShowPopupRemove(false);
	};

	// se já tem id não tem formData.usuario quer dizer que acabou de criar o formData,
	// então ainda é o mesmo usuário, apenas não recarregou o formData criado
	// portanto deve ser considerado o mesmo email, abaixo

	return (
		<>
			<div className="container">
				<div className="subhead">
					<Breadcrumbs items={['list_placeholder']} />
				</div>
				<h1 className="page-header">Meus processos</h1>

				<section className="content" style={{ marginTop: '32px' }}>
					<div className="row">
						<div className="col-xs-12 col-lg-10 offset-lg-1">
							<div className="toolbar-grid">
								<div className="buttons">
									<button
										type="button"
										className="btn-primary"
										style={{ marginTop: '-3px' }}
										onClick={() => {
											setNewReq(true);
											setTimeout(() => {
												setNovoRequerimentoFocus();
											}, 100);
										}}
									>
										Novo Processo
									</button>
								</div>
							</div>
							<br />
							<div className={'linha-filtros'}>
								<div className={'row'}>
									<div className={'col-12 titulo-filtros-processos'}>Filtrar por:</div>
								</div>
								<div className={'row'}>
									<div className={'filtro-processos-concluidos'}>
										<button
											type="button"
											className={'btn btn-secondary btn-filtros'}
											onClick={() => {
												setFiltroEmAndamento(!filtroEmAndamento);
												setFiltroConcluidos(false);
												setFiltroStatusProcesso([]);
											}}
										>
											{filtroEmAndamento && <i className="fa fa-check" style={{ marginRight: '8px' }} />}
											Processos em andamento
										</button>
									</div>
									<div className={'filtro-processos-andamento'}>
										<button
											type="button"
											className={'btn btn-secondary btn-filtros'}
											onClick={() => {
												setFiltroConcluidos(!filtroConcluidos);
												setFiltroEmAndamento(false);
												setFiltroStatusProcesso([]);
											}}
										>
											{filtroConcluidos && <i className="fa fa-check" style={{ marginRight: '8px' }} />}
											Processos concluídos
										</button>
									</div>
									<div className={'input-status-processos'}>
										<Selecao
											detailInnerClassName={'title-with-button-white-space-normal'}
											multiple={true}
											selected={filtroStatusProcesso || ''}
											label={'Status'}
											detailDescricao={'descricao'}
											autoShowList={true}
											searchTerm={optionSearchTerm}
											searchList={(STATUS_DOS_PROCESSOS.filter(f => !f.hidden) || []).filter(
												o => JSON.stringify(o).indexOf(optionSearchTerm) > -1
											)}
											searchTermMinLength={0}
											errorList={false}
											onChangeSearchTerm={e => setOptionSearchTerm(e.target.value)}
											onBlurSearchTerm={() => false}
											onSelectItem={item => () => {
												let aux = [...filtroStatusProcesso];

												if (!aux.some(i => i.codigo === item.codigo)) {
													aux.push(item);
												}
												setFiltroStatusProcesso(aux);
											}}
											onUnselect={item => () => {
												let aux = [...filtroStatusProcesso].filter(i => i.codigo !== item.codigo);
												setFiltroStatusProcesso(aux);
											}}
											loading={false}
											required={true}
											detailModifier={prop => prop}
											placeholder="Status do processo"
											disabled={false}
											readOnly={false}
											isShowSelecionadosEmbaixo={true}
										/>
									</div>
									<div>
										<button
											className={'btn-limpar-filtros'}
											type="button"
											onClick={() => {
												setFiltroConcluidos(false);
												setFiltroEmAndamento(false);
												setFiltroStatusProcesso([]);
												updateFiltro('');
											}}
										>
											Limpar filtros
										</button>
									</div>
								</div>
							</div>
							{(size(formsData) > 0 || filtro) && (
								<>
									<div className={'row'} style={{ marginTop: '4px' }}>
										<div className={'col-3 titulo-filtros-processos'}>
											Ordernar por:
											<div>
												<label
													style={{ cursor: 'pointer' }}
													onClick={() => setAlterarOrdenacaoPadrao(!alterarOrdenacaoPadrao)}
												>
													<i
														className={`fa fa-chevron${alterarOrdenacaoPadrao ? '-up' : '-down'}`}
														style={{ marginRight: '8px' }}
													/>
													Data de criação
												</label>
											</div>
										</div>
										<div className="filter col-9">
											<input
												ref={filtroRef}
												type="text"
												className="form-control"
												placeholder="Digite a identificação do seu processo."
												value={filtro || ''}
												onChange={e => updateFiltro(e.target.value)}
											/>
											<i className="fa fa-search refresh-field" />
										</div>
									</div>
								</>
							)}
							{user ? (
								user.email ? (
									<>
										{isLoading() || searching ? (
											<div className="loader">carregando processos do usuário...</div>
										) : (
											<ul className="list-interactive">
												{dataFormsData ? (
													size(formsData) > 0 ? (
														formsData.map(fd => {
															const qtdInvalidos = (fd.documentos || []).reduce(
																(acc, d) => (acc += (d || {}).invalido && !(d || {}).substituido ? 1 : 0),
																0
															);
															const qtdNovos = (fd.documentos || []).reduce(
																(acc, d) =>
																	(acc += (d || {}).descricaoOutroDocumento && !(d || {}).idDocumentoSei ? 1 : 0),
																0
															);
															const qtdPerguntasNaoRespondidas = (fd.extraInfo || []).reduce(
																(acc, info) => (acc += info.pergunta && info.naoRespondida ? 1 : 0),
																0
															);
															return (
																<li className="list-item" key={fd.id}>
																	<Link to={{ pathname: `/${fd.idFormulario}/${fd.id}` }} className="list-item-link">
																		<div className="list-item-block vertical">
																			<div className="list-item-title">
																				<Truncate
																					length={isDebug ? 60 : 80}
																					text={get(fd, 'data.identificacao') || get(fd, 'formulario.nome')}
																				/>
																				{isDebug && (
																					<>
																						{fd.bpmProcessInstance && <> (Case: {fd.bpmProcessInstance}) </>}
																						<button
																							type="button"
																							className="btn btn-link"
																							onClick={e => confirmarDuplicacao(e, fd.id)}
																						>
																							duplicar
																						</button>
																						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																						{fd.procedimentoFormatadoSei && (
																							<button
																								type="button"
																								onClick={e => {
																									e.stopPropagation();
																									e.preventDefault();
																									window.open(obtainUrlAdmin(fd.formulario.id, fd.id), '_blank');
																								}}
																								className="btn btn-link"
																							>
																								admin
																							</button>
																						)}
																						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																						{fd.procedimentoFormatadoSei && (
																							<button
																								type="button"
																								onClick={e => confirmarRemocao(e, fd.id, fd.bpmProcessInstance)}
																								className="btn btn-link"
																							>
																								excluir
																							</button>
																						)}
																					</>
																				)}
																				{fd.bpmProcessInstance &&
																					[/^aprovacao-projetos$/i].reduce(
																						(acc, pattern) =>
																							acc || pattern.test(get(fd, 'dadosFormulario.idTipoFormulario')),
																						false
																					) && (
																						<>
																							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																							<button
																								type="button"
																								onClick={e => {
																									e.stopPropagation();
																									e.preventDefault();
																									window.open(
																										obtainUrlAdmin(isDebug ? 'tracker' : 'consultapublica', fd.id),
																										'_blank'
																									);
																								}}
																								className="btn btn-link"
																							>
																								{isDebug ? 'tracker' : 'Acompanhar processo'}
																							</button>
																						</>
																					)}
																			</div>
																			<div>
																				<span className="list-item-description">
																					{get(fd, 'formulario.nome') || fd.idFormulario}
																					{isDebug && fd.bpmProcessInstance && (
																						<span className="debug-message">{` (v ${get(
																							fd,
																							'formulario.processVersion'
																						)})`}</span>
																					)}
																				</span>
																				{fd.createdAt ? (
																					<span className="list-item-date">
																						Criado em {moment(fd.createdAt).format('DD/MM/YYYY')}
																					</span>
																				) : null}
																				{fd.dataComparecimento ? (
																					<span className="list-item-date">
																						Prazo até{' '}
																						{`${moment(fd.dataComparecimento)
																							.subtract(1, 'days') // remove 1 dia da exibicao do prazo, OP #58863
																							.format('DD/MM/YYYY')} às 23:59`}
																					</span>
																				) : null}
																				{fd.procedimentoFormatadoSei ? (
																					<span
																						className="processo-link"
																						onClick={e => {
																							e.stopPropagation();
																							e.preventDefault();
																							window.open(fd.urlConsultaPublicaSei);
																						}}
																					>
																						{fd.procedimentoFormatadoSei}
																					</span>
																				) : ['-base', 'base-'].reduce(
																						(acc, base) => acc || fd.id.indexOf(base) > -1,
																						false
																				  ) ? (
																					<span className="list-item-status">Base para testes&nbsp;</span>
																				) : (
																					<>
																						<span className="list-item-status">Rascunho&nbsp;</span>
																						<button
																							type="button"
																							onClick={e => {
																								e.stopPropagation();
																								e.preventDefault();
																								return window.confirm(
																									'Você deseja realmente excluir esse processo em rascunho?'
																								)
																									? excluirProcessoRascunho(fd.id)
																									: null;
																							}}
																							className="btn btn-icon"
																							aria-label="Excluir"
																						>
																							<i className="fa fa-trash" aria-hidden="true" />
																						</button>
																					</>
																				)}
																			</div>
																			{fd?.formulario && (
																				<EstadoRequerimento
																					formulario={fd.formulario}
																					formData={fd}
																					qtdInvalidos={qtdInvalidos}
																					qtdNovos={qtdNovos}
																					qtdPerguntasNaoRespondidas={qtdPerguntasNaoRespondidas}
																					lista={true}
																					isPending={fd.bpmProcessInstance && !fd.resultado && loadingPendingTasks}
																				/>
																			)}
																		</div>
																	</Link>
																</li>
															);
														})
													) : (
														<li className="list-item" style={{ backgroundColor: 'transparent', cursor: 'default' }}>
															<div style={{ padding: '16px 0' }}>{`${
																filtro
																	? 'Nenhum processo encontrado com esse filtro'
																	: 'Ainda não há nenhum processo cadastrado.'
															}`}</div>
														</li>
													)
												) : (
													<div className="loader">carregando processos do usuário...</div>
												)}

												{(!isLoading() || searching) && (
													<PaginationV2
														totalItems={dataFormsData.count}
														pageSize={PAGE_SIZE}
														page={page}
														setPage={setPage}
													/>
												)}
											</ul>
										)}
									</>
								) : (
									<div>Erro ao carregar lista de processos do usuário. Tente novamente.</div>
								)
							) : (
								<Loader msg="Carregando dados do usuário" />
							)}
						</div>
					</div>
				</section>
			</div>
			{newReq && (
				// TODO Componentize this modal
				<div
					className="modal"
					style={{
						backgroundColor: 'rgba(0,0,0,.5)',
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					role="dialog"
				>
					<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }} ref={modalRef}>
						<div className="modal-content">
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label htmlFor="" className="control-label">
											Tipo de requerimento
										</label>

										<Selecao
											searchTermRef={novoRequerimentoRef}
											className={'form-control'}
											multiple={false}
											selected={formularioSelected}
											label={'Tipo de formulário'}
											detailCodigo={''}
											detailDescricao={'nome'}
											autoShowList={false}
											searchTerm={formularioTerm}
											searchList={tipoFormularioList
												.filter(item => (filtroOrgaoId ? (item?.idOrgao || []).includes(filtroOrgaoId) : true))
												.filter(item => filterByTerm(item, formularioTerm, ['nome', 'nomesAlternativos']))}
											searchTermMinLength={0}
											errorList={false}
											onChangeSearchTerm={event => setFormularioTerm(event.target.value)}
											onBlurSearchTerm={() => false}
											onSelectItem={item => () => setFormularioSelected(item)}
											onUnselect={() => () => setFormularioSelected(undefined)}
											loading={false}
											required={true}
											placeholder="Selecione o tipo de formulário"
											detailModifier={e => e}
										>
											{item => <DetalhesTipoProcesso item={item} />}
										</Selecao>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn-secondary" onClick={() => cancelaNovoRequerimento()}>
									Cancelar
								</button>
								{formularioSelected && (
									<button
										type="button"
										className="btn-primary"
										onClick={() => history.push(`/${formularioSelected.id}/new`)}
									>
										Continuar
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{showPopupDupl && (
				// TODO Componentize this modal
				<div
					className="modal"
					style={{
						backgroundColor: 'rgba(0,0,0,.5)',
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					role="dialog"
				>
					<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
						<div className="modal-content" style={{ padding: '20px' }}>
							<h1>Duplicar Requerimento</h1>
							<br />
							<div className="modal-body">
								<span>Isto serve para facilitar o preenchimento de um novo requerimento semelhante.</span>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn-secondary" onClick={cancelarDuplicacao}>
									Cancelar
								</button>
								<button type="button" className="btn-primary" onClick={duplicar}>
									Confirmar duplicação
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			{showPopupRemove && (
				// TODO Componentize this modal
				<div
					className="modal"
					style={{
						backgroundColor: 'rgba(0,0,0,.5)',
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					role="dialog"
				>
					<div className="modal-dialog" role="document" style={{ width: '600px', maxWidth: '90vw' }}>
						<div className="modal-content" style={{ padding: '20px' }}>
							<h1>Remover Requerimento</h1>
							<br />
							<div className="modal-body">
								<span>Remove o formData e o case do Bonita, se houver.</span>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn-secondary" onClick={cancelarRemocao}>
									Cancelar
								</button>
								<button type="button" className="btn-primary" onClick={removeCaseFormData}>
									Confirmar Remoção
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
Lista.displayName = 'Lista';
Lista.contextTypes = {
	store: PropTypes.object
};
Lista.propTypes = {
	history: PropTypes.any
};
const withReducer = injectReducer({ key: 'licenciamento', reducer });
const withSaga = injectSaga({ saga });
export default compose(withRouter, withSaga, withReducer)(Lista);

function filterByTerm(item, term, properties) {
	properties = Array.isArray(properties) ? properties : [properties];
	return properties.reduce((acc, property) => {
		if (acc) return true;
		const valor = get(item, property);
		const valores = Array.isArray(valor) ? valor : [valor];
		return (
			acc ||
			valores
				.filter(i => !!i)
				.reduce(
					(acc2, i) => acc2 || removeDiacritics(i).toLowerCase().indexOf(removeDiacritics(term).toLowerCase()) > -1,
					false
				)
		);
	}, false);
}

const DetalhesTipoProcesso = ({ item }) => (
	<ul>
		{(item.nomesAlternativos || []).map((nome, index) => (
			<li key={index}>{nome}</li>
		))}
	</ul>
);
DetalhesTipoProcesso.propTypes = {
	item: PropTypes.object
};
DetalhesTipoProcesso.displayName = 'DetalhesTipoProcesso';
