import React, { memo, useCallback } from 'react';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { isImmutable } from 'immutable';
import { concat, differenceBy, get, maxBy, set, size } from 'lodash';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import Modal from 'components/Modal';
import Selecao from 'components/Selecao';

import DocumentoUpload from 'containers/Form/aba-documentos/DocumentoUpload';
import actions from 'containers/Form/actions';
import { TextField } from 'containers/Form/metadata-template/fields';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import useErrors from 'custom-hooks/useErrors';

import { getUpdatedToken, isDebug } from 'utils/tools';

import { METAVISTORIA } from './index';
import { getTabelaCondicionantes } from './TabelaCondicionantes';

export function InformacoesCondicionantes({ data = {}, onChange, salvar }) {
	const [formulario, setFormulario] = useState(null);
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	const { idForm: idFormulario, id: idFormData } = useParams();
	const dispatch = useDispatch();
	const [errors, setErrors] = useErrors();
	const [condicionantes, setCondicionantes] = useState([]);
	const vsSalvar = useCallback(salvar, [salvar]);

	const { condicionantesVistoria = {} } = data;

	const localChange = changes => {
		let { name, value } = changes;
		// handle fields
		if (name === 'errors') {
			return;
		}
		// fix radio group name do tramitar
		if (name.startsWith(METAVISTORIA.tramiteCondicionante.name)) {
			const indexDash = name.lastIndexOf('_');
			name = name.substring(indexDash + 1);
		}
		// old control value
		const oldControl = get(condicionantesVistoria, [name], {});
		// limpa o processo sei ao mudar o tramite
		if (value.value === 'nao') {
			delete oldControl[METAVISTORIA.tramiteProcessoSei.name];
			delete oldControl[METAVISTORIA.tramiteCondicionante.name];
		}
		// novo valor atualizado
		const cond = condicionantes.find(c => c.codigo === name);
		const tempVal = {
			[name]: {
				...oldControl,
				...cond,
				...value
			}
		};
		const newvalue = { ...condicionantesVistoria, ...tempVal };
		onChange && onChange({ name: METAVISTORIA.condicionantesVistoria.name, value: newvalue });
	};

	const optionsTramite = impedirTramitacaoDurante => {
		let options = [{ codigo: 'tramitou_anterior_atendido', descricao: 'Tramitou anteriormente e já foi atendido' }];
		if (!impedirTramitacaoDurante) {
			options.push({ codigo: 'tramitar_durante_solicitacao', descricao: 'Tramitar durante esta solicitação' });
		}
		return options;
	};

	const removeFile = useCallback(
		documento => {
			dispatch(actions.removeFile({ ...documento, idFormData }));
			dispatch(actions.retiraDocumento({ idFormData, idDocumento: documento.id }));
		},
		[dispatch, idFormData]
	);

	const onDrop = useCallback(
		d => (accepted, rejected) => {
			if (size(rejected) === 0 && size(accepted) === 1) {
				const file = accepted[0];
				if (file.name.length > 100) {
					setErrors({ send: ['O nome do arquivo pode ter no máximo 100 caracteres.'] });
				} else if (accepted[0].size < 100000000) {
					setErrors({});
					getUpdatedToken().then(
						token => {
							const payload = {
								idFormulario,
								idFormData,
								id: d.id,
								idDocumento: d.idDocumento,
								tituloDocumento: d.tituloDocumento,
								obrigatorio: d.obrigatorio,
								extensao: d.extensao,
								ordem: d.ordem,
								docGroup: d.docGroup,
								file,
								token,
								complementadaEm: d.complementadaEm,
								owner: d.owner,
								condicionante: d.condicionante,
								isTramitarDurante: d.isTramitarDurante,
								fromSolicitante: d.fromSolicitante,
								refreshDocs: true // enviado para o saga fazer refresh nos docs do formData
							};
							dispatch(actions.uploadFile(payload));
						},
						error => {
							setErrors({ send: [error] });
						}
					);
					// forca salvar o arquivo como draf apos um upload
					vsSalvar && vsSalvar({ draft: true, closeAfterSave: false });
				} else {
					setErrors({ send: ['O tamanho máximo de upload é de 100MB por arquivo.'] });
				}
			} else if (size(rejected) + size(accepted) > 1) {
				setErrors({ send: ['Apenas 1 arquivo pode ser submetido em cada documento'] });
			} else {
				setErrors({
					send: [`Tipo de arquivo inválido. Selecione um arquivo do tipo ${(d.extensao || '').toUpperCase()}`]
				});
			}
		},
		[dispatch, idFormData, idFormulario, setErrors, vsSalvar]
	);

	const criarNovoDocumento = d => {
		let ordem = maxBy(get(formulario, 'documentos', []), 'ordem')?.ordem;
		const newDoc = {
			textoHelp: null,
			ordem: ordem + 100,
			extensao: get(d, 'extensao.id') === 'dwg' ? 'dwg' : 'pdf',
			obrigatorio: false,
			idDocumento: uuid(),
			id: uuid(),
			linkHelp: null,
			tituloDocumento: d.tituloDocumento,
			versao: 1,
			condicionante: d.condicionante,
			isTramitarDurante: d.isTramitarDurante,
			newDoc: true, // Para verificação de doc que é criado pelo usuário
			fromSolicitante: true // Para verificação de doc que é criado pelo usuário
		};

		dispatch(actions.addNewDocument(newDoc));
	};

	const possuiCondicionantes = data[METAVISTORIA.possuiCondicionantes.name] === 'sim';

	// se nao possui mais condicionantes remove todos os documentos
	if (possuiCondicionantes === false) {
		delete data[METAVISTORIA.condicionantesVistoria.name];
	} else {
		// pre preenche as conds para que sejam validadas (sao de preenchimento obrigatorio)
		if (condicionantes.length) {
			condicionantes.forEach(cond => {
				if (!data.condicionantesVistoria) {
					data.condicionantesVistoria = {};
				}
				if (!data.condicionantesVistoria[cond.codigo]) {
					data.condicionantesVistoria[cond.codigo] = cond;
				}
			});
		}
	}

	useEffect(() => {
		getTabelaCondicionantes().then(condicoes => {
			setCondicionantes(condicoes);
		});
	}, []);

	useEffect(() => {
		if (formularioImm) {
			const form = isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm;
			setFormulario(form);
		}
	}, [formularioImm]);

	return (
		<fieldset>
			<legend>Condicionantes</legend>
			<div className="pergunta">
				<RadioFieldLE
					name={METAVISTORIA.possuiCondicionantes.name}
					label={METAVISTORIA.possuiCondicionantes.label}
					required={METAVISTORIA.possuiCondicionantes.required}
					value={data[METAVISTORIA.possuiCondicionantes.name]}
					onChangeHandler={onChange}
					options={[
						{ codigo: 'sim', descricao: 'Sim' },
						{ codigo: 'nao', descricao: 'Não' }
					]}
				/>
			</div>
			{/* se possui condicionantes */}
			{possuiCondicionantes && (
				<div className="vistoria-form-list">
					{condicionantes.map((cv, index) => (
						<div className="pergunta" key={cv?.codigo || index}>
							{isDebug && cv.codigo}
							<RadioFieldLE
								name={cv?.codigo}
								label={cv?.descricao}
								options={[
									{ codigo: 'sim', descricao: 'Sim' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
								required={true}
								value={get(condicionantesVistoria, [cv.codigo, 'value'])}
								onChangeHandler={({ name, value }) => localChange({ name, value: { value } })}
							/>
							{get(condicionantesVistoria, [cv.codigo, 'value']) === 'sim' && (
								<div className="condicionante-resposta">
									<RadioFieldLE
										name={`${METAVISTORIA.tramiteCondicionante.name}_${cv?.codigo}`}
										label={METAVISTORIA.tramiteCondicionante.label}
										required={METAVISTORIA.tramiteCondicionante.required}
										value={get(condicionantesVistoria, [cv.codigo, METAVISTORIA.tramiteCondicionante.name])}
										options={optionsTramite(cv.impedirTramitacaoDurante)}
										onChangeHandler={({ name, value }) =>
											localChange({ name, value: { [METAVISTORIA.tramiteCondicionante.name]: value } })
										}
										disabled={
											get(condicionantesVistoria, [cv.codigo, 'codigo']) !== 'diretrizesPumaPgm' &&
											get(condicionantesVistoria, [cv.codigo, 'value'], 'nao') === 'nao'
										}
									/>
								</div>
							)}
							{/* se tramitou e foi atendido */}
							{get(condicionantesVistoria, [cv.codigo, METAVISTORIA.tramiteCondicionante.name]) ===
								'tramitou_anterior_atendido' && (
								<>
									<p className="col-md-12 text-info vistoria-condicionante-texto">
										Em caso de documentos do condicionante, anexar no campo abaixo
									</p>
									{/* slots do documento se atendido */}
									<CondicionanteDocumentos
										key={cv.codigo}
										condicionantesVistoria={condicionantesVistoria}
										condicionante={cv}
										formulario={formulario}
										onDrop={onDrop}
										removeFile={removeFile}
										condicionantes={condicionantes}
										errors={errors}
										criarNovoDocumento={criarNovoDocumento}
									/>
									{/* tramite numero do sei */}
									<div className="form-group col-md-12 vistoria-condicionante-numero-sei">
										<TextField
											name={cv?.codigo}
											label={METAVISTORIA.tramiteProcessoSei.label}
											value={get(condicionantesVistoria, [cv.codigo, METAVISTORIA.tramiteProcessoSei.name], '')}
											onChangeHandler={([valid]) =>
												localChange({
													name: valid.name,
													value: { [METAVISTORIA.tramiteProcessoSei.name]: valid.value }
												})
											}
										/>
									</div>
								</>
							)}
							{/* se tramitar durante */}
							{get(condicionantesVistoria, [cv.codigo, METAVISTORIA.tramiteCondicionante.name]) ===
								'tramitar_durante_solicitacao' && (
								<>
									<CondicionanteDocumentos
										key={cv.codigo}
										condicionantesVistoria={condicionantesVistoria}
										condicionante={cv}
										formulario={formulario}
										onDrop={onDrop}
										removeFile={removeFile}
										condicionantes={condicionantes}
										errors={errors}
										isTramitarDurante
										criarNovoDocumento={criarNovoDocumento}
									/>
								</>
							)}
						</div>
					))}
				</div>
			)}
		</fieldset>
	);
}
InformacoesCondicionantes.displayName = 'InformacoesCondicionantes';
InformacoesCondicionantes.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
	salvar: PropTypes.func
};

const CondicionanteDocumentos = memo(
	({
		condicionante,
		formulario,
		onDrop,
		removeFile,
		errors,
		filterDocs = d => !!d,
		isTramitarDurante,
		criarNovoDocumento
	}) => {
		const filterDocuments = useCallback(filterDocs, [filterDocs]);
		const [showAdd, setShowAdd] = useState(false);
		const [tempDoc, setTempDoc] = useState();

		const rascunhoDocsComplementacao = !!get(formulario, 'formData.rascunhoDocsComplementacao');

		const emComplementacao =
			size(get(formulario, 'formData.resultado')) === 0 &&
			!rascunhoDocsComplementacao &&
			!!get(formulario, 'formData.dataComparecimento') &&
			!get(formulario, 'formData.expirado');

		let maxOrdem = maxBy(get(formulario, 'documentos', []), 'ordem')?.ordem;

		const docsBaseFaltantes = differenceBy(
			get(condicionante, ['outrosDocumentos'], []),
			get(formulario, 'documentos', []).filter(d => d.condicionante === condicionante.codigo),
			'idDocumento'
		);
		const listdocumentos = concat(
			docsBaseFaltantes,
			get(formulario, 'documentos', []).filter(d => d.condicionante === condicionante.codigo)
		);

		const criarNovoDocCondicionante = () => {
			set(tempDoc, 'condicionante', condicionante.codigo);
			set(tempDoc, 'isTramitarDurante', isTramitarDurante);
			criarNovoDocumento && criarNovoDocumento(tempDoc);

			setShowAdd(false);
			setTempDoc(undefined);
		};

		return (
			<>
				{listdocumentos
					.filter(filterDocuments)
					.filter(d => !!d.isTramitarDurante === !!isTramitarDurante)
					.filter(d => !emComplementacao || (!d.invalido && !d.substituido))
					.map(doc => {
						// extra infos
						doc.condicionante = condicionante.codigo;
						doc.isTramitarDurante = isTramitarDurante;
						doc.ordem = ++maxOrdem;

						const emProcessamento = !doc.idDocumentoSei && !!doc.originalName;
						const arquivoGerado =
							!!doc.filename ||
							formulario.documentos.find(d => d.id === doc.id && d.filename) ||
							size(get(formulario, 'formData.resultado')) > 0;

						return (
							<div key={doc.id} className="form-group col-md-12">
								<label htmlFor={`inputFile${doc.idDocumento}`}>
									<span style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span>
											{doc.tituloDocumento || doc.tituloDocumentoAdmin}
											{doc.obrigatorio ? '*' : ''}
											{doc.versao && doc.versao !== 1 ? ` (v. ${doc.versao})` : ''}
										</span>
										<div className="d-flex align-items-center">
											{isDebug && (
												<span className="debug-message">
													({doc.idDocumento} - {doc.ordem})
												</span>
											)}
										</div>
									</span>
									{doc.descricaoDocumento && (
										<span style={{ fontSize: '1.0rem', color: 'grey' }}>{doc.descricaoDocumento}</span>
									)}
								</label>
								<DocumentoUpload
									key={doc.idDocumento}
									formData={formulario.formData}
									documento={doc}
									emProcessamento={emProcessamento}
									arquivoGerado={!!arquivoGerado}
									onDrop={onDrop}
									removeFile={removeFile}
									procedimentoFormatadoSei={formulario.formData.procedimentoFormatadoSei}
									usuarioDonoTask={true}
									emComplementacao={emComplementacao}
								/>
								{size(errors?.[doc.id]) > 0 && <ErrorMessages errorList={errors[doc.id]} />}
								{size(errors?.[doc.idDocumento]) > 0 && <ErrorMessages errorList={errors[doc.idDocumento]} />}
							</div>
						);
					})}
				<button type="button" className="ml-3 btn btn-secondary btn-sm" onClick={() => setShowAdd(true)}>
					<i className="fa fa-plus" style={{ fontSize: '18px' }} />
					Adicionar outro
				</button>
				{showAdd && (
					<Modal
						title="Adiciona novo documento"
						dismiss={() => setShowAdd(false)}
						submit={() => {
							criarNovoDocCondicionante();
						}}
						submitLabel="Adicionar"
					>
						<div className="form-group">
							<label>Título do documento</label>
							<input
								placeholder="Descrição do documento"
								className="form-control"
								name="titulo_documento"
								value={tempDoc?.tituloDocumento || ''}
								onChange={event => {
									const v = event.target.value;
									setTempDoc(doc => Object.assign({}, doc, { tituloDocumento: v }));
								}}
							/>
						</div>
						<div className="form-group">
							<label>Tipo de arquivo</label>
							<Selecao
								className={'form-control'}
								multiple={false}
								selected={tempDoc?.extensao || ''}
								label={'Extensão'}
								detailCodigo={''}
								detailDescricao={'desc'}
								autoShowList={true}
								searchTerm={''}
								searchList={[
									{ id: 'pdf', desc: 'PDF' },
									{ id: 'dwg', desc: 'DWG' }
								]}
								searchTermMinLength={0}
								errorList={false}
								onChangeSearchTerm={() => false}
								onBlurSearchTerm={() => false}
								onSelectItem={item => () => setTempDoc(doc => Object.assign({}, doc, { extensao: item }))}
								onUnselect={() => () => setTempDoc(doc => Object.assign({}, doc, { extensao: '' }))}
								loading={false}
								required={true}
								detailModifier={prop => prop}
								placeholder="Selecione o tipo de arquivo"
							/>
						</div>
					</Modal>
				)}
			</>
		);
	}
);

CondicionanteDocumentos.displayName = 'CondicionanteDocumentos';
CondicionanteDocumentos.propTypes = {
	condicionantesVistoria: PropTypes.object,
	condicionante: PropTypes.object,
	formulario: PropTypes.object,
	onDrop: PropTypes.func,
	condicionantes: PropTypes.array,
	errors: PropTypes.object,
	removeFile: PropTypes.func,
	filterDocs: PropTypes.func,
	isTramitarDurante: PropTypes.bool,
	criarNovoDocumento: PropTypes.func
};
