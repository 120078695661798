import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { ReactReduxContext } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import queryString from 'query-string';

import iconAgendamento from 'assets/images/agendamento-atendimento.png';
import iconCertificado from 'assets/images/certificado-sustentavel.png';
import iconExpedientes from 'assets/images/consulta-expedientes.png';
import iconHabitese from 'assets/images/consulta-habitese.png';
import iconLicencas from 'assets/images/licencas-na-hora.png';
import iconServicosUrbanismo from 'assets/images/servicos-urbanismo.png';
import iconValidacao from 'assets/images/validacao-documentos.png';
import imageCapaPortal from 'assets/images/voa_poa_capa_lic.jpg';

// import Breadcrumbs from 'components/Breadcrumbs';
// import Username from 'components/Username';

import { montaURL } from 'utils/tools';

class Home extends PureComponent {
	static displayName = 'Home';

	static contextType = ReactReduxContext;

	static propTypes = {
		history: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.logout = !!queryString.parse(this.props.history.location.search).logout;
	}

	componentDidMount() {
		if (this.logout && this.context && this.context.store && this.context.store.kc) {
			this.props.history.replace('/');
			this.context.store.kc.logout();
		}
	}

	thais = false;

	render() {
		return (
			<>
				<div className="bloco-imagem-titulo">
					<img className="img-capa-portal img-responsive" src={imageCapaPortal} alt="capa portal" />
					<div className="creditos-foto">Foto: Vinicius Zimmermann - Voa POA</div>
					<div className="container">
						<h1 className="titulo-principal-portal">
							<span>
								Portal de &nbsp;
								<br /> <b>&nbsp;LICENCIAMENTO</b>
							</span>
						</h1>
					</div>
				</div>
				<div className="fundo-icones-capa">
					<div className="container">
						{/* <div className="subhead">
						<Breadcrumbs items={[]} />
						{!this.logout && <Username />}
					</div> */}

						<section className="content">
							<div className="row primeira-linha-capa">
								<Link to="/list">
									<div className="item-icone-capa">
										<div className="icones-fundo-redondo">
											<img src={iconServicosUrbanismo} alt="Serviços urbanísticos e ambientais" />
										</div>
										<div className="texto-item-icone-capa">
											<h2>Serviços urbanísticos e ambientais</h2>
											<p>
												<strong>Aprovação de Projetos</strong>, <strong>Habite-se</strong>, <strong>Licenças</strong>,{' '}
												<strong>Certidões</strong> e mais de 200 serviços.
											</p>
											<span className="cta-item-icone">Acesse</span>
										</div>
									</div>
								</Link>
							</div>
							<div className="row segunda-linha-capa">
								<div className="item-1">
									<a href={`${montaURL('expedientes')}/consultapublica`}>
										<div className="item-icone-2a-linha-capa">
											<img src={iconExpedientes} alt="Consulta de expedientes" />
											<h2>Consulta de expedientes</h2>
										</div>
									</a>
								</div>
								<div className="item-2">
									<a href={'https://prefeitura.poa.br/habitese'}>
										<div className="item-icone-2a-linha-capa">
											<img className="consulta-habite-se" src={iconHabitese} alt="Consulta de Habite-se" />
											<h2 className="titulo-consulta-habite-se">Consulta de Habite-se</h2>
										</div>
									</a>
								</div>
								<div className="item-3">
									<a href={`${montaURL('agendamento')}`}>
										<div className="item-icone-2a-linha-capa">
											<img src={iconAgendamento} alt="Agendamento na prefeitura" />
											<h2>Agende seu atendimento</h2>
										</div>
									</a>
								</div>
								<div className="item-4">
									<Link to="/validacaoDocumentos">
										<div className="item-icone-2a-linha-capa">
											<img src={iconValidacao} alt="Validação de documentos" />
											<h2>Validação de documentos</h2>
										</div>
									</Link>
								</div>
								{this.thais && (
									<div className="item-5">
										<a href={`${montaURL('licencasnahora')}/`}>
											<div className="item-icone-2a-linha-capa">
												<img src={iconLicencas} alt="Licenças na hora" />
												<h2>Licenças na hora</h2>
											</div>
										</a>
									</div>
								)}
								<Link to="/list">
									<div className="item-icone-2a-linha-capa">
										<img src={iconCertificado} alt="Certificado em Sustentabilidade Ambiental" />
										<h2 style={{ width: '135px', marginLeft: '-15px' }}>Certificação em Sustentabilidade Ambiental</h2>
									</div>
								</Link>
							</div>
						</section>
					</div>
				</div>

				<div className="fundo-ajuda-capa">
					<div className="container">
						<section className="content">
							<p className="titulo-box-ajuda-capa">Precisa de ajuda?</p>
							<div className="itens-ajuda-capa">
								<p>
									<a
										target="_blank"
										href="https://prefeitura.poa.br/escritorio-de-licenciamento"
										rel="noopener noreferrer"
									>
										Escritório de Licenciamento
									</a>
									<span style={{ display: 'block' }}>
										Apresentação institucional, informações sobre serviços e comissões, materiais de apoio e legislação
									</span>
								</p>
								<p>
									<a
										target="_blank"
										href="https://prefeitura.poa.br/carta-de-servicos/busca?keys=licenciamento"
										rel="noopener noreferrer"
									>
										Carta de Serviços
									</a>
									<span style={{ display: 'block' }}>
										Lista com todos os serviços da SMAMUS, incluindo passo a passo de como solicitar
									</span>
								</p>
								<p>
									<a
										target="_blank"
										href="https://leismunicipais.com.br/prefeitura/rs/porto-alegre"
										rel="noopener noreferrer"
									>
										Legislação
									</a>
									<span style={{ display: 'block' }}>Plano Diretor, Decretos, Instruções Normativas e mais</span>
								</p>
								<p>
									<a target="_self" href="/faq" rel="noopener noreferrer">
										Perguntas Frequentes
									</a>
									<span style={{ display: 'block' }}>Principais dúvidas referentes à utilização do portal</span>
								</p>
							</div>
						</section>
					</div>
				</div>

				<footer className="rodape-capa">
					<div className="container container-rodape-capa">
						<div className="clearfix">
							<div className="endereco-rodape">
								<div>
									<img
										alt="Brasão da Prefeitura de Porto Alegre"
										className="brasao"
										src="https://prefeitura.poa.br/themes/pmpa/images/brasao-pmpa-branco.svg"
										onError={e => {
											e.target.src = 'https://prefeitura.poa.br/themes/pmpa/images/brasao-pmpa-branco.png';
											e.target.width = '60';
											// e.target.onError = null;
											return true;
										}}
									/>
									<div>
										<strong>SMAMUS - Secretaria Municipal do Meio Ambiente, Urbanismo e Sustentabilidade</strong>
									</div>
									<div>Escritório de Licenciamento</div>
									<div className="detalhe-endereco">
										Rua Luiz Voelcker, 55 - Bairro Auxiliadora. <br />
										Porto Alegre, RS. CEP 91330-190
									</div>
								</div>
							</div>
							<div className="contatos-rodape">
								<p>
									<i className="fa fa-phone" aria-hidden="true"></i>(51) 3289-7400
								</p>
								<p>
									<i className="fa fa-envelope" aria-hidden="true"></i>
									<a href="mailto:smams@portoalegre.rs.gov.br">escritoriolicenciamento@portoalegre.rs.gov.br</a>
								</p>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default withRouter(Home);
