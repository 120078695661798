import React from 'react';

import PropTypes from 'prop-types';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import useMessages from 'custom-hooks/useMessages';

import { format } from 'utils/tools';

const CopyButton = ({ texto, formato, title }) => {
	const { createMessage } = useMessages();
	return (
		<CopyToClipboard
			text={format(texto, formato)}
			onCopy={(text, result) => createMessage(`Número de expediente ${text}${result ? ' copiado' : 'não copiado'}.`, 5)}
		>
			<button
				type="button"
				className="btn"
				onClick={e => {
					e.preventDefault();
					e.target.blur();
				}}
			>
				<i className="fa fa-copy" aria-hidden="true" title={title} />
			</button>
		</CopyToClipboard>
	);
};
CopyButton.displayName = 'CopyButton';

CopyButton.propTypes = {
	texto: PropTypes.string,
	formato: PropTypes.string,
	title: PropTypes.string,
	createMessage: PropTypes.any
};

export default CopyButton;
