import React from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import moment from 'moment';

import { titleCase } from 'utils/tools';

import 'tippy.js/dist/tippy.css';

const ListItem = ({
	innerClassName,
	codigo,
	descricao,
	title,
	data,
	icon,
	iconStyle,
	iconTooltipText,
	modifier = titleCase,
	children,
	showHyphen = true
}) =>
	descricao || children ? (
		<span className={innerClassName ? innerClassName : ''} title={title}>
			{icon && iconTooltipText ? (
				<Tippy content={iconTooltipText} delay={100} animation="fade">
					<i className={`fa fa-${icon}`} style={iconStyle} aria-hidden="true" />
				</Tippy>
			) : icon ? (
				<i className={`fa fa-${icon}`} style={iconStyle} aria-hidden="true" />
			) : null}{' '}
			{codigo && (
				<span className="id">
					{codigo} {showHyphen ? '-' : ' '}
				</span>
			)}
			{modifier(descricao)}
			{!!children && children}
			{data && <span className="date"> ({moment(data).format('DD/MM/YYYY')})</span>}
		</span>
	) : (
		<strong>
			Para usar o componente ListItem, no mínimo, deve ser informada a descrição ou um componente como children
		</strong>
	);
ListItem.displayName = 'ListItem';
ListItem.propTypes = {
	innerClassName: PropTypes.string,
	codigo: PropTypes.string,
	descricao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	icon: PropTypes.string,
	iconStyle: PropTypes.object,
	iconTooltipText: PropTypes.string,
	modifier: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	showHyphen: PropTypes.bool
};

export default ListItem;
