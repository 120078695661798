import './FormularioUAP.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { size, flatMap, get, isEqual } from 'lodash';
import uuid from 'uuid/v4';

import DocumentoController from 'components/DocumentoController';
import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import RestricoesAdministrativasForm, { validarRestricoes } from 'components/RestricoesAdministrativasForm';
import ShowDebug from 'components/ShowDebug';

import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS } from 'containers/Form/Detalhe';

import useErrors from 'custom-hooks/useErrors';
import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { EXPEDIENTES_API_URL } from 'environments';

import { sendApi, accessApi } from 'utils/injectApi';
import { obtemDadosDocumento } from 'utils/tools';

// import { InfosAmbientaisLE } from './InfosAmbientaisAP';
import { InfosAreasAP } from './InfosAreasAP';
import { InfosDispositivosControleAP } from './InfosDispositivosControleAP';
import { InfosExpedienteAP } from './InfosExpedienteAP';
import { InfosPlanoDiretorAP, PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorAP';
import { includesTipoForm, INFOSPROJETO_CONTROLNAME, InfosProjetoAP } from './InfosProjetoAP';
import { InfosTerrenoAP } from './InfosTerrenoAP';
import { limparForm } from './LimparFormAP';
import { METAFORMAP } from './MetadataAP';
import { UAP_ENQUADRAMENTOS_ENUM } from './TabelaAreasAP/constantsAP';
import { validarForm } from './ValidarFormAP';

const { UNIFAMILIAR_AUTONOMA, UNIFAMILIAR_01_ECONO, HABITACAO_COLETIVA, UNIFAMILIAR_02_ECONO } =
	UAP_ENQUADRAMENTOS_ENUM;

const ID_METADATA_RESTRICOES_APROVACAO = 'aprovacao-projetos-restricoes-administrativas';

function AprovacaoProjetosDocEspecifico({
	setShowFormulario,
	setShowConfirm,
	setShowMessageConfirm,
	setSalvarEGerarPdf,
	salvarEGerarPdf = false,
	usuarioInterno = false,
	readOnlyVersao = null,
	preSaveDocs
}) {
	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errorsAux] = useErrors();
	const errors = useMemo(() => (size(errorsAux) === 0 ? {} : errorsAux), [errorsAux]);

	/* ESTADOS */
	const [localData, setLocalData] = useState({});
	const [localDataAnterior, setLocalDataAnterior] = useState({});
	const [loading, setLoading] = useState(false);
	const [iniciarLocalData, setIniciarLocalData] = useState(true);
	const [blockSubmit, setBlockSubmit] = useState(false);
	const [showExtraDoc, setShowExtraDoc] = useState(false);
	const [tipoForm, setTipoForm] = useState();
	const { createMessage } = useMessages();
	const [jaAcessouEvus, setJaAcessouEvus] = useState(false);
	const dispatch = useDispatch();
	const [isEstadoAlterado, setIsEstadoAlterado] = useState(false);

	/* MEMOIZED FUNCTIONS */
	const changeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}

			const { name, value } = changes;

			if (name === 'errors') {
				return;
			}

			dispatch(actions.setErrors([]));
			setBlockSubmit(false);
			let dados = { ...localData, [name]: value };
			//limpeza
			let dadosLimpos = limparForm(dados, tipoForm);
			setLocalData(dadosLimpos);
			setIsEstadoAlterado(true);
		},
		[dispatch, localData, tipoForm]
	);

	useEffect(() => {
		if (formulario && !jaAcessouEvus) {
			const expediente = get(formulario, 'formData.data.expediente');
			if (expediente) {
				obtemEvuValido(expediente.id || expediente._id)
					.then(evu => {
						setLocalData(d => ({ ...d, ultimoEvuValido: evu }));
						setIsEstadoAlterado(true);
					})
					.catch(error => {
						console.error('error', error);
					})
					.finally(() => {
						setJaAcessouEvus(true);
					});
			} else {
				setJaAcessouEvus(true);
			}
		}
	}, [formulario, jaAcessouEvus]);

	useEffect(() => {
		if (formulario) {
			const previousData = obtemDadosDocumento(
				formulario,
				EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
				false,
				false,
				readOnlyVersao
			);
			const previousDataAnterior = obtemDadosDocumento(
				formulario,
				EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
				false,
				true,
				readOnlyVersao
			);
			let dados = previousData?.dados || {};
			let dadosAnterior = previousDataAnterior?.dados || {};
			// se trocar tipoForm, limpa os dados (só ocorre em debug)
			if (tipoForm !== dados?.tipoForm) {
				dados = {};
				dadosAnterior = {};
			}
			const reloadLocalData =
				(iniciarLocalData && !isEqual(localData, dados)) || (size(localData) > 0 && isEqual(localData, dados));
			// verificar se é um carregamento inicial ou se houve alterações entre Store e localData
			// alterações em documentos ativam alterações na Store, que disparam este useEffect, esta condicional evita o reset dos dados do localData durante a edição do Form. Específico, principalmente nos casos de anexo e remoção de documentos em SLOTS no Form. Específico
			if (reloadLocalData) {
				setIniciarLocalData(false);
				setLocalData(dados);
				setLocalDataAnterior(dadosAnterior);
				setIsEstadoAlterado(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formulario, tipoForm]);

	useEffect(() => {
		let definicao = get(formulario, 'formData.data.enquadramento.definicao');
		setTipoForm(definicao);
		console.debug('definicao: ', definicao);
	}, [formulario]);

	const salvar = useCallback(
		params => {
			const { draft, erros, gerarPdf, preInvalidateForm } = params;
			try {
				setLoading(true);
				const newData = { ...localData, tipoForm };

				// add economias caso seja 1 ou 2 e nao tenha o campo na tela
				if (includesTipoForm([UNIFAMILIAR_01_ECONO, HABITACAO_COLETIVA], tipoForm)) {
					newData.infosProjeto.numeroEconomiasTotal.value = '1';
				}
				if (includesTipoForm([UNIFAMILIAR_02_ECONO], tipoForm || '')) {
					newData.infosProjeto.numeroEconomiasTotal.value = '2';
				}

				const dadosRestricoesRevisor = {}; //get(formulario, 'formData.data.dadosLE0400');
				if (size(dadosRestricoesRevisor) > 0) {
					const { restricoesAdministrativas: draRev } = dadosRestricoesRevisor;
					const { restricoesAdministrativas: draReq } = newData;

					if (size(draRev)) {
						Object.keys(draReq).forEach(prop => {
							if (Object.keys(draRev).includes(prop)) {
								if (draRev[prop] !== draReq[prop]) {
									draReq[`${prop}AlteradaRequerente`] = true;
								} else {
									delete draReq[`${prop}AlteradaRequerente`];
								}
								delete formulario?.formData?.data?.dadosLE0400[prop];
								delete formulario?.formData?.data?.dadosLE0400?.restricoesAdministrativas[prop];
							} else {
								draReq[`${prop}AlteradaRequerente`] = true;
							}
						});
					} else {
						Object.keys(newData).forEach(prop => {
							if (Object.keys(dadosRestricoesRevisor).includes(prop)) {
								if (dadosRestricoesRevisor[prop] !== newData[prop]) {
									newData[`${prop}AlteradaRequerente`] = true;
								} else {
									delete newData[`${prop}AlteradaRequerente`];
								}
								delete formulario?.formData?.data?.dadosLE0400[prop];
							} else {
								if (METAFORMAP.RESTRICOES_ADMINISTRATIVAS[prop]) {
									newData[`${prop}AlteradaRequerente`] = true;
								}
							}
						});
					}
				}

				if (gerarPdf) {
					Object.keys(newData).forEach(prop => {
						if (newData[`${prop}AlteradaRevisor`]) {
							delete newData[`${prop}AlteradaRevisor`];
						}
					});
					if (newData.restricoesAdministrativas) {
						Object.keys(newData.restricoesAdministrativas).forEach(prop => {
							delete newData.restricoesAdministrativas[`${prop}AlteradaRevisor`];
						});
					}
				}

				const formId = get(formulario, 'formData.id');
				const url = `processo/${formId}/${EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS}${draft ? '?draft=true' : ''}`;
				setIsEstadoAlterado(false);
				if (usuarioInterno && preInvalidateForm) {
					preSaveDocs({ novoDocsDados: newData, openForm: false, showMessage: true });
					setLoading(false);
				} else {
					sendApi({ url, payload: { form: newData, observacoes: erros }, required: true, label: '' }, false).then(
						({ data: dataResponse }) => {
							dispatch(
								actions.alteraStatusDocumento({
									documento: dataResponse.documento,
									documentoDados: dataResponse.documentoDados
								})
							);
							createMessage(`Documento salvo${draft && !usuarioInterno ? ' como rascunho' : ''} com sucesso`, 5);
							setLoading(false);
							if (!draft) {
								setShowFormulario(false);
							}
							if (usuarioInterno) {
								setShowFormulario(false);
							}
						}
					);
				}
			} catch (error) {
				setLoading(false);
				setShowFormulario(false);
				createMessage('Problemas no salvamento do documento... verifique a console', 3);
				console.error('erro no servidor', error);
			}
		},
		[createMessage, dispatch, formulario, localData, preSaveDocs, setShowFormulario, tipoForm, usuarioInterno]
	);

	const submit = useCallback(
		operacao => {
			const { draft, forceSave, gerarPdf } = operacao;
			let [validacao, ok] = [[], true];
			let isBloqueante = false;
			let formValidado = { validacao, isBloqueante };

			if (forceSave && draft) {
				salvar({ draft, erros: errors });
			} else {
				// validacao
				// validarInfosProjetoAP(localData, tipoForm);
				formValidado = validarForm(localData, tipoForm, formulario);
				validarRestricoes(localData, tipoForm, ID_METADATA_RESTRICOES_APROVACAO).then(valRE => {
					validacao = formValidado.validacao.concat(valRE);
					isBloqueante = formValidado.isBloqueante;
					ok = !isBloqueante && valRE.length === 0;

					const hasTPC = get(localData, [PLANODIRETOR_CONTROLNAME, 'dadosSubunidades'], []).some(sub =>
						get(sub, ['planilhaIndices', 'tipoIndices'], []).includes('tpc')
					);
					if (hasTPC) {
						const docPlanAltimetro = get(formulario, 'documentos', []).find(d => d.idDocumento === 'plan-dmi');
						const potencialConstrutivoTpcLocalAux = get(
							localData,
							`${INFOSPROJETO_CONTROLNAME}.planilhaIndicesTotalizada.potencialConstrutivoTpcLocal.subtotalAreaCalculada`,
							0.0
						);
						const formId = get(formulario, 'formData.id');
						if (formId && docPlanAltimetro && potencialConstrutivoTpcLocalAux === 0.0) {
							dispatch(actions.removeFile({ ...docPlanAltimetro, formId }));
							dispatch(actions.retiraDocumento({ formId, idDocumento: docPlanAltimetro.id }));
						}
					}

					if (ok && validacao.length > 0 && !salvarEGerarPdf) {
						dispatch(actions.setErrors(validacao));
					} else if (ok) {
						salvar({ draft, gerarPdf });
					} else {
						dispatch(actions.setErrors(validacao));
					}

					setBlockSubmit(isBloqueante);
				});
			}
		},
		[dispatch, errors, formulario, localData, salvar, salvarEGerarPdf, tipoForm]
	);

	/* AUXILIARES PARA RENDER */
	const hasErros = useMemo(() => size(errors) > 0, [errors]);

	const showForms = useMemo(() => !!tipoForm, [tipoForm]);

	const isUnidadeAutonoma = useMemo(() => includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm), [tipoForm]);

	useEffect(() => {
		if (salvarEGerarPdf) {
			setSalvarEGerarPdf(false);
			submit({ draft: false, forceSave: hasErros, gerarPdf: true });
		}
	}, [hasErros, salvarEGerarPdf, setSalvarEGerarPdf, setShowFormulario, submit]);

	const doc = useMemo(
		() =>
			get(formulario, 'documentos', []).find(d => d.idDocumento === 'plan-dmi') || {
				idDocumento: 'plan-dmi',
				id: uuid(),
				tituloDocumento: 'Levantamento Planialtimétrico',
				descricaoDocumento: 'Levantamento Planialtimétrico',
				extensao: 'pdf',
				obrigatorio: false,
				versao: 1,
				ordem: 1
			},
		[formulario]
	);

	// se alguma unidade marcar que utiliza TPC e possuir TPC Local preenchido
	useEffect(() => {
		setShowExtraDoc(false);
		let potencialConstrutivoTpcLocalAux = get(
			localData,
			`${INFOSPROJETO_CONTROLNAME}.planilhaIndicesTotalizada.potencialConstrutivoTpcLocal.subtotalAreaCalculada`,
			0.0
		);
		if (
			get(localData, [PLANODIRETOR_CONTROLNAME, 'dadosSubunidades'], []).some(sub =>
				get(sub, ['planilhaIndices', 'tipoIndices'], []).includes('tpc')
			) &&
			potencialConstrutivoTpcLocalAux > 0.0
		) {
			setShowExtraDoc(true);
		}
	}, [localData]);

	/* RENDER */
	return (
		<div className="doc-ap">
			{loading && <Loader />}
			{size(get(formulario, 'formData.data.expediente')) > 0 && (
				<div className="ap-card">
					<InfosExpedienteAP
						formulario={formulario}
						tipoForm={tipoForm}
						data={localData}
						onChangeHandler={changeHandler}
					/>
				</div>
			)}
			<div className="ap-card">
				{/* 1. Restrições Administrativas */}
				<RestricoesAdministrativasForm
					idMetadata={ID_METADATA_RESTRICOES_APROVACAO}
					data={localData.restricoesAdministrativas}
					dataAnterior={localDataAnterior.restricoesAdministrativas}
					changeHandler={data => changeHandler({ name: 'restricoesAdministrativas', value: data })}
					readOnly={usuarioInterno}
					readOnlyVersao={readOnlyVersao}
					changeComentarioHandler={
						!usuarioInterno ? null : data => changeHandler({ name: 'restricoesAdministrativas', value: data })
					}
					usuarioInterno={usuarioInterno}
				/>
			</div>
			{showForms && (
				<>
					{isUnidadeAutonoma ? (
						<div className="ap-card">
							{/* 2. Dispositivos de controle para a Unidade Autônoma */}
							<InfosDispositivosControleAP
								tipoForm={tipoForm}
								data={localData}
								dataAnterior={localDataAnterior}
								onChangeHandler={changeHandler}
								changeComentarioHandler={!usuarioInterno ? null : changeHandler}
								errosSubmissao={errors}
								disabled={usuarioInterno}
								usuarioInterno={usuarioInterno}
								readOnlyVersao={readOnlyVersao}
							/>
						</div>
					) : (
						<>
							<div className="ap-card">
								{/* 2. Área do terreno objeto do Projeto */}
								<InfosTerrenoAP
									tipoForm={tipoForm}
									data={localData}
									dataAnterior={localDataAnterior}
									onChangeHandler={changeHandler}
									errosSubmissao={errors}
									disabled={usuarioInterno}
									changeComentarioHandler={!usuarioInterno ? null : changeHandler}
									usuarioInterno={usuarioInterno}
									readOnlyVersao={readOnlyVersao}
								/>
							</div>
							<div className="ap-card">
								{/* 3. Plano Diretor */}
								<InfosPlanoDiretorAP
									tipoForm={tipoForm}
									formulario={formulario}
									data={localData}
									dataAnterior={localDataAnterior}
									onChangeHandler={changeHandler}
									errosSubmissao={errors}
									readOnly={usuarioInterno}
									changeComentarioHandler={!usuarioInterno ? null : changeHandler}
									usuarioInterno={usuarioInterno}
									readOnlyVersao={readOnlyVersao}
								/>
							</div>
						</>
					)}
					{jaAcessouEvus && (
						<div className="ap-card">
							{/* 3. ou 4. Informações do Projeto */}
							<InfosProjetoAP
								tipoForm={tipoForm}
								data={localData}
								dataAnterior={localDataAnterior}
								onChangeHandler={changeHandler}
								errosSubmissao={errors}
								semExpedienteUnico={!get(formulario, 'formData.data.expediente')}
								disabled={usuarioInterno}
								changeComentarioHandler={!usuarioInterno ? null : changeHandler}
								usuarioInterno={usuarioInterno}
								readOnlyVersao={readOnlyVersao}
							/>
							{/* escreve também <InfosProjetoSubUnidadesAP ... /> e <PlanilhaIndicesConstrutivos ... /> */}
						</div>
					)}
					<div className="ap-card">
						{/* 4. ou 5. Áreas do Projeto */}
						<InfosAreasAP
							tipoForm={tipoForm}
							data={localData}
							dataAnterior={localDataAnterior}
							onChangeHandler={changeHandler}
							errosSubmissao={errorsAux}
							disabled={usuarioInterno}
							changeComentarioHandler={!usuarioInterno ? null : changeHandler}
							usuarioInterno={usuarioInterno}
							readOnlyVersao={readOnlyVersao}
						/>
						{/* Escreve também: <TabelaAreasAP .../> e <ResumoAreasAP .../> */}
					</div>
					{showExtraDoc && (
						<>
							<div className="ap-card my-3">
								<DocumentoController documento={doc} />
								{hasErros && <ErrorMessages errorList={(errors || []).filter(e => e.campo === 'docExtra')} />}
							</div>
						</>
					)}
				</>
			)}
			{!usuarioInterno ? (
				<div className="cjto-botoes">
					{isEstadoAlterado ? (
						<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowConfirm(true)}>
							Cancelar
						</button>
					) : (
						<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowFormulario(false)}>
							Fechar
						</button>
					)}
					<button
						type="button"
						className="btn btn-secondary mt-3 mr-2"
						onClick={() => submit({ draft: true, forceSave: hasErros })}
					>
						{hasErros ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
					</button>
					<button
						type="button"
						className="btn btn-primary mt-3"
						onClick={() => {
							let showMessageConfirm = hasErros && !blockSubmit;
							if (showMessageConfirm) {
								setShowMessageConfirm(
									'O projeto arquitetônico possui inconsistências constatadas, tem certeza que deseja salvar e gerar o PDF?'
								);
							} else {
								submit({ draft: false, forceSave: hasErros, gerarPdf: true });
							}
						}}
						disabled={blockSubmit}
					>
						Salvar e gerar PDF
					</button>
				</div>
			) : (
				<div className="cjto-botoes">
					{!readOnlyVersao && (
						<button
							type="button"
							className="btn btn-primary mt-3"
							onClick={() => salvar({ draft: true, gerarPdf: false, preInvalidateForm: true })}
						>
							{'Salvar Comentários'}
						</button>
					)}
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowFormulario(false)}>
						{readOnlyVersao ? 'Fechar' : 'Cancelar'}
					</button>
					{!readOnlyVersao && (
						<button
							type="button"
							className="btn btn-danger mt-3 mr-2"
							onClick={() => preSaveDocs({ desinvalidar: true })}
						>
							Cancelar Invalidação
						</button>
					)}
				</div>
			)}
			{hasErros && (
				<div className="ap-card erros">
					<h3 className="font-weight-bold text-danger m-3">Existem erros no formulário, verifique acima.</h3>
					<ErrorMessages showLink={true} errorList={Array.isArray(errorsAux) ? flatMap(errorsAux, v => v) : []} />
					{!blockSubmit && (
						<h4 className="warning-message">
							Continue se deseja protocolar o projeto arquitetônico mesmo com as inconsistências constatadas.
						</h4>
					)}
				</div>
			)}
			<ShowDebug
				data={{
					areasPlanilha: localData?.detalhesAreas?.areasPlanilha,
					detalhesAreas: localData.detalhesAreas,
					localData,
					errors
				}}
				console
			/>
		</div>
	);
}
AprovacaoProjetosDocEspecifico.displayName = 'AprovacaoProjetosDocEspecifico';
AprovacaoProjetosDocEspecifico.propTypes = {
	setShowFormulario: PropTypes.func,
	setShowConfirm: PropTypes.func,
	setShowMessageConfirm: PropTypes.func,
	readOnly: PropTypes.bool,
	forAdmin: PropTypes.bool,
	setSalvarEGerarPdf: PropTypes.func,
	salvarEGerarPdf: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	setHasComentario: PropTypes.func,
	preSaveDocs: PropTypes.func,
	readOnlyVersao: PropTypes.any
};
export default memo(AprovacaoProjetosDocEspecifico);

async function obtemEvuValido(expedienteId) {
	if (!expedienteId) {
		return null;
	}
	const url = `${EXPEDIENTES_API_URL}/eu/${expedienteId}/ultimo-evu-valido`;
	const res = await accessApi(url, true);
	const evu = res.data;
	return evu;
}
