import React, { useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Loader from 'components/Loader';
import withMessage from 'components/WithMessage';

import { accessApi } from 'utils/injectApi';

const ValidacaoPlantaAssinada = () => {
	const [loading, setLoading] = useState(false);
	const [docRetorno, setDocRetorno] = useState(null);
	const [codAutentic, setCodAutentic] = useState('');
	const [buscou, setBuscou] = useState(false);

	let { codaut } = useParams();

	const validaDoc = async codigoAutenticacao => {
		setLoading(true);
		setBuscou(false);

		try {
			const retorno = await accessApi(`/validaPlantaAssinada/${codigoAutenticacao}`, true);
			setDocRetorno(retorno.data);
			setBuscou(true);
		} catch (e) {
			console.error('Erro ao acessar API de validacao da planta assinada: ', e);
		}

		setLoading(false);
	};

	const mostraInput = !buscou || (buscou && !docRetorno);

	if (codaut && !buscou && !loading) {
		validaDoc(codaut);
	}

	return (
		<Fragment>
			<div className="container">
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>Verificação de Planta Assinada</li>
					</ul>
				</div>
				<h1 className="page-header row">Verificação de autenticidade de projeto urbanístico</h1>
				<section className="content secao-verificacao-documentos">
					<div className="texto-apoio">
						Nesta tela, você pode <strong>verificar a autenticidade</strong> de projetos urbanísticos aprovados pela
						Prefeitura Municipal de Porto Alegre. Para isso, basta inserir, no campo abaixo, o código que consta no
						documento cuja autenticidade você deseja verificar.
					</div>
					{mostraInput && (
						<div className="row">
							<input
								placeholder="Digite o código de verificação"
								className="form-control input-com-botao"
								value={codAutentic}
								onChange={e => setCodAutentic(e.target.value)}
							/>
							<button className="btn btn-primary botao-apos-input" type="button" onClick={() => validaDoc(codAutentic)}>
								Verificar autenticidade <i className="fa fa-check" aria-hidden="true"></i>
							</button>
						</div>
					)}
					{buscou && !docRetorno && (
						<div className="bloco-sem-resultado row">
							<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Nenhum documento relacionado ao código
							informado foi localizado. Verifique se houve erro de digitação.
						</div>
					)}
					{buscou && docRetorno && (
						<div>
							<br />
							<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />{' '}
							<a href={docRetorno.urlDocumentoSei} target="_blank" rel="noopener noreferrer">
								Clique aqui para visualizar o documento autenticado
							</a>
							<br />
							<br />
							<Link to="/validacaoPlantaAssinada">
								<button
									className="btn btn-primary"
									type="button"
									onClick={() => {
										setBuscou(false);
										setDocRetorno(null);
										setCodAutentic('');
									}}
								>
									Realizar outra consulta
								</button>
							</Link>
						</div>
					)}
				</section>
			</div>
			{loading && <Loader />}
		</Fragment>
	);
};

ValidacaoPlantaAssinada.displayName = 'ValidacaoPlantaAssinada';
ValidacaoPlantaAssinada.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

export default withMessage(ValidacaoPlantaAssinada);
