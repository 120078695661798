import React, { Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { omit, size } from 'lodash';

import { AreaSubfield } from './AreaSubfield';
import { NumeroSubfield } from './NumeroSubfield';
import { TextAreaSubfield } from './TextAreaSubfield';

const Inputs = ({
	data,
	fields,
	onChangeHandler,
	errors,
	setErrors,
	usuarioInterno,
	qtdMinLinhas = 3,
	classNameContainer = ''
}) => {
	/* REDUX */
	//const [errors, setErrors] = useErrors();

	const handleChangeValue = useCallback(
		e => {
			if (Array.isArray(e)) {
				const [valid] = e;
				e = valid;
			}
			const { name, value } = e.target || e;
			if (name === 'errors') {
				return;
			}
			setErrors(old => omit(old, [name]));
			onChangeHandler(value, name);
		},
		[onChangeHandler, setErrors]
	);

	return (
		<div className="row">
			{fields
				.filter(field => (usuarioInterno ? true : !field.id.startsWith('observacoes')))
				.map(field => {
					const { id, label, type, unidade, obrigatorio, readOnly, disabled, placeholder } = field;
					const valueAlteradaRevisor = usuarioInterno ? null : data?.[`${id}AlteradaRevisor`];
					const valueAlteradaRt = usuarioInterno ? data?.[`${id}AlteradaRt`] : null;

					return readOnly && data?.[id] ? (
						<Fragment key={id}>
							<div className="container-resposta-ap">
								<div className="cs-label">
									{label}
									{obrigatorio && <span className="required">*</span>}
								</div>
								<span className="resposta-ap">{`${asString(data?.[id], type)}${unidade}`}</span>
							</div>
						</Fragment>
					) : (
						<Fragment key={id}>
							<div
								className={`${
									size(classNameContainer) === 0 && (type === 'string' || (fields.length === 1 && type !== 'number'))
										? 'col-md-12'
										: 'col-md-6'
								} ${classNameContainer}`}
							>
								{type === 'area' && (
									<AreaSubfield
										fieldName={id}
										placeholder={unidade ? unidade : null}
										label={label}
										value={data?.[id]}
										errors={errors?.[id]}
										readOnly={readOnly}
										disabled={disabled}
										required={obrigatorio}
										onChangeHandler={e => handleChangeValue(e, type, 2)}
									/>
								)}
								{type === 'number' && (
									<div className="numero">
										<NumeroSubfield
											fieldName={id}
											label={label}
											value={data?.[id]}
											errors={errors?.[id]}
											disabled={disabled}
											required={obrigatorio}
											onChangeHandler={e => handleChangeValue(e, type, 2)}
										/>
									</div>
								)}
								{type === 'string' && (
									<TextAreaSubfield
										fieldName={id}
										label={label}
										value={data?.[id]}
										errors={errors?.[id]}
										qtdMinLinhas={qtdMinLinhas}
										required={obrigatorio}
										placeholder={placeholder}
										onChangeHandler={e => handleChangeValue(e, type)}
									/>
								)}
								{valueAlteradaRevisor && (
									<div className="cs-pergunta-warning" style={{ whiteSpace: 'pre-wrap' }}>
										Valor alterado pelo revisor de <strong>{asString(valueAlteradaRevisor.de, field.type)}</strong>
										&nbsp;para <strong>{asString(valueAlteradaRevisor.para, field.type)}</strong>
									</div>
								)}
								{valueAlteradaRt && (
									<div className="cs-pergunta-warning" style={{ whiteSpace: 'pre-wrap' }}>
										Valor alterado pelo RT
										<br />
										de {asString(valueAlteradaRt.de, field.type)}
										<br />
										para {asString(valueAlteradaRt.para, field.type)}
									</div>
								)}
							</div>
						</Fragment>
					);
				})}
		</div>
	);
};
Inputs.displayName = 'Inputs';

Inputs.propTypes = {
	data: PropTypes.object,
	fields: PropTypes.arrayOf(PropTypes.object),
	usuarioInterno: PropTypes.bool,
	setErrors: PropTypes.func,
	errors: PropTypes.object,
	onChangeHandler: PropTypes.func,
	qtdMinLinhas: PropTypes.number,
	classNameContainer: PropTypes.string
};
export default Inputs;

function asString(value, type) {
	if (typeof value === 'object') {
		value = value.value;
	}

	if (['area', 'number'].includes(type) && value === undefined) {
		return 'sem valor';
	}

	if (type === 'area') {
		return formatNumber(value, 2);
	}

	if (type === 'number') {
		return formatNumber(value, 0);
	}

	return value;
}

function formatNumber(string, decimais = 0) {
	string = `${string}`;
	string = string || '0';
	string = string.replace(/,/g, '.');
	let numberValue = parseFloat(string);

	const formattedNumber = new Intl.NumberFormat('pt-BR', {
		style: 'decimal',
		minimumFractionDigits: decimais,
		maximumFractionDigits: decimais,
		useGrouping: 'always'
	}).format(numberValue);

	return formattedNumber;
}
