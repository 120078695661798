import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from 'react-apollo';
import { Card } from 'react-bootstrap';
import { useStore } from 'react-redux';

import gql from 'graphql-tag';
import { defaults, get, size } from 'lodash';

import Loader from 'components/Loader';

import TemplateForm from 'containers/Form/metadata-template/TemplateForm';

import useMessages from 'custom-hooks/useMessages';

const usuarioQuery = gql`
	query LicenciamentoUsuarioById($id: String!) {
		item: LicenciamentoUsuarioById(id: $id) {
			id
			perfil
		}
	}
`;
const usuarioMutate = gql`
	mutation updateLicenciamentoUsuario($id: String!, $perfil: Any) {
		item: updateLicenciamentoUsuario(id: $id, body: { perfil: $perfil }) {
			id
			perfil
		}
	}
`;

const PerfilUsuarioForm = () => {
	const [userForm, setUserForm] = useState({});
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [errorList, setErrorsList] = useState();
	const { createMessage } = useMessages();
	const [getUsuario, { data, loading: loadingDB }] = useLazyQuery(usuarioQuery);
	const [mutateUsuario, { loading: submitting, called, error }] = useMutation(usuarioMutate);
	const store = useStore();

	const isPessoaJuridica = userForm.tipoPerfil === 'cnpj';

	//#region fields
	const fields = useMemo(
		() => [
			{
				legend: 'Dados Pessoais',
				fieldSetName: 'dadosPessoais',
				fields: {
					tipoPerfil: {
						type: 'radioField',
						label: 'Tipo de Perfil',
						options: [
							{ codigo: 'cpf', descricao: 'Pessoa Física' },
							{ codigo: 'cnpj', descricao: 'Pessoa Jurídica' }
						],
						required: true
					},
					nomeOuRazao: {
						type: 'textField',
						label: isPessoaJuridica ? 'Razão Social' : 'Nome',
						required: true
					},
					documento: {
						type: 'cpfCnpjField',
						label: isPessoaJuridica ? 'CNPJ' : 'CPF',
						required: true
					},
					telefone: {
						type: 'phoneField',
						label: 'Telefone com DDD (preferencialmente celular)',
						required: true
					},
					email: {
						type: 'emailField',
						label: 'E-mail',
						required: true,
						columns: 6,
						readOnly: true
					}
				}
			},
			{
				legend: 'Endereço',
				fieldSetName: 'endereco',
				fields: {
					logradouro: {
						type: 'textField',
						label: 'Logradouro',
						required: true,
						columns: 6
					},
					numeroLogradouro: {
						type: 'textField',
						label: 'Número',
						required: true,
						columns: 6
					},
					complementoLogradouro: {
						type: 'textField',
						label: 'Complemento',
						required: false
					},
					cep: {
						type: 'textField',
						label: 'CEP',
						required: true,
						columns: 6
					},
					bairro: {
						type: 'textField',
						label: 'Bairro',
						required: true,
						columns: 6
					},
					cidade: {
						type: 'textField',
						label: 'Cidade',
						required: true,
						columns: 6
					},
					estado: {
						type: 'ufField',
						label: 'Estado',
						required: true,
						columns: 6
					}
				}
			}
		],
		[isPessoaJuridica]
	);
	//#endregion fields

	useEffect(() => {
		const obtainUser = async () => {
			if (store && get(store, 'kc.authenticated') && !size(user)) {
				const userKc = await store.kc.loadUserInfo();
				const profile = await store.kc.loadUserProfile();
				getUsuario({ variables: { id: userKc.sub } });
				setUser({ ...userKc, ...profile });
			}
		};
		obtainUser();
	}, [getUsuario, store, user, userForm]);

	useEffect(() => {
		if (!loadingDB && data && size(user) && !size(userForm)) {
			const { item: userDB } = data;
			if (userDB && userDB.perfil) {
				setUserForm(userDB.perfil);
			} else {
				const attributes = user.attributes || {};
				const form = defaults(userForm, {
					nomeOuRazao: get(attributes, ['acessogov.name', '0'], user.name),
					documento: get(attributes, ['acessogov.cpf', '0']),
					email: user.email,
					sub: user.sub,
					telefone: get(attributes, ['acessogov.phone_number', '0']),
					tipoPerfil: 'cpf'
				});
				setUserForm(form);
			}
			setLoading(false);
		}
	}, [loading, user, userForm, data, loadingDB]);

	useEffect(() => {
		if (!submitting && called) {
			if (error) {
				createMessage('Erro ao salvar dados');
				console.error(error);
			} else {
				createMessage('Dados salvos com sucesso');
			}
		}
	}, [called, createMessage, error, submitting]);

	const changeHandler = useCallback(change => {
		setErrorsList(undefined);
		const { key, value } = change;
		setUserForm(old => ({ ...old, [key]: value }));
	}, []);

	const submit = useCallback(
		result => {
			const [ok, errorList, customErrors] = result;
			if (ok) {
				setErrorsList(undefined);
				mutateUsuario({ variables: { id: user.sub, perfil: userForm } });
			} else {
				setErrorsList(size(customErrors) > 0 ? customErrors : errorList);
			}
		},
		[mutateUsuario, user.sub, userForm]
	);

	return (
		<Fragment>
			<div className="container">
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>Meus dados</li>
					</ul>
				</div>
				<h1 className="page-header row">Meus Dados</h1>
				<section className="content">
					<div className="row">
						<div className="col-xs-12 col-lg-10 offset-lg-1">
							<Card className="card-borderless">
								<Card.Body>
									{loading && <Loader />}
									{submitting && <Loader msg="Salvando..." />}
									<TemplateForm
										key={userForm?.sub}
										fieldsets={fields}
										formData={userForm}
										onChangeHandler={changeHandler}
										onSubmit={submit}
										submitLabel="Salvar"
										customErrors={errorList}
									/>
								</Card.Body>
							</Card>
						</div>
					</div>
				</section>
			</div>
		</Fragment>
	);
};

PerfilUsuarioForm.displayName = 'PerfilUsuarioForm';
export default PerfilUsuarioForm;
