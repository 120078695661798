import React from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';

const TooltipIcon = ({ label, text, children, icon = 'fa-question', iconColor = 'red' }) => (
	<span style={{ whiteSpace: 'nowrap' }}>
		{label}
		<Tippy content={text || children} placement="right" animation="fade" duration={200}>
			<i
				className={`fa ${icon}`}
				aria-hidden="true"
				style={{ fontSize: '16px', color: iconColor, marginLeft: '8px' }}
			></i>
		</Tippy>
	</span>
);
TooltipIcon.displayName = 'TooltipIcon';
TooltipIcon.propTypes = {
	label: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default TooltipIcon;
