import { get, isNil, size } from 'lodash';

import actions from 'containers/Form/actions';

import { METAVISTORIA } from '.';

const removeFile = (dispatch, documento, formulario) => {
	if (documento && !size(documento.idDocumentoSei)) {
		const idFormData = get(formulario, 'formData.id');
		dispatch(actions.removeFile({ ...documento, idFormData }));
		dispatch(actions.retiraDocumento({ idFormData, idDocumento: documento.id }));
	}
};

/**
 * realiza a limpeza de campos do boletim vistoria conforme regras
 * de negócio do formulário
 *
 * @returns novo objeto com os dados do boletim
 */
export function cleanRules(form, dispatch, formulario) {
	if (form === undefined || form === null) return form;

	let data = { ...form };

	if (data[METAVISTORIA.projetoOuLicenca.name]?.id !== 'outraData') {
		delete data[METAVISTORIA.dataProjetoOuLicenca.name];
	}

	// tipo de projeto ou licenca
	if (data[METAVISTORIA.tipoSolicitacao.name] !== METAVISTORIA.TIPOS_SOLICITACAO.licencaHoraExpressa.codigo) {
		delete data[METAVISTORIA.numeroLicenca.name];
	}
	// sem descricao de pranchas se unica
	if (data[METAVISTORIA.numeroPranchas.name] === 'unica') {
		delete data[METAVISTORIA.descricaoPranchas.name];
	}
	// sem boxes privativos
	if (data[METAVISTORIA.existemBoxes.name] === 'nao') {
		delete data[METAVISTORIA.qtdBoxes.name];
	}
	// ocupacao do imovel
	if (data[METAVISTORIA.imovelOcupado.name] === 'nao') {
		delete data[METAVISTORIA.apenasUmaDataOcupacao.name];
		delete data[METAVISTORIA.dataOcupacaoObra.name];
		delete data[METAVISTORIA.descricaoOcupacaoEconomias.name];
	}
	if (data[METAVISTORIA.apenasUmaDataOcupacao.name] === 'nao') {
		delete data[METAVISTORIA.dataOcupacaoObra.name];
	}
	if (data[METAVISTORIA.apenasUmaDataOcupacao.name] === 'sim') {
		delete data[METAVISTORIA.descricaoOcupacaoEconomias.name];
	}
	// tipos vistoria
	const tipoVistoria = data[METAVISTORIA.tipoVistoria.name];
	if (tipoVistoria !== METAVISTORIA.TIPOS_VISTORIA.total.codigo) {
		delete data[METAVISTORIA.metragemTotal.name];
		if (data[METAVISTORIA.economiaUnica.name] === 'sim') {
			delete data[METAVISTORIA.listaEconomias.name];
		}
	}
	if (tipoVistoria === METAVISTORIA.TIPOS_VISTORIA.total.codigo) {
		delete data[METAVISTORIA.areaCondominal.name];
		delete data[METAVISTORIA.areaPrivativa.name];
		delete data[METAVISTORIA.areaParcialTotal.name];
		delete data[METAVISTORIA.economiaUnica.name];
		delete data[METAVISTORIA.listaEconomias.name];
	}
	// condicionantes
	// nao possui condicionantes, remove todos os arquivos
	if (data[METAVISTORIA.possuiCondicionantes.name] === 'nao') {
		delete data[METAVISTORIA.condicionantesVistoria.name];
		get(formulario, 'documentos', [])
			.filter(d => d.condicionante)
			.forEach(d => removeFile(dispatch, d, formulario));

	} else if (data[METAVISTORIA.possuiCondicionantes.name] === 'sim') {
		const documentos = get(formulario, 'documentos', []).filter(d => d.condicionante);

		const cvistoria = data[METAVISTORIA.condicionantesVistoria.name];
		for (const codigo in cvistoria) {
			if (Object.hasOwnProperty.call(cvistoria, codigo)) {
				const condicao = cvistoria[codigo];
				// nao tem o condicionante
				if (condicao.value === 'nao') {
					delete condicao[METAVISTORIA.tramiteCondicionante.name];
					delete condicao[METAVISTORIA.tramiteProcessoSei.name];
					documentos.filter(d => d.condicionante === codigo).forEach(d => removeFile(dispatch, d, formulario));
					// tem condicionante que tramita durante o processo
				} else if (condicao[METAVISTORIA.tramiteCondicionante.name] === 'tramitar_durante_solicitacao') {
					delete condicao[METAVISTORIA.tramiteProcessoSei.name];
					// deleta arquivos anexados como 'anteriormente atendido'
					documentos
						.filter(d => d.condicionante === codigo && !d.isTramitarDurante)
						.forEach(d => removeFile(dispatch, d, formulario));
					// tem condicionante ja atendido
				} else if (condicao[METAVISTORIA.tramiteCondicionante.name] === 'tramitou_anterior_atendido') {
					// deleta arquivos anexados como 'tramitar durante o processo'
					documentos
						.filter(d => d.condicionante === codigo && d.isTramitarDurante)
						.forEach(d => removeFile(dispatch, d, formulario));
				}
			}
		}
	}
	// lista de economias
	if (!isNil(data[METAVISTORIA.informacoesObra.name])) {
		data[METAVISTORIA.informacoesObra.name].forEach(io => {
			if (io[METAVISTORIA.economiaUnica.name] === 'sim') {
				delete io[METAVISTORIA.listaEconomias.name];
			}
		});
	}

	return data;
}
