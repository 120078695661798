import clientActions from './actions';
import {
	SERVER_SAVE_FORM_DATA,
	SERVER_SAVE_ENQUADRAMENTO_DATA,
	SERVER_REMOVE_FILE,
	SERVER_INVALIDA_DOCUMENTO,
	SERVER_SAVE_FORM_COMPLETO,
	GERA_PROCESSO_SEI,
	SERVER_COMPLEMENTAR_PROCESSO
} from './constants';

export default {
	saveFormData: formData => ({
		type: SERVER_SAVE_FORM_DATA,
		payload: {
			formData,
			next: clientActions.limpaStore()
		}
	}),
	saveEnquadramentoData: data => ({
		type: SERVER_SAVE_ENQUADRAMENTO_DATA,
		payload: {
			...data,
			next: clientActions.limpaStore()
		}
	}),
	saveFormCompleto: formulario => ({
		type: SERVER_SAVE_FORM_COMPLETO,
		payload: {
			formulario,
			next: clientActions.limpaStore()
		}
	}),
	complementarProcesso: (formulario, usernameLogado, bpmTasks, timestamp, complementadaEm, ultimaBpmTask) => ({
		type: SERVER_COMPLEMENTAR_PROCESSO,
		payload: {
			formulario,
			usernameLogado,
			bpmTasks,
			timestamp,
			complementadaEm,
			ultimaBpmTask,
			next: clientActions.limpaStore()
		}
	}),
	geraProcessoSEI: formulario => ({
		type: GERA_PROCESSO_SEI,
		payload: {
			formulario,
			next: clientActions.limpaStore()
		}
	}),
	removeFile: documento => ({
		type: SERVER_REMOVE_FILE,
		payload: {
			documento,
			next: clientActions.limpaStore()
		}
	}),
	atualizaDocsInvalidos: formulario => ({
		type: SERVER_INVALIDA_DOCUMENTO,
		payload: {
			formulario,
			next: clientActions.limpaStore()
		}
	}),
	concordarTermo: obj => ({
		type: 'formulario/SERVER_TERMO_ACEITACAO',
		payload: {
			...obj,
			next: clientActions.setAceitarTermos(obj.concordo)
		}
	})
};
