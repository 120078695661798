import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { get, repeat, size } from 'lodash';

import { format, permiteInput } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const NumberField = ({
	label,
	labelEnum,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	isInvalid,
	maxSize,
	minValue,
	maxValue,
	onChangeHandler,
	onSetFocus = () => {},
	hasFocus,
	decimals,
	style,
	styleLabel
}) => {
	const inputRef = useRef();

	// console.log(value, parseFloat(value?.value));

	const onChangeNumber = e => {
		let input = get(e, 'target.value', '');
		input = input.replace(/[^0-9]/g, '');

		if (get(value, 'checkedForZero') || permiteInput(input, 'number', 0, maxSize || 5)) {
			const erros = validate(
				{ value: input, checkedForZero: get(value, 'checkedForZero') },
				required,
				label,
				minValue,
				maxValue
			);
			if (decimals) {
				const decimais = 10 ** decimals;
				const valDecimal = input / decimais;
				input = valDecimal.toFixed(decimals);
			}
			onChangeHandler([
				{ name, value: { value: input, checkedForZero: get(value, 'checkedForZero') } },
				{ name: 'errors', value: { [name]: erros } }
			]);
		}
	};

	const valorFormatado = input => {
		if (size(input) === 0) {
			return '';
		}

		let clearInput = input.replace(/[^0-9]/g, '');
		let inputLength = clearInput.length;
		let mascara;

		if (decimals) {
			const dec = 10 ** decimals;
			// calculo para ver se o final termina em 0 (123.2)
			const finalZero = input.split('.')[1]?.length;
			if (finalZero < decimals) {
				// adiciona um zero
				clearInput = `${clearInput}0`;
				// aumento o tamanho
				inputLength += decimals - finalZero;
			}

			if (inputLength <= `${dec}`.length) {
				mascara = `0,${repeat('0', decimals)}`;
			} else if (inputLength > `${dec}`.length) {
				inputLength -= decimals;
				const demaisPartes = Math.floor(inputLength / 3);
				const parteInicial = (inputLength - demaisPartes * 3) % 3;
				const p1 = parteInicial ? repeat('0', parteInicial) : '';
				const p2 = demaisPartes ? repeat('.000', demaisPartes) : '';
				const pd = `,${repeat('0', decimals)}`;
				mascara = p1 + p2 + pd;
			}
		} else {
			const demaisPartes = Math.floor(inputLength / 3);
			const parteInicial = (inputLength - demaisPartes * 3) % 3;
			const p1 = parteInicial ? repeat('0', parteInicial) : '';
			const p2 = demaisPartes ? repeat('.000', demaisPartes) : '';
			mascara = p1 + p2;
		}

		// const pd = hasDecimals ? ',' + repeat('0', decimals) : '';
		// mascara = p1 + p2 + pd;

		if (mascara.startsWith('.')) {
			mascara = mascara.substring(1);
		}
		const formatado = format(clearInput || '', mascara);
		return formatado;
	};

	const onFocusHandler = () => {
		onSetFocus(name);
	};

	const onBlurHandler = () => {
		onSetFocus(null);
	};

	return (
		<>
			{(labelEnum || label) && (
				<label className="control-label" style={styleLabel || {}}>
					{labelEnum || label}{' '}
					{required && (
						<span className="required" style={{ color: 'red' }}>
							*
						</span>
					)}
				</label>
			)}
			<input
				ref={inputRef}
				className={
					isInvalid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
				}
				style={style || {}}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={hasFocus ? get(value, 'value', '') : valorFormatado(get(value, 'value', ''))}
				onChange={onChangeNumber}
				onFocus={onFocusHandler}
				onBlur={onBlurHandler}
				readOnly={readOnly}
				disabled={disabled || get(value, 'checkedForZero')}
			/>
		</>
	);
};
NumberField.displayName = 'NumberField';

NumberField.propTypes = {
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	maxSize: PropTypes.string,
	minValue: PropTypes.string,
	maxValue: PropTypes.string,
	hasFocus: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	onSetFocus: PropTypes.func,
	isInvalid: PropTypes.bool,
	decimals: PropTypes.number,
	style: PropTypes.object,
	styleLabel: PropTypes.object
};

export default NumberField;

export const validate = (value, required, label = 'Campo', minValue, maxValue) => {
	let errors = [];
	const { value: valor, checkedForZero = false } = value || {};
	if (required) {
		if (!checkedForZero) {
			const intValue = parseInt(valor || 0, 10);
			if (!intValue || intValue === 0) {
				errors.push(`${label} deve ser informado`);
			} else {
				if (minValue && intValue < parseInt(minValue, 10)) {
					errors.push(`${label} não deve ser menor que ${minValue}`);
				}
				if (maxValue && intValue > parseInt(maxValue, 10)) {
					errors.push(`${label} naõ deve ser maior que ${maxValue}`);
				}
			}
		}
	}
	return errors;
};
