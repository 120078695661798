import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { size, trim } from 'lodash';

import { accessApi } from 'utils/injectApi';

import Selecao from '../components/Selecao';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const TypeAheadField = ({
	label,
	labelEnum,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	onChangeHandler,
	isInvalid,
	options = [],
	multiple,
	attrCodigo,
	attrDescricao,
	attrDescricaoProperties,
	attrDescricaoPropertiesTrimEmpty = false,
	descriptionSubstringLength = 26,
	detailIcon = null,
	autoShowList,
	fullObject,
	externalApiUrl,
	attrDescricaoSeparator = ' - ',
	sortProperty,
	filter,
	readOnlyStyle
}) => {
	const [searchTerm, setSearchTerm] = useState();
	const [selected, setSelectedItem] = useState();
	const [data, setData] = useState(options);

	useEffect(() => {
		let timer;
		if (externalApiUrl) {
			timer = setTimeout(async () => {
				let auxData = (await accessApi(externalApiUrl, true))?.data;
				auxData = auxData.map(item => ({ ...item, [attrCodigo]: attrCodigo === 'codigo' ? item.codigo : item._id }));
				if (attrDescricaoProperties) {
					auxData = auxData.map(item => {
						let descricao = '';
						for (const property of attrDescricaoProperties) {
							let hasPropAux = size(item[property]) > 0;
							descricao +=
								((size(descricao) === 0
									? ''
									: attrDescricaoPropertiesTrimEmpty
									? hasPropAux
										? ' - '
										: ''
									: ''): attrDescricaoSeparator) +
								(attrDescricaoPropertiesTrimEmpty ? (hasPropAux ? item[property] : ' ') : item[property]);
						}
						descricao = attrDescricaoPropertiesTrimEmpty ? trim(descricao) : descricao;
						return { ...item, [attrDescricao]: descricao };
					});
				}
				if (sortProperty) {
					auxData = auxData.sort((item1, item2) => (item1[sortProperty] > item2[sortProperty] ? 1 : -1));
				}
				if (filter) {
					auxData = auxData.filter(item => filter.includes(item.codigo));
				}
				setData(auxData);
			});
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [
		attrCodigo,
		attrDescricao,
		attrDescricaoProperties,
		attrDescricaoPropertiesTrimEmpty,
		attrDescricaoSeparator,
		externalApiUrl,
		filter,
		sortProperty
	]);

	useEffect(() => {
		let toSelect;
		if (fullObject) {
			toSelect = value && data && data.find(o => o[attrCodigo] === value[attrCodigo]);
		} else {
			toSelect = value && data && data.find(o => o[attrCodigo] === value);
		}
		setSelectedItem(toSelect);
	}, [value, data, attrCodigo, fullObject]);

	function onChange(item) {
		if (item) {
			if (fullObject) {
				onChangeHandler && onChangeHandler({ name, value: item });
			} else {
				const value = item[attrCodigo];
				onChangeHandler && onChangeHandler({ name, value });
			}
		} else {
			onChangeHandler && onChangeHandler({ name, value: undefined });
		}
	}

	return (
		<>
			<label className="control-label">
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<Selecao
				className={isInvalid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : CLASS_NAME_FORM_CONTROL}
				detailInnerClassName={'title-with-button-white-space-normal'}
				multiple={multiple}
				selected={selected || ''}
				label={label}
				detailDescricao={attrDescricao}
				autoShowList={autoShowList}
				searchTerm={searchTerm}
				searchList={(data || []).filter(o => JSON.stringify(o).toLowerCase().indexOf(searchTerm) > -1)}
				searchTermMinLength={0}
				errorList={false}
				onChangeSearchTerm={e => setSearchTerm(e.target?.value?.toLowerCase())}
				onBlurSearchTerm={() => false}
				onSelectItem={item => () => onChange(item)}
				onUnselect={() => () => onChange(undefined)}
				required={required}
				placeholder={placeHolder}
				detailIcon={detailIcon}
				disabled={disabled}
				readOnly={readOnly}
				descriptionSubstringLength={descriptionSubstringLength}
				readOnlyStyle={readOnlyStyle}
			/>
		</>
	);
};
TypeAheadField.displayName = 'TypeAheadField';

TypeAheadField.propTypes = {
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	options: PropTypes.array,
	multiple: PropTypes.bool,
	attrCodigo: PropTypes.string.isRequired,
	attrDescricao: PropTypes.string.isRequired,
	descriptionSubstringLength: PropTypes.number,
	autoShowList: PropTypes.bool,
	fullObject: PropTypes.bool,
	detailIcon: PropTypes.string,
	externalApiUrl: PropTypes.string,
	attrDescricaoProperties: PropTypes.arrayOf(PropTypes.string),
	attrDescricaoPropertiesTrimEmpty: PropTypes.bool,
	attrDescricaoSeparator: PropTypes.string,
	sortProperty: PropTypes.string,
	filter: PropTypes.array,
	readOnlyStyle: PropTypes.object
};

export default TypeAheadField;
