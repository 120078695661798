import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { size, get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { formatFloat, permiteInput } from 'utils/tools';

import 'tippy.js/dist/tippy.css';

function PlanilhaIndicesConstrutivosArea({
	required,
	onChangeHandler,
	value,
	errors,
	placeHolder,
	decimals = 4,
	maxSize = 10,
	auto,
	readOnly,
	disabled,
	warning
}) {
	const setHandler = e => {
		let newValue = get(e, 'target.value', '');
		newValue = newValue.replace(/[^0-9]/g, '');

		if (permiteInput(newValue, 'number', 0, maxSize)) {
			if (decimals) {
				const decimais = 10 ** decimals;
				const valDecimal = newValue / decimais;
				newValue = valDecimal.toFixed(decimals);
			}
			onChangeHandler(newValue);
		}
	};

	const valorFormatado = useMemo(() => (value ? formatFloat(parseFloat(value), decimals) : ''), [decimals, value]);

	const valueString = useMemo(
		() => (value ? (typeof value === 'string' ? `string: ${value}` : formatFloat(value, decimals)) : ''),
		[decimals, value]
	);

	return readOnly ? (
		<span
			className="form-control"
			style={{
				backgroundColor: '#cccccc',
				textAlign: typeof value === 'string' ? 'left' : 'right'
			}}
		>
			{valueString}
			{auto && (
				<Tippy content="Preenchido automaticamente pelo sistema" placement="right" animation="fade" duration={200}>
					<i className="fa fa-info-circle ml-2" aria-hidden="true"></i>
				</Tippy>
			)}
			{warning && (
				<Tippy content={warning} placement="right" animation="fade" duration={200}>
					<i className="fa fa-info-circle ml-2" aria-hidden="true"></i>
				</Tippy>
			)}
		</span>
	) : (
		<div className="input-group-lic">
			{required && <span className="required">*</span>}
			<input
				className="form-control"
				style={{ textAlign: 'right' }}
				placeholder={placeHolder}
				type="text"
				value={valorFormatado}
				onChange={setHandler}
				readOnly={false}
				disabled={disabled}
			/>
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
PlanilhaIndicesConstrutivosArea.displayName = 'PlanilhaIndicesConstrutivosArea';
PlanilhaIndicesConstrutivosArea.propTypes = {
	value: PropTypes.any,
	maxSize: PropTypes.number,
	required: PropTypes.bool,
	decimals: PropTypes.number,
	placeHolder: PropTypes.string,
	auto: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	errors: PropTypes.arrayOf(PropTypes.string),
	warning: PropTypes.string
};

export default PlanilhaIndicesConstrutivosArea;
