import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { get, pick } from 'lodash';

import RestricoesAdministrativasForm from 'components/RestricoesAdministrativasForm';
import WarningMessage from 'components/WarningMessage';

import { METAFORMLE } from './MetadataLE';

function InfoMessage({ value, message, messageNao, bloqueanteSeSim, bloqueanteSeNao }) {
	return value === 'sim' && message ? (
		<p className={bloqueanteSeSim ? 'observacao-le-error' : 'observacao-le'}>
			{bloqueanteSeSim && <i className="fa fa-exclamation-circle" style={{ marginRight: '4px' }} />}
			{message}
		</p>
	) : value === 'nao' && messageNao ? (
		<p className={bloqueanteSeNao ? 'observacao-le-error' : 'observacao-le'}>
			{bloqueanteSeNao && <i className="fa fa-exclamation-circle" style={{ marginRight: '4px' }} />}
			{messageNao}
		</p>
	) : null;
}
InfoMessage.displayName = 'InfoMessage';
InfoMessage.propTypes = {
	value: PropTypes.string,
	message: PropTypes.string,
	messageNao: PropTypes.string,
	bloqueanteSeSim: PropTypes.bool,
	bloqueanteSeNao: PropTypes.bool
};

const avisoAlteracaoRevisor = (localData, propName) => {
	if (localData && localData[`${propName}AlteradaRevisor`]) {
		return <WarningMessage>Esta resposta foi alterada pelo revisor.</WarningMessage>;
	}
	return false;
};

avisoAlteracaoRevisor.displayName = 'AvisoAlteracaoRevisor';
avisoAlteracaoRevisor.propTypes = {
	propName: PropTypes.string,
	localData: PropTypes.object
};
export function InfosRestricoesProjetoLE({ onChangeHandler, data: localData, tipoForm = 'le1' }) {
	const restricoesAdministrativas = METAFORMLE.RESTRICOES_ADMINISTRATIVAS;

	const restricoesData = get(localData, 'restricoesAdministrativas', localData);
	const flagIsLegado = get(localData, 'restricoesAdministrativas') === undefined;

	const localChangeHandler = useCallback(
		changes => {
			let tempData = restricoesData;
			if (flagIsLegado) {
				tempData = pick(restricoesData, Object.keys(restricoesAdministrativas));
			}
			if (onChangeHandler) {
				onChangeHandler({ name: 'restricoesAdministrativas', value: Object.assign({}, tempData, changes) });
			}
		},
		[flagIsLegado, onChangeHandler, restricoesAdministrativas, restricoesData]
	);

	return (
		<RestricoesAdministrativasForm
			idMetadata="licenciamento_expresso-restricoes-administrativas"
			data={restricoesData}
			changeHandler={localChangeHandler}
			readOnly={false}
			filter={tipoForm}
		/>
	);
}

InfosRestricoesProjetoLE.displayName = 'InfosRestricoesProjetoLE';
InfosRestricoesProjetoLE.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.array
};
