import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { RestricaoAdministrativaSubfield } from './RestricaoAdministrativaSubfield';

const Inputs = ({ data, fields, onChangeHandler, removeFile, usuarioInterno, emComplementacao }) => (
	<div className="row">
		{fields
			.filter(f => f.type === 'restricoesAdministrativas')
			.map(field => (
				<Fragment key={field.id}>
					<div className={field.classNameContainer || 'col-md-12'}>
						<RestricaoAdministrativaSubfield
							data={data}
							field={field}
							readOnly={usuarioInterno}
							disabled={usuarioInterno}
							emComplementacao={emComplementacao}
							onChangeHandler={onChangeHandler}
							removeFile={removeFile}
						/>
					</div>
				</Fragment>
			))}
	</div>
);
Inputs.displayName = 'Inputs';

Inputs.propTypes = {
	data: PropTypes.object,
	fields: PropTypes.arrayOf(PropTypes.object),
	usuarioInterno: PropTypes.bool,
	qtdMinLinhas: PropTypes.number,
	classNameContainer: PropTypes.string,
	emComplementacao: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	removeFile: PropTypes.func
};
export default Inputs;
