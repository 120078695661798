import createReducer from 'config/createReducer';

import { store } from 'index';

const injectReducerFactory = () => (key, reducer) => {
	store.injectedReducers[key] = reducer;
	store.replaceReducer(createReducer(store.injectedReducers));
};

const getInjectors = () => ({
	injectReducer: injectReducerFactory(store)
});

export default getInjectors;
