import React, { useEffect, useMemo, useState } from 'react';

import { useStore } from 'react-redux';

import logo from 'assets/images/logo.svg';

import useLocalStorage from 'custom-hooks/useLocalStorage';

const ServerUnavailablePage = () => {
	const store = useStore();
	const [filtroOrgaoLS] = useLocalStorage('filtroOrgao');

	const [filtroOrgao, setFiltroOrgao] = useState(null);

	useEffect(() => {
		const subscription = store.$filtros.subscribe(filtros => {
			// pega o valor do Subject $filtros da store
			if (filtros?.filtroOrgao) {
				setFiltroOrgao(filtros.filtroOrgao);
			} else {
				// ou do local storage setado pela página da lista de processos
				if (filtroOrgaoLS) {
					setFiltroOrgao(filtroOrgaoLS);
				}
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [filtroOrgaoLS, store]);

	const paginaInicial = useMemo(
		() => (filtroOrgao ? 'https://prefeitura.poa.br/carta-de-servicos' : '/'),
		[filtroOrgao]
	);

	return (
		<div className="error-screen">
			<h1 className="error-header">
				503 <span className="error-label">Servidor indisponível</span>
			</h1>
			<p>O servidor está momentaneamente indisponível.</p>
			<p>
				<a href={paginaInicial}>Tente novamente mais tarde.</a>
			</p>
			<div className="logo">
				<img src={logo} width="320" alt="logo" />
			</div>
		</div>
	);
};
ServerUnavailablePage.displayName = 'ServerUnavailablePage';
export default ServerUnavailablePage;
