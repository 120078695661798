import React, { useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';
import withMessage from 'components/WithMessage';

import { accessApi } from 'utils/injectApi';

const ValidacaoDocumentos = () => {
	const [loading, setLoading] = useState(false);
	const [docRetorno, setDocRetorno] = useState(null);
	const [codAutentic, setCodAutentic] = useState('');
	const [buscou, setBuscou] = useState(false);

	let { codaut } = useParams();

	const validaDoc = async codigoAutenticacao => {
		setLoading(true);
		setBuscou(false);

		try {
			const retorno = await accessApi(`validaDocumentos/${codigoAutenticacao}`, true);
			setDocRetorno(retorno.data);
			setBuscou(true);
		} catch (e) {
			console.error('Erro no acesso aos documentos: ', e);
			setBuscou(true);
		}

		setLoading(false);
	};

	const mostraInput = !buscou || (buscou && !docRetorno);

	if (codaut && !buscou && !loading) {
		validaDoc(codaut);
	}

	return (
		<Fragment>
			<div className="container">
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>Verificação de documentos</li>
					</ul>
				</div>
				<h1 className="page-header row">Verificação de autenticidade de documentos</h1>
				<section className="content secao-verificacao-documentos">
					<div className="texto-apoio">
						Nesta tela, você pode <strong>verificar a autenticidade</strong> de licenças, certidões ou quaisquer outros
						documentos emitidos pela Prefeitura Municipal de Porto Alegre. Para isso, basta inserir, no campo abaixo, o
						código que consta no documento cuja autenticidade você deseja verificar.
					</div>
					{mostraInput && (
						<div className="row">
							<input
								placeholder="Digite o código de verificação"
								className="form-control input-com-botao"
								value={codAutentic}
								onChange={e => setCodAutentic(e.target.value)}
							/>
							<button className="btn btn-primary botao-apos-input" type="button" onClick={() => validaDoc(codAutentic)}>
								Verificar autenticidade <i className="fa fa-check" aria-hidden="true"></i>
							</button>
						</div>
					)}
					{buscou && !docRetorno && (
						<div className="bloco-sem-resultado row">
							<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Nenhum documento relacionado ao código
							informado foi localizado. Verifique se houve erro de digitação.
						</div>
					)}
					{buscou && docRetorno && (
						<>
							{/anulad?/i.test(docRetorno.status) ? (
								<div className="bloco-sem-resultado row">
									<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
									{'O documento está anulado'}
								</div>
							) : (
								<div>
									<br />
									<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />{' '}
									<a href={docRetorno.urlDocumentoSei} target="_blank" rel="noopener noreferrer">
										Clique aqui para visualizar o documento autenticado
									</a>
									<br />
									<br />
									{docRetorno.urlDocumentoSumarioSei && docRetorno.urlDocumentoAlvaraSei ? (
										<>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
											<a href={docRetorno.urlDocumentoSumarioSei} target="_blank" rel="noopener noreferrer">
												Projeto aprovado
											</a>{' '}
											<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} /> <br />
											<br />
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
											<a href={docRetorno.urlDocumentoAlvaraSei} target="_blank" rel="noopener noreferrer">
												Alvará de Construção
											</a>{' '}
											<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} /> <br />
											<br />
										</>
									) : docRetorno.urlDocumentoSumarioSei ? (
										<>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Caso queira visualizar o documento
											unificado final do processo,{' '}
											<a href={docRetorno.urlDocumentoSumarioSei} target="_blank" rel="noopener noreferrer">
												clique aqui
											</a>{' '}
											<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} /> <br />
											<br />
										</>
									) : docRetorno.urlDocumentoAlvaraSei ? (
										<>
											{docRetorno.tipoDoc === 'licenca' ? (
												<>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Caso queira visualizar o
													documento de alvará do processo,{' '}
												</>
											) : (
												<>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Caso queira visualizar o
													documento de alvará de construção do processo,{' '}
												</>
											)}
											<a href={docRetorno.urlDocumentoAlvaraSei} target="_blank" rel="noopener noreferrer">
												clique aqui
											</a>{' '}
											<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} /> <br />
											<br />
										</>
									) : docRetorno.urlDocumentoCroquiSei ? (
										<>
											{docRetorno.tipoDoc === 'licenca' ? (
												<>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Caso queira visualizar o
													documento de croquido processo,{' '}
												</>
											) : null}
											<a href={docRetorno.urlDocumentoCroquiSei} target="_blank" rel="noopener noreferrer">
												clique aqui
											</a>{' '}
											<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} /> <br />
											<br />
										</>
									) : null}
								</div>
							)}
							<div style={{ margin: '15px -15px' }}>
								<Link to="/validacaoDocumentos">
									<button
										className="btn btn-primary"
										type="button"
										onClick={() => {
											setBuscou(false);
											setDocRetorno(null);
											setCodAutentic('');
										}}
									>
										Realizar outra consulta
									</button>
								</Link>
							</div>
						</>
					)}
					<ShowDebug data={docRetorno} />
				</section>
			</div>
			{loading && <Loader />}
		</Fragment>
	);
};

ValidacaoDocumentos.displayName = 'ValidacaoDocumentos';
ValidacaoDocumentos.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

export default withMessage(ValidacaoDocumentos);
