import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { size, filter, pick } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Selecao from 'components/Selecao';

import { accessApi } from 'utils/injectApi';
import { sortCadastros } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

/* eslint-disable react-hooks/exhaustive-deps */
const Anexo51Field = ({
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler
}) => {
	const [loading, setLoading] = useState(false);
	const [termo, setTermo] = useState('');
	const [pesquisados, setPesquisados] = useState([]);
	const [atividades, setAtividades] = useState([]);

	useEffect(() => {
		carregaAtividades();
	}, []);

	useEffect(() => {}, [atividades]);

	const carregaAtividades = async () => {
		setLoading(true);
		if (size(atividades) === 0) {
			try {
				const retorno = (await accessApi('/collections/anexo51/')).data;
				const anexo51list = sortCadastros(retorno, 'codigo');
				setAtividades(anexo51list);
			} catch (e) {
				setAtividades([]);
				console.error('Erro no acesso ao anexo 51: ', e);
			}
		}
		setLoading(false);
	};

	const filterByTerm51 = event => {
		const v = event.target.value;
		const l =
			size(v) > 0
				? filter(
						atividades,
						o =>
							JSON.stringify(pick(o, ['codigo', 'descricao']))
								.toLowerCase()
								.indexOf((v || '').toLowerCase()) > -1
				  )
				: atividades;
		setTermo(v);
		setPesquisados(l);
	};

	const onChangeAtividade = item => {
		onChangeHandler([
			{ name, value: item },
			{ name: 'errors', value: { [name]: validate(item, required, label) } }
		]);
	};

	return (
		<div className={`${containerClass}`}>
			<div className="col-md-12">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
				<Selecao
					className={
						size(errorList) > 0
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					multiple={false}
					selected={size(value) > 0 ? value : null}
					label={label}
					detailCodigo={''}
					detailDescricao={'codigo'}
					autoShowList={true}
					searchTerm={termo}
					searchList={pesquisados}
					searchTermMinLength={1}
					errorList={false}
					onChangeSearchTerm={event => filterByTerm51(event)}
					onBlurSearchTerm={() => false}
					onSelectItem={item => () => onChangeAtividade(item)}
					onUnselect={() => () => onChangeAtividade(null)}
					loading={loading}
					required={required}
					placeholder={placeHolder}
					readOnly={readOnly || disabled}
				/>
				{errorList && size(errorList) > 0 && (
					<div className={containerClass}>
						<ErrorMessages errorList={errorList} />
					</div>
				)}
			</div>
		</div>
	);
};
Anexo51Field.displayName = 'Anexo51Field';

Anexo51Field.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func
};

export default Anexo51Field;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
