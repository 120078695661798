import { isDebug } from 'utils/tools';

import useMutableState from './useMutableState';
import useSaveImutableState from './useSaveImutableState';

let i = 0;

// eslint-disable-next-line no-unused-vars
const debugLog = (...parms) => false && isDebug && console.debug('(trk-usePersistentState):', ++i, ...parms);
/**
 * Como useState, mas armazena dados na store. Exige que o componente que usa este hook use o Redux.
 * ex:
 * const withReducer = injectReducer({ key: 'licenciamento', reducer });
 * const withSaga = injectSaga({ key: 'licenciamento', saga });
 * export default compose(withSaga, withReducer)(Tracker);
 * @param {String} property key para salvar na store
 * @param {*} value valor para salvar
 * @param {Array<String>} path Array com path para store. Default para []
 * @param {String} idProperty usada pelo useMutableState para diferenciar cada objeto. Default para 'id'
 * @returns {any, function} [estado, setEstado]
 */
const usePersistentState = (property, value, path = ['licenciamento'], idProperty = 'id') => {
	const fullPath = [...path, property];

	/* CUSTOM HOOKS */
	const estado = useMutableState(fullPath, idProperty);
	const setEstado = useSaveImutableState(property, value === undefined ? estado : value, path);

	return [estado, setEstado];
};

export default usePersistentState;
