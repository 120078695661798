import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';
import moment from 'moment';

import Calendario from 'containers/Form/metadata-template/components/Calendario';
import { SelectField } from 'containers/Form/metadata-template/fields';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';
import TextField from 'containers/Form/metadata-template/fields/textField';

import { isDebug } from 'utils/tools';

import { METAVISTORIA } from '.';

export function DetalhesInformacoesProjeto({ tipoSolicitacao, expediente = {}, onChange, data = {}, containerRef }) {
	// const euLicencas = useMemo(() => {
	// 	if (!expediente) return;
	// 	let { licencas = [] } = expediente;

	// 	return (
	// 		licencas
	// 			.sort((e1, e2) => new Date(e2.data).getTime() - new Date(e1.data).getTime())
	// 			//.filter(e => e.ocorrencia && e.ocorrencia.codigo === '2043')
	// 			.map(e => {
	// 				const { tipo, subtipo, descricao } = e.ocorrencia;
	// 				const t = tipo ? tipo.toLowerCase() : null;
	// 				const s = subtipo ? subtipo.toLowerCase() : null;
	// 				const d = descricao ? descricao.toLowerCase() : null;
	// 				const array = [];
	// 				if (t) array.push(t);
	// 				if (s) array.push(s);
	// 				if (d) array.push(d);
	// 				const desc = array.join(' - ');
	// 				return {
	// 					id: e.id,
	// 					data: e.data,
	// 					dados: e,
	// 					display: `[${moment(e.data).format('DD/MM/YYYY')}] ${desc}`
	// 				};
	// 			})
	// 	);
	// }, [expediente]);
	const euProjetos = useMemo(() => {
		let { projetosEdificacao = [], etapas = [] } = expediente;

		return projetosEdificacao
			.sort((e1, e2) => new Date(e2.data).getTime() - new Date(e1.data).getTime())
			.map(ped => {
				const { codigo, descricao } = ped.tipoProjeto;
				const c = codigo ? codigo.toLowerCase() : null;
				const d = descricao ? descricao.toLowerCase() : null;
				const array = [];
				if (c) array.push(c);
				if (d) array.push(d);
				const desc = array.join(' - ');

				const etapa = etapas.find(etapa => etapa.id === ped?.etapa?.id);
				ped.etapa = etapa;

				return {
					id: ped.id,
					data: ped.data,
					dados: ped,
					display: `[${moment(ped.data).format(isDebug ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY')}] ${desc}`
				};
			});
	}, [expediente]);

	let optionsDatas = [];
	let optionsDataset = [];
	if (!tipoSolicitacao || tipoSolicitacao === 'none') {
		optionsDatas = [];
		optionsDataset = undefined;
		data[METAVISTORIA.projetoOuLicenca.name] = 'none';
	} else {
		// inicializa as opções do combo de projetos/licenças
		optionsDatas = [];
		// optionsDatas = tipoSolicitacao === METAVISTORIA.TIPOS_SOLICITACAO.projeto.codigo ? euProjetos : euLicencas;

		// Fixando para seleção de apenas Projetos de Edificação:
		optionsDatas = euProjetos;

		if (!optionsDatas.find(od => od.id === 'outraData')) {
			const outraData = {
				id: 'outraData',
				display: 'Outra data',
				data: '',
				dados: { id: 'outraData' }
			};
			optionsDatas.push(outraData);
		}

		let keysTiposSolicitacao = Object.keys(METAVISTORIA.TIPOS_SOLICITACAO);

		let keyTipoSolicitado = keysTiposSolicitacao.find(
			o => get(METAVISTORIA.TIPOS_SOLICITACAO[o], 'codigo') === tipoSolicitacao
		);

		// Mapeamento DE-PARA para os tipos de solicitação com os códigos de Ocorrência:
		let codigosOcorrencias = get(METAVISTORIA.TIPOS_SOLICITACAO[keyTipoSolicitado], 'codigosOcorrencias');
		if (size(codigosOcorrencias) > 0) {
			optionsDatas = optionsDatas.filter(
				od =>
					od.id === 'outraData' ||
					(get(od, 'dados.etapa.ocorrencia.codigo') && codigosOcorrencias.includes(od.dados.etapa.ocorrencia.codigo))
			);
		}

		let exceptCodigosOcorrencias = get(METAVISTORIA.TIPOS_SOLICITACAO[keyTipoSolicitado], 'exceptCodigosOcorrencias');
		if (size(exceptCodigosOcorrencias) > 0) {
			optionsDatas = optionsDatas.filter(
				od =>
					od.id === 'outraData' ||
					!get(od, 'dados.etapa.ocorrencia.codigo') ||
					!exceptCodigosOcorrencias.includes(od.dados.etapa.ocorrencia.codigo)
			);
		}

		optionsDataset = optionsDatas.map(o => o.dados);
	}

	let options = optionsDatas.map(opt => ({ codigo: opt.id, descricao: opt.display }));

	const isLicencaExpressa = tipoSolicitacao === METAVISTORIA.TIPOS_SOLICITACAO.licencaHoraExpressa.codigo;
	const isVariasPranchas = 'varias' === data[METAVISTORIA.numeroPranchas.name];

	return (
		<>
			{data && data.tipoSolicitacao !== '3' && (
				<div>
					<div
						className="pergunta"
						// className={
						// 	data[METAVISTORIA.projetoOuLicenca.name]?.id === 'outraData'
						// 		? 'form-group col-md-4'
						// 		: 'form-group col-md-6'
						// }
					>
						<SelectField
							name={METAVISTORIA.projetoOuLicenca.name}
							required={METAVISTORIA.projetoOuLicenca.required}
							label={METAVISTORIA.projetoOuLicenca.label}
							options={options}
							onChangeHandler={onChange}
							dataSet={optionsDataset}
							dataPath={'id'}
							value={data[METAVISTORIA.projetoOuLicenca.name]}
						/>
					</div>
					{data[METAVISTORIA.projetoOuLicenca.name]?.id === 'outraData' && (
						<div className="pergunta metragem data-projeto-licenca">
							<div className="d-flex">
								<label className="control-label">
									{METAVISTORIA.dataProjetoOuLicenca.label} <span className="required">*</span>
								</label>
								<Calendario
									onChange={e => {
										let timestamp;
										if (e && e instanceof moment) {
											timestamp = e.valueOf();
										} else if (e && e instanceof Date) {
											timestamp = e.getTime();
										}
										onChange && onChange({ name: METAVISTORIA.dataProjetoOuLicenca.name, value: timestamp });
									}}
									inputClass={'form-control'}
									value={data[METAVISTORIA.dataProjetoOuLicenca.name]}
									name={METAVISTORIA.dataProjetoOuLicenca.name}
									containerRef={containerRef}
								/>
							</div>
						</div>
					)}
				</div>
			)}

			{data && data.tipoSolicitacao === '3' && (
				<div className="pergunta">
					<TextField
						name={METAVISTORIA.numeroLicenca.name}
						required={METAVISTORIA.numeroLicenca.required}
						label={METAVISTORIA.numeroLicenca.label}
						disabled={!isLicencaExpressa}
						readOnly={!isLicencaExpressa}
						onChangeHandler={onChange}
						value={data[METAVISTORIA.numeroLicenca.name]}
					/>
				</div>
			)}

			<div className="pergunta metragem numero-pranchas">
				<div
				// className={
				// 	data[METAVISTORIA.numeroPranchas.name] === 'varias' ? 'form-group col-md-2' : 'form-group col-md-12'
				// }
				>
					<RadioFieldLE
						name={METAVISTORIA.numeroPranchas.name}
						required={METAVISTORIA.numeroPranchas.required}
						label={METAVISTORIA.numeroPranchas.label}
						options={[
							{ codigo: 'unica', descricao: 'Única' },
							{ codigo: 'varias', descricao: 'Mais de uma' }
						]}
						onChangeHandler={onChange}
						value={data[METAVISTORIA.numeroPranchas.name]}
					/>
				</div>
				{data[METAVISTORIA.numeroPranchas.name] === 'varias' && (
					<div className="perguntas metragem descricao-pranchas">
						<div className="d-flex">
							<TextField
								disabled={!isVariasPranchas}
								readOnly={!isVariasPranchas}
								name={METAVISTORIA.descricaoPranchas.name}
								label={METAVISTORIA.descricaoPranchas.label}
								required={METAVISTORIA.descricaoPranchas.required}
								onChangeHandler={onChange}
								value={data[METAVISTORIA.descricaoPranchas.name]}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
DetalhesInformacoesProjeto.displayName = 'DetalhesInformacoesProjeto';
DetalhesInformacoesProjeto.propTypes = {
	tipoSolicitacao: PropTypes.string,
	expediente: PropTypes.object,
	onChange: PropTypes.func,
	data: PropTypes.object,
	containerRef: PropTypes.any
};
