import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';
import moment from 'moment';

import { TIPO_FORMULARIO_PGM_CAMERA_CONCILIACAO } from 'containers/Form/Detalhe';

import { joinPlus } from 'utils/tools';

const diferencaDatasEmDias = (dtInicio, dtFim) =>
	moment.utc(dtFim).startOf('day').diff(moment(dtInicio).startOf('day'), 'days');

function EstadoRequerimento({
	formulario,
	formData,
	qtdInvalidos,
	qtdNovos,
	qtdPerguntasNaoRespondidas,
	lista,
	isPending
}) {
	let {
		labelDeferido,
		labelIndeferido,
		labelEmComplementacao,
		labelRecusado,
		labelExpirado,
		labelAnulado,
		labelEmAndamento
	} = formulario;

	// #region MEMOS
	const isConcluido = useMemo(() => formData.resultado, [formData.resultado]);
	const isResultadoPositivo = useMemo(
		() =>
			isConcluido && ['deferido', 'confirmado', 'concluído', 'concluido'].includes(formData?.resultado?.toLowerCase()),
		[formData, isConcluido]
	);
	const isRecusado = useMemo(
		() => isConcluido && ['recusado', 'rejeitado', 'naoConfirmado'].includes(formData?.resultado?.toLowerCase()),
		[formData, isConcluido]
	);
	const isAnulado = useMemo(
		() => isConcluido && ['anulado'].includes(formData?.resultado?.toLowerCase()),
		[formData, isConcluido]
	);
	const isIndeferido = useMemo(
		() => isConcluido && ['indeferido'].includes(formData?.resultado?.toLowerCase()),
		[formData, isConcluido]
	);

	// se já tem id não tem formData.usuario quer dizer que acabou de criar o formData,
	// então ainda é o mesmo usuário, apenas não recarregou o formData criado
	// portanto deve ser considerado o mesmo email, abaixo

	const rascunhoDocsComplementacao = !!formData.rascunhoDocsComplementacao;

	const isExpirado = useMemo(() => formData.expirado, [formData.expirado]);

	const ehOpcionalPreenchido = d =>
		!d.obrigatorio && !d.descricaoOutroDocumento && !d.invalido && d.filename && !d.idDocumentoSei;

	const qtdOpcionaisPreenchidos = ((formulario || {}).documentos || []).reduce(
		(acc, d) => (acc += ehOpcionalPreenchido(d) ? 1 : 0),
		0
	);

	const isEmComplementacao = useMemo(
		() =>
			size(formData.resultado) === 0 &&
			!rascunhoDocsComplementacao &&
			!!formData.dataComparecimento &&
			!formData.expirado &&
			// size(formData.documentos) > 0 &&
			(formData.processInstance ? size(formData.pendingBpmTasks) > 0 : true) &&
			(qtdInvalidos > 0 || qtdNovos > 0 || qtdPerguntasNaoRespondidas + qtdOpcionaisPreenchidos > 0),
		[
			formData.dataComparecimento,
			formData.expirado,
			formData.pendingBpmTasks,
			formData.processInstance,
			formData.resultado,
			qtdInvalidos,
			qtdNovos,
			qtdOpcionaisPreenchidos,
			qtdPerguntasNaoRespondidas,
			rascunhoDocsComplementacao
		]
	);

	// console.log('====================================');
	// console.log('formData?.resultado: ', formData?.resultado);
	// console.log('rascunhoDocsComplementacao: ', rascunhoDocsComplementacao);
	// console.log('formData.dataComparecimento: ', formData?.dataComparecimento);
	// console.log('formData.expirado: ', formData?.expirado);
	// console.log('qtdInvalidos: ', qtdInvalidos);
	// console.log('qtdNovos: ', qtdNovos);
	// console.log('qtdPerguntasNaoRespondidas: ', qtdPerguntasNaoRespondidas);
	// console.log('qtdOpcionaisPreenchidos: ', qtdOpcionaisPreenchidos);
	// console.log('emComplementacao: ', isEmComplementacao);
	// console.log('====================================');

	const isRegistroCriado = useMemo(() => get(formData, 'data.dataCriacaoRegistroExpedientes'), [formData]);
	const isEtapaCriada = useMemo(() => get(formData, 'data.dataCriacaoEtapa'), [formData]);
	const temLinkPesquisa = useMemo(
		() => isConcluido && get(formData, 'formulario.linkPesquisaSatisfacao'),
		[formData, isConcluido]
	);
	const isPgmCameraMediacao =
		formData.dataCriacaoSei && [TIPO_FORMULARIO_PGM_CAMERA_CONCILIACAO].includes(formulario.idTipoFormulario);

	const faClassName = useMemo(
		() =>
			`fa ${
				isResultadoPositivo
					? 'fa-check'
					: isConcluido
					? 'fa-exclamation-circle'
					: isPending
					? 'fa-refresh fa-spin'
					: isEmComplementacao
					? 'fa-exclamation-circle'
					: isRegistroCriado || isEtapaCriada || isPgmCameraMediacao
					? 'fa-info-circle'
					: ''
			}`,
		[
			isConcluido,
			isEmComplementacao,
			isEtapaCriada,
			isPending,
			isPgmCameraMediacao,
			isRegistroCriado,
			isResultadoPositivo
		]
	);

	const classContainer = useMemo(
		() =>
			!lista
				? ''
				: isConcluido
				? `status${
						['deferido'].includes(formData.resultado?.toLowerCase())
							? ' deferido'
							: ['confirmado'].includes(formData.resultado?.toLowerCase())
							? ' confirmado'
							: ['concluído', 'concluido'].includes(formData.resultado?.toLowerCase())
							? ' concluido'
							: ['indeferido', 'recusado', 'expirado', 'anulado'].includes(formData.resultado?.toLowerCase())
							? ' indeferido'
							: ''
				  }`
				: isPending
				? 'status pending'
				: isEmComplementacao
				? !formData.createdAt || diferencaDatasEmDias(formData.createdAt, formData.dataComparecimento) < 30
					? 'status complementacao error'
					: 'status complementacao warning'
				: isRegistroCriado
				? 'status registro-criado'
				: isEtapaCriada
				? 'status etapa-criada'
				: isPgmCameraMediacao
				? 'status em-andamento'
				: '',
		[
			formData.createdAt,
			formData.dataComparecimento,
			formData.resultado,
			isConcluido,
			isEmComplementacao,
			isEtapaCriada,
			isPending,
			isPgmCameraMediacao,
			isRegistroCriado,
			lista
		]
	);

	const label = useMemo(() => {
		let msg = null;
		if (isConcluido) {
			msg = isResultadoPositivo
				? labelDeferido
				: isRecusado
				? labelRecusado
				: isAnulado
				? labelAnulado
				: isExpirado
				? labelExpirado
				: isIndeferido
				? labelIndeferido
				: null;
			msg = msg || `Processo ${STATUS_PROCESS[formData?.resultado?.toLowerCase()] || formData.resultado}`;
		} else if (isExpirado) {
			msg = labelExpirado || 'Prazo expirado';
		} else if (isPending) {
			msg = 'Obtendo estado do processo...';
		} else if (isEmComplementacao) {
			let needs = [];
			if (qtdInvalidos === 1) {
				needs.push('Um documento marcado como inválido');
			} else if (qtdInvalidos > 1) {
				needs.push(`${qtdInvalidos} documentos marcados como inválidos`);
			}
			if (qtdNovos === 1) {
				needs.push('Um novo documento solicitado');
			} else if (qtdNovos > 1) {
				needs.push(`${qtdNovos} novos documentos solicitados`);
			}
			if (qtdPerguntasNaoRespondidas === 1) {
				needs.push('Uma questão a ser respondida');
			} else if (qtdPerguntasNaoRespondidas > 1) {
				needs.push(`${qtdPerguntasNaoRespondidas} questões a serem respondidas`);
			}
			needs = needs.map((item, index) => (index === 0 ? item : item.toLowerCase()));
			msg = needs.length > 0 && (
				<span>
					<strong>{labelEmComplementacao || 'Processo precisa de complementação'}: </strong>
					<span>{joinPlus(needs)}</span>
				</span>
			);
		} else if (isRegistroCriado) {
			msg = `${lista ? 'E' : 'Processo e'}m homologação`;
		} else if (isPgmCameraMediacao || isEtapaCriada) {
			msg = labelEmAndamento || `${lista ? 'E' : 'Processo e'}m análise`;
		}
		return msg;
	}, [
		formData,
		isAnulado,
		isConcluido,
		isEmComplementacao,
		isEtapaCriada,
		isExpirado,
		isIndeferido,
		isPending,
		isPgmCameraMediacao,
		isRecusado,
		isRegistroCriado,
		isResultadoPositivo,
		labelAnulado,
		labelDeferido,
		labelEmAndamento,
		labelEmComplementacao,
		labelExpirado,
		labelIndeferido,
		labelRecusado,
		lista,
		qtdInvalidos,
		qtdNovos,
		qtdPerguntasNaoRespondidas
	]);
	// #endregion

	return (
		<div>
			<div className={classContainer} title={lista ? formData.motivoIndeferimento : null}>
				<i className={faClassName} style={{ fontSize: '1.1rem' }} /> <span>{label}</span>
			</div>
			{!lista && temLinkPesquisa ? (
				<button
					type="button"
					className="btn-link-pesquisa-externa"
					onClick={e => {
						const link = get(formData, 'formulario.linkPesquisaSatisfacao');
						window.open(link, '_blank');
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<i className="fa  fa-line-chart" aria-hidden></i>
					<span> Pesquisa de satisfação</span>
				</button>
			) : null}
		</div>
	);
}
EstadoRequerimento.displayName = 'EstadoRequerimento';
EstadoRequerimento.propTypes = {
	formulario: PropTypes.object,
	formData: PropTypes.object,
	filtroOrgao: PropTypes.string,
	qtdInvalidos: PropTypes.number,
	qtdNovos: PropTypes.number,
	qtdPerguntasNaoRespondidas: PropTypes.number,
	lista: PropTypes.bool,
	isPending: PropTypes.bool
};

export default EstadoRequerimento;

export const STATUS_DOS_PROCESSOS = [
	// CONCLUIDOS POSITIVAMENTE
	{ codigo: 'deferido', descricao: 'Deferido' },
	{ codigo: 'confirmado', descricao: 'Confirmado' },
	{ codigo: 'concluido', descricao: 'Concluído' },
	{ codigo: 'concluído', descricao: 'Concluído', hidden: true },
	// CONCLUIDOS NEGATIVAMENTE
	{ codigo: 'indeferido', descricao: 'Indeferido' },
	{ codigo: 'recusado', descricao: 'Recusado' },
	{ codigo: 'expirado', descricao: 'Expirado' },
	{ codigo: 'anulado', descricao: 'Anulado', hidden: true },
	{ codigo: 'naoconfirmado', descricao: 'Não Confirmado' },
	// DEMAIS STATUS
	{ codigo: 'emanalise', descricao: 'Em análise', hidden: true },
	{ codigo: 'complementacao', descricao: 'Em complementação' },
	{ codigo: 'rascunho', descricao: 'Rascunho' }
];

export const STATUS_PROCESS = STATUS_DOS_PROCESSOS.reduce((acc, o) => ({ ...acc, [o.codigo]: o.descricao }), {});
