import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';
import styled from 'styled-components';

import EmbedList from 'components/EmbedList';
import IntlNumberInput from 'components/react-intl-number-input';

import AtividadeItemEdit from './AtividadeItemEdit';

const HeaderAtividade = styled.div`
	background-color: #edeff1;
	width: 100%;
	padding: 10px 0px;
	text-align: center;
	font-weight: bold;
	font-size: 13pt;
	text-transform: uppercase;
	border-bottom: ${props => (props.open ? '1px solid !important;' : '0px')};
	i {
		float: right;
		margin-right: 12px;
		margin-top: 5px;
		cursor: pointer;
	}
`;

const AtividadeEdit = ({ id, atividade, onChange, onRemove }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (size(atividade) === 0) {
			setShow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const adicionaAtividadeItem = e => {
		onChange({ ...atividade, atividadeItens: [...atividade.atividadeItens, {}] });
		e.stopPropagation();
	};

	const atualizaAtividadeItem = index => item => {
		onChange({
			...atividade,
			atividadeItens: atividade.atividadeItens.map((atividadeItem, i) => (index === i ? item : atividadeItem))
		});
	};

	const removeAtividadeItem = index => () => {
		onChange({
			...atividade,
			atividadeItens: atividade.atividadeItens.filter((atividadeItem, i) => i !== index)
		});
	};

	return atividade ? (
		<>
			<div className="row">
				<HeaderAtividade onClick={() => setShow(oldState => !oldState)} open={show}>
					{id}
					<i className={`fa fa-angle-${show ? 'up' : 'down'}`} />
					{show && <i className="fa fa-trash" onClick={onRemove} />}
				</HeaderAtividade>
			</div>
			{show && (
				<>
					<EmbedList
						itemList={atividade.atividadeItens}
						emptyMessage="Nenhuma atividade (anexo 5.2) selecionada."
						showAddButton={false}
						noLabel={true}
						novoItem={adicionaAtividadeItem}
						novoItemLabel="adicionar anexos (5.2 / 5.3 / 5.4)"
						itemStyle={{ margin: '0px -16px' }}
					>
						{(item, index) => (
							<AtividadeItemEdit
								atividadeItem={item}
								separatorClass={index + 1 === atividade.atividadeItens.length ? 'separador' : 'separador'}
								onChange={atualizaAtividadeItem(index)}
								onRemove={size(atividade.atividadeItens) > 1 ? removeAtividadeItem(index) : null}
								onAddItem={index + 1 === atividade.atividadeItens.length ? adicionaAtividadeItem : null}
							/>
						)}
					</EmbedList>

					<div className="row atividade">
						<div className="form-group col-sm-6">
							<label>Economias</label>
							<IntlNumberInput
								type="text"
								className="form-control"
								locale="pt-BR"
								precision={0}
								value={atividade.economias}
								onChange={(event, value) => onChange({ ...atividade, economias: value })}
							/>
						</div>
						<div className="form-group col-sm-6">
							<label>Área</label>
							<IntlNumberInput
								type="text"
								className="form-control"
								locale="pt-BR"
								suffix=" m²"
								precision={2}
								value={atividade.area}
								onChange={(event, value) => onChange({ ...atividade, area: value })}
							/>
						</div>
					</div>
				</>
			)}
		</>
	) : null;
};
AtividadeEdit.displayName = 'AtividadeEdit';

AtividadeEdit.propTypes = {
	id: PropTypes.string,
	atividade: PropTypes.object,
	onChange: PropTypes.func,
	onRemove: PropTypes.func
};

export default AtividadeEdit;
