import { size } from 'lodash';

import { TIPO_FORM_CONFIG } from './constantsAP';

const { useState, useCallback, useMemo } = require('react');

const DEFAULT_CONFIG = {
	tipoForm: null,
	showColEco: true,
	showAddEco: true,
	showColPav: true,
	showAddPav: true,
	showColNatEsp: true,
	showAddNatEsp: true,
	showRes: true,
	showNaoRes: true
};

function useTableAreas(tipoForm) {
	const [config, setConfig] = useState(() => {
		let definicao = tipoForm.split(',')[0];
		return TIPO_FORM_CONFIG[definicao] || DEFAULT_CONFIG;
	});

	const getHeaderColspan = useCallback(() => {
		let largura = 0;
		if (config.showColEco) {
			largura++;
		}
		if (config.showColPav) {
			largura++;
		}
		if (config.showColNatEsp) {
			largura++;
		}
		return largura === 0 ? 1 : largura;
	}, [config.showColEco, config.showColNatEsp, config.showColPav]);

	const getSubtotalColspan = useCallback(
		detalhesAreas => {
			let largura = 1; // independente de config.showColNatEsp
			if (config.showColPav) {
				const temPavimentoComMaisDeUmNatEsp = detalhesAreas.economias.reduce(
					(acc, { pavimentos }) =>
						acc || pavimentos.reduce((acc2, { naturezasEspecies }) => acc2 || size(naturezasEspecies) > 1, false),
					false
				);
				if (temPavimentoComMaisDeUmNatEsp) {
					largura++;
				}
			}
			if (config.showColEco) {
				const temEconomiaComMaisDeUmPavimento = detalhesAreas.economias.reduce(
					(acc, { pavimentos }) => acc || size(pavimentos) > 1,
					false
				);
				if (temEconomiaComMaisDeUmPavimento) {
					largura++;
				}
			}
			return largura === 0 ? 1 : largura;
		},
		[config.showColEco, config.showColPav]
	);

	const showCabecalhoResidencialNaoResidencial = useMemo(
		() => config.showRes && config.showNaoRes,
		[config.showNaoRes, config.showRes]
	);

	// linha 1 para tipos de áreas selecionadas (construir, demolir, etc)
	// linha 2 para tipos de economia (residencia e/ou não residencial) - linha opcional
	// linha 3 para tipos de metragens (ad/nad/isenta)
	const getHeaderRowspan = useCallback(() => {
		let rowSpan = 3;
		if (!showCabecalhoResidencialNaoResidencial) {
			rowSpan--;
		}
		return rowSpan;
	}, [showCabecalhoResidencialNaoResidencial]);

	const getFillerRowspan = useCallback(
		detalhesAreas => {
			const { economias } = detalhesAreas;
			const bodyRowspan = economias.reduce(
				(acc, { pavimentos }) =>
					acc + pavimentos.reduce((acc2, { naturezasEspecies }) => acc2 + size(naturezasEspecies), 0),
				0
			);
			return getHeaderRowspan() + bodyRowspan;
		},
		[getHeaderRowspan]
	);

	// linha 1 para subtotais das metragens (ad/nad/isenta)
	// linha 2 para subtotais das economias (residencial e/ou não residencial) - linha opcional
	// linha 3 para subtotais das áreas selecionadas (se mais de uma área) - linha opcional
	const getFooterRowspan = useCallback(
		detalhesAreas => {
			let rowSpan = 3;
			if (!showCabecalhoResidencialNaoResidencial || size(detalhesAreas.areasPlanilha) === 1) {
				rowSpan--;
			}
			return rowSpan;
		},
		[showCabecalhoResidencialNaoResidencial]
	);

	const setTipoForm = useCallback(tipoForm => {
		const definicao = tipoForm.split(',')[0];
		if (definicao && TIPO_FORM_CONFIG[definicao]) {
			const newConfig = {
				...TIPO_FORM_CONFIG[definicao]
			};
			setConfig(newConfig);
		}
	}, []);

	return {
		config,
		setTipoForm,
		getHeaderColspan,
		getSubtotalColspan,
		getHeaderRowspan,
		getFillerRowspan,
		getFooterRowspan
	};
}

export default useTableAreas;
