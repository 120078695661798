import React, { useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';

import { get, size, trim } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import TooltipIcon from 'components/TooltipIcon';

import { accessApi } from 'utils/injectApi';
import { titleCase } from 'utils/tools';

import RadioField from './radioField';

import './responsavelTecnicoNovoStyle.scss';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const TIPOS_REGISTROS = {
	CREA: { key: '1', value: 'CREA' },
	CAU: { key: '2', value: 'CAU' },
	CFT: { key: '3', value: 'CFT' }
};

const TIPOS_RT = [
	{
		codigo: 'CREA',
		descricao: 'CREA',
		descricaoSigla: 'Conselho Regional de Engenharia e Agronomia',
		documento: 'ART',
		descricaoDocumento: 'Anotação de Responsabilidade Técnica',
		placeholderSigla: 'RS000000',
		placeholderDocumento: 'falta definir',
		regexValidacao: /^[A-Z]{2}[0-9]{6}$/,
		regexInput: /^[A-Za-z0-9]{0,8}$/
	},
	{
		codigo: 'CAU',
		descricao: 'CAU',
		descricaoSigla: 'Conselho de Arquitetura e Urbanismo',
		documento: 'RRT',
		descricaoDocumento: 'Registro de Responsabilidade Técnica',
		placeholderSigla: 'A0000000',
		placeholderDocumento: 'falta definir',
		regexValidacao: /^[0-9]{0,4}[A-Z]{1}[0-9]{5,9}$/,
		regexInput: /^[A-Za-z0-9]{0,10}$/
	},
	{
		codigo: 'CFT',
		descricao: 'CFT',
		descricaoSigla: 'Conselho Regional dos Técnicos Industriais',
		documento: 'TRT',
		descricaoDocumento: 'Termo de Responsabilidade Técnica',
		placeholderSigla: 'CPF',
		placeholderDocumento: 'falta definir',
		regexValidacao: /^[0-9]{11}$/,
		regexInput: /^[0-9]{0,14}$/
	}
];
function ResponsavelTecnicoNovoField({
	title,
	label,
	required,
	placeHolder,
	name,
	value: propsValue,
	readOnly,
	disabled,
	onChangeHandler,
	ignoreValidationCrea,
	ignoreValidationCau,
	ignoreValidationCft,
	ocultarDocumento,
	templateErrors = []
}) {
	const [nomeErrors, tipoRegistroErrors, registroErrors, artRrtErrors] = templateErrors;

	const [rt, setRt] = useState(propsValue || {});
	const [remoteRegistroProfissional, setRemoteRegistroProfissional] = useState(null);

	const [loading, setLoading] = useState(false);
	const [statusRegistro, setStatusRegistro] = useState(false);

	const tipoRT = useMemo(() => TIPOS_RT.find(tipo => tipo.codigo === rt.tipoRegistro?.value), [rt.tipoRegistro]);

	useEffect(() => {
		if (size(rt) > 0) {
			let errorsAux = [];
			let temAlgumErro = false;
			let modified = false;
			if (propsValue.nome !== rt.nome) {
				const validationErrors = validateNome(rt.nome, required, 'Nome');
				if (size(validationErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(validationErrors);
				modified = true;
			} else {
				if (size(nomeErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(nomeErrors || []);
			}
			if (propsValue.tipoRegistro?.key !== rt.tipoRegistro?.key) {
				const validationErrors = validateTipoRegistro(rt.tipoRegistro, required, 'Tipo de registro');
				if (size(validationErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(validationErrors);
				modified = true;
			} else {
				if (size(tipoRegistroErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(tipoRegistroErrors || []);
			}
			if (propsValue.registro !== rt.registro) {
				const validationErrors = validateRegistro(rt.registro, required, tipoRT?.descricao, true);
				if (size(validationErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(validationErrors);
				modified = true;
			} else {
				if (size(registroErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(registroErrors || []);
			}
			if (propsValue.artRrt !== rt.artRrt) {
				const validationErrors = validateArtRrt(rt.artRrt, required, tipoRT?.documento || 'Documento');
				if (size(validationErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(validationErrors);
				modified = true;
			} else {
				if (size(artRrtErrors) > 0) {
					temAlgumErro = true;
				}
				errorsAux.push(artRrtErrors || []);
			}

			if (modified) {
				const dados = [
					{ name, value: rt },
					{ name: 'errors', value: { [name]: temAlgumErro ? errorsAux : [] } }
				];
				onChangeHandler(dados);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rt]);

	const setNome = e => {
		const nome = e.target.value;
		setRt(old => ({ ...old, nome: titleCase(nome) }));
		return true;
	};

	const setTipoRegistro = tipo => {
		setRt(old => {
			const novo = { ...old, tipoRegistro: tipo, validRegistro: true };
			if (size(old.registro) > 0) {
				novo.registro = '';
			}
			return novo;
		});
		setStatusRegistro(false);
		setRemoteRegistroProfissional(null);
		return true;
	};

	const setRegistro = e => {
		const registro = e.target.value;
		if (tipoRT.regexInput.test(registro)) {
			setRt(old => ({ ...old, registro }));
			setStatusRegistro(false);
		}
		return true;
	};

	const setDocumento = e => {
		let artRrt = get(e, 'target.value', '');
		setRt(old => ({ ...old, artRrt }));
		return true;
	};

	const checkRegistro = () => {
		if (rt.registro) {
			let ignoreValidation = true;
			if (rt.tipoRegistro?.key === '1') {
				ignoreValidation = ignoreValidationCrea;
			} else if (rt.tipoRegistro?.key === '2') {
				ignoreValidation = ignoreValidationCau;
			} else if (rt.tipoRegistro?.key === '3') {
				ignoreValidation = ignoreValidationCft;
			}
			if (ignoreValidation) {
				if (!rt.validRegistro) {
					setRt(old => ({ ...old, validRegistro: true }));
				}
			} else {
				if (!tipoRT.regexValidacao.test(rt.registro)) {
					if (rt.validRegistro) {
						setRt(old => ({ ...old, validRegistro: false }));
					}
					const validationErrors = {
						[name]: [nomeErrors || [], tipoRegistroErrors || [], ['Formato inválido'], artRrtErrors || []]
					};
					onChangeHandler([
						{ name, value: { ...rt, validRegistro: false } },
						{ name: 'errors', value: validationErrors }
					]);
				} else {
					setTimeout(async () => {
						if (!rt.validRegistro) {
							setRt(old => ({ ...old, validRegistro: true }));
						}
						await consultaRegistro(rt.tipoRegistro, rt.registro);
					});
				}
			}
		}
		return true;
	};

	const consultaRegistro = async (tipoRegistro, registro) => {
		let auxValidRegistro = false;
		let registroErrors = [];

		try {
			if (size(registro) > 0) {
				setLoading(true);
				setStatusRegistro(true);
				const { data } = await accessApi(`acessa-${tipoRegistro.value}/${registro}`, false, { timeout: 10000 });
				setLoading(false);
				let retorno = {
					cpf: data.cpf,
					nome: data.nome,
					numeroRegistro:
						size(data.numeroRegistro) === 0 ? (size(registro) === 0 ? undefined : '') : data.numeroRegistro,
					situacao: data.status === 'ERRO' ? 'Registro inválido' : data.status,
					tipo: data.tipo
				};

				if (size(data.status) > 0) {
					if (data.status === 'ERRO') {
						registroErrors.push(data.message);
					} else if (data.status !== 'Profissional habilitado' && data.status !== 'ATIVO') {
						registroErrors.push(data.status);
					}
				} else if (size(data.error)) {
					registroErrors.push(data.error === 'ERRO' ? 'Registro inválido' : data.error);
				}

				if (size(registroErrors) === 0) {
					setRemoteRegistroProfissional(retorno);
					auxValidRegistro = true;
					if (!rt.nome && retorno.nome) {
						setRt(old => ({ ...old, nome: retorno.nome }));
					}
				} else {
					setRemoteRegistroProfissional(null);
				}
			}
		} catch (error) {
			console.error('error: ', error);
			auxValidRegistro = true;
			setLoading(false);
		}

		if (!auxValidRegistro) {
			setRt(old => ({ ...old, validRegistro: false }));
		}

		onChangeHandler([
			{ name, value: { ...rt, validRegistro: auxValidRegistro } },
			{
				name: 'errors',
				value: { [name]: [nomeErrors || [], tipoRegistroErrors || [], registroErrors, artRrtErrors || []] }
			}
		]);
	};

	const nomeDiferente = () => {
		const nome1 = (rt.nome || '').toLowerCase();
		const nome2 = (get(remoteRegistroProfissional, 'nome') || '').toLowerCase();
		return nome1 !== nome2;
	};

	return (
		<>
			<div className="art-field col-md-12">
				<div className="form-group">
					<h3>{title}</h3>
					<label className="control-label">
						{label}
						{required && <span className="required">*</span>}
					</label>
					<input
						className={`${CLASS_NAME_FORM_CONTROL} ${size(nomeErrors) > 0 ? CLASS_NAME_FORM_CONTROL_ERROR : ''}`}
						type="text"
						placeholder={placeHolder}
						name="nome"
						value={rt.nome || ''}
						onChange={setNome}
						readOnly={readOnly}
						disabled={disabled}
					/>
					{size(nomeErrors) > 0 && <ErrorMessages errorList={nomeErrors} />}
				</div>

				{/**
				 *
				 *
				 */}

				<div className="flex gap-10 space-between">
					<div className="form-group" style={{ verticalAlign: 'top' }}>
						<RadioField
							name="tipoRegistro"
							label={
								<div className="responsavel-tecnico-field">
									Tipo de registro{' '}
									<TooltipIcon>
										<ul>
											{TIPOS_RT.map(tipo => (
												<li key={tipo.codigo}>{`${tipo.descricao}: ${tipo.descricaoSigla}`}</li>
											))}
										</ul>
									</TooltipIcon>
								</div>
							}
							options={TIPOS_RT}
							required={required}
							onChangeHandler={item => item.name !== 'errors' && setTipoRegistro(TIPOS_REGISTROS[item.value])}
							value={rt.tipoRegistro?.value}
							embedded={true}
						/>
						{size(tipoRegistroErrors) > 0 && <ErrorMessages errorList={tipoRegistroErrors} />}
					</div>

					<div className="form-group flex-1" style={{ verticalAlign: 'top' }}>
						<label className="control-label">
							{tipoRT?.descricao || 'Registro Profissional'}
							{TIPOS_RT.map(tipo =>
								tipo.codigo === rt.tipoRegistro?.value ? (
									<TooltipIcon key={tipo.codigo}>
										<ul>
											<li>{tipo.descricaoSigla}</li>
										</ul>
									</TooltipIcon>
								) : null
							)}
							{required && <span className="required">*</span>}
						</label>
						<div className="input-with-icon">
							<input
								className={`${CLASS_NAME_FORM_CONTROL} ${
									size(registroErrors) > 0 ? CLASS_NAME_FORM_CONTROL_ERROR : ''
								}`}
								type="text"
								placeholder={tipoRT?.placeholderSigla || 'Registro Profissional'}
								name="registro"
								value={rt.registro?.toUpperCase() || ''}
								onChange={setRegistro}
								onBlur={checkRegistro}
								readOnly={readOnly || !rt.tipoRegistro?.key}
								disabled={disabled}
							/>
							{loading && <i className="fa fa-refresh fa-spin" />}
							{!loading && statusRegistro && (
								<i
									className={`fa fa-${rt.validRegistro ? 'check' : 'exclamation'}-circle`}
									title={
										get(remoteRegistroProfissional, 'situacao') ||
										(rt.validRegistro ? 'OK' : 'Registro Profissional inválido')
									}
								/>
							)}
						</div>
						{size(registroErrors) > 0 && <ErrorMessages errorList={registroErrors} />}
					</div>

					{!ocultarDocumento && (
						<div className="form-group flex-1" style={{ verticalAlign: 'top' }}>
							<label className="control-label">
								{tipoRT?.documento || 'Documento'}
								{TIPOS_RT.map(tipo =>
									tipo.codigo === rt.tipoRegistro?.value ? (
										<TooltipIcon key={tipo.codigo}>
											<ul>
												<li>{tipo.descricaoDocumento}</li>
											</ul>
										</TooltipIcon>
									) : null
								)}
								{required && <span className="required">*</span>}
							</label>
							<input
								className={`${CLASS_NAME_FORM_CONTROL} ${size(artRrtErrors) > 0 ? CLASS_NAME_FORM_CONTROL_ERROR : ''}`}
								type="text"
								placeholder={tipoRT?.placeholderDocumento || 'Documento'}
								name="artRrt"
								value={rt.artRrt?.toUpperCase() || ''}
								onChange={setDocumento}
								readOnly={readOnly || !rt.tipoRegistro?.key}
								disabled={disabled}
							/>
							{size(artRrtErrors) > 0 && <ErrorMessages errorList={artRrtErrors} />}
						</div>
					)}
				</div>

				{/**
				 *
				 *
				 */}

				{!readOnly && size(get(remoteRegistroProfissional, 'nome')) > 0 && nomeDiferente() && (
					<div className="col-md-12">
						{remoteRegistroProfissional.nome}
						<button
							type="button"
							className={'btn btn-link'}
							onClick={() => setNome({ target: { value: remoteRegistroProfissional.nome } })}
						>
							usar este
						</button>
					</div>
				)}
			</div>
		</>
	);
}

ResponsavelTecnicoNovoField.displayName = 'ResponsavelTecnicoField';

ResponsavelTecnicoNovoField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	ignoreValidationCrea: PropTypes.bool,
	ignoreValidationCau: PropTypes.bool,
	ignoreValidationCft: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	ocultarDocumento: PropTypes.bool,
	templateErrors: PropTypes.array
};

export default ResponsavelTecnicoNovoField;

export const validate = (rt, required, label, title = 'Responsabilidade Técnica') => {
	const { nome, tipoRegistro, registro, artRrt, validRegistro, errors: validErrors } = rt || {};
	let errors = [];

	const nomeErrors = validateNome(nome, required, 'Nome');
	const tipoRegistroErrors = validateTipoRegistro(tipoRegistro, required, 'Tipo de registro');
	const registroErrors = validateRegistro(
		registro,
		required,
		TIPOS_RT.find(tipo => tipo.codigo === tipoRegistro?.value)?.descricao || 'Registro Profissional',
		validRegistro,
		validErrors
	);
	const artRrtErrors = validateArtRrt(
		artRrt,
		required,
		TIPOS_RT.find(tipo => tipo.codigo === tipoRegistro?.value)?.documento || 'Documento',
		title
	);

	if (size(nomeErrors.concat(tipoRegistroErrors).concat(registroErrors).concat(artRrtErrors)) > 0) {
		errors.push(nomeErrors);
		errors.push(tipoRegistroErrors);
		errors.push(registroErrors);
		errors.push(artRrtErrors);
	}

	return errors;
};
const validateNome = (nome, required, label) => {
	let errors = [];
	if (size(trim(nome)) === 0) {
		if (required) {
			errors.push(`${label || 'Nome'} deve ser informado`);
		}
	} else {
		if (size(trim(nome).split(' ').map(trim)) < 2) {
			errors.push('Nome completo deve ser informado');
		}
	}
	return errors;
};

const validateTipoRegistro = (tipoRegistro, required, label) => {
	let errors = [];
	if (size(tipoRegistro) === 0 || !tipoRegistro?.key) {
		if (required) {
			errors.push(`${label || 'Tipo de registro'} deve ser informado`);
		}
	}
	return errors;
};

const validateRegistro = (registro, required, label, validRegistro, errosValidacao = []) => {
	let errors = [];
	if (size(registro) === 0) {
		if (required) errors.push(`${label || 'Registro'} deve ser informado`);
	} else {
		if (!validRegistro) {
			errors.push(`${label} inválido`);
			errors.push(...errosValidacao);
		}
	}
	return errors;
};

const validateArtRrt = (artRrt, required, label) => {
	let errors = [];
	if (size(artRrt) === 0) {
		if (required) {
			errors.push(`${label || 'Documento'} deve ser informado`);
		}
	}
	return errors;
};
