import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { get } from 'lodash';

import CopyButton from 'components/CopyButton';

import { FORMATO_CODIGO_EU } from 'utils/constants';
import { formatCodigoEU } from 'utils/tools';

import { debugLog } from '.';

import 'tippy.js/dist/tippy.css';

const Aba_A_Identificacao = ({ planilha, formulario, onChange }) => {
	const [abaA, setAbaA] = useState({});

	useEffect(() => {
		if (planilha) {
			debugLog('ABA_A', '[planilha]', planilha);
			setAbaA(planilha.a || {});
		}
	}, [planilha, formulario]);

	useEffect(() => {
		debugLog('ABA_A', '[abaA]', abaA);
		onChange({ a: abaA });
	}, [abaA, onChange]);

	useEffect(() => {
		if (formulario) {
			let a = {};
			planilha.a = a;
			planilha.a.eu = formatCodigoEU(get(formulario, 'formData.data.expediente.codigo'));
			planilha.a.inscricaoSMF = get(formulario, 'formData.data.inscricaoSMF.value');
			planilha.a.enderecos = get(formulario, 'formData.data.enderecoCdlList');
			setAbaA(planilha.a || {});
		}
	}, [planilha, formulario]);

	return (
		<>
			<div className="form-group input-group-lic">
				<label htmlFor="exp">
					Endereços do requerimento{' '}
					<Tippy
						content="Se você informar o Expediente Único, este campo será preenchido automaticamente com os endereços deste Expediente Único. Só preencha este campo se você não sabe o número do Expediente Único (ou não tem certeza desse número) ou se este ainda não existe."
						delay={100}
						animation="fade"
					>
						<i className="fa fa-question" aria-hidden="true"></i>
					</Tippy>
				</label>
				{(get(formulario, 'formData.data.enderecoCdlList') || []).map((endereco, i) => (
					<div key={i}>
						<span className="inner-list-item inner-list-item-input">{endereco.enderecoFormatadoCurto}</span>
					</div>
				))}
			</div>
			{get(formulario, 'formData.data.complementoEndereco') && (
				<div className="form-group input-group-lic">
					<label htmlFor="exp">Complemento do endereço </label>
					<div>
						<span className="inner-list-item inner-list-item-input">
							{get(formulario, 'formData.data.complementoEndereco')}
						</span>
					</div>
				</div>
			)}
			<br />
			<div className="form-group input-group-lic">
				<label htmlFor="exp">
					Expediente Único{' '}
					<Tippy
						content="Obtido no Protocolo Setorial do Escritório de Licenciamento, em consulta prévia ou na protocolização do requerimento de DM"
						delay={100}
						animation="fade"
					>
						<i className="fa fa-question" aria-hidden="true"></i>
					</Tippy>
				</label>
				<div className="title-with-button" style={{ backgroundColor: '#eee' }}>
					<span className="inner-list-item inner-list-item-input">
						{formatCodigoEU(get(formulario, 'formData.data.expediente.codigo') || '')}
					</span>
					{get(formulario, 'formData.data.expediente.codigo') && (
						<CopyButton
							texto={get(formulario, 'formData.data.expediente.codigo')}
							formato={FORMATO_CODIGO_EU}
							title={'copiar processo SEI'}
						/>
					)}
				</div>
			</div>
		</>
	);
};
Aba_A_Identificacao.displayName = 'Aba_A_Identificacao';
Aba_A_Identificacao.propTypes = {
	planilha: PropTypes.object,
	formulario: PropTypes.object,
	onChange: PropTypes.func
};
export default Aba_A_Identificacao;
