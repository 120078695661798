import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { CdlField } from '.';

const CdlListField = ({
	title,
	label,
	placeHolder,
	required,
	name,
	value,
	containerClass,
	errorList,
	onChangeHandler,
	readOnly,
	disabled
}) => {
	const [incluindo, setIncluindo] = useState(false);
	const [cdl, setCdl] = useState(null);

	const receiveCdl = obj => {
		if (size(obj) > 0) {
			const cdlAux = obj[0].value;
			setCdl(cdlAux);
			if (size((get(cdlAux, 'numero') || '').toString()) > 0) {
				adicionar(cdlAux);
			}
		}
	};

	const changeHandler = newValue => {
		const aux = [
			{ name, value: newValue },
			{ name: 'errors', value: { [name]: validate(newValue, required, title) } }
		];
		onChangeHandler(aux);
	};

	const removerEndereco = endereco => {
		setIncluindo(false);
		changeHandler(value.filter(e => e.id !== endereco.id));
	};

	const adicionar = (auxCdl = cdl) => {
		const jaTem = (value || []).find(
			item => item.codLogradouro === auxCdl.codLogradouro && item.numero === auxCdl.numero
		);
		if (!jaTem) {
			changeHandler([...(value || []), auxCdl]);
		}
		setCdl(null);
		setIncluindo(false);
	};

	return (
		<div className={containerClass}>
			<div className="col-md-12">
				<h3>{title}</h3>
				{!incluindo && !readOnly && !disabled && (
					<button type="button" className="btn btn-default" onClick={() => setIncluindo(true)}>
						<i className="fa fa-plus"></i> Novo Endereço
					</button>
				)}
				{incluindo && (
					<>
						<div className="row">
							<div className="row">
								<CdlField
									title={'Endereços'}
									label={label}
									required={false}
									placeHolder={placeHolder}
									name={'enderecoCdl'}
									value={cdl}
									onChangeHandler={receiveCdl}
									containerClass={containerClass}
									errorList={false}
									readOnly={false}
								/>
							</div>
						</div>
						<>
							{cdl && cdl.nomeLogradouro && cdl.numero !== null && parseInt(cdl.numero) !== 0 && (
								<button type="button" className="btn btn-link" onClick={adicionar}>
									Adicionar endereço à certidão
								</button>
							)}
							<button type="button" className="btn btn-link" onClick={() => setIncluindo(false)}>
								Cancelar inclusão
							</button>
						</>
					</>
				)}
				{errorList && size(errorList) > 0 && (
					<div className="col-md-12">
						<ErrorMessages errorList={errorList} />
					</div>
				)}
				<ul>
					{size(value) > 0 &&
						value.map((e, idx) => (
							<li key={idx}>
								{e.enderecoFormatadoCurto}
								{!readOnly && !disabled && (
									<button type="button" className="btn btn-icon" onClick={() => removerEndereco(e)}>
										<i className="fa fa-trash"></i>
									</button>
								)}
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};
CdlListField.displayName = 'CdlListField';

CdlListField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.any),
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func
};

export default CdlListField;

export const validate = (value, required, title) => {
	let errors = [];
	if (required) {
		if (size(value) === 0) {
			errors.push(`Você deve informar pelo menos um ${title}`);
		}
	}
	return errors;
};
