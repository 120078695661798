import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { get, size } from 'lodash';

import { EXPEDIENTES_API_URL } from 'environments';

export default function ProjetosExpedienteField({
	label,
	labelEnum,
	required,
	name,
	value,
	isInvalid,
	ultimo,
	disabled,
	readOnly,
	onChangeHandler,
	expedienteValue
}) {
	const [idEU, setIdEU] = useState(expedienteValue?._id);
	const [url, setUrl] = useState(`${EXPEDIENTES_API_URL}/eu/${expedienteValue?._id}?somente-projetos=true`);
	const [permitValidate, setPermitValidate] = useState(false);
	const [erros, setErros] = useState();
	const [limparValue, setLimparValue] = useState(false);

	const [{ data: projetosEdificacao, loading: euLoading, error: euError }, execute] = useAxios(url, {
		manual: true,
		useCache: false
	});

	const options = useMemo(() => {
		if (euLoading) {
			return [];
		}
		let optionsAux = [];

		if (projetosEdificacao) {
			optionsAux = projetosEdificacao.map(p => ({
				codigo: p.id,
				descricao: p.descricao
			}));

			if (ultimo && size(optionsAux) > 0) {
				const ultimoPed = optionsAux[0];
				optionsAux = [ultimoPed];
			}
		}
		if (size(optionsAux) === 0) {
			setLimparValue(true);
		}
		return optionsAux;
	}, [projetosEdificacao, euLoading, ultimo]);

	const onChangeSelect = useCallback(
		ev => {
			let { value } = ev?.target || {};
			const option = options.find(o => o.codigo === value);
			value = value === 'none' ? undefined : { idProjeto: value, descricao: option?.descricao };

			const errorsAux = { [name]: validate(value, label, required, ultimo) };

			const changes = [{ name, value }];
			if (size(errorsAux) > 0) {
				changes.push({ name: 'errors', value: errorsAux });
			}
			onChangeHandler(changes);
		},
		[label, name, onChangeHandler, options, required, ultimo]
	);

	useEffect(() => {
		if (expedienteValue?._id !== idEU) {
			onChangeSelect();
			setTimeout(() => {
				setLimparValue(true);
				setPermitValidate(false);
				setIdEU(expedienteValue._id);
				setUrl(`${EXPEDIENTES_API_URL}/eu/${expedienteValue._id}?somente-projetos=true`);
			}, 100);
		}
	}, [expedienteValue, idEU, limparValue, onChangeSelect]);

	useEffect(() => {
		if (idEU) {
			execute().finally(() => setPermitValidate(true));
		}
	}, [execute, idEU]);

	useEffect(() => {
		if (permitValidate && !euLoading) {
			if (euError) {
				console.error(euError);
				setPermitValidate(false);
			} else if (!projetosEdificacao) {
				setErros({ [name]: ['Expediente Único não encontrado'] });
				setPermitValidate(false);
			} else {
				if (size(projetosEdificacao) === 0) {
					setErros({ [name]: ['Expediente Único não possui projetos registrados e deferidos'] });
					setPermitValidate(false);
				} else {
					setErros({});
				}
			}
		}
	}, [projetosEdificacao, euError, euLoading, name, permitValidate]);

	useEffect(() => {
		if (size(erros) && !euLoading) {
			onChangeHandler({ name: 'errors', value: erros });
		}
	}, [erros, euLoading, onChangeHandler]);

	useEffect(() => {
		if (limparValue) {
			if (value) {
				onChangeHandler([
					{ name, value: null },
					{ name: 'errors', value: { [name]: validate(null, label, required, ultimo) } }
				]);
			}
			setLimparValue(false);
		}
	}, [label, name, onChangeHandler, required, ultimo, limparValue, value]);

	useEffect(() => {
		if (size(options) === 1 && !value) {
			onChangeSelect({ target: { value: options[0].codigo } });
		}
	}, [onChangeSelect, options, value]);

	const valSelectElement = get(value, 'idProjeto', 'none');

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<select
				className={isInvalid ? 'form-control form-control-error' : 'form-control'}
				name={name}
				id={`id_${name}`}
				value={valSelectElement}
				onChange={onChangeSelect}
				disabled={disabled || readOnly || ultimo}
			>
				{options && (
					<>
						<option value="none">
							{size(options) > 0 ? 'Selecione uma opção' : 'Não há projetos deferidos associados a este expediente'}
						</option>
						{options
							.filter(o => !!o)
							.map(opt => (
								<option value={opt.codigo} key={opt.codigo}>
									{opt.descricao}
								</option>
							))}
					</>
				)}
			</select>
		</>
	);
}

ProjetosExpedienteField.displayName = 'ProjetosExpedienteField';

ProjetosExpedienteField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	forTemplate: PropTypes.bool,
	isInvalid: PropTypes.bool,
	readOnly: PropTypes.bool,
	expedienteValue: PropTypes.object,
	ultimo: PropTypes.bool
};

export const validate = (value, label, required, ultimo) => {
	let errors = [];
	if (required) {
		if (!value || value.length === 0) {
			if (ultimo) {
				errors.push('Expediente Único não possui projetos registrados');
			} else {
				errors.push(`${label} deve ser informado`);
			}
		}
	}
	return errors;
};
