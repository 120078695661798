import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import gql from 'graphql-tag';
import { get, size, trim, isNil } from 'lodash';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import Selecao from 'components/Selecao';

import { EXPEDIENTES_API_URL } from 'environments';

import { REGEX_NUMBERS_ONLY } from 'utils/constants';
import apolloClient from 'utils/graphql';
import { accessApi } from 'utils/injectApi';
import { normalizedAddress, permiteInput, format, montaUrlExpedientes } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

/* eslint-disable react-hooks/exhaustive-deps */
const CdlField = ({
	title,
	label = ['Logradouro', 'Número'],
	required,
	placeHolder = ['Nome do logradouro', 'Número do imóvel'],
	name,
	value,
	containerClass,
	pesquisarExpedientes,
	readOnly,
	disabled,
	errorList,
	onChangeHandler,
	history
}) => {
	const popup = useRef();

	const [loading, setLoading] = useState(false);
	const [timer, setTimer] = useState(null);
	const [timer2, setTimer2] = useState(null);
	const [logradourosPesquisados, setLogradourosPesquisados] = useState([]);
	const [logradouro, setLogradouro] = useState(null);
	const [numero, setNumero] = useState(null);
	const [validar, setValidar] = useState(false);

	const [showPopup, setShowPopup] = useState(false);
	const [expedientes, setExpedientes] = useState(null);
	const [query, setQuery] = useState('');

	const searchTermRef = useRef();
	const numeroRef = useRef();

	useEffect(
		() => () => {
			if (timer) {
				clearTimeout(timer);
			}
			if (timer2) {
				clearTimeout(timer2);
			}
		},
		[]
	);

	useEffect(() => {
		if (value) {
			setValidar(true);
			if (!logradouro) {
				setLogradouro(value.nomeLogradouro);
			}
			if (!numero) {
				setNumero(value.numero);
			}
		}
	}, [value]);

	useEffect(() => {
		if (timer) {
			clearTimeout(timer);
		}
		setTimer(
			setTimeout(async () => {
				const enderecosCdl = await buscaEnderecosCdl(logradouro);
				setLogradourosPesquisados(enderecosCdl);
			}, 500)
		);
	}, [logradouro]);

	useEffect(() => {
		if (timer2) {
			clearTimeout(timer2);
		}
		setTimer2(
			setTimeout(async () => {
				if (!isNil(numero)) {
					const enderecoCdl = await obtemEndereco(value, numero);
					sendRetorno(enderecoCdl);
				}
			}, 300)
		);
	}, [numero]);

	useEffect(() => {
		if (showPopup) {
			const div = popup.current;
			if (div) {
				const input = div.querySelectorAll('input');
				input[0].focus();
			}
		}
	}, [showPopup]);

	const setCDLHandler = cdl => async () => {
		setLogradourosPesquisados([]);
		const enderecoCdl = await obtemEndereco(cdl, numero);
		sendRetorno(enderecoCdl);
		if (numeroRef && numeroRef.current) {
			numeroRef.current.focus();
		}
	};

	const unsetCDLHandler = () => async () => {
		setLogradouro(null);
		sendRetorno(null);
		setTimeout(() => {
			if (searchTermRef && searchTermRef.current) {
				searchTermRef.current.focus();
			}
		}, 300);
		if (logradouro) {
			const enderecosCdl = await buscaEnderecosCdl(logradouro);
			setLogradourosPesquisados(enderecosCdl);
		}
	};

	const setNumeroHandler = e => {
		const numAux = e.target.value;
		if (permiteInput(numAux, 'int')) {
			setNumero(numAux);
		}
	};

	const sendRetorno = cdl => {
		onChangeHandler([
			{ name, value: cdl },
			{
				name: 'errors',
				value: { [name]: validate(logradouro, numero, cdl, required, label, title) }
			}
		]);
	};

	const buscaEnderecosCdl = async logradouro => {
		let saida = [];
		if (!value || (value && value.nomeLogradouro !== logradouro)) {
			try {
				const queryCDL = gql`
					query CDL($term: String) {
						list: CdlEnderecoList(term: $term) {
							id
							nomeLogradouro
							enderecoFormatadoCurto
						}
					}
				`;
				if (size(logradouro) > 2) {
					setLoading(true);
					const {
						data: { list }
					} = await apolloClient.query({
						query: queryCDL,
						variables: { term: logradouro },
						fetchPolicy: 'network-only'
					});
					saida = list;
					setLoading(false);
				}
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				// yield put(push('/serverUnavailable'));
			}
		}
		return saida;
	};

	const obtemEndereco = async (cdl, numero) => {
		let saida = null;
		if (size(cdl) > 0) {
			if (cdl.nomeLogradouro !== logradouro || `${cdl.numero}` !== `${numero}`) {
				setLoading(true);
				try {
					const queryCDL = gql`
						query byId($id: String!) {
							cdlLido: CdlEnderecoById(id: $id) {
								id
								nomeLogradouro
								nomeHistorico
								codigoBairro
								nomeBairro
								lado
								nomeBairroHistorico
								numero
								cep
								enderecoFormatado
								enderecoFormatadoCurto
							}
						}
					`;
					const id = { codLogradouro: cdl.codLogradouro || cdl.id, numero };
					const query = {
						query: queryCDL,
						variables: { id: JSON.stringify(id) },
						fetchPolicy: 'network-only'
					};
					const {
						data: { cdlLido }
					} = await apolloClient.query(query);
					saida = { ...cdlLido, codLogradouro: cdlLido.id, id: uuid() };
				} catch (e) {
					console.error('erro no servidor', e);
					// yield put(push('/serverUnavailable'));
				}
				setLoading(false);
			} else {
				saida = cdl;
			}
		}
		return saida;
	};

	const searchExpedientes = async endereco => {
		const parts = (endereco || '').split(',').map(p => trim(p));
		const numero = parts.find(p => isFinite(parseInt(p, 10)));
		const q = parts.find(p => !isFinite(parseInt(p, 10)));

		if (size(q) > 2 && numero) {
			try {
				setLoading(true);
				setExpedientes(null);
				const response = await accessApi(
					`${EXPEDIENTES_API_URL}/consulta-expedientes-por-endereco?q=${normalizedAddress(q)}&numero=${numero}`,
					true
				);
				const expedientesAux = response.data;
				setLoading(false);
				setExpedientes(expedientesAux);
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		}
	};

	const usarExpediente = expediente => {
		let modifiedFields = [];
		if (!value) {
			const endereco1 = expediente.enderecos[0];
			modifiedFields.push({ name, value: endereco1.enderecoFormatadoCurto });
		}
		modifiedFields.push({ name: 'expediente', value: expediente });
		onChangeHandler(modifiedFields);
		onChangeHandler({ name: 'errors', value: { expediente: [] } });
		hidePopupHandler();
	};

	const showPopupHandler = () => {
		if (value) {
			setQuery(value.enderecoFormatadoCurto);
			searchExpedientes(value.enderecoFormatadoCurto);
			setShowPopup(true);
		}
	};

	const hidePopupHandler = () => {
		setExpedientes(null);
		setQuery('');
		setShowPopup(false);
	};

	return (
		<>
			{showPopup && (
				<div className="modal-wrapper">
					<div className="modal modal-lg" ref={popup}>
						<div className="header-group">
							<h1> Pesquisar expedientes </h1>
							<i className="fa fa-times" onClick={() => hidePopupHandler()}></i>
						</div>
						<label className="control-label" htmlFor="root_nome">
							Pesquisar Expediente Único por endereço
						</label>
						<div className="input-group mb-3">
							<input className={CLASS_NAME_FORM_CONTROL} type="text" value={query} readOnly={true} />
							{loading && <i className={'fa fa-refresh fa-spin loading-button'} />}
						</div>
						{expedientes !== null && size(expedientes) === 0 && (
							<div className="col-md-12">Nenhum expediente com o endereço pesquisado</div>
						)}
						{size(expedientes) > 0 && (
							<div className="col-md-12 container-eu-modal">
								{expedientes
									.filter(e => !!e)
									.map((e, i) => (
										<div key={e._id} className="eu-modal">
											<div className="d-flex justify-content-between">
												<button type="button" className={'btn btn-link'} onClick={() => usarExpediente(e)}>
													{i + 1} - {format(e.numero, '000.000000.00.0')} - {format(e.areaPrivativa, '00000')}{' '}
													<em>(escolher este)</em>
												</button>
												<a
													href={montaUrlExpedientes(e._id)}
													target="_blank"
													rel="noopener noreferrer"
													className="btn-expediente"
												>
													<i className="fa fa-external-link"></i> consultar
												</a>
											</div>
											<ul>
												{e.enderecos.map(end => (
													<li key={end.id}>{get(end, 'enderecoFormatadoCurto')}</li>
												))}
											</ul>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			)}{' '}
			<div className={containerClass}>
				<div className="col-md-8">
					<label className="control-label">
						{label[0]}
						{required && <span className="required">*</span>}
					</label>
					<Selecao
						searchTermRef={searchTermRef}
						className={
							size(errorList) > 0 || (validar && size(validate(logradouro, numero, value, required, label, title)) > 0)
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						multiple={false}
						selected={value}
						label={''}
						detailInnerClassName={'inner-list-item inner-list-item-input'}
						detailCodigo={''}
						detailDescricao={'nomeLogradouro'}
						autoShowList={false}
						searchTerm={logradouro}
						searchList={logradourosPesquisados}
						searchTermMinLength={3}
						errorList={false}
						onChangeSearchTerm={e => setLogradouro(e.target.value)}
						onBlurSearchTerm={() => false}
						onSelectItem={setCDLHandler}
						onUnselect={unsetCDLHandler}
						noResetList={true}
						loading={loading}
						required={required}
						placeholder={placeHolder[0]}
						readOnly={disabled || readOnly}
					/>
				</div>
				<div className="col-md-4">
					<label className="control-label">
						{label[1]}
						{required && <span className="required">*</span>}
					</label>
					<div className="input-with-icon">
						<input
							ref={numeroRef}
							className={
								size(errorList) > 0 ||
								(validar && size(validate(logradouro, numero, value, required, label, title)) > 0)
									? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
									: `${CLASS_NAME_FORM_CONTROL}`
							}
							type="text"
							placeholder={placeHolder[1]}
							name={name}
							value={numero || ''}
							onChange={setNumeroHandler}
							readOnly={readOnly}
							disabled={disabled}
						/>
						{loading && <i className="fa fa-refresh fa-spin" />}
					</div>
				</div>
				{!(readOnly || disabled) && pesquisarExpedientes && value && !!value.numero && (
					<>
						{!showPopup && (
							<div className="col-md-12">
								<button
									type="button"
									className={'btn btn-link'}
									style={Object.assign({ marginLeft: 0, marginTop: '5px' }, loading ? { color: 'grey' } : {})}
									onClick={loading ? () => false : showPopupHandler}
								>
									{loading
										? 'carregando endereço selecionado...'
										: `Pesquisar expedientes para '${value.enderecoFormatadoCurto}'`}
								</button>
							</div>
						)}
					</>
				)}
				{errorList && size(errorList) > 0 && (
					<div className="col-md-12">
						<ErrorMessages errorList={errorList} />
					</div>
				)}
			</div>
		</>
	);
};

CdlField.displayName = 'CdlField';

CdlField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	pesquisarExpedientes: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	history: PropTypes.any
};

export default withRouter(CdlField);

const isValidLogradouro = logradouro => size(logradouro) === 0 || size(trim(normalizedAddress(logradouro))) > 0;
const isValidNumero = numero => size(numero) === 0 || REGEX_NUMBERS_ONLY.test(numero);

export const validate = (
	logradouro = '',
	numero,
	cdl,
	required,
	label = ['Logradouro', 'Número'],
	title = 'Endereço'
) => {
	const numeroAsString = `${numero ? numero : ''}`;
	const errors = [];
	if (!isValidLogradouro(logradouro)) {
		errors.push(`${label[0]} inválido. Ex: Av. Ipiranga`);
	}
	if (!isValidNumero(numeroAsString)) {
		errors.push(`${label[1]} inválido. Ex: 1200`);
	}
	if (required) {
		if (size(logradouro) === 0) {
			errors.push(`${label[0]} deve ser informado`);
		}
		if (size(numeroAsString) === 0 || numeroAsString === '0') {
			errors.push(`${label[1]} deve ser informado`);
		}
		if (size(cdl) === 0) {
			errors.push(`Você deve pesquisar e selecionar um ${title}`);
		}
	}
	return errors;
};
