/**
 * Diferente do outro componente de Paginação, este usa as propriedades totalItems (no lugar do count),
 * page com a página atual (base 0) e o método setPage para atualizaçao da mesma no componente parent.
 * O componente parent deve possuir uma variável de estado com a página atual.
 * Um exemplo implementado deste componente encontra-se na Inbox, como abaixo:

	 const [page, setPage] = useState(0);
	...
	<PaginationV2
		totalItems={totalPendingTasks}
		pageSize={PAGE_SIZE}
		page={page}
		setPage={setPage}
	/>
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { trim } from 'lodash';

import { permiteInput } from 'utils/tools';

const PaginationV2 = ({ totalItems, pageSize, page, setPage, loading }) => {
	const totalPages = useRef(1);
	const [pInt, setPInt] = useState(page + 1);
	const [timer, setTimer] = useState(null);
	const [paginaAnterior, setPaginaAnterior] = useState(null);
	const [pStr, setPStr] = useState(`${page + 1}`);
	const [invalido, setInvalido] = useState(false);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (totalItems) {
			const pageSizeInt = typeof pageSize === 'string' ? parseInt(pageSize) : pageSize;
			let parcial = totalItems / pageSizeInt;
			if (parcial !== parseInt(parcial)) {
				parcial = parseInt(parcial) + 1;
			}
			totalPages.current = parcial;
			setLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalItems]);

	const isInvalido = useCallback((str, max) => {
		const muitoGrande = parseInt(str || '0') > max;
		const cond1 = str.length === 0;
		const cond2 = parseInt(str || 0) === 0;
		const cond3 = muitoGrande;
		const cond4 = !permiteInput(str, 'validnumber', 0, `${max}`.length);
		return cond1 || cond2 || cond3 || cond4;
	}, []);

	useEffect(() => {
		if (loaded) {
			const invalid = isInvalido(pStr, totalPages.current);
			setInvalido(invalid);
			if (timer) {
				clearTimeout(timer);
			}
			setTimer(
				setTimeout(() => {
					if (!invalid) {
						setPaginaAnterior(pInt);
						setPInt(parseInt(pStr));
					}
				}, 500)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pStr, loaded]);

	useEffect(() => {
		setPage(pInt - 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pInt]);

	const onKeyDown = e => {
		if (e.keyCode === 27) {
			//esc key
			setPStr(`${paginaAnterior}`);
		}
		if (e.keyCode === 38 && pInt < totalPages.current) {
			// upper key
			setPStr(`${pInt + 1}`);
		}
		if (e.keyCode === 40 && pInt > 1) {
			// down key
			setPStr(`${pInt - 1}`);
		}
	};

	const PreviousPage = () =>
		pInt > 1 ? (
			<button type="button" className="btn btn-default page previous" onClick={() => setPStr(`${pInt - 1}`)}>
				Anterior
			</button>
		) : (
			<button type="button" className="btn btn-default page previous disabled">
				Anterior
			</button>
		);
	PreviousPage.displayName = 'PreviousPage';

	const NextPage = () =>
		pInt < totalPages.current ? (
			<button type="button" className="btn btn-default page next" onClick={() => setPStr(`${pInt + 1}`)}>
				Próxima
			</button>
		) : (
			<button type="button" className="btn btn-default page next disabled">
				Próxima
			</button>
		);
	NextPage.displayName = 'NextPage';

	const pageSizeInt = typeof pageSize === 'string' ? parseInt(pageSize) : pageSize;

	return loading ? (
		<h3>Preparando paginação... aguarde</h3>
	) : (
		<>
			{totalItems <= pageSizeInt ? null : (
				<nav className="pagination" style={{ display: 'flex', justifyContent: 'center' }}>
					<PreviousPage />
					<div>
						<input
							id={'pagina'}
							value={pStr}
							style={{
								borderRadius: '4px',
								border: '1px solid lightgray',
								padding: '8px 50px 8px 16px',
								width: '88px',
								textAlign: 'right',
								marginRight: '-50px',
								marginLeft: '8px',
								color: invalido ? 'red' : 'black'
							}}
							onChange={e => setPStr(trim(e.target.value))}
							onKeyDown={onKeyDown}
							onFocus={e => e.target.select()}
						/>
						<span style={{ marginRight: '32px', pointerEvents: 'none' }}>{` / ${totalPages.current}`}</span>
					</div>
					<NextPage />
				</nav>
			)}
		</>
	);
};
PaginationV2.displayName = 'PaginationV2';
PaginationV2.propTypes = {
	totalItems: PropTypes.number,
	pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	page: PropTypes.number,
	setPage: PropTypes.func,
	nBts: PropTypes.number,
	loading: PropTypes.bool
};

export default PaginationV2;
