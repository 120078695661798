import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import Selecao from 'components/Selecao';

import { ATIVIDADES_LE5 } from 'containers/LicenciamentoExpresso/MetadataLE';

import useMutableState from 'custom-hooks/useMutableState';
import useQueryStore from 'custom-hooks/useQueryStore';

import { PLANO_DIRETOR_API_URL } from 'environments';

import { isDebug } from 'utils/tools';

import PlanoDiretorAtividades from './PlanoDiretorAtividade';
import PlanoDiretorRegimeUrbanistico from './PlanoDiretorRegimeUrbanistico';

let i = 0;

const debugLog = (...parms) => false && isDebug && console.debug('(trk-PLANO_DIRETOR_SUBUNIDADES):', ++i, ...parms);

const PlanoDiretorSubunidades = ({
	data: dataProps = {},
	dataAnterior,
	readOnly,
	setDataHandler,
	errosSubmissao: errosSubmissaoProps,
	showAtividadesPlanoDiretor = false,
	filtroAtividadesPlanoDiretor,
	showIndices,
	usuarioInterno = false
}) => {
	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	/* CUSTOM HOOKS */
	const errors = useMutableState(['licenciamento', 'errors']);
	const dadosPlanoDiretor = useMutableState(['licenciamento', 'dadosPlanoDiretor'], 'codDte');
	const {
		data: subunidadesStore,
		loading: subunidadesLoading,
		error: subunidadesError,
		changeUrl
	} = useQueryStore(null, 'subunidades');

	const isLicencasExpressas = useMutableState(['licenciamento', 'isLicencasExpressas']);

	useEffect(() => {
		// aguarda ter todas as informações necessárias para só então disparar a query
		if (dataProps.macrozona && dataProps.ueu && dataProps.qtr) {
			changeUrl(`${PLANO_DIRETOR_API_URL}/subsreg/${dataProps.macrozona}/${dataProps.ueu}/${dataProps.qtr}`);
		}
	}, [changeUrl, dataProps.macrozona, dataProps.qtr, dataProps.ueu]);

	/* STATES */
	const [termoPesquisa, setTermoPesquisa] = useState(null);
	const [expanded, setExpanded] = useState(null);

	const setLocalDataHandler = indice => (value, property) => {
		const novoValor = dataProps.dadosSubunidades.map((dadosSU, index) => {
			if (indice === index) {
				let novo = dadosSU;
				if (property === 'dadosSubunidade') {
					novo = Object.assign(dadosSU, value);
				} else {
					novo = { ...dadosSU, [property]: value };
				}
				return novo;
			} else {
				return dadosSU;
			}
		});
		debugLog('<setLocalDataHandler>', indice, property, value, novoValor);
		setDataHandler(novoValor, 'dadosSubunidades');
	};

	const pull = useCallback(
		({ id }) => {
			const filtrado = (dataProps.dadosSubunidades || []).filter(item => item.id !== id);
			debugLog('[dataProps.dadosSubunidades]', filtrado);
			setDataHandler(filtrado, 'dadosSubunidades');
		},
		[dataProps.dadosSubunidades, setDataHandler]
	);

	const push = useCallback(
		subunidade => {
			const novoObj = { id: uuid(), macrozona: dataProps.macrozona, ueu: dataProps.ueu, subunidade };
			const newValue = [...(dataProps.dadosSubunidades || []), novoObj];
			debugLog('dadosSubunidades', newValue);
			setDataHandler(newValue, 'dadosSubunidades');
			setExpanded(novoObj.id);
		},
		[dataProps.dadosSubunidades, dataProps.macrozona, dataProps.ueu, setDataHandler]
	);

	const filteredList = useMemo(() => {
		let lista = subunidadesStore || [];
		const removerSelecionados = item =>
			!dataProps.dadosSubunidades?.find(itemSelecionado => itemSelecionado.subunidade.subunidade === item.subunidade);
		const somentePesquisados = item => {
			const temCodigo =
				JSON.stringify(item)
					.toLowerCase()
					.indexOf((termoPesquisa || '').toLowerCase()) > -1;
			return temCodigo;
		};
		const ordenarPorCodigo = (item1, item2) => (item1.codigo > item2.codigo ? 1 : -1);
		return lista.filter(removerSelecionados).filter(somentePesquisados).sort(ordenarPorCodigo);
	}, [dataProps.dadosSubunidades, subunidadesStore, termoPesquisa]);

	return (
		<>
			{dataProps ? (
				<>
					{/* Plano Diretor */}
					<div className={'grupoBpmTasks grupoEV0200'}>
						<div style={{ flex: '1' }}>
							<h3>Informe as subunidades que incidem no terreno</h3>
						</div>
						{dadosPlanoDiretor && (
							<div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
								<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
									Macrozona
									{dataProps.macrozona ? <span style={{ color: '#cc3e3e', fontWeight: 'bold' }}>*</span> : null}:{' '}
									{dadosPlanoDiretor.mz}
								</span>
								<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
									UEU{dataProps.ueu ? <span style={{ color: '#cc3e3e', fontWeight: 'bold' }}>*</span> : null}:{' '}
									{dadosPlanoDiretor.ueu}
								</span>
								<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
									Quarteirão{dataProps.ueu ? <span style={{ color: '#cc3e3e', fontWeight: 'bold' }}>*</span> : null}:{' '}
									{dadosPlanoDiretor.qtr}
								</span>
							</div>
						)}
						{subunidadesLoading ? (
							'Obtendo subunidades do Plano Diretor'
						) : subunidadesError ? (
							'Não foi possível recuperar as subunidades do plano diretor. Tente novamente mais tarde.'
						) : (
							<>
								{size(filteredList) > 0 && (
									<div>
										<div className="form-group form-group-select-subunidades">
											<label className="label-anexos">Subunidades</label>
											<Selecao
												selected={null}
												detailDescricao={'subunidade'}
												autoShowList={true}
												searchTerm={termoPesquisa}
												searchList={filteredList}
												searchTermMinLength={0}
												errorList={false}
												onChangeSearchTerm={event => setTermoPesquisa(event.target.value)}
												onBlurSearchTerm={() => false}
												onSelectItem={item => () => push(item)}
												onUnselect={item => () => pull(item)}
												loading={subunidadesLoading}
												required={true}
												placeholder={'Selecione a subunidade'}
												readOnly={readOnly}
												multiple={false}
												showHyphen={false}
											/>
											{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'dadosSubunidades', false)} />}
											<ErrorMessages
												errorList={(errosSubmissao || []).filter(e =>
													e.campo === 'planoDiretorVazio' ? e.message : null
												)}
											/>
										</div>
									</div>
								)}
								{(dataProps?.dadosSubunidades || []).map((dadosSU, indice) => (
									<div
										key={indice}
										style={{
											border: '1px solid rgba(105, 124, 128, 0.5)',
											backgroundColor: 'rgb(238, 238, 238)',
											marginBottom: '8px'
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
											onClick={() => setExpanded(old => (old === dadosSU.id ? null : dadosSU.id))}
										>
											<div style={{ paddingLeft: '10px', lineHeight: '46px' }}>
												<i
													className={`fa fa-angle-${dadosSU.id === expanded ? 'up' : 'down'}`}
													style={{ marginRight: '10px', fontSize: '14px', fontWeight: 'bold' }}
												/>
												<b>
													Subunidade
													{dataProps.macrozona && dataProps.ueu && (
														<span style={{ color: '#cc3e3e', fontWeight: 'bold' }}>*</span>
													)}
													: {dadosSU.subunidade.subunidade}
												</b>
												{size(errors) > 0 && (
													<ErrorMessages errorList={get(errors, `dadosSubunidade-${dadosSU.id}`, false)} />
												)}
											</div>
											{!readOnly && size(subunidadesStore) > 1 && (
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<button
														type="button"
														onClick={() => pull(dadosSU)}
														style={{
															color: '#cc3e3e',
															display: 'inline-block',
															backgroundColor: 'transparent',
															border: '0',
															paddingRight: '10px',
															fontSize: '20px',
															lineHeight: '44px'
														}}
													>
														<i className="fa fa-trash" />
													</button>
												</div>
											)}
										</div>
										{expanded === dadosSU.id && (
											<>
												<PlanoDiretorRegimeUrbanistico
													data={dadosSU}
													dataAnterior={(dataAnterior?.dadosSubunidades || []).find(sbAnt => sbAnt.id === dadosSU.id)}
													readOnly={readOnly}
													setDataHandler={setLocalDataHandler(indice)}
													errosSubmissao={errosSubmissao}
													showIndices={showIndices}
													usuarioInterno={usuarioInterno}
												/>
												{(showAtividadesPlanoDiretor || isLicencasExpressas) && (
													<PlanoDiretorAtividades
														data={dadosSU}
														dataAnterior={(dataAnterior?.dadosSubunidades || []).find(sbAnt => sbAnt.id === dadosSU.id)}
														readOnly={readOnly}
														setDataHandler={setLocalDataHandler(indice)}
														showAtividadesItems={filtroAtividadesPlanoDiretor || ATIVIDADES_LE5}
														disabled={readOnly}
														usuarioInterno={usuarioInterno}
													/>
												)}
											</>
										)}
									</div>
								))}
							</>
						)}
					</div>
				</>
			) : (
				<h2>Aguardando dados...</h2>
			)}
		</>
	);
};
PlanoDiretorSubunidades.displayName = 'Plano Diretor Subunidades';

PlanoDiretorSubunidades.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	readOnly: PropTypes.bool,
	setDataHandler: PropTypes.func,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	filtroAtividadesPlanoDiretor: PropTypes.array,
	showAtividadesPlanoDiretor: PropTypes.bool,
	showIndices: PropTypes.bool,
	usuarioInterno: PropTypes.bool
};

export default PlanoDiretorSubunidades;
