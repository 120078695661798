import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { get, size, trim, isFinite } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import WarningMessage from 'components/WarningMessage';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { normalizedAddress, format, montaUrlExpedientes, isDebug } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const EnderecoExpedientesField = ({
	title,
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler,
	history
}) => {
	const popup = useRef();

	const [query, setQuery] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [loading, setLoading] = useState(false);
	const [expedientes, setExpedientes] = useState(null);

	useEffect(() => {}, []);

	const setEnderecoHandler = e => {
		let { value } = e.target;
		if ((value || '').split(',').length > 2) {
			return false;
		}
		onChangeHandler({ name, value });
		onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label, title) } });
	};

	const pesquisarExpedientes = async endereco => {
		const parts = (endereco || '').split(',').map(p => trim(p));
		const numero = parts.find(p => isFinite(parseInt(p, 10)));
		const q = parts.find(p => !isFinite(parseInt(p, 10)));

		if (size(q) > 2 && numero) {
			try {
				setLoading(true);
				setExpedientes(null);
				const response = await accessApi(
					`${EXPEDIENTES_API_URL}/consulta-expedientes-por-endereco?q=${normalizedAddress(q)}&numero=${numero}`,
					true
				);
				const expedientesAux = response.data;
				setLoading(false);
				setExpedientes(expedientesAux);
			} catch (e) {
				setLoading(false);
				console.error('erro no servidor', e);
				history.push('/serverUnavailable');
			}
		}
	};

	const usarExpediente = expediente => {
		let modifiedFields = [];
		if (!value) {
			const endereco1 = expediente.enderecos[0];
			modifiedFields.push({ name, value: endereco1.enderecoFormatadoCurto });
		}
		modifiedFields.push({ name: 'expediente', value: expediente });
		onChangeHandler(modifiedFields);
		onChangeHandler({ name: 'errors', value: { expediente: [] } });
		hidePopupHandler();
	};

	const showPopupHandler = () => {
		if (value) {
			setQuery(value);
			pesquisarExpedientes(value);
		} else {
			setQuery('');
		}
		setShowPopup(true);
	};

	const hidePopupHandler = () => {
		setExpedientes(null);
		setQuery('');
		setShowPopup(false);
	};

	const trataEnter = e => {
		if (e.key === 'Enter') {
			pesquisarExpedientes(query);
		} else {
			if (e.key === 'Escape') {
				setQuery('');
				hidePopupHandler();
			}
		}
		return true;
	};

	useEffect(() => {
		if (showPopup) {
			const div = popup.current;
			if (div) {
				const input = div.querySelectorAll('input');
				input[0].focus();
			}
		}
	}, [showPopup]);

	return (
		<>
			{showPopup && (
				<div className="modal-wrapper">
					<div className="modal modal-lg" ref={popup}>
						<div className="header-group">
							<h1> Pesquisar expedientes </h1>
							<i className="fa fa-times" onClick={() => hidePopupHandler()}></i>
						</div>
						<br />
						<div className="input-group mb-3">
							<label className="control-label" htmlFor="root_nome">
								Pesquisar Expediente Único por endereço
							</label>
							<input
								className={CLASS_NAME_FORM_CONTROL}
								type="text"
								value={query || ''}
								onChange={e => setQuery(e.target.value)}
								onKeyDown={trataEnter}
								placeholder="Informe um endereço, como em Av. Julio de Castilhos, 505"
							/>
							<div className="input-group-append">
								<button type="button" className="btn btn-primary" onClick={() => pesquisarExpedientes(query)}>
									Pesquisar
								</button>
							</div>
						</div>
						{expedientes !== null && size(expedientes) === 0 && (
							<div className="col-md-12">Nenhum expediente com o endereço pesquisado</div>
						)}
						{size(expedientes) > 0 && (
							<div className="col-md-12 container-eu-modal">
								{expedientes
									.filter(e => !!e)
									.map((e, i) => (
										<div key={e._id} className="eu-modal">
											<div className="d-flex justify-content-between">
												<button type="button" className={'btn btn-link'} onClick={() => usarExpediente(e)}>
													{i + 1} - {format(e.numero, '000.000000.00.0')} - {format(e.areaPrivativa, '00000')}{' '}
													<em>(escolher este)</em>
												</button>
												{e.anulado && <WarningMessage>EXPEDIENTE ANULADO</WarningMessage>}
												<a
													href={montaUrlExpedientes(e._id)}
													target="_blank"
													rel="noopener noreferrer"
													className="btn-expediente"
												>
													<i className="fa fa-external-link"></i> consultar
												</a>
											</div>
											<ul>
												{e.enderecos.map(end => (
													<li key={end.id}>
														{get(end, 'enderecoFormatadoCurto')}
														{isDebug ? (
															<span className="debug-message">{` (codLogradouro: ${end.codigoLogradouro})`}</span>
														) : (
															''
														)}
													</li>
												))}
											</ul>
											{e.anulado && (
												<ErrorMessages
													type="warning"
													errorList={[
														{
															message: 'O Expediente Único informado possui atualmente o status de ANULADO.',
															type: 'warning'
														},
														{
															message:
																'O requerimento pode ser continuado, mas certifique-se que o Expediente Único está correto.',
															type: 'warning'
														}
													]}
												/>
											)}
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			)}
			<div className={containerClass}>
				<div className={'col-md-12'}>
					<label className="control-label">
						{title}
						{required && <span className="required">*</span>}
					</label>
					<input
						className={
							size(errorList) > 0 || size(validate(value, false, label, title)) > 0
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder}
						name={name}
						value={value || ''}
						onChange={setEnderecoHandler}
						readOnly={readOnly}
						disabled={disabled}
					/>
					{loading && <i className={'fa fa-refresh fa-spin refresh-field'} />}
					{!(readOnly || disabled) && (
						<>
							{!showPopup && (
								<button
									type="button"
									className={'btn btn-link'}
									style={{ marginLeft: 0, marginTop: '5px' }}
									onClick={showPopupHandler}
								>
									Pesquisar expediente para esse endereço?
								</button>
							)}
							{showPopup && (
								<button type="button" className={'btn btn-link'} onClick={hidePopupHandler}>
									cancelar pesquisa
								</button>
							)}
						</>
					)}
				</div>
				{errorList && size(errorList) > 0 && (
					<div className="col-md-12">
						<ErrorMessages errorList={errorList} />
					</div>
				)}
			</div>
		</>
	);
};

EnderecoExpedientesField.displayName = 'PesquisaExpedientePorEndereco';

EnderecoExpedientesField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	history: PropTypes.any
};

export default withRouter(EnderecoExpedientesField);

export const validate = (endereco, required, label = ['Logradouro', 'Número'], title = 'Endereco') => {
	const errors = [];
	if (size(endereco) > 0) {
		const parts = endereco.split(',').map(p => trim(p));
		if (size(parts) !== 2) {
			errors.push(`${title} inválido. Ex: Av. Ipiranga, 1200`);
		} else {
			const [logradouro, numero] = parts;
			if (!isFinite(parseInt(numero, 10))) {
				errors.push(`${label[1]} deve ser informado após a vírgula`);
			}
			if (isFinite(parseInt(logradouro, 10))) {
				errors.push(`${label[0]} deve começar com uma letra`);
			}
			if (size(trim(normalizedAddress(logradouro))) === 0) {
				errors.push(`${label[0]} deve ser informado antes da vírugla`);
			}
			if (size(trim(normalizedAddress(logradouro))) < 3) {
				errors.push(`${label[0]} deve ter ao menos 3 caracteres`);
			}
		}
	} else {
		if (required) {
			errors.push(`${title} deve ser informado`);
		}
	}
	return errors;
};
