import { camelCase, get } from 'lodash';

import { TIPOS_PORTE } from './licenca';
/**
 * validacoes extras para calcular a lac
 * obs:
 * o que fazer se não for competencia municipal?
 * o que fazer se 'não incidencia'?
 * o que fazer se 'única' ex. 4812,00?
 * o que fazer se valor da ufm é um 'x'?
 *
 * @param {*} form
 */
export function validateRules(form) {
	let ok = true;
	let erros = [];

	const codram = get(form, 'codram.value');
	const porte = camelCase(get(form, 'porte.value', ''));
	//const tipoLicenca = get(form, 'tipoLicenca.value');
	let valorUfm = get(form, 'valorUfm.value');

	//nao incidencia
	if (porte === TIPOS_PORTE.naoIncidencia) {
		ok = false;
		erros.push(
			'Conforme Resolução CONSEMA 372/2018  a atividade é não incidente de licenciamento ambiental para o porte informado. Caso desejado poderá ser requerido Declaração de Isenção'
		);
	}
	//valor único do porte
	if (porte === TIPOS_PORTE.especialUnico) {
		ok = true;
		// erros.push('O porte especial único não pode ser emitido por esse modo.');
	}
	//valor zero ou inválido, normalmente o 'x' na tabela
	valorUfm = parseInt(valorUfm, 10);
	if (ok && !valorUfm) {
		ok = false;
		erros.push(
			'Conforme Artigo 11 da Lei Nº 8.267/1998 Art. 11, somente as atividades de baixo e médio impacto ambiental e de mínimo e pequeno porte poderão estar sujeitas à Licença Única (LU)'
		);
	}
	// competencia
	if (ok && codram && porte) {
		const competenciaMunicipal = codram.competenciaMunicipal || [];
		const isCompetenciaMunicipal = competenciaMunicipal.includes(porte);
		if (!isCompetenciaMunicipal) {
			ok = false;
			erros.push(
				' Esta solicitação é de competência estadual e deve ser realizada através do SOL (Sistema Online de Licenciamento Ambiental) disponibilizado pelo Estado.'
			);
		}
	}

	return [ok, erros];
}
