import { push } from 'connected-react-router';
import gql from 'graphql-tag';
import { findIndex, get, size, omit, pick, set, cloneDeep } from 'lodash';
import moment from 'moment';
import { takeLatest, takeEvery, take, call, put } from 'redux-saga/effects';
import uuid from 'uuid/v1';

import { API_URL } from 'environments';

import client from 'utils/graphql';
import { send, accessApi } from 'utils/injectApi';
import { createUploadFileChannel, getAndamentoGlobal } from 'utils/tools';

import { getLayoutInstance } from 'index';

import actions from './actions';
import {
	LOAD_FORM_AND_DATA,
	SEND_FORM_DATA,
	UPLOAD_FILE,
	REMOVE_FILE,
	SEND_FORM_COMPLETO,
	ATUALIZA_DOCS_INVALIDOS,
	COMPLEMENTAR_PROCESSO,
	SEND_ENQUADRAMENTO_DATA,
	DUPLICAR_REQUERIMENTO,
	OBTAIN_BPM_TASKS,
	SUBMIT_ABA_DAM,
	LOAD_USUARIO,
	RETIRA_NEW_DOCUMENT
} from './constants';
import serverActions from './serverActions';

const txtFormDisabled = 'Seu processo foi descontinuado e deve ser realizada uma nova solicitação';

/**
 * cria uma mensagem para o LayoutContextWrapper
 * @param {*} txt
 * @param {*} timeout
 */
// eslint-disable-next-line no-unused-vars
function toastMessage(txt, timeout = 3) {
	const layoutContext = getLayoutInstance();
	layoutContext.state.createMessage(txt, timeout);
}

function* loadFormAndData(action) {
	try {
		const { idFormulario, idFormData, disabled } = action.payload;
		yield put(actions.setLoading(true));
		const {
			data: { LicenciamentoFormularioById }
		} = yield call(client.query, {
			query: gql`
				query formulario($id: String!) {
					LicenciamentoFormularioById(id: $id) {
						id
						nome
						schema
						uiSchema
						documentos
						idTipoFormulario
						idTipoEnquadramento
						idUnidadeSei
						idTipoProcessoSei
						idSetor
						codOcorrenciaLicenca
						processName
						processVersion
						info
						taxa
						termoAceitacao
						termoRequerAceite
						termoNotificacao
						disabled
						mostrarDamRequerimento
						labelEmAndamento
						labelEmComplementacao
						labelDeferido
						labelIndeferido
						labelRecusado
						labelExpirado
						labelAnulado
						documentosPrivados
					}
				}
			`,
			variables: { id: idFormulario },
			fetchPolicy: 'network-only'
		});

		const desabilita = formulario => {
			if (get(formulario, 'uiSchema')) {
				Object.keys(formulario.uiSchema)
					.filter(k => k !== 'identificacao')
					.forEach(k => {
						formulario.uiSchema[k]['ui:disabled'] = true;
					});
			}
			return formulario;
		};

		let error = false;
		let formulario = cloneDeep(LicenciamentoFormularioById);
		formulario = disabled || formulario?.disabled ? desabilita(formulario) : formulario;

		if (idFormData === 'new') {
			if (disabled || formulario?.disabled) {
				toastMessage(txtFormDisabled, 10);
				yield put(actions.setLoading(false));
				yield put(push('/list'));
				return;
			}
			formulario.documentos = formulario.documentos.map(df => ({ ...df, id: uuid(), versao: 1 }));
			if (formulario.codOcorrenciaLicenca) {
				formulario.formData = {
					data: { tipoLicencaExpressa: { codOcorrenciaLicenca: formulario.codOcorrenciaLicenca } }
				};
			}
		} else {
			const {
				data: { LicenciamentoFormDataById: formData }
			} = yield call(client.query, {
				query: gql`
					query formData($id: String!) {
						LicenciamentoFormDataById(id: $id) {
							id
							idFormulario
							formulario {
								id
								schema
								uiSchema
								documentos
								disabled
							}
							data
							userData
							documentos
							documentosDados
							idProcedimentoSei
							procedimentoFormatadoSei
							urlConsultaPublicaSei
							dataComparecimento
							resultado
							usuario
							dadosFormulario
							expirado
							bpmUser
							bpmProcessDefinition
							bpmProcessInstance
							taxa
							extraInfo
							rascunhoDocsComplementacao
							idFormDataMae
							bpmTasks {
								id
								taskName
								checklist {
									id
									itens {
										id
										descricao
										obrigatorio
										ordem
										done
										username
										data
									}
								}
								contrato
							}
						}
					}
				`,
				variables: { id: idFormData },
				fetchPolicy: 'network-only'
			});
			if (!formData) {
				yield put(actions.setErrors({ send: [`Dados com id ${idFormData} não encontrados`] }));
				error = true;
			} else if (formData.idFormulario !== idFormulario) {
				yield put(
					actions.setErrors({
						send: [
							`Dados encontrados com id ${idFormData} (${formData.idFormulario}), não correspondem ao tipo ${idFormulario}`
						]
					})
				);
				error = true;
			} else if (formulario?.disabled && !formData?.procedimentoFormatadoSei) {
				formulario = desabilita(cloneDeep(LicenciamentoFormularioById));
				yield put(actions.setErrors({ send: [txtFormDisabled] }));
				error = true;
			} else {
				//achou o formData correto
				if (formData.idProcedimentoSei || formData.expirado || disabled || formulario?.disabled) {
					formulario = desabilita(cloneDeep(LicenciamentoFormularioById));
				}

				let documentosData = formData.documentos ? [...formData.documentos.filter(doc => !!doc)] : [];
				if (!formData?.procedimentoFormatadoSei) {
					let documentosForm = formulario.documentos || [];
					documentosForm.forEach(df => {
						const indexData = findIndex(documentosData, dd => dd && dd.idDocumento === df.idDocumento);
						if (indexData === -1) {
							documentosData.push({ ...df, id: uuid(), versao: 1 });
						}
					});
				}

				documentosData = documentosData.sort((a, b) => (!a.ordem ? 1 : !b.ordem ? -1 : a.ordem > b.ordem ? 1 : -1));

				const dadosFormularioCustomizado = { ...formData.data };
				if (formulario.codOcorrenciaLicenca && dadosFormularioCustomizado.tipoLicencaExpressa) {
					dadosFormularioCustomizado.tipoLicencaExpressa.codOcorrenciaLicenca = formulario.codOcorrenciaLicenca;
				}

				let dadosFormularioCustomizadoUser = null;
				if (formData.userData) {
					dadosFormularioCustomizadoUser = { ...formData.userData };
					if (formulario.codOcorrenciaLicenca && dadosFormularioCustomizadoUser.tipoLicencaExpressa) {
						dadosFormularioCustomizadoUser.tipoLicencaExpressa.codOcorrenciaLicenca = formulario.codOcorrenciaLicenca;
					}
				}

				formData.extraInfo = (formData.extraInfo || []).map(info =>
					size(info.resposta) === 0 ? { ...info, naoRespondida: true } : info
				);

				const fdata = omit(formData, ['data', 'userData']);
				formulario = {
					...formulario,
					documentos: documentosData,
					documentosDados: formData.documentosDados,
					formData: {
						...fdata,
						data: dadosFormularioCustomizado,
						userData: dadosFormularioCustomizadoUser
					}
				};
			}
		}
		yield put(actions.setLoading(false));
		yield put(actions.setFormulario(formulario));
		if (error) {
			yield put(actions.setReload({ id: 'new', idFormulario }));
		}
		yield put(actions.setBpmTasks(null));
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

//salva os dados do formulario aba formulario
function* sendFormData(action) {
	try {
		const { id, idFormulario, data, usuario, documentos, outrosDocumentos, dadosFormulario, validarDamNoForm } =
			action.payload;
		let isNew = false;

		if (id === 'undefined') {
			yield put(push('/serverUnavailable'));
			return;
		}

		yield put(actions.setLoading(true));
		if (id === 'new' || !id) {
			data.id = uuid();
			isNew = true;
		} else {
			data.id = id;
		}

		// Verificar Formulário desabilitado
		const {
			data: { LicenciamentoFormularioById }
		} = yield call(client.query, {
			query: gql`
				query formulario($id: String!) {
					LicenciamentoFormularioById(id: $id) {
						id
						disabled
					}
				}
			`,
			variables: { id: idFormulario },
			fetchPolicy: 'network-only'
		});
		if (LicenciamentoFormularioById?.disabled) {
			toastMessage(txtFormDisabled, 15);
			yield put(actions.setLoading(false));
			yield put(push('/list'));
			return;
		}

		// #61399 update apenas da identificação
		if (size(omit(data, 'id')) === 1 && data.identificacao) {
			yield call(client.mutate, {
				mutation: gql`
					mutation updateIdentificacao($id: String!, $body: LicenciamentoFormDataUpdateInput!) {
						updateLicenciamentoFormData(id: $id, body: $body) {
							id
						}
					}
				`,
				variables: {
					id: data.id,
					body: { data: { identificacao: data.identificacao } }
				}
			});
			yield put(actions.loadFormAndData(idFormulario, data.id, false));
			yield put(actions.setResult('aba_formulario_success'));
			yield put(actions.setLoading(false));
			return;
		}

		const title = `${dadosFormulario.title}`;
		delete dadosFormulario.title;

		const novoUsuario = usuario ? { id: usuario.email, email: usuario.email, name: usuario.name } : undefined;
		const payload = {
			idFormulario,
			data,
			userData: data, // para preservar os dados sem alteração dos revisores
			usuario: novoUsuario,
			documentos: isNew ? documentos : undefined,
			outrosDocumentos: outrosDocumentos,
			new: isNew,
			dadosFormulario: dadosFormulario,
			nomeTipoProcesso: title
		};
		const response = yield call(send, serverActions.saveFormData(payload), '');

		if (response.data.ok === 1) {
			// validar a DAM do formulario, ok = 0 se não validou ou não possui DAM
			const {
				data: { ok: damResponseOk, messages }
			} = yield accessApi(`dam/validate/${data.id}`);

			yield put(actions.setLoading(false));
			yield put(actions.setFormDataData(data));

			if (isNew) {
				// ok 2 se possui dam e algum warning ocorreu ao validar
				if (damResponseOk === 2 && validarDamNoForm) {
					const warnings = messages.map(m => ({ message: m, type: 'warning' }));
					yield put(actions.setErrors({ validation: warnings }));
					// eslint-disable-next-line no-restricted-globals
					const aceito = confirm(messages.join('\n\r'));
					if (aceito === true) {
						yield put(actions.setReload({ id: data.id, idFormulario }));
					}
				} else {
					// talvez não tenha dam ou validou sem warnings, segue o caminho normal
					yield put(actions.setReload({ id: data.id, idFormulario }));
				}
			} else {
				// ok se possui dam e algum warning ocorreu ao validar
				if (damResponseOk === 2 && validarDamNoForm) {
					const warnings = messages.map(m => ({ message: m, type: 'warning' }));
					yield put(actions.setErrors({ validation: warnings }));
					// eslint-disable-next-line no-restricted-globals
					const aceito = confirm(messages.join('\n\r'));
					if (aceito === true) {
						yield put(actions.setReload({ id: data.id, idFormulario }));
					}
				} else {
					// talvez não tenha dam, segue o caminho normal
					yield put(actions.setResult('aba_formulario_success'));
				}
			}
			yield put(actions.loadFormAndData(idFormulario, data.id, false));
		} else {
			yield put(actions.setLoading(false));
			const errorType = response.data.type;
			if (errorType === 'validation') {
				yield put(actions.setErrors({ validation: [response.data] }));
				yield put(actions.setFormDataData(data));
			} else {
				yield put(actions.setErrors({ send: [`Erro ao salvar dados do formulario ${data.id}, tipo ${idFormulario}`] }));
			}
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

//salva os dados do formulario aba enquadramento
function* sendEnquadramentoData(action) {
	try {
		const {
			id,
			nomeLista,
			outrosDocumentos,
			definicao,
			codOcorrencia,
			respostas,
			etapas,
			usuario,
			metadados,
			respostaSelecionadaNum,
			alterado,
			idsDocsEspecificos,
			...extras
		} = action.payload;
		delete action.payload.idsDocsEspecificos;
		yield put(actions.setLoading(true));
		const novoUsuario = usuario ? { id: usuario.email, email: usuario.email, name: usuario.name } : undefined;
		const payload = {
			id,
			usuario: novoUsuario,
			alterado,
			enquadramento: {
				extraData: extras,
				outrosDocumentos,
				nomeLista,
				definicao,
				codOcorrencia,
				respostas,
				etapas,
				metadados,
				respostaSelecionadaNum
			}
		};
		const response = yield call(send, serverActions.saveEnquadramentoData(payload), '');
		if (response.data.ok === 1) {
			const documentosAtualizados = response.data.documentosAtualizados;
			if (alterado) {
				// se enquadramento alterado, precisa limpar o formulário específico
				const docs = (idsDocsEspecificos || []).map(id => ({ id }));
				for (let index = 0; index < docs.length; index++) {
					const doc = docs[index];
					yield* removeFileFromStore(doc);
				}
			}
			yield put(actions.setLoading(false));
			yield put(actions.setEnquadramentoData({ enquadramento: payload.enquadramento, documentosAtualizados }));
			yield put(actions.setResult('aba_enquadramento_success'));
		} else {
			yield put(actions.setLoading(false));
			yield put(actions.setErrors({ send: [`Erro ao salvar dados do enquadramento do processo ${id}`] }));
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* removeFileFromStore(doc) {
	yield put(actions.removeFileStore(doc));
}

function* sendFormCompleto(action) {
	// Retira foco de qualquer botão da página para evitar que a ação seja reexecutada se usuário clicar em botão do teclado
	var buttons = document.getElementsByTagName('button');
	var button = null;
	for (var i = 0; i < buttons.length; i++) {
		button = buttons[i];
		button.blur();
	}

	try {
		const { formulario, filtroOrgao } = action.payload;
		yield put(actions.setLoading(true));

		// Se o Formulário estiver Desabilitado
		if (formulario.disabled && size(get(formulario, 'formData.procedimentoFormatadoSei', false)) === 0) {
			yield put(actions.setLoading(false));
			yield put(
				actions.setErrors({
					send: [txtFormDisabled]
				})
			);
			return;
		}

		// se o termo final necessitar de aceitação, salva o formData com o aceite
		// antes de gerar o sei
		if (formulario.termoRequerAceite === true) {
			const obj = {
				concordo: get(formulario, 'formData.data.concordo', false),
				formDataId: get(formulario, 'formData.id')
			};
			const response = yield call(send, serverActions.concordarTermo(obj));
			// yield call(send, serverActions.saveFormData(formulario.formData), '');
			if (response.data.ok !== 1) {
				yield put(actions.setLoading(false));
				yield put(
					actions.setErrors({
						send: [
							`Erro ao salvar dados do formulario ${formulario.formData.id}, tipo ${formulario.formData.idFormulario}`
						]
					})
				);
				return;
			}
		}

		// remove dados ao gerar SEI com campos escondidos
		if (get(formulario, 'formData.data.motivoProtocolo') === 'novo') {
			delete formulario.formData.data.protocolo;
			delete formulario.formData.data.descricaoMotivo;
		}
		const alteracao = get(formulario, 'formData.idProcedimentoSei');
		// const { data } = yield call(send, serverActions.saveFormCompleto(formulario), '');
		const { data } = yield call(send, serverActions.geraProcessoSEI(formulario), '');
		const { ok } = data;
		if (ok === 1) {
			yield put(actions.setLoading(false));
			if (alteracao) {
				yield put(push(`/list${filtroOrgao ? `?orgao=${filtroOrgao}` : ''}`));
			} else {
				yield put(actions.updateFormData(data));
				yield put(actions.setResult('aba_termo_success'));
			}
		} else if (ok === 88) {
			const resto = formulario.documentos.reduce((acc, d) => {
				const expressao = `-${d.originalName}`;
				const saida = acc.replace(expressao, '');
				return saida;
			}, data.documento);
			const doc = formulario.documentos.find(d => d.idDocumento === resto);
			const nomeDocumento = (doc || {}).tituloDocumento || resto;
			yield put(actions.setLoading(false));
			yield put(actions.updateFormData(data));
			yield put(
				actions.setErrors({
					[data.field]: [data.message, `Primeiro arquivo não localizado: ${nomeDocumento}`]
				})
			);
			yield put(actions.setResult('arquivos_excluidos'));
		} else if (ok === 99) {
			yield put(actions.setLoading(false));
			yield put(actions.updateFormData(data));
			yield put(actions.setErrors({ [data.field]: [data.message] }));
		} else {
			yield put(actions.setLoading(false));
			const errorType = data.type;
			if (errorType === 'validation') {
				yield put(actions.setErrors({ send: [data] }));
			} else {
				yield put(
					actions.setErrors({
						send: [
							`Erro ao salvar tentar gerar processo do formulario ${formulario.formData.id}, tipo ${formulario.id}`
						]
					})
				);
			}
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* complementarProcesso(action) {
	try {
		const { formulario, usernameLogado, complementadaEm, createMessage, filtroOrgao } = action.payload;
		yield put(actions.setLoading(true));

		// Se o Formulário estiver Desabilitado
		if (formulario.disabled && size(get(formulario, 'formData.procedimentoFormatadoSei', false)) === 0) {
			yield put(actions.setLoading(false));
			yield put(
				actions.setErrors({
					send: [txtFormDisabled]
				})
			);
			return;
		}

		let qtdTasksPendentes = 0;
		let bpmTasks = [];
		let todasBpmTasks = [];
		let timestamp = null;
		const { formData, id: idFormulario } = formulario || {};
		const { bpmProcessInstance: caseId, usuario, id: idFormData } = formData || {};
		const { id, email, preferred_username } = usuario || {};
		const username = email || id || preferred_username;

		if (caseId) {
			if (username === usernameLogado) {
				const url = `${API_URL}/bpm/task/pending/${usernameLogado}?includeAssigned=true&f=caseId=${caseId}`;
				const response = yield call(accessApi, url);
				todasBpmTasks = response.data || [];
				qtdTasksPendentes = size(todasBpmTasks);

				const getAndamento = taskname => {
					const property = taskname === 'AP1910' && taxaComplementar(formulario) ? 'taxaComplementar' : 'concluida';
					return getAndamentoGlobal(taskname, property);
				};

				bpmTasks = todasBpmTasks
					.filter(t => t.displayName.startsWith(complementadaEm || ''))
					.map(bpmTask => {
						const taskName = bpmTask.displayName.split(' ')[0];
						const descricaoAndamentoSei = getAndamento(taskName);
						const message = taskName === 'AP1300' ? 'Respondido pelo RT' : 'Processo complementado';
						const retornoApiJson = { ok: 1, message };
						const contrato = {
							retornoApiContractInput: JSON.stringify(retornoApiJson)
						};
						return {
							taskName,
							descricaoAndamentoSei,
							contrato
						};
					});
			}
		}

		if (caseId && size(bpmTasks) === 0) {
			// se é de bpm e não encontrou a tarefa ou ela está indisponível para o usuário
			// não deveria ocorrer pois os botões de ação devem estar ocultos na tela
			// e um quadro amarelo de advertência apresentado no topo da página indicando que
			// requerimento está indisponível para o usuário
			yield put(actions.setLoading(false));
			yield put(actions.setErrors({ send: ['Tarefa não disponível para usuário'] }));
		} else {
			timestamp = `${moment().toDate().getTime()}`;
			const complementacao = {};
			const docsComplementadosAgora = (formulario.documentos || []).filter(
				doc => doc.filename && !doc.idDocumentoSei && (!complementadaEm || doc.complementadaEm === complementadaEm)
			);
			if (size(docsComplementadosAgora) > 0) {
				bpmTasks.forEach(bpmTask => {
					set(complementacao, `${timestamp}.${bpmTask.taskName}.documentos`, docsComplementadosAgora);
				});
			}
			const perguntasRespondidasAgora = (formData.extraInfo || []).filter(
				info => info.naoRespondida && (!complementadaEm || info.complementadaEm === complementadaEm)
			);
			if (size(perguntasRespondidasAgora) > 0) {
				bpmTasks.forEach(bpmTask => {
					set(complementacao, `${timestamp}.${bpmTask.taskName}.extraInfo`, perguntasRespondidasAgora);
				});
			}
			if (size(complementacao[timestamp]) > 0) {
				formData.data.dadosComplementacao = Object.assign(formData.data.dadosComplementacao || {}, complementacao);
			}
			const action = serverActions.complementarProcesso(
				formulario,
				usernameLogado,
				bpmTasks,
				timestamp,
				complementadaEm,
				qtdTasksPendentes <= 1
			);

			const { data } = yield call(send, action, '');
			// const data = {
			// 	ok: 99,
			// 	message: 'EXECUÇÃO DESATIVADA PARA TESTES',
			// 	data: {}
			// };
			if (data.ok === 1) {
				yield put(actions.setLoading(false));
				if (qtdTasksPendentes <= 1) {
					yield put(push(`/list${filtroOrgao ? `?orgao=${filtroOrgao}` : ''}`));
				} else {
					yield put(actions.setBpmTasks(todasBpmTasks.filter(t => !t.displayName.startsWith(complementadaEm || ''))));
					yield put(actions.loadFormAndData(idFormulario, idFormData, false));
					createMessage(`1 de ${qtdTasksPendentes} tarefas complementadas (${complementadaEm})`, 3);
				}
			} else if (data.ok === 99) {
				console.debug('data: ', data);
				yield put(actions.setLoading(false));
				yield put(actions.updateFormData(data));
				const messages = data.message.split('\n').filter(msg => size(msg) > 0);

				const regex = /^\[(.*?)\](.*)/;
				const errorMessages = messages.reduce((acc, m) => {
					const key = m.replace(regex, '$1');
					const value = m.replace(regex, '$2');
					const keyAux = key === value ? 'send' : key;
					if (!acc[keyAux]) {
						acc[keyAux] = [];
					}
					acc[keyAux].push(value);
					return acc;
				}, {});
				yield put(actions.setErrors(errorMessages));
			} else {
				yield put(actions.setLoading(false));
				yield put(
					actions.setErrors({
						send: [
							data.message
								? data.message
								: `Erro ao adicionar arquivos ao processo do formulario ${formData.id}, tipo ${formulario.id}`
						]
					})
				);
			}
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* uploadFile(action) {
	const { idFormulario, refreshDocs } = action.payload;

	yield put(actions.setLoading(true));

	// obtem formulario
	const {
		data: { LicenciamentoFormularioById }
	} = yield call(client.query, {
		query: gql`
			query formulario($id: String!) {
				LicenciamentoFormularioById(id: $id) {
					id
					disabled
				}
			}
		`,
		variables: { id: idFormulario },
		fetchPolicy: 'network-only'
	});

	// Obtém o formData
	const {
		data: { LicenciamentoFormDataById: formData }
	} = yield call(client.query, {
		query: gql`
			query formData($id: String!) {
				LicenciamentoFormDataById(id: $id) {
					id
					documentos
					procedimentoFormatadoSei
				}
			}
		`,
		variables: { id: action.payload.idFormData },
		fetchPolicy: 'network-only'
	});

	// Verifica se o Formulário está Desabilitado
	if (LicenciamentoFormularioById?.disabled && size(get(formData, 'procedimentoFormatadoSei', false)) === 0) {
		yield put(actions.setLoading(false));
		yield put(
			actions.setErrors({
				send: [txtFormDisabled]
			})
		);
		return;
	}

	if (refreshDocs) {
		delete action.payload.refreshDocs;
	}

	const channel = yield call(createUploadFileChannel, `${API_URL}/form/upload`, action.payload);

	while (true) {
		const props = yield take(channel);
		const { progress = 0, err, success, responseText } = props;
		if (err) {
			yield put(actions.setLoading(false));
			yield put(actions.uploadFailure(action.payload.file, err));
			return;
		}
		if (success) {
			yield put(actions.setLoading(false));
			if (refreshDocs) {
				if (formData) {
					const { documentos = [] } = formData;
					yield put(actions.updateFormData({ documentos: [...documentos, JSON.parse(responseText)] }));
				} else {
					yield put(
						actions.setErrors({
							send: [`Problemas para atualizar documentos do requerimento ${formData.id}`]
						})
					);
				}
			}
			if (responseText) {
				yield put(actions.uploadSuccess(JSON.parse(responseText)));
			}
			return;
		}
		yield put(actions.uploadProgress(action.payload.file, progress));
	}
}

function* removeFile(action) {
	try {
		const documento = action.payload;
		yield put(actions.setLoading(true));
		const response = yield call(send, serverActions.removeFile(documento), '');
		if (response.data.ok === 1) {
			yield put(actions.setLoading(false));
			yield put(actions.removeFileStore(documento));
		} else {
			yield put(actions.setLoading(false));
			yield put(
				actions.setErrors({ send: [`Erro ao remover documento ${documento.id}, tipo ${documento.idFormulario}`] })
			);
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* atualizaDocsInvalidos(action) {
	try {
		const formulario = action.payload;
		yield put(actions.setLoading(true));
		const { data } = yield call(send, serverActions.atualizaDocsInvalidos(formulario), '');
		if (data.ok === 1) {
			yield put(actions.setLoading(false));
			yield put(actions.setResult('aba_documentos_success'));
			yield put(actions.updateFormData(data));
		} else if (data.ok === 99) {
			yield put(actions.setLoading(false));
			// yield put(actions.updateFormData(data));
			yield put(actions.setErrors({ [data.field]: [data.message] }));
		} else {
			yield put(actions.setLoading(false));
			yield put(
				actions.setErrors({
					send: [`Erro ao atualizar documentos do formulario ${formulario.formData.id}, tipo ${formulario.id}`]
				})
			);
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* duplicarRequerimento(action) {
	const { idFormData, user } = action.payload;
	try {
		yield put(actions.setLoading(true));
		const {
			data: { LicenciamentoFormDataById: formData }
		} = yield call(client.query, {
			query: gql`
				query formData($id: String!) {
					LicenciamentoFormDataById(id: $id) {
						id
						dadosFormulario
						idFormulario
						formulario {
							id
							disabled
						}
						userData
						dataAsString
						documentos
						documentosDados
						usuario
					}
				}
			`,
			variables: { id: idFormData },
			fetchPolicy: 'network-only'
		});
		if (formData.formulario?.disabled) {
			yield put(actions.setLoading(false));
			toastMessage(`Não é possível Duplicar! ${txtFormDisabled}`, 10);
			return;
		}
		const docsIds = {}; // usar o mesmo novoId nos documentos e documentosDados (planilhas, arrazoados, etc...)
		formData.id = uuid();
		formData.usuario = user ? { id: user.email, email: user.email, name: user.name } : undefined;
		// filtramos duplicações de invalidações e não obrigatórios
		formData.documentos = (formData.documentos || [])
			.filter(d => d.versao < 2 && (d.obrigatorio || (d.extensao !== 'pdf' && d.extensao !== 'dwg')))
			.map(d => {
				docsIds[d.id] = uuid();
				return {
					id: docsIds[d.id],
					...pick(d, [
						'idDocumento',
						'tituloDocumento',
						'extensao',
						'linkHelp',
						'textoHelp',
						'idOrgao',
						'obrigatorio',
						'ordem'
					]),
					versao: 1
				};
			});
		if (size(formData.documentosDados) > 0) {
			formData.documentosDados = formData.documentosDados.map(d => ({ id: docsIds[d.id], ...omit(d, ['id']) }));
		}
		formData.data = formData.userData;
		formData.data.identificacao = `Copiado de ${formData.data.identificacao}`;
		formData.userData.identificacao = formData.data.identificacao;
		delete formData.data.enquandramento;
		delete formData.userData.enquandramento;
		formData.formulario = formData.idFormulario;
		const { data } = yield call(accessApi, 'save-formData', false, { method: 'post', data: formData }, '');
		yield put(actions.setLoading(false));
		if (data.ok === 1) {
			yield put(push(`/${formData.idFormulario}/${formData.id}`));
		} else {
			yield put(actions.setErrors({ send: [`problemas na duplicação do registro ${formData.id}`] }));
		}
	} catch (error) {
		console.error('erro no servidor', error);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* obtainBpmTasks(action) {
	const { caseId, username } = action.payload;
	try {
		const resp = yield call(accessApi, `bpm/task/pending/${username}?includeAssigned=true&f=caseId=${caseId}`, '');
		let tasks = resp.data || [];
		yield put(actions.setBpmTasks(tasks));
	} catch (error) {
		yield put(actions.setBpmTasks([]));
		yield put(
			actions.setErrors({
				send: [
					`Problemas na obtenção das tarefas pendentes do usuario ${username}.`,
					'Este usuário existe no BPM? Para existir, deveria ter criado pelo menos um requerimento de BPM e gerado no SEI.'
				]
			})
		);
		console.error(`problemas ao obter tarefas pendentes do usuário ${username}`, error);
		console.debug('Tarefa não disponível para', username, 'caseId', caseId);
	}
}

function* submitAbaDam(action) {
	try {
		const { id, idFormulario, data } = action.payload;
		yield put(actions.setLoading(true));

		data.id = id;

		const payload = {
			idFormulario,
			data
		};
		const response = yield call(send, serverActions.saveFormData(payload), '');
		if (response.data.ok === 1) {
			// validar a DAM do formulario, ok = 0 se não validou ou não possui DAM
			const {
				data: { ok: damResponseOk, messages }
			} = yield accessApi(`dam/validate/${data.id}`);

			yield put(actions.setLoading(false));
			yield put(actions.setFormDataData(data));

			// ok se possui dam e algum warning ocorreu ao validar
			if (damResponseOk === 2) {
				const warnings = messages.map(m => ({ message: m, type: 'warning' }));
				yield put(actions.setErrors({ validation: warnings }));
				// eslint-disable-next-line no-restricted-globals
				const aceito = confirm(messages.join('\n\r'));
				if (aceito === true) {
					// yield put(actions.setReload({ id: data.id, idFormulario }));
					yield put(actions.setResult('aba_dam_success'));
				}
			} else {
				// talvez não tenha dam, segue o caminho normal
				yield put(actions.loadFormAndData(idFormulario, data.id, false));
				yield put(actions.setResult('aba_dam_success'));
			}
		} else {
			yield put(actions.setLoading(false));
			const errorType = response.data.type;
			if (errorType === 'validation') {
				yield put(actions.setErrors({ validation: [response.data] }));
				yield put(actions.setFormDataData(data));
			} else {
				yield put(actions.setErrors({ send: [`Erro ao salvar dados do formulario ${id}, tipo ${idFormulario}`] }));
			}
		}
	} catch (e) {
		console.error('erro no servidor', e);
		yield put(actions.setLoading(false));
		yield put(push('/serverUnavailable'));
	}
}

function* getUsuario(action) {
	let { sub } = action.payload;

	try {
		yield put(actions.setLoading(true));
		const response = yield call(client.query, {
			query: gql`
				query LicenciamentoUsuarioById($id: String!) {
					item: LicenciamentoUsuarioById(id: $id) {
						id
						perfil
					}
				}
			`,
			variables: { id: sub },
			fetchPolicy: 'network-only'
		});

		const {
			data: { item: usuario }
		} = response;
		yield put(actions.setUsuario(usuario || {}));
	} catch (e) {
		console.error(e);
	} finally {
		yield put(actions.setLoading(false));
	}
}

function* retiraDocumento(action) {
	// tratado também pelo reducer para remover da store
	let { idFormData, idsDocumentos, idDocumento } = action.payload;
	if (idDocumento && !idsDocumentos) {
		idsDocumentos = [idDocumento];
	}
	const url = `${API_URL}/formData/${idFormData}/retira-documentos`;
	yield call(accessApi, url, false, { method: 'delete', data: { idsDocumentos } }, '');
}

export default function* saga() {
	yield takeLatest(LOAD_FORM_AND_DATA, loadFormAndData);
	yield takeLatest(SEND_FORM_DATA, sendFormData);
	yield takeLatest(SEND_ENQUADRAMENTO_DATA, sendEnquadramentoData);
	yield takeLatest(SEND_FORM_COMPLETO, sendFormCompleto);
	yield takeLatest(COMPLEMENTAR_PROCESSO, complementarProcesso);
	yield takeLatest(ATUALIZA_DOCS_INVALIDOS, atualizaDocsInvalidos);
	yield takeLatest(REMOVE_FILE, removeFile);
	yield takeEvery(UPLOAD_FILE, uploadFile);
	yield takeLatest(DUPLICAR_REQUERIMENTO, duplicarRequerimento);
	yield takeLatest(OBTAIN_BPM_TASKS, obtainBpmTasks);
	yield takeLatest(SUBMIT_ABA_DAM, submitAbaDam);
	yield takeLatest(LOAD_USUARIO, getUsuario);
	yield takeLatest(RETIRA_NEW_DOCUMENT, retiraDocumento);
}

function taxaComplementar(formulario) {
	const bpmTasks = (formulario?.formData?.bpmTasks || []).filter(t => t.taskName === 'AP1900');
	let taxaComplementar = false;
	for (const bpmTask of bpmTasks) {
		if (Object.hasOwnProperty.call(bpmTask?.contrato || {}, 'taxaComplementarContractInput')) {
			taxaComplementar = bpmTask.contrato.taxaComplementarContractInput;
		}
	}
	return taxaComplementar;
}
