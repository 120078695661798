import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';
import moment from 'moment';

import ErrorDetail from 'components/ErrorDetail';

import { TIPO_AUTORIZACAO_OUTROS, TIPO_AUTORIZACAO_PRAZO } from 'utils/constants';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class TipoAutorizacao extends React.Component {
	static displayName = 'TipoAutorizacao';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			tipo: props.formData.tipo || '',
			prazo: props.formData.prazo || '',
			outros: props.formData.outros || '',
			valid: true
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return prevState;
	}

	tipoPrazo = () => this.state.tipo === TIPO_AUTORIZACAO_PRAZO;
	tipoOutros = () => this.state.tipo === TIPO_AUTORIZACAO_OUTROS;

	onTipoChange = e => {
		let tipo = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutros()) {
			if (size(this.state.outros) === 0) {
				valid = false;
			}
		}
		tipo = size(tipo) === 0 ? undefined : tipo;
		this.setState(() => {
			this.retornaValor(tipo, this.state.prazo, this.state.outros);
			return { tipo, valid };
		});
	};

	onPrazoChange = e => {
		let prazo = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoPrazo()) {
			if (size(prazo) === 0) {
				valid = false;
			}
		}
		prazo = size(prazo) === 0 ? undefined : prazo;
		this.setState(() => {
			this.retornaValor(this.state.tipo, prazo, this.state.outros);
			return { prazo, valid };
		});
	};

	onOutrosChange = e => {
		let outros = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutros()) {
			if (size(outros) === 0) {
				valid = false;
			}
		}
		outros = size(outros) === 0 ? undefined : outros;
		this.setState(() => {
			this.retornaValor(this.state.tipo, this.state.prazo, outros);
			return { outros, valid };
		});
	};

	retornaValor = (tipo, prazo, outros) => {
		let retorno = { tipo, prazo, outros };
		this.props.onChange(retorno);
	};

	render() {
		const requireds = this.props.schema.required || [];
		const requiredTipo = requireds.indexOf('tipo') > -1;
		const { tipo: errorTipo, prazo: errorPrazo, outros: errorOutros } = this.props.errorSchema;
		const {
			disabled,
			readonly,
			schema: { options }
		} = this.props;
		const hoje = moment.utc(new Date()).format('YYYY-MM-DD');
		return (
			<Fragment>
				{
					<Fragment>
						<div
							className={`form-group  col-md-${this.tipoPrazo() || this.tipoOutros() ? '6' : '12'}`}
							style={{ whiteSpace: 'nowrap' }}
						>
							<label className="control-label hideable" htmlFor="root_nome">
								Tipo de Autorização
								{requiredTipo ? '*' : ''}
							</label>
							<div />
							<div className="select-wrapper">
								<select
									style={{ width: '100%' }}
									className={CLASS_NAME_FORM_CONTROL}
									value={this.state.tipo}
									onChange={this.onTipoChange}
									placeholder="Selecione o tipo de Autorização"
									readOnly={readonly}
									disabled={disabled}
								>
									<option />
									{options.map((o, i) => (
										<option key={i}>{o}</option>
									))}
								</select>
							</div>
							<ErrorDetail attr={errorTipo} />
							<div />
						</div>
						{this.tipoPrazo() ? (
							<div className="form-group  col-md-6 hideable">
								<label className="control-label" htmlFor="root_nome">
									Prazo de Prorrogação
								</label>
								<div />
								<input
									readOnly={readonly}
									disabled={disabled}
									className={
										this.state.valid
											? CLASS_NAME_FORM_CONTROL
											: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
									}
									type="date"
									value={this.state.prazo || ''}
									onChange={this.onPrazoChange}
									placeholder="Prazo de prorrogação"
									min={hoje}
								/>
								<ErrorDetail attr={errorPrazo} />
								<div />
							</div>
						) : null}
						{this.tipoOutros() ? (
							<div className="form-group  col-md-6 hideable">
								<label className="control-label" htmlFor="root_nome">
									Outro tipo de autorização
								</label>
								<div />
								<input
									readOnly={readonly}
									disabled={disabled}
									className={
										this.state.valid
											? CLASS_NAME_FORM_CONTROL
											: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
									}
									type="text"
									value={this.state.outros || ''}
									onChange={this.onOutrosChange}
									placeholder="Outro tipo"
								/>
								<ErrorDetail attr={errorOutros} />
								<div />
							</div>
						) : null}
					</Fragment>
				}
			</Fragment>
		);
	}
}

export default TipoAutorizacao;
