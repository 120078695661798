import { useState, useEffect } from 'react';

const useClickOutsideContainer = (ref, resetOutside = false) => {
	const [outside, setOutside] = useState(false);
	useEffect(() => {
		const handleClick = e => {
			if ((ref || {}).current) {
				// ignora clicks nos calendars por que eles são detectados fora do container
				const calendar = document.querySelector('.rc-calendar');
				const clickOnCalendar = calendar && calendar.contains(e.target);
				const auxOutside = !ref.current.contains(e.target) && !clickOnCalendar;
				setOutside(auxOutside);
				if (auxOutside && resetOutside) {
					// isso garante que um novo click fora da área seja capturado pelo useEffect
					// que estiver controlando os cliques outside no componente que está usando o hook.
					setTimeout(() => {
						setOutside(false);
					}, 300);
				}
			}
		};

		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [ref, resetOutside]);

	return outside;
};

export default useClickOutsideContainer;
