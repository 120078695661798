import React from 'react';

import PropTypes from 'prop-types';

const Confirm = ({ msg, onConfirm, onReject, loader = true }) => (
	<div className={'loader-wrapper modal-box'}>
		<div className="card text-center">
			<div className="card-body">
				<p className="card-text">{msg || 'Confirma?'}</p>
				{loader && <div className="mx-auto mb-2 mt-2 loader" />}
				<button type="button" className="btn btn-primary m-2" onClick={onConfirm}>
					Sim
				</button>
				<button type="button" className="btn btn-secondary m-2" onClick={onReject}>
					Não
				</button>
			</div>
		</div>
	</div>
);
Confirm.displayName = 'Confirm';

Confirm.propTypes = {
	msg: PropTypes.string,
	onConfirm: PropTypes.func,
	onReject: PropTypes.func,
	loader: PropTypes.bool
};

export default Confirm;
