import React from 'react';

import { LayoutContext } from 'components/LayoutContext';

const withMessage = WrappedComponent =>
	class extends React.Component {
		displayName = 'withMessage';
		render() {
			return (
				<LayoutContext.Consumer>
					{({ createMessage }) => <WrappedComponent createMessage={createMessage} {...this.props} />}
				</LayoutContext.Consumer>
			);
		}
	};

export default withMessage;
