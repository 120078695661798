import { isNil, size, map, trim, get } from 'lodash';

import { accessApi } from 'utils/injectApi';
import { iguais, isPrimitive, soma } from 'utils/tools';

import { METAVISTORIA } from '.';

/**
 * valida o formulario preenchido do documento de vistoria
 * retorna um objeto chave: lista de erros
 *
 * @param {*} param0
 * @return {[boolean, object]}
 */
export async function ValidarVistoriaHabitese({ dados, formulario }) {
	if (isNil(dados)) {
		dados = {};
	}

	const erros = {};

	const campos = map(METAVISTORIA, val => val);
	// validar campos requireds
	const camposReq = campos.filter(m => m.required && !m.specialValidate);
	camposReq.forEach(cp => {
		addRequiredError(cp, dados, erros);
	});

	// ###################################
	// casos especiais
	// ###################################

	/** validacoes informacoes projeto licencas **/
	const isSolProjeto = dados[METAVISTORIA.tipoSolicitacao.name] === METAVISTORIA.TIPOS_SOLICITACAO.projeto.codigo;
	// const isEdilicio = dados[METAVISTORIA.tipoSolicitacao.name] === METAVISTORIA.TIPOS_SOLICITACAO.licencaEdilicio.codigo;
	const isLicHora =
		dados[METAVISTORIA.tipoSolicitacao.name] === METAVISTORIA.TIPOS_SOLICITACAO.licencaHoraExpressa.codigo;

	// se é projetou ou licenca
	if (!isLicHora) {
		addRequiredError(METAVISTORIA.projetoOuLicenca, dados, erros);
	}
	// pranchas
	if (dados[METAVISTORIA.numeroPranchas.name] && dados[METAVISTORIA.numeroPranchas.name] === 'varias') {
		addRequiredError(METAVISTORIA.descricaoPranchas, dados, erros);
	}
	// data do projeto ou licença
	if (dados[METAVISTORIA.projetoOuLicenca.name]?.id === 'outraData') {
		addRequiredError(METAVISTORIA.dataProjetoOuLicenca, dados, erros);
	}

	/**** INFORMAÇÕES OBRA */
	// informações sobre a obra
	if (dados[METAVISTORIA.imovelOcupado.name] && dados[METAVISTORIA.imovelOcupado.name] === 'sim') {
		const { name: udocupacaoname, label: udocupacaolabel } = METAVISTORIA.apenasUmaDataOcupacao;
		const { name: dname, label: dlabel } = METAVISTORIA.dataOcupacaoObra;
		const { name: obsname, label: obslabel } = METAVISTORIA.descricaoOcupacaoEconomias;

		if (isNil(dados[udocupacaoname]) || !size(dados[udocupacaoname])) {
			addRequiredError({ name: udocupacaoname, label: udocupacaolabel }, dados, erros);
		} else {
			if (dados[udocupacaoname] === 'sim') {
				if (isNil(dados[dname]) || !size(`${dados[dname]}`)) {
					addRequiredError({ name: dname, label: dlabel }, dados, erros);
				}
			}
			if (dados[udocupacaoname] === 'nao') {
				if (isNil(dados[obsname]) || !size(dados[obsname])) {
					addRequiredError({ name: obsname, label: obslabel }, dados, erros);
				}
			}
		}
	}
	if (dados[METAVISTORIA.dataInicioObra.name] && dados[METAVISTORIA.dataOcupacaoObra.name]) {
		const dataInicio = dados[METAVISTORIA.dataInicioObra.name];
		const dataOcupa = dados[METAVISTORIA.dataOcupacaoObra.name];
		if (dataInicio >= dataOcupa) {
			erros[METAVISTORIA.dataOcupacaoObra.name] = [
				`A ${METAVISTORIA.dataOcupacaoObra.label} deve ser posterior a data de início da obra`
			];
		}
	}
	if (dados[METAVISTORIA.dataInicioObra.name] && dados[METAVISTORIA.dataFinalObra.name]) {
		const dataInicio = dados[METAVISTORIA.dataInicioObra.name];
		const dataFinal = dados[METAVISTORIA.dataFinalObra.name];
		if (dataInicio >= dataFinal) {
			erros[METAVISTORIA.dataFinalObra.name] = [
				`A ${METAVISTORIA.dataFinalObra.label} deve ser posterior a data de início da obra`
			];
		}
	}
	if (dados[METAVISTORIA.existemBoxes.name] === 'sim') {
		if (
			isNil(dados[METAVISTORIA.qtdBoxes.name]) ||
			!size(dados[METAVISTORIA.qtdBoxes.name]) ||
			dados[METAVISTORIA.qtdBoxes.name].value <= 0
		) {
			addRequiredError(METAVISTORIA.qtdBoxes, dados, erros);
		}
	}
	/*** TIPO VISTORIA */
	const isVistoriaTotal = METAVISTORIA.TIPOS_VISTORIA.total.codigo === dados[METAVISTORIA.tipoVistoria.name];
	const isVistoriaParcial = METAVISTORIA.TIPOS_VISTORIA.parcial.codigo === dados[METAVISTORIA.tipoVistoria.name];
	const isVistoriaParcialFinal =
		METAVISTORIA.TIPOS_VISTORIA.parcialFinal.codigo === dados[METAVISTORIA.tipoVistoria.name];

	if (isVistoriaTotal) {
		// metragem total
		addRequiredError(METAVISTORIA.metragemTotal, dados, erros);
	}
	if (isVistoriaParcial || isVistoriaParcialFinal) {
		const { name: acname, label: aclabel } = METAVISTORIA.areaCondominal;
		const { name: apname, label: aplabel } = METAVISTORIA.areaPrivativa;
		// ao menos uma area deve ser preenchida
		if ((isNil(dados[acname]) || !size(dados[acname])) && (isNil(dados[apname]) || !size(dados[apname]))) {
			const emsg = [`Ao menos uma área deve ser preenchida ${aclabel} ou ${aplabel}`];
			erros[acname] = emsg;
		} else {
			// somente parcial final e parcial
			if (isVistoriaParcialFinal || isVistoriaParcial) {
				// metragem total da parcial final
				let { name: aptname } = METAVISTORIA.areaParcialTotal;
				// area condominal
				let { value: vac = 0 } = dados[acname] ? dados[acname] : {};
				// area privativa
				let { value: vap = 0 } = dados[apname] ? dados[apname] : {};
				// area total parcial
				let { value: apt = 0 } = dados[aptname] ? dados[aptname] : {};
				// conversoes
				let vt = soma(vac, vap); // parseFloat(vac) + parseFloat(vap);
				if (!iguais(vt, apt)) {
					// erros[aptname] = [`O somatório das áreas parciais (${vac})+(${vap}) não é igual a área total: ${apt}`];
				}
			}
		}
	}

	// array de informações da obra
	if (isNil(dados[METAVISTORIA.informacoesObra.name]) || !size(dados[METAVISTORIA.informacoesObra.name])) {
		erros.informacoesObra = ['Ao menos um endereço da obra deve ser informado.'];
	} else {
		const informacoesObra = dados[METAVISTORIA.informacoesObra.name];
		informacoesObra.forEach((io, index) => {
			const posicao = index + 1;
			const key = `${METAVISTORIA.informacoesObra.name}_${index}`;
			// cdl
			if (isNil(io[METAVISTORIA.enderecoObra.name]) || !size(io[METAVISTORIA.enderecoObra.name])) {
				const key = `${METAVISTORIA.enderecoObra.name}_${index}`;
				erros[key] = addErrorMsgInArray(
					erros[key],
					`Informações sobre a Obra - ${posicao}º Endereço - Não foi informado o endereço da obra`
				);
			}
			// qtdEconomias
			if (!isLicHora) {
				if (
					isNil(io[METAVISTORIA.qtdEconomias.name]) ||
					!size(io[METAVISTORIA.qtdEconomias.name]) ||
					io[METAVISTORIA.qtdEconomias.name].value <= 0
				) {
					erros[key] = addErrorMsgInArray(
						erros[key],
						`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada a quantidade de economias da obra`
					);
				}
			}
			// atividade
			if (isSolProjeto) {
				if (isNil(io[METAVISTORIA.atividade.name]) || !size(io[METAVISTORIA.atividade.name])) {
					erros[key] = addErrorMsgInArray(
						erros[key],
						`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada atividade da obra`
					);
				}
			}

			// economia unica
			if (!isVistoriaTotal) {
				if (isEmpty(io[METAVISTORIA.economiaUnica.name + index])) {
					const key = `${METAVISTORIA.economiaUnica.name}_${index}`;
					erros[key] = addErrorMsgInArray(
						erros[key],
						`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada Economia Única`
					);
					// se mais de uma economia...
				} else if (io[METAVISTORIA.economiaUnica.name + index] === 'nao') {
					// lista de economias
					if (size(io[METAVISTORIA.listaEconomias.name]) === 0) {
						erros[METAVISTORIA.infoObraEconomiaInicial.name] = addErrorMsgInArray(
							erros[METAVISTORIA.infoObraEconomiaInicial.name],
							`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada o 1º Intervalo`
						);
					} else {
						io[METAVISTORIA.listaEconomias.name].forEach((eco, i) => {
							const ecoInicial = eco[METAVISTORIA.infoObraEconomiaInicial.name]?.value;
							const ecoFinal = eco[METAVISTORIA.infoObraEconomiaFinal.name]?.value;

							if (isEmpty(ecoInicial) || isEmpty(ecoFinal)) {
								if (isEmpty(ecoInicial) && isEmpty(ecoFinal)) {
									erros[METAVISTORIA.infoObraEconomiaInicial.name] = addErrorMsgInArray(
										erros[METAVISTORIA.infoObraEconomiaInicial.name],
										`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada o ${i + 1}º Intervalo`
									);
								} else {
									if (isEmpty(ecoInicial)) {
										erros[METAVISTORIA.infoObraEconomiaInicial.name] = addErrorMsgInArray(
											erros[METAVISTORIA.infoObraEconomiaInicial.name],
											`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada a ${i + 1}ª Economia Inicial`
										);
									}
									if (isEmpty(ecoFinal)) {
										erros[METAVISTORIA.infoObraEconomiaFinal.name] = addErrorMsgInArray(
											erros[METAVISTORIA.infoObraEconomiaFinal.name],
											`Informações sobre a Obra - ${posicao}º Endereço - Não foi informada a ${i + 1}ª Economia Final`
										);
									}
								}
							} else {
								const ini = parseInt(ecoInicial, 10);
								const fim = parseInt(ecoFinal, 10);
								if (ini > fim) {
									erros[METAVISTORIA.infoObraEconomiaFinal.name] = addErrorMsgInArray(
										erros[METAVISTORIA.infoObraEconomiaFinal.name],
										`Informações sobre a Obra - ${posicao}º Endereço - ${
											i + 1
										}ª Economia Final deve ser maior que Economia Inicial`
									);
								}
							}
						});
					}
				}
			}
		});
	}

	/*** CONDICIONANTES */
	if (dados[METAVISTORIA.possuiCondicionantes.name] && dados[METAVISTORIA.possuiCondicionantes.name] === 'sim') {
		// lista de condicionantes
		if (
			isNil(dados[METAVISTORIA.condicionantesVistoria.name]) ||
			!size(dados[METAVISTORIA.condicionantesVistoria.name])
		) {
			erros[METAVISTORIA.condicionantesVistoria.name] = ['Os condicionantes devem ser informados'];
		} else {
			const condicionantesVistoria = dados[METAVISTORIA.condicionantesVistoria.name];

			for (const codigo in condicionantesVistoria) {
				if (Object.hasOwnProperty.call(condicionantesVistoria, codigo)) {
					const condicao = condicionantesVistoria[codigo];
					// sem resposta
					if (isNil(condicao.value)) {
						erros[codigo] = addErrorMsgInArray(
							erros[codigo],
							`Condicionante ${condicao.descricao} precisa ser informado`
						);
					}
					// tem condicao mas nao respondeu o tramite
					if (condicao.value === 'sim' && isNil(condicao[METAVISTORIA.tramiteCondicionante.name])) {
						erros[codigo] = addErrorMsgInArray(
							erros[codigo],
							`${METAVISTORIA.tramiteCondicionante.label} para a condição: ${condicao.descricao}. Precisa ser informado`
						);
					}
					// a condicionante possui documentos
					if (condicao.value === 'sim' && condicao.outrosDocumentos) {
						const isTramitarDurante = condicao[METAVISTORIA.tramiteCondicionante.name] === 'tramitar_durante_solicitacao';
						const docs = get(formulario, 'documentos', []).filter(d => d.condicionante === codigo);
						condicao.outrosDocumentos.filter(od => od.obrigatorio && !!od.isTramitarDurante === isTramitarDurante).forEach(od => {
							const doc = docs.find(d => d.idDocumento === od.idDocumento && d.versao === od.versao);
							if (!doc) {
								erros[od.idDocumento] = addErrorMsgInArray(erros[od.idDocumento], `${od.tituloDocumento} Documento deve ser enviado`)
							}
						})
					}
					// o tramitou e encerro  precisa do número do sei
					if (size(condicao[METAVISTORIA.tramiteProcessoSei.name])) {
						// existe processo sei.
						const numprocesso = condicao[METAVISTORIA.tramiteProcessoSei.name];
						const { data: processoSei } = await accessApi(`/consultarProcessoSei?numeroProcesso=${trim(numprocesso)}`);
						if (size(processoSei)) {
							if (processoSei.error) {
								erros[codigo] = addErrorMsgInArray(
									erros[codigo],
									`${METAVISTORIA.tramiteProcessoSei.label} para a condição ${condicao.descricao}- Consulta ao SEI: ${processoSei.error}`
								);
							}
						} else if (isNil(processoSei) || !size(processoSei)) {
							erros[codigo] = addErrorMsgInArray(
								erros[codigo],
								`${METAVISTORIA.tramiteProcessoSei.label} para a condição ${condicao.descricao} informado não foi encontrado.`
							);
						}
					}
				}
			}
		}
	}

	return size(erros) === 0 ? [true] : [false, { ...erros }];
}

function addRequiredError({ name, label } = {}, dados, erros) {
	let value = dados[name];
	if (isPrimitive(value)) {
		value = Number.isFinite(value) ? value.toString() : value;
		// se for o valor do componente number;
	} else if (!isNil(value.value)) {
		value = value.value;
	}

	if (isNil(value) || size(value) === 0) {
		erros[name] = [`${label} é obrigatório e não foi informado`];
	}
}

function addErrorMsgInArray(array, emsg) {
	if (size(array) > 0) {
		return [...array, emsg];
	} else {
		return [emsg];
	}
}

function isEmpty(value) {
	return isNil(value) || size(trim(value)) === 0;
}
