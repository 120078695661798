import React from 'react';

import PropTypes from 'prop-types';

import { get, toString } from 'lodash';

import { NumberField } from 'containers/Form/metadata-template/fields';
import { CheckboxField } from 'containers/Form/metadata-template/fields';

import { METAVISTORIA } from './index';

export function DetalhesVistoriaParcial(props) {
	const { data = {}, onChange } = props;

	// const projeto = get(data, `${METAVISTORIA.projetoOuLicenca.name}`);

	const isVistoriaParcial = METAVISTORIA.TIPOS_VISTORIA.parcial.codigo === data[METAVISTORIA.tipoVistoria.name];
	const isVistoriaParcialFinal =
		METAVISTORIA.TIPOS_VISTORIA.parcialFinal.codigo === data[METAVISTORIA.tipoVistoria.name];
	// const isSolProjeto = METAVISTORIA.TIPOS_SOLICITACAO.projeto.codigo === data[METAVISTORIA.tipoSolicitacao.name];

	//#region  REMOVIDO - OP #60430
	/*
		// calculo da area total da vistoria parcial total ou vistoria parcial
		if (isVistoriaParcialFinal || isVistoriaParcial) {
			let somaAreaTotalAConstruir = 0;
			if (isSolProjeto) {
				// soma todas as áreas totais a construir do Projeto de Edificação selecionado
				if (size(get(projeto, 'detalhamentos')) > 0) {
					get(projeto, 'detalhamentos').forEach(detalhamento => {
						if (get(detalhamento, 'areaTotalAConstruir')) {
							somaAreaTotalAConstruir += get(detalhamento, 'areaTotalAConstruir');
						}
					});
				}
			} else {
				// soma todas as áreas totais a construir dos Projetos de Edificação do Expediente
				if (size(get(props, 'expediente.projetosEdificacao')) > 0) {
					get(props, 'expediente.projetosEdificacao').forEach(projeto => {
						if (size(get(projeto, 'detalhamentos')) > 0) {
							get(projeto, 'detalhamentos').forEach(detalhamento => {
								if (get(detalhamento, 'areaTotalAConstruir')) {
									somaAreaTotalAConstruir += get(detalhamento, 'areaTotalAConstruir');
								}
							});
						}
					});
				}
			}

			// calculo area total da vistoria parcial final
			const areaTotalProjeto = somaAreaTotalAConstruir;
			const { vistorias = [] } = expediente;
			// somatorio de areas das outras vistorias parciais
			let somatorioAreas = vistorias
			.filter(v => v.tipoVistoria.key === '2')
			.map(v => parseFloat(v.areaTotalVistoriada) || 0)
			.reduce((p, c) => p + c, 0);
			somatorioAreas = Math.abs(areaTotalProjeto - somatorioAreas).toFixed(2);
	*/
	//#endregion REMOVIDO - OP #60430

	// calculo area total da vistoria parcial ou parcial final
	if (isVistoriaParcialFinal || isVistoriaParcial) {
		const ac = parseFloat(get(data[METAVISTORIA.areaCondominal.name], 'value', 0));
		const ap = parseFloat(get(data[METAVISTORIA.areaPrivativa.name], 'value', 0));
		const apt = (ac + ap).toFixed(2);

		if (!data['inserirAreaTotalManualmente']) {
			data[METAVISTORIA.areaParcialTotal.name] = isNaN(apt) ? { value: '0.00' } : { value: toString(apt) };
		}
	}

	return (
		<>
			<div className="pergunta">
				<div className="d-flex">
					<div style={{ marginRight: '1em' }}>
						<NumberField
							label={METAVISTORIA.areaCondominal.label}
							name={METAVISTORIA.areaCondominal.name}
							onChangeHandler={onChange}
							decimals={2}
							maxSize={'10'}
							value={data[METAVISTORIA.areaCondominal.name]}
						/>
					</div>
					<div style={{ marginRight: '1em' }}>
						<NumberField
							label={METAVISTORIA.areaPrivativa.label}
							name={METAVISTORIA.areaPrivativa.name}
							onChangeHandler={onChange}
							decimals={2}
							maxSize={'10'}
							value={data[METAVISTORIA.areaPrivativa.name]}
						/>
					</div>
					<div style={{ marginRight: '1em' }}>
						<NumberField
							label={METAVISTORIA.areaParcialTotal.label}
							name={METAVISTORIA.areaParcialTotal.name}
							readOnly={false}
							disabled={!data['inserirAreaTotalManualmente']}
							onChangeHandler={onChange}
							decimals={2}
							maxSize={'10'}
							value={data[METAVISTORIA.areaParcialTotal.name]}
						/>
					</div>
					<div>
						<label className="control-label">Inserir área total manualmente</label>
						<CheckboxField
							name={'inserirAreaTotalManualmente'}
							readOnly={false}
							disabled={false}
							onChangeHandler={onChange}
							required={false}
							options={[{ name: 'inserirAreaTotalManualmente', value: data['inserirAreaTotalManualmente'] }]}
							value={data['inserirAreaTotalManualmente']}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
DetalhesVistoriaParcial.displayName = 'DetalhesVistoriaParcial';
DetalhesVistoriaParcial.propTypes = {
	data: PropTypes.object,
	expediente: PropTypes.object,
	onChange: PropTypes.func
};
