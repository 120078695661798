import React from 'react';

import Username from './Username';

const Header = () => (
	<header>
		<div className="container">
			<a href="https://prefeitura.poa.br">
				<img
					alt="Prefeitura de Porto Alegre"
					className="img-responsive"
					src="https://prefeitura.poa.br/themes/pmpa/images/brasao.svg"
				/>
			</a>
			<Username />
			<a
				href="/novidades"
				target="_self"
				rel="noopener noreferrer"
				className="btn-link btn-icon header-btn pull-right"
				style={{ marginTop: '-4px', fontWeight: 'normal' }}
				title="visualizar novidades"
			>
				<i className="fa fa-bell" style={{ fontSize: '20px', fontWeight: 'normal', marginRight: '8px' }} />
				Novidades
			</a>
			<a
				href="/faq"
				target="_self"
				rel="noopener noreferrer"
				className="btn-link btn-icon header-btn pull-right"
				style={{ marginTop: '-4px', fontWeight: 'normal' }}
				title="visualizar perguntas frequentes"
			>
				<i className="fa fa-question-circle" style={{ fontSize: '22px', fontWeight: 'normal', marginRight: '8px' }} />
				Ajuda
			</a>
		</div>
	</header>
);

Header.displayName = 'Header';

export default Header;
