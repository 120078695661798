import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import Form from 'react-jsonschema-form';

import { get } from 'lodash';

import customFields from 'components/Fields';
import customWidgets from 'components/Widgets';

const Formulario = props => {
	const {
		form,
		asyncErrors,
		onChange,
		onSubmit,
		onValidate,
		transformErrors,
		children,
		widgets,
		fields,
		className,
		readOnly
	} = props;

	return (
		<Fragment>
			<Form
				className={className}
				schema={form.schema}
				uiSchema={form.uiSchema}
				formData={
					readOnly
						? get(form, 'formData.userData') || get(form, 'formData.data') || {}
						: get(form, 'formData.data') || {}
				}
				extraErrors={asyncErrors || {}}
				onChange={onChange}
				onSubmit={onSubmit}
				transformErrors={transformErrors}
				validate={onValidate}
				noHtml5Validate={true}
				showErrorList={false}
				autocomplete="off"
				acceptcharset="ISO-8859-1"
				fields={fields || customFields}
				widgets={widgets || customWidgets}
				// disabled /* avaliar se alteracao sera efetivada no futuro no pacote react-jsonschema-form
			>
				{children || children === null ? (
					children
				) : (
					<button type="submit" className="btn-primary">
						Enviar
					</button>
				)}
			</Form>
		</Fragment>
	);
};

Formulario.displayName = 'Formulario';

Formulario.propTypes = {
	form: PropTypes.object,
	asyncErrors: PropTypes.object,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	onValidate: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	fields: PropTypes.object,
	widgets: PropTypes.object,
	transformErrors: PropTypes.func,
	className: PropTypes.string,
	readOnly: PropTypes.bool
};

export default Formulario;
