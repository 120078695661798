import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';

import { TIPO_REQUERENTE_TIPO_OUTRO } from 'utils/constants';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class TipoRequerente extends React.Component {
	static displayName = 'TipoRequerente';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			tipo: props.formData.tipo || '',
			outro: props.formData.outro || '',
			valid: true
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return prevState;
	}

	// Retirada do campo de Outra Especificação
	// especificacaoOutra = () => this.state.especificacao === TIPO_REQUERENTE_ESPECIFICACAO_OUTRA;

	tipoOutro = () => this.state.tipo === TIPO_REQUERENTE_TIPO_OUTRO;

	onTipoChange = e => {
		let tipo = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutro()) {
			if (size(this.state.outro) === 0) {
				valid = false;
			}
		}
		tipo = size(tipo) === 0 ? undefined : tipo;
		this.setState(() => {
			this.retornaValor(tipo, this.state.outro);
			return { tipo, valid };
		});
	};

	onOutroChange = e => {
		let outro = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutro()) {
			if (size(outro) === 0) {
				valid = false;
			}
		}
		outro = size(outro) === 0 ? undefined : outro;
		this.setState(() => {
			this.retornaValor(this.state.tipo, outro);
			return { outro, valid };
		});
	};

	retornaValor = (tipo, outro) => {
		let retorno = { tipo: tipo, outro: outro };
		this.props.onChange(retorno);
	};

	render() {
		const requireds = this.props.schema.required || [];
		const requiredTipo = requireds.indexOf('tipo') > -1;
		// Retirada do campo de Especificação
		// const requiredEspecificacao = requireds.indexOf('especificacao') > -1;
		const { tipo: errorTipo, outro: errorOutro } = this.props.errorSchema;
		const {
			disabled,
			readonly,
			schema: { options }
		} = this.props;

		const options1 = options.filter(o => o.startsWith('1-')).map(o => o.substring(2));
		// Retirada do campo de Especificação
		// const options2 = options.filter(o => o.startsWith('2-')).map(o => o.substring(2));

		return (
			<Fragment>
				{
					<Fragment>
						<div className="form-group  col-md-12" style={{ whiteSpace: 'nowrap' }}>
							<label className="control-label hideable" htmlFor="root_nome">
								{this.props.schema.title || 'Tipo de Requerente'}
								{requiredTipo ? '*' : ''}
							</label>
							<div
								style={{
									display: 'flex',
									gap: '10px',
									justifyContent: 'space-between',
									alignItems: 'center',
									width: '100%'
								}}
							>
								<div className="select-wrapper" style={{ flex: '1 1 auto' }}>
									<select
										style={{ width: '100%' }}
										className={CLASS_NAME_FORM_CONTROL}
										value={this.state.tipo}
										onChange={this.onTipoChange}
										placeholder={`Selecione o ${this.props.schema.title || 'tipo de Requerente'}`}
										readOnly={readonly}
										disabled={disabled}
									>
										<option />
										{options1.map((o, i) => (
											<option key={i}>{o === 'outro' ? 'Outro' : o}</option>
										))}
									</select>
								</div>
								{this.tipoOutro() && (
									<div>
										<div />
										<input
											readOnly={readonly}
											disabled={disabled}
											className={
												this.state.valid
													? CLASS_NAME_FORM_CONTROL
													: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
											}
											type="text"
											value={this.state.outro || ''}
											onChange={this.onOutroChange}
											placeholder="Outro"
										/>
										<div />
									</div>
								)}
							</div>
							<ErrorDetail attr={errorTipo || errorOutro} />
						</div>
					</Fragment>
				}
			</Fragment>
		);
	}
}

export default TipoRequerente;
