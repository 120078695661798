import { capitalize, isNil, size } from 'lodash';

import { isDebug } from 'utils/tools';

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(CERTIFICACAO-SUSTENTAVEL-DOC-VALIDACAO)', ++i, ...args);

export function Validacao({ metadata, data, documentos, enquadramento }) {
	debugLog('metadata, dados: ', metadata, data, documentos);
	data = data || {};

	const errors = {};

	metadata.dimensoes.forEach(dimensao => {
		dimensao.questoes.forEach(questao => {
			questao.perguntas.forEach(pergunta => {
				if (data[pergunta.id] && data[pergunta.id] !== 'nao') {
					if (size(pergunta.inputs) > 0) {
						pergunta.inputs.forEach(input => {
							if (input.obrigatorio) {
								let valor = data?.[input.id];
								valor = isNil(valor?.value) ? valor : valor?.value;
								if (size(valor) === 0 || parseFloat(valor) === 0) {
									errors[input.id] = [`${input.label} é obrigatório`];
								}
							}
						});
					}
					const docProperty = `documentosProjeto${capitalize(enquadramento)}`;
					(pergunta[docProperty] || []).forEach(doc => {
						if (doc.obrigatorio) {
							const dAux = ultimoDocumento(documentos, doc.idDocumento);
							if (!dAux?.filename) {
								errors[doc.idDocumento] = [
									`Você precisa fornecer documento ${doc.tituloDocumento} para a pergunta ${pergunta.numero}`
								];
							}
						}
					});
				}
			});
		});
	});

	metadata.fieldsets.forEach(fset => {
		fset.fields.forEach(field => {
			const { id, placeholder, obrigatorio } = field;
			if (obrigatorio) {
				if (size(data[id]) === 0) {
					errors[id] = [`Você precisa preencher o campo "${placeholder}"!`];
				}
			}
		});
	});

	return errors;
}

function ultimoDocumento(documentos, tipoDocumento) {
	const doc = (documentos || [])
		.filter(d => d.idDocumento === tipoDocumento)
		.reduce((acc, d) => ((acc || {}).versao > d.versao ? acc : d), null);
	return doc;
}
