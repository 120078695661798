import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { isDebug } from 'utils/tools';

export function CheckboxSubfield({
	fieldName,
	label,
	labelStyle,
	onChangeHandler,
	value: valueProps,
	errors,
	readOnly
}) {
	const value = useMemo(() => {
		if (typeof valueProps == 'boolean') {
			return { checked: !!valueProps };
		}
		return valueProps;
	}, [valueProps]);

	return (
		<div className="container-checkboxes">
			<label className="label-checkbox" style={labelStyle || {}}>
				<input
					type="checkbox"
					className="checkbox-input"
					name={fieldName}
					id={fieldName}
					value={fieldName}
					checked={value?.checked || false}
					onChange={e => onChangeHandler({ name: fieldName, value: e.target.checked })}
					disabled={readOnly}
				/>
				{label} {isDebug && <div className="debug-message">&nbsp;({fieldName})</div>}
			</label>
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
CheckboxSubfield.displayName = 'LicencasExpressasCheckboxSubfield';
CheckboxSubfield.propTypes = {
	fieldName: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	labelStyle: PropTypes.object,
	onChangeHandler: PropTypes.func,
	localData: PropTypes.object,
	errors: PropTypes.arrayOf(PropTypes.string),
	readOnly: PropTypes.bool
};
