import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { get, size } from 'lodash';

import DocumentoUpload from 'containers/Form/aba-documentos/DocumentoUpload';
import actions from 'containers/Form/actions';

import useErrors from 'custom-hooks/useErrors';
import useMutableState from 'custom-hooks/useMutableState';

import { getUpdatedToken, isDebug } from 'utils/tools';

export default function DocumentoController({ documento: doc }) {
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const dispatch = useDispatch();
	const [, setErrors] = useErrors();
	const { idForm: idFormulario, id: idFormData } = useParams();

	const removeFile = useCallback(
		documento => {
			dispatch(actions.removeFile({ ...documento, idFormData }));
			dispatch(actions.retiraDocumento({ idFormData, idDocumento: documento.id }));
		},
		[dispatch, idFormData]
	);

	const rascunhoDocsComplementacao = !!get(formulario, 'formData.rascunhoDocsComplementacao');

	const emComplementacao =
		size(get(formulario, 'formData.resultado', [])) === 0 &&
		!rascunhoDocsComplementacao &&
		!!get(formulario, 'formData.dataComparecimento') &&
		!get(formulario, 'formData.expirado');

	const emProcessamento = !doc.idDocumentoSei && !!doc.originalName;

	const arquivoGerado =
		!!doc.filename ||
		get(formulario, 'documentos', []).find(d => d.id === doc.id && d.filename) ||
		size(get(formulario, 'formData.resultado', [])) > 0;

	const onDrop = useCallback(
		d => (accepted, rejected) => {
			if (size(rejected) === 0 && size(accepted) === 1) {
				const file = accepted[0];
				if (file.name.length > 100) {
					setErrors({ send: ['O nome do arquivo pode ter no máximo 100 caracteres.'] });
				} else if (accepted[0].size < 100000000) {
					setErrors({});
					getUpdatedToken().then(
						token => {
							const payload = {
								idFormulario,
								idFormData,
								id: d.id,
								idDocumento: d.idDocumento,
								tituloDocumento: d.tituloDocumento,
								obrigatorio: d.obrigatorio,
								extensao: d.extensao,
								ordem: d.ordem,
								docGroup: d.docGroup,
								file,
								token,
								complementadaEm: d.complementadaEm,
								owner: d.owner,
								condicionante: d.condicionante,
								isTramitarDurante: d.isTramitarDurante,
								fromSolicitante: d.fromSolicitante,
								refreshDocs: true
							};
							dispatch(actions.uploadFile(payload));
						},
						error => {
							setErrors({ send: [error] });
						}
					);
				} else {
					setErrors({ send: ['O tamanho máximo de upload é de 100MB por arquivo.'] });
				}
			} else if (size(rejected) + size(accepted) > 1) {
				setErrors({ send: ['Apenas 1 arquivo pode ser submetido em cada documento'] });
			} else {
				setErrors({
					send: [`Tipo de arquivo inválido. Selecione um arquivo do tipo ${(d.extensao || '').toUpperCase()}`]
				});
			}
		},
		[dispatch, idFormData, idFormulario, setErrors]
	);

	return (
		<>
			<label htmlFor={`inputFile${doc.idDocumento}`} style={{ fontSize: '18px' }}>
				<span style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span>
						{doc.tituloDocumento || doc.tituloDocumentoAdmin || doc.descricaoDocumento}
						{doc.obrigatorio ? '*' : ''}
						{doc.versao && doc.versao !== 1 ? ` (v. ${doc.versao})` : ''}
					</span>
					<div className="d-flex align-items-center">
						{isDebug && (
							<span className="debug-message">
								({doc.idDocumento} - {doc.ordem})
							</span>
						)}
					</div>
				</span>
			</label>
			<DocumentoUpload
				formData={formulario?.formData}
				documento={doc}
				emProcessamento={emProcessamento}
				arquivoGerado={!!arquivoGerado}
				onDrop={onDrop}
				removeFile={removeFile}
				procedimentoFormatadoSei={get(formulario, 'formData.procedimentoFormatadoSei', '')}
				usuarioDonoTask={true}
				emComplementacao={emComplementacao}
				naoPodeRemoverSlot={true}
			/>
		</>
	);
}

DocumentoController.displayName = 'DocumentoController';
DocumentoController.propTypes = {
	documento: PropTypes.object
};
