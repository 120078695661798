/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { clone, get, replace, set, size } from 'lodash';

import { SelectField } from 'containers/Form/metadata-template/fields';
import PlanoDiretor from 'containers/PlanoDiretor/PlanoDiretor';

import { API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { LE_Enquadramentos } from './MetadataLE';

export const PLANODIRETOR_CONTROLNAME = 'planoDiretor';

export function InfosPlanoDiretorLE({ formulario, onChangeHandler, data: localData, tipoForm, errosSubmissao }) {
	/** dados do plano diretor do localData */
	const planoDiretorData = get(localData, PLANODIRETOR_CONTROLNAME, {});

	// tipos forms
	const { UNIFAMILIAR_AUTONOMA } = LE_Enquadramentos;

	const showPlanoDiretorBaseEv0200 = tipoForm !== UNIFAMILIAR_AUTONOMA;

	// Variáveis para Plano Diretor
	const [macrozona, setMacrozona] = useState();
	const [ueu, setUeu] = useState();
	const [qtr, setQtr] = useState();
	const [dadosReg, setDadosReg] = useState();
	const [dadosSubunidades, setDadosSubunidades] = useState();
	const [subunidades, setSubunidades] = useState([]);
	const [iaRegimeUrbanisticoAnexo, setIaRegimeUrbanisticoAnexo] = useState();
	const [toRegimeUrbanisticoAnexo, setToRegimeUrbanisticoAnexo] = useState();

	//#region Carregamento de dados do Plano Diretor
	const codLogradouro =
		size(get(formulario, 'formData.data.enderecoCdlList')) > 0
			? get(get(formulario, 'formData.data.enderecoCdlList.0'), 'codLogradouro') ||
			  get(get(formulario, 'formData.data.enderecoCdlList.0'), 'codigoLogradouro')
			: null;
	const numero =
		size(get(formulario, 'formData.data.enderecoCdlList')) > 0
			? get(get(formulario, 'formData.data.enderecoCdlList.0'), 'numero')
			: null;
	// GET API do Plano Diretor (passo 1)
	const [{ data: dadosPlanoDiretor, loading: dadosPlanoDiretorLoading, error: dadosPlanoDiretorError }] = useAxios(
		`${PLANO_DIRETOR_API_URL}/dte/${codLogradouro}/${numero}`,
		{
			manual: false,
			useCache: true
		}
	);

	// GET collection para IA
	const [
		{ data: anexoRegimeUrbanisticoIA, loading: anexoRegimeUrbanisticoIALoading, error: anexoRegimeUrbanisticoIAError }
	] = useAxios(`${API_URL}/collections/anexoregimeurbanisticoia/`, {
		manual: false,
		useCache: true
	});

	// GET collection para TO
	const [
		{ data: anexoRegimeUrbanisticoTO, loading: anexoRegimeUrbanisticoTOLoading, error: anexoRegimeUrbanisticoTOError }
	] = useAxios(`${API_URL}/collections/anexoregimeurbanisticoto/`, {
		manual: false,
		useCache: true
	});

	// GET collection do anexo6
	const [
		{
			data: anexo6IA
			/*, loading: anexo6IALoading,
			error: anexo6IAError*/
		}
	] = useAxios(`${API_URL}/collections/anexo6/`, {
		manual: false,
		useCache: true
	});

	// GET collection do anexo71
	const [
		{
			data: dadosAnexo71
			/*, loading: dadosAnexo71Loading,
			error: dadosAnexo71Error*/
		}
	] = useAxios(`${API_URL}/collections/anexo71/`, {
		manual: false,
		useCache: true
	});

	// GET API do REG (passo 2)
	useEffect(() => {
		if (dadosPlanoDiretor) {
			setMacrozona(dadosPlanoDiretor.mz);
			setUeu(dadosPlanoDiretor.ueu);
			setQtr(dadosPlanoDiretor.qtr);
			if (dadosPlanoDiretor.urlReg) {
				// recebe a url necessaria do plano diretor e troca pra chamar o meio campo do nosso backend
				let index = dadosPlanoDiretor.urlReg.lastIndexOf('/reg');
				const finalDaUrl = dadosPlanoDiretor.urlReg.substring(index, dadosPlanoDiretor.urlReg.length);
				const newUrl = `${PLANO_DIRETOR_API_URL}${finalDaUrl}`;
				fetch(newUrl)
					.then(response => response.json())
					.then(data => (data ? setDadosReg(data[0]) : null));
			}
		}
	}, [dadosPlanoDiretor]);

	// GET API de subunidades (passo 3)
	useEffect(() => {
		if (size(dadosReg) > 0 && (macrozona || macrozona === 0) && (ueu || ueu === 0) && (qtr || qtr === 0)) {
			fetch(`${PLANO_DIRETOR_API_URL}/subsreg/${macrozona}/${ueu}/${qtr}`)
				.then(response => response.json())
				.then(data => (data ? setDadosSubunidades(data /*[0]*/) : null));
		}
	}, [macrozona, ueu, qtr, dadosReg]);
	//#endregion

	// useEffect para atualização de dados de IA e TO pela varredura de dados das APIs
	useEffect(() => {
		if (!planoDiretorData.subunidade) {
			setIaRegimeUrbanisticoAnexo(null);
			setToRegimeUrbanisticoAnexo(null);
		} else {
			if (dadosReg && dadosPlanoDiretor && dadosSubunidades) {
				if (anexoRegimeUrbanisticoIA /*&& !iaRegimeUrbanisticoAnexo*/ && anexo6IA) {
					const anexo6Capturado =
						anexo6IA.find(
							item =>
								dadosSubunidades /*dadosReg.subunidadeAux*/ &&
								(item.codigo ===
									dadosSubunidades.find(ds => parseInt(ds.subunidade) === parseInt(planoDiretorData.subunidade))
										?.indiceAproveitamento /*dadosReg.subunidadeAux.indice*/ ||
									parseFloat(item.codigo) ===
										parseFloat(
											dadosSubunidades.find(ds => parseInt(ds.subunidade) === parseInt(planoDiretorData.subunidade))
												?.indiceAproveitamento /*dadosReg.subunidadeAux.indice*/
										))
						) || null;
					let ia =
						anexoRegimeUrbanisticoIA.find(
							item => anexo6Capturado && (item.codigo === anexo6Capturado.ia || item.descricao === anexo6Capturado.ia)
						) || null;
					setIaRegimeUrbanisticoAnexo(ia);
					// Atualiza variável global do formulário
					planoDiretorData.indiceAproveitamento = replace(ia?.descricao, ',', '.');
				}

				if (dadosAnexo71 && anexoRegimeUrbanisticoTO /*&& !toRegimeUrbanisticoAnexo*/) {
					const anexo71Capturado =
						dadosAnexo71.find(
							item =>
								dadosSubunidades /*dadosReg.subunidadeAux*/ &&
								(item.codigo ===
									dadosSubunidades.find(ds => parseInt(ds.subunidade) === parseInt(planoDiretorData.subunidade))
										?.volumetria /*dadosReg.subunidadeAux.volumetria*/ ||
									parseFloat(item.codigo) ===
										parseFloat(
											dadosSubunidades.find(ds => parseInt(ds.subunidade) === parseInt(planoDiretorData.subunidade))
												?.volumetria /*dadosReg.subunidadeAux.volumetria*/
										))
						) || null;
					let to =
						anexoRegimeUrbanisticoTO.find(
							item =>
								anexo71Capturado &&
								(item.codigo.replace('%', '').replace('%', '') === anexo71Capturado.taxaocupacao?.trim() ||
									item.descricao.replace('%', '').replace('%', '') === anexo71Capturado.taxaocupacao?.trim())
						) || null;
					if (!to) {
						to =
							anexoRegimeUrbanisticoTO.find(
								item =>
									anexo71Capturado &&
									(item.codigo.replace('%', '').replace('%', '') === anexo71Capturado.taxaocupacao?.trim() ||
										item.descricao.replace('%', '').replace('%', '') ===
											Math.round(parseFloat(anexo71Capturado.taxaocupacao?.replace(',', '.').trim())).toString())
							) || null;
					}
					// console.log('TO', to);
					setToRegimeUrbanisticoAnexo(to);
					// Atualiza variável global do formulário
					planoDiretorData.taxaOcupacao = { value: replace(to?.descricao, '%', '').trim() };
				}
			}
		}
	}, [
		anexoRegimeUrbanisticoIA,
		anexo6IA,
		iaRegimeUrbanisticoAnexo,
		dadosAnexo71,
		toRegimeUrbanisticoAnexo,
		dadosReg,
		dadosPlanoDiretor,
		anexoRegimeUrbanisticoTO,
		planoDiretorData.subunidade,
		dadosSubunidades,
		planoDiretorData.indiceAproveitamento,
		planoDiretorData.taxaOcupacao
	]);

	// Preenchimento do combobox de Subunidades
	useEffect(() => {
		if (size(dadosSubunidades) > 0) {
			let subunidadesToSelect = [];
			dadosSubunidades.forEach(su => {
				if (su.subunidade || su.subunidade === 0) {
					subunidadesToSelect.push({ codigo: su.subunidade, descricao: su.subunidade });
				}
			});

			setSubunidades(subunidadesToSelect);

			// Seleção automática de subunidade, caso seja única
			if (Array.isArray(subunidadesToSelect) && size(subunidadesToSelect) === 1) {
				let subunidadeSelected = { name: 'subunidade', value: get(subunidadesToSelect[0], 'codigo').toString() };
				localChangeHandler(subunidadeSelected);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosSubunidades]);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name, value } = changes;

			set(planoDiretorData, `${name}`, clone(value));
			onChangeHandler && onChangeHandler({ name: PLANODIRETOR_CONTROLNAME, value: clone(planoDiretorData) });
		},
		[onChangeHandler, planoDiretorData]
	);

	useEffect(() => {
		if (dadosPlanoDiretorError) {
			if (dadosPlanoDiretorError.response) {
				console.debug('dadosPlanoDiretorError.response: ', JSON.stringify(dadosPlanoDiretorError.response, null, 2));
			}
		}
	}, [dadosPlanoDiretorError]);

	return (
		<fieldset>
			<legend>3. Plano Diretor</legend>
			<div className="row row-cols-12">
				{dadosPlanoDiretorLoading ? (
					'Obtendo Dados do Plano Diretor'
				) : dadosPlanoDiretorError ? (
					<div className="col-md-12" style={{ color: 'red' }}>
						{dadosPlanoDiretorError?.response?.status === 404 ? (
							<>
								Endereço não cadastrado na DMI. Solicite no Portal de Licenciamento através do processo "Inclusão de
								Endereço para Emissão da DMI".
							</>
						) : (
							<>Não foi possível recuperar os Dados do Plano Diretor. Tente novamente mais tarde.</>
						)}
					</div>
				) : showPlanoDiretorBaseEv0200 ? (
					<div className="col-12">
						<PlanoDiretor
							data={planoDiretorData}
							formulario={formulario}
							readOnly={false}
							onChangeHandler={(value, name) => localChangeHandler({ value, name })}
							// dados da API do plano diretor
							dadosPlanoDiretor={dadosPlanoDiretor}
							errosSubmissao={errosSubmissao}
						/>
					</div>
				) : (
					<>
						{(macrozona || macrozona === 0) && (ueu || ueu === 0) && (qtr || qtr === 0) && (
							<div className="form-group col-md-12">
								<div className="form-group col-md-4">
									<b>Macrozona: </b> {macrozona}
								</div>
								<div className="form-group col-md-4">
									<b>UEU: </b> {ueu}
								</div>
								<div className="form-group col-md-4">
									<b>Quarteirão: </b> {qtr}
								</div>
							</div>
						)}
						{dadosSubunidades && (
							<div className="form-group col-md-12">
								{size(subunidades) === 1 ? (
									<div className="form-group col-md-6">
										<b>Subunidade (única): </b> {planoDiretorData.subunidade}
									</div>
								) : (
									<SelectField
										name="subunidade"
										required={true}
										label="Subunidade:"
										value={planoDiretorData.subunidade}
										options={subunidades}
										onChangeHandler={/*changeSubunidade*/ localChangeHandler}
									/>
								)}
							</div>
						)}
						{iaRegimeUrbanisticoAnexo && toRegimeUrbanisticoAnexo && (
							<div className="form-group col-md-12">
								<div className="form-group col-md-6">
									<b>Índice de Aproveitamento (IA): </b> {iaRegimeUrbanisticoAnexo.descricao}
								</div>
								<div className="form-group col-md-6">
									<b>Taxa de Ocupação (TO): </b> {toRegimeUrbanisticoAnexo.descricao}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</fieldset>
	);
}

InfosPlanoDiretorLE.displayName = 'InfosPlanoDiretorLE';
InfosPlanoDiretorLE.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.array
};
