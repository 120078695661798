import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from 'react-apollo';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';

import gql from 'graphql-tag';
import { get, size, isNil } from 'lodash';

import usePersistentState from 'custom-hooks/usePersistentState';

const usuarioQuery = gql`
	query LicenciamentoUsuarioById($id: String!) {
		item: LicenciamentoUsuarioById(id: $id) {
			id
			perfil
		}
	}
`;

const ModalPerfilUsuario = () => {
	const [showModal, setShowModal] = useState(false);
	const [paraTudo, setParaTudo] = useState(false);
	const store = useStore();
	const [usuario, setUsuario] = usePersistentState('usuario', undefined);

	let [getUsuario, { data }] = useLazyQuery(usuarioQuery);

	useEffect(() => {
		const obtainUser = async () => {
			if (store && get(store, 'kc.authenticated') && !size(usuario) && !paraTudo) {
				const user = await store.kc.loadUserInfo();
				getUsuario({ variables: { id: user.sub } });
			}
		};
		obtainUser();
	}, [getUsuario, paraTudo, store, usuario]);

	useEffect(() => {
		if (data && !showModal && !paraTudo) {
			if (isNil(data.item)) {
				setShowModal(true);
			} else {
				setUsuario(data.item);
				setParaTudo(true);
			}
		}
	}, [data, paraTudo, setUsuario, showModal]);

	const stop = useCallback(() => {
		setShowModal(false);
		setParaTudo(true);
	}, []);

	return (
		<>
			{showModal && (
				<div className="modal-wrapper" style={{ zIndex: '10000' }}>
					<div className="modal-novidades">
						<div className="header-group" style={{ marginBottom: '20px', borderBottom: '1px solid #cbcbcb' }}>
							<h2>
								Seu perfil está incompleto
								<i className="fa fa-user-o" style={{ marginLeft: '8px', top: '0px', paddingBottom: '10px' }} />
							</h2>
							<button type="button" onClick={stop} style={{ border: 'none' }}>
								<i className="fa fa-times"></i>
							</button>
						</div>
						<div>
							<p>Complete seu perfil facilitar o preenchimento de solicitações*.</p>
							<p className="text-info">
								<small>*Não disponível para todas as solicitações</small>
							</p>
						</div>
						<div className="cjto-botoes" style={{ marginRight: '0px' }}>
							<button type="button" className="btn-primary" onClick={stop} style={{ marginRight: '1rem' }}>
								Agora não
							</button>
							<Link to={'/perfil'}>
								<button type="button" className="btn-primary" onClick={stop}>
									Completar
								</button>
							</Link>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
ModalPerfilUsuario.displayName = 'ModalPerfilUsuario';

export default ModalPerfilUsuario;
