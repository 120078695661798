import React, { useCallback, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import { get, size } from 'lodash';
import { Draggable, GeoJson, Map, Marker, ZoomControl } from 'pigeon-maps';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import { LayoutContext } from 'components/LayoutContext';

import useClick from 'custom-hooks/useClick';
import useKeypress from 'custom-hooks/useKeypress';
import useMessages from 'custom-hooks/useMessages';

import { accessApi } from 'utils/injectApi';

import EnderecoField from './EnderecoField';
const DEFAULT_FEATURE = {
	center: [-30.02951268769542, -51.231061396503435],
	zoomLevel: 18,
	geoJson: { type: 'FeatureCollection', features: [] }
};

export default function MapField({
	label = 'Mapa',
	labelEnum,
	required,
	name,
	value: valueProps,
	errorList,
	readOnly,
	disabled,
	onChangeHandler
}) {
	const { createMessage } = useMessages();

	const { width: widthScreen, height: heightScreen } = useContext(LayoutContext);

	const [editMode, setEditMode] = useState(false);
	const [value, setValue] = useState(valueProps || DEFAULT_FEATURE);
	const [center, setCenter] = useState(value.center); // praça da alfândega
	const [anchor, setAnchor] = useState(null);
	const [legenda, setLegenda] = useState(null);
	const [newPolygon, setNewPolygon] = useState(null);
	const [newLineString, setNewLineString] = useState(null);
	const [newPoint, setNewPoint] = useState(null);
	const [pesquisando, setPesquisando] = useState(false);
	const [pin, setPin] = useState(null);
	const [hovering, setHovering] = useState(null);
	const [removing, setRemoving] = useState(false);
	const [hoverLegend, setHoverLegend] = useState(null);
	const [cancelarAlteracoes, setCancelarAlteracoes] = useState(false);
	const [salvarAlteracoes, setSalvarAlteracoes] = useState(false);
	const [cdl, setCdl] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleEscape = useCallback(() => {
		if (pin) {
			setPin(null);
		}
		if (removing) {
			setRemoving(false);
		}
		if (newPolygon) {
			setNewPolygon(null);
		}
		if (newLineString) {
			setNewLineString(null);
		}
		if (newPoint) {
			setNewPoint(null);
		}
		if (hoverLegend) {
			setHoverLegend(null);
		}
		if (hovering) {
			setHovering(null);
		}
		if (cancelarAlteracoes) {
			setCancelarAlteracoes(false);
		}
		if (salvarAlteracoes) {
			setSalvarAlteracoes(false);
		}
	}, [cancelarAlteracoes, hoverLegend, hovering, newLineString, newPoint, newPolygon, pin, removing, salvarAlteracoes]);
	useKeypress('Escape', handleEscape);

	const salvar = useCallback(() => {
		if (onChangeHandler) {
			onChangeHandler({ name, value }, { name: 'errors', value: { [name]: validate(value, required, label) } });
		}
		handleEscape();
		setEditMode(false);
	}, [handleEscape, label, name, onChangeHandler, required, value]);

	const updateNewPolygon = useCallback(
		(coords, enderecos, encerra) => {
			const newPol = window.structuredClone(newPolygon);
			newPol.geometry.coordinates = [coords];
			newPol.geometry.properties.enderecos = [enderecos];
			setNewPolygon(newPol);
			if (encerra) {
				setValue(old => ({ ...old, geoJson: { ...old.geoJson, features: [...old.geoJson.features, newPol] } }));
				setNewPolygon(null);
			}
		},
		[newPolygon]
	);

	const updateNewLineString = useCallback(
		(coords, enderecos, encerra) => {
			const newLine = window.structuredClone(newLineString);
			newLine.geometry.coordinates = coords;
			newLine.geometry.properties.enderecos = enderecos;
			setNewLineString(newLine);
			if (encerra) {
				setValue(old => ({ ...old, geoJson: { ...old.geoJson, features: [...old.geoJson.features, newLine] } }));
				setNewLineString(null);
			}
		},
		[newLineString]
	);

	const updateNewPoint = useCallback(
		(coords, endereco, encerra) => {
			const newPnt = window.structuredClone(newPoint);
			newPnt.geometry.coordinates = coords;
			newPnt.geometry.properties.endereco = endereco;
			setNewPoint(newPnt);
			if (encerra) {
				setValue(old => ({ ...old, geoJson: { ...old.geoJson, features: [...old.geoJson.features, newPnt] } }));
				setNewPoint(null);
			}
		},
		[newPoint]
	);

	const mapClickHandler = useCallback(
		({ event, latLng: [lat, long] }) => {
			if (!['info-pin', 'fa fa-times info-pin-close'].includes(event.target.className)) {
				obtemCdl(lat, long).then(endereco => {
					if (endereco) {
						if (newPolygon) {
							updateNewPolygon(
								[...newPolygon.geometry.coordinates[0], [long, lat]],
								[...newPolygon.geometry.properties.enderecos[0], endereco]
							);
						}
						if (newLineString) {
							updateNewLineString(
								[...newLineString.geometry.coordinates, [long, lat]],
								[...newLineString.geometry.properties.enderecos, endereco]
							);
						}
						if (newPoint) {
							updateNewPoint([long, lat], endereco);
							setAnchor([lat, long]);
							setLegenda(endereco);
						}
						if (!newPolygon && !newLineString && !newPoint && !removing) {
							setPin([lat, long]);
							setAnchor([lat, long]);
							setLegenda(endereco);
						}
					}
				});
			}
		},
		[newLineString, newPoint, newPolygon, removing, updateNewLineString, updateNewPoint, updateNewPolygon]
	);

	const obtemCdl = async (lat, long) => {
		let url = `https://utilidadesgis-api.procempa.com.br/arcgis/geocode-reverso-codigo?insr=WGS84&lat=${lat}&lon=${long}&distance=50`;
		let res = await accessApi(url, true);
		const gis = res.data || {};
		if (gis.logradouro) {
			url = `https://cdlrest.procempa.com.br/cdlrest/rest/endereco/${gis.logradouro};numero=${gis.numero}`;
			res = await accessApi(url, true);
			return res.data?.enderecoFormatadoCurto || null;
		}
		return null;
	};

	const createNewPolygon = useCallback(() => {
		setPin(null);
		setNewPolygon({
			id: uuid(),
			type: 'Feature',
			geometry: {
				id: uuid(),
				type: 'Polygon',
				coordinates: [[]],
				properties: {
					enderecos: [[]]
				}
			}
		});
	}, []);

	const createNewLineString = useCallback(() => {
		setPin(null);
		setNewLineString({
			id: uuid(),
			type: 'Feature',
			geometry: {
				id: uuid(),
				type: 'LineString',
				coordinates: [],
				properties: {
					enderecos: []
				}
			}
		});
	}, []);

	const createNewPoint = useCallback(() => {
		setPin(null);
		setNewPoint({
			id: uuid(),
			type: 'Feature',
			geometry: {
				id: uuid(),
				type: 'Point',
				coordinates: null,
				properties: {
					endereco: null
				}
			}
		});
	}, []);

	const closeNewPolygon = useCallback(() => {
		const coords = newPolygon.geometry.coordinates[0];
		if (coords.length < 3) {
			createMessage('São necessários pelo menos três pontos para criar um polígono', 3);
		} else {
			const ends = newPolygon.geometry.properties.enderecos[0];
			// coords.push(coords[0]); // repete a primeira para fechar o polígono
			// ends.push(ends[0]);
			updateNewPolygon(coords, ends, true);
		}
	}, [createMessage, newPolygon, updateNewPolygon]);

	const closeNewLineString = useCallback(() => {
		const coords = newLineString.geometry.coordinates;
		if (coords.length < 2) {
			createMessage('São necessários pelo menos dois pontos para criar uma linha', 3);
		} else {
			const ends = newLineString.geometry.properties.enderecos;
			updateNewLineString(coords, ends, true);
		}
	}, [createMessage, newLineString, updateNewLineString]);

	const closeNewPoint = useCallback(() => {
		const coords = newPoint.geometry.coordinates;
		if (!coords) {
			createMessage('Clique no mapa para escolher um ponto antes de adicioná-lo', 3);
		} else {
			const end = newPoint.geometry.properties.endereco;
			updateNewPoint(coords, end, true);
		}
	}, [createMessage, newPoint, updateNewPoint]);

	const pesquisarEndereco = useCallback(() => {
		if (loading) {
			createMessage('Aguarde um momento enquanto o endereço é pesquisado e tente novamente', 3);
		} else if (cdl?.incomplete) {
			createMessage('É necessário informar um endereço completo para pesquisar', 3);
		} else {
			if (cdl) {
				setCenter([cdl.latitude, cdl.longitude]);
				setCdl(null);
				setPesquisando(false);
			}
		}
	}, [cdl, createMessage, loading]);

	const handleClick = useCallback(
		e => {
			if (['path', 'circle'].includes(e.target.nodeName)) {
				if (hovering) {
					setValue(old => ({
						...old,
						geoJson: { ...old.geoJson, features: (old.geoJson.features || []).filter(f => f.id !== hovering) }
					}));
					setHovering(null);
				}
			}
		},
		[hovering]
	);
	useClick(handleClick);

	/* RENDER */
	return (
		<>
			<div className="d-flex gap-10 justify-content-between">
				<label className="control-label" htmlFor={`id_${name}`}>
					{labelEnum || label}
					{required && <span className="required">*</span>}
				</label>

				{!editMode && (
					<div className="toolbar">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								setEditMode(true);
							}}
						>
							{readOnly || disabled ? 'Ver elementos no mapa' : 'Editar elementos no mapa'}
						</button>
					</div>
				)}
			</div>
			<div>
				{/* VISÃO DE FORA DO MODAL */}
				<div className="d-flex gap-10">
					<MapSideView
						containerClass="lista-elementos"
						features={value?.geoJson?.features}
						setHoverLegend={setHoverLegend}
						setCenter={setCenter}
					/>
				</div>
				{errorList && size(errorList) > 0 && (
					<div className="col-md-12">
						<ErrorMessages errorList={errorList} />
					</div>
				)}
				{/* <ShowDebug data={{ hovering, new: newPolygon || newLineString || newPoint, geoJson: value.geoJson }} /> */}
			</div>
			<Modal
				show={editMode}
				onHide={() => setEditMode(false)}
				dialogClassName="modal-90w modal-componente-mapa"
				aria-labelledby="Editar Mapa"
			>
				<Modal.Header closeButton>
					<Modal.Title id="salvamento">Editar elementos no mapa</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!disabled && !readOnly && (
						<div className="toolbar secondary">
							{!newPolygon && !newLineString && !newPoint && !pesquisando && (
								<>
									{!removing && (
										<>
											<button type="button" className="btn btn-primary" onClick={() => createNewPolygon()}>
												Criar novo polígono
											</button>
											<button type="button" className="btn btn-primary" onClick={() => createNewLineString()}>
												Criar nova linha
											</button>
											<button type="button" className="btn btn-primary" onClick={() => createNewPoint()}>
												Criar novo ponto
											</button>
											<button type="button" className="btn btn-primary" onClick={() => setPesquisando(true)}>
												Localizar endereço
											</button>
										</>
									)}
									{!pesquisando && (
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => {
												setPin(null);
												setRemoving(old => !old);
											}}
											disabled={size(value?.geoJson?.features) === 0}
										>
											{removing ? 'Sair do modo de exclusão' : 'Remover elemento'}
										</button>
									)}
								</>
							)}
							{newPolygon && (
								<>
									<button type="button" className="btn btn-secondary" onClick={() => setNewPolygon(null)}>
										Cancelar
									</button>
									<button type="button" className="btn btn-primary" onClick={() => closeNewPolygon()}>
										Incluir polígono
									</button>
									{newPolygon.geometry.coordinates[0].map((coords, index) => {
										const endereco = newPolygon?.geometry?.properties?.enderecos?.[0]?.[index];
										return (
											<button type="button" className="info-pin" key={index} title={endereco || coords}>
												<div className="close">
													<i
														className="fa fa-times"
														onClick={() => {
															const index = newPolygon.geometry.coordinates[0].findIndex(
																c => c[0] === coords[0] && c[1] === coords[1]
															);
															if (index > -1) {
																newPolygon.geometry.coordinates[0].splice(index, 1);
																newPolygon.geometry.properties.enderecos[0].splice(index, 1);
																updateNewPolygon(
																	newPolygon.geometry.coordinates[0],
																	newPolygon.geometry.properties.enderecos[0]
																);
															}
														}}
													/>
												</div>
												Ponto {index + 1}
											</button>
										);
									})}
								</>
							)}

							{newLineString && (
								<>
									<button type="button" className="btn btn-secondary" onClick={() => setNewLineString(null)}>
										Cancelar
									</button>
									<button type="button" className="btn btn-primary" onClick={() => closeNewLineString()}>
										Incluir linha
									</button>
									{newLineString.geometry.coordinates.map((coords, index) => {
										const endereco = newLineString?.geometry?.properties?.enderecos?.[index];
										return (
											<button type="button" className="info-pin" key={index} title={endereco || coords}>
												<div className="close">
													<i
														className="fa fa-times"
														onClick={() => {
															const index = newLineString.geometry.coordinates.findIndex(
																c => c[0] === coords[0] && c[1] === coords[1]
															);
															if (index > -1) {
																newLineString.geometry.coordinates.splice(index, 1);
																newLineString.geometry.properties.enderecos.splice(index, 1);
																updateNewLineString(
																	newLineString.geometry.coordinates,
																	newLineString.geometry.properties.enderecos
																);
															}
														}}
													/>
												</div>
												Ponto {index + 1}
											</button>
										);
									})}
								</>
							)}

							{newPoint && (
								<>
									<button type="button" className="btn btn-secondary" onClick={() => setNewPoint(null)}>
										Cancelar
									</button>
									<button type="button" className="btn btn-primary" onClick={() => closeNewPoint()}>
										Incluir Ponto
									</button>
									{newPoint?.geometry?.coordinates && (
										<button
											type="button"
											className="info-pin"
											title={newPoint.geometry.properties?.endereco || newPoint.geometry.coordinates}
										>
											<div className="close">
												<i className="fa fa-times" onClick={() => updateNewPoint(null, null)} />
											</div>
											Ponto
										</button>
									)}
								</>
							)}

							{pesquisando && (
								<div className="d-flex space-between gap-10 baseline">
									<EnderecoField
										value={cdl}
										onChangeHandler={setCdl}
										style={{ flex: 1 }}
										loading={loading}
										setLoading={setLoading}
									/>
									<div className="toobar">
										<button
											type="button"
											className="btn btn-default"
											onClick={() => setPesquisando(false)}
											style={{ padding: '8px 16px' }}
										>
											Cancelar
										</button>
										<button
											type="button"
											className="btn btn-primary"
											onClick={pesquisarEndereco}
											style={{ padding: '8px 16px' }}
										>
											Pesquisar
										</button>
									</div>
								</div>
							)}
						</div>
					)}
					{!pesquisando && (
						<div className="modal-map">
							<div className={`d-flex gap-10 ${widthScreen <= 768 ? 'flex-column' : ''}`}>
								{editMode && (
									<Map
										provider={
											(x, y, z) =>
												`https://apigateway.procempa.com.br/apiman-gateway/geo/mapas-base-api/1.0/osm-procempa-2/${z}/${x}/${y}.png`
											// return `https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/${z}/${y}/${x}`;
										}
										height={(62 * heightScreen) / 100}
										width={widthScreen <= 768 ? widthScreen * 0.9 : 0.7 * widthScreen}
										center={center}
										maxZoom={22}
										defaultZoom={value.zoomLevel}
										onClick={pesquisando ? null : mapClickHandler}
										onBoundsChanged={({ center }) => setCenter(center)}
									>
										<ZoomControl />
										{pin && <InfoPin pin={pin} setPin={setPin} setCenter={setCenter} />}
										{(pin || newPoint?.geometry?.coordinates) && (
											<Draggable offset={[100, -10]} anchor={anchor} onDragEnd={setAnchor}>
												<div className="legenda-mapa">
													{pin && (
														<div className="close">
															<i
																className="fa fa-times"
																onClick={e => {
																	e.stopPropagation();
																	setPin(null);
																}}
															/>
														</div>
													)}
													<p>{legenda}</p>
												</div>
											</Draggable>
										)}
										{/* escreve os polígonos */}
										<GeoJson
											data={value.geoJson}
											styleCallback={(feature, hover) => {
												if (hover && removing) {
													setHovering(feature.id);
												} else if (hovering === feature.id) {
													setHovering(null);
												}
												return {
													fill: ['Polygon', 'Point'].includes(feature.geometry.type)
														? hoverLegend === feature?.geometry?.id
															? '#09afa882' //cor no hover
															: hovering === feature.id
															? '#fe191970' //cor para excluir
															: '#d8d8d8'
														: null,
													strokeWidth:
														feature.geometry.type === 'LineString' &&
														(hoverLegend === feature?.geometry?.id || (removing && hover))
															? '3'
															: '2',
													stroke: 'rgba(0,0,0,.55)',
													r: '10'
												};
											}}
										/>
										{/* escreve os markers */}
										{pin && (
											<Marker width={9} height={20} anchor={[pin[0], pin[1]]}>
												<i className="fa fa-map-marker marcador-componente-mapa" />
											</Marker>
										)}
										{newPolygon &&
											newPolygon.geometry.coordinates.map(coordinates =>
												coordinates.map((marker, index) => {
													const [long, lat] = marker;
													return (
														<Marker key={index} width={9} height={20} anchor={[lat, long]}>
															<i className="fa fa-map-marker marcador-componente-mapa" />
															<span className="numeracao-marcador-componente-mapa">{index + 1}</span>
														</Marker>
													);
												})
											)}
										{newLineString &&
											newLineString.geometry.coordinates.map((marker, index) => {
												const [long, lat] = marker;
												return (
													<Marker key={index} width={9} height={20} anchor={[lat, long]}>
														<i className="fa fa-map-marker marcador-componente-mapa" />
														<span className="numeracao-marcador-componente-mapa">{index + 1}</span>
													</Marker>
												);
											})}
										{newPoint?.geometry?.coordinates?.length > 0 && (
											<Marker
												width={9}
												height={20}
												anchor={[newPoint.geometry.coordinates[1], newPoint.geometry.coordinates[0]]}
											>
												<i className="fa fa-map-marker marcador-componente-mapa" />
											</Marker>
										)}
										{value.geoJson.features
											.filter(feature => !!feature.geometry)
											.map(feature => feature.geometry)
											.map((geo, index) =>
												geo.type === 'Polygon' ? (
													(geo.coordinates?.[0] || []).map(([long, lat], i) => (
														<Marker key={`${geo.type}-${lat}-${long}-${i}}`} width={9} height={20} anchor={[lat, long]}>
															<i className="fa fa-map-marker marcador-componente-mapa" />
														</Marker>
													))
												) : geo.type === 'LineString' ? (
													(geo.coordinates || []).map(([long, lat], i) => (
														<Marker key={`${geo.type}-${lat}-${long}-${i}}`} width={9} height={20} anchor={[lat, long]}>
															<i className="fa fa-map-marker marcador-componente-mapa" />
														</Marker>
													))
												) : geo.type === 'Point' ? (
													<Marker
														key={`Point-${index}`}
														width={9}
														height={20}
														anchor={[geo.coordinates[1], geo.coordinates[0]]}
													>
														<i className="fa fa-map-marker marcador-componente-mapa" />
													</Marker>
												) : null
											)}
									</Map>
								)}
								<MapSideView
									containerClass="map-sideview"
									features={value?.geoJson?.features}
									setHoverLegend={setHoverLegend}
									setCenter={setCenter}
								/>
							</div>
							{errorList && size(errorList) > 0 && (
								<div className="col-md-12">
									<ErrorMessages errorList={errorList} />
								</div>
							)}
							{/* <ShowDebug data={{ hovering, new: newPolygon || newLineString || newPoint, geoJson: value.geoJson }} /> */}
						</div>
					)}
					{salvarAlteracoes && (
						<div className="salvarAlteracoes">
							<h3>Tem certeza de que deseja confirmar as alterações feitas?</h3>
							<div className="toolbar toolbar-botoes">
								<button type="button" className="btn btn-secondary" onClick={() => setSalvarAlteracoes(false)}>
									Não
								</button>
								<button type="button" className="btn btn-primary" onClick={() => salvar(true)}>
									Sim
								</button>
							</div>
						</div>
					)}
					{cancelarAlteracoes && (
						<div className="cancelarAlteracoes">
							<h3>Tem certeza de que deseja cancelar as alterações feitas?</h3>
							<div className="toolbar toolbar-botoes">
								<button type="button" className="btn btn-secondary" onClick={() => setCancelarAlteracoes(false)}>
									Não
								</button>
								<button type="button" className="btn btn-primary" onClick={() => salvar(false)}>
									Sim
								</button>
							</div>
						</div>
					)}
					{!salvarAlteracoes && !cancelarAlteracoes && !pesquisando && !readOnly && !disabled && (
						<div className="toolbar toolbar-botoes">
							<button type="button" className="btn btn-secondary" onClick={() => setCancelarAlteracoes(true)}>
								Cancelar edição
							</button>
							<button type="button" className="btn btn-primary" onClick={() => setSalvarAlteracoes(true)}>
								Confirmar edição
							</button>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}

MapField.displayName = 'MapField';

MapField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func
};

function InfoPin({ pin, setPin, setCenter }) {
	return (
		<div className="container-info-pin" onClick={() => setCenter(pin)}>
			<div className="info-pin">
				Há um pin ativo no mapa
				<div className="close">
					<i
						className="fa fa-times info-pin-close"
						onClick={e => {
							e.stopPropagation();
							setPin(null);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
InfoPin.displayName = 'InfoPin';
InfoPin.propTypes = {
	pin: PropTypes.arrayOf(PropTypes.number),
	setPin: PropTypes.func,
	setCenter: PropTypes.func
};

export const validate = (value, required, label = 'Mapa') => {
	let errors = [];
	if (required) {
		if (size(!value?.geoJson?.features) === 0) {
			errors.push(`Você deve adicionar algum elemento ao ${label}`);
		}
	}
	return errors;
};

function MapSideView({ containerClass = 'map-sideview', features, setHoverLegend, setCenter }) {
	const getCenter = useCallback(coords => {
		const [[xMenor, yMenor], [xMaior, yMaior]] = coords.reduce(
			(acc, coord) => {
				const [x, y] = coord;
				let [[xMenor, yMenor], [xMaior, yMaior]] = acc;
				xMenor = !xMenor || x < xMenor ? x : xMenor;
				xMaior = !xMaior || x > xMaior ? x : xMaior;
				yMenor = !yMenor || y < yMenor ? y : yMenor;
				yMaior = !yMaior || y > yMaior ? y : yMaior;
				return [
					[xMenor, yMenor],
					[xMaior, yMaior]
				];
			},
			[
				[null, null],
				[null, null]
			]
		);
		return [(xMenor + xMaior) / 2, (yMenor + yMaior) / 2];
	}, []);

	const getType = useCallback(type => {
		let saida = type;
		switch (type) {
			case 'Polygon':
				saida = 'Polígono';
				break;

			case 'Point':
				saida = 'Ponto';
				break;

			case 'LineString':
				saida = 'Linha';
				break;

			default:
				break;
		}

		return saida;
	}, []);

	return (
		<div className={containerClass}>
			{(features || [])
				.filter(f => size(get(f, 'geometry.properties')) > 0)
				.map(f => f.geometry)
				.map(geometry => {
					const { properties } = geometry || {};
					return (
						<div
							key={geometry.id}
							id={geometry.id}
							className="map-geometry"
							onMouseOver={() => setHoverLegend(geometry.id)}
							onMouseOut={() => setHoverLegend(null)}
							onClick={() => {
								const coords =
									geometry.type === 'Point'
										? get(geometry, 'coordinates')
										: geometry.type === 'LineString'
										? getCenter(get(geometry, 'coordinates'))
										: getCenter(get(geometry, 'coordinates.0'));
								if (coords) {
									setCenter([coords[1], coords[0]]);
								}
								setHoverLegend(geometry.id);
							}}
						>
							<div className="map-geometry-type">{getType(geometry.type)}</div>
							<div className="map-enderecos-list">
								{geometry.type === 'Point' ? (
									<div>{properties.endereco}</div>
								) : geometry.type === 'LineString' ? (
									properties.enderecos.map((endereco, index) => (
										<div id={index} key={index}>
											{endereco}
										</div>
									))
								) : (
									properties.enderecos.map(end =>
										end.map((endereco, index) => (
											<div id={index} key={index}>
												{endereco}
											</div>
										))
									)
								)}
							</div>
						</div>
					);
				})}
		</div>
	);
}
MapSideView.displayName = 'MapSideView';
MapSideView.propTypes = {
	containerClass: PropTypes.string,
	features: PropTypes.arrayOf(PropTypes.object),
	setHoverLegend: PropTypes.func,
	setCenter: PropTypes.func
};
