import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import withMessage from 'components/WithMessage';

const HabiteSe = () => (
	<Fragment>
		<div className="container">
			<div className="subhead">
				<ul className="breadcrumb">
					<li>
						<a href="/">Início</a>
					</li>
					<li>Habite-se</li>
				</ul>
			</div>
			<h1 className="page-header" style={{ marginBottom: '3rem' }}>
				Habite-se
			</h1>
			<div className="conteudo-habite-se" style={{ maxWidth: '800px' }}>
				<p>
					O Habite-se (Carta de Habitação) é uma certidão expedida pela prefeitura atestando que o imóvel está pronto
					para ser habitado e que foi construído ou reformado conforme as exigências legais estabelecidas pelo
					município.
				</p>
				<h2 style={{ color: '#525257' }}>Consulte a segunda via da Carta de Habitação:</h2>
				<ul className="nav nav-pills" style={{ display: 'flex' }}>
					<li className="active">
						<button type="button">Por endereço</button>
					</li>
					<li className="">
						<button type="button">Por expediente único</button>
					</li>
				</ul>
				<form>
					<div className="form-row">
						<div className="form-group col-md-10">
							<label htmlFor="#">Logradouro</label>
							<input
								type="#"
								className="form-control"
								id="#"
								aria-describedby="#"
								placeholder="Informe o nome da rua, avenida, acesso etc."
							/>
						</div>
						<div className="form-group col-md-2">
							<label htmlFor="#">Número</label>
							<input type="#" className="form-control" id="#" />
						</div>
					</div>
					<div className="form-check">
						<input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
						<label className="form-check-label" htmlFor="defaultCheck1">
							Dados históricos (marque para pesquisar endereços antigos)
						</label>
					</div>

					<button type="submit" className="btn btn-primary" style={{ marginTop: '3rem' }}>
						Pesquisar
					</button>
				</form>
			</div>
		</div>
	</Fragment>
);

HabiteSe.displayName = 'ValidacaoDocumentos';
HabiteSe.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

export default withMessage(HabiteSe);
