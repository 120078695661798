import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';
import uuid from 'uuid/v4';

import Label from './label';

const Question = ({ question, onChange, readOnly, root, debug }) => {
	const questions = question.questions || [];
	//.filter(q => q.id.startsWith(root || ''));
	return (
		<>
			{size(questions) > 0 && (
				<>
					<div className="label-enquadramento">
						<span>{question.sublabel}</span>
					</div>
					<div
						className="question-container"
						style={{
							backgroundColor: readOnly ? '#edeff1' : 'white'
						}}
					>
						{questions.map(question => (
							<Label
								key={uuid()}
								id={question.id}
								text={question.label}
								selected={root && root.startsWith(question.id)}
								debug={debug}
								onClick={() => (readOnly ? null : onChange({ root: question.id }))}
							/>
						))}
					</div>
				</>
			)}
		</>
	);
};
Question.displayName = 'Question';
Question.propTypes = {
	question: PropTypes.object,
	readOnly: PropTypes.bool,
	root: PropTypes.string,
	onChange: PropTypes.func,
	debug: PropTypes.bool
};

export default Question;
