import React, { useState, useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { accessApi } from 'utils/injectApi';
// import { isLocalhost } from 'utils/tools';

import {
	CdlField,
	EnderecoExpedientesField,
	ExpedienteEnderecosField,
	NumeroDamField,
	TextAreaField,
	TextField,
	TipoLicencaExpressaField,
	ResponsavelTecnicoField,
	validateCdlField,
	validateEnderecoExpedientesField,
	validateExpedienteEnderecosField,
	validateNumeroDamField,
	validateResponsavelTecnicoField,
	validateTextAreaField,
	validateTextField,
	validateTipoLicencaExpressaField
} from '../fields';

const TIPO_FORMULARIO_LICENCA_EXPRESSA = 'licenca-expressa';
const ID_LICENCA_DEMOLICAO_PARCIAL = '36dbaeb4-7c3c-4b83-8cb2-6c84ae083d66';
const ID_LICENCA_DEMOLICAO_TOTAL = 'cdef277b-422c-4052-991a-0fcdd7b8d9ef';

/* eslint-disable react-hooks/exhaustive-deps */
const LicencaExpressaForm = ({ data: propsData, usuario, readOnly, showNext = true, onSubmit = () => false }) => {
	const [loading, setLoading] = useState(true);
	const [licencaExpressaFields, setLicencaExpressaFields] = useState(null);
	const [data, setData] = useState(propsData);
	const [errors, setErrors] = useState({});
	const [qtdErrors, setQtdErrors] = useState(0);
	const [showDam, setShowDam] = useState(false);
	const [formulario, setFormulario] = useState({});

	// formulario
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			const formulario = isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm;
			setFormulario(formulario);
			const showDamNoRequerimento = get(formulario, 'mostrarDamRequerimento', false) === true;
			setShowDam(showDamNoRequerimento);
		}
	}, [formularioImm]);

	useEffect(
		() => () => {
			setData(null);
		},
		[]
	);

	useEffect(() => {
		carregaMetadata();
		if (propsData) {
			setData(propsData);
		}
	}, [propsData]);

	const carregaMetadata = async () => {
		setLoading(true);
		if (size(licencaExpressaFields) === 0) {
			try {
				const retorno = (await accessApi('/collections/metadata/licenca-expressa'))?.data;
				const licencaExpressaFields = get(retorno, 'fields') || {};
				let fields = licencaExpressaFields;
				if (!showDam) {
					fields = Object.entries(licencaExpressaFields).reduce((p, c) => {
						const [k, v] = c;
						if (['numeroDamField', 'numeroDam'].includes(v?.type)) return p;
						if ('valorDam' === k || v.usage === 'dam_value') return p;
						return Object.assign(p, { [k]: v });
					}, {});
				}
				setLicencaExpressaFields(fields);
			} catch (e) {
				setLicencaExpressaFields({});
				console.error('Erro no acesso aos metadados da licenca-expressa: ', e);
			}
		}
		setLoading(false);
	};

	const changeHandler = obj => {
		if (obj.target) {
			setData({ ...data, [obj.target.name]: obj.target.value });
		} else {
			if (Array.isArray(obj)) {
				const newData = { ...data };
				let modified = false;
				obj.forEach(o => {
					if (o.name === 'errors') {
						trataErrors(o.value);
					} else {
						modified = true;
						newData[o.name] = o.value;
					}
				});
				if (modified) {
					setData(newData);
				}
			} else {
				if (obj.name === 'errors') {
					trataErrors(obj.value);
				} else {
					setData({ ...data, [obj.name]: obj.value });
				}
			}
		}
	};

	const trataErrors = novosErros => {
		// inicializa com todos os erros que já existiam
		const newErrors = { ...errors };

		// substitui, inteiramente, os erros que já existiam pelos valores
		// que estão chegando para as propriedades que estão entrando
		for (let property in novosErros) {
			newErrors[property] = novosErros[property];
		}

		// inicializa um novo objeto com erros limpo,
		// com todas as propriedades envolvidas
		const cleanErrors = {};
		for (let property in newErrors) {
			const propertyErrors = newErrors[property];

			if (Array.isArray(propertyErrors)) {
				cleanErrors[property] = [];
			} else {
				cleanErrors[property] = {};
				Object.keys(propertyErrors || {}).forEach(n => {
					cleanErrors[property][n] = [];
				});
			}
		}

		// evita a adição de mensagens duplicadas
		for (let property in newErrors) {
			const propertyErrors = newErrors[property];
			let propCleanErrors = cleanErrors[property];

			if (Array.isArray(propertyErrors)) {
				// propertyErrors é um array de mensagens de uma propriedade
				propertyErrors.forEach(message => {
					if (propCleanErrors.indexOf(message) === -1) {
						propCleanErrors.push(message);
					}
				});
			} else {
				// propertyErrors é um objeto com propriedades,
				// cada uma com um array de mensagens
				Object.keys(propertyErrors || {}).forEach(name => {
					(propertyErrors[name] || []).forEach(message => {
						if (propCleanErrors[name].indexOf(message) === -1) {
							propCleanErrors[name].push(message);
						}
					});
				});
			}
		}

		// set valor com todos os erros previamente existentes
		// substituindo o valor dos atributos que entraram no
		// novosErros pelo valor deles nesse obejto
		setErrors(Object.assign(cleanErrors));
	};

	const validate = () => {
		let errors = {};
		Object.keys(licencaExpressaFields).forEach(name => {
			const { required: req, label: lab, title: tit } = licencaExpressaFields[name];
			const value = data[name];
			const type = licencaExpressaFields[name].type;

			let validateFunction = null;

			switch (type) {
				case 'tipoLicencaExpressaField': {
					errors[name] = validateTipoLicencaExpressaField(
						value,
						get(data, 'tipoLicencaExpressa.codOcorrenciaLicenca'),
						licencaExpressaFields[name].fields
					);
					break;
				}

				case 'cdlField': {
					errors[name] = validateCdlField(get(value, 'nomeLogradouro'), get(value, 'numero'), value, req, lab, tit);
					break;
				}
				case 'enderecoExpedientesField': {
					validateFunction = validateEnderecoExpedientesField;
					break;
				}
				case 'expedienteEnderecosField': {
					validateFunction = validateExpedienteEnderecosField;
					break;
				}
				case 'numeroDamField': {
					validateFunction = validateNumeroDamField;
					break;
				}
				case 'responsavelTecnicoField': {
					validateFunction = validateResponsavelTecnicoField;
					break;
				}
				case 'textAreaField': {
					validateFunction = validateTextAreaField;
					break;
				}
				case 'textField': {
					validateFunction = validateTextField;
					break;
				}

				default:
					console.error('field ainda não implementado', type);
			}
			if (validateFunction) {
				if (
					formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCA_EXPRESSA &&
					formulario?.id === ID_LICENCA_DEMOLICAO_PARCIAL
				) {
					const objLicExpressa = {
						tipoMaterial: data?.licencaExpressa?.tipoMaterial,
						areaDemolicao: data?.licencaExpressa?.areaTotalDemolir?.value?.replace(',', '.')
					};
					errors[name] = validateFunction(value, req, lab, tit, false, objLicExpressa);
				} else if (
					formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCA_EXPRESSA &&
					formulario?.id === ID_LICENCA_DEMOLICAO_TOTAL
				) {
					const objLicExpressa = {
						tipoMaterial: data?.licencaExpressa?.tipoMaterial,
						areaDemolicao: data?.licencaExpressa?.areaObjetoAnalise?.value?.replace(',', '.')
					};
					errors[name] = validateFunction(value, req, lab, tit, false, objLicExpressa);
				} else {
					errors[name] = validateFunction(value, req, lab, tit);
				}
			}
		});
		trataErrors(errors);
		return errors;
	};

	const submitHandler = () => {
		if (readOnly) {
			onSubmit();
		} else {
			const errors = validate();

			let nroErrors = 0;
			for (const key in errors) {
				const element = errors[key];
				if (Array.isArray(element)) {
					nroErrors += element.length;
				} else {
					for (const k2 in element) {
						const elem2 = element[k2];
						nroErrors += elem2.length;
					}
				}
			}

			setQtdErrors(nroErrors);

			if (nroErrors === 0) {
				onSubmit(data);
			}
		}
	};
	return (
		<>
			{loading ? (
				<Loader msg="Carregando metadados da Licença Expressa" />
			) : data ? (
				<form className="rjsf" autoComplete="off" acceptCharset="ISO-8859-1" noValidate="">
					<div className="row">
						<fieldset id="root">
							{data.parecerRevisao && readOnly && (
								<div className="row">
									<div className="col-md-12">
										<div className="form-group col-md-12">
											<label>Recomendação da Revisão</label>
											<textarea rows="3" value={data.parecerRevisao} disabled={true} readOnly={true} />
										</div>
									</div>
								</div>
							)}

							<div className="row">
								{Object.keys(licencaExpressaFields)
									// .filter(
									// 	k =>
									// 		isLocalhost
									// 			? true
									// 			: k !== 'identificacao' /* 2020-02-05: REMOVIDO A PEDIDO DE RAFAEL, POR TELEFONEMA À NADIA */
									// )
									.map((name, i) => (
										<Fragment key={i}>
											{licencaExpressaFields[name].type === 'textField' && (
												<TextField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || ''}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}
											{licencaExpressaFields[name].type === 'cdlField' && (
												<CdlField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name]}
													containerClass={licencaExpressaFields[name].containerClass}
													pesquisarExpedientes={licencaExpressaFields[name].pesquisarExpedientes}
													errorList={errors[name]}
													readOnly={readOnly}
													onChangeHandler={changeHandler}
												/>
											)}
											{licencaExpressaFields[name].type === 'enderecoExpedientesField' && (
												<EnderecoExpedientesField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || ''}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}
											{licencaExpressaFields[name].type === 'expedienteEnderecosField' && (
												<ExpedienteEnderecosField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || {}}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}
											{licencaExpressaFields[name].type === 'numeroDamField' && (
												<NumeroDamField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || ''}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}
											{licencaExpressaFields[name].type === 'responsavelTecnicoField' && (
												<ResponsavelTecnicoField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || {}}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}

											{licencaExpressaFields[name].type === 'tipoLicencaExpressaField' && (
												<TipoLicencaExpressaField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || {}}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													tipoLicencaExpressa={get(data, 'tipoLicencaExpressa.codOcorrenciaLicenca')}
													errorObj={errors[name]}
													readOnly={readOnly}
													fields={licencaExpressaFields[name].fields}
												/>
											)}
											{licencaExpressaFields[name].type === 'textAreaField' && (
												<TextAreaField
													title={licencaExpressaFields[name].title}
													label={licencaExpressaFields[name].label}
													required={licencaExpressaFields[name].required}
													placeHolder={licencaExpressaFields[name].placeHolder}
													name={name}
													value={data[name] || ''}
													onChangeHandler={changeHandler}
													containerClass={licencaExpressaFields[name].containerClass}
													errorList={errors[name]}
													readOnly={readOnly}
												/>
											)}
										</Fragment>
									))}
								{usuario && (
									<div className="col-md-12">
										<div className="col-md-12">
											<h3>Usuário Criador</h3>
											<input
												className="form-control"
												type="text"
												disabled
												value={`${get(usuario, 'name')} (${get(usuario, 'email')})`}
											/>
										</div>
									</div>
								)}
							</div>
							{!readOnly && showNext && (
								<div className="cjto-botoes">
									<button type="button" className="btn-primary" onClick={() => submitHandler()}>
										Avançar
									</button>
								</div>
							)}
							{qtdErrors > 0 && (
								<ErrorMessages
									errorList={[`Ainda ${qtdErrors === 1 ? 'existe' : 'existem'} ${qtdErrors} erros no formulario`]}
								/>
							)}
						</fieldset>
					</div>
				</form>
			) : (
				<h2>Aguardando dados...</h2>
			)}
		</>
	);
};
LicencaExpressaForm.displayName = 'Licencao Expressa Form';

LicencaExpressaForm.propTypes = {
	data: PropTypes.object,
	usuario: PropTypes.object,
	readOnly: PropTypes.bool,
	showNext: PropTypes.bool,
	onSubmit: PropTypes.func
};

export default LicencaExpressaForm;
