import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import IntlNumberInput from 'components/react-intl-number-input';
import Selecao from 'components/Selecao';
import TooltipIcon from 'components/TooltipIcon';

import { accessApi } from 'utils/injectApi';
import { sortCadastros } from 'utils/tools';

const LABELS = {
	semRestricao: 'Sem restrição',
	proibido: 'Proibido',
	area: 'Com restrição de porte',
	naoInformado: '___'
};

const AtividadeItemEdit = ({ atividadeItem, separatorClass, onChange, onRemove, onAddItem }) => {
	const [anexo52list, setAnexo52list] = useState([]);
	const [term52, setTerm52] = useState('');

	useEffect(() => {
		const acessaAnexo52 = async () => {
			try {
				const retorno2 = (await accessApi('anexo52/')).data;
				const anexo52listAux = sortCadastros(retorno2, 'codigo');
				setAnexo52list(anexo52listAux);
			} catch (e) {
				setAnexo52list([]);
				console.error('erro no servidor', e);
			}
		};
		acessaAnexo52();
	}, []);

	const instant = Date.now();

	return atividadeItem ? (
		<>
			{
				<div className={`atividade-item  ${separatorClass}`}>
					<div className="atividade">
						<div className="form-group">
							<label>
								<TooltipIcon label="Descrição (anexo 5.2) *">
									Informar a atividade pretendida, conforme Anexo 5.2 do PDDUA. Verificar se é permitida no endereço em
									questão.
								</TooltipIcon>
							</label>
							<Selecao
								className={'form-control'}
								multiple={false}
								selected={atividadeItem.anexo52}
								label={'Atividade (anexo 5.2)'}
								detailCodigo={'codigo'}
								detailDescricao={'descricao'}
								autoShowList={true}
								searchTerm={term52}
								searchList={anexo52list.filter(item => {
									const regex = new RegExp(term52, 'i');
									return regex.test(item.codigo) || regex.test(item.descricao);
								})}
								searchTermMinLength={1}
								errorList={false}
								onChangeSearchTerm={event => setTerm52(event.target.value)}
								onBlurSearchTerm={() => setTerm52('')}
								onSelectItem={item => () => onChange({ ...atividadeItem, anexo52: item })}
								onUnselect={() => () => onChange({ ...atividadeItem, anexo52: null })}
								loading={false}
								required={true}
								placeholder="Selecione a atividade"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="anexo53">
								<TooltipIcon label="Restrição (anexo 5.3)">
									Informar as restrições de implantaçâo e de porte, conforme Anexos 5.3 do do PDDUA.
								</TooltipIcon>
							</label>
							<div className="inline-input-radio-checkbox">
								<label>
									<input
										type="radio"
										name={`anexo53-${instant}`}
										value="semRestricao"
										checked={atividadeItem.anexo53 === 'semRestricao'}
										onClick={e => onChange({ ...atividadeItem, anexo53: e.target.value })}
										onChange={() => false}
									/>
									{LABELS.semRestricao}
								</label>
							</div>
							<div className="inline-input-radio-checkbox">
								<label>
									<input
										type="radio"
										name={`anexo53-${instant}`}
										value="proibido"
										checked={atividadeItem.anexo53 === 'proibido'}
										onClick={e => onChange({ ...atividadeItem, anexo53: e.target.value })}
										onChange={() => false}
									/>
									{LABELS.proibido}
								</label>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="anexo54">
								<TooltipIcon label="Restrição (anexo 5.4)">
									Informar as restrições de implantaçâo e de porte, conforme Anexos 5.4 do do PDDUA.
								</TooltipIcon>
							</label>
							<div className="inline-input-radio-checkbox">
								<label>
									<input
										type="radio"
										name={`anexo54-${instant}`}
										value="semRestricao"
										checked={atividadeItem.anexo54 === 'semRestricao'}
										onClick={e => onChange({ ...atividadeItem, anexo54: e.target.value })}
										onChange={() => false}
									/>
									{LABELS.semRestricao}
								</label>
							</div>
							<div className="inline-input-radio-checkbox">
								<label>
									<input
										type="radio"
										name={`anexo54-${instant}`}
										value="proibido"
										checked={atividadeItem.anexo54 === 'proibido'}
										onClick={e => onChange({ ...atividadeItem, anexo54: e.target.value })}
										onChange={() => false}
									/>
									{LABELS.proibido}
								</label>
							</div>
							<div className="inline-input-radio-checkbox">
								<label>
									<input
										type="radio"
										name={`anexo54-${instant}`}
										value="area"
										checked={atividadeItem.anexo54 === 'area'}
										onClick={e => onChange({ ...atividadeItem, anexo54: e.target.value })}
										onChange={() => false}
									/>
									{LABELS.area}
								</label>
							</div>
							{atividadeItem.anexo54 === 'area' && (
								<label>
									Informe o limite de porte
									<IntlNumberInput
										type="text"
										className="form-control"
										locale="pt-BR"
										suffix={' m²'}
										precision={2}
										value={atividadeItem.anexo54Area}
										onChange={(event, value) => onChange({ ...atividadeItem, anexo54Area: value })}
										placeholder="Área anexo 5.4"
									/>
								</label>
							)}
						</div>
					</div>
					<div className="side-toolbar">
						{onRemove && <i className="fa fa-trash" onClick={onRemove} />}
						{onAddItem && <i className="fa fa-plus" onClick={onAddItem} />}
					</div>
				</div>
			}
		</>
	) : null;
};
AtividadeItemEdit.displayName = 'AtividadeItemEdit';

AtividadeItemEdit.propTypes = {
	atividadeItem: PropTypes.object,
	separatorClass: PropTypes.string,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
	onAddItem: PropTypes.func
};

export default AtividadeItemEdit;
