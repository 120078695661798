import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router';

import { get } from 'lodash';

import ArrazoadoEvu from 'containers/ArrazoadoEvu';

import { API_URL } from 'environments';

import { EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU } from '../Detalhe';

const FORMULARIO_EM_RASCUNHO = 'Formulario em rascunho';
const AGUARDANDO_CRIACAO_PROCESSO_SEI = 'Aguardando criação do processo';
const ARQUIVO_ANEXADO_AO_PROCESSO = 'Anexado ao Processo';
const INVALIDADO_PELO_AGENTE = 'Invalidado pelo agente';
const AINDA_SEM_DOCUMENTO = 'Ainda sem documento';
const EM_COMPLEMENTACAO = 'Em complementacao';

const DocumentoArrazoadoEvu = ({ documento, dados, usuarioDonoTask, removeFile }) => {
	const { id: idFormData } = useParams();

	const [showFormulario, setShowFormulario] = useState(false);

	const jaTemArquivo = !!documento.filename;
	const emProcessamento = jaTemArquivo && !documento.idDocumentoSei;
	const emComplementacao = documento.invalido && !documento.substituido;

	let estado = '';
	if (jaTemArquivo) {
		if (emProcessamento) {
			estado = EM_COMPLEMENTACAO;
		} else {
			if (emComplementacao) {
				estado = EM_COMPLEMENTACAO;
			} else {
				estado = ARQUIVO_ANEXADO_AO_PROCESSO;
			}
		}
	} else {
		if (dados) {
			if (dados.draft) {
				estado = FORMULARIO_EM_RASCUNHO;
			} else {
				if (documento.original && dados.id === documento.original) {
					estado = INVALIDADO_PELO_AGENTE;
				} else {
					estado = FORMULARIO_EM_RASCUNHO;
				}
			}
		} else {
			estado = AINDA_SEM_DOCUMENTO;
		}
	}

	// const estado2 = jaTemArquivo
	// 	? emProcessamento
	// 		? emComplementacao
	// 			? EM_COMPLEMENTACAO
	// 			: AGUARDANDO_CRIACAO_PROCESSO_SEI
	// 		: ARQUIVO_ANEXADO_AO_PROCESSO
	// 	: documento.original && (dados || {}).id === documento.original
	// 	? INVALIDADO_PELO_AGENTE
	// 	: dados
	// 	? FORMULARIO_EM_RASCUNHO
	// 	: AINDA_SEM_DOCUMENTO;

	const blocoPorEstado = estado => {
		let jsx;
		switch (estado) {
			case AINDA_SEM_DOCUMENTO:
				jsx = (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
					>
						Criar Arrazoado EVU
					</button>
				);
				break;

			case FORMULARIO_EM_RASCUNHO:
				jsx = (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
					>
						Arrazoado EVU em rascunho
					</button>
				);
				break;

			case INVALIDADO_PELO_AGENTE:
				jsx = (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => (usuarioDonoTask ? setShowFormulario(true) : null)}
					>
						Corrigir o documento de Arrazoado EVU
					</button>
				);
				break;

			case AGUARDANDO_CRIACAO_PROCESSO_SEI:
			case EM_COMPLEMENTACAO:
				jsx = (
					<>
						<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />
						<i
							className="fa fa-over fa-exclamation-circle"
							style={{
								color: '#09afa7'
							}}
						>
							<div className="icon-tooltip">Arquivo salvo no servidor. Aguardando criação do processo.</div>
						</i>
						<a
							className="btn-link"
							target="_blank"
							rel="noopener noreferrer"
							href={`${API_URL}/processo/${idFormData}/${EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU}`}
						>
							{documento.originalName}
						</a>
						{usuarioDonoTask && (
							<div style={{ float: 'right' }}>
								<button type="button" className="btn-link" onClick={() => setShowFormulario(true)}>
									<i className="fa fa-pencil-square-o" style={{ fontSize: '18px' }} />
								</button>
								<button type="button" className="btn-link" onClick={() => removeFile(documento)}>
									<i className="fa fa-trash-o" style={{ fontSize: '18px' }} />
								</button>
							</div>
						)}
					</>
				);
				break;

			case ARQUIVO_ANEXADO_AO_PROCESSO:
				jsx = (
					<>
						<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />
						<i
							className="fa fa-over fa-check"
							style={{
								color: 'green'
							}}
						>
							<div className="icon-tooltip">Arquivo anexado ao processo.</div>
						</i>
						<a href={get(documento, 'urlConsultaPublicaSei')} target="_blank" rel="noopener noreferrer">
							{documento.originalName}
							<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
						</a>
					</>
				);
				break;

			default:
				jsx = '&nbsp';
		}
		return jsx;
	};

	return (
		<>
			<div className="form-control" style={{ marginTop: '6px', border: '2px solid #c5c5c9', padding: '16px' }}>
				{blocoPorEstado(estado)}
			</div>
			{showFormulario && (
				<Modal
					show={showFormulario}
					onHide={() => setShowFormulario(false)}
					dialogClassName="modal-90w"
					aria-labelledby="Arrazoado EVU"
				>
					<Modal.Header closeButton>
						<Modal.Title id="arrazoadoEvu">Arrazoado EVU</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ArrazoadoEvu setShowFormulario={setShowFormulario} />
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};

DocumentoArrazoadoEvu.displayName = 'DocumentoArrazoadoEvu';

DocumentoArrazoadoEvu.propTypes = {
	documento: PropTypes.object,
	dados: PropTypes.object,
	emComplementacao: PropTypes.bool,
	usuarioDonoTask: PropTypes.bool,
	removeFile: PropTypes.func
};

export default DocumentoArrazoadoEvu;
