import React, { Fragment, useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';

import { padStart, size, trim } from 'lodash';
import moment from 'moment';

import Loader from 'components/Loader';

import CdlField from 'containers/Form/fields/cdlField';
import ExpedienteEnderecosField from 'containers/Form/fields/expedienteEnderecosField';

import useRecaptchaV3 from 'custom-hooks/useRecaptchaV3';

import { EXPEDIENTES_API_URL } from 'environments';

import { PROCESSOS_FORMAT } from 'utils/enums';
import { accessApi } from 'utils/injectApi';
import { formataProcessos } from 'utils/tools';

function ConsultaCartaHabitacao() {
	const executeRecaptcha = useRecaptchaV3();

	const [aba, setAba] = useState('endereco');
	const [endereco, setEndereco] = useState(null);
	const [expediente, setExpediente] = useState(null);
	const [cartas, setCartas] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingCadastro, setLoadingCadastro] = useState(false);
	const [mensagem, setMensagem] = useState(null);
	const [cadastrosPrediais, setCadastrosPrediais] = useState(null);

	const onChangeHandler = obj => {
		if (Array.isArray(obj)) {
			obj.forEach(item => {
				if (item.name === 'endereco') {
					setEndereco(item.value);
				}
			});
		} else {
			if (obj.name === 'expediente') {
				setExpediente(obj.value);
			}
		}
	};

	const pesquisaPorEndereco = async () => {
		try {
			const RECAPTCHA_ACTION = 'pesquisa_carta_habitacao_por_endereco';
			const token = await executeRecaptcha(RECAPTCHA_ACTION);
			const payload = { token, action: RECAPTCHA_ACTION };

			setCartas(null);
			setLoading(true);
			setMensagem(null);
			const { codigoLogradouro, codLogradouro, numero } = endereco || {};
			const codigo = codigoLogradouro || codLogradouro;

			if (!codigo || !numero) {
				setCartas({});
				setMensagem('Informe o endereço para pesquisar as cartas de habitação.');
				return;
			}

			const response = await accessApi(
				`EXPEDIENTES_API_URL/listacartahabitacaoenderecopost?codigoLogradouro=${codigo}&numero=${numero}`,
				true,
				{
					method: 'POST',
					data: payload
				}
			);

			if (size(response.data) === 0) {
				setCartas({});
				setMensagem('Não foram encontradas cartas de habitação para o endereço informado.');
				return;
			}

			let habiteses = response.data || [];

			// garante que não haja repetição nos ids
			const idsUnicos = habiteses.reduce((acc, eu) => ({ ...acc, [`ids=${eu.idExpediente}`]: null }), {});

			// prepara a queryString para a consulta de cadastro predial
			const ids = Object.keys(idsUnicos).join('&');

			let cadastrosPrediais = {};
			if (size(idsUnicos) > 0) {
				const response2 = await accessApi(`EXPEDIENTES_API_URL/consulta-cadastro-predial?${ids}`, true);
				cadastrosPrediais = response2.data || {};
			}

			let auxCartas = {};
			habiteses.forEach(cHab => {
				if (!auxCartas[cHab.codigo]) {
					auxCartas[cHab.codigo] = {
						expedienteUnico: cHab.expedienteUnico,
						areaPrivativa: cHab.areaPrivativa,
						vistorias: [],
						cadastrosPrediais: cadastrosPrediais[cHab.idExpediente] || []
					};
				}
				if (/deferido/i.test(cHab.resultado) && cHab.homologada) {
					auxCartas[cHab.codigo].vistorias.push(cHab);
				}
			});

			Object.keys(auxCartas).forEach(key => {
				const eu = auxCartas[key];
				eu.vistorias = eu.vistorias.sort((v1, v2) => {
					if (v1.dataOcorrencia > v2.dataOcorrencia) {
						return -1;
					}
					if (v1.dataOcorrencia < v2.dataOcorrencia) {
						return 1;
					}
					return 0;
				});
			});

			setCartas(auxCartas);
		} catch (err) {
			setCartas({});
			setMensagem('Problemas ao pesquisar cartas para os expedientes do endereço. Tente novamente mais tarde.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const pesquisaPorExpediente = async () => {
		try {
			const RECAPTCHA_ACTION = 'pesquisa_carta_habitacao_por_expediente';
			const token = await executeRecaptcha(RECAPTCHA_ACTION);
			const payload = { token, action: RECAPTCHA_ACTION };

			setCartas(null);
			setLoading(true);
			setMensagem(null);
			const { numero, areaPrivativa } = expediente || {};

			if (!numero) {
				setCartas({});
				setMensagem('Informe o expediente para pesquisar as cartas de habitação.');
				return;
			}

			const response = await accessApi(
				`EXPEDIENTES_API_URL/listacartahabitacaoexpuniareapost?numeroExpediente=${numero}&areaPrivativa=${areaPrivativa}`,
				true,
				{
					method: 'POST',
					data: payload
				}
			);

			if (size(response.data) === 0) {
				setCartas({});
				setMensagem('Não foram encontradas cartas de habitação para o expediente informado.');
				return;
			}

			let eus = response.data || [];

			// garante que não haja repetição nos ids
			const idsUnicos = eus.reduce((acc, eu) => ({ ...acc, [`ids=${eu.idExpediente}`]: null }), {});

			// prepara a queryString para a consulta de cadastro predial
			const ids = Object.keys(idsUnicos).join('&');

			let cadastrosPrediais = {};
			if (size(idsUnicos) > 0) {
				const response2 = await accessApi(`EXPEDIENTES_API_URL/consulta-cadastro-predial?${ids}`, true);
				cadastrosPrediais = response2.data || {};
			}

			let auxCartas = {};
			eus.forEach(eu => {
				if (!auxCartas[eu.codigo]) {
					auxCartas[eu.codigo] = {
						expedienteUnico: eu.expedienteUnico,
						areaPrivativa: eu.areaPrivativa,
						vistorias: [],
						cadastrosPrediais: cadastrosPrediais[eu.idExpediente] || []
					};
				}
				if (/deferido/i.test(eu.resultado) && eu.homologada) {
					auxCartas[eu.codigo].vistorias.push(eu);
				}
			});
			setCartas(auxCartas);
		} catch (err) {
			setCartas({});
			setMensagem('Problemas ao pesquisar cartas para o expediente. Tente novamente mais tarde.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const emiteCarta = (idExpediente, idVistoria) => {
		const url = `${EXPEDIENTES_API_URL}/cartahabitacao/${idExpediente}?idVistoria=${idVistoria}`;
		window.open(url, '_blank', 'noopener noreferrer');
	};

	const contaCadastrosPrediais = enderecosObj => {
		let total = 0;
		Object.keys(enderecosObj || {}).forEach(end => {
			const endEtapas = enderecosObj[end];
			endEtapas.forEach(endEtapa => {
				const etapas = (endEtapa.etapas || []).filter(e => e.numCartaHab);
				total += size(etapas);
			});
		});
		return total;
	};

	const pesquisaCadastroPredial = async numCartaHab => {
		try {
			const RECAPTCHA_ACTION = 'pesquisa_cartas_cadastro_predial';
			const token = await executeRecaptcha(RECAPTCHA_ACTION);
			const payload = { token, action: RECAPTCHA_ACTION };

			setCadastrosPrediais(null);
			setLoadingCadastro(true);
			const response = await accessApi(
				`/api/pesquisa-carta-habitacao-cadastro-predial?numCartaHab=${numCartaHab}`,
				true,
				{
					method: 'POST',
					data: payload
				}
			);

			if (size(response.data) === 0) {
				setMensagem('Carta de habitação não encontrada no cadastro predial.');
				return;
			}
			setCadastrosPrediais(response.data?.items || []);
		} catch (err) {
			setCadastrosPrediais(null);
			setMensagem('Problemas ao pesquisar cartas no cadastro predial. Tente novamente mais tarde.');
			console.error(err);
		} finally {
			setLoadingCadastro(false);
		}
	};

	const openFile = path => {
		const ini = path.indexOf('minio/livro');
		const url = `${EXPEDIENTES_API_URL}/${path.substring(ini)}`;
		window.open(url, '_blank', 'noopener noreferrer');
	};

	useEffect(() => console.debug('cartas', cartas), [cartas]);
	useEffect(() => console.debug('cadastrosPrediais', cadastrosPrediais), [cadastrosPrediais]);

	return (
		<div className="container cartas-habitacao">
			<div className="subhead">
				<ul className="breadcrumb">
					<li>
						<a href="/">Início</a>
					</li>
					<li>Consulta Carta Habitação</li>
				</ul>
			</div>
			<h1 className="page-header row">Consulta Carta Habitação</h1>
			<br />
			<div className="row">
				<section className="col-md-8">
					<div>
						<p>
							O Habite-se (Carta de Habitação) é uma certidão expedida pela prefeitura atestando que o imóvel está
							pronto para ser habitado e que foi construído ou reformado conforme as exigências legais estabelecidas
							pelo município.
						</p>
						<p>
							<a href="http://www2.portoalegre.rs.gov.br/edificapoa/default.php?p_secao=1542" target="blank">
								Clique aqui para ver o ofício que determina a utilização da versão da carta de habite-se nos cartórios
								do município.
							</a>
						</p>
						<p>
							Para consultar a segunda via da carta ou verificar a autenticidade do documento, preencha os campos
							abaixo.
						</p>
					</div>
					<br />
					<div className="row">
						<div className="col-md-12">
							<ul className="nav nav-pills" style={{ gap: '10px' }}>
								<li className={`${aba === 'endereco' ? ' active' : ''}`} onClick={() => setAba('endereco')}>
									<button type="button">Consulta por endereço</button>
								</li>
								<li className={`${aba === 'expediente' ? ' active' : ''}`} onClick={() => setAba('expediente')}>
									<button type="button">Consulta por expediente</button>
								</li>
							</ul>
						</div>
					</div>
					<div className="pesquisas">
						{aba === 'endereco' && (
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<CdlField
											required={true}
											name="endereco"
											value={endereco}
											pesquisarExpedientes={false}
											onChangeHandler={onChangeHandler}
										/>{' '}
									</div>
								</div>
							</div>
						)}
						{aba === 'expediente' && (
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<ExpedienteEnderecosField
											label={['Expediente', 'Área Privativa']}
											placeHolder={['número do expediente', 'número do filhote']}
											required={[false, false]}
											name="expediente"
											value={expediente || {}}
											onChangeHandler={onChangeHandler}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<button
						type="button"
						className="btn btn-primary"
						onClick={aba === 'endereco' ? pesquisaPorEndereco : pesquisaPorExpediente}
					>
						Pesquisar
					</button>
				</section>
				<aside className="col-md-4">
					<div className="region region-sidebar-right">
						<section>
							<div className="block-container">
								<div region="sidebar_right" className="form-group">
									<div className="bloco-servico-contato view view-dados-contato view-id-dados_contato view-display-id-block_5 js-view-dom-id-d6c8b01bd1ee8485b615bbc65215bcf7509f6df4a1585e9c4b6842751ac5b698">
										<div className="view-content">
											<div className="views-row">
												<div className="views-field views-field-nothing">
													<span className="field-content">
														<h2 className="block-title">
															<i className="fa fa-question-circle" style={{ marginRight: '8px' }}></i>Dúvidas
														</h2>
														<br />
													</span>
												</div>
												<div className="views-field views-field-title">
													<span className="field-content">
														<b>Escritório de Licenciamento (SMAMUS)</b>
													</span>
												</div>
												<div className="views-field views-field-field-telefone">
													<div className="field-content">
														{' '}
														<i className="fa fa-phone" style={{ marginRight: '8px' }}></i>
														(51) 3289 7400
													</div>
												</div>
												<div className="views-field views-field-field-e-mail">
													<div className="field-content">
														<i className="fa fa-envelope" style={{ marginRight: '8px' }}></i>
														<a
															href="mailto:escritoriolicenciamento@portoalegre.rs.gov.br"
															style={{ wordWrap: 'break-word' }}
														>
															escritoriolicenciamento@porto alegre.rs.gov.br
														</a>
													</div>
												</div>
												<br />
												<div className="views-field views-field-field-texto-livre">
													<div className="field-content">
														<b>Endereço:</b> Rua Luiz Voelcker, 55 - bairro Três Figueiras
													</div>
												</div>
												<br />
												<div className="views-field views-field-field-texto-paragrafo">
													<div className="field-content">
														<p>
															<strong>Horário de atendimento:</strong> de segunda a sexta-feira, das 9h às 16h, sem
															fechar ao meio-dia
														</p>
													</div>
													<br />
													<div>
														<div className="field-content">
															<p>
																<strong>Agende seu atendimento:</strong>
																<a href="https://agendamento.procempa.com.br/" style={{ wordWrap: 'break-word' }}>
																	{' '}
																	agendamento.procempa.com.br
																</a>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</aside>
			</div>
			{loading && (
				<div className="row" style={{ margin: '2px' }}>
					pesquisando...
				</div>
			)}
			{mensagem && (
				<div className="row" style={{ padding: '30px' }}>
					{mensagem}
				</div>
			)}
			{size(cartas) > 0 && (
				<div className="cartas">
					{Object.keys(cartas).map(codigo => {
						const eu = cartas[codigo];
						return (
							<div key={codigo} className="expediente">
								<div className="expediente-header">
									<div className="particula">
										<span className="label">Expediente</span>
										<span className="value">{formataProcessos(eu.expedienteUnico, PROCESSOS_FORMAT.EXPEDIENTE)}</span>
									</div>
									<div className="particula">
										<span className="label">Área Privativa</span>
										<span className="value">{padStart(eu.areaPrivativa, 5, '0')}</span>
									</div>
								</div>
								{size(eu?.vistorias) > 0 ? (
									<div className="vistorias">
										<h3>Cartas de Habitação</h3>

										{eu.vistorias.map((vistoria, index) => {
											const { idExpediente, idVistoria, tipoVistoriaString, areaVistoriada, dataOcorrencia } = vistoria;
											let areaTotalVistoriada = '';
											let dataVistoria = '';
											if (areaVistoriada) {
												areaTotalVistoriada = new Intl.NumberFormat('pt-BR', {
													style: 'decimal',
													minimumFractionDigits: 2,
													maximumFractionDigits: 2
												}).format(areaVistoriada);
												areaTotalVistoriada = ` (${areaTotalVistoriada} m²)`;
											}
											if (dataOcorrencia) {
												dataVistoria = new Intl.DateTimeFormat('pt-BR').format(dataOcorrencia);
												dataVistoria = ` emitida em ${dataVistoria}`;
											}
											return (
												<div key={index} className="vistoria" onClick={() => emiteCarta(idExpediente, idVistoria)}>
													Vistoria {tipoVistoriaString}
													{areaTotalVistoriada}
													{dataVistoria}
												</div>
											);
										})}
									</div>
								) : (
									<div className="vistorias">
										<h3>Não há cartas de habitação para esse Expediente Único</h3>
									</div>
								)}
								{contaCadastrosPrediais(eu.cadastrosPrediais) > 0 && (
									<div className="cadastros">
										<h3>Cadastros Prediais</h3>
										{Object.keys(eu.cadastrosPrediais || {}).map((endereco, indexE) => {
											const endEtapas = eu.cadastrosPrediais[endereco];
											return endEtapas.map(endEtapa => {
												const etapas = (endEtapa.etapas || []).filter(e => e.numCartaHab);
												if (size(etapas) === 0) {
													return null;
												}
												return (
													<Fragment key={indexE}>
														<div className="endereco">
															<div className="linha">
																<div className="label">Endereço</div>
																<div className="valor">{endereco}</div>
															</div>
														</div>
														<div className="cadastro">
															{etapas.map((etapa, index) => {
																const { codOcorrencia, numCartaHab, despacho, dataDespacho, observacoes } = etapa;
																const despachoStr = /d/i.test(trim(despacho))
																	? 'Deferida'
																	: /i/i.test(trim(despacho))
																	? 'Indeferida'
																	: despacho
																	? trim(despacho)
																	: 'Sem despacho';
																if (!numCartaHab) {
																	return null;
																}
																const { descricao } = codOcorrencia || {};
																return (
																	<div
																		key={index}
																		className="carta"
																		onClick={() => pesquisaCadastroPredial(numCartaHab)}
																	>
																		<div className="textos">
																			<div className="linha">
																				<div className="label">Carta de Habitação</div>
																				<div className="valor">
																					{`${numCartaHab}${!!numCartaHab && !!descricao ? '- ' : ''}${
																						descricao || ''
																					}`}
																				</div>
																			</div>
																			<div className="linha">
																				<div className="label">Despacho</div>
																				<div className="valor">
																					{despachoStr} em {moment(dataDespacho).format('DD/MM/YYYY')}
																				</div>
																			</div>
																			{size(observacoes) > 0 && (
																				<div className="linha-obs">
																					<div className="label">Observações</div>
																					{(observacoes || []).map((observacao, indexO) => (
																						<div key={indexO} className="observacao">
																							{observacao}
																						</div>
																					))}
																				</div>
																			)}
																		</div>
																	</div>
																);
															})}
														</div>
													</Fragment>
												);
											});
										})}
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}
			{loadingCadastro && <Loader msg="Por favor, aguarde. Carregando informações." />}
			<Modal key={'titleAddFile'} show={size(cadastrosPrediais) > 0} onHide={() => setCadastrosPrediais(null)}>
				<Modal.Header>
					<Modal.Title>Documentos encontrados no Cadastro Predial</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
						{cadastrosPrediais?.map((cadastro, index) => (
							<li key={index} onClick={() => openFile(cadastro.path)} style={{ cursor: 'pointer' }}>
								{cadastro.name}
							</li>
						))}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<div className="submit-group-small-left">
						<button type="button" className="btn btn-secondary" onClick={() => setCadastrosPrediais(null)}>
							Fechar
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
ConsultaCartaHabitacao.displayName = 'ConsultaCartaHabitacao';

export default ConsultaCartaHabitacao;
