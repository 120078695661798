import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { formatCodigoEU } from 'utils/tools';

export function ExpedienteSubfield({ formulario }) {
	const expediente = useMemo(() => get(formulario, 'formData.data.expediente'), [formulario]);
	const enderecos = useMemo(() => get(formulario, 'formData.data.enderecoCdlList'), [formulario]);

	return (
		<fieldset>
			<div className="row id-le id-le-dinamico">
				<div className="col-auto exp-un">
					<label>Expediente Único </label>
					{size(get(expediente, 'numero')) > 0 ? (
						<span>{formatCodigoEU(get(expediente, 'numero'), true)}</span>
					) : (
						<span className="nao-informado">Não informado</span>
					)}
				</div>
				<div className="col area-priv">
					<label>
						Área Privativa <small>(unidade privativa/autônoma)</small>
					</label>
					{size(get(expediente, 'codigo')) > 0 ? (
						<span>{get(expediente, 'codigo', '').substr(12, 5)}</span>
					) : (
						<span className="nao-informado">Não informado</span>
					)}
				</div>
			</div>
			<div className="id-le">
				<label>Endereço(s) da solicitação </label>
				{size(enderecos) > 0 && (
					<div className="endereco">
						<ul>
							{enderecos.map((item, index) => (
								<li key={index}>{item.enderecoFormatadoCurto}</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</fieldset>
	);
}

ExpedienteSubfield.displayName = 'ExpedienteSubfield';
ExpedienteSubfield.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	tipoForm: PropTypes.string
};
