import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, intersection, isEqual, isNil, omit, pick, size } from 'lodash';

import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import {
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_ETR_NA_HORA,
	TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
	TIPO_FORMULARIO_CERTIDAO_AVERBACAO,
	TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM,
	TIPO_FORMULARIO_ADESAO_PRCHPA,
	TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA
} from 'containers/Form/Detalhe';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';
import { formatCodigoEU } from 'utils/tools';

import TemplateForm from '../metadata-template/TemplateForm';

const ERRO_EVENTO_RUA = 'Já existe um evento próximo a data desejada no local solicitado.';
const ERRO_TIPO_LOGRADOURO = 'Tipo de logradouro deve ser "Prq." ou "Pca." se evento for em parque ou praça';
const ERRO_AREA_OCUPACAO_PUBLICA =
	'Você precisa marcar Parque/Praça, se escolher um "Prq." ou "Pca." como Logradouro do Evento.';

export function MetadataForm({
	data: propsData,
	usuario,
	readOnly: readOnlyProps,
	type = 'certidao',
	showNext,
	usuarioInterno,
	consultaPublicaExpedientes,
	onSubmit = () => false,
	onChange = () => false
}) {
	const feirasDeRuaEmViasPublicasOuParques = useRef(0);

	const [loading, setLoading] = useState(true);
	const [formFields, setFormFields] = useState(null);
	const [data, setData] = useState(propsData || {});
	const [name, setName] = useState('');
	const [errorList, setErrorList] = useState();
	const [textoAvisoAutomatica, setTextoAvisoAutomatica] = useState();
	const [isLoadingVerificacaoEtr2Anos, setIsLoadingVerificacaoEtr2Anos] = useState();
	const [showDam, setShowDam] = useState(false);
	const [informacoesEU, setInformacoesEU] = useState({});
	const [cache, setCache] = useState({});
	// verificação do EU
	const [textoAvisoAreaNaoEscriturada, setTextoAvisoAreaNaoEscriturada] = useState();
	const [textoAvisoEUNaoMigrado, setTextoAvisoEUNaoMigrado] = useState();
	const [isLoadingVerificacaoMigracaoEU, setIsLoadingVerificacaoMigracaoEU] = useState();
	const [codigoExpedienteAtual, setCodigoExpedienteAtual] = useState(propsData?.expediente?.codigo);

	const debug = false && isDebug;

	// formulario
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			const formulario = isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm;
			const showDamNoRequerimento = get(formulario, 'mostrarDamRequerimento', false) === true;
			setShowDam(showDamNoRequerimento);
		}
	}, [formularioImm]);

	// type é o formulario.idTipoFormulario
	useEffect(
		() => () => {
			setData(null);
			setName(type === 'certidao' ? 'Certidão' : type === 'aprovacao-projetos' ? 'Aprovação de Projetos' : type);
		},
		[type]
	);

	// TODO : obter informações do expediente
	// #region obter informações do expediente
	const validarAreaNaoEscriturada = useCallback((data, type, objAlteracao) => {
		let resposta = null;
		let saida = null;

		if (type === TIPO_FORMULARIO_ETR_NA_HORA || type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
			if (get(objAlteracao, 'key') === 'instalacaoAreaEscriturada' && get(objAlteracao, 'value') === 'nao') {
				resposta = 'nao';
			} else if (get(objAlteracao, 'key') === 'instalacaoAreaEscriturada' && get(objAlteracao, 'value') === 'sim') {
				resposta = 'sim';
			} else if (data?.instalacaoAreaEscriturada === 'nao') {
				resposta = 'nao';
			} else if (data?.instalacaoAreaEscriturada === 'sim') {
				resposta = 'sim';
			}

			if (resposta === 'nao') {
				saida = {
					texto:
						type === TIPO_FORMULARIO_ETR_NA_HORA
							? 'Conforme Lei Complementar n° 838/2018, Art. 24  essa licença possui prazo de 2 (dois) anos para adequação/regularização não podendo ser renovado.'
							: 'Conforme Lei Complementar n° 838/2018, Art. 24 a Licença não poderá ser renovada.',

					tipo: 'alerta'
				};
			} else if (resposta === 'sim' && type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
				saida = {
					texto:
						'Foi identificado que a Licença da Estação Transmissora de Radiocomunicação (ETR) foi gerada em área sem escritura. A renovação desta licença somente poderá ser requerida mediante a regularização da área anexando a Matrícula ou Certidão do Registro de Imóveis na aba 2. Documentos.',
					tipo: 'alerta'
				};
			}
		}

		return saida;
	}, []);

	const validarExpedienteJaTemEtr2Anos = useCallback(
		(data, type, objAlteracao) => {
			let resposta = null;
			let saida = null;

			if (informacoesEU?.[codigoExpedienteAtual]?.temLicencaEtr2Anos) {
				if (type === TIPO_FORMULARIO_ETR_NA_HORA || type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
					if (get(objAlteracao, 'key') === 'instalacaoAreaEscriturada' && get(objAlteracao, 'value') === 'nao') {
						resposta = 'nao';
					} else if (get(objAlteracao, 'key') === 'instalacaoAreaEscriturada' && get(objAlteracao, 'value') === 'sim') {
						resposta = 'sim';
					} else if (data?.instalacaoAreaEscriturada === 'nao') {
						resposta = 'nao';
					} else if (data?.instalacaoAreaEscriturada === 'sim') {
						resposta = 'sim';
					}

					if (resposta === 'nao') {
						saida =
							type === TIPO_FORMULARIO_ETR_NA_HORA
								? 'Expediente já possui um ETR emitido para uma área irregular, portanto não pode solicitar o benefício novamente.'
								: 'Expediente já possui um ETR emitido para uma área irregular, portanto não pode solicitar renovação da licença.';
					}
				}
			}

			return saida;
		},
		[codigoExpedienteAtual, informacoesEU]
	);

	const verificaEuMigrado = useCallback(async codigoExpediente => {
		try {
			setIsLoadingVerificacaoMigracaoEU(true);
			const { data } = await accessApi(
				`${EXPEDIENTES_API_URL}/verificaMigracaoSEI/?codigoExpediente=${codigoExpediente}`,
				true
			);
			const { migradoSei } = data || {};
			setIsLoadingVerificacaoMigracaoEU(false);
			return !!migradoSei;
		} catch (err) {
			setIsLoadingVerificacaoMigracaoEU(false);
			console.error('erro na verificação da migração do EU', err);
		}
	}, []);

	const verificaEtr2Anos = useCallback(async codigoExpediente => {
		setIsLoadingVerificacaoEtr2Anos(true);
		try {
			const { data } = await accessApi(`${EXPEDIENTES_API_URL}/verifica-eu-teve-etr-2anos/${codigoExpediente}`, true);
			const { temLicencaEtr2Anos } = data || {};
			setIsLoadingVerificacaoEtr2Anos(false);
			return !!temLicencaEtr2Anos;
		} catch (error) {
			console.error(`Erro na chamada da api do expedientes /verifica-eu-teve-etr-2anos/${codigoExpediente} `, error);
			setIsLoadingVerificacaoEtr2Anos(false);
			return false;
		}
	}, []);

	useEffect(() => {
		if (codigoExpedienteAtual) {
			if ([TIPO_FORMULARIO_ETR_NA_HORA].includes(type)) {
				if (!isLoadingVerificacaoMigracaoEU) {
					let newInfo = informacoesEU || {};
					if (!newInfo[codigoExpedienteAtual]) {
						newInfo[codigoExpedienteAtual] = {};
					}
					if (!Object.hasOwnProperty.call(newInfo[codigoExpedienteAtual], 'migrado')) {
						verificaEuMigrado(codigoExpedienteAtual)
							.then(retorno => {
								newInfo[codigoExpedienteAtual].migrado = retorno;
							})
							.catch(() => delete newInfo[codigoExpedienteAtual].migrado)
							.finally(() => {
								setInformacoesEU(old => {
									const keysExistsInBoth = intersection(Object.keys(old), Object.keys(newInfo));
									const objectClean = pick(Object.assign(old, newInfo), keysExistsInBoth);
									return objectClean;
								});
							});
					}
				}
			}
			if ([TIPO_FORMULARIO_ETR_NA_HORA, TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO].includes(type)) {
				if (!isLoadingVerificacaoEtr2Anos) {
					let newInfo = informacoesEU || {};
					if (!newInfo[codigoExpedienteAtual]) {
						newInfo[codigoExpedienteAtual] = {};
					}
					if (!Object.hasOwnProperty.call(newInfo[codigoExpedienteAtual], 'temLicencaEtr2Anos')) {
						verificaEtr2Anos(codigoExpedienteAtual)
							.then(retorno => {
								newInfo[codigoExpedienteAtual].temLicencaEtr2Anos = retorno;
							})
							.catch(() => delete newInfo[codigoExpedienteAtual].temLicencaEtr2Anos)
							.finally(() => {
								setInformacoesEU(old => {
									const keysExistsInBoth = intersection(Object.keys(old), Object.keys(newInfo));
									const objectClean = pick(Object.assign(old, newInfo), keysExistsInBoth);
									return objectClean;
								});
							});
					}
				}
			}
		}
	}, [
		codigoExpedienteAtual,
		informacoesEU,
		isLoadingVerificacaoEtr2Anos,
		isLoadingVerificacaoMigracaoEU,
		type,
		verificaEtr2Anos,
		verificaEuMigrado
	]);

	useEffect(() => {
		if (informacoesEU && codigoExpedienteAtual) {
			let errorListFn = old => omit(old || {}, ['instalacaoAreaEscriturada']);
			const infoEU = informacoesEU[codigoExpedienteAtual];
			if (size(infoEU) > 0 && !readOnlyProps) {
				if (Object.hasOwnProperty.call(infoEU, 'migrado')) {
					if (infoEU.migrado) {
						setTextoAvisoEUNaoMigrado(null);
						if (isNil(get(data, 'naHoraEUNaoMigrado')) || !get(data, 'naHoraEUNaoMigrado')) {
							setData(old => ({ ...old, naHoraEUNaoMigrado: true }));
						}
					} else {
						setTextoAvisoEUNaoMigrado({
							texto:
								'Expediente Único não está migrado. Dessa forma, você não poderá emitir a Licença de forma automática, devendo o processo ser analisado pelo revisor.',
							tipo: 'alerta'
						});
						if (isNil(get(data, 'naHoraEUNaoMigrado')) || get(data, 'naHoraEUNaoMigrado')) {
							setData(old => ({ ...old, naHoraEUNaoMigrado: false }));
						}
					}
				}
				if (Object.hasOwnProperty.call(infoEU, 'temLicencaEtr2Anos')) {
					setTextoAvisoAreaNaoEscriturada(validarAreaNaoEscriturada(data, type));
					if (infoEU.temLicencaEtr2Anos) {
						const erro2Anos = validarExpedienteJaTemEtr2Anos(data, type);
						if (erro2Anos) {
							errorListFn = old => ({ ...old, instalacaoAreaEscriturada: [erro2Anos] });
						}
						if (isNil(get(data, 'temLicencaEtr2Anos')) || !get(data, 'temLicencaEtr2Anos')) {
							setData(old => ({ ...old, temLicencaEtr2Anos: true }));
						}
					} else {
						if (isNil(get(data, 'temLicencaEtr2Anos')) || get(data, 'temLicencaEtr2Anos')) {
							setData(old => ({ ...old, temLicencaEtr2Anos: false }));
						}
					}
				}
			}
			setErrorList(errorListFn);
		}
	}, [
		codigoExpedienteAtual,
		data,
		informacoesEU,
		readOnlyProps,
		type,
		validarAreaNaoEscriturada,
		validarExpedienteJaTemEtr2Anos
	]);
	// #endregion

	useEffect(() => {
		async function carregaMetadata() {
			setLoading(true);
			try {
				const url = `collections/metadata/${type}`;
				const retorno = (await accessApi(url))?.data;
				const meta = retorno || {};
				// const fields = get(retorno, '0.fieldsets', get(retorno, '0.fields', {}));
				const flds = meta.fieldsets || meta.fields || {};
				let fsets = Array.isArray(flds) ? flds : [{ fields: flds }];

				if (showDam) {
					setFormFields(fsets);
				} else {
					fsets = fsets.map(fset => {
						let novosfields = Object.entries(fset.fields).reduce((p, c) => {
							const [k, v] = c;
							if (['numeroDamField', 'numeroDam'].includes(v?.type)) return p;
							if ('valorDam' === k || v.usage === 'dam_value') return p;
							return Object.assign(p, { [k]: v });
						}, {});
						return { ...fset, fields: novosfields };
					});
					setFormFields(fsets);
				}
			} catch (e) {
				setFormFields([]);
				console.error(`Erro no acesso aos metadados da ${name}: `, e);
			} finally {
				setLoading(false);
			}
		}
		carregaMetadata();
	}, [name, showDam, type]);

	useEffect(() => {
		if (propsData) {
			setData(propsData);
		}
	}, [propsData]);

	const tipoEvento = useMemo(() => data.tipoEvento, [data.tipoEvento]);
	const areaOcupadaPublica = useMemo(() => data.areaOcupadaPublica, [data.areaOcupadaPublica]);
	const viaPublicaParquePraca = useMemo(
		() => ['via', 'parque'].reduce((acc, current) => acc || (areaOcupadaPublica || []).includes(current), false),
		[areaOcupadaPublica]
	);
	const localEvento = useMemo(() => data.localEvento, [data.localEvento]);
	const datasEvento = useMemo(() => data.datasEvento, [data.datasEvento]);

	useEffect(() => {
		if (tipoEvento !== 'feiras' || !viaPublicaParquePraca) {
			feirasDeRuaEmViasPublicasOuParques.current = 0; // remove qtd de eventos desses tipos
			return;
		}

		const codLogradouro = localEvento?.codLogradouro;
		let numero = localEvento?.numero;

		const dataInicioEvento = datasEvento?.[0].datas?.[0];
		const dataFimEvento = datasEvento?.[0].datas?.[1];

		let dias = null;

		if ((areaOcupadaPublica || []).includes('via')) {
			dias = 14 - 1; // se evento num domingo, pode ter novo evento no segundo sábado subsequente
		} else if ((areaOcupadaPublica || []).includes('parque')) {
			dias = 6 - 1; // se evento num domingo, pode ter novo evento no sábado subsequente
		}

		if (dias && codLogradouro && numero && dataInicioEvento && dataFimEvento) {
			// ler requerimentos que já tenham sido gerados no SEI (não são mais rascunhos)
			// e que tenham "numero" num intervalo de numeração cadastrado pela CEVEN para o "codLogradouro"
			// e que ocorram desde 15 dias antes de dataInicial até 15 dias depois de dataFinal

			// if (
			// 	['Prq.', 'Pca.'].reduce(
			// 		(acc, inicio) => acc || (localEvento?.enderecoFormatadoCurto || '').startsWith(inicio),
			// 		false
			// 	)
			// ) {
			// numero = '0';
			// }
			// inicialmente iria desconsiderar a numeração apenas para parques ou praças, mas
			// decidiram desconsiderar para qualquer logradouro.
			numero = '0';

			const url =
				'/api/verifica-evento-feira-rua/' +
				`?codLogradouro=${codLogradouro}` +
				`&numero=${numero}` +
				`&dataInicial=${dataInicioEvento}&dataFinal=${dataFimEvento}` +
				`&dias=${dias}`;

			if (Object.hasOwnProperty.call(cache, url)) {
				feirasDeRuaEmViasPublicasOuParques.current = cache[url];
				return;
			}

			if (isDebug) {
				console.debug(`enviando ${url}`);
			}

			accessApi(url, true).then(retorno => {
				// console.log('retorno: ', retorno.data, errorList?.localEvento);
				const qtd = retorno?.data?.eventos || 0;
				if (qtd === 0 && errorList?.localEvento?.find(erro => erro === ERRO_EVENTO_RUA)) {
					setErrorList(old => {
						let saida = { ...(old || {}) };
						saida.localEvento = saida.localEvento.filter(e => e !== ERRO_EVENTO_RUA);
						if (size(saida.localEvento) === 0) {
							saida = omit(saida, ['localEvento']);
						}
						return saida;
					});
				}
				feirasDeRuaEmViasPublicasOuParques.current = qtd;
				setCache(old => ({ ...old, [url]: qtd }));
			});
		}
	}, [viaPublicaParquePraca, errorList, localEvento, datasEvento, areaOcupadaPublica, tipoEvento, cache]);

	const validarEmissaoNaoAutomatica = (type, obj, key, value) => {
		let keyAux = obj ? get(obj, 'key') : key;
		let valueAux = obj ? get(obj, 'value') : value;
		if (
			[TIPO_FORMULARIO_ETR_NA_HORA, TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO].includes(type) &&
			(([
				'instalacaoVegetacao',
				'instalacaoImovelTombado',
				'instalacaoEdificacaoSuporte',
				'instalacaoAreaPublicaMunicipal',
				'instalacaoAreaPreservacao'
			].includes(keyAux) &&
				valueAux === 'sim') ||
				(['instalacaoInfraestruturaSuporte'].includes(keyAux) && valueAux === 'nao'))
		) {
			return {
				texto:
					'Você não poderá emitir a Licença ETR de forma automática. Dessa forma, a documentação anexada ao processo deverá ser analisada pelo revisor.',
				tipo: 'alerta'
			};
		}
		if (
			[TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA].includes(type) &&
			['leitoViaPublica', 'patrimonioCultural', 'imovelPublico', 'intervencaoVegetacao'].includes(keyAux) &&
			valueAux === 'sim'
		) {
			return {
				texto:
					'Você não poderá emitir a Licença Toldos de forma automática. Dessa forma, a documentação anexada ao processo deverá ser analisada pelo revisor.',
				tipo: 'alerta'
			};
		}
		return null;
	};

	// Precisa ser mantido fora do método changeHandler. Ver observação interna
	let dataAlterado = { ...data };
	const changeHandler = obj => {
		// let dataAlterado = { ...data };
		// Este let precisou ser colocado fora do método por que este
		// callback é utilizado mais de uma vez na mesma renderização, e esta
		// é a única forma de garantir que o valor de data esteja atualizado.
		// O método é recriado toda vez que a tela é renderizada. Mas o valor de
		// data, que é usado para criar dataAlterado, está desatualizado depois da
		// primeira execução.
		// Como a cada execução ele parte do valor que data tinha no momento
		// da primeira execução, ele não consegue pegar o valor atualizado para
		// as demais execuções, e como atualizamos todo o data (setData) a cada
		// execução, os valores das execuções anteriores são sobrescritos com o valor
		// inicial do data... muito complicado... :face_with_diagonal_mouth:

		// Objeto para tipo de Processo de ETR || Licenca Toldos
		let objEtrNaHora = null;

		if (Array.isArray(obj)) {
			obj.forEach(o => {
				if (get(o, 'key') === 'expediente') {
					setCodigoExpedienteAtual(get(o, 'value.codigo'));
				}
			});
		}

		// Se tipo de Processo for Habite-se do BPM
		if (type === TIPO_FORMULARIO_HABITE_SE) {
			let textoAvisoExpedienteSelecionado = null;

			if (Array.isArray(obj)) {
				obj.forEach(o => {
					if (get(o, 'key') === 'expediente') {
						if (size(get(o, 'value.projetosEdificacao')) === 0 && size(get(o, 'value.licencas')) === 0) {
							textoAvisoExpedienteSelecionado = {
								texto: `Não foi identificado projeto/licença para o EU ${formatCodigoEU(
									get(o, 'value.codigo')
								)}, selecionado no campo.`,
								tipo: 'alerta'
							};
						} else {
							textoAvisoExpedienteSelecionado = {
								texto: `Foi identificado projeto/licença para o EU ${formatCodigoEU(
									get(o, 'value.codigo')
								)}, selecionado no campo.`,
								tipo: 'aviso-sucesso'
							};
						}
					}
				});
			}

			textoAvisoExpedienteSelecionado = undefined;
			setTextoAvisoAutomatica(textoAvisoExpedienteSelecionado);
		} else if (type === TIPO_FORMULARIO_ETR_NA_HORA) {
			// Se tipo de Processo for ETR na Hora
			objEtrNaHora = { naHora: data?.naHora };
			let textoAvisoAux = null;
			if (!Array.isArray(obj)) {
				textoAvisoAux = validarEmissaoNaoAutomatica(type, obj);
				objEtrNaHora = { naHora: textoAvisoAux ? false : true };
				Object.keys(dataAlterado).forEach(prop => {
					textoAvisoAux =
						get(obj, 'key') !== prop && !textoAvisoAux
							? validarEmissaoNaoAutomatica(type, null, prop, dataAlterado[prop])
							: textoAvisoAux;
					objEtrNaHora = { naHora: textoAvisoAux ? false : true };
				});
				if (get(obj, 'key') === 'instalacaoAreaEscriturada') {
					setTextoAvisoAreaNaoEscriturada(validarAreaNaoEscriturada(dataAlterado, type, obj));
					const erro2Anos = validarExpedienteJaTemEtr2Anos(dataAlterado, type, obj);
					if (erro2Anos) {
						setErrorList({ instalacaoAreaEscriturada: [erro2Anos] });
					} else if (errorList?.instalacaoAreaEscriturada) {
						setErrorList(old => omit(old, ['instalacaoAreaEscriturada']));
					}
				}
				setTextoAvisoAutomatica(textoAvisoAux);
			}
		} else if (type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
			// Se tipo de Processo for Renovação de ETR na Hora
			if (get(obj, 'key') === 'instalacaoAreaEscriturada') {
				setTextoAvisoAreaNaoEscriturada(validarAreaNaoEscriturada(dataAlterado, type, obj));
				const erro2Anos = validarExpedienteJaTemEtr2Anos(dataAlterado, type, obj);
				if (erro2Anos) {
					setErrorList({ instalacaoAreaEscriturada: [erro2Anos] });
				} else if (errorList?.instalacaoAreaEscriturada) {
					setErrorList(old => omit(old, ['instalacaoAreaEscriturada']));
				}
			}
			// Para renovação, sempre é na hora (automática)
			objEtrNaHora = { naHora: true };
		} else if (type === TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA) {
			// Se tipo de Processo for ETR na Hora
			objEtrNaHora = { naHora: data?.naHora };
			let textoAvisoAux = null;
			if (!Array.isArray(obj)) {
				textoAvisoAux = validarEmissaoNaoAutomatica(type, obj);
				objEtrNaHora = { naHora: textoAvisoAux ? false : true };
				Object.keys(dataAlterado).forEach(prop => {
					textoAvisoAux =
						get(obj, 'key') !== prop && !textoAvisoAux
							? validarEmissaoNaoAutomatica(type, null, prop, dataAlterado[prop])
							: textoAvisoAux;
					objEtrNaHora = { naHora: textoAvisoAux ? false : true };
				});
				if (get(obj, 'key') === 'instalacaoAreaEscriturada') {
					setTextoAvisoAreaNaoEscriturada(validarAreaNaoEscriturada(dataAlterado, type, obj));
					const erro2Anos = validarExpedienteJaTemEtr2Anos(dataAlterado, type, obj);
					if (erro2Anos) {
						setErrorList({ instalacaoAreaEscriturada: [erro2Anos] });
					} else if (errorList?.instalacaoAreaEscriturada) {
						setErrorList(old => omit(old, ['instalacaoAreaEscriturada']));
					}
				}
				setTextoAvisoAutomatica(textoAvisoAux);
			}
		}

		if (size(objEtrNaHora) > 0) {
			dataAlterado = Object.assign(dataAlterado, objEtrNaHora);
		}

		if (Array.isArray(obj)) {
			let modified = false;
			obj.forEach(o => {
				if (!isEqual(dataAlterado[o.key], o.value)) {
					modified = true;
					dataAlterado[o.key] = o.value;
					const errorListFn = old => omit(old || {}, [o.key]);
					setErrorList(errorListFn);
				}
			});
			if (modified) {
				setData(dataAlterado);
				onChange(dataAlterado);
			}
		} else {
			// recebe como parametro um objeto assim: { fieldSetName, key, value };
			const { key, value } = obj;
			const errorListFn = old => omit(old || {}, [key]);
			setErrorList(errorListFn);
			dataAlterado[key] = value;
			setData(dataAlterado);
			onChange(dataAlterado);
		}
	};

	const submitHandler = result => {
		const [ok, errorList, customErrors] = result;
		let precisaRevisar =
			(type === TIPO_FORMULARIO_ETR_NA_HORA &&
				[
					'instalacaoAreaEscriturada',
					'instalacaoAreaPreservacao',
					'instalacaoAreaPublicaMunicipal',
					'instalacaoEdificacaoSuporte',
					'instalacaoImovelTombado',
					'instalacaoInfraestruturaSuporte',
					'instalacaoVegetacao'
				].reduce(
					(acc, current) =>
						(acc += data[current] && validarEmissaoNaoAutomatica(type, null, current, data[current]) ? 1 : 0),
					0
				) > 0) ||
			(type === TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA &&
				['leitoViaPublica', 'patrimonioCultural', 'imovelPublico', 'intervencaoVegetacao'].reduce(
					(acc, current) =>
						(acc += data[current] && validarEmissaoNaoAutomatica(type, null, current, data[current]) ? 1 : 0),
					0
				) > 0);

		// Se for EU não migrado ou precisa revisar, seta como false a propriedade de licença automática
		if (
			(!isNil(get(data, 'naHora')) && !isNil(get(data, 'naHoraEUNaoMigrado')) && !get(data, 'naHoraEUNaoMigrado')) ||
			precisaRevisar
		) {
			data.naHora = false;
		}

		if (readOnlyProps) {
			setErrorList(null);
			onSubmit({ ...data, sendReadOnly: true });
		} else if (ok) {
			setErrorList(null);
			onSubmit(data);
		} else {
			setErrorList(size(customErrors) > 0 ? customErrors : errorList);
		}
	};

	const getCustomValidates = useCallback(() => {
		if (type === TIPO_FORMULARIO_HABITE_SE) {
			return fields => {
				const nok = false;
				const value = get(fields, ['obraExecutadaConformeAprovado', 'value']);
				if (value === 'nao') {
					return [
						nok,
						[
							'Deve ser solicitado modificação de projeto arquitetônico conforme o Decreto Municipal nº 18.623/2014 ou ajustes conforme o Decreto Municipal nº 20.782/2020'
						],
						'obraExecutadaConformeAprovado'
					];
				}
				return undefined;
			};
		} else if (type === TIPO_FORMULARIO_ETR_NA_HORA || type === TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO) {
			return fields => {
				const nok = false;
				let value = get(fields, ['valorDam', 'value']);
				value = get(value, 'value');
				if (parseFloat(value) < 3000) {
					return [nok, ['Deve ser paga a taxa DAM correspondente a 800 UFMs'], 'valorDam'];
				}
				return undefined;
			};
		} else if (type === TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM) {
			// feiras de rua
			return fields => {
				let validateds = [];

				const tipoLogradouro = get(fields, ['localEvento', 'value', 'nomeLogradouro'], '').split(' ')[0];
				const areaOcupadaPublica = get(fields, 'areaOcupadaPublica.value', []);
				if (areaOcupadaPublica.includes('parque')) {
					if (!['Prq.', 'Pca.'].includes(tipoLogradouro)) {
						validateds.push([false, [ERRO_TIPO_LOGRADOURO], 'localEvento']);
					}
				} else {
					if (['Prq.', 'Pca.'].includes(tipoLogradouro)) {
						validateds.push([false, [ERRO_AREA_OCUPACAO_PUBLICA], 'areaOcupadaPublica']);
					}
				}

				// validado num usEffect por ser assíncrono (acessa uma API para verificar se já existe evento no local e data)
				if (feirasDeRuaEmViasPublicasOuParques.current > 0) {
					validateds.push([false, [ERRO_EVENTO_RUA], 'localEvento']);
				}

				if (size(validateds) > 0) {
					return validateds;
				}

				return undefined;
			};
		} else if (type === TIPO_FORMULARIO_ADESAO_PRCHPA) {
			return fields => {
				const { enderecoCdlList } = fields;
				let validateds = [];

				if (!((enderecoCdlList || {}).value || []).find(e => e.regiao === 'centro_historico')) {
					validateds.push([
						false,
						['Nenhum dos endereços informados está localizado no Centro Histórico.'],
						'enderecoCdlList'
					]);
				}

				if (size(validateds) > 0) {
					return validateds;
				}

				return undefined;
			};
		}
	}, [type]);

	const readOnly = readOnlyProps === true;
	const disable = readOnlyProps === true;

	const disabledCA =
		type === TIPO_FORMULARIO_CERTIDAO_AVERBACAO
			? data?.constr === 'sim' && data?.pavimento === 'sim' && data?.anos === 'sim' && data?.baixaRenda === 'sim'
				? false
				: true
			: false;

	const disabled4D =
		type === TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO
			? data?.beneficioQuartoDistrito === 'sim' ||
			  data?.regimeEspecialQuartoDistrito === 'sim' ||
			  data?.analiseUPEQuartoDistrito === 'nao'
				? true
				: false
			: false;

	return (
		<>
			{loading ? (
				<Loader msg={`Carregando metadados da ${name}`} />
			) : (
				<>
					<TemplateForm
						fieldsets={formFields}
						onChangeHandler={changeHandler}
						onSubmit={submitHandler}
						validateForm={getCustomValidates()}
						formData={data}
						readOnly={readOnly}
						disabled={disable}
						disabled4D={disabled4D}
						disabledCA={disabledCA}
						submitLabel={showNext ? 'Avançar' : 'Salvar'}
						usuarioInterno={!!usuarioInterno}
						consultaPublicaExpedientes={consultaPublicaExpedientes}
						obterDadosProjetosLicencasExpediente={type === TIPO_FORMULARIO_HABITE_SE}
						customErrors={errorList}
						type={type}
					/>
					<ShowDebug data={{ data, errorList }} readOnly={readOnly} />
					{size(textoAvisoAutomatica) > 0 && (
						<div className="form-group form-docs">
							<div className={textoAvisoAutomatica.tipo === 'aviso-sucesso' ? 'col alert info' : 'col alert warning'}>
								<p>* {textoAvisoAutomatica.texto}</p>
							</div>
						</div>
					)}
					{size(textoAvisoAreaNaoEscriturada) > 0 && (
						<div className="form-group form-docs">
							<div
								className={
									textoAvisoAreaNaoEscriturada.tipo === 'aviso-sucesso' ? 'col alert info' : 'col alert warning'
								}
							>
								<p>* {textoAvisoAreaNaoEscriturada.texto}</p>
							</div>
						</div>
					)}
					{/* para ETR na Hora */}
					{/* {size(textoAvisoAutomatica) === 0 && !isNil(get(data, 'naHora')) && !get(data, 'naHora') && (
						<div className="form-group form-docs">
							<div className={'col alert warning'}>
								<p>* {textoAvisoAutomatica}</p>
							</div>
						</div>
					)} */}
					{!isLoadingVerificacaoMigracaoEU && size(textoAvisoEUNaoMigrado) > 0 && (
						<div className="form-group form-docs">
							<div className={textoAvisoEUNaoMigrado.tipo === 'aviso-sucesso' ? 'col alert info' : 'col alert warning'}>
								<p>* {textoAvisoEUNaoMigrado.texto}</p>
							</div>
						</div>
					)}
				</>
			)}
			{usuario && (
				<div className="col-md-12">
					<div className="col-md-12">
						<h3>Usuário Criador</h3>
						<input
							className="form-control"
							type="text"
							disabled
							value={`${get(usuario, 'name')} (${get(usuario, 'email')})`}
						/>
					</div>
				</div>
			)}
			{debug && size(data) > 0 && <pre id="pre-data">{JSON.stringify(data, null, 2)}</pre>}
		</>
	);
}

MetadataForm.displayName = 'MetadataForm';
MetadataForm.propTypes = {
	data: PropTypes.object,
	usuario: PropTypes.object,
	readOnly: PropTypes.bool,
	type: PropTypes.string,
	showNext: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	consultaPublicaExpedientes: PropTypes.bool,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func
};

export default MetadataForm;
