import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import useAxios from 'axios-hooks';
import { size, get, pick, sortBy } from 'lodash';
import uuid from 'uuid/v4';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO } from 'containers/Form/Detalhe';

import useErrors from 'custom-hooks/useErrors';
import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { EXPEDIENTES_API_URL } from 'environments';

import { sendApi } from 'utils/injectApi';
import { calculaNumeroLinhas, getValueCutDigits } from 'utils/tools';

import { AreaSubfield } from './AreaSubfield';
import { ExpedienteSubfield } from './ExpedienteSubfield';
import { SelecaoSubfield } from './SelecaoSubfield';

export function CertidaoDemolicaoDocEspecifico({ setShowFormulario, setShowConfirm }) {
	const dispatch = useDispatch();

	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errors, setErrors] = useErrors();
	const { createMessage } = useMessages();

	/* ESTADOS */
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [tiposConstrucao, setTiposConstrucao] = useState([]);
	const [allowForceSave, setAllowForceSave] = useState(false);

	const [{ data: tiposconstrucao }] = useAxios(`${EXPEDIENTES_API_URL}/cadastros/tipoconstrucao`);

	const enquadramento = useMemo(() => formulario?.formData?.data?.enquadramento?.definicao, [formulario]);
	const isDemolicaoTotal = enquadramento === 'total';

	// #region SIDE EFFECTS
	useEffect(() => {
		const previousData = obtemCertidaoDemolicaoDados(formulario);
		setData(previousData?.dados || { tiposMaterial: [{ id: uuid() }] });
		// setTipoForm(previousData?.dados?.tipoForm);
	}, [formulario]);

	useEffect(() => {
		if (tiposconstrucao) {
			let tipos = tiposconstrucao.map(tp => pick(tp, ['_id', 'codigo', 'descricao']));
			tipos = sortBy(tipos, 'descricao');
			tipos.push({ _id: '000', codigo: 'outro', descricao: 'Informar outro' });
			setTiposConstrucao(tipos);
		}
	}, [tiposconstrucao]);
	// #endregion

	// #region HANDLERS
	const obtemTotaisAtualizados = useCallback(tiposMaterial => {
		let areaDemolicaoRegularTotal = 0;
		let areaPermanecerTotal = 0;
		tiposMaterial.forEach(tm => {
			if (tm.areaDemolicaoRegular) {
				areaDemolicaoRegularTotal += getValueCutDigits(parseFloat(tm.areaDemolicaoRegular?.value || 0), 2);
				areaPermanecerTotal += getValueCutDigits(parseFloat(tm.areaPermanecerRegular?.value || 0), 2);
			}
		});
		areaDemolicaoRegularTotal = areaDemolicaoRegularTotal.toFixed(2).replace(/\./, ',');
		areaPermanecerTotal = areaPermanecerTotal.toFixed(2).replace(/\./, ',');
		return [
			{ name: 'areaDemolicaoRegularTotal', value: areaDemolicaoRegularTotal },
			{ name: 'areaPermanecerTotal', value: areaPermanecerTotal }
		];
	}, []);

	const onChangeHandler = useCallback(
		e => {
			const { name, value } = e.target || e;
			if (data?.[name] !== value) {
				let changes = { [name]: value };
				if (name === 'tiposMaterial') {
					const totais = obtemTotaisAtualizados(value);
					totais.forEach(tots => {
						Object.assign(changes, { [tots.name]: tots.value });
						setData(old => ({ ...old, ...changes }));
					});
				} else {
					setData(old => ({ ...old, ...changes }));
				}
				setAllowForceSave(false);
			}
		},
		[data, obtemTotaisAtualizados]
	);

	const onChangeTipoMaterialHandler = useCallback(
		(dataAux, property, value, i) => {
			const tiposMaterialAux = [...dataAux.tiposMaterial];
			tiposMaterialAux[i][property] = value;
			onChangeHandler({ name: 'tiposMaterial', value: tiposMaterialAux });
		},
		[onChangeHandler]
	);

	const onRemoveTipoMaterial = useCallback(
		(dataAux, id) => {
			const index = (dataAux.tiposMaterial || []).findIndex(tm => tm.id === id);
			if (index > -1) {
				const tiposMaterialAux = (dataAux.tiposMaterial || []).filter(tm => tm.id !== id);
				onChangeHandler({ name: 'tiposMaterial', value: tiposMaterialAux });
			}
		},
		[onChangeHandler]
	);

	useEffect(() => {
		if (size(errors) > 0) {
			setErrors(validate(data));
		}
	}, [data, errors, setErrors]);

	const submit = async operacao => {
		const { draft, forceSave, gerarPdf } = operacao;

		const errors = validate(data, enquadramento);

		// forceSave é true quando form já tem erros de validação
		// adicionado o teste de draft para desabilitar a possibilidade de gerar pdf com errors
		if (draft && forceSave) {
			salvar({ draft, errors });
			setAllowForceSave(false);
		} else {
			if (size(errors) > 0) {
				setErrors(errors);
				setAllowForceSave(true);
			} else {
				salvar({ draft, gerarPdf });
				setAllowForceSave(false);
			}
		}
	};

	const salvar = async params => {
		const { draft, errors, gerarPdf } = params;
		try {
			setLoading(true);
			const fdId = get(formulario, 'formData.id');
			const url = `processo/${fdId}/${EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO}${draft ? '?draft=true' : ''}`;
			const newData = { ...data };

			if (gerarPdf) {
				Object.keys(newData).forEach(prop => {
					if (newData[`${prop}AlteradaRevisor`]) {
						delete newData[`${prop}AlteradaRevisor`];
					}
				});
			}

			const objetoEspecifico = preparaObjetoParaTemplatePdf({
				data
			});

			const { data: dataResponse } = await sendApi(
				{ url, payload: { form: newData, observacoes: errors, objetoEspecifico } },
				false
			);
			dispatch(
				actions.alteraStatusDocumento({
					documento: dataResponse.documento,
					documentoDados: dataResponse.documentoDados
				})
			);
			createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 2);
		} catch (error) {
			createMessage('Problemas no salvamento do documento... verifique a console', 5);
			console.error('erro no servidor', error);
		} finally {
			setLoading(false);
			setShowFormulario(false);
		}
	};
	// #endregion

	// #region AUXILIARES PARA RENDER
	const hasErros = size(errors) > 0;
	// #endregion

	/* RENDER */
	return (
		<div className="doc-certidao-demolicao">
			{loading && <Loader />}
			<ExpedienteSubfield formulario={formulario} />

			<fieldset>
				<div className="titulo-certidao-demolicao">{`Dados certidão de demolição ${enquadramento}`}</div>
				<p className="observacoes">
					<b>Área construída regular:</b> área com Carta de Habitação.
				</p>
				<p className="observacoes">
					<b>Áreas construídas existentes:</b> áreas comprovadamente existentes há mais de 20 (vinte) anos, pelos
					registros dos cadastros do Município, nos termos do Art. 159-B da L.C. 434/99, atualizada e compilada até a
					L.C. 667/11, incluindo a L.C. 646/10.
				</p>
				<div className="row">
					<div className="col-md-6">
						<AreaSubfield
							fieldName="areaDemolicaoRegularTotal"
							label="Área regular total (m²)"
							value={{ value: data.areaDemolicaoRegularTotal }}
							errors={errors?.areaDemolicaoRegularTotal}
							readOnly={true}
							disabled={true}
						/>
					</div>
					<div className="col-md-6">
						<AreaSubfield
							fieldName="areaPermanecerTotal"
							label="Área total a permanecer (m²)"
							value={{ value: data.areaPermanecerTotal }}
							errors={errors?.areaPermanecerTotal}
							readOnly={true}
							disabled={true}
						/>
					</div>
				</div>
				{(data.tiposMaterial || []).map((tm, i) => {
					const tam = size(data.tiposMaterial);
					return (
						<div
							className="row"
							style={{
								borderTop: i > 0 ? '1px solid #dfe2e6' : '0',
								borderBottom: tam > 1 && i + 1 === tam ? '1px solid #dfe2e6' : '0'
							}}
							key={i}
						>
							<div className="col-md-5">
								<SelecaoSubfield
									label="Tipo de material"
									fieldName="tipoConstrucao"
									placeholder="tipo de material"
									value={tm?.tipoConstrucao}
									searchList={tiposConstrucao}
									errors={errors?.[`tipoConstrucao${i}`]}
									multiple={false}
									onChangeHandler={({ name, value }) => onChangeTipoMaterialHandler(data, name, value, i)}
									loading={size(tiposConstrucao) === 0}
								/>
							</div>
							<div className="col-md-3">
								<AreaSubfield
									fieldName="areaDemolicaoRegular"
									label="Área a demolir regular (m²)"
									onChangeHandler={array => {
										const { name, value } = array.find(a => a.name !== 'errors') || {};
										if (name) {
											onChangeTipoMaterialHandler(data, name, value, i);
										}
									}}
									value={tm?.areaDemolicaoRegular}
									errors={errors?.[`areaDemolicaoRegular${i}`]}
									required={false}
								/>
							</div>
							<div className="col-md-3">
								<AreaSubfield
									fieldName="areaPermanecerRegular"
									label="Área a permanecer regular (m²)"
									onChangeHandler={array => {
										const { name, value } = array.find(a => a.name !== 'errors') || {};
										if (name) {
											onChangeTipoMaterialHandler(data, name, value, i);
										}
									}}
									value={tm?.areaPermanecerRegular}
									errors={errors?.[`areaPermanecerRegular${i}`]}
									required={false}
									readOnly={isDemolicaoTotal}
									tooltip="Não há área a permanecer para certidão de demolição total"
								/>
							</div>
							<div className="col-md-1" style={{ top: '38px' }}>
								{size(data.tiposMaterial) === 1 ? (
									<button type="button" className="btn p-2" disabled>
										<i className="fa fa-trash" style={{ color: '#7d7f80', fontSize: '20px' }} />
									</button>
								) : (
									<button type="button" className="btn p-2" onClick={() => onRemoveTipoMaterial(data, tm.id)}>
										<i className="fa fa-trash" style={{ color: '#d12f2f', fontSize: '20px' }} />
									</button>
								)}
							</div>
						</div>
					);
				})}

				<div className="cjto-botoes">
					<button
						type="button"
						className="btn btn-primary mr-1 mb-3"
						onClick={() => {
							setAllowForceSave(false);
							setData(old => ({ ...old, tiposMaterial: [...(old?.tiposMaterial || []), { id: uuid() }] }));
						}}
					>
						Adicionar tipo de material
					</button>
				</div>

				<div className="col-md-12">
					<label style={{ color: '#7d7f80', fontSize: '14px' }}>Observações </label>
					<textarea
						style={{ marginBottom: '10px', marginLeft: '-5px' }}
						className="form-control"
						type="text"
						placeholder="Observações"
						value={data?.observacoes || ''}
						name="observacoes"
						onChange={onChangeHandler}
						rows={calculaNumeroLinhas(data?.observacoes)}
						readOnly={false}
						disabled={false}
					></textarea>
					{size(errors?.observacoes) > 0 && <ErrorMessages errorList={errors.observacoes} />}
				</div>
			</fieldset>

			<div className="cjto-botoes">
				<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowConfirm(true)}>
					Cancelar
				</button>
				<button
					type="button"
					className="btn btn-secondary mt-3 mr-2"
					onClick={() => submit({ draft: true, forceSave: allowForceSave })}
				>
					{allowForceSave ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
				</button>

				<button
					type="button"
					className="btn btn-primary mt-3"
					onClick={() => submit({ draft: false, forceSave: allowForceSave, gerarPdf: true })}
				>
					Salvar e gerar PDF
				</button>
			</div>
			{hasErros && (
				<div className="alert alert-danger mt-5">
					<p style={{ marginBottom: '0' }}>Existem erros no formulário, verifique acima.</p>
				</div>
			)}
			<ShowDebug data={{ data, objetoEspecifico: preparaObjetoParaTemplatePdf({ data }), errors }} />
		</div>
	);
}
CertidaoDemolicaoDocEspecifico.displayName = 'CertidaoDemolicaoDocEspecifico';
CertidaoDemolicaoDocEspecifico.propTypes = {
	setShowFormulario: PropTypes.func,
	setShowConfirm: PropTypes.func,
	readOnly: PropTypes.bool,
	forAdmin: PropTypes.bool
};
export default CertidaoDemolicaoDocEspecifico;

function validate(data, enquadramento) {
	let errors = {};
	let permanecerRegularExiste = false;
	const isDemolicaoTotal = enquadramento === 'total';
	if (isDemolicaoTotal) return errors;

	data.tiposMaterial.forEach((tm, i) => {
		if (size(tm.tipoConstrucao) === 0) {
			errors[`tipoConstrucao${i}`] = ['O campo "Tipo de Material" é obrigatório'];
		}
		if (!tm.areaDemolicaoRegular || tm.areaDemolicaoRegular.value === 0 || tm.areaDemolicaoRegular.value === '0.00') {
			errors[`areaDemolicaoRegular${i}`] = ['O campo "Área demolição regular" é obrigatório'];
		}
		if (
			!permanecerRegularExiste &&
			(!tm.areaPermanecerRegular || tm.areaPermanecerRegular?.value === 0 || tm.areaPermanecerRegular?.value === '0.00')
		) {
			errors[`areaPermanecerRegular${i}`] = ['Pelo menos um campo "Área a permanecer regular" é obrigatório'];
		} else {
			permanecerRegularExiste = true;
		}
	});
	if (permanecerRegularExiste)
		data.tiposMaterial.forEach((e, i) => {
			delete errors[`areaPermanecerRegular${i}`];
		});
	return errors;
}

/**
 * obtem os dados preenchidos do doc específico de certidão de demolição
 * @param {*} formulario
 * @returns
 */
export function obtemCertidaoDemolicaoDados(formulario) {
	const { documentos, documentosDados } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento?.original);
	return docDados || {};
}

/**
 * Este método parte do estado inicial do objeto de metadata e adiciona respostas e documentos às perguntas
 * além de adicionar certificação e selo almejados pelo solicitante. Este objeto é enviado ao backend para
 * criação do PDF que será anexado como documento específico a este requerimento
 */
function preparaObjetoParaTemplatePdf({ data }) {
	let saida = { ...(data || {}) };

	saida.areaDemolicaoRegularTotal = formatArea(saida.areaDemolicaoRegularTotal);
	saida.tiposMaterial = (saida.tiposMaterial || []).map(tm => {
		const tc = tm.tipoConstrucao ? tm.tipoConstrucao[Object.keys(tm.tipoConstrucao)[0]] : {};
		return {
			id: tm.id,
			tipoConstrucao: tc?.descricao || null,
			areaDemolicaoRegular: formatArea(tm?.areaDemolicaoRegular?.value),
			areaPermanecerRegular: tm.areaPermanecerRegular ? formatArea(tm?.areaPermanecerRegular?.value) : null
		};
	});

	return saida;
}

function formatArea(string) {
	string = string || '0';
	string = string.replace(/,/g, '.');
	let numberValue = parseFloat(string);

	const formattedNumber = new Intl.NumberFormat('pt-BR', {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		useGrouping: 'always'
	}).format(numberValue);

	return formattedNumber;
}
