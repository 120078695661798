import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import useAxios from 'axios-hooks';

import actions from 'containers/Form/actions';

import useMutableState from './useMutableState';

/**
 * Executa a query especificada na URL e armazena na store sob a chave [property]
 * Opcionalmente fornece uma URL nula e posteriormente seta a URL com o método changeUrl
 *    - útil quando não temos todas as partes da URL na entrada da tela
 * @param {String} url to query the list from
 * @param {String} property key to use to save in store
 * @param {String} idProperty default 'id'
 * @param {string[]} path default '[licenciamento]'
 * @returns {Any} mutable state got from store
 */
const useQueryStore = (url, property, idProperty = 'id', path = ['licenciamento']) => {
	/* REDUX HOOKS */
	const dispatch = useDispatch();
	// isso realmente nao deveria estar aqui mas eh o local mais
	// comum entre todos os componentes
	// mapper das atividades para mudar o nome de habitacao para habitacao unifamiliar
	const mapAtividades = useCallback(
		lista => {
			if (url && url.endsWith('anexo52')) {
				return (lista || []).map(i => {
					if (i && (i.item === '1.1.' || i.objectid === 2)) {
						return { ...i, atv: 'Habitação Unifamiliar' };
					}
					return i;
				});
			}
			return lista;
		},
		[url]
	);

	const [alternateUrl, changeUrl] = useState(null);

	const lista = useMutableState([...path, property], idProperty);

	useEffect(() => {
		if (alternateUrl) {
			dispatch(actions.salvaAnexoList(property, null));
		}
	}, [alternateUrl, dispatch, property]);

	// #region AXIOS HOOKS
	const [{ data: dadosConsultados, loading, error }, execute] = useAxios(alternateUrl || url, {
		manual: true,
		useCache: true
	});

	// se ainda não tem valor na store, roda a query
	useEffect(() => {
		// retirada a lista do teste, pra executar novamente caso altere a URL
		if (url || alternateUrl) {
			// debugLog('pesquisando em ', url, 'e guardando na store sob a chave', property, alternateUrl || url);
			execute();
		}
	}, [alternateUrl, execute, lista, url]);

	// se obteve dados na query, salva na store
	useEffect(() => {
		if (dadosConsultados) {
			// debugLog(`Recebendo dados da consulta para ${property} e salvando na store`, size(dadosConsultados));
			const dados = mapAtividades(dadosConsultados);
			dispatch(actions.salvaAnexoList(property, dados));
		}
	}, [dadosConsultados, dispatch, mapAtividades, property]);
	// #endregion

	return {
		data: lista,
		loading,
		error,
		changeUrl
	};
};
useQueryStore.displayName = 'useQueryStore';

useQueryStore.propTypes = {
	data: PropTypes.object,
	property: PropTypes.string,
	multiple: PropTypes.bool,
	label: PropTypes.string,
	url: PropTypes.string,
	tagged: PropTypes.bool,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	extraFilter: PropTypes.func
};

export default useQueryStore;
