import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { get, size, trim, isFinite } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';
import Loader from 'components/Loader';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { permiteInput, normalizedAddress, format, montaUrlExpedientes } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class Expediente extends React.Component {
	static displayName = 'Expediente';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	static onValidate = (data, errors) => {
		const { numeroExpediente, areaPrivativa, endereco } = data;
		let enderecos = [];
		if (numeroExpediente) {
			if (size(areaPrivativa) === 0) {
				errors.expediente.areaPrivativa.addError('Informando EU, informe a área privativa');
				// async validations are unavailable in this version of react-jsonschema-form
				// } else {
				// enderecos = await buscaExpediente(numeroExpediente, areaPrivativa);
				// if (size(enderecos) === 0) {
				// 	errors.expediente.numeroExpediente.addError('Expediente Único inválido');
				// }
			}
		}

		if (!endereco) {
			errors.expediente.endereco.addError('Endereço deve ser informado como no exemplo "Av. Ipiranga, 1200"');
		} else {
			if (enderecos.length) {
				const encontrou = enderecos.find(e => e.logradouro.enderecoFormatadoCurto === endereco);
				if (!encontrou) {
					errors.expediente.endereco.addError(`Endereço não bate com nenhum dos endereços do expediente.
					Clique no botão "usar este" abaixo do Número Expediente para selecionar o endereço adequado`);
				}
			} else {
				const partes = endereco.split(',').map(trim);
				if (partes.length !== 2) {
					errors.expediente.endereco.addError('Endereço deve ser informado como no exemplo "Av. Ipiranga, 1200"');
				} else {
					const [logradouro, numero] = partes;
					if (!/^[a-zA-Z]/.test(logradouro)) {
						errors.expediente.endereco.addError('Logradouro deve iniciar por letras');
					}
					if (!/^[0-9]*$/.test(numero)) {
						errors.expediente.endereco.addError('Número inválido');
					}
				}
			}
		}
		return errors;
	};

	timeout = null;

	constructor(props) {
		super(props);

		this.state = {
			formData: get(props, 'formData'),
			endereco: get(props, 'formData.endereco'),
			numeroExpediente: get(props, 'formData.numeroExpediente'),
			areaPrivativa: get(props, 'formData.areaPrivativa' || 0),
			className: CLASS_NAME_FORM_CONTROL,
			enderecos: get(props, 'formData.enderecos'),
			expedientes: null,
			query: '',
			showPopup: false
		};
	}

	componentDidMount() {
		if (!get(this.props, 'formData.areaPrivativa')) {
			this.setAreaPrivativa({ target: { value: '0' } });
		}
	}

	setEndereco = e => {
		let { value } = e.target;
		if ((value || '').split(',').length > 2) {
			return false;
		}
		this.setState({ endereco: value, query: value }, () =>
			this.props.onChange({ ...this.props.formData, endereco: this.state.endereco })
		);
	};

	setNumeroExpediente = e => {
		let { value } = e.target;
		value = (value || '').replace(/[^0-9]/g, '');
		value = (value || '').substring(0, 12);
		if (permiteInput(value, 'number', 0, 12)) {
			this.setState({ numeroExpediente: value }, () =>
				this.props.onChange({ ...this.props.formData, numeroExpediente: this.state.numeroExpediente })
			);
			if (size(value) === 12) {
				setTimeout(async () => {
					this.setState({ loading: true, enderecos: null });
					const enderecos = await buscaExpediente(value, this.state.areaPrivativa);
					this.setState({ enderecos, loading: false });
				}, 0);
			}
		}
	};

	setAreaPrivativa = e => {
		let { value } = e.target;
		value = (value || '').replace(/[^0-9]/g, '');
		value = (value || '').substring(0, 5);
		if (permiteInput(value, 'number', 0, 5)) {
			this.setState({ areaPrivativa: value }, () =>
				this.props.onChange({ ...this.props.formData, areaPrivativa: value })
			);
			if (value.length === 0) {
				return false;
			}
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			if (size(this.state.numeroExpediente) === 12) {
				this.timeout = setTimeout(async () => {
					this.setState({ loading: true, enderecos: null });
					const enderecos = await buscaExpediente(this.state.numeroExpediente, value);
					this.setState({ enderecos, loading: false });
				}, 500);
			}
		}
	};

	extractErrors = attr => get(attr, '__errors') || [];

	isValidExpediente = () => {
		const { numeroExpediente, areaPrivativa, enderecos, loading } = this.state;
		let valid = true;
		if (numeroExpediente) {
			if (!numeroExpediente.startsWith('002') || numeroExpediente.length !== 12) {
				valid = false;
			}
		}
		if (size(areaPrivativa) === 0) {
			valid = false;
		}
		if (numeroExpediente && !loading) {
			if (!enderecos || enderecos.length === 0) {
				valid = false;
			}
		}
		return valid;
	};

	setQuery = e => {
		const { value } = e.target;
		this.setState({ query: value });
	};

	pesquisarExpedientes = async () => {
		let { query } = this.state;
		const parts = (query || '').split(',').map(p => trim(p));
		const numero = parts.find(p => isFinite(parseInt(p, 10)));
		const q = parts.find(p => !isFinite(parseInt(p, 10)));

		if (size(q) > 3 && numero) {
			try {
				this.setState({ loading: true, expedientes: null });
				const response = await accessApi(
					`${EXPEDIENTES_API_URL}/consulta-expedientes-por-endereco?q=${normalizedAddress(q)}&numero=${numero}`,
					true
				);
				const expedientes = response.data;
				this.setState({ loading: false, expedientes });
			} catch (e) {
				this.setState({ loading: false });
				console.error('erro no servidor', e);
			}
		}
	};

	usarExpediente = expediente => {
		const newState = { numeroExpediente: expediente.numero, areaPrivativa: expediente.areaPrivativa };
		this.setState(newState, () => this.props.onChange(Object.assign(this.props.formData, newState)));
		setTimeout(async () => {
			this.setState({ loading: true, enderecos: null });
			const enderecos = await buscaExpediente(expediente.numero, expediente.areaPrivativa);
			this.setState({ enderecos, loading: false });
		}, 0);
		this.hidePopup();
	};

	usarEndereco = endereco => {
		this.setEndereco({ target: { value: endereco.logradouro.enderecoFormatadoCurto } });
		const newState = {
			query: endereco.logradouro.enderecoFormatadoCurto
		};
		this.setState(newState);
	};

	showPopup = () => {
		if (this.state.query) {
			this.pesquisarExpedientes();
		}
		this.setState({ showPopup: true });
	};
	hidePopup = () => this.setState({ showPopup: false });

	render() {
		const requireds = get(this.props, 'schema.required') || [];
		const requiredNumeroExpediente = requireds.indexOf('numeroExpediente') > -1;
		const requiredAreaPrivativa = requireds.indexOf('areaPrivativa') > -1;
		const requiredEndereco = requireds.indexOf('endereco') > -1;
		const {
			endereco: errorEndereco,
			numeroExpediente: errorNumeroExpediente,
			areaPrivativa: errorAreaPrivativa
		} = this.props.errorSchema || [];
		const { endereco, numeroExpediente, areaPrivativa, enderecos, expedientes, query, showPopup, loading } = this.state;
		const { readonly, disabled } = this.props;
		return loading ? (
			<Loader msg="Pesquisando Expediente Único" />
		) : (
			<Fragment>
				<div className={`form-group  col-md-${readonly || disabled ? 12 : 10}`}>
					<label className="control-label" htmlFor="root_nome">
						Endereço
						{requiredEndereco ? '*' : ''}
					</label>
					<div />
					<input
						className={
							errorEndereco || !isValidEndereco(this.state.endereco)
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						value={endereco || ''}
						onChange={this.setEndereco}
						placeholder="Informe o endereço principal da licença, como em Av. Julio de Castilhos, 505 "
						readOnly={readonly}
						disabled={disabled}
					/>
					<ErrorDetail attr={errorEndereco} />
					<div />
				</div>
				{!(readonly || disabled) && (
					<div className="col-md-2">
						{!showPopup && (
							<button type="button" className={'btn btn-link'} onClick={this.showPopup}>
								pesquisar expediente
							</button>
						)}
						{showPopup && (
							<button type="button" className={'btn btn-link'} onClick={this.hidePopup}>
								cancelar pesquisa
							</button>
						)}
					</div>
				)}
				<div className="form-group  col-md-6">
					<label className="control-label" htmlFor="root_nome">
						Número Expediente
						{requiredNumeroExpediente ? '*' : ''}
					</label>
					<div />
					<input
						className={
							errorNumeroExpediente || !this.isValidExpediente()
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						value={numeroExpediente || ''}
						onChange={this.setNumeroExpediente}
						placeholder="apenas números"
						readOnly={readonly}
						disabled={disabled}
					/>
					<ErrorDetail attr={errorNumeroExpediente} />
					<div />
				</div>
				<div className="form-group  col-md-6">
					<label className="control-label" htmlFor="root_nome">
						Área Privativa
						{requiredAreaPrivativa ? '*' : ''}
					</label>
					<div />
					<input
						className={
							errorAreaPrivativa || !this.isValidExpediente()
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						value={areaPrivativa || ''}
						onChange={this.setAreaPrivativa}
						placeholder="apenas números"
						readOnly={readonly}
						disabled={disabled}
					/>
					<ErrorDetail attr={errorAreaPrivativa} />
					<div />
				</div>
				{size(enderecos) > 0 && (
					<div className="form-group col-md-12">
						{enderecos
							.filter(e => !!e)
							.filter(e => e.tipo.value === 'Atual')
							.map((e, i) => (
								<div key={e.id}>
									{i + 1} - {e.logradouro.enderecoFormatadoCurto}{' '}
									<button type="button" className={'btn btn-link'} onClick={() => this.usarEndereco(e)}>
										usar este
									</button>
								</div>
							))}
					</div>
				)}
				{showPopup && (
					<Fragment>
						<div className="form-group  col-md-10">
							<label className="control-label" htmlFor="root_nome">
								Pesquisar Expediente Único por endereço
							</label>
							<div />
							<input
								className={CLASS_NAME_FORM_CONTROL}
								type="text"
								value={query || ''}
								onChange={this.setQuery}
								placeholder="Informe um endereço, como em Av. Julio de Castilhos, 505"
							/>
							<ErrorDetail attr={errorNumeroExpediente} />
							<div />
						</div>
						<div className="form-group  col-md-2">
							<button type="button" className={'btn btn-secondary'} onClick={this.pesquisarExpedientes}>
								Pesquisar
							</button>
						</div>
						{expedientes && size(expedientes) === 0 && (
							<div className="form-group col-md-12">Nenhum expediente com o endereço pesquisado</div>
						)}
						{size(expedientes) > 0 && (
							<div className="form-group col-md-12">
								{expedientes
									.filter(e => !!e)
									.map((e, i) => (
										<div key={e._id}>
											{i + 1} -{' '}
											<a href={montaUrlExpedientes(e._id)} target="_blank" rel="noopener noreferrer">
												{format(e.numero, '000.000000.00.0')} - {format(e.areaPrivativa, '00000')}
											</a>{' '}
											<button type="button" className={'btn btn-link'} onClick={() => this.usarExpediente(e)}>
												usar este
											</button>
											<ul>
												{e.enderecos
													// .filter(end => end.tipo.value === 'Atual')
													.map(end => (
														<li key={end.id}>
															{get(end, 'logradouro.enderecoFormatadoCurto')} ({end.tipo.value})
														</li>
													))}
											</ul>
										</div>
									))}
							</div>
						)}
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default Expediente;

const buscaExpediente = async (numeroExpediente, areaPrivativa) => {
	try {
		const response = await accessApi(
			`${EXPEDIENTES_API_URL}/expedienteUnico/enderecos?numeroExpediente=${numeroExpediente}&areaPrivativa=${areaPrivativa}`,
			true
		);
		return response.data;
	} catch (e) {
		if (get(e, 'response.status') !== 404) {
			console.error('erro no servidor', e);
			return [];
		}
	}
};

const isValidEndereco = endereco => {
	if (size(endereco) === 0) {
		return true;
	}
	const parts = endereco.split(',').map(p => trim(p));
	const numero = parts.find(p => isFinite(parseInt(p, 10)));
	const q = parts.find(p => !isFinite(parseInt(p, 10)));
	return numero && size(trim(normalizedAddress(q))) > 0;
};
