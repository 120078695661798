import React from 'react';

import PropTypes from 'prop-types';

export default function UFSelectField({
	label = 'UF',
	labelEnum,
	required,
	name,
	value,
	isInvalid,
	disabled,
	onChangeHandler
}) {
	const val = value && value.length ? value : 'none';

	function onChangeSelect(ev) {
		let { value } = ev.target;
		value = value === 'none' ? undefined : value;
		onChangeHandler({ name, value });
		onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label) } });
	}

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<div>
				<select
					className={isInvalid ? 'form-control form-control-error' : 'form-control'}
					name={name}
					id={`id_${name}`}
					value={val}
					onChange={onChangeSelect}
					disabled={disabled}
				>
					<option value="none">Selecione uma opção</option>
					<option value="AC">Acre</option>
					<option value="AL">Alagoas</option>
					<option value="AP">Amapá</option>
					<option value="AM">Amazonas</option>
					<option value="BA">Bahia</option>
					<option value="CE">Ceará</option>
					<option value="DF">Distrito Federal</option>
					<option value="ES">Espírito Santo</option>
					<option value="GO">Goiás</option>
					<option value="MA">Maranhão</option>
					<option value="MT">Mato Grosso</option>
					<option value="MS">Mato Grosso do Sul</option>
					<option value="MG">Minas Gerais</option>
					<option value="PA">Pará</option>
					<option value="PI">Piauí</option>
					<option value="PB">Paraíba</option>
					<option value="PR">Paraná</option>
					<option value="PE">Pernambuco</option>
					<option value="RJ">Rio de Janeiro</option>
					<option value="RN">Rio Grande do Norte</option>
					<option value="RS">Rio Grande do Sul</option>
					<option value="RO">Rondônia</option>
					<option value="RR">Roraima</option>
					<option value="SC">Santa Catarina</option>
					<option value="SP">São Paulo</option>
					<option value="SE">Sergipe</option>
					<option value="TO">Tocantins</option>
				</select>
			</div>
		</>
	);
}

UFSelectField.displayName = 'UFSelectField';

UFSelectField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

function validate(value, required, label) {
	let errors = [];
	if (required) {
		if (!value || value.length === 0) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
}
