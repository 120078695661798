import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { isImmutable } from 'immutable';

import { compareStates, deepEqual } from 'utils/tools';

/**
 * Recupera/armazena dado da/na store através da chave [storePath]. Dados na store, armazenados como Immutables.
 * @param {String[]} storePath na store - exemplo: const storePath = ['licenciamento', 'errors'];
 * @param {String} idProperty property para comparar objetos. Opcional - aceita array ou string simples. Default para ['id', '_id'].
 * @returns {Any} mutable state storaged in store
 */
const useMutableState = (storePath, idProperty) => {
	const [mutableState, setMutableState] = useState();

	// eslint-disable-next-line no-unused-vars
	// const immutableState = useSelector(state => {
	// 	debugLog('\n=======================================\n', 'RECEBENDO NOVO ESTADO PARA', storePath[1]);
	// 	const mutable = state.getIn(storePath)?.toJS?.() || state.getIn(storePath) || null;
	// 	let equals = idProperty ? compareStates(mutable, mutableState, idProperty) : deepEqual(mutable, mutableState);
	// 	if (equals) {
	// 		if (mutable || mutableState) {
	// 			debugLog(storePath[1] === 'DADOS', 'ERAM IGUAIS', mutable, mutableState);
	// 		}
	// 	} else {
	// 		debugLog(storePath[1] === 'DADOS', '============> VAI ATUALIZAR <============', mutable);
	// 		setMutableState(mutable);
	// 	}
	// 	return state.getIn(storePath);
	// });

	const ims = useSelector(state => state.getIn(storePath));

	useEffect(() => {
		if (ims) {
			const mutable = isImmutable(ims) ? ims.toJS() : ims;
			const eq = idProperty ? compareStates(mutable, mutableState, idProperty) : deepEqual(mutable, mutableState);
			if (!eq) {
				setMutableState(mutable);
			}
		}
	}, [ims, idProperty, mutableState]);

	return mutableState;
};

export default useMutableState;
