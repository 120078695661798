import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import TemplateForm from '../../metadata-template/TemplateForm';
import metas from './formmetadata.json';

/* eslint-disable react-hooks/exhaustive-deps */
/** RELATÓRIO DE CARACTERIZAÇÃO DO EMPREENDIMENTO para o LAC de  Oficinas mecânicas, reparação, manutenção*/
const RceOficinaSimilaresForm = props => {
	const { data: propsData, onSubmit, readOnly: readOnlyView } = props;
	// state sobre o form
	const [dadosFormulario, setDadosFormulario] = useState({});
	// state com os fields do form
	const [formFieldsets, setFormFieldsets] = useState([]);
	// extras states
	const [errorList, setErrorsList] = useState();
	const [loading, setLoading] = useState(true);
	// effects
	useEffect(() => {
		async function carregaMetadata() {
			setLoading(true);
			if (size(formFieldsets) === 0) {
				try {
					// const retorno = (await accessApi('/collections/metadata/eventos'))?.data;
					// const solicitacaoEventosFieldsets = get(retorno, 'fieldsets') || [];
					const fsets = metas;
					setFormFieldsets(fsets);
				} catch (e) {
					setFormFieldsets([]);
					console.error('Erro no acesso aos metadados do formulário: ', e);
				}
			}
			setLoading(false);
		}
		carregaMetadata();
		if (propsData) {
			setDadosFormulario({ ...propsData });
		}
	}, [propsData]);

	// form handler
	const handleChangeFormulario = useCallback(
		onChangeParams => {
			const { key, value } = onChangeParams;
			if (key === 'detalhesArmazenamento') {
				const val = value || [];
				val.forEach(data => {
					Object.keys(data)
						.filter(key => key.startsWith('armTipoTanque'))
						.forEach(key => {
							const capacidade = data[key].substring(data[key].lastIndexOf('#') + 1);
							if (capacidade !== '0') {
								data['armCapacidade'] = { value: capacidade };
							}
						});
				});
			}
			const newLocal = { ...dadosFormulario, [key]: value };
			setDadosFormulario(newLocal);
		},
		[dadosFormulario, setDadosFormulario]
	);

	const handleSubmit = useCallback(
		([ok, erros, extras]) => {
			if (ok) {
				onSubmit(dadosFormulario, extras);
			} else {
				setErrorsList(erros);
			}
		},
		[dadosFormulario]
	);

	const readOnly = readOnlyView === true;
	const disable = readOnlyView === true;

	return (
		<>
			{loading ? (
				<Loader msg="Carregando metadados do formulário" />
			) : (
				<>
					<TemplateForm
						fieldsets={formFieldsets}
						onChangeHandler={handleChangeFormulario}
						onSubmit={handleSubmit}
						formData={dadosFormulario}
						readOnly={readOnly}
						disabled={disable}
					></TemplateForm>
					<ErrorMessages errorList={errorList}></ErrorMessages>
				</>
			)}
		</>
	);
};
RceOficinaSimilaresForm.displayName = 'RceOficinaSimilaresForm';

RceOficinaSimilaresForm.propTypes = {
	data: PropTypes.any,
	onSubmit: PropTypes.func,
	readOnly: PropTypes.bool
};

export default RceOficinaSimilaresForm;
