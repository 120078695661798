import { isImmutable } from 'immutable';
import { createSelector } from 'reselect';

const selectDomain = state => state.get('licenciamento');

const makeSelect = property =>
	createSelector(selectDomain, domain => {
		let result = domain.get(property);
		const resultJS = result && isImmutable(result) ? result.toJS() : result;
		return resultJS;
	});

export default { makeSelect };
