import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';
import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { send } from 'utils/injectApi';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';
const SERVER_CONSULTA_REGISTRO_PROFISSIONAL = 'registroProfissional/SERVER_CONSULTA_CREA_CAU';
const MIN_SIZE_PESQUISAR = 3;

class RegistroProfissional extends Component {
	static displayName = 'RegistroProfissional';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool,
		value: PropTypes.string
	};

	timer: null;

	constructor(props) {
		super(props);

		const options = get(this.props, 'uiSchema.ui:options') || {};
		let crea = options.crea || false;
		let cau = options.cau || false;
		const escolheConselho = options.escolheConselho;

		if (!crea && !cau) {
			crea = true;
			cau = true;
		}

		this.state = {
			formData: props.formData,
			className: CLASS_NAME_FORM_CONTROL,
			valid: true,
			numeroRegistro: props.formData.numeroRegistro,
			erroCreaCau: null,
			crea,
			cau,
			escolheConselho,
			conselho: null,
			offline: false
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	isCrea = () => this.state.crea && !this.state.cau;
	isCau = () => !this.state.crea && this.state.cau;

	setConselho = async conselho => {
		this.setState({ conselho });

		if (size(this.state.numeroRegistro) >= MIN_SIZE_PESQUISAR) {
			this.updateState(this.state.numeroRegistro);
		}

		return true;
	};

	setNumeroRegistro = async e => {
		const numeroRegistro = e.target.value.replace(/ /g, '');

		const { escolheConselho, conselho } = this.state;
		const podePesquisar = escolheConselho ? (conselho ? true : false) : true;
		this.setState({ numeroRegistro, valid: size(numeroRegistro) === 0 });
		if (podePesquisar) {
			this.updateState(numeroRegistro);
		}

		return true;
	};

	updateState(value) {
		const that = this;
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(
			async () => {
				that.setState({ pesquisando: true, erroCreaCau: null });
				const retorno = await that.consultaRegistro(value);
				if (value.numeroRegistro) {
					value.numeroRegistro = `${value.numeroRegistro}`;
				}
				if (!retorno.error) {
					//se não excluir dá erro por não ser string - piradíssimo
					delete retorno.error;
				}
				this.props.onChange(retorno);
				that.setState({
					erroCreaCau: retorno.error ? [retorno.error] : null,
					valid: retorno.valid,
					pesquisando: false
				});
			},
			size(value) === 0 || this.state.numeroRegistro === value ? 0 : 500
		);
	}

	consultaRegistro = async value => {
		const { escolheConselho, conselho } = this.state;
		let response = { data: { numeroRegistro: value, conselho } };
		let retorno = null;
		try {
			if (size(value) >= MIN_SIZE_PESQUISAR && (escolheConselho ? !!conselho : true)) {
				const payload = { numeroRegistro: value };
				let filter = null;
				if (escolheConselho) {
					filter = this.state.conselho;
				} else {
					if (this.isCrea()) {
						filter = 'crea';
					}
					if (this.isCau()) {
						filter = 'cau';
					}
				}
				if (filter) {
					payload.queryString = { filter };
				}
				response = await send(
					{ type: SERVER_CONSULTA_REGISTRO_PROFISSIONAL, payload },
					10000, //timeout
					''
				);
			}
			const { data } = response;
			let valid = size(get(data, 'error')) === 0 || data.numeroRegistro === '';
			this.setState({ offline: false });

			retorno = {
				numeroRegistro: size(data.numeroRegistro) === 0 ? (size(value) === 0 ? undefined : '') : data.numeroRegistro,
				conselho,
				nome: data.nome,
				cpf: data.cpf,
				situacao: data.status,
				tipo: escolheConselho ? conselho : data.tipo || '',
				valid,
				error: null
			};
			if (size(data.error)) {
				retorno.numeroRegistro = value;
				retorno.error = data.error;
				retorno.valid = false;
			} else if (size(data.status) > 0) {
				const status = get(data, 'status') || '';
				if (status !== 'Profissional habilitado' && status !== 'ATIVO') {
					retorno.error = status;
					retorno.valid = false;
				}
				if (data.offline) {
					this.setState({ offline: true });
				}
			}
		} catch (error) {
			///////// Caso aconteca um erro no servico externo, o valor inserido eh aceito sem conferencia //////////
			retorno = {
				numeroRegistro: value
				// error: `A consulta a base ${this.creaOuCau()} está indisponível no momento. Tente novamente mais tarde.`,
				// valid: false
			};
			/////////////////////////////////////////////////////////////////////////////////////////////////////////
		}
		return retorno;
	};

	creaOuCau() {
		return this.state.escolheConselho
			? (this.state.conselho || 'CREA/CAU').toUpperCase()
			: this.isCrea()
			? 'CREA'
			: this.isCau()
			? 'CAU'
			: 'CREA/CAU';
	}

	extractErrors = attr => get(attr, '__errors') || [];

	render() {
		const requireds = this.props.schema.required || [];
		const requiredNumeroRegistro = requireds.indexOf('numeroRegistro') > -1;
		const { numeroRegistro: errorNumeroRegistro, errorConselho } = this.props.errorSchema;
		const { numeroRegistro, erroCreaCau, escolheConselho } = this.state;
		const { disabled, readonly } = this.props;

		return (
			<Fragment>
				{escolheConselho && (
					<div className="form-group col-md-4">
						<label className="control-label" htmlFor="root_nome">
							Selecione o conselho
							{requiredNumeroRegistro ? '*' : ''}
						</label>
						<div className="options-conselhos">
							{['crea', 'cau'].map((c, i) => (
								<label key={i}>
									<input
										className="CLASS_NAME_FORM_CONTROL"
										type="radio"
										name="tipo"
										value={c}
										onChange={e => this.setConselho(e.target.value)}
										readOnly={readonly}
										disabled={disabled}
									/>
									{c.toUpperCase()}
								</label>
							))}
						</div>
						<ErrorDetail attr={errorConselho} />
					</div>
				)}
				<div className={`form-group col-md-${escolheConselho ? '8' : '12'}`}>
					<label className="control-label" htmlFor="root_nome">
						{this.isCrea() ? 'CREA' : this.isCau() ? 'CAU' : 'Registro Profissional'}
						{requiredNumeroRegistro ? '*' : ''}
					</label>
					<div />
					<input
						className={
							this.state.valid ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
						}
						placeholder={`Informe um registro válido no ${this.creaOuCau()}`}
						type="text"
						onChange={this.setNumeroRegistro}
						readOnly={this.state.pesquisando}
						value={numeroRegistro || ''}
						disabled={disabled || readonly}
					/>
					{}
					{this.state.pesquisando && <i className={'fa fa-refresh fa-spin refresh-field'} />}
					<ErrorDetail attr={errorNumeroRegistro} />
					{this.state.offline && (
						<ErrorMessages
							errorList={[
								'A consulta à base CREA/CAU está indisponível no momento e não podemos validar seu registro.'
							]}
						/>
					)}

					<div />
					{erroCreaCau && <ErrorMessages errorList={erroCreaCau} />}
				</div>
				{this.state.pesquisando && <Loader msg="Pesquisando registro profissional..." />}
			</Fragment>
		);
	}
}

export default RegistroProfissional;
