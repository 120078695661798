import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	padding: 10px 0;

	input {
		font-size: 18pt;
	}
`;

const CheckboxField = ({
	label,
	required,
	name,
	value,
	options,
	readOnly,
	disabled,
	onChangeHandler,
	labelEnum,
	stacked
}) => {
	const onCheckItem = (e, codigo) => {
		const { checked } = e.target;
		let valor;
		if (options.length === 1) {
			valor = checked;
		} else {
			valor = (value || []).filter(v => v !== codigo);
			if (checked) {
				valor.push(codigo);
			}
		}

		onChangeHandler({ name, value: valor });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo) } });
	};

	let infos = [];
	return (
		<>
			{label && (
				<div className="row">
					<div className="form-group col-md-12">
						<label className="control-label">
							{labelEnum || label}
							{required && <span className="required">*</span>}
						</label>
					</div>
				</div>
			)}
			{options.map(o => {
				let codigo = typeof o === 'object' ? o.codigo : o;
				const descricao = typeof o === 'object' ? o.descricao : o;

				const infoOnChecked = typeof o === 'object' ? o.infoOnChecked : null;

				const checked = Array.isArray(value)
					? !!(value || []).find(v => (typeof v === 'object' ? v.codigo === codigo : v === codigo))
					: !!value;

				if (infoOnChecked && !infos.includes(infoOnChecked) && checked) {
					infos.push(infoOnChecked);
				}

				return stacked ? (
					<Container key={codigo}>
						<div className="form-type-checkbox checkbox">
							<input
								type="checkbox"
								name={name}
								onClick={e => onCheckItem(e, codigo)}
								readOnly={readOnly}
								disabled={disabled}
								onChange={() => false}
								checked={checked}
								className="form-checkbox"
								id={`${name}${codigo}`}
							/>
							<label htmlFor={`${name}${codigo}`} className="control-label option">
								{descricao}
							</label>
						</div>
					</Container>
				) : (
					<div className="row" key={codigo}>
						<div className="form-check">
							<label>
								<input
									type="checkbox"
									name={name}
									onClick={e => onCheckItem(e, codigo)}
									readOnly={readOnly}
									disabled={disabled}
									onChange={() => false}
									checked={checked}
								/>{' '}
								<span>{descricao}</span>
							</label>
						</div>
					</div>
				);
			})}

			{size(infos) > 0 && (
				<>
					<div className="bloco-lista-anexos">
						<div className="titulo">
							<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
							<p>
								Com base nos indicadores selecionados, na próxima tela será necessário anexar os seguintes documentos:
							</p>
						</div>
						<ul>
							{infos.map((info, index) => (
								<li key={index}>{info}</li>
							))}
						</ul>
					</div>
				</>
			)}
		</>
	);
};
CheckboxField.displayName = 'CheckboxField';

CheckboxField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	stacked: PropTypes.bool
};

export default CheckboxField;

export const validate = () => {
	let errors = [];
	return errors;
};
