import React, { useEffect, useState } from 'react';

import { useLazyQuery } from 'react-apollo';
import { useStore } from 'react-redux';

import gql from 'graphql-tag';
import { size } from 'lodash';
import moment from 'moment';

import EditorView from 'components/EditorView';

import useLocalStorage from 'custom-hooks/useLocalStorage';

const PAGE_SIZE = 1000;

const ModalNovidades = () => {
	const [showModal, setShowModal] = useState(false);
	const [listaNovidades, setListaNovidades] = useState(null);
	const [dataUltimaNewsVisualizada, setDataUltimaNewsVisualizada] = useLocalStorage('dataUltimaNews', null);

	const store = useStore();

	let [getNews, { data }] = useLazyQuery(
		gql`
			query novidadeList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoNovidadeList(term: $term, skip: $skip, limit: $limit) {
					id
					titulo
					descricao
					dataAtualizacao
					tipoNovidade
				}
				count: LicenciamentoNovidadeCount(term: $term)
			}
		`,
		{
			variables: { limit: PAGE_SIZE },
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		if (store?.kc?.authenticated) {
			getNews();
		}
	}, [getNews, store]);

	useEffect(() => {
		if (data && size(data.list) > 0) {
			let listaAux = data?.list
				?.filter(o => o.tipoNovidade === 'portal' || o.tipoNovidade === 'ambos')
				.sort((o1, o2) => (o1.dataAtualizacao > o2.dataAtualizacao ? -1 : 1));

			listaAux = listaAux.filter(news => moment().valueOf() > moment(news.dataAtualizacao).startOf('day').valueOf());

			if (dataUltimaNewsVisualizada && dataUltimaNewsVisualizada !== 'null') {
				listaAux = listaAux.filter(news => news.dataAtualizacao > dataUltimaNewsVisualizada);
			}
			setListaNovidades(listaAux);
			if (size(listaAux) > 0) {
				setShowModal(true);
			}
		}
	}, [data, dataUltimaNewsVisualizada]);

	return (
		<>
			{data && showModal && (
				<div className="modal-wrapper" style={{ zIndex: '10000' }}>
					<div className="modal-novidades">
						<div className="header-group" style={{ marginBottom: '20px', borderBottom: '1px solid #cbcbcb' }}>
							<h2>
								{'Novidades do sistema'}
								<i className="fa fa-bell-o" style={{ marginLeft: '8px', top: '0px', paddingBottom: '10px' }} />
							</h2>
							<button
								type="button"
								onClick={() => {
									setShowModal(false);
									setDataUltimaNewsVisualizada(listaNovidades[0].dataAtualizacao);
								}}
								style={{ border: 'none' }}
							>
								<i className="fa fa-times"></i>
							</button>
						</div>
						{size(listaNovidades) > 0 &&
							listaNovidades
								.sort((o1, o2) => (o1.dataAtualizacao > o2.dataAtualizacao ? -1 : 1))
								.map(novidade => (
									<div key={novidade.id} style={{ marginBottom: '20px' }}>
										<h3>{novidade.titulo}</h3>
										<h4 className="data-novidade-popup">
											Publicado em {moment(novidade.dataAtualizacao).format('DD/MM/YYYY')}
										</h4>
										<div style={{ border: '1px solid', padding: '15px' }}>
											<EditorView state={novidade.descricao} hideClass={true} />
										</div>
									</div>
								))}
						<div className="cjto-botoes" style={{ marginRight: '0px' }}>
							<button
								type="button"
								className="btn-primary"
								onClick={() => {
									setShowModal(false);
									setDataUltimaNewsVisualizada(listaNovidades[0].dataAtualizacao);
								}}
							>
								{'Fechar'}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
ModalNovidades.displayName = 'ModalNovidades';

export default ModalNovidades;
