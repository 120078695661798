import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { get, size } from 'lodash';
import moment from 'moment';

import { EXPEDIENTES_API_URL } from 'environments';

export default function VistoriasExpedienteField({
	label,
	labelEnum,
	required,
	name,
	value,
	isInvalid,
	disabled,
	readOnly,
	onChangeHandler,
	expedienteValue
}) {
	const [idEU, setIdEU] = useState(expedienteValue?._id);
	const [url, setUrl] = useState(`${EXPEDIENTES_API_URL}/eu/${expedienteValue?._id}`);
	const [permitValidate, setPermitValidate] = useState(false);
	const [erros, setErros] = useState();

	const [{ data: euData, loading: euLoading, error: euError }, execute] = useAxios(url, {
		manual: true
	});

	useEffect(() => {
		if (expedienteValue && expedienteValue._id !== idEU) {
			setIdEU(expedienteValue._id);
			setUrl(`${EXPEDIENTES_API_URL}/eu/${expedienteValue._id}`);
		}
	}, [expedienteValue, idEU]);

	useEffect(() => {
		if (idEU) {
			execute().finally(() => setPermitValidate(true));
		}
	}, [execute, idEU]);

	useEffect(() => {
		if (permitValidate && !euLoading) {
			if (euError) {
				console.error(euError);
				setPermitValidate(false);
			} else if (!euData) {
				setErros({ [name]: ['Expediente Único não encontrado'] });
				setPermitValidate(false);
			} else {
				if (!euData.vistorias || !euData.vistorias.length) {
					setErros({ [name]: ['Expediente Único não possui vistorias realizadas'] });
					setPermitValidate(false);
				} else {
					const { vistorias = [] } = euData;
					const hasCarta = vistorias.some(v => v.cartaHabitacao !== null);
					if (!hasCarta) {
						setErros({ [name]: ['Expediente Único não possui carta habitação'] });
						setPermitValidate(false);
					} else {
						setErros({ [name]: [] });
						setPermitValidate(false);
					}
				}
			}
		}
	}, [euData, euError, euLoading, permitValidate, name]);

	useEffect(() => {
		if (size(erros) && !euLoading) {
			onChangeHandler({ name: 'errors', value: erros });
		}
	}, [erros, euLoading, onChangeHandler]);

	function onChangeSelect(ev) {
		let { value } = ev.target;
		const option = options.find(o => o.codigo === value);
		value = value === 'none' ? undefined : { idCartaHabitacao: value, descricao: option?.descricao };

		onChangeHandler({ name, value });
		onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label) } });
	}

	const options = useMemo(() => {
		if (!euData) {
			return [];
		} else {
			const { vistorias = [] } = euData;
			return vistorias
				.filter(v => !!v.cartaHabitacao)
				.filter(v => !v.isSubstituida)
				.map(({ cartaHabitacao }) => {
					const { id, dataEmissao } = cartaHabitacao;
					const data = moment.utc(dataEmissao).format('DD/MM/YYYY');
					return {
						codigo: id,
						descricao: `Carta emitida em ${data}`
					};
				})
				.reverse();
		}
	}, [euData]);

	const valSelectElement = get(value, 'idCartaHabitacao', 'none');

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<select
				className={isInvalid ? 'form-control form-control-error' : 'form-control'}
				name={name}
				id={`id_${name}`}
				value={valSelectElement}
				onChange={onChangeSelect}
				disabled={disabled || readOnly}
			>
				{options && (
					<>
						<option value="none">Selecione uma opção</option>
						{options.map(opt => (
							<option value={opt.codigo} key={opt.codigo}>
								{opt.descricao}
							</option>
						))}
					</>
				)}
			</select>
		</>
	);
}

VistoriasExpedienteField.displayName = 'VistoriasExpedienteField';

VistoriasExpedienteField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	forTemplate: PropTypes.bool,
	isInvalid: PropTypes.bool,
	readOnly: PropTypes.bool,
	expedienteValue: PropTypes.object
};

export const validate = (value, required, label) => {
	let errors = [];
	if (required) {
		if (!value || value.length === 0) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
