import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import useErrors from 'custom-hooks/useErrors';

import TooltipIcon from './TooltipIcon';

const RadioComponent = ({
	label,
	observacao,
	required,
	name,
	value,
	options,
	readOnly,
	disabled: disabledProps,
	onChangeHandler,
	radioStack,
	isVS = false,
	hasExternalLink,
	externalLink,
	externalLinkText
}) => {
	/* REDUX */
	const [errors] = useErrors();

	const onChangeRadio = e => {
		const { name, value: codigo } = e.target;
		if (codigo !== value) {
			if (isVS) {
				onChangeHandler({ value: codigo, name });
			} else {
				onChangeHandler(codigo, name);
			}
		}
	};
	const resposta = options.reduce((acc, o) => {
		const codigo = typeof o === 'object' ? o.codigo : o;
		const descricao = typeof o === 'object' ? o.descricao : o;
		return value === codigo ? descricao : acc;
	}, null);
	return readOnly & !resposta ? null : (
		<>
			<div className={`${readOnly ? 'row container-resposta-ap' : ''}`}>
				<div>
					<span>{label}</span>
					{required && <span className="required">*</span>}
				</div>
				{readOnly && <span className="resposta-ap">{resposta || 'não respondido'}</span>}
				{observacao && (
					<div style={{ marginTop: '5px' }}>
						Observação: <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red' }}>{observacao}</span>
					</div>
				)}
			</div>
			{!readOnly && (
				<div className="row">
					<div className="form-group col-md-12">
						{options.map(o => {
							const codigo = typeof o === 'object' ? o.codigo : o;
							const descricao = typeof o === 'object' ? o.descricao : o;
							const descricaoComplementar = typeof o === 'object' ? o.descricaoComplementar : o;
							const iconWarning = o.warning || o.iconWarning;
							const disabled = typeof o === 'object' ? o.disabled || false : false;
							const defaultValue = typeof o === 'object' ? o.defaultValue || false : false;
							if (defaultValue && !value) {
								onChangeRadio({ target: { name, value: codigo } });
							}
							return (
								<Fragment key={codigo}>
									<div
										className={`form-check ${radioStack ? '' : 'form-check-inline'}`}
										key={codigo}
										style={radioStack ? { marginBottom: '7px' } : {}}
									>
										<input
											type="radio"
											value={codigo}
											id={`radio-${name}-${codigo}`}
											name={name}
											onClick={onChangeRadio}
											readOnly={readOnly}
											disabled={disabled || disabledProps}
											onChange={() => false}
											checked={value === codigo}
											className="form-check-input"
										/>
										<label htmlFor={`radio-${name}-${codigo}`} className="form-check-label">
											{iconWarning && value === codigo ? (
												<TooltipIcon
													label={descricao}
													text={iconWarning}
													icon="fa-exclamation-triangle"
													iconColor="#b39d5b"
												/>
											) : (
												descricao
											)}
											{descricaoComplementar && <div style={{ fontSize: 'small' }}>{descricaoComplementar}</div>}
										</label>
									</div>
									{o.observacao && value === codigo && (
										<div style={{ marginTop: '5px' }}>
											Observação:{' '}
											<span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red' }}>{o.observacao}</span>
										</div>
									)}
								</Fragment>
							);
						})}
						{size(errors?.[name]) > 0 && <ErrorMessages errorList={errors[name]} />}
						{hasExternalLink && externalLink && (
							<>
								<a href={externalLink} target="_blank" rel="noopener noreferrer">
									{externalLinkText}
									<i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
								</a>
							</>
						)}
					</div>
				</div>
			)}{' '}
		</>
	);
};
RadioComponent.displayName = 'RadioField';

RadioComponent.propTypes = {
	label: PropTypes.string,
	observacao: PropTypes.string,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	radioStack: PropTypes.bool,
	hasExternalLink: PropTypes.bool,
	externalLink: PropTypes.string,
	externalLinkText: PropTypes.string,
	isVS: PropTypes.bool
};
export default RadioComponent;
