import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { isCPF, isCNPJ, format } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const CpfCnpjField = ({
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler
}) => {
	const onChangeText = e => {
		let { value } = e.target;
		if (value) {
			value = value.replace(/[^0-9]/g, '');
			value = value.substr(0, 14);

			if (isCPF(value)) {
				value = format(value, '000.000.000-00');
			} else if (isCNPJ(value)) {
				value = format(value, '00.000.000/0000-00');
			}
		}
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};
	return (
		<div className={containerClass}>
			<div className="col-md-12">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
				<input
					className={
						size(errorList) > 0
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					type="text"
					placeholder={placeHolder}
					name={name}
					value={value}
					onChange={onChangeText}
					readOnly={readOnly}
					disabled={disabled}
				/>
			</div>
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
CpfCnpjField.displayName = 'TextField';

CpfCnpjField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default CpfCnpjField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (!isCPF(value) && !isCNPJ(value)) {
			errors.push(`Insira um ${label} válido`);
		}
	}
	return errors;
};
