import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import EmbedList from 'components/EmbedList';
import ErrorMessages from 'components/ErrorMessages';
import IntlNumberInput from 'components/react-intl-number-input';
import TooltipIcon from 'components/TooltipIcon';

import { debugLog } from '.';
import SubunidadeAreaEdit from './SubunidadeAreaEdit';

const Aba_C_Area = ({ planilha, onChange }) => {
	const [abaC, setAbaC] = useState(planilha.c || {});

	useEffect(() => {
		debugLog('ABA_C', '[abaC]', abaC);
		onChange({ c: abaC });
	}, [abaC, onChange]);

	const adicionaSubunidade = () => {
		onChange({ d: { ...(planilha.d || {}), listaSubunidades: [...(planilha.d.listaSubunidades || []), {}] } });
	};

	const atualizaSubunidade = index => item => {
		onChange({
			d: {
				...(planilha.d || {}),
				listaSubunidades: planilha.d.listaSubunidades.map((subunidade, i) => (index === i ? item : subunidade))
			}
		});
	};

	const removeSubunidade = index => () => {
		onChange({
			d: {
				...(planilha.d || {}),
				listaSubunidades: planilha.d.listaSubunidades.filter((subunidade, i) => i !== index)
			}
		});
	};

	return (
		<>
			{abaC.errors && <ErrorMessages errorList={abaC.errors} />}
			<h3>Áreas</h3>
			<div className="row">
				<div className="form-group col-sm-4">
					<label htmlFor="escrit">
						<TooltipIcon label="Área escritura *">
							Área calculada conforme as dimensões constantes na Matrícula registrada no Cartório de Registro de
							Imóveis, ou área apresentada em levantamento planialtimétrico em anexo aos documentos juntamente com
							ART/RRT.
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaEscriturada}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaEscriturada: value }))}
					/>
				</div>
				<div className="form-group col-sm-4">
					<label htmlFor="poli">
						<TooltipIcon label="Área menor polígono">
							Área do polígono resultante da superposição dos polígonos com as dimensões do título e do local. Caso o
							polígono resultante for maior que a área da matrícula ou estiver deslocado, este deverá ser retificado
							junto ao cartório.
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaPoligono}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaPoligono: value }))}
					/>
				</div>
				<div className="form-group col-sm-4">
					<label htmlFor="aplic">
						<TooltipIcon label="Área para aplicação R.U.">
							Área do terreno considerada para o cálculo do RU (IA, Alturas, TO e ALP), conforme uma das opções abaixo:
							1. Área do terreno, conforme dimensões da matrícula; 2. Área da menor poligonal – podendo utilizar os
							benefícios do Art. 8º do Dec. 18.623/2014; 3. Área do terreno, conforme dimensões da matrícula, descontada
							a área Atingida por gravame do PDDUA; 4. Área da menor poligonal, descontada a área atingida da menor
							poligonal - podendo utilizar os benefícios do Art. 8º do Dec. 18.623/2014.
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaAplicacao}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaAplicacao: value }))}
					/>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-sm-4">
					<label htmlFor="ating">
						<TooltipIcon label="Área atingida da matrícula">
							Área atingida por gravame do PDDUA (alargamento viário ou equipamentos urbanos).
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaAtingida}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaAtingida: value }))}
					/>
				</div>
				<div className="form-group col-sm-4">
					<label htmlFor="ating">
						<TooltipIcon label="Área ating menor polígono">
							EK-Lu incluir texto explicativo, inclusive em relação ao RU
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaAtingidaMenorPoligono}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaAtingidaMenorPoligono: value }))}
					/>
				</div>
				<div className="form-group col-sm-4">
					<label htmlFor="remanes">
						<TooltipIcon label="Área Remanescente">
							Área resultante da subtração da área da escritura e área atingida;
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaC.areaRemanescente}
						onChange={(event, value) => setAbaC(oldState => ({ ...oldState, areaRemanescente: value }))}
					/>
				</div>
			</div>

			<h3>TPC</h3>
			<div className="row">
				<div className="form-group col-12 col-sm-12">
					<p>
						<TooltipIcon label="Uso de Transferência de Potencial Construtivo Local para o Projeto">
							Indicar se usará ou não TPC (Transferência de Pontencial Construtivo), referente a área atingida por
							Gravame do PDDUA.
						</TooltipIcon>
					</p>
					<div className="inline-input-radio-checkbox">
						<label>
							<input
								type="radio"
								name="usoTransferenciaPotencialConstrutivo"
								value="sim"
								id="radioSim"
								onChange={() => null}
								onClick={e => setAbaC(oldState => ({ ...oldState, [e.target.name]: e.target.value }))}
								checked={abaC.usoTransferenciaPotencialConstrutivo === 'sim'}
							/>
							Sim
						</label>
					</div>
					<div className="inline-input-radio-checkbox">
						<label>
							<input
								type="radio"
								name="usoTransferenciaPotencialConstrutivo"
								value="nao"
								id="radioNao"
								onChange={() => null}
								onClick={e => setAbaC(oldState => ({ ...oldState, [e.target.name]: e.target.value }))}
								checked={abaC.usoTransferenciaPotencialConstrutivo === 'nao'}
							/>
							Não
						</label>
					</div>
				</div>
			</div>

			<h3>Subunidades</h3>
			<EmbedList
				itemList={get(planilha, 'd.listaSubunidades')}
				emptyMessage="Nenhuma atividade (anexo 5.2) selecionada."
				showAddButton={size(get(planilha, 'd.listaSubunidades')) === 0}
				noLabel={size(get(planilha, 'd.listaSubunidades')) > 0}
				novoItem={adicionaSubunidade}
				novoItemLabel="adicionar anexos (5.2 / 5.3 / 5.4)"
				itemStyle={{ margin: '0px -16px' }}
			>
				{(item, index) => (
					<SubunidadeAreaEdit
						subunidade={item}
						onChange={atualizaSubunidade(index)}
						onRemove={size(planilha.d.listaSubunidades) > 1 ? removeSubunidade(index) : null}
						onAddItem={index + 1 === planilha.d.listaSubunidades.length ? adicionaSubunidade : null}
					/>
				)}
			</EmbedList>
		</>
	);
};
Aba_C_Area.displayName = 'Aba_C_Area';
Aba_C_Area.propTypes = {
	planilha: PropTypes.object,
	formulario: PropTypes.object,
	onChange: PropTypes.func
};

export default Aba_C_Area;
