import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
	const [value, setValueAux] = useState(() => {
		let value = localStorage.getItem(key) || initialValue;
		if (value === 'null') {
			value = null;
		}
		if (value === 'undefined') {
			value = undefined;
		}
		if (value?.startsWith('{') && value?.endsWith('}')) {
			value = JSON.parse(value);
		}
		if (value?.startsWith('[') && value?.endsWith(']')) {
			value = JSON.parse(value);
		}
		localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
		return value;
	});
	const setValue = value => {
		setValueAux(value);
		window.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
	};
	return [value, setValue];
};

export default useLocalStorage;
