export const TIPOS_CONSTRUCAO = [
	{ codigo: 'aConstruir', descricao: 'a construir' },
	{ codigo: 'aRegularizar', descricao: 'a regularizar' },
	{ codigo: 'existentePermanecer', descricao: 'existente (a permanecer)' },
	{ codigo: 'existenteReciclar', descricao: 'existente (a reciclar)' }
];

export const TIPOS_MATERIAL = [
	{ codigo: 'alvenaria', descricao: 'Alvenaria' },
	{ codigo: 'madeira', descricao: 'Madeira' },
	{ codigo: 'mista', descricao: 'Mista' },
	{ codigo: 'outro', descricao: 'Outro' }
];

export const CODIGO_ATIVIDADE_RESIDENCIAL = '1';

export const RESIDENCIAL_1_ECONOMIA = 'residencial1economia';
export const RESIDENCIAL_2_ECONOMIAS = 'residencial2economias';
export const EDIFICACOES_GERAIS = 'edificacoesGerais';
export const LABELS_TIPO_PLANILHA = {
	[RESIDENCIAL_1_ECONOMIA]: 'Planilha para Residencial com uma economia',
	[RESIDENCIAL_2_ECONOMIAS]: 'Planilha para Residencial com duas economias',
	[EDIFICACOES_GERAIS]: 'Planilha para Edificações Gerais'
};
export const GRUPOS = {
	[RESIDENCIAL_1_ECONOMIA]: [{ codigo: 'casa1', descricao: 'casa 1' }],
	[RESIDENCIAL_2_ECONOMIAS]: [
		{ codigo: 'casa1', descricao: 'casa 1' },
		{ codigo: 'casa2', descricao: 'casa 2' }
	],
	[EDIFICACOES_GERAIS]: [
		{ codigo: 'residencial', descricao: 'residencial' },
		{ codigo: 'naoResidencial', descricao: 'não residencial' }
	]
};
export const TIPOS_AREA = [
	{ codigo: 'adensavel', descricao: 'adensável' },
	{ codigo: 'naoAdensavel', descricao: 'não adensável' },
	{ codigo: 'isenta', descricao: 'isenta' }
];

export const getDescricao = (name, codigo, tipoPlanilha) => {
	const collection =
		name === 'grupos'
			? GRUPOS[tipoPlanilha]
			: name === 'tiposConstrucao'
			? TIPOS_CONSTRUCAO
			: name === 'tiposArea'
			? TIPOS_AREA
			: [];
	const saida = collection.find(item => item.codigo === codigo).descricao || `${codigo} não encontrado em "${name}"`;
	return saida;
};
