import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';

import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, size, omit, trim } from 'lodash';
import uuid from 'uuid/v1';

import ErrorMessages from 'components/ErrorMessages';

import actions from 'containers/Form/actions';

import { debugLog } from '.';
import {
	RESIDENCIAL_1_ECONOMIA,
	RESIDENCIAL_2_ECONOMIAS,
	EDIFICACOES_GERAIS,
	GRUPOS,
	TIPOS_AREA,
	TIPOS_CONSTRUCAO,
	LABELS_TIPO_PLANILHA
} from './constants';
import EconomiaComponent from './economia';

// #region constants
// #endregion

const PlanilhaDiscriminacao = ({ planilha, onChange }) => {
	/* REDUX */
	const dispatch = useDispatch();

	// recebe o errors da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const [errors, setErrors] = useState(null);
	const errorsImm = useSelector(state => state.getIn(['licenciamento', 'errors']));
	useEffect(() => {
		if (errorsImm) {
			setErrors(isImmutable(errorsImm) ? errorsImm.toJS() : errorsImm);
		}
	}, [errorsImm]);

	/* ESTADOS */
	const [abaE, setAbaE] = useState(planilha.e || {});
	const [tipoPlanilha, setTipoPlanilha] = useState(null);
	const [tiposConstrucaoSelecionados, setTiposConstrucaoSelecionados] = useState([]);
	const [incluindoEconomia, setIncluindoEconomia] = useState(false);
	const [economiaEmEdicao, setEconomiaEmEdicao] = useState(null);
	const [labelEconomia, setLabelEconomia] = useState(null);

	useEffect(() => {
		if (planilha) {
			const tipos = get(planilha, 'b.tiposConstrucao');
			if (size(tipos) > 0 || size(tiposConstrucaoSelecionados) > 0) {
				setTiposConstrucaoSelecionados(tipos);
			}
			const tipo =
				size(get(planilha, 'b.listaAtividades')) === 1 &&
				get(planilha, 'b.listaAtividades.0.anexo52.descricao') === 'RESIDENCIAL'
					? get(planilha, 'b.totalEconomias') === 1
						? RESIDENCIAL_1_ECONOMIA
						: get(planilha, 'b.totalEconomias') === 2
						? RESIDENCIAL_2_ECONOMIAS
						: EDIFICACOES_GERAIS
					: EDIFICACOES_GERAIS;
			if (tipoPlanilha !== tipo) {
				setTipoPlanilha(tipo);
			}
			if (!abaE[tipo]) {
				setAbaE(oldstate => ({ ...oldstate, [tipo]: {} }));
			}
		}
	}, [abaE, planilha, tipoPlanilha, tiposConstrucaoSelecionados]);

	useEffect(() => {
		debugLog('ABA_E', '[abaE]', abaE);
		onChange({ e: abaE });
	}, [abaE, onChange]);

	const obtemNovoPavimento = label => ({
		codigo: uuid(),
		label,
		area: 0
	});

	const novaEconomia = () => {
		const pavimento = obtemNovoPavimento('Térreo');
		const economia = {
			id: uuid(),
			label: `${trim(labelEconomia)}`,
			pavimentos: [omit(pavimento, ['area'])],
			tiposConstrucao: TIPOS_CONSTRUCAO.map(tipoConstrucao => ({
				codigo: tipoConstrucao.codigo,
				// enabled: !!tiposConstrucaoSelecionados.find(tps => tps.codigo === tipoConstrucao.codigo),
				grupos: GRUPOS[tipoPlanilha].map(grupo => ({
					codigo: grupo.codigo,
					tiposArea: TIPOS_AREA.map(tipoArea => ({
						codigo: tipoArea.codigo,
						pavimentos: [pavimento]
					}))
				}))
			}))
		};
		setAbaE(oldState => {
			const newState = { ...(oldState || {}) };
			const tipo = newState[tipoPlanilha] || {};
			const economias = tipo.economias || [];
			economias.push(economia);
			return { ...newState, [tipoPlanilha]: { ...tipo, economias: [...economias] } };
		});
	};

	const novoPavimento = (idEconomia, label) => {
		const novoPavimento = obtemNovoPavimento(label);
		const auxEconomia = window.structuredClone(abaE[tipoPlanilha].economias.find(e => e.id === idEconomia));
		auxEconomia.pavimentos.push(omit(novoPavimento, ['area']));
		auxEconomia.tiposConstrucao.forEach(tipoConstrucao => {
			tipoConstrucao.grupos.forEach(grupo => {
				grupo.tiposArea.forEach(tipoArea => {
					tipoArea.pavimentos.push(omit(novoPavimento, ['label']));
				});
			});
		});
		setAbaE(oldState => {
			const newState = { ...(oldState || {}) };
			const tipo = newState[tipoPlanilha] || {};
			const economias = (tipo.economias || []).map(e => (e.id === idEconomia ? auxEconomia : e));
			return { ...newState, [tipoPlanilha]: { ...tipo, economias: [...economias] } };
		});
	};

	const onChangeValue = (
		tipoPlanilha,
		idEconomia,
		codigoTipoConstrucao,
		codigoGrupo,
		codigoTipoArea,
		codigoPavimento,
		value
	) => {
		const economias = (get(abaE, `${tipoPlanilha}.economias`) || []).map(economia => {
			if (economia.id === idEconomia) {
				let valorAnterior = 0;
				let valorAtual = Math.round((value || 0) * 100);

				const tiposConstrucao = (economia.tiposConstrucao || []).map(tipoConstrucao => {
					if (tipoConstrucao.codigo === codigoTipoConstrucao) {
						const grupos = (tipoConstrucao.grupos || []).map(grupo => {
							if (grupo.codigo === codigoGrupo) {
								const tiposArea = (grupo.tiposArea || []).map(tipoArea => {
									if (tipoArea.codigo === codigoTipoArea) {
										const pavimentos = (tipoArea.pavimentos || []).map(pavimento => {
											if (pavimento.codigo === codigoPavimento) {
												valorAnterior = Math.round((pavimento.area || 0) * 100);
												return { ...pavimento, area: value };
											} else {
												return pavimento;
											}
										});
										const tipoAreaTotal = Math.round((tipoArea.total || 0) * 100);
										return {
											...tipoArea,
											pavimentos,
											total: (tipoAreaTotal - valorAnterior + valorAtual) / 100
										};
									} else {
										return tipoArea;
									}
								});
								return { ...grupo, tiposArea };
							} else {
								return grupo;
							}
						});
						return { ...tipoConstrucao, grupos };
					} else {
						return tipoConstrucao;
					}
				});
				const pavimentos = (economia.pavimentos || []).map(pavimento => {
					if (pavimento.codigo === codigoPavimento) {
						const pavimentoTotal = Math.round((pavimento.total || 0) * 100);
						const totalParcial = pavimentoTotal - valorAnterior + valorAtual;
						const total = totalParcial / 100;
						return { ...pavimento, total };
					} else {
						return pavimento;
					}
				});
				const economiaTotal = Math.round((economia.total || 0) * 100);
				return {
					...economia,
					tiposConstrucao,
					pavimentos,
					total: (economiaTotal - valorAnterior + valorAtual) / 100
				};
			} else {
				return economia;
			}
		});

		setAbaE(oldState => ({ ...oldState, [tipoPlanilha]: { ...(oldState[tipoPlanilha] || {}), economias } }));
	};

	const incluirEconomia = () => {
		if (size(trim(labelEconomia)) === 0) {
			dispatch(actions.setErrors({ labelNovaEconomia: ['Label é obirgatório'] }));
		} else {
			const labelUtilizado = (((abaE || {})[tipoPlanilha] || {}).economias || []).reduce(
				(acc, economia) => acc || trim(get(economia, 'label')) === trim(labelEconomia),
				false
			);
			if (labelUtilizado) {
				dispatch(actions.setErrors({ labelNovaEconomia: ['Este label já está sendo usado para outra economia'] }));
			} else {
				novaEconomia();
				ocultarFormularioEconomia();
			}
		}
		return true;
	};

	const tratarTeclas = e => {
		if (e.key === 'Enter') {
			return incluindoEconomia ? incluirEconomia(e) : editarEconomia(e);
		} else {
			if (e.key === 'Escape') {
				return ocultarFormularioEconomia(e);
			}
		}
		return true;
	};

	const ocultarFormularioEconomia = () => {
		setLabelEconomia(null);
		setIncluindoEconomia(false);
		setEconomiaEmEdicao(null);
		if (size(errors) > 0) {
			dispatch(actions.setErrors({}));
		}
		return true;
	};

	const editLabelEconomia = idEconomia => {
		const economia = abaE[tipoPlanilha].economias.find(e => e.id === idEconomia);
		setLabelEconomia(economia.label);
		setEconomiaEmEdicao(idEconomia);
	};

	const editarEconomia = () => {
		if (size(trim(labelEconomia)) === 0) {
			dispatch(actions.setErrors({ labelNovaEconomia: ['Label é obirgatório'] }));
		} else {
			const labelUtilizado = (((abaE || {})[tipoPlanilha] || {}).economias || [])
				.filter(e => e.id !== economiaEmEdicao)
				.reduce((acc, economia) => acc || trim(get(economia, 'label')) === trim(labelEconomia), false);
			if (labelUtilizado) {
				dispatch(actions.setErrors({ labelNovaEconomia: ['Este label já está sendo usado para outra economia'] }));
			} else {
				setAbaE({
					...abaE,
					[tipoPlanilha]: {
						...abaE[tipoPlanilha],
						economias: abaE[tipoPlanilha].economias.map(e =>
							e.id === economiaEmEdicao ? { ...e, label: labelEconomia } : e
						)
					}
				});
				ocultarFormularioEconomia();
			}
		}
		return true;
	};

	const removeEconomia = idEconomia => {
		if (window.confirm('Confirma exclusão da economia')) {
			setAbaE({
				...abaE,
				[tipoPlanilha]: {
					...abaE[tipoPlanilha],
					economias: abaE[tipoPlanilha].economias.filter(e => e.id !== idEconomia)
				}
			});
		}
	};

	const updateLabelPavimento = (idEconomia, codigoPavimento, labelPavimento) => {
		setAbaE({
			...abaE,
			[tipoPlanilha]: {
				...abaE[tipoPlanilha],
				economias: abaE[tipoPlanilha].economias.map(e =>
					e.id === idEconomia
						? {
								...e,
								pavimentos: e.pavimentos.map(p => (p.codigo === codigoPavimento ? { ...p, label: labelPavimento } : p))
						  }
						: e
				)
			}
		});
	};

	const removePavimento = (idEconomia, codigoPavimento) => {
		if (window.confirm('Confirma exclusão do pavimento')) {
			let valor = 0;
			let valorAcumulado = 0;
			setAbaE({
				...abaE,
				[tipoPlanilha]: {
					...abaE[tipoPlanilha],
					economias: abaE[tipoPlanilha].economias.map(e =>
						e.id === idEconomia
							? {
									...e,
									tiposConstrucao: e.tiposConstrucao.map(tc => ({
										...tc,
										grupos: tc.grupos.map(g => ({
											...g,
											tiposArea: g.tiposArea.map(ta => ({
												...ta,
												pavimentos: ta.pavimentos.filter(p => {
													if (p.codigo === codigoPavimento) {
														valor = parseInt(p.area * 100);
														valorAcumulado += valor;
													}
													return p.codigo !== codigoPavimento;
												}),
												total: (parseInt(ta.total * 100) - valor) / 100
											}))
										}))
									})),
									pavimentos: e.pavimentos.filter(p => p.codigo !== codigoPavimento),
									total: (parseInt(e.total * 100) - valorAcumulado) / 100
							  }
							: e
					)
				}
			});
		}
	};

	// AUXILIARES PARA RENDER
	const economias = (get(abaE, `${tipoPlanilha}.economias`) || []).map(economia => ({
		...(economia || {}),
		tiposConstrucao: (economia.tiposConstrucao || []).map(tipoConstrucao => ({
			...(tipoConstrucao || {}),
			enabled: !!tiposConstrucaoSelecionados.find(tps => tps.codigo === tipoConstrucao.codigo)
		}))
	}));

	// RENDER
	return (
		<>
			{tipoPlanilha ? (
				<h1 className="titulo-planilhas">{LABELS_TIPO_PLANILHA[tipoPlanilha]}</h1>
			) : (
				<span>loading...</span>
			)}
			<div className="cjto-botoes">
				<button type="button" className="btn btn-link btn-planilhas" onClick={() => setIncluindoEconomia(true)}>
					novo tipo de economia
				</button>
			</div>
			{(incluindoEconomia || economiaEmEdicao) && (
				<div style={{ display: 'flex' }}>
					<div className="formGroup" style={{ flex: 1 }}>
						<label className="control-label" htmlFor="root_nome">
							{incluindoEconomia ? 'Escolha o nome da nova economia' : 'Escolha o novo nome da economia'}
						</label>
						<input
							className={size(errors) > 0 ? 'form-control-error' : 'form-control'}
							type="text"
							value={labelEconomia || ''}
							onChange={e => setLabelEconomia(e.target.value)}
							onKeyDown={tratarTeclas}
							placeholder={incluindoEconomia ? 'Informe o nome da nova economia' : 'Informe o novo nome da economia'}
						/>
						<ErrorMessages errorList={get(errors, 'labelNovaEconomia')} />
					</div>
					<div className="inline-buttons">
						<button
							type="button"
							className={'btn btn-primary'}
							onClick={incluindoEconomia ? incluirEconomia : editarEconomia}
						>
							Concluir
						</button>
						<button type="button" className={'btn btn-secondary'} onClick={ocultarFormularioEconomia}>
							Cancelar
						</button>
					</div>
				</div>
			)}
			{economias.map(economia => (
				<Fragment key={economia.id}>
					<EconomiaComponent
						key={economia.id}
						economia={economia}
						tipoPlanilha={tipoPlanilha}
						tiposConstrucaoSelecionados={tiposConstrucaoSelecionados}
						novoPavimento={novoPavimento}
						editLabelEconomia={editLabelEconomia}
						removeEconomia={removeEconomia}
						updateLabelPavimento={updateLabelPavimento}
						removePavimento={removePavimento}
						onChange={onChangeValue}
					/>
				</Fragment>
			))}
		</>
	);
};
PlanilhaDiscriminacao.displayName = 'PlanilhaDiscriminacao';
PlanilhaDiscriminacao.propTypes = {
	planilha: PropTypes.object,
	formulario: PropTypes.object,
	onChange: PropTypes.func
};
export default PlanilhaDiscriminacao;
