import './PlanoDiretor.scss';

import React, { useEffect, useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import Tippy from '@tippyjs/react';
import { get, size, isEqual } from 'lodash';

import actions from 'containers/Form/actions';

import useMutableState from 'custom-hooks/useMutableState';
import useQueryStore from 'custom-hooks/useQueryStore';

import { PLANO_DIRETOR_API_URL } from 'environments';

import { isDebug } from 'utils/tools';

import PlanoDiretorSubunidades from './PlanoDiretorSubunidades';

import 'tippy.js/dist/tippy.css';

let i = 0;

const debugLog = (...parms) => false && isDebug && console.debug('(trk-PLANO_DIRETOR):', ++i, ...parms);

const PlanoDiretor = ({
	data = {},
	dataAnterior,
	formulario,
	readOnly,
	onChangeHandler,
	errosSubmissao,
	usuarioInterno = false,
	...props
}) => {
	const dispatch = useDispatch();

	const errors = useMutableState(['licenciamento', 'errors']);

	const [show, setShow] = useState(true);

	// LIMPA DADOS PLANO DIRETOR E SUBUNIDADE DA STORE NA SAÍDA DA TELA
	// MANTÉM TODOS OS DEMAIS CADASTROS PARA OTIMIZAR NOS PRÓXIMOS ACESSOS
	useEffect(
		() => () => {
			dispatch(actions.clearAnexoList(['dadosPlanoDiretor', 'subunidades']));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	/* HANDLERS */
	const setDataHandler = useCallback(
		(value, name) => {
			const deveEnviar = !isEqual(value, data?.[name]);
			if (deveEnviar) {
				debugLog('[data]', name, value);
				onChangeHandler(value, name);
			}
		},
		[data, onChangeHandler]
	);

	const codLogradouro =
		size(get(formulario, 'formData.data.enderecoCdlList')) > 0
			? get(get(formulario, 'formData.data.enderecoCdlList')[0], 'codLogradouro') ||
			  get(get(formulario, 'formData.data.enderecoCdlList')[0], 'codigoLogradouro')
			: null;
	const numero =
		size(get(formulario, 'formData.data.enderecoCdlList')) > 0
			? get(get(formulario, 'formData.data.enderecoCdlList')[0], 'numero')
			: null;
	//  passo 1 - obtem plano diretor
	const {
		data: dadosPlanoDiretorStore,
		loading: dadosPlanoDiretorLoading,
		error: dadosPlanoDiretorError
	} = useQueryStore(`${PLANO_DIRETOR_API_URL}/dte/${codLogradouro}/${numero}`, 'dadosPlanoDiretor', 'codDte');

	// passo 2 - obter dados do reg - useEffect para captura de retorno de dadosPlanoDiretor
	useEffect(() => {
		if (dadosPlanoDiretorStore) {
			if (data?.macrozona !== dadosPlanoDiretorStore?.mz) {
				setDataHandler(dadosPlanoDiretorStore?.mz, 'macrozona');
			}
			if (data?.ueu !== dadosPlanoDiretorStore?.ueu) {
				setDataHandler(dadosPlanoDiretorStore?.ueu, 'ueu');
			}
			if (data?.qtr !== dadosPlanoDiretorStore?.qtr) {
				setDataHandler(dadosPlanoDiretorStore?.qtr, 'qtr');
			}
		}
	}, [dadosPlanoDiretorStore, data, setDataHandler]);

	useEffect(() => {
		if (
			data?.subunidadeAtiva &&
			!(data?.subunidades || []).find(
				item => item?.subunidade?.subunidade === data?.subunidadeAtiva?.subunidade?.subunidade
			)
		) {
			setDataHandler(null, 'subunidadeAtiva');
			setDataHandler(null, 'zonaUsoAnexo51');
			setDataHandler(null, 'gaRegimeUrbanisticoAnexo');
			setDataHandler(null, 'iaRegimeUrbanisticoAnexo');
			setDataHandler(null, 'alturaRegimeUrbanisticoAnexo');
			setDataHandler(null, 'divisaRegimeUrbanisticoAnexo');
			setDataHandler(null, 'baseRegimeUrbanisticoAnexo');
			setDataHandler(null, 'toRegimeUrbanisticoAnexo');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.subunidades]);

	return (
		<>
			{data ? (
				<div className="plano-diretor">
					{/* Plano Diretor */}
					<div className="super-grupo">
						<div className="header-super-grupo">
							<h2 onClick={() => setShow(old => !old)}>
								{show ? <i className="fa fa-angle-down" /> : <i className="fa fa-angle-right" />}
								{' Informações do Plano Diretor'}
								{size(get(errors, 'planoDiretor')) > 0 ? <span className="error-super-grupo">* </span> : ' '}
								<Tippy
									content="Clique neste título para expandir as questões relacionadas."
									delay={100}
									animation="fade"
								>
									<i className="fa fa-info-circle" aria-hidden="true"></i>
								</Tippy>
							</h2>
						</div>
						{show ? (
							dadosPlanoDiretorLoading ? (
								'Obtendo Dados do Plano Diretor'
							) : dadosPlanoDiretorError ? (
								'Não foi possível recuperar os Dados do Plano Diretor. Tente novamente mais tarde.'
							) : (
								<>
									<PlanoDiretorSubunidades
										{...props}
										data={data}
										dataAnterior={dataAnterior}
										readOnly={readOnly}
										setDataHandler={setDataHandler}
										errosSubmissao={errosSubmissao}
										usuarioInterno={usuarioInterno}
									/>
									<div className={'obs-plano-diretor'}>
										<span style={{ color: '#cc3e3e', fontWeight: 'bold' }}>* </span>
										<span>informação obtida no Plano Diretor</span>
									</div>
								</>
							)
						) : null}
					</div>
				</div>
			) : (
				<h2>Aguardando dados...</h2>
			)}
		</>
	);
};
PlanoDiretor.displayName = 'PlanoDiretor';

PlanoDiretor.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	formulario: PropTypes.object,
	readOnly: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	filtroAtividadesPlanoDiretor: PropTypes.array,
	showAtividadesPlanoDiretor: PropTypes.bool,
	showIndices: PropTypes.bool,
	usuarioInterno: PropTypes.bool
};

export default PlanoDiretor;
