import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import {
	E_RECICLAGEM_USO_PARCIAL,
	E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL,
	obtemFormularioLicencasExpressasDados
} from '.';

function TermosCondicoesLicencasExpressas({ formulario, avancarLabel, onAvancarHandler }) {
	const l5OuL7 = useMemo(() => {
		const dados = obtemFormularioLicencasExpressasDados(formulario)?.dados || {};
		const l5OuL7 = [E_RECICLAGEM_USO_PARCIAL, E_DESMEMBRA_UNIFICA_RECICLA_USO_TOTAL].includes(dados.tipoForm);
		const concorda = l5OuL7 && dados.termoQuantoAreaOcupacaoIntensiva;
		return concorda;
	}, [formulario]);

	return (
		<>
			<h3>DECLARO QUE, COMO RESPONSÁVEL TÉCNICO PELO PROJETO</h3>
			<ol>
				<li>
					Que o projeto arquitetônico ora apresentado atende à legislação federal, estadual e municipal vigente, entre
					estas, a Lei Complementar nº 284, de 1992 e alterações posteriores, PDDUA, Decreto nº 20.613, de 2020 e
					alterações posteriores, estando o município isento de examinar o projeto ora apresentado;
				</li>
				<li>O projeto atende a legislação federal referente à acessibilidade;</li>
				<li>
					Estar ciente de que, caso se constate, a qualquer momento, desconformidade em relação aos parâmetros legais
					determinados por Lei Federal, Estadual ou Municipal específica, a edificação não poderá ser regularizada ou
					beneficiada por qualquer outra lei de anistia que venha a ser publicada, ficando sujeita às penalidades
					aplicáveis, inclusive ação demolitória;
				</li>
				<li>
					Que são verdadeiras as informações ora prestadas, bem como estarei sujeito a responder civil, criminal e
					administrativamente em caso de informações inverídicas ou descumprimento da lei;
				</li>
			</ol>
			<p>A DECLARAÇÃO EM DESACORDO COM LEGISLAÇÃO SUPRACITADA IMPLICA:</p>
			<ol>
				<li>Nulidade da licença eventualmente expedida com suporte na declaração;</li>
				<li>Embargo imediato da obra, caso constatadas irregularidades;</li>
				<li>
					Remessa de processo de licenciamento à fiscalização para a aplicação de penalidades administrativas cabíveis;
				</li>
				<li>Responsabilidade profissional do declarante junto ao órgão de controle do exercício da profissão;</li>
				<li>
					Remessa de documentos à Procuradoria-Geral do Município para a apuração da responsabilidade civil e criminal.
				</li>
			</ol>
			{l5OuL7 && (
				<p>
					O Responsável Técnico declara que verificou no PDDUA que a(s) atividade(s) não residencial(is) (conforme ANEXO
					5.2) encontra(m)-se dentro da RESTRIÇÃO QUANTO À IMPLANTAÇÃO DE ATIVIDADES NA ÁREA DE OCUPAÇÃO INTENSIVA
					(ANEXO 5.3), e o(s) porte(s) também encontra(m)-se dentro da RESTRIÇÃO QUANTO AOS LIMITES DE PORTE NA ÁREA DE
					OCUPAÇÃO INTENSIVA (ANEXO 5.4), estando em conformidade com o Plano Diretor de Desenvolvimento Urbano
					Ambiental (PDDUA).
				</p>
			)}
			<p>
				<strong>DECLARO QUE</strong> estou ciente que responderei civil e penalmente pela veracidade das declarações ora
				prestadas, isentando a Prefeitura Municipal de Porto Alegre de quaisquer responsabilidades pelas mesmas,
				assumindo todas as obrigações previstas na Lei Complementar 284, de 1992, inclusive eventuais danos causados a
				terceiros, bem como estará sujeito a responder civil, criminal e administrativamente em caso de informações
				inverídicas ou descumprimento da lei.
			</p>
			<p>
				<strong>DECLARO QUE</strong> o imóvel existente atende o Art. 12, da Lei 946/22, atendia a capacidade
				construtiva em conformidade com a legislação urbanística vigente na época da construção, ficando isenta,
				conforme declaração do Responsável Técnico, do ônus do Solo.
			</p>
			<div className="toolbar">
				<button
					style={{ marginTop: '10px' }}
					type="button"
					className="btn btn-primary"
					onClick={e => onAvancarHandler && onAvancarHandler(e)}
				>
					{avancarLabel}
				</button>
			</div>
		</>
	);
}
TermosCondicoesLicencasExpressas.displayName = 'TermosCondicoesLicencasExpressas';
TermosCondicoesLicencasExpressas.propTypes = {
	formulario: PropTypes.object,
	avancarLabel: PropTypes.string,
	onAvancarHandler: PropTypes.func
};

export default TermosCondicoesLicencasExpressas;
