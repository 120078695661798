import React from 'react';

import PropTypes from 'prop-types';

import { get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';

import { METAFORMLE } from './MetadataLE';

export function InfosDispositivosControleLE({ data: localData, onChangeHandler, errosSubmissao }) {
	const restricaoALPdoCondominio = METAFORMLE.RESTRICOES_ADMINISTRATIVAS.restricaoALPdoCondominio.name;
	const restricoesData = get(localData, 'restricoesAdministrativas', localData);
	const showALP = get(restricoesData, `${restricaoALPdoCondominio}`) === 'sim';

	return (
		<>
			<fieldset>
				<legend>
					Dispositivos de controle para a Unidade Autônoma{' '}
					<small className="d-block">conforme a aprovação do condomínio</small>
				</legend>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="areaTotalMatriculaLote"
						label="Área da matrícula da unidade autônoma (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.areaTotalMatriculaLote}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'areaTotalMatriculaLote')} />
				</div>
				<h2 className="legend_secundaria">Quotas</h2>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="quotaIndiceAproveitamento"
						label="Índice de Aproveitamento - IA (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaIndiceAproveitamento}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaIndiceAproveitamento')} />
				</div>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="quotaTaxaOcupacao"
						label="Taxa de Ocupação - TO (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaTaxaOcupacao}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaTaxaOcupacao')} />
				</div>
				{showALP && (
					<div className="pergunta metragem">
						<NumberFieldLE
							name="quotaALP"
							label="Área Livre Permeável - ALP (m²)"
							placeHolder="metragem m²"
							required={showALP}
							onChangeHandler={onChangeHandler}
							value={localData.quotaALP}
							decimals={2}
							maxSize="10"
						/>
						<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaALP')} />
					</div>
				)}
				<h2 className="legend_secundaria">Alturas Máximas</h2>
				<div className="pergunta metragem">
					<NumberFieldLE
						name={METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name}
						label={METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.label}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.alturaMaximaRegimeUrbanistico}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages
						errorList={(errosSubmissao || []).filter(
							e => e.campo === METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name
						)}
					/>
				</div>
				<div className="pergunta metragem">
					<NumberFieldLE
						name={METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name}
						label={METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.label}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.alturaMaximaAeroportoAerodromo}
						decimals={2}
						maxSize="10"
					/>
					<ErrorMessages
						errorList={(errosSubmissao || []).filter(
							e => e.campo === METAFORMLE.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name
						)}
					/>
				</div>
			</fieldset>
		</>
	);
}

InfosDispositivosControleLE.displayName = 'InfosDispositivosControleLE';
InfosDispositivosControleLE.propTypes = {
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	data: PropTypes.object,
	errosSubmissao: PropTypes.array
};
