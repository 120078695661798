import React from 'react';

import logo from 'assets/images/logo.svg';

const NotFoundPage = () => (
	<div className="error-screen">
		<h1 className="error-header">
			404 <span className="error-label">Página não existente</span>
		</h1>
		<p>O expediente único que você procura não existe ou não está mais nesse endereço.</p>
		<p>
			Você pode começar de novo fazendo uma <a href="/">nova busca por expediente</a>.
		</p>
		<div className="logo">
			<img src={logo} width="320" alt="logo" />
		</div>
	</div>
);
NotFoundPage.displayName = 'NotFoundPage';
export default NotFoundPage;
