import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { isImmutable } from 'immutable';
import { map, size, trim, isEqual, get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS } from 'containers/Form/Detalhe';

import useMessages from 'custom-hooks/useMessages';

import { sendApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

import actions from '../Form/actions';
import AbaA_Identificacao from './Aba_A_Identificacao';
import AbaB_Atividade from './Aba_B_Atividade';
import Aba_C_Area from './Aba_C_Area';
import AbaD_Indices from './Aba_D_Indices';
import AbaE_Discriminacao from './Aba_E_Discriminacao';
import AccordionSection from './AccordionSection';

const debug = false && isDebug;

let i = 0;

export const debugLog = (...args) => debug && console.debug('(PLANILHA)', ++i, ...args);

const ESTADO_INICIAL_PLANILHA = { a: {}, b: {}, c: {}, d: {}, e: {} };

const PlanilhaEdificacoesGerais = ({ exibePlanilha }) => {
	// #region hooks

	/* REDUX */
	const dispatch = useDispatch();
	// recebe o formulario da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const [formulario, setFormulario] = useState(null);
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			setFormulario(isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm);
		}
	}, [formularioImm]);
	// recebe o errors da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const [errors, setErrors] = useState(null);
	const errorsImm = useSelector(state => state.getIn(['licenciamento', 'errors']));
	useEffect(() => {
		if (errorsImm) {
			setErrors(isImmutable(errorsImm) ? errorsImm.toJS() : errorsImm);
		}
	}, [errorsImm]);

	/* OUTROS HOOKS */
	const { createMessage } = useMessages();
	const { id } = useParams();

	/* ESTADOS */
	const [planilha, setPlanilha] = useState(ESTADO_INICIAL_PLANILHA);
	const [aba, setAba] = useState(0);
	const [loading, setLoading] = useState(false);
	// #endregion

	useEffect(() => {
		if (formulario) {
			debugLog('[formulario]', formulario);
			const doc = (formulario.documentos || []).reduce(
				(acc, d) =>
					d.extensao === EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS
						? acc
							? d.versao > acc.versao
								? d
								: acc
							: d
						: acc,
				null
			);

			const docDados = doc ? (formulario.documentosDados || []).find(docDados => docDados.id === doc.id) : null;
			setPlanilha(docDados || ESTADO_INICIAL_PLANILHA);
		}
	}, [formulario]);

	const validateB = aba => {
		const LABEL_ABA = 'Planilha B';

		if (size(aba) === 0) {
			return [`${LABEL_ABA}: Campos obrigatórios devem ser preenchidos`];
		}

		let errorList = [];

		if ((aba.tipoMaterial || []).find(tm => tm.codigo === 'outro')) {
			if (size(trim(aba.tipoMaterialOutro)) === 0) {
				errorList.push(`${LABEL_ABA}: Selecionando tipo de material "Outro", é necessária especificá-lo`);
			}
		}

		if (!aba.zelador || (aba.zelador !== 'sim' && aba.zelador !== 'nao')) {
			errorList.push(`${LABEL_ABA}: Deve ser informada existência de apartamento do zelador.`);
		}
		if (!aba.bicicletario || (aba.bicicletario !== 'sim' && aba.bicicletario !== 'nao')) {
			errorList.push(`${LABEL_ABA}: Deve ser informada existência de bicicletário.`);
		}

		aba.errors = errorList;
		return errorList;
	};

	const validateC = aba => {
		const LABEL_ABA = 'Planilha C';
		if (size(aba) === 0) {
			return [`${LABEL_ABA}: Campos obrigatórios devem ser preenchidos`];
		}

		let errorList = [];
		if (!aba.areaEscriturada) {
			errorList.push(`${LABEL_ABA}: Área Escritura deve ser preenchida`);
		} else {
			if (aba.areaPoligono && aba.areaPoligono >= aba.areaEscriturada) {
				errorList.push(`${LABEL_ABA}: Área do Menor Polígono deve ser menor que Área Escriturada`);
			}
			if (aba.areaAtingida && aba.areaAtingida >= aba.areaEscriturada) {
				errorList.push(`${LABEL_ABA}: Área Atingida deve ser menor que Área Escriturada`);
			}
		}

		if (size(aba.listaSubunidades) > 0) {
			const totalAreasSubunidades = aba.listaSubunidades.reduce((acc, su) => acc + su.area, 0);
			if (totalAreasSubunidades > aba.areaAplicacao) {
				errorList.push(`${LABEL_ABA}: A soma das áreas das subunidades precisa ser menor que a área da aplicação R.U.`);
			}
		}

		aba.errors = errorList;
		return errorList;
	};

	const validateD = aba => {
		const LABEL_ABA = 'Planilha D';
		let errorList = [];
		if (size(aba) === 0) {
			return [`${LABEL_ABA}: Campos obrigatórios devem ser preenchidos`];
		}
		/* 		const bonusUmaEconomia = (abaB.totalEconomias || 0) === 1 ? 1.3 : 1;
		const totalTpcSoloCriado =
			abaD.soloCriadoPequenoAdensavel +
			abaD.soloCriadoMedioAdensavel +
			abaD.soloCriadoGrandeAdensavel +
			abaD.tpcAdensavel; */

		if (aba.listaSubunidades) {
			aba.listaSubunidades.forEach((su, indexSu) => {
				if (size(su.anexo51) === 0) {
					errorList.push(`${LABEL_ABA}: [${indexSu + 1}ª subunidade] Anexo 5.1 deve ser preenchido`);
				}
				if (size(su.listaAtividades) > 0) {
					su.listaAtividades.forEach((atividade, index) => {
						(atividade.atividadeItens || []).forEach(item => {
							if (size(item.anexo52) === 0) {
								errorList.push(
									`${LABEL_ABA}: [${indexSu + 1}ª subunidade] - [${
										index + 1
									}ª atividade] 'Anexo 5.2' deve ser preenchido.`
								);
							}

							if (!item.anexo53) {
								errorList.push(
									`${LABEL_ABA}: [${indexSu + 1}ª subunidade] - [${
										index + 1
									}ª atividade] 'Anexo 5.3' deve ser preenchido.`
								);
							}

							if (!item.anexo54) {
								errorList.push(
									`${LABEL_ABA}: [${indexSu + 1}ª subunidade] - [${
										index + 1
									}ª atividade] 'Anexo 5.4' deve ser preenchido.`
								);
							} else if (item.anexo54 === 'area' && !item.anexo54Area) {
								errorList.push(
									`${LABEL_ABA}: [${indexSu + 1}ª subunidade] - [${
										index + 1
									}ª atividade] Selecionando Anexo 5.4 tipo "Área", é necessário informá-la.`
								);
							}
						});

						if (!atividade.economias) {
							errorList.push(
								`${LABEL_ABA}: [${indexSu + 1}ª subunidade] - [${
									index + 1
								}ª atividade] 'Economias' deve ser preenchido.`
							);
						}

						if (!atividade.area) {
							errorList.push(
								`${LABEL_ABA}: [${indexSu + 1}ª subunidade] -  Área da ${index + 1}ª atividade deve ser preenchido.`
							);
						}
					});

					/*  campo EVU foi retirado
					const existeRestricaoNasAtividades = !!su.listaAtividades.find(
						atividade =>
							atividade.anexo53 === 'proibido' ||
							atividade.anexo54 === 'proibido' ||
							(atividade.anexo54 === 'area' && atividade.anexo54Area > 0)
					);

					if (existeRestricaoNasAtividades) {
						if (!aba.possuiEvuValido) {
							errorList.push(`${LABEL_ABA}: Havendo restrições nas atividades, informar se há EVU Válido`);
						} else if (aba.possuiEvuValido === 'nao') {
							errorList.push(
								`${LABEL_ABA}: Havendo restrições nas atividades, é necessária a existência de EVU válido`
							);
						}
					} */
				} else {
					errorList.push(
						`${LABEL_ABA}: [${indexSu + 1}ª subunidade] As atividades da subunidade devem ser informadas.`
					);
				}
			});
		}

		aba.errors = errorList;
		return errorList;
	};

	const validateE = aba => {
		const LABEL_ABA = 'Planilha E';
		if (size(aba) === 0) {
			return [`${LABEL_ABA}: Campos obrigatórios devem ser preenchidos`];
		}

		let errorList = [];
		aba.errors = errorList;
		return errorList;
	};

	const validate = () => {
		let errorList = [];

		const { b: abaB, c: abaC, d: abaD, e: abaE } = planilha || {};

		errorList = [...errorList, ...validateB(abaB)];
		errorList = [...errorList, ...validateC(abaC)];
		errorList = [...errorList, ...validateD(abaD)];
		errorList = [...errorList, ...validateE(abaE)];

		return errorList;
	};

	const submit = async ({ draft }) => {
		debugLog('<submit>', planilha);

		const errorList = draft ? [] : validate();
		setErrors({ submit: errorList });

		if (size(errorList) === 0) {
			setLoading(true);
			try {
				delete planilha.a.errors;
				delete planilha.b.errors;
				delete planilha.c.errors;
				delete planilha.d.errors;
				delete planilha.e.errors;

				const url = `processo/${id}/planilha-controle-registro${draft ? '?draft=true' : ''}`;
				const { data: dataApi } = await sendApi({ url, payload: planilha }, false);
				dispatch(
					actions.alteraStatusDocumento({
						documento: dataApi.documento,
						documentoDados: dataApi.documentoDados
					})
				);
				exibePlanilha(false);
				createMessage(`Planilha salva${draft ? ' como rascunho' : ''} com sucesso`, 5);
			} catch (error) {
				console.error('erro no servidor', error);
			}
			setLoading(false);
		}
	};

	const onChange = dataFromChild => {
		debugLog('<onChange>', dataFromChild);

		if (dataFromChild.a) {
			if (!isEqual(planilha.a, dataFromChild.a)) {
				debugLog('<onChange>', 'aba A alterada', dataFromChild.a);
				setPlanilha({ ...planilha, a: dataFromChild.a });
			}
		} else if (dataFromChild.b) {
			if (!isEqual(planilha.b, dataFromChild.b)) {
				debugLog('<onChange>', 'aba B alterada', dataFromChild.b);
				setPlanilha({ ...planilha, b: dataFromChild.b });
			}
		} else if (dataFromChild.c) {
			if (!isEqual(planilha.c, dataFromChild.c)) {
				debugLog('<onChange>', 'aba C alterada', dataFromChild.c);
				setPlanilha({ ...planilha, c: dataFromChild.c });
			}
		} else if (dataFromChild.d) {
			if (!isEqual(planilha.d, dataFromChild.d)) {
				debugLog('<onChange>', 'aba D alterada', dataFromChild.d);
				setPlanilha({ ...planilha, d: dataFromChild.d });
			}
		} else if (dataFromChild.e) {
			if (!isEqual(planilha.e, dataFromChild.e)) {
				debugLog('<onChange>', 'aba E alterada', dataFromChild.e);
				setPlanilha({ ...planilha, e: dataFromChild.e });
			}
		}
	};

	const planilhaSections = [
		{
			title: 'A. Dados do imóvel',
			component: AbaA_Identificacao,
			componentProps: {}
		},
		{
			title: 'B. Dados do projeto',
			component: AbaB_Atividade,
			componentProps: {}
		},
		{
			title: 'C. Áreas do terreno objeto do projeto',
			component: Aba_C_Area,
			componentProps: {}
		},
		{
			title: 'D. Regime Urbanístico',
			component: AbaD_Indices,
			componentProps: {}
		},
		{
			title: 'E. Cálculos de área do projeto arquitetônico',
			component: AbaE_Discriminacao,
			componentProps: {}
		}
	];

	return (
		<>
			<div className="container">
				<section className="content planilha">
					<div className="row">
						<div className="col-12 col-lg-10 offset-lg-1">
							{planilha && (
								<div className="accordion">
									{map(planilhaSections, (section, index) => (
										<AccordionSection
											key={index}
											title={section.title}
											component={section.component}
											componentProps={{
												...section.componentProps,
												planilha,
												formulario,
												onChange
											}}
											identificacaoAba={index}
											aba={aba}
											setAba={setAba}
										/>
									))}
								</div>
							)}
							<div className="cjto-botoes">
								<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => submit({ draft: true })}>
									Salvar Rascunho
								</button>
								<button type="button" className="btn btn-primary mt-3" onClick={() => submit({ draft: false })}>
									Salvar e gerar PDF
								</button>
							</div>
							<ErrorMessages errorList={get(errors, 'submit') || null} />
						</div>
					</div>
				</section>
			</div>
			{loading && <Loader msg="Enviando dados da planilha..." />}
		</>
	);
};
PlanilhaEdificacoesGerais.displayName = 'Planilha';
PlanilhaEdificacoesGerais.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any,
	exibePlanilha: PropTypes.func
};

export default PlanilhaEdificacoesGerais;
