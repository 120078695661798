import React, { useState, Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { get, size } from 'lodash';
import { compose } from 'redux';

import Loader from 'components/Loader';
import withMessage from 'components/WithMessage';

import actions from 'containers/Form/actions';

import useMutableState from 'custom-hooks/useMutableState';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from '../Form/reducer';
import saga from '../Form/saga';

const Tracker = () => {
	const dispatch = useDispatch();
	const [showChild, setShowChild] = useState({});

	let { idFormData } = useParams();
	let { idForm } = useParams();

	// recebe o formulario da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const formulario = useMutableState(['licenciamento', 'formulario']);
	useEffect(() => {
		if (!formulario) {
			dispatch(actions.loadFormAndData(idForm, idFormData, false));
		}
	}, [idFormData, dispatch, idForm, formulario]);

	const bpmTasks = formulario ? get(formulario, 'formData.bpmTasks') : null;

	const documentosRecebidosFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP0100');

	const documentosRecebidosAndamento = () => formulario && get(formulario, 'formData.procedimentoFormatadoSei');

	const etapaProtocoladaFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP0200');

	const etapaProtocoladaAndamento = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP0100') && !hasResultado();

	const analiseDocumentalFinalizada = () => {
		if (bpmTasks) {
			let ap500 = null;
			let l = bpmTasks.length;
			while (l--) {
				if (get(bpmTasks[l], 'taskName') === 'AP0500') {
					if (get(bpmTasks[l], 'contrato.analiseEVUContractInput')) {
						ap500 = bpmTasks[l];
					} else {
						break;
					}
				}
			}
			return (
				ap500 ||
				bpmTasks.find(t => t.taskName === 'AP1100') ||
				(bpmTasks.find(t => t.taskName === 'AP0905') && hasResultado())
			);
		} else {
			return false;
		}
	};

	const analiseDocumentalAndamento = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP0200') && !hasResultado();

	const digitalizacaoExpUnicoFinalizada = () =>
		bpmTasks.find(t => t.taskName === 'AP0800') &&
		(bpmTasks.find(t => t.taskName === 'AP0900') || size(bpmTasks.filter(t => t.taskName === 'AP0500') > 1));

	const digitalizacaoExpUnicoAndamento = () =>
		bpmTasks &&
		bpmTasks.find(t => t.taskName === 'AP0500') &&
		get(formulario, 'formData.data.dadosTriagem.formatoEu') === 'papel';

	const estudoViabilidadeUrbanisticaFinalizado = () =>
		bpmTasks && bpmTasks.find(t => t.taskName === 'EV0100') && bpmTasks.find(t => t.taskName === 'EV0600');

	const estudoViabilidadeUrbanisticaAndamento = () => {
		if (bpmTasks) {
			let ap500 = null;
			let l = bpmTasks.length;
			while (l--) {
				if (get(bpmTasks[l], 'taskName') === 'AP0500') {
					if (get(bpmTasks[l], 'contrato.analiseEVUContractInput')) {
						ap500 = bpmTasks[l];
					} else {
						break;
					}
				}
			}
			return get(ap500, 'contrato.analiseEVUContractInput');
		} else {
			return false;
		}
	};

	const distribuicaoAnaliseCondFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP1200');

	const distribuicaoAnaliseCondAndamento = () =>
		bpmTasks &&
		(bpmTasks.find(t => t.taskName === 'AP1100') || (estudoViabilidadeUrbanisticaFinalizado() && !hasResultado())) &&
		!bpmTasks.find(t => t.taskName === 'AP1200') &&
		!hasResultado();

	const analiseSecretariasFinalizada = () =>
		bpmTasks &&
		(size(bpmTasks.filter(t => t.taskName === 'AP1200')) > 1 || size(bpmTasks.find(t => t.taskName === '1510')) > 1) &&
		size(
			bpmTasks.filter(t =>
				['DM', 'CP', 'DP', 'EA', 'EP', 'ER', 'ES', 'PH', 'SM', 'UD', 'EV', 'EU', 'EC', 'EP', 'UA', 'PG'].includes(
					t.taskName.substring(0, 2)
				)
			)
		) > 0;

	const analiseSecretariasAndamento = () =>
		bpmTasks &&
		(bpmTasks.find(t => t.taskName === 'AP1200') || bpmTasks.find(t => t.taskName === '1510')) &&
		size(
			bpmTasks.filter(t =>
				['DM', 'CP', 'DP', 'EA', 'EP', 'ER', 'ES', 'PH', 'SM', 'UD', 'EV', 'EU', 'EC', 'EP', 'UA', 'PG'].includes(
					t.taskName.substring(0, 2)
				)
			)
		) > 0;

	const analiseProjArqFinalizada = () =>
		bpmTasks && bpmTasks.find(t => t.taskName === 'AP1600') && !vistoChefiaAndamento() && !aquisicaoIndiceAndamento();

	const analiseProjArqAndamento = () => {
		if (bpmTasks) {
			let ultimaAp1200 = null;
			let l = bpmTasks.length;
			while (l--) {
				if (get(bpmTasks[l], 'taskName') === 'AP1200') {
					if (
						!get(bpmTasks[l], 'contrato.duvidasContractInput') &&
						get(bpmTasks[l], 'contrato.condicoesDistribuicaoContractInput') &&
						!get(bpmTasks[l], 'contrato.evuContractInput') &&
						!get(bpmTasks[l], 'contrato.condicionantesContractInput')
					) {
						ultimaAp1200 = bpmTasks[l];
					} else {
						break;
					}
				}
			}
			return !analiseProjArqFinalizada() && ultimaAp1200;
		} else {
			return false;
		}
	};

	const vistoChefiaFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP1700');

	const vistoChefiaAndamento = () =>
		bpmTasks &&
		bpmTasks.find(t => t.taskName === 'AP1600') &&
		!bpmTasks.find(t => t.taskName === 'AP1700') &&
		get(formulario, 'formData.data.dadosAnaliseCondicionantes.vistoChefia') === 'sim';

	const aquisicaoIndiceFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP1800');

	const aquisicaoIndiceAndamento = () =>
		formulario &&
		bpmTasks &&
		!bpmTasks.find(t => t.taskName === 'AP1800') &&
		get(formulario, 'formData.data.dadosAnaliseProjetoArquitetonico.utilizacaoIndicesConstrutivos') === 'sim';

	const assinaturaDigitalFinalizada = () =>
		bpmTasks && bpmTasks.find(t => t.taskName === 'AP1920') && bpmTasks.find(t => t.taskName === 'AP1990');

	const assinaturaTecnicoFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP1920');

	const assinaturaTecnicoAndamento = () =>
		bpmTasks &&
		(bpmTasks.find(t => t.taskName === 'AP1600') ||
			bpmTasks.find(t => t.taskName === 'AP1700') ||
			bpmTasks.find(t => t.taskName === 'AP1800')) &&
		!bpmTasks.find(t => t.taskName === 'AP1920') &&
		!analiseProjArqAndamento();

	const assinaturaPrefFinalizada = () => bpmTasks && bpmTasks.find(t => t.taskName === 'AP1990');

	const assinaturaPrefAndamento = () =>
		bpmTasks && bpmTasks.find(t => t.taskName === 'AP1920') && !analiseProjArqAndamento();

	const hasResultado = () => formulario && get(formulario, 'formData.resultado');

	return formulario ? (
		<Fragment>
			<div className="container" style={{ marginBottom: '20px' }}>
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>Acompanhamento de processo</li>
					</ul>
				</div>
				<h1 className="page-header row">Acompanhamento de processo</h1>
				<br />
				Clique sobre os itens a seguir para obter mais informações. Opcionalmente, você pode
				<strong>
					<a href={get(formulario, 'formData.urlConsultaPublicaSei')} target="_blank" rel="noopener noreferrer">
						{' ver o andamento no SEI'}{' '}
						<i className="fa fa-external-link" aria-hidden="true" style={{ marginRight: '8px' }} />
					</a>
				</strong>
				<div style={{ marginTop: '8px' }}>
					As etapas estão marcadas da seguinte forma, de acordo com seu status: <br />
					<i className="fa fa-check" style={{ marginRight: '4px', color: '#09afa7', fontSize: '20px' }} /> Completadas
					<br />
					<i className="fa fa-hourglass-2" style={{ marginRight: '4px', fontSize: '20px' }} /> Em andamento
					<br />
					<i className="fa fa-fast-forward" style={{ marginRight: '4px', opacity: 0.5, fontSize: '20px' }} /> Não
					necessária neste projeto
					<br />E as etapas que ainda não foram atingidas têm o título esmaecido.
				</div>
				<h2
					className={
						documentosRecebidosFinalizada()
							? 'tracker-finalizado'
							: documentosRecebidosAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() => setShowChild(showChild['1'] ? { ...showChild, 1: false } : { ...showChild, 1: true })}
				>
					{documentosRecebidosFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					)}
					Documentos recebidos
				</h2>
				{showChild['1'] && (
					<div className="tracker-geral">
						Preenchimento do protocolo no portal de licenciamento e abertura processo SEI
					</div>
				)}
				<h2
					className={
						etapaProtocoladaFinalizada()
							? 'tracker-finalizado'
							: etapaProtocoladaAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(etapaProtocoladaFinalizada() || etapaProtocoladaAndamento()) &&
						setShowChild(showChild['2'] ? { ...showChild, 2: false } : { ...showChild, 2: true })
					}
				>
					{etapaProtocoladaFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : etapaProtocoladaAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : null}
					Etapa protocolada
				</h2>
				{showChild['2'] && (
					<div className="tracker-geral">Criação de etapa no Expediente Único e relacionamento no SEI</div>
				)}
				<h2
					className={
						analiseDocumentalFinalizada()
							? 'tracker-finalizado'
							: analiseDocumentalAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(analiseDocumentalFinalizada() || analiseDocumentalAndamento()) &&
						setShowChild(showChild['3'] ? { ...showChild, 3: false } : { ...showChild, 3: true })
					}
				>
					{analiseDocumentalFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : analiseDocumentalAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : null}
					Análise documental
				</h2>
				{showChild['3'] && (
					<div className="tracker-geral">
						Verificação da matrícula, planta de situação e outros documentos para validação do Expediente Único
						<br />
						<h3
							className={
								digitalizacaoExpUnicoFinalizada()
									? 'tracker-finalizado'
									: digitalizacaoExpUnicoAndamento()
									? 'tracker-andamento'
									: 'tracker-ausente'
							}
							onClick={() =>
								(digitalizacaoExpUnicoFinalizada() || digitalizacaoExpUnicoAndamento()) &&
								setShowChild(showChild['31'] ? { ...showChild, 31: false } : { ...showChild, 31: true })
							}
						>
							{digitalizacaoExpUnicoFinalizada() ? (
								<i className="fa fa-check" style={{ marginRight: '8px' }} />
							) : digitalizacaoExpUnicoAndamento() ? (
								<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
							) : analiseDocumentalFinalizada() ? (
								<i className="fa fa-fast-forward" style={{ marginRight: '8px' }} />
							) : null}
							Digitalização do Expediente Único
						</h3>
						{showChild['31'] && (
							<div className="tracker-geral">Digitalização dos documentos físicos do expediente único</div>
						)}
					</div>
				)}
				<h2
					className={
						estudoViabilidadeUrbanisticaFinalizado()
							? 'tracker-finalizado'
							: estudoViabilidadeUrbanisticaAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(estudoViabilidadeUrbanisticaFinalizado() || estudoViabilidadeUrbanisticaAndamento()) &&
						setShowChild(showChild['4'] ? { ...showChild, 4: false } : { ...showChild, 4: true })
					}
				>
					{estudoViabilidadeUrbanisticaFinalizado() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : estudoViabilidadeUrbanisticaAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : distribuicaoAnaliseCondAndamento() || distribuicaoAnaliseCondFinalizada() ? (
						<i className="fa fa-fast-forward" style={{ marginRight: '8px' }} />
					) : null}
					Estudo de viabilidade urbanística
				</h2>
				{showChild['4'] && <div className="tracker-geral">Análise do Estudo de Viabilidade Urbanística de 1º Grau</div>}
				<h2
					className={
						distribuicaoAnaliseCondFinalizada()
							? 'tracker-finalizado'
							: distribuicaoAnaliseCondAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(distribuicaoAnaliseCondFinalizada() || distribuicaoAnaliseCondAndamento()) &&
						setShowChild(showChild['5'] ? { ...showChild, 5: false } : { ...showChild, 5: true })
					}
				>
					{distribuicaoAnaliseCondFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : distribuicaoAnaliseCondAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : null}
					Distribuição e análise de condicionantes
				</h2>
				{showChild['5'] && (
					<div className="tracker-geral">
						Verificação da complexidade do projeto e distribuição para as chefias analisarem os condicionantes do
						projeto
					</div>
				)}
				<h2
					className={
						analiseSecretariasFinalizada()
							? 'tracker-finalizado'
							: analiseSecretariasAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(analiseSecretariasFinalizada() || analiseSecretariasAndamento()) &&
						setShowChild(showChild['6'] ? { ...showChild, 6: false } : { ...showChild, 6: true })
					}
				>
					{analiseSecretariasFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : analiseSecretariasAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : analiseProjArqAndamento() || analiseProjArqFinalizada() ? (
						<i className="fa fa-fast-forward" style={{ marginRight: '8px' }} />
					) : null}
					Análise de secretarias
				</h2>
				{showChild['6'] && (
					<div className="tracker-geral">Análise das outras secretarias em caso de condicionantes</div>
				)}
				<h2
					className={
						analiseProjArqFinalizada()
							? 'tracker-finalizado'
							: analiseProjArqAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(analiseProjArqFinalizada() || analiseProjArqAndamento()) &&
						setShowChild(showChild['7'] ? { ...showChild, 7: false } : { ...showChild, 7: true })
					}
				>
					{analiseProjArqFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : analiseProjArqAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : null}
					Análise do projeto arquitetônico
				</h2>
				{showChild['7'] && (
					<div className="tracker-geral">
						Análise do Projeto Arquitetônico pelo revisor da Unidade de Aprovação de Projetos
						<br />
						<h3
							className={
								vistoChefiaFinalizada()
									? 'tracker-finalizado'
									: vistoChefiaAndamento()
									? 'tracker-andamento'
									: 'tracker-ausente'
							}
							onClick={() =>
								(vistoChefiaFinalizada() || vistoChefiaAndamento()) &&
								setShowChild(showChild['71'] ? { ...showChild, 71: false } : { ...showChild, 71: true })
							}
						>
							{vistoChefiaFinalizada() ? (
								<i className="fa fa-check" style={{ marginRight: '8px' }} />
							) : vistoChefiaAndamento() ? (
								<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
							) : (
								<i className="fa fa-fast-forward" style={{ marginRight: '8px' }} />
							)}
							Visto chefia
						</h3>
						{showChild['71'] && (
							<div className="tracker-geral">Em caso de visto da chefia, o projeto retorna para a chefia analisar</div>
						)}
						<h3
							className={
								aquisicaoIndiceFinalizada()
									? 'tracker-finalizado'
									: aquisicaoIndiceAndamento()
									? 'tracker-andamento'
									: 'tracker-ausente'
							}
							onClick={() =>
								(aquisicaoIndiceFinalizada() || aquisicaoIndiceAndamento()) &&
								setShowChild(showChild['72'] ? { ...showChild, 72: false } : { ...showChild, 72: true })
							}
						>
							{aquisicaoIndiceFinalizada() ? (
								<i className="fa fa-check" style={{ marginRight: '8px' }} />
							) : aquisicaoIndiceAndamento() ? (
								<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
							) : (
								<i className="fa fa-fast-forward" style={{ marginRight: '8px' }} />
							)}
							Aquisição de índice construtivo
						</h3>
						{showChild['72'] && (
							<div className="tracker-geral">
								Verificação de aquisição de índice construtivo solo criado e/ou TPC (Transferência de Potencial
								Construtivo)
							</div>
						)}
					</div>
				)}
				<h2
					className={
						assinaturaDigitalFinalizada()
							? 'tracker-finalizado'
							: assinaturaPrefAndamento() || assinaturaTecnicoAndamento()
							? 'tracker-andamento'
							: 'tracker-ausente'
					}
					onClick={() =>
						(assinaturaDigitalFinalizada() || assinaturaPrefAndamento() || assinaturaTecnicoAndamento()) &&
						setShowChild(showChild['8'] ? { ...showChild, 8: false } : { ...showChild, 8: true })
					}
				>
					{assinaturaDigitalFinalizada() ? (
						<i className="fa fa-check" style={{ marginRight: '8px' }} />
					) : assinaturaPrefAndamento() || assinaturaTecnicoAndamento() ? (
						<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
					) : null}
					Assinatura digital do projeto
				</h2>
				{showChild['8'] && (
					<div className="tracker-geral">
						<h3
							className={
								assinaturaTecnicoFinalizada()
									? 'tracker-finalizado'
									: assinaturaTecnicoAndamento()
									? 'tracker-andamento'
									: 'tracker-ausente'
							}
							onClick={() =>
								(assinaturaTecnicoFinalizada() || assinaturaTecnicoAndamento()) &&
								setShowChild(showChild['81'] ? { ...showChild, 81: false } : { ...showChild, 81: true })
							}
						>
							{' '}
							{assinaturaTecnicoFinalizada() ? (
								<i className="fa fa-check" style={{ marginRight: '8px' }} />
							) : assinaturaTecnicoAndamento() ? (
								<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
							) : null}
							Assinatura pelo responsável técnico e revisão da taxa
						</h3>
						{showChild['81'] && (
							<div className="tracker-geral">Assinatura do sumário + projeto pelo Responsável Técnico</div>
						)}

						<h3
							className={
								assinaturaPrefFinalizada()
									? 'tracker-finalizado'
									: assinaturaPrefAndamento()
									? 'tracker-andamento'
									: 'tracker-ausente'
							}
							onClick={() =>
								(assinaturaPrefFinalizada() || assinaturaPrefAndamento()) &&
								setShowChild(showChild['82'] ? { ...showChild, 82: false } : { ...showChild, 82: true })
							}
						>
							{' '}
							{assinaturaPrefFinalizada() ? (
								<i className="fa fa-check" style={{ marginRight: '8px' }} />
							) : assinaturaPrefAndamento() ? (
								<i className="fa fa-hourglass-2" style={{ marginRight: '8px' }} />
							) : null}
							Assinatura da Prefeitura Municipal de Porto Alegre
						</h3>
						{showChild['82'] && (
							<div className="tracker-geral">
								Assinatura do sumário + projeto pelo revisor da Prefeitura Municipal de Porto Alegre
							</div>
						)}
					</div>
				)}
				<h2
					className={
						hasResultado() && get(formulario, 'formData.resultado') === 'deferido'
							? 'tracker-finalizado'
							: hasResultado() &&
							  (get(formulario, 'formData.resultado') === 'indeferido' ||
									get(formulario, 'formData.resultado') === 'expirado' ||
									get(formulario, 'formData.resultado') === 'recusado')
							? 'tracker-indeferido-expirado'
							: 'tracker-ausente'
					}
					onClick={() =>
						hasResultado() && setShowChild(showChild['9'] ? { ...showChild, 9: false } : { ...showChild, 9: true })
					}
				>
					{hasResultado() && <i className="fa fa-check" style={{ marginRight: '8px' }} />}
					Resultado
				</h2>
				{showChild['9'] && (
					<div className="tracker-geral">
						{hasResultado() && get(formulario, 'formData.resultado') === 'deferido' && (
							<div style={{ textAlign: 'justify' }}>
								<strong>{'Seu projeto foi aprovado!'}</strong>
								<br />
								{'Para realizar o download de seu projeto assinado digitalmente '}
								<strong>
									<a
										href={
											(get(formulario, 'documentos').find(doc => doc.idDocumento === 'planta-assinada-revisor') || {})
												.urlConsultaPublicaSei
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
									</a>
								</strong>
								{
									' ou retorne ao portal, vá até o projeto e acesse o item "5. PROTOCOLO", clique no número do processo SEI e faça o download do documento "Documento final assinado pelo revisor - v. 1.pdf'
								}
							</div>
						)}
						{hasResultado() &&
							(get(formulario, 'formData.resultado') === 'indeferido' ||
								get(formulario, 'formData.resultado') === 'recusado') && (
								<div style={{ textAlign: 'justify' }}>
									<strong>{`Seu projeto foi ${get(formulario, 'formData.resultado')}.`}</strong>
									<br />
									{'Para mais detalhes, '}
									<strong>
										<a
											href={get(formulario, 'formData.urlConsultaPublicaSei')}
											target="_blank"
											rel="noopener noreferrer"
										>
											clique aqui
											<i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: '4px' }} />
										</a>
									</strong>
									{' ou retorne ao portal e acesse as informações do projeto.'}
								</div>
							)}
					</div>
				)}
			</div>
		</Fragment>
	) : (
		<Loader />
	);
};

Tracker.displayName = 'ValidacaoPlantaAssinada';
Tracker.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

const withReducer = injectReducer({ key: 'licenciamento', reducer });
const withSaga = injectSaga({ key: 'licenciamento', saga });

export default compose(withMessage, withSaga, withReducer)(Tracker);
