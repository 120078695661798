import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import useAxios from 'axios-hooks';
import { isImmutable } from 'immutable';
import { flatMap, get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import actions from 'containers/Form/actions';
import { EXTENSAO_DOC_ESPECIFICO_HABITESE } from 'containers/Form/Detalhe';

import useErrors from 'custom-hooks/useErrors';
import useMessages from 'custom-hooks/useMessages';

import { EXPEDIENTES_API_URL } from 'environments';

import { sendApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

import { cleanRules } from './CleanRules';
import { InformacoesCondicionantes } from './InformacoesCondicionantes';
import { InformacoesEUEnderecos } from './InformacoesEUEnderecos';
import { InformacoesObraVistoria } from './InformacoesObraVistoria';
import { InformacoesProjetoLicenca } from './InformacoesProjetoLicenca';
import { InformacoesTipoVistoria } from './InformacoesTipoVistoria';
import { ValidarVistoriaHabitese } from './ValidarVistoriaHabitese';

import './DocumentoVistoria.scss';

const debug = false && isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(HABITE-SE DOC)', ++i, ...args);

function DocumentoVistoriaHabitese({ setShowFormulario, setShowConfirm }) {
	// #region hooks

	/* ESTADOS */
	const [formulario, setFormulario] = useState(null);
	const [errors, setErrors] = useErrors();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);

	/* REDUX */
	const dispatch = useDispatch();
	// recebe o formulario da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			setFormulario(isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm);
		}
	}, [formularioImm]);
	// dados se mudar o formulário
	useEffect(() => {
		if (!data) {
			const dd = obtemVistoriaDados(formulario);
			setData(dd?.dados);
		}
	}, [data, formulario]);

	/* OUTROS HOOKS */
	/* eslint-disable-next-line no-unused-vars */
	const [{ data: expediente, loading: euLoading, error: euError }, executeEu] = useAxios(
		`${EXPEDIENTES_API_URL}/eu/${get(formulario, 'formData.data.expediente._id', '0')}`
	);
	const formRef = useRef();
	const { createMessage } = useMessages();
	// #endregion

	const onChangeHandler = useCallback(
		changes => {
			debugLog('changes---', changes);
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}

			const { name, value } = changes;
			if (name === 'errors') {
				return;
			}
			setData({ ...data, [name]: value });
			setErrors({});
		},
		[data, setErrors]
	);

	const submit = async operacao => {
		const { draft, forceSave } = operacao;
		let [ok, validacao] = [true];

		if (!forceSave) {
			[ok, validacao] = await ValidarVistoriaHabitese({ dados: data, formulario });
		}

		if (forceSave && draft) {
			salvar({ draft, erros: errors });
		} else {
			if (!ok) {
				setErrors(validacao);
			} else {
				salvar({ draft });
			}
		}
	};

	const salvar = async params => {
		const { draft, erros, closeAfterSave = true } = params;
		try {
			setLoading(true);
			const formId = get(formulario, 'formData.id');
			const url = `processo/${formId}/${EXTENSAO_DOC_ESPECIFICO_HABITESE}${draft ? '?draft=true' : ''}`;
			const newData = await cleanRules({ ...data }, dispatch, formulario);
			const { data: dataResponse } = await sendApi({ url, payload: { form: newData, observacoes: erros } }, false);
			dispatch(
				actions.alteraStatusDocumento({
					documento: dataResponse.documento,
					documentoDados: dataResponse.documentoDados
				})
			);
			createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 5);
		} catch (error) {
			createMessage('Problemas no salvamento do documento... verifique a console', 3);
			console.error('erro no servidor', error);
		} finally {
			setLoading(false);
			setShowFormulario(!closeAfterSave);
		}
	};

	const hasErros = size(errors) > 0;

	return (
		<>
			{loading && <Loader />}
			<div className="form-vistoria-especifico doc-vistoria" ref={formRef}>
				<div className="vistoria-card">
					<InformacoesEUEnderecos
						expediente={get(formulario, 'formData.data.expediente')}
						enderecos={get(formulario, 'formData.data.enderecoCdlList')}
					/>
				</div>
				<div className="vistoria-card">
					<InformacoesProjetoLicenca
						expediente={expediente}
						data={data}
						onChange={onChangeHandler}
						tipoSolicitacao={data?.tipoSolicitacao}
						containerRef={formRef.current}
					/>
				</div>
				<div className="vistoria-card">
					<InformacoesTipoVistoria
						expediente={expediente}
						data={data}
						onChange={onChangeHandler}
						tipoSolicitacao={data?.tipoSolicitacao}
					/>
				</div>
				<div className="vistoria-card">
					<InformacoesObraVistoria
						expediente={expediente}
						data={data}
						onChange={onChangeHandler}
						tipoSolicitacao={data?.tipoSolicitacao}
						containerRef={formRef.current}
					/>
				</div>
				<div className="vistoria-card">
					<InformacoesCondicionantes
						expediente={expediente}
						data={data}
						onChange={onChangeHandler}
						tipoSolicitacao={data?.tipoSolicitacao}
						salvar={salvar}
					/>
				</div>
				<div className="cjto-botoes">
					<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => setShowConfirm(true)}>
						Cancelar
					</button>
					<button
						type="button"
						className="btn btn-secondary mt-3 mr-2"
						onClick={() => submit({ draft: true, forceSave: hasErros })}
					>
						{hasErros ? 'Ignorar e Salvar Rascunho' : 'Salvar Rascunho'}
					</button>
					<button
						type="button"
						className="btn btn-primary mt-3"
						onClick={() => submit({ draft: false, forceSave: hasErros })}
						disabled={hasErros}
					>
						Salvar e gerar PDF
					</button>
				</div>
				{hasErros && (
					<div className="vistoria-card erros">
						<h3 className="font-weight-bold text-danger m-3">Existem erros no formulário</h3>
						<ErrorMessages errorList={flatMap(errors, v => v)} />
					</div>
				)}
			</div>
		</>
	);
}

DocumentoVistoriaHabitese.displayName = 'DocumentoVistoriaHabitese';
DocumentoVistoriaHabitese.propTypes = {
	setShowFormulario: PropTypes.func,
	setShowConfirm: PropTypes.func,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool
};
export default DocumentoVistoriaHabitese;

export function obtemVistoriaDados(formulario) {
	const { documentos, documentosDados } = formulario || {};
	const documento = (documentos || []).reduce((acc, doc) => {
		if (doc.extensao === EXTENSAO_DOC_ESPECIFICO_HABITESE) {
			if (acc) {
				return doc.versao > acc.versao ? doc : acc;
			} else {
				return doc;
			}
		}
		return acc;
	}, null);
	const docDados = (documentosDados || []).find(dd => dd?.id === documento?.id || dd?.id === documento.original);
	return docDados;
}

export const METAVISTORIA = {
	TIPOS_SOLICITACAO: {
		projeto: {
			codigo: '1',
			descricao: 'Projeto aprovado e licenciado  (Aprovação de Projeto Arquitetônico ou Licenciamento Expresso)',
			exceptCodigosOcorrencias: ['1236', '2020', '2043']
		},
		// licencaEdilicio: {
		// 	codigo: '2',
		// 	descricao: 'Licenciamento edilício expresso por meio de declaração de responsabilidade técnica',
		// 	codigosOcorrencias: ['1236', '2020', '2043']
		// },
		licencaHoraExpressa: { codigo: '3', descricao: 'Licença urbanística' }
	},
	TIPOS_VISTORIA: {
		total: { codigo: 'total', descricao: 'Total' },
		parcial: { codigo: 'parcial', descricao: 'Parcial' },
		parcialFinal: { codigo: 'parcial_final', descricao: 'Parcial Final' }
	},
	tipoSolicitacao: { name: 'tipoSolicitacao', required: true, label: 'Qual é a solicitação?' },
	projetoOuLicenca: {
		name: 'projetoOuLicenca',
		required: true,
		label: 'Selecione o projeto/licença',
		specialValidate: true
	},
	dataProjetoOuLicenca: {
		name: 'dataProjetoOuLicenca',
		required: true,
		specialValidate: true,
		label: 'Data do projeto/licença'
	},
	numeroLicenca: { name: 'numeroLicenca', required: false, label: 'Número da licença' },
	numeroPranchas: { name: 'numeroPranchas', required: true, label: 'Número de pranchas?' },
	descricaoPranchas: {
		name: 'descricaoPranchas',
		required: true,
		label: 'Informar numeração das pranchas',
		specialValidate: true
	},
	qtdBoxes: { name: 'qtdBoxes', required: true, label: 'Informar a quantidade de boxes', specialValidate: true },
	existemBoxes: { name: 'existemBoxes', required: true, label: 'Existem boxes privativos no projeto?' },
	qtdPavimentos: { name: 'qtdPavimentos', required: true, label: 'Número de pavimentos' },
	tipoConstrucao: { name: 'tipoConstrucao', label: 'Qual é o material?', specialValidate: true },
	outroMaterial: { name: 'outroMaterial', required: true, label: 'Outro material', specialValidate: true },
	dataInicioObra: { name: 'dataInicioObra', required: true, label: 'Data de início da obra' },
	dataFinalObra: { name: 'dataFinalObra', label: 'Data final da obra' },
	imovelOcupado: { name: 'imovelOcupado', required: true, label: 'O imóvel está ocupado?' },
	apenasUmaDataOcupacao: {
		name: 'apenasUmaDataOcupacao',
		required: true,
		label: 'Possui apenas uma data de ocupação?',
		specialValidate: true
	},
	dataOcupacaoObra: {
		name: 'dataOcupacaoObra',
		required: true,
		label: 'Data de ocupação da obra',
		specialValidate: true
	},
	descricaoOcupacaoEconomias: {
		name: 'descricaoOcupacaoEconomias',
		required: true,
		label: 'Descrever ocupação por economias',
		specialValidate: true,
		placeholder: 'Informar da seguinte forma: 01/2019- ap 201, 03/2019 - ap 300 demais desocupados'
	},
	informacoesObra: { name: 'informacoesObra', isFieldList: true, specialValidate: true },
	enderecoObra: { name: 'enderecosObra', required: true, specialValidate: true },
	atividade: { name: 'atividade', required: true, label: 'Atividade', specialValidate: true },
	qtdEconomias: { name: 'qtdEconomias', required: true, label: 'Quantas economias?', specialValidate: true },
	listaEconomias: { name: 'listaEconomias', required: false, specialValidate: true },
	infoObraEconomiaInicial: {
		name: 'economiaInicial',
		required: true,
		label: 'Economia inícial',
		specialValidate: true
	},
	infoObraEconomiaFinal: { name: 'economiaFinal', required: true, label: 'Economia final', specialValidate: true },
	tipoVistoria: { name: 'tipoVistoria', required: true, label: 'Selecione o tipo de vistoria' },
	metragemTotal: { name: 'metragem', required: true, label: 'Metragem total (m²)', specialValidate: true },
	areaCondominal: { name: 'areaCondominal', required: true, label: 'Área condominal (m²)', specialValidate: true },
	areaPrivativa: { name: 'areaPrivativa', required: true, label: 'Área privativa (m²)', specialValidate: true },
	areaParcialTotal: {
		name: 'areaParcialTotal',
		required: true,
		label: 'Área total da parcial (m²)',
		specialValidate: true
	},
	economiaUnica: { name: 'economiaUnica', required: true, label: 'Economia única?', specialValidate: true },
	possuiCondicionantes: {
		name: 'possuiCondicionantes',
		required: true,
		label: 'O projeto aprovado e licenciado possui condicionantes?'
	},
	condicionantesVistoria: { name: 'condicionantesVistoria', isFieldList: true },
	tramiteCondicionante: { name: 'tramiteCondicionante', label: 'Trâmite do condicionante', specialValidate: true },
	tramiteProcessoSei: { name: 'tramiteProcessoSei', label: 'Número processo SEI', specialValidate: true },
	infoObraObservacoes: {
		name: 'observacoes',
		required: false,
		label: 'Observações (opcional)'
	}
};
