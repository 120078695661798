import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';
import moment from 'moment';

import { isDebug } from 'utils/tools';

import DocumentosSlots from './DocumentosSlots';

const Documentos = ({
	formulario,
	documentos: documentosProps,
	documentosDados,
	emComplementacao,
	motivos,
	errors,
	onDrop,
	removeFile,
	retiraDocumento,
	criarNovoDocumento,
	complementarProcesso,
	avancar,
	criarMultiDocumentos,
	updateResposta,
	// taskName,
	usuarioDonoTask
}) => {
	const documentos = useMemo(() => {
		documentosProps.forEach(doc => {
			doc.newDoc = doc.newDoc || (doc.ordem % 100 !== 0 && size(doc.idDocumentoSei) === 0);
		});
		return [...documentosProps];
	}, [documentosProps]);

	const documentosOriginaisObrigatorios = documentos.filter(d => !d.newDoc && d.obrigatorio);

	const naoRespondidas = (formulario?.formData?.extraInfo || []).filter(info => info.naoRespondida);

	const semSei = !formulario?.formData?.procedimentoFormatadoSei;
	const semResultado = size(formulario?.formData?.resultado) === 0;
	const rascunhoDocsComplementacao = !!formulario?.formData?.rascunhoDocsComplementacao;
	const naoExpirado = !formulario?.formData?.expirado;
	const disponivel = semResultado && naoExpirado && (emComplementacao || semSei) && usuarioDonoTask;

	const emProcessamento = documento => !documento.idDocumentoSei && !!documento.originalName;

	const prontoParaComplementar =
		semResultado &&
		naoExpirado &&
		!rascunhoDocsComplementacao &&
		(size(motivos) > 0 ||
			(get(formulario, 'formData.idProcedimentoSei') &&
				documentos.reduce((acc, d) => (acc = acc || emProcessamento(d)), false)) ||
			size(naoRespondidas) > 0);
	return (
		<>
			<div className="form-group form-docs">
				<legend>Documentos {isDebug && <span className="debug-message">[COMPLEMENTAÇÃO NÃO É BPM]</span>}</legend>

				{size(documentosOriginaisObrigatorios) === 0 ? (
					<div className="field-description">
						<br />
						<b>Não há documentos obrigatórios para serem anexados;</b>
						{disponivel && (
							<span>
								<br />
								<b>Obs:</b> Mesmo assim, podem ser adicionados documentos para subsidiar a análise.
							</span>
						)}
					</div>
				) : (
					<div className="field-description">
						<br />
						Documentos marcados com asterisco (<span style={{ color: 'red' }}>*</span>) são obrigatórios
					</div>
				)}
				{emComplementacao && (
					<div className="col alert warning">
						<h3>
							Complementação de documentos e/ou informações <i className="fa fa-exclamation-circle" />
						</h3>
						<p>Verifique se há documentos invalidados ou se foi solicitado informações referente ao seu processo.</p>
						{formulario?.formData?.expirado ? (
							<p>
								{`Você pode ver os campos com as observações sobre o motivo pelo qual o documento foi invalidado e, em
								caso de informações solicitadas, verificar o solicitado. Mas como seu processo expirou em
								${moment(formulario?.formData?.dataComparecimento).format('DD/MM/YYYY')}, não é possível efetivamente complementá-lo.`}
							</p>
						) : (
							<p>
								Se um documento foi invalidado, veja os campos com as observações sobre o motivo pelo qual o documento
								foi invalidado e anexe o documento conforme. Em caso de informações solicitadas, verifique o solicitado
								e esclareça a dúvida.
							</p>
						)}
					</div>
				)}
				<DocumentosSlots
					formulario={formulario}
					documentos={documentos}
					documentosDados={documentosDados}
					perguntas={naoRespondidas}
					disponivel={disponivel}
					motivos={motivos}
					errors={errors}
					usuarioDonoTask={usuarioDonoTask}
					prontoParaComplementar={prontoParaComplementar}
					criarMultiDocumentos={criarMultiDocumentos}
					emProcessamento={emProcessamento}
					onDrop={onDrop}
					removeFile={removeFile}
					retiraDocumento={retiraDocumento}
					criarNovoDocumento={criarNovoDocumento}
					updateResposta={updateResposta}
					complementarProcesso={complementarProcesso}
					avancar={avancar}
					emComplementacao={emComplementacao}
				/>
			</div>
		</>
	);
};
Documentos.displayName = 'AbaDocumentos';
Documentos.propTypes = {
	formulario: PropTypes.object,
	documentos: PropTypes.array,
	documentosDados: PropTypes.array,
	emComplementacao: PropTypes.bool,
	motivos: PropTypes.object,
	errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
	onDrop: PropTypes.func,
	removeFile: PropTypes.func,
	retiraDocumento: PropTypes.func,
	criarNovoDocumento: PropTypes.func,
	complementarProcesso: PropTypes.func,
	avancar: PropTypes.func,
	criarMultiDocumentos: PropTypes.func,
	updateResposta: PropTypes.func,
	taskName: PropTypes.string,
	usuarioDonoTask: PropTypes.bool
};

export default Documentos;
