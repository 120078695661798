import React, { useState, useEffect } from 'react';
import { useRef } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, size, trim } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import IntlNumberInput from 'components/react-intl-number-input';

import actions from 'containers/Form/actions';

import { Economia } from './ABA_E_StyledComponents';
import { getDescricao } from './constants';

const EconomiaComponent = ({
	economia,
	tipoPlanilha,
	novoPavimento,
	editLabelEconomia,
	removeEconomia,
	updateLabelPavimento,
	removePavimento,
	onChange
}) => {
	/* REDUX */
	const dispatch = useDispatch();

	// recebe o errors da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const [errors, setErrors] = useState(null);
	const errorsImm = useSelector(state => state.getIn(['licenciamento', 'errors']));
	useEffect(() => {
		if (errorsImm) {
			setErrors(isImmutable(errorsImm) ? errorsImm.toJS() : errorsImm);
		}
	}, [errorsImm]);

	const corpo = useRef();

	/* ESTADOS */
	const [incluindoPavimento, setIncluindoPavimento] = useState(false);
	const [pavimentoEmEdicao, setPavimentoEmEdicao] = useState(null);
	const [labelPavimento, setLabelPavimento] = useState(null);

	const incluirPavimento = () => {
		if (size(trim(labelPavimento)) === 0) {
			dispatch(actions.setErrors({ labelNovoPavimento: ['Nome do pavimento é obirgatório'] }));
		} else {
			const labelUtilizado = ((economia || {}).pavimentos || []).reduce(
				(acc, pavimento) => acc || trim(get(pavimento, 'label')) === trim(labelPavimento),
				false
			);
			if (labelUtilizado) {
				dispatch(
					actions.setErrors({
						labelNovoPavimento: ['Este nome já está sendo usado para outro pavimento desta economia']
					})
				);
			} else {
				novoPavimento(economia.id, labelPavimento);
				ocultarFormularioPavimento();
			}
		}
		return true;
	};

	const tratarTeclas = e => {
		if (e.key === 'Enter') {
			return incluindoPavimento ? incluirPavimento(e) : editarPavimento(economia.id);
		} else {
			if (e.key === 'Escape') {
				return ocultarFormularioPavimento(e);
			}
		}
		return true;
	};

	const ocultarFormularioPavimento = () => {
		setLabelPavimento(null);
		setIncluindoPavimento(false);
		setPavimentoEmEdicao(null);
		if (size(errors) > 0) {
			dispatch(actions.setErrors({}));
		}
		return true;
	};

	const editLabelPavimento = codigoPavimento => {
		const pavimento = economia.pavimentos.find(e => e.codigo === codigoPavimento);
		setPavimentoEmEdicao(codigoPavimento);
		setLabelPavimento(pavimento.label);
	};

	const editarPavimento = () => {
		if (size(trim(labelPavimento)) === 0) {
			dispatch(actions.setErrors({ labelNovoPavimento: ['Nome do pavimento é obirgatório'] }));
		} else {
			const labelUtilizado = ((economia || {}).pavimentos || [])
				.filter(p => p.codigo !== pavimentoEmEdicao)
				.reduce((acc, pavimento) => acc || trim(get(pavimento, 'label')) === trim(labelPavimento), false);
			if (labelUtilizado) {
				dispatch(
					actions.setErrors({
						labelNovoPavimento: ['Este nome já está sendo usado para outro pavimento desta economia']
					})
				);
			} else {
				updateLabelPavimento(economia.id, pavimentoEmEdicao, labelPavimento);
				ocultarFormularioPavimento();
			}
		}
		return true;
	};

	const { label, pavimentos = [], tiposConstrucao = [] } = economia;
	return (
		<Economia>
			<div className="cjto-botoes">
				<button
					type="button"
					className="btn btn-link btn-planilhas btn-planilhas-p"
					onClick={() => setIncluindoPavimento(true)}
				>
					novo pavimento
				</button>
			</div>

			{(incluindoPavimento || pavimentoEmEdicao) && (
				<div style={{ display: 'flex', marginBottom: '15px' }}>
					<div className="formGroup" style={{ flex: 1 }}>
						<label className="control-label" htmlFor="root_nome">
							{incluindoPavimento ? 'Escolha o nome do novo pavimento' : 'Escolha o novo nome do pavimento'}
						</label>
						<input
							className={size(errors) > 0 ? 'form-control-error' : 'form-control'}
							type="text"
							value={labelPavimento || ''}
							onChange={e => setLabelPavimento(e.target.value)}
							onKeyDown={tratarTeclas}
							placeholder={incluindoPavimento ? 'Informe o nome do novo pavimento' : 'Informe o novo nome do pavimento'}
						/>
						<ErrorMessages errorList={get(errors, 'labelNovoPavimento')} />
					</div>
					<div className="inline-buttons">
						<button
							type="button"
							className={'btn btn-primary'}
							onClick={incluindoPavimento ? incluirPavimento : editarPavimento}
						>
							Concluir
						</button>
						<button type="button" className={'btn btn-secondary'} onClick={ocultarFormularioPavimento}>
							Cancelar
						</button>
					</div>
				</div>
			)}

			<div className="title">
				<p>{label}</p>
				<div style={{ float: 'right' }}>
					<button type="button" className="btn-link" onClick={() => editLabelEconomia(economia.id)}>
						<i className="fa fa-pencil-square-o" style={{ fontSize: '18px', color: 'white' }} />
					</button>
					<button type="button" className="btn-link" onClick={() => removeEconomia(economia.id)}>
						<i className="fa fa-trash-o" style={{ fontSize: '18px', color: 'white' }} />
					</button>
				</div>
			</div>

			<div className="corpo" ref={corpo}>
				<div className="header">
					<div className="void">Tipos Construção</div>
					<div className="areas">
						{pavimentos.map(pav => (
							<div key={pav.codigo} className="area">
								<p>{pav.label}</p>
								<div>
									<button type="button" className="btn-link" onClick={() => editLabelPavimento(pav.codigo)}>
										<i className="fa fa-pencil-square-o" style={{ fontSize: '18px' }} />
									</button>
									<button type="button" className="btn-link" onClick={() => removePavimento(economia.id, pav.codigo)}>
										<i className="fa fa-trash-o" style={{ fontSize: '18px' }} />
									</button>
								</div>
							</div>
						))}
					</div>
					<div className="total-areas">TOTAL</div>
				</div>

				{tiposConstrucao
					.filter(tipoConstrucao => tipoConstrucao.enabled)
					.map(tipoConstrucao => {
						const { codigo: codigoTipoConstrucao, grupos = [] } = tipoConstrucao;
						return (
							<div key={tipoConstrucao.codigo} className="container-tipo-construcao">
								<div className="tiposConstrucao">
									<div className="tipo-construcao">{getDescricao('tiposConstrucao', codigoTipoConstrucao)}</div>
									<div className="resto-tipo-construcao">
										<div className="grupos">
											{grupos.map(grupo => {
												const { codigo: codigoGrupo, tiposArea } = grupo;
												return (
													<div key={codigoGrupo} className="grupo">
														<div className="titulo-grupo">{getDescricao('grupos', codigoGrupo, tipoPlanilha)}</div>
														<div className="resto-grupo">
															<div className="tipos-areas">
																{tiposArea.map(tipoArea => {
																	const { codigo: codigoArea, pavimentos } = tipoArea;
																	return (
																		<div key={codigoArea} className="area">
																			<div className="titulo-area">{getDescricao('tiposArea', codigoArea)}</div>
																			<div className="areas">
																				{pavimentos.map((pavimento, indPavimento) => {
																					const { codigo: codigoPavimento, area } = pavimento;
																					return (
																						<IntlNumberInput
																							key={codigoPavimento}
																							type="text"
																							className="area"
																							locale="pt-BR"
																							precision={2}
																							suffix=" m²"
																							value={area}
																							onFocus={e =>
																								(corpo.current.scrollLeft = e.target.clientWidth * indPavimento)
																							}
																							onChange={(event, value) =>
																								onChange(
																									tipoPlanilha,
																									economia.id,
																									codigoTipoConstrucao,
																									codigoGrupo,
																									codigoArea,
																									codigoPavimento,
																									value
																								)
																							}
																						/>
																					);
																					// return <input key={codigoPavimento} defaultValue={area} />;
																				})}
																			</div>
																			<div className="total-areas">
																				<IntlNumberInput
																					type="text"
																					className="area"
																					locale="pt-BR"
																					suffix=" m²"
																					precision={2}
																					value={tipoArea.total || 0}
																					disabled={true}
																					keepZero={true}
																				/>
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						);
					})}

				<div className="footer">
					<div className="void">TOTAL PAVIMENTOS</div>
					<div className="areas">
						{economia.pavimentos.map(pavimento => (
							<div key={pavimento.codigo} className="area">
								<IntlNumberInput
									type="text"
									className="area"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={pavimento.total || 0}
									disabled={true}
									keepZero={true}
								/>
							</div>
						))}
					</div>
					<div className="total-areas">
						<IntlNumberInput
							type="text"
							className="area"
							locale="pt-BR"
							suffix=" m²"
							precision={2}
							value={economia.total}
							disabled={true}
							keepZero={true}
						/>
					</div>
				</div>
			</div>
		</Economia>
	);
};
EconomiaComponent.displayName = 'Economia';
EconomiaComponent.propTypes = {
	economia: PropTypes.object,
	tipoPlanilha: PropTypes.string,
	novoPavimento: PropTypes.func,
	editLabelEconomia: PropTypes.func,
	removeEconomia: PropTypes.func,
	updateLabelPavimento: PropTypes.func,
	removePavimento: PropTypes.func,
	onChange: PropTypes.func
};

export default EconomiaComponent;
