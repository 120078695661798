import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import Selecao from 'components/Selecao';

export const TIPOS_ETAPA_ENQUADRAMENTO = {
	aprovacao: ' de aprovação',
	evu: 'de EVU',
	indeferimento: 'de indeferimento',
	projetoAprovado: 'de projeto Aprovado',
	etapaAcompanhamento: 'para acompanhamento',
	reconsideracao: 'para reconsideração',
	habitese: 'de vistoria para obtenção do habite-se'
};

const SelecaoEtapa = ({ tipoEtapa, etapas, selected, readOnly, onChange }) => {
	const [termo, setTermo] = useState('');
	return (
		<Fragment key={tipoEtapa}>
			<label className="label-enquadramento">
				Você deve selecionar a etapa{' '}
				{TIPOS_ETAPA_ENQUADRAMENTO[tipoEtapa] ? TIPOS_ETAPA_ENQUADRAMENTO[tipoEtapa] : tipoEtapa}
			</label>
			<Selecao
				multiple={false}
				selected={size(selected) > 0 ? selected : null}
				detailCodigo={''}
				detailDescricao={'display'}
				autoShowList={true}
				searchTerm={termo}
				searchList={etapas.filter(
					e =>
						JSON.stringify(e)
							.toLowerCase()
							.indexOf((termo || '').toLowerCase()) > -1
				)}
				searchTermMinLength={0}
				errorList={false}
				onChangeSearchTerm={event => setTermo(event.target.value)}
				onBlurSearchTerm={() => false}
				onSelectItem={item => () => onChange(item)}
				onUnselect={() => () => onChange(null)}
				loading={false}
				required={true}
				placeholder={'Selecione a etapa'}
				readOnly={readOnly}
			/>
		</Fragment>
	);
};
SelecaoEtapa.displayName = 'SelecaoEtapa';
SelecaoEtapa.propTypes = {
	tipoEtapa: PropTypes.string,
	selected: PropTypes.object,
	etapas: PropTypes.arrayOf(PropTypes.object),
	readOnly: PropTypes.bool,
	onChange: PropTypes.func
};

export default SelecaoEtapa;
