import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect, ReactReduxContext } from 'react-redux';

import axios from 'axios';
import { get, reduce, isObject, some, isArray, map } from 'lodash';
import queryString from 'query-string';
import { createStructuredSelector, createSelector } from 'reselect';

import { API_URL, EXPEDIENTES_API_URL } from 'environments';

import { store } from 'index';

const IGNORE_PROPERTIES_REGEX = [/^(__)/i];

// TODO: resolver o caso de arrays
export const cleanTypeName = obj =>
	isArray(obj)
		? map(obj, o => (typeof o === 'string' || typeof o === 'number' ? o : cleanTypeName(o)))
		: reduce(
				obj,
				(acc, v, k) =>
					// eslint-disable-next-line no-nested-ternary
					isObject(v)
						? { ...acc, [k]: cleanTypeName(v) }
						: some(IGNORE_PROPERTIES_REGEX, r => r.test(k))
						? acc
						: { ...acc, [k]: v },
				{}
		  );
/**
			Duas funções possiveis:
			1 para os métodos do eventHandler, onde o fluxo no
				Node-Red é determinado pela action.type, o action é um objeto como no exemplo:
				{
					type: 'formulario/SERVER_SAVE_FORM_DATA',
					payload: { ... },
					next: 'REFRESH_PAGE'
				},
				true // opcional

			2	para os métodos de API externa, onde o fluxo do Node-Red é determinado pela
				URL do nodo Http-In, dois parametros são necessários, como no exemplo:
				{
					url: 'processo/${this.props.match.params.id}/planilha-unifamiliar',
					payload: { ... }
				},
				false // para indicar que não é método do eventHandler
*/
export const send = async (action, timeout = 0) => {
	let token;
	if (get(store, 'kc.authenticated')) {
		const result = await new Promise(resolve =>
			store.kc
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${store.kc.token}`;
		} else {
			store.kc.logout();
		}
	}

	let url = `${API_URL}/eventhandler`;

	if (action.payload.queryString) {
		const queryStringObj = { ...action.payload.queryString };
		const qs = queryString.stringify(queryStringObj);
		url = `${url}?${qs}`;
		delete action.payload.queryString;
	}

	const options = {
		method: 'post',
		url: url,
		data: cleanTypeName(action),
		headers: token ? { Authorization: token } : {},
		timeout: timeout
	};
	return axios(options);
};

export const sendApi = async action => {
	let token;
	if (get(store, 'kc.authenticated')) {
		const result = await new Promise(resolve =>
			store.kc
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${store.kc.token}`;
		} else {
			store.kc.logout();
		}
	}

	return axios({
		method: 'post',
		url: `${API_URL}/${action.url}`,
		data: action.payload,
		headers: token ? { Authorization: token } : {}
	});
};

export const accessApi = async (endpoint, publicApi = false, options = {}) => {
	let token;
	if (!publicApi && get(store, 'kc.authenticated')) {
		const result = await new Promise(resolve =>
			store.kc
				.updateToken(5)
				.then(() => resolve(true))
				.catch(() => resolve(false))
		);
		if (result) {
			token = `Bearer ${store.kc.token}`;
		} else {
			store.kc.logout();
		}
	}
	let url = null;

	if (endpoint.startsWith('http')) {
		url = endpoint;
	} else if (endpoint.startsWith('EXPEDIENTES_API_URL')) {
		url = endpoint.replace('EXPEDIENTES_API_URL', EXPEDIENTES_API_URL);
	} else {
		url = endpoint;

		if (url.startsWith('/')) {
			url = url.substring(1);
		}

		if (url.startsWith('api/')) {
			url = url.substring(4);
		}
		url = `${API_URL}/${url}`;
	}
	if (!options?.method) {
		options = options || {};
		options.method = 'get';
	}
	console.debug('accessApi - url: ', url);
	return axios({
		url,
		headers: token ? { Authorization: token } : {},
		...options
	});
};

export default mapSendToProps => Component => {
	class ApiProvider extends PureComponent {
		static displayName = 'ApiProvider';

		static contextType = ReactReduxContext;

		static propTypes = {
			dispatch: PropTypes.func
		};

		send = async action => {
			try {
				// const result = await new Promise(resolve =>
				// 	this.context.store.kc
				// 		.updateToken(5)
				// 		.then(() => resolve(true))
				// 		.catch(() => resolve(false))
				// );

				// if (result) {
				const { data } = await send(action); // , `Bearer ${this.context.store.kc.token}`
				if (data.ok === 1) {
					if (data.next) {
						this.props.dispatch(data.next);
					} else {
						this.props.dispatch({
							type: `${action.type}_SUCCESS`,
							payload: data.data
						});
					}
				} else {
					this.props.dispatch({
						type: `${action.type}_FAIL`,
						payload: data
					});
				}
				// } else {
				// 	this.context.store.kc.logout();
				// }
			} catch (error) {
				this.props.dispatch({
					type: `${action.type}_FAIL`,
					payload: error.message
				});
			}
		};

		render() {
			return <Component {...this.props} {...mapSendToProps(this.send)} />;
		}
	}
	const selectLoginDomain = state => state.get('login');

	const mapStateToProps = createStructuredSelector({
		token: createSelector(selectLoginDomain, login => (login ? login.get('token') : ''))
	});

	const mapDispatchToProps = dispatch => ({
		dispatch
	});

	return connect(mapStateToProps, mapDispatchToProps)(ApiProvider);
};
