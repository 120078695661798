import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { SelectField } from 'containers/Form/metadata-template/fields';

import { METAVISTORIA } from '.';
import { DetalhesInformacoesProjeto } from './DetalhesInformacoesProjeto';

export function InformacoesProjetoLicenca(props) {
	const { data = {}, onChange } = props;

	const optionsTipoSol = useMemo(
		() =>
			Object.keys(METAVISTORIA.TIPOS_SOLICITACAO).map(mtsk => {
				const tp = METAVISTORIA.TIPOS_SOLICITACAO[mtsk];
				return { codigo: tp.codigo, descricao: tp.descricao };
			}),
		[]
	);

	return (
		<fieldset>
			<legend>Informações Projeto/Licença</legend>
			<div className="pergunta">
				<SelectField
					name={METAVISTORIA.tipoSolicitacao.name}
					label={METAVISTORIA.tipoSolicitacao.label}
					options={optionsTipoSol}
					required={METAVISTORIA.tipoSolicitacao.required}
					onChangeHandler={onChange}
					value={data[METAVISTORIA.tipoSolicitacao.name]}
				/>
			</div>
			<DetalhesInformacoesProjeto {...props} tipoSolicitacao={data[METAVISTORIA.tipoSolicitacao.name]} />
		</fieldset>
	);
}
InformacoesProjetoLicenca.displayName = 'InformacoesProjetoLicenca';
InformacoesProjetoLicenca.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func
};
