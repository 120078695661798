import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import gql from 'graphql-tag';
import { size, trim, get } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';
import Selecao from 'components/Selecao';

import { REGEX_NUMBERS_ONLY } from 'utils/constants';
import apolloClient from 'utils/graphql';
import { accessApi } from 'utils/injectApi';
import { normalizedAddress, permiteInput, montaURL } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class EnderecosCDL extends React.Component {
	static displayName = 'EnderecosCDL';

	static propTypes = {
		formData: PropTypes.array,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);
		const options = get(this.props, 'uiSchema.ui:options') || {};
		let latlong = !!options.latlong;
		let enderecoUnico = !!options.enderecoUnico;
		this.state = {
			formData: get(props, 'formData'),
			className: CLASS_NAME_FORM_CONTROL,
			enderecos: [],
			latlong,
			enderecoUnico
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let enderecos = nextProps.formData ? nextProps.formData.filter(e => !!e) : [];
		return { ...prevState, enderecos };
	}

	setLogradouro = e => {
		const term = e.target.value;
		this.setState(() => ({ logradouro: term }));
		this.buscaCDL(term);
		// this.props.onChange([{ logradouro: term }]);
	};

	setCDL = (cdl, setShowList) => () => {
		this.setState(() => ({
			codigoCDL: cdl.id,
			logradouro: cdl.nomeLogradouro,
			numero: this.state.numero,
			logradourosPesquisados: [],
			cdl: {
				id: cdl.id,
				nomeLogradouro: cdl.nomeLogradouro
			}
		}));
		setShowList(false);
	};

	unsetCDL = () => () => {
		this.setState(() => ({ logradouro: '', cdl: undefined, codigoCDL: undefined }));
	};

	setNumero = e => {
		const numero = e.target.value;
		if (permiteInput(numero, 'int')) {
			this.setState(() => ({ numero }));
		}
	};

	buscaCDL = async logradouro => {
		try {
			const queryCDL = gql`
				query CDL($term: String) {
					CdlEnderecoList(term: $term) {
						id
						nomeLogradouro
						enderecoFormatadoCurto
					}
				}
			`;
			if (size(logradouro) > 2) {
				this.setState({ loading: true });
				const {
					data: { CdlEnderecoList }
				} = await apolloClient.query({
					query: queryCDL,
					variables: { term: logradouro },
					fetchPolicy: 'network-only'
				});
				this.setState({ logradourosPesquisados: CdlEnderecoList, loading: false });
			} else {
				this.setState({ logradourosPesquisados: [], loading: false });
			}
		} catch (e) {
			this.setState({ logradourosPesquisados: [], loading: false });
			console.error('erro no servidor', e);
			// yield put(push('/serverUnavailable'));
		}
	};

	isValidLogradouro = () =>
		size(this.state.logradouro) === 0 || size(trim(normalizedAddress(this.state.logradouro))) > 0;
	isValidNumero = () => size(this.state.numero) === 0 || REGEX_NUMBERS_ONLY.text(this.state.numero);

	extractErrors = attr => get(attr, '__errors') || [];

	addLogradouro = async () => {
		if (this.state.codigoCDL && this.state.numero) {
			let enderecos = this.state.enderecos || [];

			const aux = enderecos.filter(e => e.codigoCDL === this.state.codigoCDL && e.numero === this.state.numero);
			if (size(aux) === 0) {
				enderecos.push({
					codigoCDL: this.state.codigoCDL,
					logradouro: this.state.logradouro,
					numero: this.state.numero
				});
				if (this.state.latlong) {
					enderecos = await this.addLatlong(enderecos, this.state.codigoCDL, this.state.numero);
				}
				this.setState(() => ({
					enderecos,
					codigoCDL: undefined,
					logradouro: undefined,
					numero: undefined,
					cdl: undefined
				}));
				this.props.onChange(enderecos);
			} else {
				this.setState(() => ({
					codigoCDL: undefined,
					logradouro: undefined,
					numero: undefined,
					cdl: undefined
				}));
				alert('Endereço já adicionado à lista');
			}
		} else {
			alert('Selecione um logradouro da lista e informe o número');
		}
	};

	addLatlong = async (enderecos, codigo, numero) => {
		const result = await accessApi(
			`https://cdlrest.procempa.com.br/cdlrest/rest/query/endereco/${codigo}?numero=${numero}&latlong=true`,
			true
		);
		const endereco = result.data;

		if (endereco) {
			return enderecos.map(e =>
				e.codigoCDL === codigo && e.numero === numero
					? { ...e, latitude: endereco.latitude, longitude: endereco.longitude }
					: e
			);
		}
		return enderecos;
	};

	removeLogradouro = (codigoCDL, numero) => () => {
		let enderecos = this.state.enderecos || [];
		enderecos = enderecos.filter(e => e.codigoCDL !== codigoCDL || e.numero !== numero);
		this.setState(() => ({
			enderecos,
			codigoCDL: undefined,
			logradouro: undefined,
			numero: undefined,
			cdl: undefined
		}));
		this.props.onChange(enderecos);
	};

	formatLatlong = e => {
		/*
		"latitude": -30.04746164024034,
		"longitude": -51.21506910168151
		*/
		const { latitude, longitude } = e;
		const separa = s => s.split('.');
		const prepara = s => (s.length > 4 ? s.substring(0, 4) : s);
		const junta = s => s.join(',');

		const lat = junta(separa(`${latitude}`).map(prepara));
		const lng = junta(separa(`${longitude}`).map(prepara));

		return `${lat} x ${lng}`;
	};

	render() {
		const requireds = this.props.schema.required || [];
		const requiredLogradouro = requireds.indexOf('logradouro') > -1;
		const requiredNumero = requireds.indexOf('numero') > -1;
		const {
			codigoCDL: errorCodigoCDL,
			logradouro: errorLogradouro,
			numero: errorNumero
			// enderecosCDL: errorEnderecosCDL
		} = this.props.errorSchema;
		const missingCDL = this.extractErrors(errorCodigoCDL) > 0 && this.extractErrors(errorLogradouro) === 0;
		const {
			cdl,
			numero,
			codigoCDL,
			logradourosPesquisados,
			logradouro,
			loading,
			latlong,
			enderecoUnico = false
		} = this.state;
		const { disabled, readonly } = this.props;

		const enderecoUnicoPreenchido = this.state.enderecos.length > 0 && enderecoUnico;
		return (
			<Fragment>
				{!(readonly || disabled) && (
					<Fragment>
						<div className="form-group  col-md-7 align-top">
							<label className="control-label hideable" htmlFor="root_nome">
								Logradouro
								{requiredLogradouro ? '*' : ''}
							</label>
							<Selecao
								className={`hideable ${
									this.isValidLogradouro()
										? `${CLASS_NAME_FORM_CONTROL}`
										: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								}`}
								multiple={false}
								selected={cdl}
								label={'Logradouro'}
								detailInnerClassName={'inner-list-item inner-list-item-input'}
								detailCodigo={''}
								detailDescricao={'nomeLogradouro'}
								autoShowList={false}
								searchTerm={logradouro}
								searchList={logradourosPesquisados}
								searchTermMinLength={3}
								errorList={false}
								onChangeSearchTerm={this.setLogradouro}
								onBlurSearchTerm={() => false}
								onSelectItem={this.setCDL}
								onUnselect={this.unsetCDL}
								noResetList={true}
								loading={loading}
								required={requiredLogradouro}
								placeholder="Informe o logradouro e selecione da lista"
								readOnly={disabled || readonly || enderecoUnicoPreenchido}
							/>
							<ErrorDetail attr={errorLogradouro} />
							<input type="hidden" value={codigoCDL || ''} />
							{missingCDL && <ErrorDetail attr={errorCodigoCDL} />}
							<div />
						</div>
						<div className="form-group  col-md-3 hideable align-top">
							<label className="control-label" htmlFor="root_nome">
								Número
								{requiredNumero ? '*' : ''}
							</label>
							<div />
							<input
								readOnly={disabled || readonly}
								className={
									this.isValidLogradouro()
										? `${CLASS_NAME_FORM_CONTROL}`
										: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								}
								type="text"
								value={numero || ''}
								onChange={this.setNumero}
								placeholder="apenas números"
								disabled={enderecoUnicoPreenchido}
							/>
							<ErrorDetail attr={errorNumero} />
							<div />
						</div>
						<div className="form-group  col-md-2 hideable" style={{ verticalAlign: 'bottom', paddingBottom: '2px' }}>
							<button
								type="button"
								className="btn btn-primary"
								style={{ width: '100%', display: 'block', marginLeft: 0 }}
								onClick={this.addLogradouro}
								disabled={enderecoUnicoPreenchido}
							>
								{/* <i className="fa fa-plus" style={{ fontSize: '18px' }} /> */}
								incluir
							</button>
						</div>
					</Fragment>
				)}
				<div className="col-md-12">
					<ul className="list-blocks">
						{size(this.state.enderecos) > 0 ? (
							this.state.enderecos.map(e => (
								<li className="list-item" key={`${e.codigoCDL}-${e.numero}`}>
									<div className="list-item-block" style={{ display: 'block' }}>
										{<i className={'fa fa-map-marker'} aria-hidden="true" />}
										&nbsp;
										{/* {`${e.logradouro}, ${e.numero} [${e.codigoCDL}]`} */}
										{`${e.logradouro}, ${e.numero}`}
										{latlong ? (
											<>
												{' '}
												(
												<a
													href={`https://maps.google.com/?q=${e.latitude},${e.longitude}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													{this.formatLatlong(e)}
												</a>
												)
											</>
										) : (
											''
										)}
										{!(readonly || disabled) && (
											<button
												type="button"
												className="btn-link hideable"
												style={{ cursor: 'pointer', float: 'right' }}
												onClick={this.removeLogradouro(e.codigoCDL, e.numero)}
												disabled={disabled || readonly}
											>
												<i className="fa fa-trash-o" style={{ fontSize: '18px' }} />
											</button>
										)}
										<a
											style={{ fontWeight: 'normal', float: 'right', fontSize: '14px', top: '2px' }}
											href={encodeURI(`${montaURL('expedientes')}/consultapublica?q=${e.logradouro}, ${e.numero}`)}
											target="_blank"
											rel="noopener noreferrer"
										>
											consultar o Expediente Único
										</a>
									</div>
								</li>
							))
						) : (
							<>
								<li className="list-item">
									<div className="list-item-block" style={{ color: 'grey' }}>
										{enderecoUnico ? 'Cadastre um endereço.' : 'Cadastre um ou mais endereços.'}
									</div>
								</li>
								<ErrorDetail attr={errorCodigoCDL} />
							</>
						)}
					</ul>
				</div>
			</Fragment>
		);
	}
}

export default EnderecosCDL;
