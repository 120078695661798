import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { NumberField, SelectField } from 'containers/Form/metadata-template/fields';

import { montaURL } from 'utils/tools';

import { DetalhesVistoriaParcial } from './DetalhesVistoriaParcial';
import { METAVISTORIA } from './index';

export function InformacoesTipoVistoria(props) {
	const { data = {}, onChange } = props;

	const tiposVistoria = useMemo(
		() =>
			Object.keys(METAVISTORIA.TIPOS_VISTORIA).map(tk => {
				const { codigo, descricao } = METAVISTORIA.TIPOS_VISTORIA[tk];
				return { codigo, descricao };
			}),
		[]
	);

	const [formTouched, setFormTouched] = useState(false);

	const projeto = get(data, `${METAVISTORIA.projetoOuLicenca.name}`);

	useEffect(() => {
		setFormTouched(false);
	}, [projeto]);

	const isVistoriaTotal = METAVISTORIA.TIPOS_VISTORIA.total.codigo === data[METAVISTORIA.tipoVistoria.name];
	const isVistoriaParcial = METAVISTORIA.TIPOS_VISTORIA.parcial.codigo === data[METAVISTORIA.tipoVistoria.name];
	const isVistoriaParcialFinal =
		METAVISTORIA.TIPOS_VISTORIA.parcialFinal.codigo === data[METAVISTORIA.tipoVistoria.name];

	if (data[METAVISTORIA.tipoSolicitacao.name]) {
		if (!formTouched && isVistoriaTotal) {
			let somaAreaTotalAConstruir = 0;

			// soma todas as áreas totais a construir do Projeto de Edificação selecionado
			if (size(get(projeto, 'detalhamentos')) > 0) {
				get(projeto, 'detalhamentos').forEach(detalhamento => {
					if (get(detalhamento, 'areaTotalAConstruir')) {
						somaAreaTotalAConstruir += parseFloat(get(detalhamento, 'areaTotalAConstruir'));
					}
				});
			}
			if (Number.isInteger(somaAreaTotalAConstruir)) somaAreaTotalAConstruir = somaAreaTotalAConstruir.toFixed(2);
			let with2Decimals = somaAreaTotalAConstruir.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
			data[METAVISTORIA.metragemTotal.name] = { value: with2Decimals };
		}
	}

	return (
		<fieldset>
			<legend>Tipo de Vistoria</legend>
			<div className="pergunta">
				<SelectField
					label={METAVISTORIA.tipoVistoria.label}
					name={METAVISTORIA.tipoVistoria.name}
					required={METAVISTORIA.tipoVistoria.required}
					options={tiposVistoria}
					onChangeHandler={onChange}
					value={data[METAVISTORIA.tipoVistoria.name]}
				/>
			</div>
			{isVistoriaTotal && (
				<div className="pergunta metragem">
					<div className="d-flex">
						<NumberField
							label={METAVISTORIA.metragemTotal.label}
							name={METAVISTORIA.metragemTotal.name}
							onChangeHandler={changes => {
								setFormTouched(true);
								onChange(changes);
							}}
							required={METAVISTORIA.metragemTotal.required}
							decimals={2}
							maxSize={'10'}
							value={data[METAVISTORIA.metragemTotal.name]}
						/>
					</div>
				</div>
			)}
			{(isVistoriaParcial || isVistoriaParcialFinal) && <DetalhesVistoriaParcial {...props} />}
			{(isVistoriaTotal || isVistoriaParcial || isVistoriaParcialFinal) && (
				<div className="pergunta">
					<a
						href={`${montaURL('expedientes')}/consultapublica${
							get(props, 'expediente._id') ? `/${get(props, 'expediente._id')}` : ''
						}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Consulta de dados no Expedientes{' '}
						<i style={{ marginLeft: '0.2em' }} className="fa fa-external-link" aria-hidden="true"></i>
					</a>
				</div>
			)}
		</fieldset>
	);
}
InformacoesTipoVistoria.displayName = 'InformacoesTipoVistoria';
InformacoesTipoVistoria.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func
};
