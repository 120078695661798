import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';

const ErrorDetail = props => {
	const { attr } = props;
	const errors = get(attr, '__errors') || [];
	return (
		<div>
			{size(errors) > 0 && (
				<Fragment>
					<p />
					<ul className="error-detail bs-callout bs-callout-info">
						{errors.map((e, index) => (
							<li key={index} className="text-danger">
								{e}
							</li>
						))}
					</ul>
				</Fragment>
			)}
		</div>
	);
};
ErrorDetail.displayName = 'ErrorDetail';

ErrorDetail.propTypes = {
	attr: PropTypes.object
};

export default ErrorDetail;
