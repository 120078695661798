import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import Selecao from 'components/Selecao';

export default function SelecaoField({ label, required, name, value, readOnly, multiple, options, onChangeHandler }) {
	const [searchTerm, setSearchTerm] = useState('');

	function onSelect(name, item) {
		let auxValue = null;
		if (multiple) {
			auxValue = [...value];
			auxValue.push(item);
		} else {
			auxValue = item;
		}

		onChangeHandler({ name, value: auxValue });
		onChangeHandler({ name: 'errors', value: { [name]: validate(auxValue, required, label) } });
	}

	function onRemove(name, item) {
		let auxValue = null;
		if (multiple) {
			auxValue = value.filter(i => i.codigo !== item.codigo);
		}

		onChangeHandler({ name, value: auxValue });
	}

	return (
		<>
			<label className="control-label" htmlFor={`id_${name}`}>
				{label}
				{required && <span className="required">*</span>}
			</label>

			<Selecao
				className={'form-control'}
				multiple={multiple}
				selected={value}
				label={'Bairros'}
				readOnly={readOnly}
				detailCodigo={''}
				detailDescricao={'descricao'}
				autoShowList={true}
				searchTerm={searchTerm}
				searchList={options
					.filter(item => item.descricao.toLowerCase().includes(searchTerm.toLowerCase()))
					.filter(item => (multiple ? !(value || []).map(v => v.codigo).includes(item.codigo) : true))}
				searchTermMinLength={3}
				errorList={false}
				onChangeSearchTerm={event => setSearchTerm(event.target.value)}
				onBlurSearchTerm={() => false}
				onSelectItem={item => () => onSelect(name, item)}
				onUnselect={item => () => onRemove(name, item)}
				loading={false}
				required={true}
				placeholder="Selecione o bairro"
			/>
		</>
	);
}

SelecaoField.displayName = 'SelecaoField';

SelecaoField.propTypes = {
	label: PropTypes.string,
	required: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	multiple: PropTypes.bool,
	readOnly: PropTypes.bool
};

function validate(value, required, label) {
	let errors = [];
	if (required) {
		if (Array.isArray(value) ? size(value) === 0 : !value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
}
