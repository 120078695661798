import React, { useState, Fragment, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useQuery } from 'react-apollo';
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router';

import gql from 'graphql-tag';
import moment from 'moment';

import EditorView from 'components/EditorView';
import Loader from 'components/Loader';
import withMessage from 'components/WithMessage';

const PAGE_SIZE = 1000;

const Novidades = () => {
	const [panel, setPanel] = useState();
	let {
		data,
		loading: loadingData,
		error
	} = useQuery(
		gql`
			query novidadeList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoNovidadeList(term: $term, skip: $skip, limit: $limit) {
					id
					titulo
					descricao
					dataAtualizacao
					tipoNovidade
				}
				count: LicenciamentoNovidadeCount(term: $term)
			}
		`,
		{
			variables: { limit: PAGE_SIZE },
			fetchPolicy: 'network-only'
		}
	);

	useEffect(() => {
		setPanel(false);
	}, []);

	const togglePanel = useCallback(
		name => {
			if (panel === name) {
				setPanel(null);
			} else {
				setPanel(name);
			}
		},
		[panel]
	);

	return (
		<Fragment>
			<div className="container">
				<div className="subhead">
					<ul className="breadcrumb">
						<li>
							<a href="/">Início</a>
						</li>
						<li>Novidades</li>
					</ul>
				</div>
				<h1 className="page-header row">Novidades</h1>
				{data &&
					data.list
						.filter(o => o.tipoNovidade === 'portal' || o.tipoNovidade === 'ambos')
						.sort((o1, o2) => (o1.dataAtualizacao > o2.dataAtualizacao ? -1 : 1))
						.map(novidade => {
							const showBody = panel === novidade.id;
							return (
								<Card key={novidade.id} className={`${showBody ? '' : 'card-borderless'}`}>
									<Card.Header
										className={`${showBody ? 'card-header-selecionado' : ''}`}
										onClick={() => togglePanel(novidade.id)}
									>
										<div name="header" className="container-header">
											<div className="flex justify-content-between">
												<i
													className={panel === novidade.id ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
													aria-hidden="true"
													style={{ padding: '0 0 0 0.5rem' }}
												></i>
												<span style={{ padding: '0 0 0 1.5rem' }}>{novidade.titulo}</span>
												<span style={{ padding: '0 0 0 1.5rem', float: 'right' }}>
													{moment(novidade.dataAtualizacao).format('DD/MM/YYYY')}
												</span>
											</div>
										</div>
									</Card.Header>
									{showBody && (
										<Card.Body>
											<div className="acordeon-collapse">
												<EditorView state={novidade.descricao} hideClass={true} />
											</div>
										</Card.Body>
									)}
								</Card>
							);
						})}
			</div>
			{error ? <Redirect to={'/serverUnavailable'} /> : loadingData && <Loader />}
		</Fragment>
	);
};

Novidades.displayName = 'Novidades';
Novidades.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
	location: PropTypes.any
};

export default withMessage(Novidades);
