import React from 'react';

import PropTypes from 'prop-types';

import TimePicker from 'rc-time-picker';

import 'rc-time-picker/assets/index.css';

import { getMomentDate } from 'utils/tools';

export function TimePickerField(props) {
	const { onChange, value: oriValue, disabled } = props;
	const value = getMomentDate(oriValue);

	const clearIcon = (
		<i
			style={{
				fontSize: '12px',
				fontStyle: 'normal',
				color: '#aaa',
				lineHeight: '1',
				transition: 'color 0.3s ease',
				position: 'absolute',
				width: '24px',
				right: 0,
				top: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
			className="fa fa-times"
		/>
	);
	return (
		<TimePicker
			placeholder="HH:mm"
			showSecond={false}
			defaultOpenValue={getMomentDate()}
			style={{ width: 100, position: 'relative' }}
			format="HH:mm"
			clearIcon={clearIcon}
			value={value}
			onChange={onChange}
			disabled={disabled}
		/>
	);
}

TimePickerField.displayName = 'TimePickerField';
TimePickerField.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
	disabled: PropTypes.bool
};
export default TimePickerField;
