import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { flatMap, get, size } from 'lodash';

// import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { accessApi } from 'utils/injectApi';
// import { soma } from 'utils/tools';

import TemplateForm from '../../metadata-template/TemplateForm';

/* eslint-disable react-hooks/exhaustive-deps */
const EventosForm = props => {
	const { data: propsData, onSubmit, readOnly: readOnlyView, tipoForm } = props;
	// state sobre o form
	const [dadosFormulario, setDadosFormulario] = useState({});
	// state com os fields do form
	const [formFieldsets, setFormFieldsets] = useState([]);
	// extras states
	const [errorObj, setErrorsObj] = useState({});
	const [loading, setLoading] = useState(true);
	// effects
	useEffect(() => {
		setLoading(true);
		carregaMetadata()
			.then(() => {
				if (propsData) {
					setDadosFormulario({ ...propsData });
				}
			})
			.finally(() => setLoading(false));
	}, []);

	// load metadados do form
	const carregaMetadata = async () => {
		if (size(formFieldsets) === 0) {
			try {
				const retorno = (await accessApi(`/collections/metadata/${tipoForm}`))?.data;
				const solicitacaoEventosFieldsets = get(retorno, 'fieldsets') || [];
				setFormFieldsets(solicitacaoEventosFieldsets);
			} catch (e) {
				setFormFieldsets([]);
				console.error('Erro no acesso aos metadados do formulário: ', e);
			}
		}
	};

	// form handler
	const handleChangeFormulario = onChangeParams => {
		const { fieldSetName, key, value } = onChangeParams;

		setErrorsObj(undefined);

		if (fieldSetName === 'estruturaEvento') {
			let tempState;

			if (key === 'amplificador' || key === 'caixasDeSom') {
				const infoSom = dadosFormulario.infoSom || [];
				key === 'amplificador' ? (infoSom[0] = value) : (infoSom[1] = value);
				tempState = Object.assign({}, { infoSom }, { [key]: value });
			} else if (key === 'equipamentosOutros') {
				tempState = { equipamentosOutros: [...value] };
			} else {
				tempState = { [key]: value };
			}
			const novoState = Object.assign({}, dadosFormulario, tempState);
			// limpeza
			if (value === 'nao') {
				delete novoState[`${key}Qtd`];
				delete novoState[`${key}M2`];
				delete novoState[`${key}Descricao`];
			}

			// calculo total de area
			/*const m2Lista = Object.keys(novoState)
				.filter(k => k.endsWith('M2'))
				.filter(m2k => isFinite(novoState[m2k].value))
				.map(m2k => novoState[m2k].value || '0')
				.reduce((p, c) => soma(p, c, 0), '0');

			const m2Outros = flatMap(novoState.equipamentosOutros, veo =>
				Object.keys(veo)
					.filter(k => k.startsWith('outroEqpM2'))
					.filter(m2k => isFinite(veo[m2k].value))
					.map(m2k => veo[m2k].value || '0')
			).reduce((p, c) => soma(p, c, 0), '0');

			novoState.metrosQuadradosTotal = { value: soma(m2Lista, m2Outros, 0) };
			*/
			setDadosFormulario({ ...novoState });
		} else if (key === 'datasEvento') {
			const tempState = { datasEvento: [...value] };
			const publicos = flatMap(tempState.datasEvento, dts =>
				Object.keys(dts)
					.filter(k => k.startsWith('publicoPrevisto'))
					.filter(kps => isFinite(dts[kps].value))
					.map(kps => parseInt(dts[kps].value, 10) || 0)
			);
			tempState.maxPublico = `${Math.max(...publicos)}`;
			const novoState = { ...dadosFormulario, ...tempState };
			setDadosFormulario(novoState);
		} else {
			setDadosFormulario(Object.assign({}, dadosFormulario, { [key]: value }));
		}
	};

	const handleSubmit = ([ok, , ce]) => {
		if (ok) {
			onSubmit(dadosFormulario);
		} else {
			setErrorsObj(ce);
		}
	};

	const readOnly = readOnlyView === true;
	const disable = readOnlyView === true;

	return (
		<>
			{loading ? (
				<Loader msg="Carregando metadados do formulário" />
			) : (
				<>
					<TemplateForm
						fieldsets={formFieldsets}
						onChangeHandler={handleChangeFormulario}
						onSubmit={handleSubmit}
						formData={dadosFormulario}
						readOnly={readOnly}
						disabled={disable}
						customErrors={errorObj}
					></TemplateForm>
					{/* <ErrorMessages errorList={errorList}></ErrorMessages> */}
				</>
			)}
		</>
	);
};
EventosForm.displayName = 'EventosForm';

EventosForm.propTypes = {
	data: PropTypes.any,
	tipoForm: PropTypes.string,
	onSubmit: PropTypes.func,
	readOnly: PropTypes.bool
};

export default EventosForm;
