import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

const CheckboxField = ({
	label,
	required,
	name,
	value,
	options,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler
}) => {
	const onCheckItem = (e, codigo) => {
		const { checked } = e.target;
		let valor;
		if (options.length === 1) {
			valor = checked;
		} else {
			valor = (value || []).filter(v => v !== codigo);
			if (checked) {
				valor.push(codigo);
			}
		}

		onChangeHandler({ name, value: valor });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo) } });
	};
	return (
		<div className={`${containerClass}`}>
			{label && (
				<div className="col-md-12">
					<label className="control-label">
						{label}
						{required && <span className="required">*</span>}
					</label>
				</div>
			)}
			{options.map((o, i) => {
				let codigo = typeof o === 'object' ? o.codigo : o;
				const descricao = typeof o === 'object' ? o.descricao : o;

				const checked = Array.isArray(value)
					? !!(value || []).find(v => (typeof v === 'object' ? v.codigo === codigo : v === codigo))
					: !!value;

				return (
					<div className="form-check col-md-3" key={i} style={{ whiteSpace: 'nowrap' }}>
						<label>
							<input
								type="checkbox"
								name={name}
								onClick={e => onCheckItem(e, codigo)}
								readOnly={readOnly}
								disabled={disabled}
								onChange={() => false}
								checked={checked}
							/>{' '}
							<span>{descricao}</span>
						</label>
					</div>
				);
			})}
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
CheckboxField.displayName = 'CheckboxField';

CheckboxField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default CheckboxField;

export const validate = () => {
	let errors = [];
	return errors;
};
