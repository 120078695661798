import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { NumberField } from 'containers/Form/metadata-template/fields';

export function AreaSubfield({
	fieldName,
	placeholder,
	label,
	required,
	onChangeHandler,
	value,
	errors,
	readOnly,
	disabled
}) {
	return (
		<div className="pergunta metragem">
			<NumberField
				label={label}
				placeHolder={
					placeholder
						? placeholder === 'L'
							? 'Volume (L)'
							: placeholder === 'Wh'
							? 'Geração estimada (kWh/ano)'
							: 'Metragem (m²)'
						: 'Metragem (m²)'
				}
				containerClass="input-group-lic"
				name={fieldName}
				onChangeHandler={onChangeHandler}
				value={value}
				decimals={2}
				maxSize="10"
				styleLabel={{ fontSize: '0.9rem' }}
				required={required}
				readOnly={readOnly}
				disabled={disabled}
			/>
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
AreaSubfield.displayName = 'LicencasExpressasAreaSubfield';
AreaSubfield.propTypes = {
	fieldName: PropTypes.string,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	localData: PropTypes.object,
	errors: PropTypes.arrayOf(PropTypes.string),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool
};
