import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import DescriptionField from 'react-jsonschema-form/lib/components/fields/DescriptionField';

import { size } from 'lodash';
import moment from 'moment';

import Calendario /*, { disabledPast }*/ from 'components/Calendario';

import { EMAIL_REGEX, REGEX_TELEFONE } from 'utils/constants';
import {
	isCPF,
	isCNPJ,
	formataTelefone,
	format,
	titleCase,
	isDam,
	isNumeroExpediente,
	permiteInput,
	formatFloat
} from 'utils/tools';
const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const widgetPropTypes = {
	autofocus: PropTypes.bool,
	disabled: PropTypes.bool,
	formContext: PropTypes.object,
	id: PropTypes.string,
	label: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	options: PropTypes.object,
	placeholder: PropTypes.string,
	rawErrors: PropTypes.any,
	readonly: PropTypes.bool,
	registry: PropTypes.object,
	required: PropTypes.bool,
	schema: PropTypes.object,
	value: PropTypes.any
};

class CopyButton extends Component {
	static displayName = 'CopyButton';
	static propTypes = widgetPropTypes;

	render() {
		const { schema, id, value, required, disabled, readonly, label, autofocus, onChange } = this.props;
		return !(readonly || disabled) ? (
			<div className={`copy-button ${disabled || readonly ? 'disabled' : ''}`}>
				{schema.description && <DescriptionField description={schema.description} />}
				<label>
					<input
						className="input-hidden"
						type="checkbox"
						id={id}
						checked={typeof value === 'undefined' ? false : value}
						required={required}
						disabled={disabled || readonly}
						autoFocus={autofocus}
						onChange={event => onChange(event.target.checked)}
					/>
					<span>{label}</span>
				</label>
			</div>
		) : null;
	}
}

class CpfCnpj extends Component {
	static displayName = 'CnpjCpf';
	static propTypes = widgetPropTypes;

	static defaultProps = {
		options: {
			cpf: true,
			cnpj: true
		}
	};

	constructor(props) {
		super(props);
		const { cpf, cnpj } = props.options;
		const placeholder = `Informe ${(!cpf && !cnpj) || (cpf && cnpj) ? 'CPF ou CNPJ' : `${cpf ? 'CPF' : 'CNPJ'}`}`;
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: '',
			valid: true,
			placeholder
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			const { cpf, cnpj } = nextProps.options;
			let value = nextProps.value;
			let valid = false;

			if (cnpj) {
				// o campo pode ser cpf/cnpj também
				// atualiza valor para não permitir mais de 14 caracteres para o CNPJ
				if (value && value.length > 14) {
					value = value.substr(0, 14);
				}
			} else if (cpf) {
				// atualiza valor para não permitir mais de 11 caracteres para o CPF
				if (value && value.length > 11) {
					value = value.substr(0, 11);
				}
			}
			if (value && cpf && isCPF(value)) {
				value = format(value, '000.000.000-00');
				valid = true;
			}
			if (value && cnpj && isCNPJ(value)) {
				value = format(value, '00.000.000/0000-00');
				valid = true;
			}
			return { value, valid: valid || size(value) === 0 };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.replace(/[^0-9]/g, '');
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.state.valid ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder={this.state.placeholder}
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class Email extends Component {
	static displayName = 'Email';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.toLowerCase();
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {};

	isValid = () => size(this.state.value) === 0 || EMAIL_REGEX.test(this.state.value);

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder="Informe um email válido"
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class Telefone extends Component {
	static displayName = 'Telefone';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = formataTelefone(value);
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {};

	isValid = () => size(this.state.value) === 0 || REGEX_TELEFONE.test(this.state.value);

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder={disabled || readonly ? '' : '(XX) XXXX-XXXX'}
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class Nome extends Component {
	static displayName = 'Nome';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value.replace(/ {2}/g, ' ');
		value = titleCase(value);
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {};

	isValid = () =>
		size(this.state.value) === 0 || (size(this.state.value) > 2 && this.state.value.trim().split(' ').length > 1);

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					placeholder="Informe nome e sobrenome"
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class NumeroART extends Component {
	static displayName = 'NumeroART';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.toLowerCase();
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {};

	isValid = () =>
		size(this.state.value) === 0 || (size(this.state.value) > 2 && this.state.value.trim().split(' ').length > 1);

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						CLASS_NAME_FORM_CONTROL
						//this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					placeholder="Informe o número"
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class PrevisaoTermino extends Component {
	static displayName = 'PrevisaoTermino';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = size(e) === 0 ? '' : moment.isMoment(e) ? e.toDate().getTime() : moment.utc(e).toDate().getTime();
		return this.props.onChange(value);
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {};

	isValid = () =>
		size(this.state.value) === 0 || (size(this.state.value) > 2 && this.state.value.trim().split(' ').length > 1);

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Calendario
				id={this.props.id}
				inputClass={
					CLASS_NAME_FORM_CONTROL
					//this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
				}
				disabled={disabled || readonly}
				value={this.state.value || ''}
				onChange={this.onChangeHandler}
				onFocus={this.onFocusHandler}
				onBlur={this.onBlurHandler}
				//disabledDate={disabledPast}
			/>
		);
	}
}

class NumeroDam extends Component {
	static displayName = 'NumeroDam';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		const placeholder = 'Informe o número da DAM sem formatação';
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: '',
			valid: true,
			placeholder
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			let value = nextProps.value;
			let valid = false;

			if (isDam(value)) {
				value = format(value, '0000.00.000000.000-0');
				valid = true;
			}
			return { value, valid: valid || size(value) === 0 };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.replace(/[^0-9]/g, '');
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.state.valid ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder={this.state.placeholder}
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class NumeroExpediente extends Component {
	static displayName = 'NumeroExpediente';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		const placeholder = 'Informe o número do Expediente Único sem formatação';
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: '',
			valid: true,
			placeholder
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			let value = nextProps.value;
			let valid = false;

			if (isNumeroExpediente(value)) {
				value = format(value, '000.000000.00.0');
				valid = true;
			}
			return { value, valid: valid || size(value) === 0 };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.replace(/[^0-9]/g, '');
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.state.valid ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder={this.state.placeholder}
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class AreaPrivativaExpediente extends Component {
	static displayName = 'AreaPrivativaExpediente';

	static propTypes = widgetPropTypes;

	constructor(props) {
		super(props);
		const placeholder = 'Informe a Área Privativa do Expediente';
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: '',
			valid: true,
			placeholder
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			let value = nextProps.value;
			let valid = false;

			//if (isAreaPrivativaExpediente(value)) {
			//	value = format(value, '00000');
			valid = true;
			//}
			return { value, valid: valid || size(value) === 0 };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let value = e.target.value;
		value = value.replace(/[^0-9]/g, '');
		value = size(value) === 0 ? undefined : value;
		return this.props.onChange(value);
	};

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.state.valid ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					required=""
					placeholder={this.state.placeholder}
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

class ValorNumerico extends Component {
	static displayName = 'ValorNumerico';

	static propTypes = { ...widgetPropTypes, decimals: PropTypes.number, maxSize: PropTypes.number };

	static defaultProps = {
		options: {
			decimals: 2,
			maxSize: 7
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			className: CLASS_NAME_FORM_CONTROL,
			value: props.value || ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.value !== nextProps.value) {
			let value = nextProps.value;
			return { value };
		}
		return prevState;
	}

	onChangeHandler = e => {
		let { value } = e.target;
		value = (value || '').replace(/[^0-9,]/g, '');
		const { decimals, maxSize } = this.props;
		if (permiteInput(value, 'currency', decimals || 2, maxSize || 7)) {
			this.props.onChange(value);
		}
		return true;
	};

	onFocusHandler = () => {};

	onBlurHandler = () => {
		this.format();
	};

	componentDidMount() {
		this.format();
	}

	format = () => {
		if (this.state.value) {
			const formatado = formatFloat(this.state.value, this.props.decimals, true);
			this.props.onChange(formatado);
		}
	};

	isValid = () => true;

	render() {
		const { disabled, readonly } = this.props;
		return (
			<Fragment>
				<input
					className={
						this.isValid() ? CLASS_NAME_FORM_CONTROL : `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
					}
					id={this.props.id}
					placeholder="Informe valor"
					type="text"
					onChange={this.onChangeHandler}
					onFocus={this.onFocusHandler}
					onBlur={this.onBlurHandler}
					value={this.state.value || ''}
					disabled={disabled || readonly}
				/>
			</Fragment>
		);
	}
}

export default {
	customCopyButton: CopyButton,
	customCpfCnpj: CpfCnpj,
	customEmail: Email,
	customNome: Nome,
	customTelefone: Telefone,
	previsaoTermino: PrevisaoTermino,
	numART: NumeroART,
	numeroDam: NumeroDam,
	numeroExpediente: NumeroExpediente,
	areaPrivativaExpediente: AreaPrivativaExpediente,
	valorNumerico: ValorNumerico
};
