import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, get } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';

import { TIPO_PROPRIETARIO_OUTROS } from 'utils/constants';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class TipoProprietario extends React.Component {
	static displayName = 'TipoProprietario';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			tipo: props.formData.tipo || '',
			outros: props.formData.outros || '',
			valid: true
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return prevState;
	}

	tipoOutros = () => this.state.tipo === TIPO_PROPRIETARIO_OUTROS;

	onTipoChange = e => {
		let tipo = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutros()) {
			if (size(this.state.outros) === 0) {
				valid = false;
			}
		}
		tipo = size(tipo) === 0 ? undefined : tipo;
		this.setState(() => {
			this.retornaValor(tipo, this.state.outros);
			return { tipo, valid };
		});
	};

	onOutrosChange = e => {
		let outros = get(e, 'target.value') || '';
		let valid = true;
		if (this.tipoOutros()) {
			if (size(outros) === 0) {
				valid = false;
			}
		}
		outros = size(outros) === 0 ? undefined : outros;
		this.setState(() => {
			this.retornaValor(this.state.tipo, outros);
			return { outros, valid };
		});
	};

	retornaValor = (tipo, outros) => {
		let retorno = { tipo: tipo, outros: outros };
		this.props.onChange(retorno);
	};

	render() {
		const requireds = this.props.schema.required || [];
		const requiredTipo = requireds.indexOf('tipo') > -1;
		const { tipo: errorTipo, outros: errorOutros } = this.props.errorSchema;
		const {
			disabled,
			readonly,
			schema: { options }
		} = this.props;
		return (
			<Fragment>
				{
					<Fragment>
						<div className={`form-group  col-md-${this.tipoOutros() ? '6' : '12'}`} style={{ whiteSpace: 'nowrap' }}>
							<label className="control-label hideable" htmlFor="root_nome">
								Tipo de Proprietário
								{requiredTipo ? '*' : ''}
							</label>
							<div />
							<div className="select-wrapper">
								<select
									style={{ width: '100%' }}
									className={CLASS_NAME_FORM_CONTROL}
									value={this.state.tipo}
									onChange={this.onTipoChange}
									placeholder="Selecione o tipo de Proprietário"
									readOnly={readonly}
									disabled={disabled}
								>
									<option />
									{options.map((o, i) => (
										<option key={i}>{o}</option>
									))}
								</select>
							</div>
							<ErrorDetail attr={errorTipo} />
							<div />
						</div>
						{this.tipoOutros() ? (
							<div className="form-group  col-md-6 hideable">
								<label className="control-label" htmlFor="root_nome">
									Outro tipo de proprietário
								</label>
								<div />
								<input
									readOnly={readonly}
									disabled={disabled}
									className={
										this.state.valid
											? CLASS_NAME_FORM_CONTROL
											: `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
									}
									type="text"
									value={this.state.outros || ''}
									onChange={this.onOutrosChange}
									placeholder="Outro tipo"
								/>
								<ErrorDetail attr={errorOutros} />
								<div />
							</div>
						) : null}
					</Fragment>
				}
			</Fragment>
		);
	}
}

export default TipoProprietario;
