import React from 'react';

import PropTypes from 'prop-types';

const Loader = ({ blockLoad, minHeight, height, backgroundColor, msg }) => (
	<div
		className={blockLoad ? 'loader-wrapper block-loader' : 'loader-wrapper '}
		style={{ minHeight: minHeight, height: height, background: backgroundColor }}
	>
		<span>{msg || 'carregando...'}</span>
		<div className="loader" />
	</div>
);

Loader.displayName = 'Loader';

Loader.propTypes = {
	blockLoad: PropTypes.bool,
	minHeight: PropTypes.string,
	height: PropTypes.string,
	backgroundColor: PropTypes.string,
	msg: PropTypes.string
};

export default Loader;
