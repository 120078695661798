import {
	SET_FORMULARIO,
	SET_FORM_DATA,
	SET_ENQUADRAMENTO_DATA,
	LIMPA_STORE,
	SET_ERRORS,
	SET_RELOAD,
	SET_LOADING,
	SEND_FORM_DATA,
	SEND_ENQUADRAMENTO_DATA,
	SEND_FORM_COMPLETO,
	LOAD_FORM_AND_DATA,
	UPLOAD_FILE,
	UPLOAD_PROGRESS,
	UPLOAD_SUCCESS,
	UPLOAD_FAILURE,
	ADD_NEW_DOCUMENT,
	RETIRA_NEW_DOCUMENT,
	SET_RESULT,
	REMOVE_FILE,
	REMOVE_FILE_STORE,
	UPDATE_FORM_ID,
	UPDATE_FORM_DATA,
	SET_MOTIVO_DOC,
	ATUALIZA_DOCS_INVALIDOS,
	COMPLEMENTAR_PROCESSO,
	SET_DATA_COMPARECIMENTO,
	TOGGLE_DOC,
	SET_FORM_DATA_DATA,
	SET_USER_AGREEMENT,
	UPDATE_STATUS_DOCUMENTO,
	DUPLICAR_REQUERIMENTO,
	UPDATE_RESPOSTA_EXTRAINFO,
	OBTAIN_BPM_TASKS,
	SET_BPM_TASKS,
	SET_KC_USER,
	SUBMIT_ABA_DAM,
	SALVA_ANEXO_LIST,
	CLEAR_ANEXO_LIST,
	SET_PERSISTENT_STATE,
	LOAD_USUARIO,
	SET_USUARIO,
	SET_USUARIO_INTERNO
} from './constants';

export default {
	loadFormAndData: (idFormulario, idFormData, disabled, usernameLogado) => ({
		type: LOAD_FORM_AND_DATA,
		payload: { idFormulario, idFormData, disabled, usernameLogado }
	}),
	setFormulario: form => ({
		type: SET_FORMULARIO,
		payload: form
	}),
	setFormData: formData => ({
		type: SET_FORM_DATA,
		payload: formData
	}),
	setEnquadramentoData: data => ({
		type: SET_ENQUADRAMENTO_DATA,
		payload: data
	}),
	setFormDataData: formDataData => ({
		type: SET_FORM_DATA_DATA,
		payload: formDataData
	}),
	sendFormData: formData => ({
		type: SEND_FORM_DATA,
		payload: formData
	}),
	sendEnquadramentoData: payload => ({
		type: SEND_ENQUADRAMENTO_DATA,
		payload
	}),
	sendFormCompleto: (formulario, filtroOrgao) => ({
		type: SEND_FORM_COMPLETO,
		payload: { formulario, filtroOrgao }
	}),
	complementarProcesso: (formulario, usernameLogado, complementadaEm, createMessage, filtroOrgao) => ({
		type: COMPLEMENTAR_PROCESSO,
		payload: {
			formulario,
			usernameLogado,
			complementadaEm,
			createMessage,
			filtroOrgao
		}
	}),
	setResult: result => ({
		type: SET_RESULT,
		payload: result
	}),
	updateFormId: id => ({
		type: UPDATE_FORM_ID,
		payload: id
	}),
	updateFormData: data => ({
		type: UPDATE_FORM_DATA,
		payload: data
	}),
	uploadFile: payload => ({
		type: UPLOAD_FILE,
		payload: payload
	}),
	addNewDocument: payload => ({
		type: ADD_NEW_DOCUMENT,
		payload: payload
	}),
	retiraDocumento: payload => ({
		type: RETIRA_NEW_DOCUMENT,
		payload
	}),
	uploadProgress: (file, progress) => ({
		type: UPLOAD_PROGRESS,
		payload: progress,
		meta: { file }
	}),
	uploadSuccess: payload => ({
		type: UPLOAD_SUCCESS,
		payload
	}),
	uploadFailure: (file, err) => ({
		type: UPLOAD_FAILURE,
		payload: err,
		error: true,
		meta: { file }
	}),
	removeFile: payload => ({
		type: REMOVE_FILE,
		payload
	}),
	removeFileStore: payload => ({
		type: REMOVE_FILE_STORE,
		payload
	}),
	setReload: payload => ({
		type: SET_RELOAD,
		payload
	}),
	setLoading: loading => ({
		type: SET_LOADING,
		payload: loading
	}),
	limpaStore: () => ({
		type: LIMPA_STORE
	}),
	setErrors: errors => ({
		type: SET_ERRORS,
		payload: errors
	}),
	setMotivoDoc: documento => ({
		type: SET_MOTIVO_DOC,
		payload: documento
	}),
	setDataComparecimento: dataComparecimento => ({
		type: SET_DATA_COMPARECIMENTO,
		payload: dataComparecimento
	}),
	atualizarDocsInvalidos: formulario => ({
		type: ATUALIZA_DOCS_INVALIDOS,
		payload: formulario
	}),
	toggleDoc: doc => ({
		type: TOGGLE_DOC,
		payload: doc
	}),
	setAceitarTermos: aceito => ({
		type: SET_USER_AGREEMENT,
		payload: aceito
	}),
	alteraStatusDocumento: payload => ({
		type: UPDATE_STATUS_DOCUMENTO,
		payload
	}),
	duplicarRequerimento: payload => ({
		type: DUPLICAR_REQUERIMENTO,
		payload
	}),
	updateRespostaExtraInfo: payload => ({
		type: UPDATE_RESPOSTA_EXTRAINFO,
		payload
	}),
	obtainBpmTasks: payload => ({
		type: OBTAIN_BPM_TASKS,
		payload
	}),
	setBpmTasks: payload => ({
		type: SET_BPM_TASKS,
		payload
	}),
	setKcUser: payload => ({
		type: SET_KC_USER,
		payload
	}),
	sendAbaDam: payload => ({
		type: SUBMIT_ABA_DAM,
		payload
	}),
	salvaAnexoList: (property, lista) => ({
		type: SALVA_ANEXO_LIST,
		payload: { property, lista }
	}),
	clearAnexoList: propertyArray => ({
		type: CLEAR_ANEXO_LIST,
		payload: { propertyArray }
	}),
	setPersistentState: payload => ({
		type: SET_PERSISTENT_STATE,
		payload
	}),
	loadUsuario: payload => ({
		type: LOAD_USUARIO,
		payload
	}),
	setUsuario: payload => ({
		type: SET_USUARIO,
		payload
	}),
	setUsuarioInterno: payload => ({
		type: SET_USUARIO_INTERNO,
		payload
	})
};
