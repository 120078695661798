import React from 'react';

import PropTypes from 'prop-types';

import { NumberField } from 'containers/Form/metadata-template/fields';

export function InfosQuotasLE({ data: localData, onChangeHandler, tipoForm = 'le1' }) {
	return (
		<fieldset>
			<legend>Quotas</legend>
			<div className="row row-cols-12">
				<div className="form-group col-md-4">
					<NumberField
						name="quotaIndiceAproveitamento"
						label="Índice de Aproveitamento - IA"
						placeHolder="0"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaIndiceAproveitamento}
						decimals={2}
						maxSize="10"
					/>
				</div>
				<div className="form-group col-md-4">
					<NumberField
						name="quotaTaxaOcupacao"
						label="Taxa de Ocupação - TO (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaTaxaOcupacao}
						decimals={2}
						maxSize="10"
					/>
				</div>
				<div className="form-group col-md-4">
					<NumberField
						name="quotaALP"
						label="Área Livre Permeável - ALP (m²)"
						placeHolder="metragem m²"
						required={false}
						onChangeHandler={onChangeHandler}
						value={localData.quotaALP}
						decimals={2}
						maxSize="10"
					/>
				</div>
			</div>
		</fieldset>
	);
}

InfosQuotasLE.displayName = 'InfosQuotasLE';
InfosQuotasLE.propTypes = {
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	data: PropTypes.object
};
