import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE';

export function AreaSubfield({ fieldName, label, required, onChangeHandler, value, errors, readOnly, disabled, tooltip }) {
	return (
		<div className="pergunta">
			<label style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span>
					{label}
					{!!required && <span className="required">*</span>}
				</span>
			</label>
			<NumberFieldLE
				placeHolder={'metragem m²'}
				containerClass="input-group-lic"
				name={fieldName}
				onChangeHandler={onChangeHandler}
				value={value}
				decimals={2}
				maxSize="10"
				required={required}
				readOnly={readOnly}
				disabled={disabled}
				tooltip={tooltip}
			/>
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
AreaSubfield.displayName = 'CertidaoDemolicaoAreaSubfield';
AreaSubfield.propTypes = {
	fieldName: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	required: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	localData: PropTypes.object,
	errors: PropTypes.arrayOf(PropTypes.string),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	tooltip: PropTypes.string
};
