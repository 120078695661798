import React, { useLayoutEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { List } from 'immutable';
import { size, isArray, get, toString } from 'lodash';
import uuid from 'uuid/v1';

import ErrorMessages from 'components/ErrorMessages';
import ListItem from 'components/ListItem';

const Selecao = ({
	className,
	readOnly,
	multiple,
	selected,
	label,
	detailInnerClassName,
	detailCodigo,
	detailDescricao,
	detailData,
	detailIcon,
	detailModifier,
	searchTerm,
	searchList,
	errorList,
	warning,
	required,
	searchTermRef,
	onUnselect,
	onChangeSearchTerm,
	onBlurSearchTerm,
	onSelectItem,
	searchTermMinLength = 2,
	autoShowList,
	noResetList = false,
	loading = false,
	placeholder,
	children,
	isShowSelecionadosEmbaixo
}) => {
	const [showList, setShowList] = useState(false);
	const refContainer = useRef();
	const refList = useRef();

	const selectedJS = List.isList(selected) ? selected.toJS() : selected;

	const selectedList = selectedJS ? (isArray(selectedJS) ? selectedJS : [selectedJS]) : selectedJS;

	const hasSelected = size(selectedList) > 0;

	let searchSelected = -1;
	let prev;

	const handleKeyDown = event => {
		// let sl = Map.isMap(searchList) ? searchList.toJS() : searchList;
		let sl = searchList;

		if (!noResetList && !showList && event.key === 'ArrowDown') {
			//empty search
			setShowList(true);
			onChangeSearchTerm(event);
		}

		if (sl && sl.length > 0) {
			if (event.key === 'Enter') {
				searchSelected = searchSelected === -1 ? 0 : searchSelected;
				//select
				document.querySelectorAll('.results-item')[searchSelected].click();
			} else if (event.key === 'ArrowUp') {
				//focus previous
				prev = searchSelected;
				searchSelected = searchSelected === 0 ? sl.length - 1 : searchSelected - 1;
				fakeFocus(searchSelected, prev);
			} else if (event.key === 'ArrowDown') {
				//focus next
				prev = searchSelected;
				searchSelected = searchSelected === sl.length - 1 ? 0 : searchSelected + 1;
				fakeFocus(searchSelected, prev);
			}
		}
	};

	const fakeFocus = (searchSelected, prev) => {
		const list = document.querySelectorAll('.results-item');

		if (list && prev > -1 && list[prev]) {
			list[prev].classList.remove('fake-focus');
		}
		if (list && searchSelected > -1 && list[searchSelected]) {
			list[searchSelected].classList.add('fake-focus');
			list[searchSelected].scrollIntoView(false);
		}
	};

	const handleOnChange = event => {
		onChangeSearchTerm(event);
		const mostra = autoShowList || size(event.target.value) >= searchTermMinLength;
		setShowList(mostra);
	};

	const handleOnFocus = event => {
		if (size(searchTerm) >= searchTermMinLength || autoShowList) {
			setShowList(true);
			onChangeSearchTerm(event);
		}
		if (!autoShowList) {
			onBlurSearchTerm();
		}
	};

	const handleArrowBtn = event => {
		event.target.parentNode.getElementsByTagName('input')[0].focus();
		setShowList(true);
		onChangeSearchTerm({ target: { value: '' } });
		// clearTimeout(timer);
	};

	const select = (item, e) => {
		if (item) setShowList(false);
		const callback = onSelectItem && onSelectItem(item, setShowList);
		if (callback && typeof callback === 'function') {
			callback(e);
		}
		e.stopPropagation();
	};

	useLayoutEffect(() => {
		/**
		 * o blur e focus sempre acontecem no input, lá não da pra fechar a lista de options.
		 * adicionei um handler de click no documento inteiro pois ao clicar em alguma parte do doc
		 * o evento é escutado em outras partes por causa do pattern 'efeito bubble'.
		 *
		 * Se a o elemento lista existir e o click NÃO ocorrer dentro do container desse componente
		 * 'perde o focus' então a lista é fechada
		 */
		const fn = e => {
			if (refList.current && !refContainer.current?.contains(e.target)) {
				setShowList(false);
			}
		};
		document.addEventListener('click', fn);
		return () => document.removeEventListener('click', fn);
	}, []);

	return (
		<>
			<div className="input-group-lic">
				{!isShowSelecionadosEmbaixo && hasSelected && (
					<>
						{selectedList.map(selected => (
							<div className={`title-with-button${readOnly ? ' read-only' : ''}`} key={selected.id || uuid()}>
								<ListItem
									innerClassName={detailInnerClassName}
									codigo={get(selected, detailCodigo)}
									descricao={toString(get(selected, detailDescricao))}
									data={detailData ? get(selected, detailData) : null}
									icon={detailIcon}
									modifier={detailModifier}
								>
									{children ? children(selected) : null}
								</ListItem>
								{!readOnly && (
									<button
										type="button"
										onClick={onUnselect(selected)}
										aria-label={multiple ? 'excluir item' : 'modificar item'}
									>
										<i className={`fa fa-${multiple ? 'trash' : 'exchange'}`} aria-hidden="true" />
									</button>
								)}
							</div>
						))}
						{!multiple && <ErrorMessages errorList={errorList} />}
					</>
				)}
				{(multiple || !hasSelected) && (
					<>
						<div className="floating-list-wrapper" ref={refContainer}>
							<div className="input-with-icon">
								<input
									ref={searchTermRef}
									className={className}
									label={label}
									required={required}
									readOnly={!!readOnly}
									type="text"
									value={searchTerm || ''}
									onKeyDown={handleKeyDown}
									// onChange={onChangeSearchTerm}
									onChange={readOnly ? () => undefined : event => handleOnChange(event)}
									onBlur={readOnly ? undefined : onBlurSearchTerm}
									onFocus={readOnly ? () => undefined : event => handleOnFocus(event)}
									// onFocus={onBlurSearchTerm}
									placeholder={placeholder}
								/>
								{!noResetList && !readOnly && !loading && (
									<i className="fa fa-angle-down" onClick={event => handleArrowBtn(event)} />
								)}
								{loading && <i className="fa fa-refresh fa-spin" />}
							</div>
							{!readOnly && showList && (
								<div ref={refList} className="input-floating-list">
									<p className="results-count">
										{size(searchList) === 0
											? loading
												? 'Pesquisando...'
												: 'Nenhum item encontrado'
											: 'Selecione um item'}
									</p>
									{size(searchList) > 0 && (
										<ul className="results-list" style={{ zIndex: '10000' }}>
											{searchList.map((item, index) => (
												<li key={index} className="results-item" onClick={e => select(item, e)}>
													<ListItem
														innerClassName={detailInnerClassName}
														codigo={get(item, detailCodigo)}
														descricao={toString(get(item, detailDescricao))}
														data={detailData ? get(item, detailData) : null}
														icon={detailIcon}
														modifier={detailModifier}
													>
														{children ? children(item) : null}
													</ListItem>
												</li>
											))}
										</ul>
									)}
								</div>
							)}
						</div>
						<ErrorMessages errorList={errorList} />
						{warning && <ErrorMessages errorList={['Nenhum item foi selecionado']} warning />}
					</>
				)}
				{isShowSelecionadosEmbaixo && hasSelected && (
					<>
						{selectedList.map(selected => (
							<div className={`title-with-button${readOnly ? ' read-only' : ''}`} key={selected.id || uuid()}>
								<ListItem
									innerClassName={detailInnerClassName}
									codigo={get(selected, detailCodigo)}
									descricao={get(selected, detailDescricao)}
									data={detailData ? get(selected, detailData) : null}
									icon={detailIcon}
									modifier={detailModifier}
								>
									{children ? children(selected) : null}
								</ListItem>
								{!readOnly && (
									<button
										type="button"
										onClick={onUnselect(selected)}
										aria-label={multiple ? 'excluir item' : 'modificar item'}
									>
										<i className={`fa fa-${multiple ? 'trash' : 'exchange'}`} aria-hidden="true" />
									</button>
								)}
							</div>
						))}
						{!multiple && <ErrorMessages errorList={errorList} />}
					</>
				)}
			</div>
		</>
	);
};
Selecao.displayName = 'Selecao';

Selecao.propTypes = {
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	multiple: PropTypes.bool,
	selected: PropTypes.any,
	label: PropTypes.string,
	required: PropTypes.bool,
	detailInnerClassName: PropTypes.string,
	detailCodigo: PropTypes.string,
	detailDescricao: PropTypes.string,
	detailData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	detailIcon: PropTypes.string,
	detailModifier: PropTypes.func,
	searchTerm: PropTypes.string,
	searchList: PropTypes.arrayOf(PropTypes.object),
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	warning: PropTypes.bool,
	searchTermRef: PropTypes.any,
	onUnselect: PropTypes.func,
	onChangeSearchTerm: PropTypes.func,
	onBlurSearchTerm: PropTypes.func,
	onSelectItem: PropTypes.func,
	searchTermMinLength: PropTypes.number,
	autoShowList: PropTypes.bool,
	noResetList: PropTypes.bool,
	loading: PropTypes.bool,
	placeholder: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
	isShowSelecionadosEmbaixo: PropTypes.bool
};

export default Selecao;
