import React from 'react';

import PropTypes from 'prop-types';

import { truncate } from 'lodash';

const Truncate = ({ text, length, separator }) => {
	const trunc = truncate(text, { length: length || 30, separator: separator || ' ' });
	return trunc === text ? (
		text
	) : (
		<div className="trucate" title={text}>
			{trunc}
		</div>
	);
};
Truncate.displayName = 'Truncate';
Truncate.propTypes = {
	text: PropTypes.string,
	length: PropTypes.number,
	separator: PropTypes.string
};

export default Truncate;
