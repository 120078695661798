import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import RegiaoBadge from 'components/RegiaoBadge';

import { CdlField } from '.';

const CdlListFieldBox = ({
	title,
	label,
	placeHolder,
	required,
	name,
	value = [],
	onChangeHandler,
	readOnly,
	disabled,
	hideAddEndereco,
	hideTitle = false,
	smallTitle = false,
	adicionarManual = false,
	consultarRegiao,
	maxEnderecos
}) => {
	const [clearData, setClearData] = useState(false);
	const [cdlFieldLoading, setCdlFieldLoading] = useState(true);
	const [cdl, setCdl] = useState();

	const receiveCdl = obj => {
		if (size(obj) > 0) {
			const cdlAux = obj[0].value;
			setCdl(cdlAux);
			if (size((cdlAux?.numero || '').toString()) && adicionarManual === false) {
				adicionar(cdlAux);
			}
		}
	};

	const changeHandler = useCallback(
		newValue => {
			const aux = [
				{ name, value: newValue },
				{ name: 'errors', value: { [name]: validate(newValue, required, title) } }
			];
			onChangeHandler(aux);
		},
		[name, onChangeHandler, required, title]
	);

	const removerEndereco = endereco => {
		changeHandler(value.filter(e => e.id !== endereco.id));
	};

	const adicionar = (auxCdl = cdl) => {
		const jaTem = (value || []).find(
			item => item.codLogradouro === auxCdl.codLogradouro && item.numero === auxCdl.numero
		);
		if (!jaTem) {
			changeHandler([...(value || []), auxCdl]);
		}
		setCdl(null);
		setClearData(true);
	};

	useEffect(() => {
		if (!readOnly && maxEnderecos) {
			if (size(value) > maxEnderecos) {
				changeHandler([]);
			}
		}
	}, [changeHandler, maxEnderecos, readOnly, value]);

	const [isFinalized, setIsFinalized] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const finalizeEndereco = () => {
		setIsFinalized(true);
		setIsEditing(false);
		setCdl(null); // Limpa qualquer estado residual nos campos de entrada
		setClearData(true); // Assegura que todos os dados temporários são limpos
	};

	const editEndereco = () => {
		setIsEditing(true);
		setIsFinalized(false);
	};

	const areCdlFieldsEmpty = () => !(cdl && (cdl.nomeLogradouro || cdl.numero));

	return (
		<div className="form-group">
			{!hideAddEndereco && !hideTitle ? <h3>{title}</h3> : value && !hideTitle && <h3>{title}</h3>}
			{size(value) > 0 ? (
				<div className="cdl-wrapper-lista-aux">
					{smallTitle && <label className="control-label-aux">Lista de endereço(s):</label>}
					<div className="card">
						<ul className="list-group list-group-flush">
							{value.map((e, idx) => (
								<li key={idx} className="list-group-item menor d-flex justify-content-between align-items-center">
									{e.enderecoFormatadoCurto}
									<RegiaoBadge regiao={e.regiao} />
									{!readOnly && !disabled && !isFinalized && (
										<button type="button" className="btn btn-icon" onClick={() => removerEndereco(e)}>
											<i className="fa fa-trash"></i>
										</button>
									)}
								</li>
							))}
						</ul>
					</div>
				</div>
			) : null}
			<>
				{!isFinalized && (
					<div className="cdl-wrapper-aux">
						{smallTitle && <label className="control-label-aux">Incluir endereço:</label>}
						<>
							<div className="col-md-9 no-padding">
								<CdlField
									title={maxEnderecos === 1 ? 'Endereço' : 'Endereços'}
									label={label}
									noLabel={true}
									required={false}
									placeHolder={placeHolder}
									name={'enderecoCdl'}
									value={cdl}
									onChangeHandler={receiveCdl}
									readOnly={false}
									consultarRegiao={consultarRegiao}
									setCdlFieldLoading={setCdlFieldLoading}
									setClearData={setClearData}
									clearData={clearData}
								/>
							</div>
							<div className="col-md-3 cdl-action-btns">
								<button
									type="button"
									className="btn btn-link btn-link-actions btn-usar-address"
									onClick={() => adicionar(cdl)}
									disabled={cdlFieldLoading || !(cdl && cdl.nomeLogradouro && cdl.numero != null)}
								>
									<i className="fa fa-plus"></i> incluir endereço
								</button>
								<button
									type="button"
									className="btn btn-link btn-link-actions btn-descartar-address"
									onClick={() => setClearData(true)}
									disabled={!(cdl && (size(cdl.nomeLogradouro) > 0 || size(cdl.numero) > 0))}
									style={{ top: '-5px' }}
								>
									<i className="fa fa-times"></i> limpar campos
								</button>
							</div>
						</>
					</div>
				)}
				{!isFinalized && (
					<button
						type="button"
						className={size(value) === 0 || !areCdlFieldsEmpty() ? 'btn-disabled' : 'btn-primary'}
						onClick={finalizeEndereco}
						disabled={size(value) === 0 || !areCdlFieldsEmpty()}
						title="Você deve incluir endereço à lista"
					>
						Concluir
					</button>
				)}
				{isFinalized && !isEditing && (
					<button type="button" className="btn btn-primary" onClick={editEndereco}>
						Editar
					</button>
				)}
			</>
		</div>
	);
};
CdlListFieldBox.displayName = 'CdlListFieldBox';

CdlListFieldBox.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.any),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	hideTitle: PropTypes.bool,
	smallTitle: PropTypes.bool,
	hideAddEndereco: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	adicionarManual: PropTypes.bool,
	consultarRegiao: PropTypes.bool,
	maxEnderecos: PropTypes.number
};

export default CdlListFieldBox;

export const validate = (value, required, title) => {
	let errors = [];
	if (required) {
		if (size(value) === 0) {
			errors.push(`Você deve informar pelo menos um ${title}`);
		}
	}
	return errors;
};
