import React from 'react';

import PropTypes from 'prop-types';

import { get } from 'lodash';

import { includesTipoForm } from '../InfosProjetoAP';
import { CelulaTabelaAreasAP } from './CelulaTabelaAreasAP';
import { TIPOS_METRAGENS_ENUM, UAP_ENQUADRAMENTOS_ENUM } from './constantsAP';

export function LinhaTabelaAreasAP({
	naturezaEspecie,
	prefix,
	onChangeHandler,
	data,
	readOnly,
	areasPlanilha,
	tiposEconomias,
	tipoForm,
	disabled = false
}) {
	const metragensReadOnly = getMetragensReadOnly(data, tipoForm);
	return areasPlanilha.map(area =>
		tiposEconomias.map(tipoEconomia =>
			Object.keys(TIPOS_METRAGENS_ENUM).map(metragem => {
				const name = `${prefix}.${area}.${tipoEconomia}.${metragem}`;
				return (
					<CelulaTabelaAreasAP
						key={name}
						placeHolder="0,00"
						value={get(naturezaEspecie, [area, tipoEconomia, metragem])}
						name={name}
						onChangeHandler={onChangeHandler}
						readOnly={readOnly || metragensReadOnly.includes(metragem)}
						disabled={disabled}
					/>
				);
			})
		)
	);
}
LinhaTabelaAreasAP.displayName = 'LinhaTabelaAreasAP';
LinhaTabelaAreasAP.propTypes = {
	totalPrefix: PropTypes.string,
	naturezaEspecie: PropTypes.object,
	economia: PropTypes.object,
	prefix: PropTypes.string,
	economiaPrefix: PropTypes.string,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	areasPlanilha: PropTypes.arrayOf(PropTypes.string),
	tiposEconomias: PropTypes.arrayOf(PropTypes.string),
	tipoForm: PropTypes.string,
	bloqueiaAdNad: PropTypes.bool,
	disabled: PropTypes.bool
};

function getMetragensReadOnly(localData, tipoForm) {
	const { UNIFAMILIAR_02_ECONO, UNIFAMILIAR_01_ECONO, NAO_RESIDENCIAL } = UAP_ENQUADRAMENTOS_ENUM;

	const isUnifamiliarUmaEconomia = includesTipoForm([UNIFAMILIAR_01_ECONO], tipoForm);
	const isUnifamiliarDuasEconomias = includesTipoForm([UNIFAMILIAR_02_ECONO], tipoForm);
	const isNaoResidencial = includesTipoForm([NAO_RESIDENCIAL], tipoForm);

	// metragens que devem ficar readonly na tabela
	const metragensReadonly =
		isUnifamiliarUmaEconomia || isUnifamiliarDuasEconomias
			? ['mtAdensavel', 'mtNaoAdensavel']
			: isNaoResidencial && localData?.infosProjeto?.trintaPorcentoIa === 'sim'
			? ['mtNaoAdensavel']
			: [];

	return metragensReadonly;
}
