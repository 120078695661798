import { TIPOS_PPCI } from './licenca';
/**
 * obtem a validade da licenca conforme o tipo de documento
 * dos bombeiros, 1 ano ppci 4 anos appci
 *
 * @param {*} param0
 * @param {*} callback
 */
export function obterValidade({ tipoPPCI }, callback) {
	let validade;

	if (tipoPPCI === TIPOS_PPCI.ppci) {
		validade = '1 ano';
	} else if (tipoPPCI === TIPOS_PPCI.appci) {
		validade = '4 anos';
	}
	callback && callback({ validadeLicenca: validade });
}
