import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { TextAreaField } from 'containers/Form/metadata-template/fields';

import { isDebug } from 'utils/tools';

export function TextAreaSubfield({
	fieldName,
	label,
	placeholder,
	onChangeHandler,
	value,
	qtdMinLinhas,
	tamanhoLinha,
	errors
}) {
	return (
		<div className="pergunta metragem">
			<TextAreaField
				label={label}
				placeHolder={''}
				name={fieldName}
				placeholder={placeholder}
				onChangeHandler={onChangeHandler}
				value={value}
				qtdMinLinhas={qtdMinLinhas}
				tamanhoLinha={tamanhoLinha}
				required={true}
			/>
			{isDebug && <div className="debug-message">{fieldName}</div>}
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	);
}
TextAreaSubfield.displayName = 'LicencasExpressasTextAreaSubfield';
TextAreaSubfield.propTypes = {
	fieldName: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	qtdMinLinhas: PropTypes.number,
	tamanhoLinha: PropTypes.number,
	onChangeHandler: PropTypes.func,
	localData: PropTypes.object,
	errors: PropTypes.arrayOf(PropTypes.string)
};
