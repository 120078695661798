import React from 'react';

import logo from 'assets/images/logo.svg';

const AccessDenied = () => (
	<div className="error-screen">
		<h1 className="error-header">
			403
			<span className="error-label" style={{ fontSize: '24px' }}>
				Você ainda não tem permissão para usar esse serviço.
			</span>
		</h1>

		<p>
			Entre em contato através do e-mail <strong>smamus.delsuporte@portoalegre.rs.gov.br</strong>
		</p>
		<div className="logo">
			<img src={logo} width="320" alt="logo" />
		</div>
	</div>
);
AccessDenied.displayName = 'AccessDenied';
export default AccessDenied;
