import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { get, size, pick, filter } from 'lodash';

import ErrorDetail from 'components/ErrorDetail';
import Loader from 'components/Loader';
import Selecao from 'components/Selecao';

import { TIPOS_LICENCA_EXPRESSA } from 'utils/enums';
import { accessApi } from 'utils/injectApi';
import { permiteInput, sortCadastros } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
// const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

class TipoLicencaExpressa extends React.Component {
	static displayName = 'TipoLicencaExpressa';

	static propTypes = {
		formData: PropTypes.object,
		onChange: PropTypes.func,
		schema: PropTypes.object,
		errorSchema: PropTypes.object,
		readonly: PropTypes.bool,
		disabled: PropTypes.bool
	};

	timeout = null;

	constructor(props) {
		super(props);

		this.state = {
			formData: get(props, 'formData'),
			className: CLASS_NAME_FORM_CONTROL,
			tipoLicenca: get(props, 'formData.tipoLicenca'),
			tipoMaterial: get(props, 'formData.tipoMaterial'),
			areaConstrucaoRegular: get(props, 'formData.areaConstrucaoRegular'),
			areaConstrucaoIrregular: get(props, 'formData.areaConstrucaoIrregular'),
			areaTotal: get(props, 'formData.areaTotal'),
			areaAReciclar: get(props, 'formData.areaAReciclar'),
			areaAdensavel: get(props, 'formData.areaAdensavel'),
			anexo51: get(props, 'formData.anexo51'),
			anexo52: get(props, 'formData.anexo52'),
			anexo5354: get(props, 'formData.anexo5354'),
			nroVagasObrigatorias: get(props, 'formData.nroVagasObrigatorias'),
			nroVagasProjeto: get(props, 'formData.nroVagasProjeto'),
			term51: '',
			term52: '',
			pesquisados51: [],
			pesquisados52: [],
			anexo51list: [],
			anexo52list: []
		};
	}

	componentDidMount() {
		if (size(this.state.tipoLicenca) === 0) {
			const tl = TIPOS_LICENCA_EXPRESSA.find(
				t => t.codigoTipo === parseInt(get(this.props, 'formData.codOcorrenciaLicenca') || '', 10)
			);
			this.setState({
				tipoLicenca: tl
			});
		}
		this.carregaAnexos();
	}

	carregaAnexos = async () => {
		this.setState({ loading: true });
		if (size(this.state.anexo51list) === 0) {
			try {
				const retorno = (await accessApi('/collections/anexo51/')).data;
				const anexo51list = sortCadastros(retorno, 'codigo');
				this.setState({ anexo51list });
			} catch (e) {
				this.setState({ anexo51list: [] });
				console.error('Erro no acesso ao anexo 51: ', e);
			}
		}
		if (size(this.state.anexo52list) === 0) {
			try {
				const retorno2 = (await accessApi('anexo52/')).data;
				const anexo52list = sortCadastros(retorno2, 'codigo');
				this.setState({ anexo52list });
			} catch (e) {
				this.setState({ anexo52list: [] });
				console.error('Erro no acesso ao anexo 52: ', e);
			}
		}
		this.setState({ loading: false });
	};

	setProperty = property => e => {
		let { value } = e.target;
		let allow = true;
		if (
			['areaConstrucaoRegular', 'areaConstrucaoIrregular', 'areaTotal', 'areaAReciclar', 'areaAdensavel'].indexOf(
				property
			) > -1
		) {
			value = (value || '').replace(/[^0-9,]/g, '');
			if (!permiteInput(value, 'currency', 2, 12)) {
				allow = false;
			}
		} else if (['nroVagasObrigatorias', 'nroVagasProjeto'].indexOf(property) > -1) {
			value = (value || '').replace(/[^0-9,]/g, '');
			if (!permiteInput(value, 'number', 0, 4)) {
				allow = false;
			}
		} else if (property === 'tipoLicenca') {
			value = TIPOS_LICENCA_EXPRESSA.filter(t => t.codigoTipo === parseInt(value, 10));
			if (value && value.length) {
				value = value[0];
			} else {
				value = null; // não pode acontecer
			}
		}
		if (allow) {
			this.setState({ [property]: value }, () => {
				this.props.onChange({ ...this.props.formData, [property]: get(this.state, property) });
			});
		}
	};

	trocaTipoLicenca = () => {
		this.setState({
			tipoLicenca: null,
			tipoMaterial: null,
			areaConstrucaoRegular: null,
			areaConstrucaoIrregular: null,
			areaTotal: null,
			areaAReciclar: null,
			areaAdensavel: null,
			anexo51: null,
			anexo52: null,
			anexo5354: null,
			nroVagasObrigatorias: null,
			nroVagasProjeto: null,
			term51: '',
			term52: '',
			pesquisados51: [],
			pesquisados52: []
		});
	};

	extractErrors = attr => get(attr, '__errors') || [];

	filterByTerm51 = event => {
		const v = event.target.value;
		const l =
			size(v) > 0
				? filter(
						this.state.anexo51list,
						o =>
							JSON.stringify(pick(o, ['codigo']))
								.toLowerCase()
								.indexOf((v || '').toLowerCase()) > -1
				  )
				: this.state.anexo51list;
		this.setState({ term51: v, pesquisados51: l });
	};

	filterByTerm52 = event => {
		const v = event.target.value;
		const l =
			size(v) > 0
				? filter(
						this.state.anexo52list,
						o =>
							JSON.stringify(pick(o, ['codigo', 'descricao']))
								.toLowerCase()
								.indexOf((v || '').toLowerCase()) > -1
				  )
				: this.state.anexo52list;
		this.setState({ term52: v, pesquisados52: l });
	};

	render() {
		const requireds = get(this.props, 'schema.required') || [];
		const requiredTipoLicenca = requireds.indexOf('tipoLicenca') > -1;
		const { tipoLicenca: errorTipoLicenca } = this.props.errorSchema || [];
		const {
			tipoLicenca,
			// tipoMaterial,
			areaConstrucaoRegular,
			areaConstrucaoIrregular,
			areaAReciclar,
			areaTotal,
			areaAdensavel,
			nroVagasObrigatorias,
			nroVagasProjeto,
			anexo51list,
			anexo52list,
			anexo51,
			anexo52,
			term51,
			term52,
			pesquisados51,
			pesquisados52,
			loading
		} = this.state;
		const { readonly, disabled } = this.props;
		return loading ? (
			<Loader msg="Buscando tabelas 5.1 e 5.2" />
		) : (
			<Fragment>
				{size(tipoLicenca) === 0 && (
					<div className="form-group col-md-12">
						<label className="control-label" htmlFor="root_nome">
							Selecione o tipo de licença expressa:
							{requiredTipoLicenca ? '*' : ''}
						</label>
						<div />
						<ul>
							{TIPOS_LICENCA_EXPRESSA.map(t => (
								<li key={t.codigoTipo}>
									<label>
										<input
											className="CLASS_NAME_FORM_CONTROL"
											type="radio"
											name="tipo"
											value={t.codigoTipo}
											onChange={this.setProperty('tipoLicenca')}
											readOnly={readonly}
											disabled={disabled}
										/>
										{t.descricao}
									</label>
								</li>
							))}
						</ul>
						<ErrorDetail attr={errorTipoLicenca} />
						<div />
					</div>
				)}
				{size(tipoLicenca) > 0 && (
					<Fragment>
						<div className="row">
							<div className="form-group col-md-3">Tipo de Licença sendo solicitada: </div>
							<div className="form-group col-md-9">
								<b>{tipoLicenca.descricao}</b>
							</div>
						</div>
						{/* <div className="form-group  col-md-3">
							<button type="button" className="btn btn-link" onClick={this.trocaTipoLicenca}>
								Escolher outro tipo
							</button>
						</div> */}
						{tipoLicenca.codigoTipo === 1255 && ( // Demolição Parcial
							<Fragment>
								<div className="row">
									<div className="form-group col-md-3">
										<label>
											<input
												type="radio"
												name="tipoMaterial"
												value="alvenaria"
												onClick={this.setProperty('tipoMaterial')}
												readOnly={readonly}
												disabled={disabled}
											/>
											Alvenaria
										</label>
									</div>
									<div className="form-group col-md-3">
										<label>
											<input
												type="radio"
												name="tipoMaterial"
												value="madeira"
												onClick={this.setProperty('tipoMaterial')}
												readOnly={readonly}
												disabled={disabled}
											/>
											Madeira
										</label>
									</div>
									<div className="form-group col-md-3">
										<label>
											<input
												type="radio"
												name="tipoMaterial"
												value="outros"
												onClick={this.setProperty('tipoMaterial')}
												readOnly={readonly}
												disabled={disabled}
											/>
											Outros
										</label>
									</div>
									<div className="form-group col-md-3">
										<label>
											<input
												type="radio"
												name="tipoMaterial"
												value="mista"
												onClick={this.setProperty('tipoMaterial')}
												readOnly={readonly}
												disabled={disabled}
											/>
											Mista
										</label>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-6">
										<label>
											Área Construção Regular (m²):
											<input
												type="text"
												className={CLASS_NAME_FORM_CONTROL}
												value={areaConstrucaoRegular || ''}
												onChange={this.setProperty('areaConstrucaoRegular')}
												readOnly={readonly}
												disabled={disabled}
											/>
										</label>
									</div>
									<div className="form-group col-md-6">
										<label>
											Área Construção Irregular (m²):
											<input
												type="text"
												className={CLASS_NAME_FORM_CONTROL}
												value={areaConstrucaoIrregular || ''}
												onChange={this.setProperty('areaConstrucaoIrregular')}
												readOnly={readonly}
												disabled={disabled}
											/>
										</label>
									</div>
								</div>
							</Fragment>
						)}
						{(tipoLicenca.codigoTipo === 1256 || tipoLicenca.codigoTipo === 1264) && ( // Reciclagem
							<Fragment>
								<div className="row">
									<div className="form-group col-md-6">
										<label>
											Área Total (m²):
											<input
												type="text"
												className={CLASS_NAME_FORM_CONTROL}
												value={areaTotal || ''}
												onChange={this.setProperty('areaTotal')}
												readOnly={readonly}
												disabled={disabled}
											/>
										</label>
									</div>
									{
										<div className="form-group col-md-6">
											<label>
												<span>Área {tipoLicenca.codigoTipo === 1256 ? 'a reciclar' : 'Adensável'} (m²):</span>
												<input
													type="text"
													className={CLASS_NAME_FORM_CONTROL}
													value={(tipoLicenca.codigoTipo === 1256 ? areaAReciclar : areaAdensavel) || ''}
													onChange={this.setProperty(
														tipoLicenca.codigoTipo === 1256 ? 'areaAReciclar' : 'areaAdensavel'
													)}
													readOnly={readonly}
													disabled={disabled}
												/>
											</label>
										</div>
									}
								</div>
								<div className="row">
									<div className="form-group col-md-12">
										<label>G.A. (anexo 5.1)</label>
										<Selecao
											className={'form-control'}
											multiple={false}
											selected={size(anexo51) > 0 ? anexo51 : null}
											label={'G.A. (anexo 5.1'}
											detailCodigo={''}
											detailDescricao={'codigo'}
											autoShowList={true}
											searchTerm={term51}
											searchList={pesquisados51}
											searchTermMinLength={1}
											errorList={false}
											onChangeSearchTerm={event => this.filterByTerm51(event, anexo51list)}
											onBlurSearchTerm={() => false}
											onSelectItem={item => () => this.setProperty('anexo51')({ target: { value: item } })}
											onUnselect={() => () => this.setProperty('anexo51')({ target: { value: undefined } })}
											loading={false}
											required={true}
											placeholder="Selecione a G.A."
											readOnly={readonly || disabled}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-12">
										<label>Atividade (anexo 5.2)</label>
										<Selecao
											className={'form-control'}
											multiple={false}
											selected={size(anexo52) > 0 ? anexo52 : null}
											label={'Atividade (anexo 5.2'}
											detailCodigo={'codigo'}
											detailDescricao={'descricao'}
											autoShowList={true}
											searchTerm={term52}
											searchList={pesquisados52}
											searchTermMinLength={1}
											errorList={false}
											onChangeSearchTerm={event => this.filterByTerm52(event, anexo52list)}
											onBlurSearchTerm={() => false}
											onSelectItem={item => () => this.setProperty('anexo52')({ target: { value: item } })}
											onUnselect={() => () => this.setProperty('anexo52')({ target: { value: undefined } })}
											loading={false}
											required={true}
											placeholder="Selecione a atividade"
											readOnly={readonly || disabled}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-12">
										<span>Restrição ou limite de porte (especificar conforme anexos 5.3 e 5.4)</span>
										<label>
											<input
												type="radio"
												name="limite-porte"
												value="sim"
												onClick={this.setProperty('anexo5354')}
												readOnly={readonly}
												disabled={disabled}
												checked={this.state.anexo5354 === 'sim'}
												onChange={() => true}
											/>{' '}
											Sim
										</label>
										<label>
											<input
												type="radio"
												name="limite-porte"
												value="nao"
												onClick={this.setProperty('anexo5354')}
												readOnly={readonly}
												disabled={disabled}
												checked={this.state.anexo5354 === 'nao'}
												onChange={() => true}
											/>{' '}
											Não
										</label>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-6">
										<label>Nº vagas obrigatórias</label>
										<input
											type="text"
											className={CLASS_NAME_FORM_CONTROL}
											value={nroVagasObrigatorias || ''}
											onChange={this.setProperty('nroVagasObrigatorias')}
											readOnly={readonly}
											disabled={disabled}
										/>
									</div>
									<div className="form-group col-md-6">
										<label>Nº vagas no projeto</label>
										<input
											type="text"
											className={CLASS_NAME_FORM_CONTROL}
											value={nroVagasProjeto || ''}
											onChange={this.setProperty('nroVagasProjeto')}
											readOnly={readonly}
											disabled={disabled}
										/>
									</div>
								</div>
							</Fragment>
						)}
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default TipoLicencaExpressa;
