import React from 'react';

import PropTypes from 'prop-types';

import { format, isCNPJ, isCPF } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const CpfCnpjField = ({
	label,
	cpf = true,
	cnpj = true,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	onChangeHandler,
	isInvalid
}) => {
	const onChangeText = e => {
		let { value } = e.target;
		if (value) {
			value = value.replace(/[^0-9]/g, '');
			value = value.substr(0, 14);

			if (cpf && isCPF(value)) {
				value = format(value, '000.000.000-00');
			} else if (cnpj && isCNPJ(value)) {
				value = format(value, '00.000.000/0000-00');
			}
		}
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label, cpf, cnpj) } }
		]);
	};
	return (
		<>
			<label className="control-label">
				{label}
				{required && <span className="required">*</span>}
			</label>
			<input
				className={
					isInvalid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value}
				onChange={onChangeText}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</>
	);
};
CpfCnpjField.displayName = 'TextField';

CpfCnpjField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	cpf: PropTypes.bool,
	cnpj: PropTypes.bool,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default CpfCnpjField;

export const validate = (value, required, label = 'Campo', consideraCpf = true, consideraCnpj = true) => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else {
			const isCpf = consideraCpf && isCPF(value);
			const isCnpj = consideraCnpj && isCNPJ(value);

			if (!isCpf && !isCnpj) {
				errors.push(`Insira um ${label} válido`);
			}
		}
	}
	return errors;
};
