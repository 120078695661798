import React from 'react';

import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

import Decimal from 'decimal.js';
import { createBrowserHistory } from 'history';
import Keycloak from 'keycloak-js';
import { get, set } from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import configureStore from 'config/configureStore';

import LayoutContextWrapper from 'components/LayoutContext';

import apolloClient from 'utils/graphql';

import AppRouter from './AppRouter';
import { SSO_URL } from './environments';
import * as serviceWorker from './serviceWorker';
import 'font-awesome/css/font-awesome.min.css';
import 'lato-font/css/lato-font.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'numeral/locales/pt-br';

import './style.scss';

if (!Array.prototype.findLastIndex) {
	const polyfill = function (predicate) {
		let array = this;
		let k = array.length - 1;
		while (k >= 0) {
			const kValue = array[k];
			const testResult = predicate(kValue, k, array);
			if (testResult) {
				return k;
			}
			k -= 1;
		}
		return -1;
	};
	// eslint-disable-next-line no-extend-native
	Array.prototype.findLastIndex = polyfill;

	if (Array.prototype.findLastIndex) {
		let a = [7, 8, 9, 10, 0, 1, 2, 3, 4, 5, 6];
		console.debug(
			'dado o array',
			a,
			'o índice do último elemento > 7 é',
			a.findLastIndex(n => n > 7),
			'e este teste foi feito com o polyfill',
			'incluído para atender browsers antigos'
		);
	}
}

const googleAnalyticsID = 'UA-81358275-11';
if (process.env.NODE_ENV === 'production') {
	ReactGA.initialize(googleAnalyticsID);
}

let layoutReference;
export const getLayoutInstance = () => layoutReference || {};

export const history = createBrowserHistory();
export const store = configureStore({});

const $filtros = new BehaviorSubject({});
store.$filtros = $filtros;
store.updateFiltros = data => {
	const up = { ...$filtros.value };
	let updated = false;
	for (const property in data) {
		const value = data[property];
		if (get(up, property) !== value) {
			set(up, property, value);
			updated = true;
		}
	}
	if (updated) {
		$filtros.next(up);
	}
};

const keycloakObject = new Keycloak({
	url: SSO_URL,
	realm: 'acessopoa',
	clientId: 'licenciamento'
});

store.kc = keycloakObject;

const App = () => (
	<Provider store={store}>
		<LayoutContextWrapper ref={r => (layoutReference = r)}>
			<ApolloProvider client={apolloClient}>
				<AppRouter />
			</ApolloProvider>
		</LayoutContextWrapper>
	</Provider>
);
App.displayName = 'KeycloakInjection';

store.kc.init({ checkLoginIframe: false, promiseType: 'native' }).then(() => {
	ReactDOM.render(<App />, document.getElementById('app'));
});

serviceWorker.unregister();

// Hot Module Replacement
if (module.hot) {
	module.hot.accept();
}

//Inicialização de libs
Decimal.set({
	precision: 20,
	rounding: Decimal.ROUND_HALF_UP,
	toExpNeg: -7,
	toExpPos: 21
});

moment.defineLocale('pt-br', {
	months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
	monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
	weekdays: 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
	weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
	weekdaysMin: 'Do_2ª_3ª_4ª_5ª_6ª_Sá'.split('_'),
	weekdaysParseExact: true,
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D [de] MMMM [de] YYYY',
		LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
		LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm'
	},
	calendar: {
		sameDay: '[Hoje às] LT',
		nextDay: '[Amanhã às] LT',
		nextWeek: 'dddd [às] LT',
		lastDay: '[Ontem às] LT',
		lastWeek: function () {
			return this.day() === 0 || this.day() === 6
				? '[Último] dddd [às] LT' // Saturday + Sunday
				: '[Última] dddd [às] LT'; // Monday - Friday
		},
		sameElse: 'L'
	},
	relativeTime: {
		future: 'em %s',
		past: 'há %s',
		s: 'poucos segundos',
		ss: '%d segundos',
		m: 'um minuto',
		mm: '%d minutos',
		h: 'uma hora',
		hh: '%d horas',
		d: 'um dia',
		dd: '%d dias',
		M: 'um mês',
		MM: '%d meses',
		y: 'um ano',
		yy: '%d anos'
	},
	dayOfMonthOrdinalParse: /\d{1,2}º/,
	ordinal: '%dº'
});
moment.locale('pt-br');
