import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import RegiaoBadge from 'components/RegiaoBadge';

import { CdlField } from '.';

const CdlListField = ({
	title,
	label,
	placeHolder,
	required,
	name,
	value = [],
	onChangeHandler,
	readOnly,
	disabled,
	hideAddEndereco,
	adicionarManual = false,
	consultarRegiao,
	maxEnderecos
}) => {
	const [incluindo, setIncluindo] = useState(false);
	const [cdl, setCdl] = useState();

	const receiveCdl = obj => {
		if (size(obj) > 0) {
			const cdlAux = obj[0].value;
			setCdl(cdlAux);
			if (size((cdlAux?.numero || '').toString()) && adicionarManual === false) {
				adicionar(cdlAux);
			}
		}
	};

	const changeHandler = useCallback(
		newValue => {
			const aux = [
				{ name, value: newValue },
				{ name: 'errors', value: { [name]: validate(newValue, required, title) } }
			];
			onChangeHandler(aux);
		},
		[name, onChangeHandler, required, title]
	);

	const removerEndereco = endereco => {
		setIncluindo(false);
		changeHandler(value.filter(e => e.id !== endereco.id));
	};

	const adicionar = (auxCdl = cdl) => {
		const jaTem = (value || []).find(
			item => item.codLogradouro === auxCdl.codLogradouro && item.numero === auxCdl.numero
		);
		if (!jaTem) {
			changeHandler([...(value || []), auxCdl]);
		}
		setCdl(null);
		setIncluindo(false);
	};

	useEffect(() => {
		if (!readOnly && maxEnderecos) {
			if (size(value) > maxEnderecos) {
				changeHandler([]);
			}
		}
	}, [changeHandler, maxEnderecos, readOnly, value]);

	return (
		<div className="form-group col-md-12">
			{!hideAddEndereco ? <h3>{title}</h3> : value && <h3>{title}</h3>}
			{!hideAddEndereco &&
				!incluindo &&
				!readOnly &&
				!disabled &&
				(maxEnderecos === undefined || value.length < maxEnderecos) && (
					<button type="button" className="btn btn-default" onClick={() => setIncluindo(true)}>
						<i className="fa fa-plus"></i> Novo Endereço
					</button>
				)}
			{incluindo && (
				<>
					<div className="row">
						<CdlField
							title={maxEnderecos === 1 ? 'Endereço' : 'Endereços'}
							label={label}
							required={false}
							placeHolder={placeHolder}
							name={'enderecoCdl'}
							value={cdl}
							onChangeHandler={receiveCdl}
							readOnly={false}
							consultarRegiao={consultarRegiao}
						/>
					</div>
					<>
						{cdl && cdl.nomeLogradouro && cdl.numero != null && (
							<button type="button" className="btn btn-link" onClick={() => adicionar(cdl)}>
								Adicionar endereço à lista
							</button>
						)}
						<button type="button" className="btn btn-link" onClick={() => setIncluindo(false)}>
							Cancelar inclusão
						</button>
					</>
				</>
			)}
			{size(value) > 0 && (
				<div className="card">
					<ul className="list-group list-group-flush">
						{value.map((e, idx) => (
							<li key={idx} className="list-group-item menor d-flex justify-content-between align-items-center">
								{e.enderecoFormatadoCurto}
								<RegiaoBadge regiao={e.regiao} />
								{!readOnly && !disabled && (
									<button type="button" className="btn btn-icon" onClick={() => removerEndereco(e)}>
										<i className="fa fa-trash"></i>
									</button>
								)}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
CdlListField.displayName = 'CdlListField';

CdlListField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.bool,
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.any),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	hideAddEndereco: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	adicionarManual: PropTypes.bool,
	consultarRegiao: PropTypes.bool,
	maxEnderecos: PropTypes.number
};

export default CdlListField;

export const validate = (value, required, title) => {
	let errors = [];
	if (required) {
		if (size(value) === 0) {
			errors.push(`Você deve informar pelo menos um ${title}`);
		}
	}
	return errors;
};
