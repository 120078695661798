import React from 'react';

import PropTypes from 'prop-types';

import ReactGA from 'react-ga';
import { ReactReduxContext } from 'react-redux';

import NotFound from './404Page';
// const googleAnalyticsID = 'UA-81358275-11';
// console.debug(ReactGA.initialize(googleAnalyticsID, {debug:true}));
const withGoogleAnalytics = WrappedComponent =>
	class extends React.Component {
		displayName = 'withGoogleAnalytics';

		static contextType = ReactReduxContext;

		static propTypes = {
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
			match: PropTypes.object,
			history: PropTypes.object
		};

		componentDidMount() {
			if (process.env.NODE_ENV === 'production') {
				if (WrappedComponent === NotFound) {
					ReactGA.event({
						category: 'Navegação',
						action: '404',
						label: `URL: ${this.props.history.location.pathname}`
					});
				}
				ReactGA.pageview(this.props.match.path);
			} else {
				console.debug('Google Analytics desativado em ambiente de desenvolvimento');
			}
		}
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

export default withGoogleAnalytics;
