import React from 'react';

import PropTypes from 'prop-types';

import { calculaNumeroLinhas } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const TextField = ({
	label,
	labelEnum,
	required,
	placeHolder,
	name,
	value,
	qtdMinLinhas = 3,
	tamanhoLinha = 80,
	readOnly,
	disabled,
	onChangeHandler,
	isInvalid,
	style
}) => {
	const onChangeTextArea = e => {
		const { value } = e.target;
		onChangeHandler({ name, value });
		onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label) } });
	};
	return (
		<>
			<label className="control-label">
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<textarea
				className={
					isInvalid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value}
				rows={calculaNumeroLinhas(value, qtdMinLinhas, tamanhoLinha)}
				onChange={onChangeTextArea}
				readOnly={readOnly}
				disabled={disabled}
				style={style}
			></textarea>
		</>
	);
};
TextField.displayName = 'TextAreaField';

TextField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	qtdMinLinhas: PropTypes.number,
	tamanhoLinha: PropTypes.number,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	style: PropTypes.object
};

export default TextField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
