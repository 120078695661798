import React from 'react';

import PropTypes from 'prop-types';

const Modal = props => (
	<div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,.5)' }}>
		<div className="modal-dialog" role="document">
			<div className="modal-content">
				{props.title ? (
					<div className="modal-header">
						<h5 className="modal-title" style={{ marginTop: 0, fontSize: '1.25rem' }}>
							{props.title}
						</h5>
						<button
							type="button"
							className="close"
							onClick={props.dismiss}
							aria-label="Fechar"
							style={{
								float: 'right',
								fontSize: '1.5rem',
								fontWeight: '700',
								lineHeight: '1',
								color: '#000',
								textShadow: '0 1px 0 #fff',
								opacity: '.5',
								backgroundColor: 'transparent',
								border: 0,
								appearance: 'none'
							}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				) : null}
				<div className="modal-body">{props.children}</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={props.dismiss}>
						Fechar
					</button>
					{props.submit ? (
						<button type="button" className="btn btn-primary" onClick={props.submit}>
							{props.submitLabel}
						</button>
					) : null}
				</div>
			</div>
		</div>
	</div>
);
Modal.displayName = 'Modal';

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	title: PropTypes.string,
	dismiss: PropTypes.func,
	submit: PropTypes.func,
	submitLabel: PropTypes.string
};
export default Modal;
