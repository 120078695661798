import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { clone, forEach, get, omit, set, size } from 'lodash';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import WarningMessage from 'components/WarningMessage';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';
import PlanoDiretorAtividades from 'containers/PlanoDiretor/PlanoDiretorAtividade';
import { TABELA_PORTE, TABELA_RESTRICAO_IMPLANTACAO } from 'containers/PlanoDiretor/PlanoDiretorConstantes';
import { MSG_BASE_DESATIVADA } from 'containers/PlanoDiretor/PlanoDiretorRegimeUrbanistico';

import useQueryStore from 'custom-hooks/useQueryStore';

import { API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { isDebug, joinBr, multiplica, soma, trocaPontoPorVirgula } from 'utils/tools';

import { PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorAP';
import { includesTipoForm } from './InfosProjetoAP';
import {
	ATV_COLETIVA,
	ATV_MULTIFAMILIAR,
	ATV_MULTIFAMILIAR_NAO_RESIDENCIAL,
	ATV_NAO_RESIDENCIAL,
	ATV_UNIFAMILIAR,
	ATV_UNIFAMILIAR_NAO_RESIDENCIAL,
	ITEM_HABITACAO_COLETIVA,
	UAP_ENQUADRAMENTOS_ENUM
} from './TabelaAreasAP/constantsAP';

const {
	NAO_RESIDENCIAL,
	MULTIFAMILIAR,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO,
	UNIFAMILIAR_NAO_RESIDENCIAL,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	HABITACAO_COLETIVA
} = UAP_ENQUADRAMENTOS_ENUM;

const OPT_SIM_NAO = [
	{ codigo: 'sim', descricao: 'Sim' },
	{ codigo: 'nao', descricao: 'Não' }
];
const sufixoComentario = 'Comentario';

export function InfosProjetoSubUnidadesAP({
	data: localData,
	onChangeHandler,
	dadosSubunidade,
	dadosSubunidadeAnterior,
	tipoForm,
	atividadeModificada,
	atividadeModificadaCallback,
	errosSubmissao: errosSubmissaoProps,
	indicesConstrutivos,
	showAvisoAreaAplicacaoRu,
	setShowAvisoAreaAplicacaoRu,
	readOnly,
	disabled = false,
	changeComentarioHandler = null,
	usuarioInterno = false,
	readOnlyVersao = null,
	setAdvertenciaRestricoes = () => null
}) {
	const [, setUpdateUI] = useState(false);
	const [temComentario, setTemComentario] = useState({});

	const setTemComentarioAux = valores => {
		const { name, value } = valores || {};
		if (temComentario[name] !== value) {
			setTemComentario(oldState => {
				const newState = { ...oldState };
				newState[name] = value;
				return newState;
			});
		}
	};

	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	const isUnifamiliarUmaOuDuas = useMemo(
		() => includesTipoForm([UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO], tipoForm),
		[tipoForm]
	);
	const isUnifamiliarENaoResidencial = useMemo(
		() => includesTipoForm([UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm),
		[tipoForm]
	);
	const isEnqHabColetiva = useMemo(() => includesTipoForm([HABITACAO_COLETIVA], tipoForm), [tipoForm]);
	const isMultifamiliar = useMemo(() => includesTipoForm([MULTIFAMILIAR], tipoForm), [tipoForm]);
	const isMultifamiliarNaoResidencial = useMemo(
		() => includesTipoForm([MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm),
		[tipoForm]
	);
	const isNaoResidencial = useMemo(() => includesTipoForm([NAO_RESIDENCIAL], tipoForm), [tipoForm]);
	const isNaoResidenciais = useMemo(
		() => includesTipoForm([NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm),
		[tipoForm]
	);

	const atividadesPermitidas = useMemo(() => {
		let saida = [];
		if (isUnifamiliarUmaOuDuas) saida.push(...ATV_UNIFAMILIAR);
		if (isEnqHabColetiva) saida.push(...ATV_COLETIVA);
		if (isMultifamiliar) saida.push(...ATV_MULTIFAMILIAR);
		if (isNaoResidencial) saida.push(...ATV_NAO_RESIDENCIAL);
		if (isUnifamiliarENaoResidencial) saida.push(...ATV_UNIFAMILIAR_NAO_RESIDENCIAL);
		if (isMultifamiliarNaoResidencial) saida.push(...ATV_MULTIFAMILIAR_NAO_RESIDENCIAL);
		return saida;
	}, [
		isEnqHabColetiva,
		isMultifamiliar,
		isMultifamiliarNaoResidencial,
		isNaoResidencial,
		isUnifamiliarENaoResidencial,
		isUnifamiliarUmaOuDuas
	]);

	// código da atividade, usado se for unifamiliares 1/2
	const atvCod = get(dadosSubunidade, 'dadosAtividades.0.atividadeAnexo52.codigo');
	// código do grupamento, usado se for unifamiliares 1/2
	const gaCod = get(dadosSubunidade, 'gaRegimeUrbanisticoAnexo.codigo');
	// hardcode atividade coletiva objectid eh 3 usado se unifamiliares 1/2
	// objectid eh o indice, nao da pra usar, alterado pra usar a prop item
	const isColetiva = get(dadosSubunidade, 'dadosAtividades.0.atividadeAnexo52.item') === ITEM_HABITACAO_COLETIVA;
	// codigo, descricao usado se unifamiliares 1/2
	const i53 = joinBr(
		get(dadosSubunidade, 'dadosAtividades', []).reduce((acc, atv) => {
			if (size(atv.implantacaoAnexo53?.descricao) > 0) {
				return [...acc, atv.implantacaoAnexo53.descricao];
			}
			return acc;
		}, [])
	);
	const p54 = joinBr(
		get(dadosSubunidade, 'dadosAtividades', []).reduce((acc, atv) => {
			if (size(atv.porteAnexo54?.descricao) > 0) {
				return [...acc, atv.porteAnexo54.descricao];
			}
			return acc;
		}, [])
	);

	const { data: implantacaoAnexo53Store } = useQueryStore(
		`${API_URL}/collections/anexo53implantacao/`,
		'implantacaoAnexo53'
	);
	const { data: porteAnexo54Store } = useQueryStore(`${API_URL}/collections/anexo54porte/`, 'porteAnexo54');

	const [showAvisoAlturaDivisa, setShowAvisoAlturaDivisa] = useState(false);
	const [showAvisoAlturaBase, setShowAvisoAlturaBase] = useState(false);
	const [showAvisoAlturaMaxima, setShowAvisoAlturaMaxima] = useState(false);

	// dados restricoes adminstrativas
	const restricoesAdmData = get(localData, 'restricoesAdministrativas', {});
	// dados do plano diretor
	const planoDiretorData = get(localData, PLANODIRETOR_CONTROLNAME, {});
	// dados do projeto

	// nome
	const nomeid = get(dadosSubunidade, 'subunidade.subunidade');
	// altura readonly
	/* const alturaRegimeUrbanisticoAnexo = set({}, 'value', get(dadosSubunidade, 'alturaRegimeUrbanisticoAnexo.codigo')); */

	// anexo 7.2
	const anexo72 = get(dadosSubunidade, 'possuiAnexo72');
	// para calculos
	const [taxaOcupacao, setTaxaOcupacao] = useState(0);
	const [indiceAproveitamentoRU, setIndiceAproveitamentoRU] = useState(0);
	const [areaTerrenoAtingida, setAreaTerrenoAtingida] = useState(0);
	const [tombadoEstruturacaoTaxa, setTombadoEstruturacaoTaxa] = useState(1);
	const [jaRegistrouRestricoes, setJaRegistrouRestricoes] = useState(false);

	const [recalcular, setRecalcular] = useState(false);

	// divisa
	const limiteDivisaRegimeUrbanisticoAnexo = get(dadosSubunidade, 'divisaRegimeUrbanisticoAnexo');

	// base
	const limiteBaseRegimeUrbanisticoAnexo = get(dadosSubunidade, 'baseRegimeUrbanisticoAnexo');

	// altura maxima possivel para o projeto
	const certificadoSustentavel = get(localData, 'certificadoSustentavel');
	const limiteAlturaMaxima = useMemo(() => {
		let limiteRU = clone(get(dadosSubunidade, 'alturaRegimeUrbanisticoAnexo', {}));
		if (certificadoSustentavel && !limiteRU.alteradoCertificado) {
			const novoLimite = multiplica(soma(certificadoSustentavel.valor, '1'), limiteRU.codigo);
			limiteRU.codigo = novoLimite;
			limiteRU.descricao = trocaPontoPorVirgula(novoLimite);
			limiteRU.alteradoCertificado = true;
		}
		return limiteRU;
	}, [dadosSubunidade, certificadoSustentavel]);

	const calculos = useCallback(
		dadosSubunidade => {
			// to da base
			let resultadoTOBase;
			if (anexo72 === 'sim') {
				resultadoTOBase = multiplica(areaTerrenoAtingida, 0.9);
				// resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.75);
			} else {
				resultadoTOBase = multiplica(areaTerrenoAtingida, taxaOcupacao);
				// resultadoTOCorpo = multiplica(areaTerrenoAtingida, taxaOcupacao);
			}
			// to do corpo
			let resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.75);
			const codAnexo71 = parseFloat(get(dadosSubunidade, 'subunidade.volumetria', 0));
			if (1 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.666);
			} else if (21 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.2);
			} else if (23 === codAnexo71) {
				resultadoTOCorpo = multiplica(areaTerrenoAtingida, 0.5);
			}
			// to da subunidade
			const resultadoTOSubunidade = multiplica(areaTerrenoAtingida, taxaOcupacao);

			dadosSubunidade.resultadoTOSubunidade = { value: resultadoTOSubunidade };
			dadosSubunidade.resultadoTOBase = { value: resultadoTOBase };
			dadosSubunidade.resultadoTOCorpo = { value: resultadoTOCorpo };

			// limpa dadosSubunidade de propriedades dependentes de tipo,
			// antes de carregá-las adequadamente
			// delete dadosSubunidade.totalAreaAdensavel;
			// delete dadosSubunidade.totalQtdEconomias;
			// delete dadosSubunidade.indiceAproveitamentoMaximo;

			// indice de aproveitamento
			dadosSubunidade.indiceAproveitamento = { value: '0' };

			// habitacao coletiva
			if (isEnqHabColetiva || (isColetiva && isUnifamiliarUmaOuDuas)) {
				const indiceAproveitamentoValue = multiplica(
					multiplica(areaTerrenoAtingida, indiceAproveitamentoRU),
					tombadoEstruturacaoTaxa
				);
				set(dadosSubunidade, 'indiceAproveitamento.value', indiceAproveitamentoValue);
			}

			// multifamiliar ou nao residenciais
			if (isMultifamiliar || isNaoResidenciais) {
				// atividades dessa subunidade
				const atividades = get(dadosSubunidade, 'dadosAtividades', []);
				const iamaximo = get(dadosSubunidade, 'indiceAproveitamentoMaximoAnexo.iamaximo', '0');
				let totalEconomias = '0';
				let totalAreaAd = '0';
				forEach(atividades, a => {
					const eco = get(a, 'qtdEconomias.value', '0');
					totalEconomias = soma(eco, totalEconomias, 0);
					const ad = get(a, 'areaAdensavel.value', '0');
					totalAreaAd = soma(ad, totalAreaAd);
				});
				set(dadosSubunidade, 'totalAreaAdensavel.value', totalAreaAd);
				set(dadosSubunidade, 'totalQtdEconomias.value', totalEconomias);
				const indiceAproveitamentoValue = multiplica(
					multiplica(indiceAproveitamentoRU, areaTerrenoAtingida),
					tombadoEstruturacaoTaxa
				);
				set(dadosSubunidade, 'indiceAproveitamento.value', indiceAproveitamentoValue);
				set(
					dadosSubunidade,
					'indiceAproveitamentoMaximo.value',
					iamaximo.includes('-') ? {} : multiplica(areaTerrenoAtingida, iamaximo)
				);
				// gambiarra por que estamos atualizando uma prop diretamente e não um
				// estado, então não dispara um novo render para colocar o valor na tela
				setUpdateUI(old => !old);
			}
		},
		[
			anexo72,
			areaTerrenoAtingida,
			taxaOcupacao,
			isEnqHabColetiva,
			isColetiva,
			isUnifamiliarUmaOuDuas,
			isMultifamiliar,
			isNaoResidenciais,
			indiceAproveitamentoRU,
			tombadoEstruturacaoTaxa
		]
	);

	useEffect(() => {
		calculos(dadosSubunidade);
	}, [indicesConstrutivos, dadosSubunidade, calculos]);

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			let { name, value } = changes;

			if (name === 'errors') {
				return;
			}
			if (name && name.indexOf('_') !== -1) {
				const i = name.indexOf('_');
				name = name.substring(0, i);
			}

			const subid = dadosSubunidade.id;
			const subunidades = get(planoDiretorData, ['dadosSubunidades'], []);
			const index = subunidades.findIndex(ds => ds.id === subid);

			// se nao possui altura de divisa e base, zera os campos internos
			if (name === 'possuiAlturaDivisaBase' && value === 'nao') {
				delete dadosSubunidade.alturaDivisaRegimeUrbanisticoAnexo;
				delete dadosSubunidade.alturaBaseRegimeUrbanisticoAnexo;
			}

			const subuni = Object.assign({}, dadosSubunidade, { [name]: value });

			calculos(subuni);

			set(subunidades, [index], subuni);
			set(planoDiretorData, 'dadosSubunidades', [...subunidades]);
			onChangeHandler && onChangeHandler({ name: PLANODIRETOR_CONTROLNAME, value: { ...planoDiretorData } });
		},
		[calculos, dadosSubunidade, onChangeHandler, planoDiretorData]
	);

	const addRestricaoImplantacao = useCallback(
		async (cru, catv) => {
			const dadosAtividade = get(dadosSubunidade, 'dadosAtividades.0');

			const url = `${PLANO_DIRETOR_API_URL}/restricao/${parseFloat(cru)}/${catv}`;

			try {
				const { data: codRI } = await accessApi(url, true);

				const ctabela = TABELA_RESTRICAO_IMPLANTACAO.find(item => item.descricao === codRI)?.codigo;
				const ri = (implantacaoAnexo53Store || []).find(item => item.codigo === ctabela);

				if (ri) {
					set(dadosAtividade, 'implantacaoAnexo53', ri);
					set(dadosAtividade, 'riAutoSelected', true);
					localChangeHandler && localChangeHandler({ name: 'dadosAtividades', value: [dadosAtividade] });
				}
			} catch (e) {
				localChangeHandler &&
					localChangeHandler({
						name: 'dadosAtividades.0',
						value: omit(dadosAtividade, ['implantacaoAnexo53', 'riAutoSelected'])
					});
			}
		},
		[dadosSubunidade, implantacaoAnexo53Store, localChangeHandler]
	);

	const addRestricaoPorte = useCallback(
		async (cru, catv) => {
			const dadosAtividade = get(dadosSubunidade, 'dadosAtividades.0');

			const url = `${PLANO_DIRETOR_API_URL}/porte/${parseFloat(cru)}/${catv}`;

			try {
				const { data: descricaoApi } = await accessApi(url, true);
				const ctabela = TABELA_PORTE.find(item => item.descricoes.find(desc => desc === descricaoApi))?.codigo;
				const rp = (porteAnexo54Store || []).find(item => item.codigo === ctabela);

				if (rp) {
					set(dadosAtividade, 'porteAnexo54', rp);
					set(dadosAtividade, 'porteAutoSelected', true);
					localChangeHandler && localChangeHandler({ name: 'dadosAtividades', value: [dadosAtividade] });
				}
			} catch (e) {
				localChangeHandler &&
					localChangeHandler({
						name: 'dadosAtividades.0',
						value: omit(dadosAtividade, ['porteAnexo54', 'porteAutoSelected'])
					});
			}
		},
		[dadosSubunidade, localChangeHandler, porteAnexo54Store]
	);

	// pega as restrições de implantacão e de porte
	if (
		(isUnifamiliarUmaOuDuas || isMultifamiliar || isEnqHabColetiva) &&
		atividadeModificada &&
		atvCod &&
		gaCod &&
		!jaRegistrouRestricoes
	) {
		setJaRegistrouRestricoes(true);
		const p1 = addRestricaoImplantacao(gaCod, atvCod);
		const p2 = addRestricaoPorte(gaCod, atvCod);
		Promise.all([p1, p2]).finally(() => {
			atividadeModificadaCallback && atividadeModificadaCallback();
		});
	}

	useEffect(() => {
		let somaAreasAtingidas = '0';
		const subs = get(planoDiretorData, ['dadosSubunidades'], []);
		subs.forEach(sub => {
			somaAreasAtingidas = soma(sub.areaTerrenoAtingida?.value, somaAreasAtingidas);
		});

		const mustShow = somaAreasAtingidas !== localData?.areaAplicacaoRU?.value;

		if (mustShow !== showAvisoAreaAplicacaoRu) {
			setShowAvisoAreaAplicacaoRu(mustShow);
		}
	}, [localData, planoDiretorData, setShowAvisoAreaAplicacaoRu, showAvisoAreaAplicacaoRu]);

	useEffect(() => {
		const mustShow =
			parseFloat(get(dadosSubunidade, 'alturaDivisaRegimeUrbanisticoAnexo.value')) >
			parseFloat(limiteDivisaRegimeUrbanisticoAnexo?.codigo);
		if (mustShow !== showAvisoAlturaDivisa) {
			setShowAvisoAlturaDivisa(mustShow);
		}
	}, [dadosSubunidade, limiteDivisaRegimeUrbanisticoAnexo, showAvisoAlturaDivisa]);

	useEffect(() => {
		const mustShow =
			parseFloat(get(dadosSubunidade, 'alturaBaseRegimeUrbanisticoAnexo.value')) >
			parseFloat(limiteBaseRegimeUrbanisticoAnexo?.codigo);
		if (mustShow !== showAvisoAlturaBase) {
			setShowAvisoAlturaBase(mustShow);
		}
	}, [dadosSubunidade, limiteBaseRegimeUrbanisticoAnexo, showAvisoAlturaBase]);

	useEffect(() => {
		const desabilitarConferenciaAltura =
			get(dadosSubunidade, 'contemplaAcomodTelhadoSotaoMezanino') === 'sim' ||
			get(dadosSubunidade, 'contemplaRooftops') === 'sim';

		const mustShow =
			!desabilitarConferenciaAltura &&
			parseFloat(get(dadosSubunidade, 'alturaProjeto.value')) > parseFloat(limiteAlturaMaxima?.codigo);
		if (mustShow !== showAvisoAlturaMaxima) {
			setShowAvisoAlturaMaxima(mustShow);
		}
	}, [dadosSubunidade, limiteAlturaMaxima, showAvisoAlturaMaxima]);

	// effect dos states de valores
	useEffect(() => {
		const anexo72 = get(dadosSubunidade, 'possuiAnexo72');
		const to = anexo72 ? get(dadosSubunidade, 'toRegimeUrbanisticoAnexo.codigo') : '0';
		if (to !== taxaOcupacao) {
			setTaxaOcupacao(to);
		}
	}, [dadosSubunidade, taxaOcupacao]);

	useEffect(() => {
		const ia = get(dadosSubunidade, 'iaRegimeUrbanisticoAnexo.codigo');
		if (ia !== indiceAproveitamentoRU) {
			setIndiceAproveitamentoRU(ia);
		}
	}, [dadosSubunidade, indiceAproveitamentoRU]);

	useEffect(() => {
		const at = get(dadosSubunidade, 'areaTerrenoAtingida.value', '0');
		if (at !== areaTerrenoAtingida) {
			setAreaTerrenoAtingida(at);
		}
	}, [areaTerrenoAtingida, dadosSubunidade]);

	useEffect(() => {
		const tombadoEstruturacaoTaxaAux =
			restricoesAdmData?.predioTombadoInventariado === 'imoveltombado' ||
			restricoesAdmData?.predioTombadoInventariado === 'imovelinventariadodeestruturacao'
				? '1.25'
				: '1';
		if (tombadoEstruturacaoTaxaAux !== tombadoEstruturacaoTaxa) {
			setTombadoEstruturacaoTaxa(tombadoEstruturacaoTaxaAux);
		}
	}, [restricoesAdmData, tombadoEstruturacaoTaxa]);

	// effects para setar os recalculos
	useEffect(() => {
		setRecalcular(true);
	}, [taxaOcupacao, indiceAproveitamentoRU, areaTerrenoAtingida, tombadoEstruturacaoTaxa]);

	// effect para realizar o recaltulo
	useEffect(() => {
		if (recalcular) {
			localChangeHandler({});
			setRecalcular(false);
		}
	}, [recalcular, localChangeHandler]);

	// const getTippy = desc => {
	// 	getTippy.displayName = 'getTippy';
	// 	return desc ? (
	// 		desc.length > 40 ? (
	// 			<div>
	// 				<p>Preenchido automaticamente pelo sistema</p>
	// 				<p>{desc}</p>
	// 			</div>
	// 		) : (
	// 			'Preenchido automaticamente pelo sistema'
	// 		)
	// 	) : null;
	// };

	const isPossuiAlturaDivisa = dadosSubunidade?.possuiAlturaDivisa === 'sim';
	const isPossuiAlturaBase = dadosSubunidade?.possuiAlturaBase === 'sim';
	// const tippy53 = getTippy(i53);
	// const tippy54 = getTippy(p54);

	const descricaoAdvertenciaImplantacao = TABELA_RESTRICAO_IMPLANTACAO.find(item => item.codigo === '11').descricao;
	const descricaoAdvertenciaPorte = TABELA_PORTE.find(item => item.codigo === '18').descricoes[0];
	const advertenciaRestricoes = i53 === descricaoAdvertenciaImplantacao && p54 === descricaoAdvertenciaPorte;
	setAdvertenciaRestricoes(advertenciaRestricoes);

	return (
		<div className="conjunto-dados-projeto-subunidade">
			<div>
				<div>
					<h2 className="titulo-subunidade-ap">Subunidade {nomeid}</h2>
				</div>
				<>
					<PlanoDiretorAtividades
						data={dadosSubunidade}
						setDataHandler={(value, name) => localChangeHandler({ name, value })}
						showAtividadesItems={atividadesPermitidas}
						readOnly={readOnly}
						disabled={disabled}
					/>
					{(errosSubmissao || []).some(e => e.campo === 'atividadesObrigatorias') && (
						<ErrorMessages
							errorList={(errosSubmissao || []).filter(e => (e.campo === 'atividadesObrigatorias' ? e.message : null))}
						/>
					)}
					{/* <h3>Dados projeto</h3> */}
					<div
						className={`pergunta metragem ${
							temComentario[`areaTerrenoAtingida${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<NumberFieldLE
							label={`Área para aplicação do Regime Urbanístico que incide na subunidade ${nomeid} (m²)`}
							name="areaTerrenoAtingida"
							value={get(dadosSubunidade, 'areaTerrenoAtingida')}
							readOnly={false}
							onChangeHandler={localChangeHandler}
							maxSize="10"
							decimals={2}
							placeHolder="0,00"
							required={true}
							disabled={disabled}
						/>
						{(errosSubmissao || []).some(e => e.campo === 'areaTerrenoAtingida') ? (
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e => (e.campo === 'areaTerrenoAtingida' ? e.message : null))}
							/>
						) : showAvisoAreaAplicacaoRu ? (
							<WarningMessage>
								A soma das áreas que incidem em todas as subunidades deve ser igual a Área para aplicação do Regime
								Urbanístico (m²)
							</WarningMessage>
						) : null}
						<HistoricoRespostaAnterior
							dataAnterior={get(dadosSubunidadeAnterior, 'areaTerrenoAtingida')}
							dataAtual={get(dadosSubunidade, 'areaTerrenoAtingida')}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`areaTerrenoAtingida${sufixoComentario}`}
							valueComentario={get(dadosSubunidade, `areaTerrenoAtingida${sufixoComentario}`)}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`areaTerrenoAtingida${sufixoComentario}`]}
						/>
					</div>

					{(isMultifamiliar || isMultifamiliarNaoResidencial) &&
						get(dadosSubunidade, 'deveUsarQuotaIdealSubunidade') && (
							<div
								className={`pergunta metragem ${
									temComentario[`quantidadeMaxEconomiasResidenciaisSubunidadeArredondada${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									name="quantidadeMaxEconomiasResidenciaisSubunidadeArredondada"
									label="Resultado da Quota Ideal da Subunidade"
									value={{
										value: `${get(dadosSubunidade, 'quantidadeMaxEconomiasResidenciaisSubunidadeArredondada') || 0}`
									}}
									decimals={0}
									maxSize="10"
									onChangeHandler={localChangeHandler}
									required={true}
									readOnly={true}
									disabled={disabled}
								/>
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'quantidadeMaxEconomiasResidenciaisSubunidadeArredondada')}
									dataAtual={get(dadosSubunidade, 'quantidadeMaxEconomiasResidenciaisSubunidadeArredondada')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`quantidadeMaxEconomiasResidenciaisSubunidadeArredondada${sufixoComentario}`}
									valueComentario={get(
										dadosSubunidade,
										`quantidadeMaxEconomiasResidenciaisSubunidadeArredondada${sufixoComentario}`
									)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={
										temComentario[`quantidadeMaxEconomiasResidenciaisSubunidadeArredondada${sufixoComentario}`]
									}
								/>
							</div>
						)}

					<div
						className={`pergunta metragem ${
							temComentario[`alturaProjeto${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<NumberFieldLE
							label="Altura do projeto conforme o Art. 112, §1º, III do PDDUA (m)"
							name={'alturaProjeto'}
							value={get(dadosSubunidade, 'alturaProjeto')}
							readOnly={false}
							disabled={disabled}
							onChangeHandler={localChangeHandler}
							maxSize="10"
							decimals={2}
							placeHolder="0,00"
							required={true}
						/>
						{(errosSubmissao || []).some(e => dadosSubunidade.id === e.idSub && e.campo === 'alturaProjeto') ? (
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e =>
									dadosSubunidade.id === e.idSub && e.campo === 'alturaProjeto' ? e.message : null
								)}
							/>
						) : showAvisoAlturaMaxima ? (
							<WarningMessage>
								{`A altura do projeto deve ser menor ou igual ao limite do Plano
									Diretor (${limiteAlturaMaxima?.descricao} m), exceto em caso de
									Estudo de Viabilidade Urbanístico aprovado e válido.`}
							</WarningMessage>
						) : null}
						<HistoricoRespostaAnterior
							dataAnterior={get(dadosSubunidadeAnterior, 'alturaProjeto')}
							dataAtual={get(dadosSubunidade, 'alturaProjeto')}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`alturaProjeto${sufixoComentario}`}
							valueComentario={get(dadosSubunidade, `alturaProjeto${sufixoComentario}`)}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`alturaProjeto${sufixoComentario}`]}
						/>
					</div>

					<div
						className={`pergunta ${
							temComentario[`contemplaAcomodTelhadoSotaoMezanino_${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<RadioFieldLE
							label='O projeto contempla o benefício do Art. 113, Inc. II, alínea "c" (acomodação do telhado) e/ou alínea "e" (sótão ou mezanino, etc.) do PDDUA?'
							name={`contemplaAcomodTelhadoSotaoMezanino_${nomeid}`}
							value={get(dadosSubunidade, 'contemplaAcomodTelhadoSotaoMezanino')}
							options={OPT_SIM_NAO}
							onChangeHandler={localChangeHandler}
							required={true}
							disabled={disabled}
						/>
						<ErrorMessages
							errorList={(errosSubmissao || []).filter(e =>
								dadosSubunidade.id === e.idSub && e.campo === 'contemplaAcomodTelhadoSotaoMezanino' ? e.message : null
							)}
						/>
						<HistoricoRespostaAnterior
							dataAnterior={get(dadosSubunidadeAnterior, 'contemplaAcomodTelhadoSotaoMezanino_')}
							dataAtual={get(dadosSubunidade, 'contemplaAcomodTelhadoSotaoMezanino_')}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`contemplaAcomodTelhadoSotaoMezanino_${sufixoComentario}`}
							valueComentario={get(dadosSubunidade, `contemplaAcomodTelhadoSotaoMezanino_${sufixoComentario}`)}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`contemplaAcomodTelhadoSotaoMezanino_${sufixoComentario}`]}
						/>
					</div>
					{!(isUnifamiliarUmaOuDuas || isEnqHabColetiva) && (
						<div
							className={`pergunta ${
								temComentario[`contemplaRooftops_${nomeid}${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								label="O projeto contempla a existência de rooftops nos termos do Decreto Municipal nº 20.746/20?"
								name={`contemplaRooftops_${nomeid}`}
								value={get(dadosSubunidade, 'contemplaRooftops')}
								options={OPT_SIM_NAO}
								onChangeHandler={localChangeHandler}
								required={true}
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e =>
									dadosSubunidade.id === e.idSub && e.campo === 'contemplaRooftops' ? e.message : null
								)}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={get(dadosSubunidadeAnterior, `contemplaRooftops_${nomeid}`)}
								dataAtual={get(dadosSubunidade, `contemplaRooftops_${nomeid}`)}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`contemplaRooftops_${nomeid}${sufixoComentario}`}
								valueComentario={get(dadosSubunidade, `contemplaRooftops_${nomeid}${sufixoComentario}`)}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`contemplaRooftops_${nomeid}${sufixoComentario}`]}
							/>
						</div>
					)}
					{/* {(isUnifamiliarUmaOuDuas || isMultifamiliar || isEnqHabColetiva) && (i53 || p54) && (
							<div className="pergunta" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
								<div style={{ position: 'relative' }}>
									<TextField
										label="Restrição quanto à implantação de atividades (Anexo 5.3)"
										value={ellipsis(i53, 45)}
										readOnly={true}
										disabled={disabled}
										tippy={tippy53}
									/>
								</div>
								<div style={{ position: 'relative' }}>
									<TextField
										label="Restrição quanto ao porte (Anexo 5.4)"
										value={ellipsis(p54, 45)}
										readOnly={true}
										disabled={disabled}
										tippy={tippy54}
									/>
								</div>
								{advertenciaRestricoes && (
									<span style={{ gridColumn: '1 / span 2' }}>
										<ErrorMessages
											type="warning"
											errorList={[
												{
													type: 'warning',
													message:
														'A atividade necessita de Estudo de Viabilidade Urbanística aprovado e válido para esta subunidade'
												}
											]}
										/>
									</span>
								)}
							</div>
						)} */}
					<div
						className={`pergunta ${
							temComentario[`possuiAlturaDivisa_${nomeid}${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<RadioFieldLE
							name={`possuiAlturaDivisa_${nomeid}`}
							label="O projeto possui altura de divisa?"
							value={dadosSubunidade.possuiAlturaDivisa}
							options={OPT_SIM_NAO}
							onChangeHandler={localChangeHandler}
							required={true}
							disabled={disabled}
						/>
						<ErrorMessages
							errorList={(errosSubmissao || []).filter(e =>
								dadosSubunidade.id === e.idSub && e.campo === 'possuiAlturaDivisa' ? e.message : null
							)}
						/>
						<HistoricoRespostaAnterior
							dataAnterior={get(dadosSubunidadeAnterior, `possuiAlturaDivisa_${nomeid}`)}
							dataAtual={get(dadosSubunidade, `possuiAlturaDivisa_${nomeid}`)}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`possuiAlturaDivisa_${nomeid}${sufixoComentario}`}
							valueComentario={get(dadosSubunidade, `possuiAlturaDivisa_${nomeid}${sufixoComentario}`)}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`possuiAlturaDivisa_${nomeid}${sufixoComentario}`]}
						/>
					</div>

					{isPossuiAlturaDivisa && (
						<>
							<div
								className={`pergunta metragem ${
									temComentario[`alturaDivisaRegimeUrbanisticoAnexo${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									label="Altura da Divisa (m)"
									name="alturaDivisaRegimeUrbanisticoAnexo"
									value={get(dadosSubunidade, 'alturaDivisaRegimeUrbanisticoAnexo')}
									readOnly={false}
									disabled={disabled}
									maxSize="10"
									onChangeHandler={localChangeHandler}
									decimals={2}
									required={true}
								/>
								{(errosSubmissao || []).some(
									e => dadosSubunidade.id === e.idSub && e.campo === 'alturaDivisaRegimeUrbanisticoAnexo'
								) ? (
									<ErrorMessages
										errorList={(errosSubmissao || []).filter(e =>
											dadosSubunidade.id === e.idSub && e.campo === 'alturaDivisaRegimeUrbanisticoAnexo'
												? e.message
												: null
										)}
									/>
								) : showAvisoAlturaDivisa ? (
									<WarningMessage>
										{'A altura da divisa deve ser menor ou igual ao limite do ' +
											`Plano Diretor (${limiteDivisaRegimeUrbanisticoAnexo?.descricao} m), ` +
											'exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'}
									</WarningMessage>
								) : null}
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'alturaDivisaRegimeUrbanisticoAnexo')}
									dataAtual={get(dadosSubunidade, 'alturaDivisaRegimeUrbanisticoAnexo')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`alturaDivisaRegimeUrbanisticoAnexo${sufixoComentario}`}
									valueComentario={get(dadosSubunidade, `alturaDivisaRegimeUrbanisticoAnexo${sufixoComentario}`)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`alturaDivisaRegimeUrbanisticoAnexo${sufixoComentario}`]}
								/>
							</div>
						</>
					)}
					{dadosSubunidade?.hideAlturaBase ? (
						<div className="pergunta metragem">
							<WarningMessage>{MSG_BASE_DESATIVADA}</WarningMessage>
						</div>
					) : (
						<div
							className={`pergunta ${
								temComentario[`possuiAlturaBase_${nomeid}${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name={`possuiAlturaBase_${nomeid}`}
								label="O projeto possui altura de base?"
								value={dadosSubunidade.possuiAlturaBase}
								options={OPT_SIM_NAO}
								onChangeHandler={localChangeHandler}
								required={true}
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={(errosSubmissao || []).filter(e =>
									dadosSubunidade.id === e.idSub && e.campo === 'possuiAlturaBase' ? e.message : null
								)}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={get(dadosSubunidadeAnterior, `possuiAlturaBase_${nomeid}`)}
								dataAtual={get(dadosSubunidade, `possuiAlturaBase_${nomeid}`)}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`possuiAlturaBase_${nomeid}${sufixoComentario}`}
								valueComentario={get(dadosSubunidade, `possuiAlturaBase_${nomeid}${sufixoComentario}`)}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`possuiAlturaBase_${nomeid}${sufixoComentario}`]}
							/>
						</div>
					)}
					{isPossuiAlturaBase && (
						<>
							<div
								className={`pergunta metragem ${
									temComentario[`alturaBaseRegimeUrbanisticoAnexo${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									label="Altura da Base (m)"
									name="alturaBaseRegimeUrbanisticoAnexo"
									value={get(dadosSubunidade, 'alturaBaseRegimeUrbanisticoAnexo')}
									readOnly={false}
									onChangeHandler={localChangeHandler}
									maxSize="10"
									decimals={2}
									required={true}
									disabled={disabled}
								/>
								{(errosSubmissao || []).some(
									e => dadosSubunidade.id === e.idSub && e.campo === 'alturaBaseRegimeUrbanisticoAnexo'
								) ? (
									<ErrorMessages
										errorList={(errosSubmissao || []).filter(
											e => dadosSubunidade.id === e.idSub && e.campo === 'alturaBaseRegimeUrbanisticoAnexo'
										)}
									/>
								) : showAvisoAlturaBase ? (
									<WarningMessage>
										{'A altura da base deve ser menor ou igual ao limite do Plano Diretor (' +
											`${limiteBaseRegimeUrbanisticoAnexo?.descricao} m), exceto em caso de ` +
											'Estudo de Viabilidade Urbanístico aprovado e válido.'}
									</WarningMessage>
								) : null}
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'alturaBaseRegimeUrbanisticoAnexo')}
									dataAtual={get(dadosSubunidade, 'alturaBaseRegimeUrbanisticoAnexo')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`alturaBaseRegimeUrbanisticoAnexo${sufixoComentario}`}
									valueComentario={get(dadosSubunidade, `alturaBaseRegimeUrbanisticoAnexo${sufixoComentario}`)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`alturaBaseRegimeUrbanisticoAnexo${sufixoComentario}`]}
								/>
							</div>
						</>
					)}
					{/* INICIO */}

					{!showAvisoAreaAplicacaoRu && (
						<>
							{(isColetiva || isMultifamiliar || isNaoResidenciais) && (
								<div
									className={`pergunta metragem ${
										temComentario[`indiceAproveitamento${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<NumberFieldLE
										name="indiceAproveitamento"
										label={`${
											isDebug ? '1 ' : ''
										}Resultado da Área aplicação do Regime Urbanístico x Índice de Aproveitamento (IA) da Subunidade (m²)`}
										value={dadosSubunidade.indiceAproveitamento}
										readOnly={true}
										disabled={disabled}
										maxSize="10"
										decimals={2}
									/>
									<HistoricoRespostaAnterior
										dataAnterior={get(dadosSubunidadeAnterior, 'indiceAproveitamento')}
										dataAtual={get(dadosSubunidade, 'indiceAproveitamento')}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`indiceAproveitamento${sufixoComentario}`}
										valueComentario={get(dadosSubunidade, `indiceAproveitamento${sufixoComentario}`)}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`indiceAproveitamento${sufixoComentario}`]}
									/>
								</div>
							)}
							{(isMultifamiliar || isNaoResidenciais) && (
								<>
									<div
										className={`pergunta metragem ${
											temComentario[`indiceAproveitamentoMaximo${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="indiceAproveitamentoMaximo"
											label={`${
												isDebug ? '2 ' : ''
											}Resultado da Área aplicação do Regime Urbanístico x Índice de Aproveitamento (IA) Máximo da Subunidade (m²)`}
											value={dadosSubunidade.indiceAproveitamentoMaximo}
											readOnly={true}
											disabled={disabled}
											maxSize="10"
											decimals={2}
										/>
										<HistoricoRespostaAnterior
											dataAnterior={get(dadosSubunidadeAnterior, 'indiceAproveitamentoMaximo')}
											dataAtual={get(dadosSubunidade, 'indiceAproveitamentoMaximo')}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`indiceAproveitamentoMaximo${sufixoComentario}`}
											valueComentario={get(dadosSubunidade, `indiceAproveitamentoMaximo${sufixoComentario}`)}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`indiceAproveitamentoMaximo${sufixoComentario}`]}
										/>
									</div>
								</>
							)}
							<div
								className={`pergunta metragem ${
									temComentario[`resultadoTOSubunidade${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									name="resultadoTOSubunidade"
									label={`${
										isDebug ? '3 ' : ''
									}Resultado da Área aplicação do Regime Urbanístico x T.O. da Subunidade (m²)`}
									value={dadosSubunidade.resultadoTOSubunidade}
									readOnly={true}
									disabled={disabled}
									maxSize="10"
									decimals={2}
								/>
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'resultadoTOSubunidade')}
									dataAtual={get(dadosSubunidade, 'resultadoTOSubunidade')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`resultadoTOSubunidade${sufixoComentario}`}
									valueComentario={get(dadosSubunidade, `resultadoTOSubunidade${sufixoComentario}`)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`resultadoTOSubunidade${sufixoComentario}`]}
								/>
							</div>
							<div
								className={`pergunta metragem ${
									temComentario[`resultadoTOBase${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									name="resultadoTOBase"
									label={`${isDebug ? '4 ' : ''}Resultado da Área aplicação do Regime Urbanístico x T.O. da Base (m²)`}
									value={dadosSubunidade.resultadoTOBase}
									readOnly={true}
									disabled={disabled}
									maxSize="10"
									decimals={2}
								/>
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'resultadoTOBase')}
									dataAtual={get(dadosSubunidade, 'resultadoTOBase')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`resultadoTOBase${sufixoComentario}`}
									valueComentario={get(dadosSubunidade, `resultadoTOBase${sufixoComentario}`)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`resultadoTOBase${sufixoComentario}`]}
								/>
							</div>
							<div
								className={`pergunta metragem ${
									temComentario[`resultadoTOCorpo${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									name="resultadoTOCorpo"
									label={`${isDebug ? '5 ' : ''}Resultado da Área aplicação do Regime Urbanístico x T.O. do Corpo (m²)`}
									value={dadosSubunidade.resultadoTOCorpo}
									readOnly={true}
									disabled={disabled}
									maxSize="10"
									decimals={2}
								/>
								<HistoricoRespostaAnterior
									dataAnterior={get(dadosSubunidadeAnterior, 'resultadoTOCorpo')}
									dataAtual={get(dadosSubunidade, 'resultadoTOCorpo')}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`resultadoTOCorpo${sufixoComentario}`}
									valueComentario={get(dadosSubunidade, `resultadoTOCorpo${sufixoComentario}`)}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`resultadoTOCorpo${sufixoComentario}`]}
								/>
							</div>
						</>
					)}
					{/* FIM */}
				</>
			</div>
		</div>
	);
}

InfosProjetoSubUnidadesAP.displayName = 'InfosProjetoSubUnidadesAP';
InfosProjetoSubUnidadesAP.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	dadosSubunidade: PropTypes.object,
	dadosSubunidadeAnterior: PropTypes.object,
	atividadeModificada: PropTypes.bool,
	atividadeModificadaCallback: PropTypes.func,
	errosSubmissao: PropTypes.array,
	indicesConstrutivos: PropTypes.bool,
	showAvisoAreaAplicacaoRu: PropTypes.bool,
	deveUsarQuotaIdealProjeto: PropTypes.bool,
	setShowAvisoAreaAplicacaoRu: PropTypes.func,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	setAdvertenciaRestricoes: PropTypes.func,
	readOnlyVersao: PropTypes.any
};
