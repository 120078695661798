import React, { useCallback, useState, useMemo, useEffect, Fragment, useRef } from 'react';

import PropTypes from 'prop-types';

import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, get, set, size } from 'lodash';

import RadioComponent from 'components/RadioComponent';
import ShowDebug from 'components/ShowDebug';

import { TextField } from 'containers/Form/metadata-template/fields';

import { isDebug } from 'utils/tools';

import { includesTipoForm } from '../InfosProjetoAP';
import { TIPOS_AREAS_LABELS_PLANILHA_ENUM, TIPOS_METRAGENS_ENUM, UAP_ENQUADRAMENTOS_ENUM } from './constantsAP';
import { LinhaTabelaAreasAP } from './LinhaTabelaAreasAP';
import useTableAreas from './useTabelaAreas';

const { UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO } = UAP_ENQUADRAMENTOS_ENUM;

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(DOC-ESP-UAP-PLANILHA)', ++i, ...args);

export function TabelaAreasAP({ data, onChangeHandler, tipoForm, disabled = false }) {
	const [isFocoMode, setIsFocoMode] = useState(false);

	const entrarModoFoco = () => setIsFocoMode(true);
	const sairModoFoco = () => setIsFocoMode(false);

	const detalhesAreas = get(data, 'detalhesAreas', {});

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				/* eslint-disable-next-line no-unused-vars */
				const [valid, invalid] = changes;
				changes = valid;
			}
			const { name: controlName, value } = changes;

			if (controlName === 'errors') return;

			// set novo valor
			let newDetalhes = cloneDeep(detalhesAreas);

			// set o valor da coluna
			set(newDetalhes, controlName, value);

			// adiciona totais aos detalhesAreas (planilha)
			newDetalhes = calcularTotais(newDetalhes);

			// change handler
			onChangeHandler && onChangeHandler({ name: 'detalhesAreas', value: newDetalhes });
		},
		[detalhesAreas, onChangeHandler]
	);

	const colunaEconomiaRef = useRef();
	const colunaPavimentoRef = useRef();
	const colunaNatEspRef = useRef();

	const { areasPlanilha = [], economias = [] } = detalhesAreas;

	const isUnifamiliarUmaEconomia = includesTipoForm([UNIFAMILIAR_01_ECONO], tipoForm);
	const isUnifamiliarDuasEconomias = includesTipoForm([UNIFAMILIAR_02_ECONO], tipoForm);
	const isUnifamiliar = isUnifamiliarUmaEconomia || isUnifamiliarDuasEconomias;

	const {
		config,
		setTipoForm,
		getHeaderColspan,
		getSubtotalColspan,
		getHeaderRowspan,
		getFillerRowspan,
		getFooterRowspan
	} = useTableAreas(tipoForm);

	const isResidencial_e_naoResidencial = config.showRes && config.showNaoRes;

	const addNatEsp = useCallback(
		({ economiaIndex, pavimentoIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos[pavimentoIndex].naturezasEspecies.push({});
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const deleteNatEsp = useCallback(
		({ economiaIndex, pavimentoIndex, natEspIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos[pavimentoIndex].naturezasEspecies.splice(natEspIndex, 1);
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const addPavimento = useCallback(
		({ economiaIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos.push({ naturezasEspecies: [{}] });
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const deletePavimento = useCallback(
		({ economiaIndex, pavimentoIndex }) => {
			const economiasAux = cloneDeep(economias);
			economiasAux[economiaIndex].pavimentos.splice(pavimentoIndex, 1);
			localChangeHandler([{ name: 'economias', value: economiasAux }]);
		},
		[economias, localChangeHandler]
	);

	const addEconomia = useCallback(
		descricao => {
			localChangeHandler([
				{ name: 'economias', value: [...(economias || []), { descricao, pavimentos: [{ naturezasEspecies: [{}] }] }] }
			]);
		},
		[economias, localChangeHandler]
	);

	const deleteEconomia = useCallback(
		({ economiaIndex }) => {
			economias.splice(economiaIndex, 1);
			localChangeHandler([{ name: 'economias', value: [...economias] }]);
		},
		[economias, localChangeHandler]
	);

	useEffect(() => {
		if (size(economias) === 0) {
			if (isUnifamiliarUmaEconomia) {
				localChangeHandler([
					{
						name: 'economias',
						value: [...(economias || []), { descricao: 'Economia 01', pavimentos: [{ naturezasEspecies: [{}] }] }]
					}
				]);
			} else if (isUnifamiliarDuasEconomias) {
				localChangeHandler([
					{
						name: 'economias',
						value: [
							...(economias || []),
							{ descricao: 'Economia 01', pavimentos: [{ naturezasEspecies: [{}] }] },
							{ descricao: 'Economia 02', pavimentos: [{ naturezasEspecies: [{}] }] }
						]
					}
				]);
			} else {
				addEconomia('');
			}
		}
		debugLog('economias', economias);
	}, [addEconomia, economias, isUnifamiliarDuasEconomias, isUnifamiliarUmaEconomia, localChangeHandler]);

	const showCabecalhoResidencialNaoResidencial = useMemo(
		() => config.showRes && config.showNaoRes,
		[config.showNaoRes, config.showRes]
	);

	const qtdEconomias = size(economias);

	// 3 linhas se tiver precisar cabeçalho para tipos de economia (residencia e não residencial)
	const qtdLinhasCabecalho = useMemo(
		() => (showCabecalhoResidencialNaoResidencial ? 3 : 2),
		[showCabecalhoResidencialNaoResidencial]
	);

	const colSpanSubtotaisHorizontal = useMemo(() => {
		let largura = 1;
		if (config.showColEco) {
			largura++;
		}
		if (config.showColPav) {
			largura++;
		}
		return largura === 0 ? 1 : largura;
	}, [config.showColEco, config.showColPav]);

	const rowSpanSubtotaisRodape = useMemo(() => {
		let colSpan = qtdLinhasCabecalho;
		if (size(detalhesAreas.areasPlanilha) === 1) {
			colSpan--;
		}
		return colSpan;
	}, [detalhesAreas, qtdLinhasCabecalho]);

	const tiposEconomia = useMemo(() => {
		const saida = [];
		if (config.showRes || !config.showNaoRes) {
			saida.push('residencial');
		}
		if (config.showNaoRes) {
			saida.push('naoResidencial');
		}
		return saida;
	}, [config.showNaoRes, config.showRes]);

	const temPavimentoComMaisDeUmNatEsp = useMemo(
		() =>
			detalhesAreas.economias.reduce(
				(acc, { pavimentos }) =>
					acc || pavimentos.reduce((acc2, { naturezasEspecies }) => acc2 || size(naturezasEspecies) > 1, false),
				false
			),
		[detalhesAreas]
	);
	const temEconomiaComMaisDeUmPavimento = useMemo(
		() => detalhesAreas.economias.reduce((acc, { pavimentos }) => acc || size(pavimentos) > 1, false),
		[detalhesAreas]
	);

	return (
		<>
			<div className={`${isFocoMode ? ' modo-foco' : ''}`}>
				{isDebug && (
					<div style={{ border: '7px solid yellow', padding: '10px', margin: '10px 0px' }}>
						<h3>
							Enquadramento: {tipoForm}
							<small className="debug-message">(somente em debug)</small>
						</h3>
						<RadioComponent
							label="Selecione o enquadramento para a tabela de áreas"
							options={Object.values(UAP_ENQUADRAMENTOS_ENUM).map(codigo => ({
								codigo,
								descricao: codigo
							}))}
							value={config.tipoForm}
							onChangeHandler={setTipoForm}
							disabled={disabled}
						/>
						<ShowDebug data={{ config, tipoForm }} />
					</div>
				)}
				{isFocoMode ? (
					<button
						type="button"
						className={`btn btn-sm btn-tabela-areas btn-modo-foco ${
							disabled ? 'btn-outline-primary2' : 'btn-outline-primary'
						}`}
						onClick={sairModoFoco}
					>
						Sair do modo foco&nbsp;
						<FontAwesomeIcon icon={faExpandArrowsAlt} />
					</button>
				) : (
					<button
						type="button"
						className={`btn btn-sm btn-tabela-areas btn-modo-foco ${
							disabled ? 'btn-outline-primary2' : 'btn-outline-primary'
						}`}
						onClick={entrarModoFoco}
					>
						Expandir tabela &nbsp;
						<FontAwesomeIcon icon={faExpandArrowsAlt} />
					</button>
				)}
				<div className="table-sticky-container">
					<table className="table table-sm table-ap" id="table-areas">
						<tbody>
							{/* CABEÇALHO */}
							<tr>
								<th
									colSpan={getHeaderColspan(detalhesAreas)}
									className="celula-descricao-principal"
									rowSpan={
										config.showColEco || config.showColPav || config.showColNatEsp
											? getHeaderRowspan(detalhesAreas)
											: getFillerRowspan(detalhesAreas)
									}
								>
									{config.showColEco ? (
										isUnifamiliar ? (
											<span>Economias</span>
										) : (
											!disabled &&
											config.showAddEco && (
												<button
													type="button"
													className="btn btn-outline-primary btn-sm btn-tabela-areas"
													onClick={() => addEconomia()}
												>
													<i className="fa fa-plus"></i> BLOCO
												</button>
											)
										)
									) : !disabled && config.showColPav ? (
										config.showAddPav && (
											<button
												type="button"
												className="btn btn-outline-primary btn-sm btn-tabela-areas"
												onClick={() => addPavimento({ economiaIndex: 0 })}
											>
												<i className="fa fa-plus"></i> PAVIMENTO
											</button>
										)
									) : !disabled && config.showColNatEsp ? (
										config.showAddNatEsp && (
											<button
												type="button"
												className="btn btn-outline-primary btn-sm btn-tabela-areas"
												onClick={() => addNatEsp({ economiaIndex: 0, pavimentoIndex: 0 })}
											>
												<i className="fa fa-plus"></i> Natureza / Espécie
											</button>
										)
									) : null}
								</th>

								{areasPlanilha?.map((area, index) => (
									<Fragment key={index}>
										<th colSpan={tiposEconomia.length * size(TIPOS_METRAGENS_ENUM)}>
											{TIPOS_AREAS_LABELS_PLANILHA_ENUM[area]}
										</th>
									</Fragment>
								))}
								<td
									className="subtotais"
									colSpan={getSubtotalColspan(detalhesAreas)}
									rowSpan={getHeaderRowspan(detalhesAreas)}
								>
									Sub-Totais (m²)
								</td>
							</tr>
							{showCabecalhoResidencialNaoResidencial && (
								<tr>
									{areasPlanilha?.map((_, index) => (
										<Fragment key={index}>
											<>
												<th colSpan={size(TIPOS_METRAGENS_ENUM)}>Residencial</th>
												<th colSpan={size(TIPOS_METRAGENS_ENUM)}>Não Residencial</th>
											</>
										</Fragment>
									))}
								</tr>
							)}
							<tr>
								{areasPlanilha?.map((_, index) => (
									<Fragment key={index}>
										{Object.keys(TIPOS_METRAGENS_ENUM).map((key, i) => (
											<th key={i}>{TIPOS_METRAGENS_ENUM[key]}</th>
										))}
										{showCabecalhoResidencialNaoResidencial &&
											Object.keys(TIPOS_METRAGENS_ENUM).map((key, i) => <th key={i}>{TIPOS_METRAGENS_ENUM[key]}</th>)}
									</Fragment>
								))}
							</tr>

							{/* CORPO */}
							{detalhesAreas?.economias?.map((economia = {}, economiaIndex) => {
								const { pavimentos = [], descricao: descEconomia } = economia;
								const qtdPavimentos = size(pavimentos);
								const economiaPrefix = `economias.${economiaIndex}`;

								return pavimentos.map((pavimento, pavimentoIndex) => {
									const { naturezasEspecies = [], descricao: descPavimento } = pavimento;
									const isFirstPavimento = pavimentoIndex === 0;
									const qtdNatEsps = size(naturezasEspecies);
									const pavimentoPrefix = `${economiaPrefix}.pavimentos.${pavimentoIndex}`;

									return naturezasEspecies.map((natEsp, natEspIndex) => {
										const { descricao: descNatEsp } = natEsp;
										const isFirstNatEsp = natEspIndex === 0;
										const natEspPrefix = `${economiaPrefix}.pavimentos.${pavimentoIndex}.naturezasEspecies.${natEspIndex}`;

										return (
											<Fragment key={`${economiaIndex}_${pavimentoIndex}_${natEspPrefix}`}>
												<tr>
													{isFirstPavimento && isFirstNatEsp && config.showColEco && (
														<td
															ref={colunaEconomiaRef}
															className={`cell-input celula-coluna-economia ${disabled ? 'cell-disabled' : ''}`}
															rowSpan={pavimentos.reduce(
																(acc, { naturezasEspecies }) => acc + size(naturezasEspecies),
																0
															)}
														>
															<div className={'cell-input-wrapper'}>
																<TextField
																	placeHolder="bloco"
																	value={descEconomia}
																	name={`${economiaPrefix}.descricao`}
																	onChangeHandler={localChangeHandler}
																	readOnly={isUnifamiliar}
																	style={{ minWidth: '100px' }}
																	disabled={disabled}
																/>
																{!disabled && config.showAddEco && qtdEconomias > 1 && (
																	<button
																		type="button"
																		title="remover bloco"
																		className="btn btn-trash-tabela-areas btn-sm"
																		onClick={() => deleteEconomia({ economiaIndex })}
																	>
																		<i className="fa fa-trash"></i>
																	</button>
																)}
															</div>
															{!disabled && config.showColPav && config.showAddPav && (
																<button
																	type="button"
																	title="adicionar pavimento"
																	className="btn btn-outline-primary btn-sm btn-tabela-areas"
																	onClick={() => addPavimento({ economiaIndex })}
																>
																	<i className="fa fa-plus"></i> Pavimento
																</button>
															)}
														</td>
													)}
													{isFirstNatEsp && config.showColPav && (
														<td
															ref={colunaPavimentoRef}
															className={`cell-input celula-coluna-pavimento ${disabled ? 'cell-disabled' : ''}`}
															rowSpan={qtdNatEsps}
															style={{
																left: `${colunaEconomiaRef.current?.getBoundingClientRect().width || 0}px`
															}}
														>
															<div className={'cell-input-wrapper'}>
																<TextField
																	placeHolder="pavimento"
																	value={descPavimento}
																	name={`${pavimentoPrefix}.descricao`}
																	onChangeHandler={localChangeHandler}
																	style={{ minWidth: '100px' }}
																	disabled={disabled}
																/>
																{!disabled && config.showAddPav && qtdPavimentos > 1 && (
																	<button
																		type="button"
																		title="remover pavimento"
																		className="btn btn-trash-tabela-areas btn-sm"
																		onClick={() => deletePavimento({ economiaIndex, pavimentoIndex })}
																	>
																		<i className="fa fa-trash"></i>
																	</button>
																)}
															</div>
															{!disabled && config.showColNatEsp && config.showAddNatEsp && (
																<button
																	type="button"
																	title="adicionar natureza/espécie"
																	className="btn btn-outline-primary btn-sm btn-tabela-areas"
																	onClick={() => addNatEsp({ economiaIndex, pavimentoIndex })}
																>
																	<i className="fa fa-plus"></i> Nat/Esp
																</button>
															)}
														</td>
													)}
													{config.showColNatEsp && (
														<td
															ref={colunaNatEspRef}
															className={`cell-input celula-coluna-nat-esp ${disabled ? 'cell-disabled' : ''}`}
															style={{
																left: `${
																	(colunaEconomiaRef.current?.getBoundingClientRect().width || 0) +
																	(colunaPavimentoRef.current?.getBoundingClientRect().width || 0)
																}px`
															}}
														>
															<div className={'cell-input-wrapper'}>
																<TextField
																	placeHolder="natureza/espécie"
																	value={descNatEsp}
																	name={`${natEspPrefix}.descricao`}
																	onChangeHandler={localChangeHandler}
																	style={{ minWidth: '100px' }}
																	disabled={disabled}
																/>
																{!disabled && config.showAddNatEsp && qtdNatEsps > 1 && (
																	<button
																		type="button"
																		title="remover natureza/espécie"
																		className="btn btn-trash-tabela-areas btn-sm"
																		onClick={() => deleteNatEsp({ economiaIndex, pavimentoIndex, natEspIndex })}
																	>
																		<i className="fa fa-trash"></i>
																	</button>
																)}
															</div>
														</td>
													)}

													<LinhaTabelaAreasAP
														key={`${economiaIndex}_${pavimentoIndex}_${natEspIndex}`}
														data={data}
														naturezaEspecie={natEsp}
														prefix={natEspPrefix}
														onChangeHandler={localChangeHandler}
														areasPlanilha={areasPlanilha}
														economia={economia}
														economiaPrefix={economiaPrefix}
														tiposEconomias={tiposEconomia}
														tipoForm={tipoForm}
														disabled={disabled}
													/>

													{/* sub-total de cada natureza/especie horizontal */}
													<td className="subtotais">{formata(get(data.detalhesAreas, `${natEspPrefix}.total`, 0))}</td>
													{/* sub-total de cada pavimento horizontal */}
													{isFirstNatEsp && config.showColPav && temPavimentoComMaisDeUmNatEsp && (
														<td className="subtotais" rowSpan={qtdNatEsps}>
															{formata(get(data.detalhesAreas, `${pavimentoPrefix}.total`, 0))}
														</td>
													)}

													{/* sub-total de cada economia horizontal */}
													{isFirstPavimento &&
														isFirstNatEsp &&
														config.showColEco &&
														temEconomiaComMaisDeUmPavimento && (
															<td
																className="subtotais"
																rowSpan={economia.pavimentos.reduce((acc, pav) => acc + size(pav.naturezasEspecies), 0)}
															>
																{formata(get(data.detalhesAreas, `${economiaPrefix}.total`, 0))}
															</td>
														)}
												</tr>
											</Fragment>
										);
									});
								});
							})}

							{/* RODAPE */}
							<tr>
								{/* célula "Sub-totais (m²)" */}
								<td
									className="subtotais celula-subtotais"
									colSpan={getHeaderColspan(detalhesAreas)}
									rowSpan={getFooterRowspan(detalhesAreas)}
								>
									Sub-Totais (m²)
								</td>
								{/* total metragens do tipoEconomia */}
								{areasPlanilha.map(area =>
									tiposEconomia.map(tipoEconomia =>
										Object.keys(TIPOS_METRAGENS_ENUM).map(metragem => (
											<td key={`${area}.${tipoEconomia}.${metragem}`} className="subtotais">
												{formata(
													parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, metragem, 'total'], 0))
												)}
											</td>
										))
									)
								)}
								<td className="total-geral" rowSpan={rowSpanSubtotaisRodape} colSpan={colSpanSubtotaisHorizontal}>
									<b>TOTAL&nbsp;GERAL</b>
									<br />
									<br />
									{formata(parseFloat(get(detalhesAreas.areasTotais, ['total'], 0)))}
								</td>
							</tr>
							{isResidencial_e_naoResidencial && (
								<>
									<tr>
										{areasPlanilha.map((area, i) =>
											tiposEconomia.map(tipoEconomia => (
												<td
													key={`${area}.${i}.${tipoEconomia}`}
													colSpan={size(TIPOS_METRAGENS_ENUM)}
													className="subtotais"
												>
													{formata(parseFloat(get(detalhesAreas.areasTotais, [area, tipoEconomia, 'total'], 0)))}
												</td>
											))
										)}
									</tr>
								</>
							)}
							{size(detalhesAreas.areasPlanilha) > 1 && (
								<tr>
									{areasPlanilha.map((area, i) => (
										<td
											key={`${area}.${i}`}
											colSpan={size(TIPOS_METRAGENS_ENUM) * size(tiposEconomia)}
											className="subtotais"
										>
											{formata(parseFloat(get(detalhesAreas.areasTotais, [area, 'total'], 0)))}
										</td>
									))}
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
export default TabelaAreasAP;
TabelaAreasAP.displayName = 'TabelaAreasAP';
TabelaAreasAP.propTypes = {
	data: PropTypes.object,
	onChangeHandler: PropTypes.func,
	name: PropTypes.string,
	tipoForm: PropTypes.string,
	isProjetoNovo: PropTypes.bool,
	disabled: PropTypes.bool
};

/* calcula os totais por linha/coluna	*/
function calcularTotais(detalhes, tiposEconomias = ['residencial', 'naoResidencial']) {
	const { areasPlanilha, economias } = detalhes || {};

	let areasTotais = areasPlanilha.reduce((accAreas, area) => {
		const tipos = tiposEconomias.reduce((accTiposEconomias, tipoEconomia) => {
			const totalTipoEconomia = economias.reduce(
				(acc2, economia) =>
					acc2 +
					economia.pavimentos.reduce(
						(acc3, pavimento) =>
							acc3 +
							pavimento.naturezasEspecies.reduce(
								(acc4, natEsp) =>
									acc4 +
									Object.keys(TIPOS_METRAGENS_ENUM).reduce(
										(acc6, metragem) => acc6 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
										0
									),
								0
							),
						0
					),
				0
			);

			const metragens = Object.keys(TIPOS_METRAGENS_ENUM).reduce((accMetragens, metragem) => {
				const totalMetragem = economias.reduce(
					(acc2, economia) =>
						acc2 +
						economia.pavimentos.reduce(
							(acc3, pavimento) =>
								acc3 +
								pavimento.naturezasEspecies.reduce(
									(acc4, natEsp) => acc4 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
									0
								),
							0
						),
					0
				);
				return { ...accMetragens, [metragem]: { total: totalMetragem } };
			}, {});

			return { ...accTiposEconomias, [tipoEconomia]: { total: totalTipoEconomia, ...metragens } };
		}, {});

		const totalArea = economias.reduce(
			(acc2, economia) =>
				acc2 +
				economia.pavimentos.reduce(
					(acc3, pavimento) =>
						acc3 +
						pavimento.naturezasEspecies.reduce(
							(acc4, natEsp) =>
								acc4 +
								tiposEconomias.reduce(
									(acc5, tipoEconomia) =>
										acc5 +
										Object.keys(TIPOS_METRAGENS_ENUM).reduce(
											(acc6, metragem) => acc6 + parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0)),
											0
										),
									0
								),
							0
						),
					0
				),
			0
		);
		return { ...accAreas, [area]: { total: totalArea, ...tipos } };
	}, {});

	areasTotais.total = areasPlanilha.reduce((acc, area) => acc + areasTotais[area].total, 0);
	detalhes.areasTotais = areasTotais;

	economias.forEach(economia => {
		economia.total = economia.pavimentos.reduce((acc, pavimento) => {
			pavimento.total = pavimento.naturezasEspecies.reduce((acc2, natEsp) => {
				natEsp.total = areasPlanilha.reduce((acc3, area) => {
					const tiposEconomiaAreaTotal = tiposEconomias.reduce((acc4, tipoEconomia) => {
						const metragensTipoEconomiaAreaTotal = Object.keys(TIPOS_METRAGENS_ENUM).reduce((acc5, metragem) => {
							const val = parseFloat(get(natEsp, [area, tipoEconomia, metragem, 'value'], 0));
							return acc5 + val;
						}, 0);
						return acc4 + metragensTipoEconomiaAreaTotal;
					}, 0);
					return acc3 + tiposEconomiaAreaTotal;
				}, 0);
				return acc2 + natEsp.total;
			}, 0);
			return acc + pavimento.total;
		}, 0);
	});
	detalhes.totalPorEconomias = economias.reduce((acc, economia) => acc + economia.total, 0);

	return detalhes;
}

const formatter = new Intl.NumberFormat('pt-BR', {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	useGrouping: 'always'
});

function formata(numberValue) {
	return formatter.format(numberValue);
}
