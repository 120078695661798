import React, { useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { get, omit, size } from 'lodash';
import uuid from 'uuid/v4';

import CheckboxComponent from 'components/CheckboxComponent';
import ErrorMessages from 'components/ErrorMessages';

import DocumentoUpload from 'containers/Form/aba-documentos/DocumentoUpload';
import actions from 'containers/Form/actions';

import useErrors from 'custom-hooks/useErrors';
import useMutableState from 'custom-hooks/useMutableState';

import { getUpdatedToken, isDebug } from 'utils/tools';

import { obtemAdesaoPrchpaDocumento } from '.';

export function RestricaoAdministrativaSubfield({
	data,
	field,
	readOnly,
	disabled,
	emComplementacao,
	onChangeHandler,
	removeFile
}) {
	/* REDUX */
	const dispatch = useDispatch();
	const { idForm: idFormulario, id: idFormData } = useParams();

	/* CUSTOM HOOKS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const [errors, setErrors] = useErrors();

	/* VARIÁVEIS AUXILIARES */
	const { id, documentos: docsMetadata, required } = field;
	const getLabel = useCallback(field => `${field.numero} - ${field.label}`, []);
	const formData = useMemo(() => formulario?.formData || {}, [formulario]);
	const documentos = useMemo(() => formulario?.documentos || [], [formulario]);
	const docEspecifico = useMemo(() => obtemAdesaoPrchpaDocumento(formulario), [formulario]);

	useEffect(() => {
		if (size(errors) > 0) {
			onChangeHandler(null, 'resetForceSave');
			setErrors(old => omit(old, ['restricoesAdministrativas']));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentos]);

	/* FUNÇÕES AUXILIARES */
	const retiraDocumento = useCallback(payload => dispatch(actions.retiraDocumento(payload)), [dispatch]);

	const onDrop = useCallback(
		d => (accepted, rejected) => {
			if (size(rejected) === 0 && size(accepted) === 1) {
				const file = accepted[0];
				if (file.name.length > 100) {
					setErrors({ send: ['O nome do arquivo pode ter no máximo 100 caracteres.'] });
				} else if (accepted[0].size < 100000000) {
					getUpdatedToken().then(
						token => {
							const payload = {
								idFormulario,
								idFormData,
								id: d.id,
								idDocumento: d.idDocumento,
								tituloDocumento: d.tituloDocumento,
								obrigatorio: d.obrigatorio,
								extensao: d.extensao,
								ordem: d.ordem,
								docGroup: d.docGroup,
								file,
								token,
								original: d.original,
								complementadaEm: docEspecifico?.complementadaEm ? docEspecifico.complementadaEm : d.complementadaEm,
								owner: d.owner,
								versao: d.versao,
								newDoc: true,
								fromSolicitante: d.fromSolicitante
							};
							dispatch(actions.uploadFile(payload));
							if (errors?.[d.id]) {
								setErrors(old => omit(old, [d.id]));
							}
							if (errors?.[d.idDocumento]) {
								setErrors(old => omit(old, [d.idDocumento]));
							}
						},
						error => {
							setErrors({ send: [error] });
						}
					);
				} else {
					setErrors({ send: ['O tamanho máximo de upload é de 100MB por arquivo.'] });
				}
			} else if (size(rejected) + size(accepted) > 1) {
				setErrors({ send: ['Apenas 1 arquivo pode ser submetido em cada documento'] });
			} else {
				setErrors({
					send: [`Tipo de arquivo inválido. Selecione um arquivo do tipo ${(d.extensao || '').toUpperCase()}`]
				});
			}
		},
		[dispatch, docEspecifico, errors, idFormData, idFormulario, setErrors]
	);

	const criarMultiDocumentos = useCallback(
		doc => {
			const docPai = documentos.find(d => d.id === (doc.docGroup || doc.id));
			const dg = documentos.filter(d => d.docGroup === docPai.id);
			let ordem = (docPai.ordem || 100) + dg.length + 1;
			const newDoc = {
				textoHelp: null,
				ordem: ordem,
				extensao: 'pdf',
				obrigatorio: false,
				idDocumento: `${docPai.idDocumento}-${ordem % 100}`,
				id: uuid(),
				linkHelp: null,
				tituloDocumento: `${docPai.tituloDocumento}-${ordem % 100}`,
				versao: 1,
				docGroup: docPai.id,
				newDoc: true,
				fromSolicitante: true // Para verificação de doc que é criado pelo usuário
			};
			if (docEspecifico.complementadaEm) {
				newDoc.complementadaEm = docEspecifico.complementadaEm;
			}
			if (docEspecifico.owner) {
				newDoc.owner = docEspecifico.owner;
			}
			dispatch(actions.addNewDocument(newDoc));
		},
		[dispatch, docEspecifico, documentos]
	);

	/* AUXILIARES PARA RENDER */
	const docs = useMemo(() => getDocs(docsMetadata, documentos), [docsMetadata, documentos]);
	const motivos = useMemo(() => getMotivos(docs), [docs]);
	const maioresOrdens = useMemo(() => extrairMaioresOrdens(docs), [docs]);
	const temSubItensInsuficientes = useMemo(
		() => size(field.subItens) > 0 && field.subItens.reduce((acc, si) => acc + (data?.[si.id] ? 1 : 0), 0) < 3,
		[data, field.subItens]
	);

	return (
		<>
			{/* TODO: Implementar a restrição (checkbox) e seus documentos */}
			<CheckboxComponent
				name={id}
				label={getLabel(field)}
				value={data?.[field.id]}
				required={required}
				showCodigo={false}
				layoutNovo={true}
				stackOptions={true}
				readOnly={readOnly}
				disabled={disabled}
				onChangeHandler={onChangeHandler}
			/>
			{temSubItensInsuficientes && data?.[field.id] && (
				<ErrorMessages warningList={['Para ser considerada, deve ter pelo menos 3 subitens marcados']} />
			)}
			{data?.[field.id] && size(field.subItens) > 0 && (
				<div style={{ paddingLeft: '32px' }}>
					{field.subItens.map(si => (
						<RestricaoAdministrativaSubfield
							key={si.id}
							data={data}
							field={si}
							readOnly={readOnly}
							disabled={readOnly}
							emComplementacao={emComplementacao}
							onChangeHandler={onChangeHandler}
							removeFile={removeFile}
						/>
					))}
				</div>
			)}

			{data?.[field.id] &&
				size(docsMetadata) > 0 &&
				docs.map(doc => {
					const docMaiorOrdem = maioresOrdens[doc.docGroup || doc.id] === doc.ordem;

					const emProcessamento = !doc.idDocumentoSei && !!doc.originalName;
					const arquivoGerado =
						!!doc.filename ||
						(documentos || []).find(d => d.id === doc.id && d.filename) ||
						size(formData?.resultado) > 0;

					return (
						<div key={doc.idDocumento} className="form-group col-md-12" style={{ paddingLeft: '32px' }}>
							<label htmlFor={`inputFile${doc.idDocumento}`}>
								<span style={{ display: 'flex', justifyContent: 'space-between' }}>
									<span>
										{doc.tituloDocumento || doc.tituloDocumentoAdmin}
										{doc.obrigatorio ? <span className="required">*</span> : ''}
										{doc.versao && doc.versao !== 1 ? ` (v. ${doc.versao})` : ''}
									</span>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{isDebug && (
											<span className="debug-message">
												({doc.idDocumento} - {doc.ordem})
											</span>
										)}
									</div>
								</span>
								{doc.descricaoDocumento && (
									<span style={{ fontSize: '1.0rem', color: 'grey' }}>{doc.descricaoDocumento}</span>
								)}
							</label>

							<DocumentoUpload
								key={doc.id}
								formData={formData}
								documento={doc}
								emProcessamento={emProcessamento}
								arquivoGerado={arquivoGerado}
								onDrop={onDrop}
								removeFile={docMaiorOrdem ? removeFile : null}
								retiraDocumento={retiraDocumento}
								procedimentoFormatadoSei={formData.procedimentoFormatadoSei}
								usuarioDonoTask={true}
								emComplementacao={emComplementacao}
								naoPodeRemoverSlot={!doc.fromSolicitante}
							/>

							{showBotaoAddMultiDocs(doc.docPai || doc.id) && docMaiorOrdem && (
								<button hidden={false} type="button" className="btn btn-link" onClick={() => criarMultiDocumentos(doc)}>
									<i className="fa fa-plus" style={{ fontSize: '18px' }} />
									{`  Adicionar outro documento de ${doc.tituloDocumento || doc.tituloDocumentoAdmi}`}
								</button>
							)}
							{size(motivos?.[doc.id]) > 0 && <ErrorMessages errorList={motivos[doc.id]} />}
							{size(errors?.[doc.id]) > 0 && <ErrorMessages errorList={errors[doc.id]} />}
							{size(errors?.[doc.idDocumento]) > 0 && <ErrorMessages errorList={errors[doc.idDocumento]} />}
						</div>
					);
				})}
		</>
	);
}
RestricaoAdministrativaSubfield.displayName = 'LicencasExpressasRestricaoAdministrativaSubfield';
RestricaoAdministrativaSubfield.propTypes = {
	data: PropTypes.object,
	field: PropTypes.object,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	emComplementacao: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	removeFile: PropTypes.func
};

function getMotivos(docs) {
	docs
		.filter(d => d.complementadaEm)
		.reduce((acc, d) => {
			const original = docs.find(dAux => dAux.id === d.original);
			return {
				...acc,
				[d.id]: [
					{
						type: `${get(original, 'motivo') ? 'warning' : 'info'}`,
						message: `${
							get(original, 'motivo')
								? `Arquivo rejeitado pelo revisor: ${
										size(get(original, 'motivo')) > 0 ? get(original, 'motivo') : 'sem motivo'
								  }`
								: 'Novo arquivo complementado'
						}`,
						resolvido: d.filename
					}
				]
			};
		}, {});
}

function proximaOrdem(documentos) {
	const maior = documentos?.reduce((acc, o) => (o.ordem && o.ordem > acc ? o.ordem : acc), 0) || 0;
	const proxima = maior - (maior % 100) + 100;
	return proxima;
}

function showBotaoAddMultiDocs(paiId, documentos) {
	if (!(documentos || []).find(d => d.id === paiId)?.filename) {
		return false; // não mostra se ainda não subiu um pai
	}

	const algumSlotSemDocumento = (documentos || []).filter(d => d.docGroup === paiId).find(d => !d.filename);
	if (algumSlotSemDocumento) {
		return false; // não mostra se ainda não adicionou documentos em todos os slots disponíveis
	}

	return true;
}

function getDocs(docsMetadata, documentos) {
	const idsDocumentos = (docsMetadata || []).map(d => d.idDocumento);
	let docs = (documentos || []).filter(d => idsDocumentos.includes(d.idDocumento));
	const idsPrincipais = docs.map(d => d.id);
	const docsFilhosDosPrincipais = (documentos || []).filter(d => idsPrincipais.includes(d.docGroup));
	docs = [...docs, ...docsFilhosDosPrincipais];

	// adiciona slots para os docs do metadata que ainda não existem em documentos
	idsDocumentos.forEach((id, i) => {
		if (!docs.find(dAux => dAux.idDocumento === id)) {
			const doc = docsMetadata[i];
			const newDoc = Object.assign({ id: uuid(), versao: 1, ordem: proximaOrdem(documentos), extensao: 'pdf' }, doc);
			docs = [...docs, newDoc];
		}
	});

	docs = docs.sort((d1, d2) => (d1.ordem > d2.ordem ? 1 : -1));
	return docs;
}

function extrairMaioresOrdens(slots) {
	// ordenar documentos em ordem crescente com base no campo ordem
	slots.sort((a, b) => a.ordem - b.ordem);

	// objeto para armazenar a maior ordem de cada grupo
	const maioresOrdens = {};

	// percorrer documentos em ordem crescente
	for (const documento of slots) {
		if (!documento.docGroup) {
			// documento não tem docGroup, criar nova entrada no objeto
			maioresOrdens[documento.id] = documento.ordem;
		} else {
			// documento tem docGroup, verificar se já existe uma entrada no objeto para o docGroup
			if (maioresOrdens[documento.docGroup]) {
				// comparar campo ordem do documento com valor armazenado na entrada do docGroup
				const maiorOrdem = maioresOrdens[documento.docGroup];
				if (documento.ordem > maiorOrdem) {
					// atualizar valor armazenado na entrada do docGroup
					maioresOrdens[documento.docGroup] = documento.ordem;
				}
			} else {
				// ainda não há uma entrada no objeto para o docGroup, criar uma nova
				maioresOrdens[documento.docGroup] = documento.ordem;
			}
		}
	}

	return maioresOrdens;
}
