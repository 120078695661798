import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';
import moment from 'moment';

import ErrorMessages from 'components/ErrorMessages';

import { joinPlus } from 'utils/tools';

/**
	- Data despacho
	- Assunto (pode ter mais de um)
	- Atividade (se tiver e pode ter mais de um)
	- Número de parecer/resolução
*/

function UltimoEvuValido({ evu, semExpedienteUnico }) {
	const dataDespacho = useMemo(() => (evu?.data ? moment(evu.data).format('DD/MM/YYYY') : null), [evu]);
	const assuntos = useMemo(
		() =>
			joinPlus(
				(evu?.assuntos || []).map(a => a.descricao.trim()),
				', '
			),
		[evu]
	);
	const atividades = useMemo(
		() =>
			joinPlus(
				(evu?.atividades || []).map(a => a.descricao.trim()),
				', '
			),
		[evu]
	);
	const numeroParecer = useMemo(() => evu?.numeroParecer, [evu]);
	return semExpedienteUnico ? (
		<ErrorMessages
			type="warning"
			errorList={[
				{
					type: 'warning',
					message:
						'Este formulário não possui Expediente Único informado, não havendo Estudo de Viabilidade Urbanística de 1º grau deferido previamente.'
				}
			]}
		/>
	) : !evu ? (
		<ErrorMessages
			type="warning"
			errorList={[
				{
					type: 'warning',
					message:
						'O Expediente Único informado não possui um Estudo de Viabilidade Urbanística de 1º grau deferido previamente.'
				}
			]}
		/>
	) : (
		<div className="ultimo-evu-valido">
			<ErrorMessages
				type="warning"
				errorList={[
					{
						type: 'warning',
						message: `O Expediente Único informado possui um Estudo de Viabilidade Urbanística de 1º grau deferido previamente da data de ${dataDespacho} ${
							size(assuntos) > 0 ? `para o assunto(s) ${assuntos}` : ''
						}${
							size(atividades) > 0 ? ` e atividade(s) ${atividades}` : ' (não há atividades cadastradas),'
						} com o número de parecer/resolução ${numeroParecer}`
					}
				]}
			/>
			<div className="title bloco-info-evu">Resumo das informações constantes do último EVU deferido do Expediente</div>
			<div className="grid">
				<span className="campo">Data do Despacho</span>
				<span className="valor">{dataDespacho}</span>
				{size(assuntos) > 0 && (
					<>
						<span className="campo">Assuntos</span>
						<span className="valor">{assuntos}</span>
					</>
				)}
				{size(assuntos) > 0 && (
					<>
						<span className="campo">Atividades</span>
						<span className="valor">{size(atividades) > 0 ? ` ${atividades}` : 'Não há dados'}</span>
					</>
				)}
				<span className="campo">Nº parecer/resolução</span>
				<span className="valor">{numeroParecer}</span>
			</div>
		</div>
	);
}
UltimoEvuValido.displayName = 'UltimoEvuValido';
UltimoEvuValido.propTypes = {
	evu: PropTypes.object,
	semExpedienteUnico: PropTypes.bool
};

// Kaue, cuidado para remover os warnings/erros de lint antes de subir
// esse eslint disable é desaconselhado, mas coloquei por que imagino que
// tu ainda queira usar a função

// eslint-disable-next-line no-unused-vars
function capitalizar(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export default UltimoEvuValido;
