import React from 'react';

import PropTypes from 'prop-types';

import { isImmutable } from 'immutable';
import { cloneDeep, size, uniqBy } from 'lodash';

const ErrorMessages = props => {
	let errorList = cloneDeep(props.errorList);

	if (props.warningList) {
		props.warningList.forEach(warning => {
			let warningAux = cloneDeep(warning);
			if (typeof warning === 'string') {
				warningAux = {
					type: 'warning',
					message: warning
				};
			}
			if (!errorList) {
				errorList = [];
			}
			errorList.push(warningAux);
		});
	}

	if (props.errorList) {
		errorList = props.errorList.map(auxError => {
			const error = cloneDeep(auxError);
			if (error && typeof error === 'object' && error.message) {
				error.linkId = error.message?.replace(/ /g, '_');
				return error;
			}
			let errorAux = error;
			if (typeof error === 'string') {
				errorAux = {
					linkId: error.replace(/ /g, '_'),
					type: props.type || 'error',
					message: error
				};
			}
			return errorAux;
		});
	}

	errorList = uniqBy(errorList, 'message');

	return size(errorList) > 0 ? (
		<ul className={'error-block error-message'}>
			{errorList
				.filter(error => !Array.isArray(error))
				.filter(error => !!error)
				.map((error, i) => {
					const message = error.msg || error.message;
					const type = error.type ? error.type : 'error';
					const resolvido = error.resolvido ? error.resolvido : false;
					const linkId = error.linkId || message.replace(/ /g, '_');

					return (
						<li
							key={i}
							id={`${linkId}`}
							className={`${type}-message`}
							style={{ textDecoration: resolvido ? 'line-through' : '' }}
						>
							{type !== 'warning' ? (
								<i
									className={`fa fa-${type === 'debug' ? 'bug' : 'exclamation-circle'}`}
									style={{ marginRight: '8px' }}
									aria-hidden="true"
								/>
							) : null}
							<span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>

							{props?.showLink && ( // isso vai levar para a primeira vez que esta mensagem aparece na tela
								// útil de se colocar esta propriedade quando a mensagem é exibida no final do formulário,
								// para que o link leve para a primeira aparição do erro
								<span
									onClick={() => {
										const element = document.getElementById(linkId);
										if (element && element.scrollIntoView) {
											element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
											element.classList.add('blink');
											setTimeout(() => {
												element.classList.remove('blink');
											}, 5000);
										}
									}}
									className="btn-link"
								>
									Ir para o campo
								</span>
							)}
						</li>
					);
				})}
		</ul>
	) : null;
};
ErrorMessages.displayName = 'ErrorMessages';

ErrorMessages.propTypes = {
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	warningList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	type: PropTypes.string,
	showLink: PropTypes.bool
};

ErrorMessages.defaultProps = {
	type: 'error'
};

export default ErrorMessages;

export function errorsHasType(errors, type) {
	let tem = false;
	if (size(errors) > 0) {
		errors = isImmutable(errors) ? errors.toJS() : errors;

		for (const key in errors) {
			const array = errors[key];
			if (Array.isArray(array)) {
				for (const message of array) {
					if (typeof message === 'string' && type === 'error') {
						tem = true;
					} else {
						if (typeof message === 'object') {
							if (message.type === type) {
								tem = true;
							}
						}
					}
				}
			}
		}
	}
	return tem;
}
