import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import gql from 'graphql-tag';
import { size, get } from 'lodash';

import EmbedList from 'components/EmbedList';
import ErrorMessages from 'components/ErrorMessages';
import IntlNumberInput from 'components/react-intl-number-input';
import Selecao from 'components/Selecao';
import TooltipIcon from 'components/TooltipIcon';

import apolloClient from 'utils/graphql';

import { debugLog } from '.';
import SubunidadeEdit from './SubunidadeEdit';

const Aba_D_Indices = ({ planilha, onChange }) => {
	const [abaD, setAbaD] = useState(planilha.d || {});

	const [totalAdensavelSubunidades, setTotalAdensavelSubunidades] = useState(0);
	const [totalNaoAdensavelSubunidades, setTotalNaoAdensavelSubunidades] = useState(0);
	const [totalAdensavelGeral, setTotalAdensavelGeral] = useState(0);
	const [totalNaoAdensavelGeral, setTotalNaoAdensavelGeral] = useState(0);

	const [limiteIaMaximo, setLimiteIaMaximo] = useState(0);

	const [logradourosPesquisados, setLogradourosPesquisados] = useState([]);
	const [logradouro, setLogradouro] = useState(null);
	const [timer, setTimer] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(
		() => () => {
			if (timer) {
				clearTimeout(timer);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		if (timer) {
			clearTimeout(timer);
		}
		setTimer(
			setTimeout(async () => {
				const enderecosCdl = await buscaEnderecosCdl(logradouro);
				setLogradourosPesquisados(enderecosCdl);
			}, 500)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logradouro]);

	const buscaEnderecosCdl = async logradouro => {
		let saida = [];

		try {
			const queryCDL = gql`
				query CDL($term: String) {
					list: CdlEnderecoList(term: $term) {
						id
						nomeLogradouro
						enderecoFormatadoCurto
					}
				}
			`;
			if (size(logradouro) > 2) {
				setLoading(true);
				const {
					data: { list }
				} = await apolloClient.query({
					query: queryCDL,
					variables: { term: logradouro },
					fetchPolicy: 'network-only'
				});
				saida = list;
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			console.error('erro no servidor', e);
			// yield put(push('/serverUnavailable'));
		}

		return saida;
	};

	useEffect(() => {
		const { totalAdensavelSubunidades, totalNaoAdensavelSubunidades } = (
			(planilha.d || {}).listaSubunidades || []
		).reduce(
			(acc, su) => ({
				totalAdensavelSubunidades: acc.totalAdensavelSubunidades + (su.limiteAreaAdensavel || 0),
				totalNaoAdensavelSubunidades: acc.totalNaoAdensavelSubunidades + (su.limiteAreaNaoAdensavel || 0)
			}),
			{
				totalAdensavelSubunidades: 0,
				totalNaoAdensavelSubunidades: 0
			}
		);
		setLimiteIaMaximo(totalAdensavelSubunidades);
		setTotalAdensavelSubunidades(totalAdensavelSubunidades);
		setTotalNaoAdensavelSubunidades(totalNaoAdensavelSubunidades);
	}, [planilha.d]);

	useEffect(() => {
		debugLog('ABA_D', '[abaD]', abaD);

		if (abaD) {
			abaD.soloCriadoPequenoNaoAdensavel = (abaD.soloCriadoPequenoAdensavel || 0) / 2;
			abaD.soloCriadoMedioNaoAdensavel = (abaD.soloCriadoMedioAdensavel || 0) / 2;
			abaD.soloCriadoGrandeNaoAdensavel = (abaD.soloCriadoGrandeAdensavel || 0) / 2;
			abaD.tpcNaoAdensavel = (abaD.tpcAdensavel || 0) / 2;

			const totalAdensavel =
				totalAdensavelSubunidades +
				(abaD.soloCriadoPequenoAdensavel || 0) +
				(abaD.soloCriadoMedioAdensavel || 0) +
				(abaD.soloCriadoGrandeAdensavel || 0) +
				(abaD.tpcAdensavel || 0);
			const totalNaoAdensavel =
				totalNaoAdensavelSubunidades +
				(abaD.soloCriadoPequenoNaoAdensavel || 0) +
				(abaD.soloCriadoMedioNaoAdensavel || 0) +
				(abaD.soloCriadoGrandeNaoAdensavel || 0) +
				(abaD.tpcNaoAdensavel || 0);

			setTotalAdensavelGeral(totalAdensavel);
			setTotalNaoAdensavelGeral(totalNaoAdensavel);
		}
		onChange({ d: abaD });
	}, [abaD, onChange, totalAdensavelSubunidades, totalNaoAdensavelSubunidades]);

	const handleChange = e => {
		const { name, value } = e.target;
		if (name === 'areaLivrePermIsenta' && value === 'sim') {
			setAbaD(oldState => ({ ...oldState, [name]: value, areaLivrePermObrigatoria: 0 }));
		} else {
			setAbaD(oldState => ({ ...oldState, [name]: value }));
		}
	};

	const calculateTpcSoloCriado = () => {
		const total =
			(abaD.soloCriadoPequenoAdensavel || 0) +
				(abaD.soloCriadoMedioAdensavel || 0) +
				(abaD.soloCriadoGrandeAdensavel || 0) +
				abaD.tpcAdensavel || 0;
		return total;
	};

	const adicionaSubunidade = () => {
		setAbaD(oldState => ({ ...oldState, listaSubunidades: [...(oldState.listaSubunidades || []), {}] }));
	};

	const atualizaSubunidade = index => item => {
		setAbaD(oldState => ({
			...oldState,
			listaSubunidades: (oldState.listaSubunidades || []).map((subunidade, i) => (index === i ? item : subunidade))
		}));
	};

	const removeSubunidade = index => () => {
		setAbaD(oldState => ({
			...oldState,
			listaSubunidades: (oldState.listaSubunidades || []).filter((item, i) => i !== index)
		}));
	};

	const atualizaRecuoJardim = (item, index) => {
		setAbaD(oldState => ({
			...oldState,
			listaRecuoJardim: (oldState.listaRecuoJardim || []).map((itemRecuo, i) => (index === i ? item : itemRecuo))
		}));
	};

	const removeRecuoJardim = index => {
		setAbaD(oldState => ({
			...oldState,
			listaRecuoJardim: (oldState.listaRecuoJardim || []).filter((item, i) => i !== index)
		}));
	};

	const setCDLHandler = ({ item, cdl }) => {
		setLogradourosPesquisados([]);
		item.logradouro = cdl;
	};

	const unsetCDLHandler = item => {
		setLogradouro(null);
		item.logradouro = null;
	};

	return (
		<>
			{abaD.errors && <ErrorMessages errorList={abaD.errors} />}

			<h3>Subunidades</h3>
			<EmbedList
				itemList={abaD.listaSubunidades}
				emptyMessage="Nenhuma subunidade cadastrada."
				showAddButton={true}
				novoItem={adicionaSubunidade}
				novoItemLabel="adicionar subunidade"
				itemStyle={{ marginBottom: '8px' }}
			>
				{(item, index) => (
					<SubunidadeEdit
						subunidade={item}
						onChange={atualizaSubunidade(index)}
						onRemove={removeSubunidade(index)}
						totalEconomias={get(planilha, 'b.totalEconomias')}
						totalTpcSoloCriado={calculateTpcSoloCriado()}
					/>
				)}
			</EmbedList>

			<div className="form-group">
				<table style={{ width: '100%', marginTop: '16px' }}>
					<thead>
						<tr>
							<th width="25%" />
							<th style={{ padding: '0 12px' }}>
								<div style={{ textAlign: 'right' }}>
									<TooltipIcon label="Adensável">
										As áreas adensáveis máximas permitidas serão o resultado da multiplicação do IA pela área de
										aplicação do R.U (Campo C)
									</TooltipIcon>
								</div>
							</th>
							<th style={{ padding: '0 12px' }}>
								<div style={{ textAlign: 'right' }}>
									<TooltipIcon label="Não adensável">
										As áreas não adensáveis serão equivalentes a 50% da áreas adensáveis. Em se tratando de economia
										única poderá utilizar o benefício previsto no páragrafo 5° do Art. 107 do PDDUA.
									</TooltipIcon>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{size(abaD.listaSubunidades) > 0 && (
							<>
								<tr style={{ height: '10px' }}>
									<td colSpan="3">
										<hr />
									</td>
								</tr>
								<tr>
									<td></td>
									<td colSpan="2" align="center">
										<div
											style={{
												backgroundColor: '#e9ecef',
												padding: '5px',
												border: '1px solid',
												textTransform: 'uppercase',
												fontWeight: 'bold'
											}}
										>
											Subunidades
										</div>
									</td>
								</tr>
								{abaD.listaSubunidades.map((subunidade, index) => (
									<tr key={index}>
										<td style={{ fontSize: '10pt' }}>{`Subunidade ${subunidade.codigo || '???'}`}</td>
										<td>
											<IntlNumberInput
												type="text"
												className="form-control"
												locale="pt-BR"
												suffix=" m²"
												precision={2}
												value={subunidade.limiteAreaAdensavel}
												disabled={true}
												keepZero={true}
											/>
										</td>
										<td>
											<IntlNumberInput
												type="text"
												className="form-control"
												locale="pt-BR"
												suffix=" m²"
												precision={2}
												value={subunidade.limiteAreaNaoAdensavel}
												disabled={true}
												keepZero={true}
											/>
										</td>
									</tr>
								))}
							</>
						)}
						<tr style={{ height: '10px' }}>
							<td colSpan="3">
								<hr />
								<div className="row">
									<div className="form-group col-sm-8">
										<label htmlFor="">Necessitará de índices construtivos (Solo Criado e/ou TPC)?</label>
										<div className="inline-input-radio-checkbox">
											<label>
												<input
													type="radio"
													name="necessitaIndices"
													value="sim"
													checked={abaD.necessitaIndices === 'sim'}
													onClick={handleChange}
													onChange={() => false}
												/>
												Sim
											</label>
										</div>
										<div className="inline-input-radio-checkbox">
											<label>
												<input
													type="radio"
													name="necessitaIndices"
													value="nao"
													checked={abaD.necessitaIndices === 'nao'}
													onClick={handleChange}
													onChange={() => false}
												/>
												Não
											</label>
										</div>
									</div>
									<div className="form-group col-sm-4">
										{abaD.necessitaIndices === 'sim' && (
											<div style={{ marginTop: '8px' }}>
												<label>Limite I.A. Máximo</label>
												<IntlNumberInput
													type="text"
													className="form-control"
													locale="pt-BR"
													precision={2}
													suffix=" m²"
													value={limiteIaMaximo}
													disabled={true}
												/>
											</div>
										)}
									</div>
								</div>
								<hr />
							</td>
						</tr>

						<tr>
							<td></td>
							<td colSpan="2" align="center">
								<div
									style={{
										backgroundColor: '#e9ecef',
										padding: '5px',
										border: '1px solid',
										textTransform: 'uppercase',
										fontWeight: 'bold'
									}}
								>
									<TooltipIcon label="Solo criado (Art. 53 e 53a)">
										Indicar caso utilize SC, observando os Art. 53, 53A e Anexo 6 do PDDUA.
									</TooltipIcon>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ fontSize: '10pt' }}>Pequeno adensamento</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoPequenoAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, soloCriadoPequenoAdensavel: value }))}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoPequenoNaoAdensavel}
									onChange={(event, value) =>
										setAbaD(oldState => ({ ...oldState, soloCriadoPequenoNaoAdensavel: value }))
									}
									disabled={true}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ fontSize: '10pt' }}>Médio adensamento</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoMedioAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, soloCriadoMedioAdensavel: value }))}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoMedioNaoAdensavel}
									onChange={(event, value) =>
										setAbaD(oldState => ({ ...oldState, soloCriadoMedioNaoAdensavel: value }))
									}
									disabled={true}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ fontSize: '10pt' }}>Grande adensamento</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoGrandeAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, soloCriadoGrandeAdensavel: value }))}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoGrandeNaoAdensavel}
									onChange={(event, value) =>
										setAbaD(oldState => ({ ...oldState, soloCriadoGrandeNaoAdensavel: value }))
									}
									disabled={true}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ fontSize: '10pt' }}>Não adensável</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={0}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, soloCriadoGrandeAdensavel: value }))}
									disabled={true}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.soloCriadoNaoAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, soloCriadoNaoAdensavel: value }))}
								/>
							</td>
						</tr>
						<tr style={{ height: '10px' }}>
							<td colSpan="3">
								<hr />
							</td>
						</tr>
						<tr>
							<td style={{ fontSize: '10pt' }}>
								Transferência de Potencial Construtivo (Art. 51)
								<TooltipIcon text="Indicar caso utilize TPC, observando o Art. 51 do PDDUA" />
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.tpcAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, tpcAdensavel: value }))}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={abaD.tpcNaoAdensavel}
									onChange={(event, value) => setAbaD(oldState => ({ ...oldState, tpcNaoAdensavel: value }))}
									disabled={true}
								/>
							</td>
						</tr>
						<tr style={{ height: '10px' }}>
							<td colSpan="3">
								<hr />
							</td>
						</tr>
						<tr>
							<td>
								<b>TOTAIS</b>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={totalAdensavelGeral}
									disabled={true}
								/>
							</td>
							<td>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									suffix=" m²"
									precision={2}
									value={totalNaoAdensavelGeral}
									disabled={true}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="row">
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="dgcea">
						<TooltipIcon label="Altitude máxima DGCEA">
							Informar quando houver restrição de cota altimétrica em função do aeroporto. Vide DMWeb, aba
							&quote;Aeroporto&quote;.
						</TooltipIcon>
					</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m"
						precision={2}
						value={abaD.dgcea}
						onChange={(event, value) => setAbaD(oldState => ({ ...oldState, dgcea: value }))}
					/>
				</div>
			</div>

			<h4 style={{ textAlign: 'center', textTransform: 'uppercase', padding: '10px', backgroundColor: '#e9ecef' }}>
				<TooltipIcon label="Recuo para jardim">
					Informar o recuo para jardim de todos os logradouros com os quais seu imóvel possui testada
				</TooltipIcon>
			</h4>

			<div className="row">
				<div className="form-group col-md-12">
					<EmbedList
						itemList={abaD.listaRecuoJardim}
						emptyMessage="Nenhum recuo para jardim inserido."
						showAddButton={true}
						noLabel={false}
						novoItem={e => {
							setAbaD(oldState => ({ ...oldState, listaRecuoJardim: [...(oldState.listaRecuoJardim || []), {}] }));
							e.stopPropagation();
						}}
						novoItemLabel="adicionar recuo para jardim"
						itemStyle={{ margin: '0px -16px' }}
					>
						{(item, index) => (
							<div className="row" key={index}>
								<div className="form-group col-md-6">
									<label htmlFor="logradouro">Logradouro</label>
									<Selecao
										multiple={false}
										selected={item.logradouro}
										label={''}
										detailInnerClassName={'inner-list-item inner-list-item-input'}
										detailCodigo={''}
										detailDescricao={'nomeLogradouro'}
										autoShowList={false}
										searchTerm={logradouro}
										searchList={logradourosPesquisados}
										searchTermMinLength={3}
										errorList={false}
										onChangeSearchTerm={e => setLogradouro(e.target.value)}
										onBlurSearchTerm={() => false}
										onSelectItem={cdl => () => setCDLHandler({ item, cdl })}
										onUnselect={() => () => unsetCDLHandler(item)}
										noResetList={true}
										loading={loading}
									/>
								</div>
								<div className="form-group col-md-4">
									<label htmlFor="recuo">Recuo para jardim</label>
									<IntlNumberInput
										type="text"
										className="form-control"
										locale="pt-BR"
										suffix=" m"
										precision={2}
										value={item.recuoJardim}
										onChange={(e, value) => atualizaRecuoJardim({ ...item, recuoJardim: value }, index)}
									/>
								</div>
								<div className="form-group col-md-2">
									<i className="fa fa-trash" onClick={() => removeRecuoJardim(index)} />
								</div>
							</div>
						)}
					</EmbedList>
				</div>
			</div>

			<h4 style={{ textAlign: 'center', textTransform: 'uppercase', padding: '10px', backgroundColor: '#e9ecef' }}>
				<TooltipIcon label="Área Livre Permeável">
					Informar a ALP obrigatória, conforme Art. 96 do PDDUA e área efetiva do Projeto. Indicar se o imóvel é isento,
					conforme Art. 113, inciso V e Parecer do GRPDDUA 7/12.
				</TooltipIcon>
			</h4>
			<div className="row">
				<div className="form-group col-12 col-sm-4">
					<p>Isenta (Art 113 - VI)</p>
					<div className="inline-input-radio-checkbox">
						<label>
							<input
								type="radio"
								name="areaLivrePermIsenta"
								value="sim"
								id="radioSim"
								onChange={() => null}
								onClick={handleChange}
								checked={abaD.areaLivrePermIsenta === 'sim'}
							/>
							Sim
						</label>
					</div>
					<div className="inline-input-radio-checkbox">
						<label>
							<input
								type="radio"
								name="areaLivrePermIsenta"
								value="nao"
								id="radioNao"
								onChange={() => null}
								onClick={handleChange}
								checked={abaD.areaLivrePermIsenta === 'nao'}
							/>
							Não
						</label>
					</div>
				</div>
				<div className="form-group col-6 col-sm-4">
					<label htmlFor="obri">Obrigatória (Art 96 §8)</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaD.areaLivrePermObrigatoria}
						onChange={(event, value) => setAbaD(oldState => ({ ...oldState, areaLivrePermObrigatoria: value }))}
						disabled={abaD.areaLivrePermIsenta === 'sim'}
					/>
				</div>
				<div className="form-group col-6 col-sm-4">
					<label htmlFor="projeto">Projeto</label>
					<IntlNumberInput
						type="text"
						className="form-control"
						locale="pt-BR"
						suffix=" m²"
						precision={2}
						value={abaD.areaLivrePermProjeto}
						onChange={(event, value) => setAbaD(oldState => ({ ...oldState, areaLivrePermProjeto: value }))}
					/>
				</div>
			</div>

			{abaD.areaLivrePermIsenta === 'nao' && (
				<div className="row">
					<div className="form-group col-12 col-sm-6">
						<p>O projeto utilizará medidas alternativas para ALP?</p>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="medidasAlternativasALP"
									value="sim"
									id="radioSim"
									onChange={() => null}
									onClick={handleChange}
									checked={abaD.medidasAlternativasALP === 'sim'}
								/>
								Sim
							</label>
						</div>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="medidasAlternativasALP"
									value="nao"
									id="radioNao"
									onChange={() => null}
									onClick={handleChange}
									checked={abaD.medidasAlternativasALP === 'nao'}
								/>
								Não
							</label>
						</div>
					</div>
					{abaD.medidasAlternativasALP === 'sim' && (
						<div className="form-group col-6 col-sm-6">
							<p>Proporção da medida alternativa</p>
							<IntlNumberInput
								type="text"
								className="form-control"
								locale="pt-BR"
								suffix=" m²"
								precision={2}
								value={abaD.proporcaoMedidaAlternativaALP}
								onChange={(event, value) =>
									setAbaD(oldState => ({ ...oldState, proporcaoMedidaAlternativaALP: value }))
								}
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
};
Aba_D_Indices.displayName = 'Aba_C_Indices';
Aba_D_Indices.propTypes = {
	planilha: PropTypes.object,
	formulario: PropTypes.object,
	onChange: PropTypes.func
};

export default Aba_D_Indices;
