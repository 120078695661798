import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { accessApi } from 'utils/injectApi';

function LinkProcesso({ id, admin, label, ...props }) {
	const [link, setLink] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (id && !link && !error) {
			accessApi(`/get-processo-link/${id}?admin=${admin ? 'true' : 'false'}`)
				.then(res => {
					if (res?.data?.ok === 1) {
						setLink(res.data.link);
					} else {
						setError(true);
					}
				})
				.catch(() => setError(true));
		}
	}, [admin, error, id, link]);

	const handleClick = useCallback(() => {
		window.open(link, '_blank');
	}, [link]);

	return error ? null : link ? (
		<span className="botao-link" onClick={handleClick} {...props}>
			<span className="label">{label}</span>
		</span>
	) : null;
}
LinkProcesso.displayName = 'LinkProcesso';
LinkProcesso.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	admin: PropTypes.bool
};

export default LinkProcesso;
