import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { get, size, trim, isNil } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { send } from 'utils/injectApi';
import { titleCase } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';
const SERVER_CONSULTA_REGISTRO_PROFISSIONAL = 'registroProfissional/SERVER_CONSULTA_CREA_CAU';

/* eslint-disable react-hooks/exhaustive-deps */
const ResponsavelTecnicoField = ({
	title,
	label,
	required,
	placeHolder,
	name,
	value: propsValue,
	readOnly,
	disabled,
	onChangeHandler,
	ignoreValidationCauCrea,
	ocultarArtRrt,
	templateErrors = []
}) => {
	const [valueNome, setValueNome] = useState(null);
	const [valueRegistro, setValueRegistro] = useState(null);
	const [valueArtRrt, setValueArtRrt] = useState(null);
	const [valueRegistroProfissional, setValueRegistroProfissional] = useState(null);
	const [serviceOnline, setServiceOnline] = useState(true);

	const [loading, setLoading] = useState(false);
	const [validNome, setValidNome] = useState(true);
	const [validRegistro, setValidRegistro] = useState(true);
	const [validArtRrt, setValidArtRrt] = useState(true);
	const [statusRegistro, setStatusRegistro] = useState(false);
	// const [offline, setOffline] = useState(false);

	const requiredRegistro = required[1];
	const [nameErrors, registroErrors, artRrtErrors] = templateErrors;

	useEffect(() => {
		if (propsValue) {
			setValueNome(propsValue.nome);
			setValueRegistro(propsValue.registro);
			setValueArtRrt(propsValue.artRrt);
		}
	}, []);

	useEffect(() => {
		const value = { nome: valueNome, registro: valueRegistro, artRrt: valueArtRrt, serviceOnline, validRegistro };
		onChangeHandler({ name, value });
		if (!isNil(valueNome) || !isNil(valueRegistro) || !isNil(valueArtRrt)) {
			if (size(valueRegistro) === 0 && !requiredRegistro) setValidRegistro(true);
			onChangeHandler({ name: 'errors', value: { [name]: validate(value, required, label, title, true) } });
		}
	}, [valueNome, valueRegistro, valueArtRrt, valueRegistroProfissional, serviceOnline, validRegistro]);

	const setNome = e => {
		const nome = e.target.value;
		setValueNome(titleCase(nome));
		setValidNome(size(validateNome(nome)) === 0);
		return true;
	};

	const setRegistro = e => {
		const registro = e.target.value;
		setValueRegistro(registro);
		setStatusRegistro(false);
		return true;
	};

	const setArtRrt = e => {
		let artRrt = get(e, 'target.value', '');
		setValueArtRrt(artRrt);
		setValidArtRrt(size(validateArtRrt(artRrt)) === 0);
		return true;
	};

	const checkRegistro = () => {
		if (valueRegistro) {
			if (!ignoreValidationCauCrea) {
				setValidRegistro(false);
				setTimeout(async () => {
					await consultaRegistro(valueRegistro);
				});
				setValidRegistro(false);
			} else {
				setValidRegistro(true);
			}
		}
		return true;
	};

	const consultaRegistro = async registro => {
		let auxValidRegistro = false;
		let auxServiceOnline = true;
		let errors = [];

		try {
			if (size(registro) > 0) {
				setLoading(true);
				setStatusRegistro(true);
				// setOffline(false);
				const { data } = await send(
					{ type: SERVER_CONSULTA_REGISTRO_PROFISSIONAL, payload: { numeroRegistro: registro } },
					10000, //timeout
					''
				);
				setLoading(false);
				let retorno = {
					cpf: data.cpf,
					nome: data.nome,
					numeroRegistro:
						size(data.numeroRegistro) === 0 ? (size(registro) === 0 ? undefined : '') : data.numeroRegistro,
					situacao: data.status === 'ERRO' ? 'Registro inválido' : data.status,
					tipo: data.tipo
				};

				if (size(data.status) > 0) {
					if (data.status === 'ERRO') {
						errors.push('Registro inválido', data.message);
					} else if (data.status !== 'Profissional habilitado' && data.status !== 'ATIVO') {
						errors.push(data.status);
					}
					// if (data.offline) {
					// 	setOffline(true);
					// }
				} else if (size(data.error)) {
					errors.push(data.error === 'ERRO' ? 'Registro inválido' : data.error);
				}

				setValueRegistro(registro);
				if (size(errors) === 0) {
					setValueRegistroProfissional(retorno);
					auxValidRegistro = true;
					if (!valueNome && retorno.nome) {
						setValueNome(retorno.nome);
					}
				} else {
					setValueRegistroProfissional(null);
				}
			}
		} catch (error) {
			console.error('error: ', error);
			auxServiceOnline = false;
			auxValidRegistro = true;
			setLoading(false);
		}

		setValidRegistro(auxValidRegistro);
		setServiceOnline(auxServiceOnline);
		const auxValue = { ...propsValue, serviceOnline: auxServiceOnline, validRegistro: auxValidRegistro, errors };
		onChangeHandler({ name: 'errors', value: { [name]: validate(auxValue, required, label, title, true) } });
	};

	const nomeDiferente = () => {
		const nome1 = (valueNome || '').toLowerCase();
		const nome2 = (get(valueRegistroProfissional, 'nome') || '').toLowerCase();
		return nome1 !== nome2;
	};

	return (
		<div className="art-field">
			<div className="form-group col-md-12">
				<h3>{title}</h3>
				<label className="control-label">
					{label[0]}
					{required[0] && <span className="required">*</span>}
				</label>
				<input
					className={
						!validNome ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
					}
					type="text"
					placeholder={placeHolder[0]}
					name="nome"
					value={valueNome || ''}
					onChange={setNome}
					readOnly={readOnly}
					disabled={disabled}
				/>
				<ErrorMessages errorList={nameErrors} />
			</div>
			<div className={`form-group ${ocultarArtRrt ? 'col-md-12' : 'col-md-6'}`} style={{ verticalAlign: 'top' }}>
				<label className="control-label">
					{label[1]}
					{required[1] && <span className="required">*</span>}
				</label>
				<div className="input-with-icon">
					<input
						className={
							!validRegistro
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder[1]}
						name="registro"
						value={valueRegistro ? valueRegistro.toUpperCase() : ''}
						onChange={setRegistro}
						onBlur={checkRegistro}
						readOnly={readOnly}
						disabled={disabled}
					/>
					{loading && <i className="fa fa-refresh fa-spin" />}
					{!loading && statusRegistro && (
						<i
							className={`fa fa-${validRegistro ? 'check' : 'exclamation'}-circle`}
							title={
								get(valueRegistroProfissional, 'situacao') || validRegistro ? 'OK' : 'Registro Profissional inválido'
							}
						/>
					)}
				</div>
				<ErrorMessages errorList={registroErrors} />
			</div>
			{!ocultarArtRrt && (
				<div className="form-group col-md-6" style={{ verticalAlign: 'top' }}>
					<label className="control-label">
						{label[2]}
						{required[2] && <span className="required">*</span>}
					</label>
					<input
						className={
							!validArtRrt
								? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
								: `${CLASS_NAME_FORM_CONTROL}`
						}
						type="text"
						placeholder={placeHolder[2]}
						name="artRrt"
						value={valueArtRrt ? valueArtRrt.toUpperCase() : ''}
						onChange={setArtRrt}
						readOnly={readOnly}
						disabled={disabled}
					/>
					<ErrorMessages errorList={artRrtErrors} />
				</div>
			)}
			{!readOnly && size(get(valueRegistroProfissional, 'nome')) > 0 && nomeDiferente() && (
				<div className="col-md-12">
					{valueRegistroProfissional.nome}
					<button
						type="button"
						className={'btn btn-link'}
						onClick={() => setNome({ target: { value: valueRegistroProfissional.nome } })}
					>
						usar este
					</button>
				</div>
			)}
		</div>
	);
};

ResponsavelTecnicoField.displayName = 'ResponsavelTecnicoField';

ResponsavelTecnicoField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.arrayOf(PropTypes.string),
	required: PropTypes.arrayOf(PropTypes.bool),
	placeHolder: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	value: PropTypes.object,
	ignoreValidationCauCrea: PropTypes.bool,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	ocultarArtRrt: PropTypes.bool,
	templateErrors: PropTypes.array
};

export default ResponsavelTecnicoField;

export const validate = (
	rt,
	required,
	label = ['RT', 'CAU / CREA', 'ART / RRT'],
	title = 'Responsabilidade Técnica',
	interno = false
) => {
	const { nome, registro, artRrt, serviceOnline, validRegistro, errors: validErrors } = rt || {};
	const errors = [];

	const enomes = validateNome(nome, required[0], label[0], title);
	const ereg = validateRegistro(
		registro,
		required[1],
		label[1],
		title,
		serviceOnline,
		validRegistro,
		interno,
		validErrors
	);
	const ert = validateArtRrt(artRrt, required[2], label[2], title);

	if (enomes && enomes.length) {
		errors[0] = enomes;
	}
	if (ereg && ereg.length) {
		errors[1] = ereg;
	}
	if (ert && ert.length) {
		errors[2] = ert;
	}

	return errors;
};
const validateNome = (nome, required, label) => {
	let errors = [];
	if (size(trim(nome)) === 0) {
		if (required) {
			errors.push(`${label} deve ser informado`);
		}
	} else {
		if (size(trim(nome).split(' ').map(trim)) < 2) {
			errors.push('Nome completo deve ser informado');
		}
	}
	return errors;
};

const validateRegistro = (
	registro,
	required,
	label,
	title,
	serviceOnline,
	validRegistro,
	interno,
	errosValidacao = []
) => {
	let errors = [];
	if (size(registro) === 0) {
		if (required) errors.push(`${label} deve ser informado`);
	} else {
		if (!serviceOnline && interno) {
			//nao adiciona erro, somente exibe warning de servico offline
			// errors.push(`A consulta à base CREA/CAU está indisponível no momento e não podemos validar seu ${label}.`);
		} else {
			if (!validRegistro) {
				errors.push(`${label} inválido`);
				errors.push(...errosValidacao);
			}
		}
	}
	return errors;
};

const validateArtRrt = (artRrt, required, label) => {
	let errors = [];
	if (size(artRrt) === 0) {
		if (required) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
