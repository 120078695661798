import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useAxios from 'axios-hooks';
import { forEach, get, maxBy, size } from 'lodash';

import { TextField } from 'containers/Form/metadata-template/fields';

import { EXPEDIENTES_API_URL } from 'environments';

import { CERTIFICADOS_SUSTENTAVEL } from 'utils/constants';
import { formatCodigoEU } from 'utils/tools';

import seloBronze from '../../assets/images/selo_bronze.png';
import seloDiamante from '../../assets/images/selo_diamante.png';
import seloOuro from '../../assets/images/selo_ouro.png';
import seloPrata from '../../assets/images/selo_prata.png';
import { METAFORMLE } from './MetadataLE';

export function InfosExpedienteLE({
	formulario,
	onChangeHandler,
	data: localData,
	tipoForm = 'le1',
	onCertificadoSustentavel
}) {
	const expediente = get(formulario, 'formData.data.expediente');
	const enderecos = get(formulario, 'formData.data.enderecoCdlList', []);
	const isQuartoDistrito = enderecos.some(cdl => cdl.regiao === 'quarto_distrito');

	const showAreaPrivativa = !['le2', 'le3'].includes(tipoForm);
	const [certificado, setCertificado] = useState();

	const labelNomeLocal = {
		le1: 'Nome do condomínio',
		le2: 'Nome do loteamento',
		le3: 'Nome do loteamento'
	};

	/* eslint-disable-next-line no-unused-vars */
	const [{ data: exped, loading: euLoading, error: euError }, executeEu] = useAxios(
		`${EXPEDIENTES_API_URL}/eu/${get(expediente, '_id', '0')}`
	);

	useEffect(() => {
		if (exped && !euLoading && !euError) {
			// 2234 - bronze
			// 2235 - prata
			// 2236 - ouro
			// 2237 - diamante
			const codigosCertificacoes = {
				2234: CERTIFICADOS_SUSTENTAVEL.bronze,
				2235: CERTIFICADOS_SUSTENTAVEL.prata,
				2236: CERTIFICADOS_SUSTENTAVEL.ouro,
				2237: CERTIFICADOS_SUSTENTAVEL.diamante
			};
			const certificacoes = [];
			forEach(exped?.etapas, e => {
				const codigo = get(e, 'ocorrencia.codigo', '');
				const cert = get(codigosCertificacoes, `${codigo}`);
				if (cert) certificacoes.push(cert);
			});
			let certificado = maxBy(certificacoes, 'valor');
			setCertificado(certificado);
			onCertificadoSustentavel && onCertificadoSustentavel(certificado);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exped, euLoading, euError]);

	return (
		<>
			{!!certificado && (
				<div className="selo">
					<img
						src={
							certificado.nivel === CERTIFICADOS_SUSTENTAVEL.diamante.nivel
								? seloDiamante
								: certificado.nivel === CERTIFICADOS_SUSTENTAVEL.ouro.nivel
								? seloOuro
								: certificado.nivel === CERTIFICADOS_SUSTENTAVEL.prata.nivel
								? seloPrata
								: seloBronze
						}
						alt={`selo sustentável ${certificado.nivel}`}
					/>
				</div>
			)}
			<fieldset>
				{/* <legend>Informações de Expediente Único / Endereços</legend> */}
				<div className="row id-le id-le-dinamico">
					<div className="col-auto exp-un">
						<label>Expediente Único </label>
						{size(get(expediente, 'numero')) > 0 ? (
							<span>{formatCodigoEU(get(expediente, 'numero'), true)}</span>
						) : (
							<span className="nao-informado">Não informado</span>
						)}
					</div>

					{showAreaPrivativa && (
						<div className="col area-priv">
							<label>
								Área Privativa <small>(unidade privativa/autônoma)</small>
							</label>
							{size(get(expediente, 'codigo')) > 0 ? (
								<span>{get(expediente, 'codigo', '').substr(12, 5)}</span>
							) : (
								<span className="nao-informado">Não informado</span>
							)}
						</div>
					)}
				</div>
				<div className="id-le">
					<label>Endereço(s) da solicitação </label>
					{size(enderecos) > 0 && (
						<div className="endereco">
							<ul>
								{enderecos.map((item, index) => (
									<li key={index}>{item.enderecoFormatadoCurto}</li>
								))}
							</ul>
						</div>
					)}
					{isQuartoDistrito && <span>{'O endereço informado está localizado em área do 4º Distrito'}</span>}
				</div>
				{!!certificado && (
					<div className="id-le">
						<label>Certificação em Sustentabilidade Ambiental</label>
						<p>O expediente possui o Certificação em Sustentabilidade Ambiental nível {certificado.nivel}</p>
					</div>
				)}
				<div className="nome-lot">
					<TextField
						className="form-group"
						name={METAFORMLE.nomeLocal.name}
						label={labelNomeLocal[tipoForm] || 'Nome do loteamento'}
						value={localData[METAFORMLE.nomeLocal.name]}
						onChangeHandler={onChangeHandler}
					/>
				</div>
			</fieldset>
		</>
	);
}

InfosExpedienteLE.displayName = 'InfosExpedienteLE';
InfosExpedienteLE.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	tipoForm: PropTypes.string,
	onCertificadoSustentavel: PropTypes.func
};
