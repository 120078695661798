import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { accessApi } from 'utils/injectApi';

import { TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES } from '../Detalhe';
import TemplateForm from '../metadata-template/TemplateForm';
import { obterPotencialPorte } from './obterPotencialPorte';
import { obterValidade } from './obterValidade';
import { obterValorUfm } from './obterValorUfm';
import { validateRules } from './validateRules';

export const TIPOS_POLUICAO = {
	baixo: 'Baixo',
	medio: 'Médio',
	alto: 'Alto'
};
export const TIPOS_LAC = {
	unica: 'licencaUnica',
	previa: 'licencaPrevia',
	instalacao: 'licencaInstalacao',
	operacao: 'licencaOperacao'
};
export const TIPOS_PORTE = {
	especialUnico: 'especialUnico',
	naoIncidencia: 'naoIncidencia',
	minimo: 'porteMinimo',
	pequeno: 'portePequeno',
	medio: 'porteMedio',
	grande: 'porteGrande',
	excepcional: 'porteExcepcional'
};
export const TIPOS_PPCI = {
	ppci: 'ppci',
	appci: 'appci'
};

const metadataform = {
	fields: {
		infoConsema: {
			type: 'info',
			info: '<p>Para consultar o seu código de atividade <i>CODRAM</i> utilize a Resolução CONSEMA 372/2018</p>	 <a target="_blank" rel="noopener noreferrer" href="https://www.sema.rs.gov.br/upload/arquivos/202001/28154433-372-2018-atividades-licenciaveis-compilada.pdf">Consultar Resolução CONSEMA</a>'
		},
		codram: {
			label: 'CONSEMA CODRAM',
			type: 'typeahead',
			columns: 6,
			attrCodigo: 'codram',
			attrDescricao: 'descricao',
			required: true,
			fullObject: true
		},
		medidaPorte: {
			label: 'Medida Porte',
			type: 'number',
			decimals: 2,
			maxSize: '12',
			columns: 6,
			required: true
		},
		tipoLicenca: {
			label: 'Tipo de Licença',
			type: 'select',
			required: true,
			options: [
				{ codigo: TIPOS_LAC.unica, descricao: 'Licença Única' },
				{ codigo: TIPOS_LAC.previa, descricao: 'Licença Prévia' },
				{ codigo: TIPOS_LAC.instalacao, descricao: 'Licença de Instalação' },
				{ codigo: TIPOS_LAC.operacao, descricao: 'Licença de Operação' }
			]
		},
		tipoPPCI: {
			label: 'Proteção Contra Incêndios',
			type: 'radio',
			required: true,
			columns: 6,
			options: [
				{ codigo: TIPOS_PPCI.ppci, descricao: 'PPCI' },
				{ codigo: TIPOS_PPCI.appci, descricao: 'APPCI' }
			]
		},
		validadeLicenca: {
			label: 'Validade',
			type: 'text',
			readOnly: true,
			columns: 6
		},
		porte: {
			label: 'Porte',
			type: 'text',
			readOnly: true,
			columns: 6
		},
		potencialPoluidor: {
			label: 'Potencial Poluidor',
			type: 'text',
			readOnly: true,
			columns: 6
		},
		valorUfm: {
			label: 'Valor em UFM',
			type: 'text',
			readOnly: true,
			columns: 6
		},
		valorTotal: {
			label: 'Valor Final em UFM (UFM x Validade)',
			type: 'text',
			readOnly: true,
			columns: 6
		},
		infoUFM: {
			type: 'info',
			info: `<p>A UFM foi instituída pela Lei Complementar 303/93 e é a indexadora de todos os tributos municipais, dos valores relativos a juros, multas e penalidades tributárias e administrativas.
			O valor atual pode ser consultado aqui: <a target="_blank" rel="noopener noreferrer" href='http://www2.portoalegre.rs.gov.br/smf/default.php?p_secao=154'>Valor UFM</a></p>`
		}
	}
};

/**
 * Formulario para preencher os dados referentes ao calculo do valor em ufm
 * da licença ambiental
 */
export function FormLicencaAmbiental({ formData, onSubmitHandler, readOnly, tipoFormulario }) {
	// states
	const [formLicenca, setFormLicenca] = useState(metadataform);
	const [licencaData, setLicencaData] = useState({});
	const [errosForm, setErros] = useState();

	// on init
	useEffect(() => {
		let codramsFiltro = [];
		if (tipoFormulario === TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES) {
			// 3419,20 ESTACIONAMENTO DE FROTISTAS COM MANUTENÇÃO DE VEÍCULO
			// 3430,10 LAVAGEM COMERCIAL DE VEÍCULO/S
			// 3430,20 OFICINA MECÂNICA/CHAPEAÇÃO/PINTURA
			codramsFiltro = ['3430,20', '3419,20', '3430,10'];
		}

		async function getAtividadesLicenciaveis() {
			const query = codramsFiltro.length > 0 ? `?codram=${codramsFiltro.join('&codram=')}` : '';
			const url = `/collections/consema-atividades-licenciaveis${query}`;
			const result = await accessApi(url, true);
			const options = get(result, 'data', []).sort((o1, o2) => {
				const c1 = parseFloat(o1.codram.replace(',', '.'));
				const c2 = parseFloat(o2.codram.replace(',', '.'));
				return c1 > c2 ? 1 : -1;
			});

			metadataform.fields.codram.options = options;
			setFormLicenca({ ...metadataform });
		}
		getAtividadesLicenciaveis();
	}, [tipoFormulario]);
	// on init data
	useEffect(() => {
		const { data: { LACAmbiental = {} } = {} } = formData;
		setLicencaData(LACAmbiental);
	}, [formData]);

	function changeFormLicenca(params) {
		const { key, value } = params;
		Object.assign(licencaData, { [key]: value });
		obterPotencialPorte(licencaData, pp => Object.assign(licencaData, pp));
		obterValorUfm(licencaData, vl => Object.assign(licencaData, vl));
		obterValidade(licencaData, vl => Object.assign(licencaData, vl));

		setLicencaData(Object.assign({}, licencaData));
	}

	function onSubmit(result) {
		const [ok, erros] = result;
		const [vOk, vErros] = result.pop();
		const tudoOK = ok && vOk;
		const listaErros = [...erros, ...vErros];

		if (!tudoOK) {
			setErros(listaErros);
		} else {
			setErros(undefined);
			const { data: prevdata } = formData;
			const newdata = Object.assign({}, prevdata, { LACAmbiental: licencaData });
			onSubmitHandler && onSubmitHandler({ formData: newdata });
		}
	}

	return (
		<>
			<TemplateForm
				fieldsets={[formLicenca]}
				onChangeHandler={changeFormLicenca}
				formData={licencaData}
				onSubmit={onSubmit}
				validateForm={validateRules}
				readOnly={readOnly}
				disabled={readOnly}
			/>
			{errosForm && <ErrorMessages errorList={errosForm} />}
		</>
	);
}

FormLicencaAmbiental.displayName = 'FormLicencaAmbiental';
FormLicencaAmbiental.propTypes = {
	tipoFormulario: PropTypes.string,
	formData: PropTypes.object,
	readOnly: PropTypes.bool,
	onSubmitHandler: PropTypes.func
};
