import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { get, size, repeat } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { format, permiteInput } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const NumberField = ({
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	maxSize,
	minValue,
	maxValue,
	checkForZero,
	onChangeHandler,
	onSetFocus = () => {},
	hasFocus
}) => {
	const inputRef = useRef();
	const onChangeNumber = e => {
		let { value: input } = e.target;
		input = (input || '').replace(/[^0-9]/g, '');
		if (get(value, 'checkedForZero') || permiteInput(input, 'validnumber', 0, maxSize || 5)) {
			const erros = validate(
				{ value: input, checkedForZero: get(value, 'checkedForZero') },
				required,
				label,
				minValue,
				maxValue
			);
			onChangeHandler([
				{ name, value: { value: input, checkedForZero: get(value, 'checkedForZero') } },
				{ name: 'errors', value: { [name]: erros } }
			]);
		}
	};

	const onChangeCheck = () => {
		const checked = !get(value, 'checkedForZero');
		const valor = checked ? '0' : '';
		const erros = checked ? validate({ value: '0', checkedForZero: checked }, required, label, minValue, maxValue) : [];
		onChangeHandler([
			{ name, value: { value: valor, checkedForZero: checked } },
			{ name: 'errors', value: { [name]: erros } }
		]);
		if (!checked) {
			setTimeout(() => {
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}, 100);
		}
	};

	const valorFormatado = input => {
		if (size(input) === 0) {
			return '';
		}
		const tamanhoInteiro = input.length;
		const demaisPartes = Math.floor(tamanhoInteiro / 3);
		const parteInicial = (tamanhoInteiro - demaisPartes * 3) % 3;
		const p1 = parteInicial > 0 ? repeat('0', parteInicial) : '';
		const p2 = demaisPartes > 0 ? repeat('.000', demaisPartes) : '';
		let mascara = p1 + p2;
		if (mascara.startsWith('.')) {
			mascara = mascara.substring(1);
		}
		const formatado = format(input || '', mascara);
		return formatado;
	};

	const onFocusHandler = () => {
		onSetFocus(name);
	};

	const onBlurHandler = () => {
		onSetFocus(null);
	};

	return (
		<div className={`${containerClass}`} id="numberContainerClass">
			<div className={`col-md-${checkForZero ? '6' : '12'}`} id="numberCol1">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
				<input
					ref={inputRef}
					className={
						size(errorList) > 0
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					type="text"
					placeholder={placeHolder}
					name={name}
					value={hasFocus ? get(value, 'value') || '' : valorFormatado(get(value, 'value') || '')}
					onChange={onChangeNumber}
					onFocus={onFocusHandler}
					onBlur={onBlurHandler}
					readOnly={readOnly}
					disabled={disabled || get(value, 'checkedForZero')}
				/>
			</div>
			{checkForZero && !disabled && !readOnly && (
				<div className="form-check col-md-6" style={{ whiteSpace: 'nowrap', paddingTop: '40px' }} id="numberCol2">
					<label>
						<input
							type="checkbox"
							name={name}
							onClick={onChangeCheck}
							readOnly={readOnly}
							disabled={disabled}
							onChange={() => false}
							checked={!!get(value, 'checkedForZero')}
						/>{' '}
						<span>Não há {(label || 'valor a ser informado').toLowerCase()}</span>
					</label>
				</div>
			)}
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
NumberField.displayName = 'NumberField';

NumberField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	maxSize: PropTypes.string,
	minValue: PropTypes.string,
	maxValue: PropTypes.string,
	hasFocus: PropTypes.bool,
	checkForZero: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	onSetFocus: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default NumberField;

export const validate = (value, required, label = 'Campo', minValue, maxValue) => {
	let errors = [];
	const { value: valor, checkedForZero = false } = value || {};
	if (required) {
		if (!checkedForZero) {
			const intValue = parseInt(valor || 0, 10);
			if (!intValue || intValue === 0) {
				errors.push(`${label} deve ser informado`);
			} else {
				if (minValue && intValue < parseInt(minValue, 10)) {
					errors.push(`${label} não deve ser menor que ${minValue}`);
				}
				if (maxValue && intValue > parseInt(maxValue, 10)) {
					errors.push(`${label} naõ deve ser maior que ${maxValue}`);
				}
			}
		}
	}
	return errors;
};
