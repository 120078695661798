import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { size, snakeCase } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Selecao from 'components/Selecao';

import { TextField } from 'containers/Form/metadata-template/fields';

import { filterList, isDebug } from 'utils/tools';

export function SelecaoSubfield({
	value,
	errors,
	onChangeHandler,
	onRemoveHandler,
	multiple,
	label,
	searchList,
	fieldName,
	placeholder,
	loading
}) {
	const [searchTerm, setSearchTerm] = useState();
	const [outro, setOutro] = useState();
	const [hasOutro, setHasOutro] = useState(false);

	const handleSelect = useCallback(
		({ name, value: item, operacao }) => {
			const aux = value || {};
			if (item.codigo === 'outro') {
				setHasOutro(true);
				setSearchTerm(undefined);
			} else if (operacao === 'add-outro') {
				aux[item.codigo] = { ...item };
				setHasOutro(false);
				setOutro(undefined);
			} else if (operacao === 'add') {
				aux[item.codigo] = { ...item };
			}
			if (operacao === 'remove') {
				delete aux[item.codigo];
			}
			onChangeHandler({ name, value: { ...aux } });
		},
		[onChangeHandler, value]
	);

	return (
		<div className="pergunta">
			<label className="control-label">
				<span>
					Tipo de material
					<span className="required" style={{ color: 'red' }}>
						*
					</span>
				</span>
				{onRemoveHandler && (
					<button type="button" className="btn btn-link" onClick={onRemoveHandler}>
						<i className="fa fa-trash" />
					</button>
				)}
			</label>
			<Selecao
				className={'form-control'}
				multiple={multiple}
				selected={Object.values(value || {})}
				label={label}
				detailCodigo={''}
				detailDescricao={'descricao'}
				autoShowList={true}
				searchTerm={searchTerm}
				searchList={filterList(searchList, searchTerm)}
				searchTermMinLength={1}
				errorList={false}
				onChangeSearchTerm={e => setSearchTerm(e.target.value)}
				onBlurSearchTerm={() => false}
				onSelectItem={item => () => handleSelect({ name: fieldName, value: item, operacao: 'add' })}
				onUnselect={item => () => handleSelect({ name: fieldName, value: item, operacao: 'remove' })}
				loading={loading}
				required={true}
				placeholder={placeholder}
			/>
			{isDebug && <div className="debug-message">{fieldName}</div>}
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
			{hasOutro && (
				<div style={{ marginTop: '8px' }}>
					<TextField
						name={snakeCase(`outro${fieldName}`)}
						value={outro || ''}
						label={`Descrever Outro ${label}`}
						placeHolder={`Informar o nome do outro ${label}...`}
						onChangeHandler={([{ value }]) => setOutro(value)}
					/>
					<button
						type="button"
						onClick={() => {
							let item = { descricao: outro, codigo: snakeCase(outro) };
							size(outro) &&
								handleSelect({
									name: fieldName,
									value: item,
									operacao: 'add-outro'
								});
						}}
					>
						Adicionar
					</button>
				</div>
			)}
		</div>
	);
}
SelecaoSubfield.displayName = 'LicencasExpressasSelecaoSubfield';
SelecaoSubfield.propTypes = {
	value: PropTypes.any,
	label: PropTypes.string,
	onChangeHandler: PropTypes.func,
	onRemoveHandler: PropTypes.func,
	multiple: PropTypes.bool,
	searchList: PropTypes.array,
	fieldName: PropTypes.string,
	placeholder: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.string),
	loading: PropTypes.bool
};
