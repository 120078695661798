import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

const AccordionHeader = styled.button`
	border-bottom: 2px solid ${props => (props.aba === props.abaAtiva ? '#09afa7' : 'transparent')} !important;
	&:active,
	&:focus {
		outline: none;
		box-shadow: none;
		border-bottom: 2px solid ${props => (props.aba === props.abaAtiva ? '#09afa7' : 'transparent')} !important;
	}
`;

const AccordionSection = ({ title, component: Component, componentProps, identificacaoAba, aba, setAba }) => (
	<section className="accordion-section">
		<div className="header col-12 ">
			<AccordionHeader
				abaAtiva={aba}
				aba={identificacaoAba}
				type="button"
				onClick={() => setAba(aba === identificacaoAba ? null : identificacaoAba)}
			>
				<span>{title}</span>
				{aba === identificacaoAba ? (
					<i className="fa fa-angle-up" aria-hidden="true" />
				) : (
					<i className="fa fa-angle-down" aria-hidden="true" />
				)}
			</AccordionHeader>
		</div>
		{aba === identificacaoAba && (
			<div className={`content py-3 ${aba === identificacaoAba ? 'active' : ''}`}>
				<Component {...componentProps} />
			</div>
		)}
	</section>
);
AccordionSection.propTypes = {
	title: PropTypes.string,
	aba: PropTypes.number,
	identificacaoAba: PropTypes.number,
	component: PropTypes.any,
	componentProps: PropTypes.object,
	setAba: PropTypes.func
};
AccordionSection.displayName = 'AccordionSection';

export default AccordionSection;
