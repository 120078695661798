import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';

import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';

import { METAFORMAP } from './MetadataAP';
const sufixoComentario = 'Comentario';

export function InfosDispositivosControleAP({
	data: localData,
	dataAnterior,
	onChangeHandler,
	changeComentarioHandler = null,
	errosSubmissao: errosSubmissaoProps,
	disabled = false,
	usuarioInterno = false,
	readOnlyVersao = null
}) {
	const showALP = true;
	const [temComentario, setTemComentario] = useState({});

	const setTemComentarioAux = valores => {
		const { name, value } = valores || {};
		if (temComentario[name] !== value) {
			setTemComentario(oldState => {
				const newState = { ...oldState };
				newState[name] = value;
				return newState;
			});
		}
	};

	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	return (
		<>
			<fieldset>
				<legend>
					2. Dispositivos de controle para a Unidade Autônoma{' '}
					<small className="d-block">conforme a aprovação do condomínio</small>
				</legend>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="areaTotalMatriculaLote"
						label="Área da matrícula da unidade autônoma (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.areaTotalMatriculaLote}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['areaTotalMatriculaLote']}
						dataAtual={localData['areaTotalMatriculaLote']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`areaTotalMatriculaLote${sufixoComentario}`}
						valueComentario={localData[`areaTotalMatriculaLote${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`areaTotalMatriculaLote${sufixoComentario}`]}
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'areaTotalMatriculaLote')} />
				</div>
				<h2 className="legend_secundaria">Quotas</h2>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="quotaIndiceAproveitamento"
						label="Índice de Aproveitamento - IA (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaIndiceAproveitamento}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['quotaIndiceAproveitamento']}
						dataAtual={localData['quotaIndiceAproveitamento']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`quotaIndiceAproveitamento${sufixoComentario}`}
						valueComentario={localData[`quotaIndiceAproveitamento${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`quotaIndiceAproveitamento${sufixoComentario}`]}
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaIndiceAproveitamento')} />
				</div>
				<div className="pergunta metragem">
					<NumberFieldLE
						name="quotaTaxaOcupacao"
						label="Taxa de Ocupação - TO (m²)"
						placeHolder="metragem m²"
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.quotaTaxaOcupacao}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior['quotaTaxaOcupacao']}
						dataAtual={localData['quotaTaxaOcupacao']}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`quotaTaxaOcupacao${sufixoComentario}`}
						valueComentario={localData[`quotaTaxaOcupacao${sufixoComentario}`]}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={temComentario[`quotaTaxaOcupacao${sufixoComentario}`]}
					/>
					<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaTaxaOcupacao')} />
				</div>
				{showALP && (
					<div className="pergunta metragem">
						<NumberFieldLE
							name="quotaALP"
							label="Área Livre Permeável - ALP (m²)"
							placeHolder="metragem m²"
							required={showALP}
							onChangeHandler={onChangeHandler}
							value={localData.quotaALP}
							decimals={2}
							maxSize="10"
							disabled={disabled}
						/>
						<HistoricoRespostaAnterior
							dataAnterior={dataAnterior['quotaALP']}
							dataAtual={localData['quotaALP']}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`quotaALP${sufixoComentario}`}
							valueComentario={localData[`quotaALP${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`quotaALP${sufixoComentario}`]}
						/>
						<ErrorMessages errorList={(errosSubmissao || []).filter(e => e.campo === 'quotaALP')} />
					</div>
				)}
				<h2 className="legend_secundaria">Alturas Máximas</h2>
				<div className="pergunta metragem">
					<NumberFieldLE
						name={METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name}
						label={METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.label}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.alturaMaximaRegimeUrbanistico}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior[METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name]}
						dataAtual={localData[METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name]}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name}${sufixoComentario}`}
						valueComentario={
							localData[`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name}${sufixoComentario}`]
						}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={
							temComentario[`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name}${sufixoComentario}`]
						}
					/>
					<ErrorMessages
						errorList={(errosSubmissao || []).filter(
							e => e.campo === METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaRegimeUrbanistico.name
						)}
					/>
				</div>
				<div className="pergunta metragem">
					<NumberFieldLE
						name={METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name}
						label={METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.label}
						required={true}
						onChangeHandler={onChangeHandler}
						value={localData.alturaMaximaAeroportoAerodromo}
						decimals={2}
						maxSize="10"
						disabled={disabled}
					/>
					<HistoricoRespostaAnterior
						dataAnterior={dataAnterior[METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name]}
						dataAtual={localData[METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name]}
						usuarioInterno={usuarioInterno}
					/>
					<Comentario
						campoComentario={`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name}${sufixoComentario}`}
						valueComentario={
							localData[`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name}${sufixoComentario}`]
						}
						onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? changeComentarioHandler : null}
						setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
						readOnly={readOnlyVersao || !usuarioInterno}
						temComentario={
							temComentario[
								`${METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name}${sufixoComentario}`
							]
						}
					/>
					<ErrorMessages
						errorList={(errosSubmissao || []).filter(
							e => e.campo === METAFORMAP.INFOS_ALTURAS_MAXIMAS.alturaMaximaAeroportoAerodromo.name
						)}
					/>
				</div>
			</fieldset>
		</>
	);
}

InfosDispositivosControleAP.displayName = 'InfosDispositivosControleAP';
InfosDispositivosControleAP.propTypes = {
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	changeComentarioHandler: PropTypes.func,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	disabled: PropTypes.bool,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any
};
