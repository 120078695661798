import React from 'react';

import PropTypes from 'prop-types';

function WarningMessage({ children, ...props }) {
	return (
		<div className="warning-message" {...props}>
			<span>{children}</span>
		</div>
	);
}
WarningMessage.displayName = 'WarningMessage';
WarningMessage.propTypes = {
	children: PropTypes.node
};

export default WarningMessage;
