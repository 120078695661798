import React, { useState, useEffect, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { get, omit, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';

import { EXTENSAO_DOC_ESPECIFICO_UDRI } from 'containers/Form/Detalhe';

import useMessages from 'custom-hooks/useMessages';
import useMutableState from 'custom-hooks/useMutableState';

import { DATA_CONTAINERS } from 'utils/constants';
import { sendApi } from 'utils/injectApi';
import { calculaNumeroLinhas, isDebug } from 'utils/tools';

import actions from '../Form/actions';

const debug = false && isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(ARRAZOADO)', ++i, ...args);

const tiposSolos = ['PequenoAdensamento', 'MedioAdensamento', 'GrandeAdensamento', 'NaoAdensavel'];

const RequerimentoIndices = ({ setShowFormulario }) => {
	// #region hooks
	/* REDUX */
	const dispatch = useDispatch();
	/* OUTROS HOOKS */
	const { createMessage } = useMessages();
	const { id } = useParams();

	/* ESTADOS */
	const formulario = useMutableState(['licenciamento', 'formulario']);
	const errors = useMutableState(['licenciamento', 'errors']);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [solosReadOnly, setSolosReadOnly] = useState({});

	const planilhaUdri = useMemo(
		() => get(formulario, `formData.data.${DATA_CONTAINERS.AP1800}.planilha`) || {},
		[formulario]
	);
	const areaPA = useMemo(() => planilhaUdri?.soloCriadoPequenoAdensamento?.subtotalAreaCalculada || 0, [planilhaUdri]);
	const areaMA = useMemo(() => planilhaUdri?.soloCriadoMedioAdensamento?.subtotalAreaCalculada || 0, [planilhaUdri]);
	const areaGA = useMemo(() => planilhaUdri?.soloCriadoGrandeAdensamento?.subtotalAreaCalculada || 0, [planilhaUdri]);
	const areaNA = useMemo(
		() => (planilhaUdri?.soloCriadoNaoAdensavel?.areas || []).reduce((acc, area) => acc + (area?.informada || 0), 0),
		[planilhaUdri]
	);
	// #endregion

	useEffect(() => {
		if (formulario) {
			debugLog('[formulario]', formulario);
			const docDados = obtemRequerimentoIndicesDados(formulario);
			setData(docDados?.dados);
			// partes readonly conforme ap
			const planilha = get(formulario, `formData.data.${DATA_CONTAINERS.AP1800}.planilha`);
			tiposSolos.forEach(ts => {
				let area = get(planilha, `soloCriado${ts}.subtotalAreaCalculada`, 0);
				area = parseFloat(area);
				setSolosReadOnly(old => Object.assign({}, old, { [ts]: Number.isNaN(area) || area <= 0.0 }));
			});
		}
	}, [formulario]);

	const validate = useCallback(() => {
		let errors = {};

		if (areaPA !== 0 && size(data.tipoBoletoPequenoAdensamento) === 0) {
			errors.tipoBoletoPequenoAdensamento = ['Informe o tipo de boleto para pequeno adensamento'];
		}

		if (areaMA !== 0 && size(data.tipoBoletoMedioAdensamento) === 0) {
			errors.tipoBoletoMedioAdensamento = ['Informe o tipo de boleto para médio adensamento'];
		}

		if (areaGA !== 0 && size(data.tipoBoletoGrandeAdensamento) === 0) {
			errors.tipoBoletoGrandeAdensamento = ['Informe o tipo de boleto para grande adensamento'];
		}

		if (areaNA !== 0 && size(data.tipoBoletoNaoAdensavel) === 0) {
			errors.tipoBoletoNaoAdensavel = ['Informe o tipo de boleto para não adensável'];
		}

		return errors;
	}, [data, areaGA, areaMA, areaNA, areaPA]);

	const camposAremover = useCallback(() => {
		const remove = {};

		if (areaPA === 0) {
			remove.tipoBoletoPequenoAdensamento = true;
		}

		if (areaMA === 0) {
			remove.tipoBoletoMedioAdensamento = true;
		}

		if (areaGA === 0) {
			remove.tipoBoletoGrandeAdensamento = true;
		}

		if (areaNA === 0) {
			remove.tipoBoletoNaoAdensavel = true;
		}

		return remove;
	}, [areaGA, areaMA, areaNA, areaPA]);

	const submit = async ({ draft }) => {
		let errors = validate();
		if (size(errors) !== 0) {
			dispatch(actions.setErrors(errors));
		} else {
			try {
				dispatch(actions.setErrors({}));
				setLoading(true);
				// limpa itens que não deveriam estar preenchidos
				const url = `processo/${id}/${EXTENSAO_DOC_ESPECIFICO_UDRI}${draft ? '?draft=true' : ''}`;
				const newData = { ...omit(data, Object.keys(camposAremover())) };
				const { data: dataApi } = await sendApi({ url, payload: { form: newData } }, false);
				dispatch(
					actions.alteraStatusDocumento({
						documento: dataApi.documento,
						documentoDados: dataApi.documentoDados
					})
				);
				setLoading(false);
				setShowFormulario(false);
				createMessage(`Documento salvo${draft ? ' como rascunho' : ''} com sucesso`, 5);
			} catch (error) {
				createMessage('Problemas no salvamento do documento... verifique a console', 3);
				console.error('erro no servidor', error);
			}
		}
	};

	const handleOnChange = e => {
		const { name, value } = e.target;
		if (name) {
			setData(oldState => ({
				...oldState,
				[name]: value
			}));
		}
	};

	return (
		<>
			<div className="container requerimento-indices">
				<h1>Documento Requerimento de Índices</h1>
				<h2>Requerimento em atendimento ao Art. 8º do Decreto 21.585/2022</h2>
				{/* INSTRUCAO */}
				<div
					style={{
						backgroundColor: 'ghostwhite',
						padding: '10px',
						marginBottom: '10px'
					}}
				>
					<p>
						Conforme determinado no artigo 8º do Decreto 21.585/2022, encaminhamos o processo para que o requerente
						anexe, caso tenha interesse, requerimento solicitando pagamento da outorga onerosa do direito de construir
						em moeda corrente nacional, em até 8 meses, em parcela única.
					</p>
					<p>
						Caso não tenha interesse em solicitar este prazo, deve informar neste comparecimento e estar ciente que
						receberá o boleto para pagamento em até 30 dias da data de resposta e a respectiva emissão da Certidão de
						Solo Criado, quando da quitação do débito.
					</p>
				</div>
				{/* PEQUENO */}
				{areaPA !== 0 && (
					<section>
						<h3>Pequeno adensamento</h3>
						<div className="container-checkboxes">
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoPequenoAdensamento"
									value="agora"
									checked={'agora' === data?.tipoBoletoPequenoAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['PequenoAdensamento']}
								/>
								Em até 30 dias
							</label>
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoPequenoAdensamento"
									value="8meses"
									checked={'8meses' === data?.tipoBoletoPequenoAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['PequenoAdensamento']}
								/>
								Em até 8 meses, parcela única
							</label>
							{size(errors?.tipoBoletoPequenoAdensamento) !== 0 && (
								<ErrorMessages errorList={errors.tipoBoletoPequenoAdensamento} />
							)}
						</div>
					</section>
				)}
				{/* MEDIO */}
				{areaMA !== 0 && (
					<section>
						<h3>Médio adensamento</h3>
						<div className="container-checkboxes">
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoMedioAdensamento"
									value="agora"
									checked={'agora' === data?.tipoBoletoMedioAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['MedioAdensamento']}
								/>
								Em até 30 dias
							</label>
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoMedioAdensamento"
									value="8meses"
									checked={'8meses' === data?.tipoBoletoMedioAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['MedioAdensamento']}
								/>
								Em até 8 meses, parcela única
							</label>
							{size(errors?.tipoBoletoMedioAdensamento) !== 0 && (
								<ErrorMessages errorList={errors.tipoBoletoMedioAdensamento} />
							)}
						</div>
					</section>
				)}
				{/* GRANDE */}
				{areaGA !== 0 && (
					<section>
						<h3>Grande adensamento</h3>
						<div className="container-checkboxes">
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoGrandeAdensamento"
									value="agora"
									checked={'agora' === data?.tipoBoletoGrandeAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['GrandeAdensamento']}
								/>
								Em até 30 dias
							</label>
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoGrandeAdensamento"
									value="8meses"
									checked={'8meses' === data?.tipoBoletoGrandeAdensamento}
									onChange={handleOnChange}
									disabled={solosReadOnly['GrandeAdensamento']}
								/>
								Em até 8 meses, parcela única
							</label>
							{size(errors?.tipoBoletoGrandeAdensamento) !== 0 && (
								<ErrorMessages errorList={errors.tipoBoletoGrandeAdensamento} />
							)}
						</div>
					</section>
				)}
				{/* NAO ADENSAVEL */}
				{areaNA !== 0 && (
					<section>
						<h3>Não adensável</h3>
						<div className="container-checkboxes">
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoNaoAdensavel"
									value="agora"
									checked={'agora' === data?.tipoBoletoNaoAdensavel}
									onChange={handleOnChange}
									disabled={solosReadOnly['NaoAdensavel']}
								/>
								Em até 30 dias
							</label>
							<label className="label-checkbox">
								<input
									type="radio"
									className="checkbox-input"
									name="tipoBoletoNaoAdensavel"
									value="8meses"
									checked={'8meses' === data?.tipoBoletoNaoAdensavel}
									onChange={handleOnChange}
									disabled={solosReadOnly['NaoAdensavel']}
								/>
								Em até 8 meses, parcela única
							</label>
							{size(errors?.tipoBoletoNaoAdensavel) !== 0 && (
								<ErrorMessages errorList={errors.tipoBoletoNaoAdensavel} />
							)}
						</div>
					</section>
				)}
				{/* OBS */}
				<section
					style={{
						backgroundColor: 'ghostwhite',
						border: '1px solid',
						padding: '10px',
						marginBottom: '10px'
					}}
				>
					<label>Observações</label>
					<textarea
						name="observacoes"
						defaultValue={data?.observacoes}
						onChange={handleOnChange}
						rows={calculaNumeroLinhas(data?.observacoes)}
					/>
				</section>
				{size(errors?.observacoes) !== 0 && <ErrorMessages errorList={errors.observacoes} />}
			</div>
			<div className="cjto-botoes">
				<button type="button" className="btn btn-secondary mt-3 mr-2" onClick={() => submit({ draft: true })}>
					Salvar Rascunho
				</button>
				<button type="button" className="btn btn-primary mt-3" onClick={() => submit({ draft: false })}>
					Salvar e gerar PDF
				</button>
			</div>
			{loading && <Loader msg="Enviando dados do documento..." />}
		</>
	);
};
RequerimentoIndices.displayName = 'RequerimentoIndices';
RequerimentoIndices.propTypes = {
	setShowFormulario: PropTypes.func,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool
};

export default RequerimentoIndices;

export function obtemRequerimentoIndicesDados(formulario) {
	const doc = (formulario.documentos || []).reduce(
		(acc, d) => (d.extensao === EXTENSAO_DOC_ESPECIFICO_UDRI ? (acc ? (d.versao > acc.versao ? d : acc) : d) : acc),
		null
	);
	let docDados = doc ? (formulario.documentosDados || []).find(docDados => docDados.id === doc.id) : null;
	if (!docDados) {
		docDados = doc ? (formulario.documentosDados || []).find(docDados => docDados.id === doc.original) : null;
	}
	if (!docDados) {
		docDados = {};
	}
	return docDados;
}
