import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import actions from 'containers/Form/actions';

import useMutableState from './useMutableState';

/**
 * @returns {[any, function]} same as useState hook but for errors using store
 */
const useErrors = (path = ['licenciamento', 'errors']) => {
	const dispatch = useDispatch();

	const errors = useMutableState(path);

	const setErrors = useCallback(
		e => {
			const value = typeof e === 'function' ? e(errors) : e;
			dispatch(actions.setErrors(value));
		},
		[dispatch, errors]
	);

	return [errors, setErrors];
};

export default useErrors;
