import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { EMAIL_REGEX } from 'utils/constants';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const EmailField = ({
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler
}) => {
	const onChangeText = e => {
		const { value } = e.target;
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};
	return (
		<div className={containerClass}>
			<div className="col-md-12">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
				<input
					className={
						size(errorList) > 0
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					type="text"
					placeholder={placeHolder}
					name={name}
					value={value}
					onChange={onChangeText}
					readOnly={readOnly}
					disabled={disabled}
					required={required}
				/>
			</div>
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
EmailField.displayName = 'EmailField';

EmailField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default EmailField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (size(value) === 0 || !EMAIL_REGEX.test(value)) {
			errors.push('Insira um email válido');
		}
	}
	return errors;
};
