import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { size, slice, isUndefined } from 'lodash';

import EmptyList from 'components/EmptyList';
import ErrorMessages from 'components/ErrorMessages';

class EmbedList extends React.Component {
	static displayName = 'EmbedList';
	constructor(props) {
		super(props);

		this.state = {
			collapsed: isUndefined(this.props.collapsed) ? this.props.startCollapsed : this.props.collapsed
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!isUndefined(nextProps.collapsed) && prevState.collapsed !== nextProps.collapsed) {
			return {
				collapsed: nextProps.collapsed
			};
		}

		return null;
	}

	render() {
		const {
			label,
			novoItem,
			novoItemLabel = 'novo item',
			itemList,
			errorList,
			children,
			emptyMessage = 'Nenhum item cadastrado.',
			showAddButton = true,
			collapsible = false,
			maxResults,
			noLabel,
			toggleCollapseState,
			loading
		} = this.props;

		const toggleCollapse = () => this.setState({ collapsed: !this.state.collapsed });

		const listaItens = maxResults ? slice(itemList, 0, maxResults) : itemList;

		return (
			<div className="input-group-lic">
				{!noLabel && (
					<div className="label label-button-group">
						<h2
							onClick={collapsible ? toggleCollapseState || toggleCollapse : undefined}
							style={{ cursor: `${collapsible ? 'pointer' : 'auto'}` }}
						>
							{collapsible ? (
								<button
									type="button"
									className="btn-left"
									aria-label={this.state.collapsed ? 'expandir item' : 'encolher item'}
								>
									<i className={this.state.collapsed ? 'fa fa-angle-right' : 'fa fa-angle-down'} aria-hidden="true" />
								</button>
							) : null}
							{label}
							{this.state.collapsed && (
								<span className="item-count" onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
									{size(listaItens) > 0
										? size(listaItens) === 1
											? 'contém 1 item.'
											: `contém ${size(listaItens)} itens.`
										: 'nenhum item na lista.'}
								</span>
							)}
						</h2>
						{showAddButton && (
							<button type="button" className="btn" onClick={novoItem}>
								<i className="fa fa-plus" aria-hidden="true" /> {novoItemLabel}
							</button>
						)}
					</div>
				)}
				{!this.state.collapsed && (
					<ul className="embed-list">
						{loading ? (
							<EmptyList msg={'Obtendo dados'} />
						) : size(listaItens) > 0 ? (
							<Fragment>
								{listaItens.map((item, index) => (
									<li className="embed-list-item" key={index} style={this.props.itemStyle || {}}>
										{children ? children(item, index) : null}
									</li>
								))}
								{size(itemList) > size(listaItens) ? (
									<li className="embed-list-item" key={1000000}>
										<span className="gray-text">
											...{' '}
											{`mais ${size(itemList) - size(listaItens)} ${
												size(itemList) - size(listaItens) === 1 ? ' item ' : 'itens'
											}`}
										</span>
									</li>
								) : null}
							</Fragment>
						) : (
							<EmptyList msg={emptyMessage} />
						)}
					</ul>
				)}
				<ErrorMessages errorList={errorList} />
			</div>
		);
	}
}

EmbedList.propTypes = {
	label: PropTypes.any,
	novoItem: PropTypes.func,
	novoItemLabel: PropTypes.string,
	itemList: PropTypes.arrayOf(PropTypes.object),
	itemStyle: PropTypes.object,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	emptyMessage: PropTypes.string,
	showAddButton: PropTypes.bool,
	addButtonPermission: PropTypes.any,
	maxResults: PropTypes.number,
	noLabel: PropTypes.bool,
	loading: PropTypes.bool,
	collapsible: PropTypes.bool, //controla se a lista pode ser compactada
	startCollapsed: PropTypes.bool, //controla se a lista começa compactada
	collapsed: PropTypes.bool, //controla o estado expandida/compactada pela prop
	toggleCollapseState: PropTypes.func //usada em conjunto com a anterior para controlar o estado de fora do componente
};

export default EmbedList;
