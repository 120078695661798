import React from 'react';

import PropTypes from 'prop-types';

import IntlNumberInput from 'components/react-intl-number-input';
import TooltipIcon from 'components/TooltipIcon';

const SubunidadeAreaEdit = ({ subunidade, onChange, onRemove, onAddItem }) =>
	subunidade ? (
		<>
			<div className="subunidade-area">
				<div className="campos">
					<div className="campo">
						<label>
							<TooltipIcon label="Código da subunidade">Código da subunidade</TooltipIcon>
							<IntlNumberInput
								type="text"
								className="form-control"
								locale="pt-BR"
								precision={0}
								value={subunidade.codigo}
								onChange={(e, value) => onChange({ ...subunidade, codigo: value })}
							/>
						</label>
					</div>
					<div className="campo">
						<label>
							<TooltipIcon label="Área da Subunidade">
								Quando o imóvel for atingido por mais de uma subunidade (ex. subunidade 2,3, etc.) deverá ser informada
								a área do terreno atingida por cada uma destas subunidades nos campos correspondentes, para posterior
								aplicação dos regimes individualmente.
							</TooltipIcon>
							<IntlNumberInput
								type="text"
								className="form-control"
								locale="pt-BR"
								suffix=" m²"
								precision={2}
								value={subunidade.area}
								onChange={(e, value) => onChange({ ...subunidade, area: value })}
							/>
						</label>
					</div>
				</div>

				<div className="side-toolbar">
					{onRemove && <i className="fa fa-trash" onClick={onRemove} />}
					{onAddItem && <i className="fa fa-plus" onClick={onAddItem} />}
				</div>
			</div>
		</>
	) : null;
SubunidadeAreaEdit.displayName = 'SubunidadeAreaEdit';

SubunidadeAreaEdit.propTypes = {
	subunidade: PropTypes.object,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
	onAddItem: PropTypes.func
};

export default SubunidadeAreaEdit;
