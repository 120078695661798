import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';

import { history } from 'index';

const createRootReducer = injectedReducers =>
	combineReducers({
		router: connectRouter(history),
		...injectedReducers
	});
export default createRootReducer;
