export const TIPOS_LICENCA_EXPRESSA = [
	{
		codigoTipo: 1254,
		descricao:
			'Aumento de até 50m2 em edificações regulares ou existentes quando se tratar de habitação unifamiliar ou unidade auônomas residenciais em condomínio'
	},
	{ codigoTipo: 1255, descricao: 'Demolição Parcial' },
	{ codigoTipo: 1256, descricao: 'Reciclagem de uso parcial' },
	{
		codigoTipo: 1264,
		descricao:
			'Reciclagem de uso total em edificações com área adensável maior que 200,00m² sem aumento de área, sem compra de solo criado e/ou TCP, para atividades que não dependem de EVU'
	},
	{ codigoTipo: 1257, descricao: 'Quiosque de vendas que ocupem a área atingida por traçado PDDUA' },
	{
		codigoTipo: 1258,
		descricao:
			'Muro com altura superior ao permitido em legislação em edificações existentes ou regulares ou terrenos não edificados'
	},
	{ codigoTipo: 1259, descricao: 'Desmembramento ou unificação de economias em edificações existentes ou regulares' },
	{ codigoTipo: 1260, descricao: 'Eventos temporários' },
	{ codigoTipo: 1262, descricao: 'Cercas energizadas' },
	{ codigoTipo: 1263, descricao: 'Estacionamento a ceu aberto para suprir vagas conforme artigo 125 do PDDUA' }
];

export const RESULTADO_ENUM = {
	DEFERIDO: 1,
	INDEFERIDO: 2,
	properties: {
		1: { key: 1, value: 'Deferido' },
		2: { key: 2, value: 'Indeferido' }
	}
};

export const TIPO_EVU_ENUM = {
	CONSULTA: 1,
	ESTUDO: 2,
	properties: {
		1: { key: 1, value: 'Consulta' },
		2: { key: 2, value: 'Estudo' }
	}
};

export const TIPO_PARECER_ENUM = {
	PARECER: 1,
	RESOLUCAO: 2,
	properties: {
		1: { key: 1, value: 'Parecer' },
		2: { key: 2, value: 'Resolução' }
	}
};

export const TIPO_ESTACIONAMENTO_ENUM = {
	PRIVATIVO: 1,
	CONDOMINIAL: 2,
	PNE: 3,
	properties: {
		1: { key: 'privativo', value: 'Privativo' },
		2: { key: 'condominial', value: 'Condominial' },
		3: { key: 'pne', value: 'Portadores de Necessidades Especiais' }
	}
};

export const SUB_TIPO_ESTACIONAMENTO_ENUM = {
	COBERTO: 1,
	DESCOBERTO: 2,
	PARCIALMENTE_COBERTO: 3,
	properties: {
		1: { key: 'coberto', value: 'Coberto' },
		2: { key: 'descoberto', value: 'Descoberto' },
		3: { key: 'parcialmenteCoberto', value: 'Parcialmente Coberto' }
	}
};

export const TIPO_DOCUMENTO_ENUM = {
	CPF: 3,
	CNPJ: 1,
	properties: {
		3: { key: 3, value: 'CPF' },
		1: { key: 1, value: 'CNPJ' }
	}
};

export const TIPO_REGISTRO_ENUM = {
	CREA: 1,
	CAU: 2,
	properties: {
		1: { key: 1, value: 'CREA' },
		2: { key: 2, value: 'CAU' }
	}
};

export const SITUACAO_ETAPA_ENUM = {
	EM_ANDAMENTO: 1,
	ARQUIVADA: 2,
	CONCLUIDA: 3,
	DEFERIDA: 4,
	INDEFERIDA: 5,
	properties: {
		1: { key: 1, value: 'Em Andamento' },
		2: { key: 2, value: 'Arquivada' },
		3: { key: 3, value: 'Concluída' },
		4: { key: 4, value: 'Deferida' },
		5: { key: 5, value: 'Indeferida' }
		/*comRegistro: {
			1: { key: 1, value: 'Deferido' },
			2: { key: 2, value: 'Indeferido' }
		},
		semRegistro: {
			3: { key: 3, value: 'Arquivado' },
			4: { key: 4, value: 'Concluído' }
		}*/
	}
};

export const LADO_TRECHO_ENUM = {
	PAR: 1,
	IMPAR: 2,
	AMBOS: 3,
	properties: {
		1: { key: 'P', value: 'Par' },
		2: { key: 'I', value: 'Impar' },
		3: { key: 'A', value: 'Ambos' }
	}
};

export const ACAO_BLOQUEIO_ENUM = {
	BLOQUEIO: 1,
	DESBLOQUEIO: 2,
	properties: {
		1: { key: 1, value: 'Bloqueio' },
		2: { key: 2, value: 'Desbloqueio' }
	}
};

export const TIPO_VISTORIA_ENUM = {
	TOTAL: 1,
	PARCIAL: 2,
	PARCIAL_FINAL: 3,
	properties: {
		1: { key: '1', value: 'Total' },
		2: { key: '2', value: 'Parcial' },
		3: { key: '3', value: 'Parcial Final' }
	}
};

export const TIPO_ECONOMIA_ENUM = {
	ECONOMIA: 1,
	ESTACIONAMENTOS_PRIVAT_COBERTOS: 2,
	ESTACIONAMENTOS_PRIVAT_DESCOBERTOS: 3,
	ESTACIONAMENTOS_PRIVAT_PARC_COBERTOS: 4,
	ESTACIONAMENTOS_CONDOM_COBERTOS: 5,
	ESTACIONAMENTOS_CONDOM_DESCOBERTOS: 6,
	ESTACIONAMENTOS_CONDOM_PARC_COBERTOS: 7,
	ESTACIONAMENTOS_PNE: 8,
	properties: {
		1: { key: '1', value: 'Economia' },
		2: { key: '2', value: 'Estacionamentos Privativos Cobertos' },
		3: { key: '3', value: 'Estacionamentos Privativos Descobertos' },
		4: { key: '4', value: 'Estacionamentos Privativos Parcialmente Cobertos' },
		5: { key: '5', value: 'Estacionamentos Condominiais Cobertos' },
		6: { key: '6', value: 'Estacionamentos Condominiais Descobertos' },
		7: {
			key: '7',
			value: 'Estacionamentos Condominiais Parcialmente Cobertos'
		},
		8: { key: '8', value: 'Estacionamentos Portadores Deficiência' }
	}
};

export const PROCESSOS_FORMAT = {
	EXPEDIENTE: 'expediente',
	EXPEDIENTE_AREA: 'expediente_area',
	SEI: 'sei'
};

export const ANALISTAS = [
	'nadia@procempa.com.br',
	'ricardo.bard@procempa.com.br',
	'pauloh.klein@procempa.com.br',
	'marcio.arrosi@procempa.com.br',
	'alexgv@procempa.com.br',
	'marcelo@procempa.com.br',
	'bridi@procempa.com.br',
	'alex.araujo@procempa.com.br',
	'alexgv99@gmail.com',
	'marceloxogum@gmail.com'
];
