import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { isNil, size } from 'lodash';

class IntlNumberInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			maskedValue: '0',
			value: 0
		};
	}

	componentDidMount() {
		this.setMaskedValue(this.props.value);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setMaskedValue(this.props.value);
		}
	}

	setMaskedValue(value = 0) {
		const formatted = this.formatNumber(value);
		if (this.state.maskedValue !== formatted) {
			this.setState({ maskedValue: formatted });
		}
	}

	getMaskedValue() {
		return this.state.maskedValue;
	}

	getValue() {
		return this.state.value;
	}

	getPrecisionValue(precision) {
		return Math.pow(10, precision);
	}

	getNumberValue(strValue) {
		const numberValue = Number(strValue.replace(/[^0-9]/g, ''));

		return numberValue / this.getPrecisionValue(this.props.precision);
	}

	formatNumber(value = 0) {
		let numberValue = value;
		if (typeof numberValue === 'string') {
			numberValue = this.getNumberValue(numberValue);
		}

		const formattedNumber = new Intl.NumberFormat(this.props.locale, {
			style: 'decimal',
			minimumFractionDigits: this.props.precision,
			maximumFractionDigits: this.props.precision
		}).format(numberValue);

		return `${this.props.prefix}${formattedNumber}${this.props.suffix}`;
	}

	updateValues(event) {
		const value = this.getNumberValue(event.target.value);
		const maskedValue = this.formatNumber(value);

		return [value, maskedValue];
	}

	handleChange(event) {
		event.preventDefault();

		const [value, maskedValue] = this.updateValues(event);

		event.persist();

		if (this.props.onChange && maskedValue) {
			this.setState({ value, maskedValue }, () => {
				const valueOut = this.props.keepZero ? value : value === 0 ? null : value;
				const maskedValueOut = this.props.keepZero
					? maskedValue
					: isNil(maskedValue) || maskedValue === '0'
					? ''
					: maskedValue;
				this.props.onChange(event, valueOut, maskedValueOut);
			});
		}
	}

	handleKeyDown = event => {
		event.persist();
		if (event.key === 'Backspace') {
			let stringValue = this.state.value ? `${this.state.value}` : null;
			if (stringValue) {
				stringValue = stringValue.substring(0, stringValue.length - 1);
				if (size(stringValue) === 0) {
					stringValue = null;
				}
			}
			const calcValue = stringValue ? Number(stringValue) : 0;
			const [value, maskedValue] = this.updateValues({ target: { value: this.formatNumber(calcValue) } });
			if (this.props.onChange && maskedValue) {
				this.setState({ value, maskedValue }, () => {
					this.props.onChange(event, calcValue, maskedValue);
				});
			}
		}
	};

	customProps() {
		const customProps = { ...this.props };

		delete customProps.locale;
		delete customProps.prefix;
		delete customProps.suffix;
		delete customProps.precision;
		delete customProps.autoFocus;
		delete customProps.value;
		delete customProps.onChange;
		delete customProps.style;
		delete customProps.keepZero;

		return customProps;
	}

	render() {
		return (
			<input
				style={Object.assign({ textAlign: 'right' }, this.props.style || {})}
				value={
					this.props.keepZero
						? this.state.maskedValue
						: isNil(this.state.maskedValue) || this.state.maskedValue === '0'
						? ''
						: this.state.maskedValue
				}
				disabled={this.props.disabled}
				onChange={e => this.handleChange(e)}
				onKeyDown={this.handleKeyDown}
				onFocus={e => e.target.select()}
				{...this.customProps()}
			/>
		);
	}
}
IntlNumberInput.displayName = 'IntlNumberInput';
IntlNumberInput.propTypes = {
	locale: PropTypes.string.isRequired,
	prefix: PropTypes.string.isRequired,
	suffix: PropTypes.string.isRequired,
	precision: PropTypes.number.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	style: PropTypes.object,
	keepZero: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func
};

IntlNumberInput.defaultProps = {
	locale: 'en-US',
	prefix: '',
	suffix: '',
	precision: 2
};

export default IntlNumberInput;
