import React, { useCallback, useEffect } from 'react';
import './style/Comentario.scss';

import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import { size } from 'lodash';

import useMessages from 'custom-hooks/useMessages';

import { calculaNumeroLinhas } from 'utils/tools';

const Comentario = ({
	campoComentario,
	valueComentario,
	valueComentarioAnterior = null,
	onChangeComentarioHandler,
	setTemComentario = null,
	setTemComentarioAux = null,
	temComentario,
	readOnly = false
}) => {
	const { createMessage } = useMessages();

	const defineComentarioFunc = useCallback(
		valueAux => {
			if (setTemComentario) {
				setTemComentario(valueAux);
			} else {
				setTemComentarioAux({ name: campoComentario, value: valueAux });
			}
		},
		[campoComentario, setTemComentario, setTemComentarioAux]
	);

	useEffect(() => {
		if (size(valueComentario) > 0) {
			defineComentarioFunc(true);
		}
	}, [campoComentario, defineComentarioFunc, setTemComentario, setTemComentarioAux, valueComentario]);

	const defineComentario = useCallback(
		campoComentario => {
			if (size(valueComentario) === 0) {
				defineComentarioFunc(!temComentario);
				onChangeComentarioHandler({ name: campoComentario, value: undefined });
			} else {
				createMessage('Apague o texto do comentário para poder removê-lo', 5);
			}
		},
		[createMessage, defineComentarioFunc, onChangeComentarioHandler, temComentario, valueComentario]
	);

	return readOnly ? (
		<>
			{temComentario && (
				<div className={'comentario-wrapper '}>
					<Tippy placement="top" content={'O revisor adicionou um comentário para a sua resposta'}>
						<i className="fa fa-comment icone-comentario"></i>
					</Tippy>
					<div className={'comentario-form-especifico is-comentario-readonly'}>{valueComentario}</div>
				</div>
			)}
		</>
	) : (
		<>
			{typeof onChangeComentarioHandler === 'function' && (
				<div className={'comentario-wrapper '}>
					<Tippy
						placement="top"
						content={
							temComentario
								? size(valueComentario) > 0
									? 'Apague o texto do comentário para poder removê-lo'
									: 'Remover comentário da resposta'
								: 'Adicionar comentário à resposta'
						}
					>
						<i className="fa fa-comment icone-comentario" onClick={() => defineComentario(campoComentario)}></i>
					</Tippy>
					{valueComentarioAnterior && (
						<div className={'comentario-form-especifico'}>
							<label>Último Comentário registrado:</label>
							<div className={'comentario-form-especifico is-comentario-readonly'}>{valueComentarioAnterior}</div>
						</div>
					)}
					{temComentario && (
						<div className={'comentario-form-especifico'}>
							<label>Adicionar Comentário à resposta:</label>
							<textarea
								className="form-control"
								rows={calculaNumeroLinhas(valueComentario, 3)}
								placeholder="Adicionar Comentário"
								value={valueComentario || ''}
								onChange={event => onChangeComentarioHandler({ name: campoComentario, value: event.target.value })}
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
};
Comentario.displayName = 'Comentario';

Comentario.propTypes = {
	campoComentario: PropTypes.string,
	valueComentario: PropTypes.string,
	valueComentarioAnterior: PropTypes.string,
	onChangeComentarioHandler: PropTypes.func,
	setTemComentario: PropTypes.func,
	temComentario: PropTypes.bool,
	setTemComentarioAux: PropTypes.func,
	readOnly: PropTypes.bool
};

export default Comentario;
