import 'unfetch/polyfill';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import { GRAPHQL_URL } from 'environments';

import { store } from 'index';

import { isDebug } from './tools';

const debug = isDebug;
// eslint-disable-next-line no-unused-vars
const DEFAULT_APOLLO_CLIENT_OPTIONS = {
	watchQuery: {
		// fetchPolicy: 'cache-and-network'
		errorPolicy: 'all'
	},
	query: {
		fetchPolicy: 'network-only',
		errorPolicy: 'all'
	},
	mutate: {
		errorPolicy: 'all'
	}
};

const customFetch = (uri, options) => {
	if (options.body) {
		const body = JSON.parse(options.body);
		if (body.variables) {
			body.variables = stripTypenames(body.variables, ['__typename', '__proto__']);
		}
		options.body = JSON.stringify(body);
		if (debug) {
			console.debug(
				`${body.operationName === 'mutation' ? 'MUTATION' : 'QUERY'} GRAPHQL:`,
				body.operationName,
				'\n--------------------------------\nVARIÁVEIS:',
				body.variables,
				'\n--------------------------------\nQUERY:',
				body.query,
				'\n--------------------------------'
			);
		}
	}
	return store.kc
		? new Promise((resolve, reject) => {
				store.kc
					.updateToken(5)
					.then(async () => {
						const optionsWithToken = {
							...options,
							method: 'post',
							headers: { ...options.headers, Authorization: `Bearer ${store.kc.token}` }
						};
						let result = null;
						try {
							result = await fetch(GRAPHQL_URL, optionsWithToken);
							resolve(result);
						} catch (error) {
							debug && console.debug('ERRO NA QUERY GRAPHQL: ', error);
							reject(error);
						}
					})
					.catch(function () {
						alert('Falha ao atualizar token... Você está logado?');
					});
		  })
		: Promise.reject('Sem token ainda');
};
/*
	Se for necessário, podemos colocar um parser para a identificação dos objetos em cache
	(pode-se ver isso com a extensão do Apollo no Chrome), como o exemplo abaixo:

	import md5 from 'md5';

	cache: new InMemoryCache({
		dataIdFromObject: object => {
			switch (object.__typename) {
				case 'ExpedientesProjetoEdificacaoBloco':
					return `${object.__typename}: ${md5(JSON.stringify(object))}`; // use `md5` as the primary key
				default:
					return `${object.__typename}: ${object.id || object._id}`; // fall back to `id` and `_id` for all other types
			}
		}
	})

*/
const client = new ApolloClient({
	link: new HttpLink({ fetch: customFetch }),
	cache: new InMemoryCache()
	// defaultOptions: DEFAULT_APOLLO_CLIENT_OPTIONS
});

const publicClient = new ApolloClient({
	link: new HttpLink({ uri: GRAPHQL_URL }),
	cache: new InMemoryCache()
	// defaultOptions: DEFAULT_APOLLO_CLIENT_OPTIONS
});

const PUBLIC_PATH = ['/consultapublica', '/faq', '/novidades', '/consulta-carta-habitacao'].reduce(
	(acc, url) => acc || window.location.pathname.indexOf(url) > -1,
	false
);

export default PUBLIC_PATH ? publicClient : client;

const stripTypenames = (obj, propsToDelete) => {
	for (const property in obj) {
		if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
			delete obj.property;
			const newData = stripTypenames(obj[property], propsToDelete);
			obj[property] = newData;
		} else {
			if (propsToDelete.indexOf(property) > -1) {
				delete obj[property];
			}
		}
	}
	return obj;
};
