import { size } from 'lodash';

import { isDebug } from 'utils/tools';

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(ADESAO_PRCHPA-VALIDACAO)', ++i, ...args);

const temAlgumDocumento = ({ documentos, field }) => {
	const docsMetadata = field?.documentos || [];
	const temAlgum = docsMetadata.reduce(
		(acc, d) =>
			acc || (documentos || []).find(documento => documento.idDocumento === d.idDocumento && !!documento.filename),
		false
	);
	return temAlgum;
};

const restricoesDesmarcadasComDocumentos = ({ metadata, data, documentos }) =>
	metadata.restricoesAdministrativas
		.filter(field => !data?.[field.id])
		.filter(field => temAlgumDocumento({ documentos, field }))
		.reduce((acc, p) => {
			acc[p.id] = ['Há documentos associados a esta pergunta'];
			return acc;
		}, {});

const temTodosDocumentos = ({ documentos, field }) => {
	const docsMetadata = field?.documentos || [];
	const temTodos = docsMetadata.reduce(
		(acc, d) =>
			acc && !!(documentos || []).find(documento => documento.idDocumento === d.idDocumento && !!documento.filename),
		true
	);
	return temTodos;
};

const restricoesMarcadasSemDocumentos = ({ metadata, data, documentos }) =>
	metadata.restricoesAdministrativas
		.filter(field => !!data?.[field.id])
		.filter(field => !temTodosDocumentos({ documentos, field }))
		.reduce((acc, field) => {
			field.documentos.forEach(d => {
				const doc = documentos.find(doc => doc.idDocumento === d.idDocumento && !doc.filename);
				if (doc) {
					acc[doc.id] = [`Documento obrigatório${isDebug ? ` (${doc.idDocumento})` : ''}`];
				} else {
					const doc = documentos.find(doc => doc.idDocumento === d.idDocumento && !!doc.filename);
					if (!doc) {
						acc[d.idDocumento] = [`Documento obrigatório${isDebug ? ` (${d.idDocumento})` : ''}`];
					}
				}
			});
			return acc;
		}, {});

const validacaoRestricoesAdministrativas = ({ metadata, data }) => {
	let qtd = 0;
	let comSubitemIncompleto = false;
	const errors = metadata.restricoesAdministrativas.reduce((acc, ra) => {
		if (!data[ra.id]) {
			return acc;
		}

		if (size(ra.subItens) === 0) {
			qtd++;
			return acc;
		}

		const innerQtd = ra.subItens.reduce((acc2, si) => acc2 + (data[si.id] ? 1 : 0), 0);

		if (innerQtd < 3) {
			acc[ra.id] = ['Você deve selecionar ao menos 3 subitens'];
			comSubitemIncompleto = true;
			return acc;
		}

		qtd++;
		return acc;
	}, {});

	if (qtd < 4) {
		errors.restricoesAdministrativas = ['Você deve selecionar ao menos 4 itens das Restrições Administrativas'];
		if (comSubitemIncompleto) {
			errors.restricoesAdministrativas.push(
				'Selecionando um item que possui subitens, deve selecionar ao menos 3 desses subitens.'
			);
		}
	}

	return errors;
};

export function validacao({ metadata, data, documentos }) {
	debugLog('metadata, dados: ', metadata, data);

	data = data || {};

	let errors = {
		...validacaoRestricoesAdministrativas({ metadata, data }),
		...restricoesDesmarcadasComDocumentos({ metadata, data, documentos }),
		...restricoesMarcadasSemDocumentos({ metadata, data, documentos })
	};
	let comSubitemIncompleto = false;

	const qtd = metadata.restricoesAdministrativas.reduce((acc, ra) => {
		if (!data[ra.id]) {
			return acc;
		}

		if (size(ra.subItens) === 0) {
			return acc + 1;
		}

		const innerQtd = ra.subItens.reduce((acc2, si) => acc2 + (data[si.id] ? 1 : 0), 0);

		if (innerQtd < 3) {
			comSubitemIncompleto = true;
			return acc;
		}
		return acc + 1;
	}, 0);

	if (qtd < 4) {
		const errorsAux = ['Você deve selecionar ao menos 4 itens das Restrições Administrativas.'];
		if (comSubitemIncompleto) {
			errorsAux.push('Selecionando um item que possui subitens, deve selecionar ao menos 3 desses subitens.');
		}
		errors.restricoesAdministrativas = errorsAux;
	}

	return errors;
}
