import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import { isDam, format } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const NumeroDamField = ({
	label,
	labelEnum,
	required,
	placeHolder,
	name,
	value,
	readOnly,
	disabled,
	onChangeHandler
}) => {
	const [valid, setValid] = useState(true);

	const onChangeNumeroDam = e => {
		let value = e.target.value;
		value = value.replace(/[^0-9]/g, '');
		value = size(value) === 0 ? undefined : value;
		onChangeHandler({ name, value });
		const errors = validate(value, required, label);
		onChangeHandler({ name: 'errors', value: { [name]: errors } });
		setValid(size(errors) === 0);
		return true;
	};

	return (
		<>
			<label className="control-label">
				{labelEnum || label}
				{required && <span className="required">*</span>}
			</label>
			<input
				className={
					!valid ? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}` : `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value && valid ? format(value, '0000.00.000000.000-0') : value || ''}
				onChange={onChangeNumeroDam}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</>
	);
};

NumeroDamField.displayName = 'NumeroDam';

NumeroDamField.propTypes = {
	label: PropTypes.string,
	labelEnum: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func
};

export default NumeroDamField;

export const validate = (numeroDam, required, label = 'Número DAM') => {
	const errors = [];

	if (numeroDam) {
		if (!isDam(numeroDam)) {
			errors.push(`Informe o ${label} correto para este requerimento`);
		}
	} else {
		if (required) {
			errors.push(`${label} deve ser informado`);
		}
	}

	return errors;
};
