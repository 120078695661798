import React from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import { formatCodigoEU } from 'utils/tools';
import { montaURL } from 'utils/tools';

export function InfosExpedienteAP({ formulario }) {
	const expediente = get(formulario, 'formData.data.expediente');
	const enderecos = get(formulario, 'formData.data.enderecoCdlList', []);
	const isQuartoDistrito = enderecos.some(cdl => cdl.regiao === 'quarto_distrito');

	// const tiposForm = (tipoForm.split(','): []).map(tf => tf.trim());
	// const showAreaPrivativa = !tiposForm.reduce((acc, tf) => acc || ['le2', 'le3'].includes(tf), false);
	const showAreaPrivativa = true;

	return (
		<>
			<fieldset>
				{/* <legend>Informações de Expediente Único / Endereços</legend> */}
				<div className="row id-ap id-ap-dinamico">
					<div className="col-auto exp-un">
						<label>Expediente Único </label>
						{size(get(expediente, 'numero')) > 0 ? (
							<a
								href={`${montaURL('expedientes')}/consultapublica/${formulario.formData.data.expediente._id}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>{formatCodigoEU(get(expediente, 'numero'), true)}</span>
								<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
							</a>
						) : (
							<span className="nao-informado">Não informado</span>
						)}
					</div>

					{showAreaPrivativa && (
						<div className="col area-priv">
							<label>
								Área Privativa <small>(unidade privativa/autônoma)</small>
							</label>
							{size(get(expediente, 'codigo')) > 0 ? (
								<span>{get(expediente, 'codigo', '').substr(12, 5)}</span>
							) : (
								<span className="nao-informado">Não informado</span>
							)}
						</div>
					)}
				</div>
				<div className="id-ap">
					<label>Endereço(s) da solicitação </label>
					{size(enderecos) > 0 && (
						<div className="endereco">
							<ul>
								{enderecos.map((item, index) => (
									<li key={index}>{item.enderecoFormatadoCurto}</li>
								))}
							</ul>
						</div>
					)}
					{isQuartoDistrito && <span>{'O endereço informado está localizado em área do 4º Distrito'}</span>}
				</div>
			</fieldset>
		</>
	);
}

InfosExpedienteAP.displayName = 'InfosExpedienteAP';
InfosExpedienteAP.propTypes = {
	formulario: PropTypes.object,
	onChangeHandler: PropTypes.func,
	data: PropTypes.object,
	tipoForm: PropTypes.string
};
