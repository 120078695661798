import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { get, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import { permiteInput } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const CurrencyFieldAverbacao = ({
	label,
	required,
	placeHolder,
	name,
	value,
	containerClass,
	readOnly,
	disabled,
	errorList,
	maxSize,
	decimals,
	checkForZero,
	onChangeHandler,
	hasFocus
}) => {
	const inputRef = useRef();
	const onChangeNumber = e => {
		let { value: input } = e.target;

		// Removendo formatação anterior (pontos e vírgulas)
		input = input.replace(/[.,]/g, '');

		// Inserindo a vírgula para o decimal, se necessário
		if (decimals) {
			input = input.replace(/(\d+)(\d{2})$/, '$1,$2');
		}

		// Adicionando pontos para os milhares
		input = input.replace(/\B(?=(\d{5})+(?!\d))/g, '.');

		// Atualizando o estado com o valor formatado
		if (get(value, 'checkedForZero') || permiteInput(input, 'currency', decimals || 2, maxSize || 7)) {
			const erros = validate({ value: input, checkedForZero: get(value, 'checkedForZero') }, required, label);
			onChangeHandler([
				{ name, value: { value: input, checkedForZero: get(value, 'checkedForZero') } },
				{ name: 'errors', value: { [name]: erros } }
			]);
		}
	};

	const onChangeCheck = () => {
		const checked = !get(value, 'checkedForZero');
		const valor = checked ? '0' : '';
		const erros = checked ? validate({ value: '0', checkedForZero: checked }, required, label) : [];
		onChangeHandler([
			{ name, value: { value: valor, checkedForZero: checked } },
			{ name: 'errors', value: { [name]: erros } }
		]);
		if (!checked) {
			setTimeout(() => {
				if (inputRef && inputRef.current) {
					inputRef.current.focus();
				}
			}, 100);
		}
	};

	const valorFormatado = input => {
		if (input.length === 0) {
			return '';
		}

		// Separando a parte inteira e a parte decimal
		let [parteInteira, parteDecimal] = input.split(',');

		// Formatando a parte inteira para ter o separador de milhar
		parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

		// Reconstruindo o valor formatado
		return parteDecimal ? `${parteInteira},${parteDecimal}` : parteInteira;
	};

	return (
		<div className={`${containerClass}`} id="currencyContainerClass">
			<div className={`col-md-${checkForZero ? '6' : '12'}`} id="currencyCol1">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
				<input
					ref={inputRef}
					className={
						size(errorList) > 0
							? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
							: `${CLASS_NAME_FORM_CONTROL}`
					}
					id={name}
					type="text"
					placeholder={placeHolder}
					name={name}
					value={hasFocus ? get(value, 'value') || '' : valorFormatado(get(value, 'value') || '')}
					onChange={onChangeNumber}
					readOnly={readOnly}
					disabled={disabled || get(value, 'checkedForZero')}
				/>
			</div>
			{checkForZero && !disabled && !readOnly && (
				<div className="form-check col-md-6" style={{ whiteSpace: 'nowrap', paddingTop: '40px' }} id="numberCol2">
					<label>
						<input
							type="checkbox"
							name={name}
							onClick={onChangeCheck}
							readOnly={readOnly}
							disabled={disabled}
							onChange={() => false}
							checked={!!get(value, 'checkedForZero')}
						/>{' '}
						<span>Não há {(label || 'valor a ser informado').toLowerCase()}</span>
					</label>
				</div>
			)}
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
CurrencyFieldAverbacao.displayName = 'CurrencyFieldAverbacao';

CurrencyFieldAverbacao.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.object,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	maxSize: PropTypes.string,
	decimals: PropTypes.number,
	maxValue: PropTypes.string,
	checkForZero: PropTypes.bool,
	hasFocus: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	onSetFocus: PropTypes.func
};

export default CurrencyFieldAverbacao;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	const { value: valor, checkedForZero = false } = value || {};
	if (required) {
		if (!checkedForZero) {
			const currencyValue = parseFloat((valor || '').replace(',', '.'));
			if (!currencyValue || currencyValue === 0) {
				errors.push(`${label} deve ser informado`);
			}
		}
	}
	return errors;
};
