import React from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { push } from 'connected-react-router';

/**
 * Por padrao mostra todas as abas porem se a aba possuir a propriedade `showOnly`
 * mostra apenas se o array conter o `idTipoFormulario` desejadao
 * @param {*} props
 */
export function ListaAbas({ formulario, abas, aba }) {
	const dispatch = useDispatch();

	const dispatchGoAba = (idFormulario, id, aba) => dispatch(push(`/${idFormulario}/${id}?aba=${aba}`));

	let abaIndex = 0;
	return (
		<ul key={formulario} className="nav nav-pills" style={{ display: 'flex' }}>
			{Object.keys(abas).map(abaKey => {
				const { showOnlyIf, title, disabled = () => false } = abas[abaKey];
				abaIndex++;
				const abaEl = (
					<li key={abaKey} className={aba === abaKey ? 'active' : ''}>
						<button
							type="button"
							disabled={disabled()}
							onClick={() => dispatchGoAba(formulario?.id, formulario?.formData?.id, abaKey)}
						>
							{`${abaIndex}. ${title}`}
						</button>
					</li>
				);
				if (showOnlyIf === undefined) {
					return abaEl;
				} else if (showOnlyIf && Array.isArray(showOnlyIf) && showOnlyIf.includes(formulario.idTipoFormulario)) {
					return abaEl;
				} else if (showOnlyIf === true) {
					return abaEl;
				} else {
					abaIndex--;
					return undefined;
				}
			})}
		</ul>
	);
}

ListaAbas.displayName = 'ListaAbas';
ListaAbas.propTypes = {
	formulario: PropTypes.object,
	abas: PropTypes.object,
	aba: PropTypes.string
};
