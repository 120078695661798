import React, { Fragment, useMemo, useState, useCallback } from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useLazyQuery } from 'react-apollo';

import gql from 'graphql-tag';
import { flatMap, get, isNil, omit, pick, size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';

import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import useErrors from 'custom-hooks/useErrors';

import { accessApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

import Comentario from './Comentario';

function RestricoesAdministrativasForm({
	idMetadata,
	metadataParaEditor,
	data: dataProps,
	changeHandler,
	readOnly,
	filter,
	changeComentarioHandler = null,
	usuarioInterno = false
}) {
	const [perguntasMetadata, setPerguntasMetadata] = useState(metadataParaEditor || []);
	const [data, setData] = useState(dataProps || {});
	const [errors] = useErrors();
	const [localErrors, setLocalErrors] = useState();
	const [inputId, setInputId] = useState('');
	const [loadingMetadata, setLoadingMetadata] = useState(false);

	const perguntasFlatKeys = useMemo(() => {
		const ps = flatPerguntas(perguntasMetadata);
		return flatMap(Object.keys(ps), k => [k, `${k}AlteradaRevisor`, `${k}AlteradaRequerente`, `${k}Comentario`]);
	}, [perguntasMetadata]);

	const [fetchFormData, { data: dataFormData, loading: loadingFormData, errors: errorsFormData }] = useLazyQuery(
		gql`
			query LicenciamentoFormDataById($id: String!) {
				item: LicenciamentoFormDataById(id: $id) {
					id
					data
					documentos
					documentosDados
				}
			}
		`
	);

	useEffect(() => {
		const cleanData = pick(dataProps, perguntasFlatKeys);
		setData(cleanData || {});
	}, [dataProps, perguntasFlatKeys]);

	useEffect(() => {
		async function fetchMetadata(idMetadata) {
			try {
				setLoadingMetadata(true);
				const url = `collections/metadata/${idMetadata}`;
				const retorno = (await accessApi(url))?.data;
				const meta = retorno || {};
				setPerguntasMetadata(meta?.perguntas || []);
				// console.log('flat', flatPerguntas(meta?.perguntas))
				setLocalErrors(old => omit(old, ['loadingMetadata']));
			} catch (e) {
				setPerguntasMetadata([]);
				setLocalErrors(old => ({
					...old,
					loadingMetadata: [`Erro carregando metadados de id "${idMetadata}"`]
				}));
				console.error(`Erro no acesso aos metadados de id "${idMetadata}"`, e);
			} finally {
				setLoadingMetadata(false);
			}
		}

		if (size(perguntasMetadata) === 0) {
			if (idMetadata) {
				fetchMetadata(idMetadata);
			} else {
				setLocalErrors(old => ({
					...old,
					loadingMetadata: ['Metadata não fornecido (props.metadata) nem identificado para carga (props.idMetadata).']
				}));
			}
		}
	}, [idMetadata, perguntasMetadata, setLocalErrors]);

	const buscaFormData = useCallback(
		id => {
			if (size(id) > 0) {
				fetchFormData({ variables: { id: id }, errorPolicy: 'all' });
			}
		},
		[fetchFormData]
	);

	useEffect(() => {
		if (dataFormData) {
			if (dataFormData.item) {
				const documento = (dataFormData.item.documentos || [])
					.filter(d => d.idDocumento === 'form-licenciamento-expresso')
					.reduce((acc, d) => (acc?.versao > d.versao ? acc : d), null);
				const dados = dataFormData.item.documentosDados.find(d => d.id === documento.id);
				setData(dados?.dados || {});
				setLocalErrors(old => omit(old, ['loadingFormData']));
			} else {
				setData({});
				setLocalErrors(old => ({
					...old,
					loadingFormData: ['Erro carregando dados de exemplo - id não encontrado']
				}));
			}
		}
	}, [dataFormData, setLocalErrors]);

	useEffect(() => {
		if (errorsFormData) {
			setData({});
			setLocalErrors(old => ({
				...old,
				loadingFormData: ['Erro carregando dados de exemplo - precisa usar o formDataId de um Licenciamento Expresso']
			}));
		}
	}, [errorsFormData, setLocalErrors]);

	const onChangeHandler = ({ name, value }) => {
		let newData = { ...data, [name]: value };
		newData = limpaSubs(newData, name, value);
		setData(newData);
		// setErrors(old => omit(old, [name]));
		changeHandler(newData);
	};

	const limpaSubs = (data, name, value) => {
		const limpaPerguntaRecursivamente = pergunta => {
			if (pergunta.campo !== name) {
				// não pode ser delete por que em alguns formulários em que o RestriçõesAdmistrativas
				// é um componente de outro formulário, o componente pai junta o data do filho com o dele
				// através de um assign, e isso acaba trazendo o valor anterior de volta
				data[pergunta.campo] = undefined;
			}
			pergunta.respostas.forEach(r => {
				if (size(r.perguntas) > 0) {
					if (pergunta.campo !== name || r.codigo !== value) {
						r.perguntas.forEach(p => limpaPerguntaRecursivamente(p));
					}
				}
			});
		};

		let achou = false;

		const encontraPerguntaParaLimparRecursivamente = perguntas => {
			perguntas.forEach(p => {
				if (p.campo === name) {
					limpaPerguntaRecursivamente(p);
					achou = true;
				} else if (!achou) {
					p.respostas
						.filter(r => size(r.perguntas) > 0)
						.forEach(r => encontraPerguntaParaLimparRecursivamente(r.perguntas));
				}
			});
		};

		encontraPerguntaParaLimparRecursivamente(perguntasMetadata);
		return data;
	};

	const sortPerguntaPorOrdem = (p1, p2) => (p1.ordem > p2.ordem ? 1 : -1);

	return (
		<>
			<fieldset>
				<legend>1. Restrições administrativas</legend>
				{isDebug && (
					<>
						<div className="d-flex gap-10 space-between flex-end mb-5">
							<input
								type="text"
								value={inputId || ''}
								className="form-control flex-1"
								placeholder="id de um processo para carregar"
								onChange={e => setInputId(e.target.value)}
							/>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									buscaFormData(inputId);
								}}
							>
								Carregar
							</button>
						</div>
					</>
				)}
				{loadingMetadata && loadingFormData ? (
					<Loader msg="Carregando metadados e dados de teste" />
				) : loadingFormData ? (
					<Loader msg="Carregando dados de teste" />
				) : loadingMetadata ? (
					<Loader msg="Carregando metadados" />
				) : null}
				{size(localErrors?.loadingMetadata) > 0 && <ErrorMessages errorList={localErrors.loadingMetadata} />}
				{size(localErrors?.loadingFormData) > 0 && <ErrorMessages errorList={localErrors.loadingFormData} />}
				{data && perguntasMetadata && (
					<div className="restricoes-administrativas-le">
						{(perguntasMetadata || []).sort(sortPerguntaPorOrdem).map(p => (
							<Fragment key={p.id}>
								<Pergunta
									pergunta={p}
									data={data}
									errors={errors}
									onChangeHandler={onChangeHandler}
									readOnly={readOnly}
									filter={filter}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? onChangeHandler : null}
									usuarioInterno={usuarioInterno}
								/>
							</Fragment>
						))}
					</div>
				)}
			</fieldset>
			<ShowDebug data={data} />
		</>
	);
}
RestricoesAdministrativasForm.displayName = 'RestricoesAdministrativasForm';
RestricoesAdministrativasForm.propTypes = {
	idMetadata: PropTypes.string,
	metadataParaEditor: PropTypes.arrayOf(PropTypes.object),
	idFormData: PropTypes.string,
	data: PropTypes.object,
	changeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool
};

export default RestricoesAdministrativasForm;

function Pergunta({
	pergunta: p,
	data,
	errors: errorsProps = [],
	onChangeHandler,
	margin: marginProps = -15,
	readOnly,
	filter: filterProps,
	onChangeComentarioHandler = null,
	usuarioInterno
}) {
	const filter = useMemo(
		() => (filterProps ? (Array.isArray(filterProps) ? filterProps : [filterProps]) : []),
		[filterProps]
	);
	const sortPerguntaPorOrdem = (p1, p2) => (p1.ordem > p2.ordem ? 1 : -1);
	const margin = useMemo(() => marginProps + 15, [marginProps]);
	const options = p.respostas.map(r => ({
		codigo: r.codigo,
		descricao: r.resposta
		// defaultValue: isDebug ? r.codigo === 'nao' : false: causando bugs pois o radio emite diversos eventos de change e confunde o componente pai (formulario uap)
	}));
	const value = data[p.campo];
	const campoComentario = `${p.campo}Comentario`;
	const valueComentario = data[campoComentario];
	const alteradaRevisor = !!data[`${p.campo}AlteradaRevisor`];
	const alteradaRequerente = !!data[`${p.campo}AlteradaRequerente`];
	const respostas = (p?.respostas || []).reduce((acc, r) => ({ ...acc, [r.codigo]: r }), {});
	const errors = Array.isArray(errorsProps)
		? errorsProps.filter(e => e.campo === p.campo).map(e => e.message)
		: undefined;
	const [temComentario, setTemComentario] = useState(false);

	const filtrada = pergunta => {
		if (size(filter) === 0 || size(pergunta.filter) === 0) {
			return true;
		}
		return filter.reduce((acc, f) => acc || (pergunta.filter || []).includes(f), false);
	};

	return readOnly ? (
		<>
			<div
				className={`row container-resposta-ap ${
					temComentario ? 'resposta-com-comentario pin-comentario' : usuarioInterno ? 'resposta-com-comentario' : ''
				}`}
				style={{ position: 'relative' }}
			>
				<span>{p.pergunta}</span>
				<span className="resposta-ap">{value === 'nao' ? 'não' : value}</span>
				<Comentario
					campoComentario={campoComentario}
					valueComentario={valueComentario}
					onChangeComentarioHandler={onChangeComentarioHandler}
					setTemComentario={setTemComentario}
					temComentario={temComentario}
					readOnly={!usuarioInterno}
				/>
			</div>
			{size(get(respostas, `${value}.perguntas`)) > 0 &&
				get(respostas, `${value}.perguntas`)
					.sort(sortPerguntaPorOrdem)
					.map(pr => (
						<Pergunta
							key={pr.id}
							pergunta={pr}
							data={data}
							errors={errorsProps}
							onChangeHandler={onChangeHandler}
							readOnly={readOnly}
							filter={filter}
							onChangeComentarioHandler={onChangeComentarioHandler}
							usuarioInterno={usuarioInterno}
						/>
					))}
		</>
	) : filtrada(p) ? (
		<div
			className={`pergunta ${
				temComentario ? 'resposta-com-comentario pin-comentario' : usuarioInterno ? 'resposta-com-comentario' : ''
			}`}
			style={{ marginLeft: `${margin}px` }}
		>
			<RadioFieldLE
				name={p.campo}
				label={p.pergunta + (isDebug ? ` (${p.campo})` : '')}
				options={options}
				required={true}
				onChangeHandler={({ name, value }) => (name === 'errors' ? null : onChangeHandler({ name, value }))}
				value={value}
			/>

			<Comentario
				campoComentario={campoComentario}
				valueComentario={valueComentario}
				onChangeComentarioHandler={onChangeComentarioHandler}
				setTemComentario={setTemComentario}
				temComentario={temComentario}
				readOnly={!usuarioInterno}
			/>

			{alteradaRevisor && <AvisoAlteracaoRevisor />}
			{alteradaRequerente && <AvisoAlteracaoRequerente />}
			{get(respostas, `${value}.textoEntrada`) && (
				<InfoMessage
					message={get(respostas, `${value}.textoEntrada`)}
					bloqueante={get(respostas, `${value}.bloqueante`)}
				/>
			)}
			{size(get(respostas, `${value}.perguntas`)) > 0 &&
				get(respostas, `${value}.perguntas`)
					.sort(sortPerguntaPorOrdem)
					.map(pr => (
						<Pergunta
							key={pr.id}
							pergunta={pr}
							data={data}
							errors={errorsProps}
							onChangeHandler={onChangeHandler}
							filter={filter}
							onChangeComentarioHandler={onChangeComentarioHandler}
						/>
					))}
			{isDebug && (
				<div className="textos">
					{get(respostas, `${value}.textoEntrada`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoEntrada`)}</span>
							<label>texto formulário de entrada</label>
						</div>
					)}
					{get(respostas, `${value}.textoDocumento0`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoDocumento0`)}</span>
							<label>texto documento 0</label>
						</div>
					)}
					{get(respostas, `${value}.textoRevisor`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoRevisor`)}</span>
							<label>texto revisor</label>
						</div>
					)}
					{get(respostas, `${value}.textoSumario`) && (
						<div className="observacao-le labeled">
							<span>{get(respostas, `${value}.textoSumario`)}</span>
							<label>texto sumário</label>
						</div>
					)}
				</div>
			)}
			{size(errors) > 0 && <ErrorMessages errorList={errors} />}
		</div>
	) : isDebug ? (
		<p>
			removida {p.campo} por que o filtro é [{filter.join(', ')}] e a pergunta requer [{p.filter.join(', ')}]
		</p>
	) : null;
}
Pergunta.displayName = 'Pergunta';
Pergunta.propTypes = {
	pergunta: PropTypes.object,
	data: PropTypes.object,
	errors: PropTypes.any,
	margin: PropTypes.number,
	onChangeHandler: PropTypes.func,
	readOnly: PropTypes.bool,
	filter: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	onChangeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool
};

function AvisoAlteracaoRevisor() {
	return (
		<div className="warning-message ">
			<i
				className="fa fa-exclamation-triangle"
				title={'Esta resposta foi alterada pelo revisor.'}
				style={{ fontSize: '18px', marginRight: '8px' }}
			/>
			Esta resposta foi alterada pelo revisor.
		</div>
	);
}
AvisoAlteracaoRevisor.displayName = 'AvisoAlteracaoRevisor';

function InfoMessage({ message, bloqueante }) {
	return (
		<p className={bloqueante ? 'observacao-le-error' : 'observacao-le'}>
			{bloqueante && <i className="fa fa-exclamation-circle" style={{ marginRight: '4px' }} />}
			{message}
		</p>
	);
}
InfoMessage.displayName = 'InfoMessage';
InfoMessage.propTypes = {
	message: PropTypes.string,
	bloqueante: PropTypes.bool
};

function AvisoAlteracaoRequerente() {
	return (
		<div className="warning-message ">
			<i
				className="fa fa-exclamation-triangle"
				title={'Esta resposta foi alterada pelo requerente.'}
				style={{ fontSize: '18px', marginRight: '8px' }}
			/>
			Esta resposta foi alterada pelo requerente.
		</div>
	);
}
AvisoAlteracaoRequerente.displayName = 'AvisoAlteracaoRequerente';

export const validarRestricoes = async (dados, tipoForm, idMetadata) => {
	const restricoesMeta = await fetchMetadataRestricoesAdministrativas(idMetadata);
	const { perguntas = [] } = restricoesMeta;
	const { restricoesAdministrativas } = dados;

	const erros = [];
	const dfs = p => {
		const tiposForm = size(tipoForm) > 0 ? tipoForm.split(',').map(tf => tf.trim()) : [];
		if (size(p.filter) > 0 && !tiposForm.reduce((acc, tf) => acc || p.filter.includes(tf), false)) return;
		if (p.respostas) p.r = p.respostas.reduce(redutorRespostas, {});

		if (restricoesAdministrativas?.[p.campo] === undefined) {
			erros.push({ campo: p.campo, message: `Informar "${p.pergunta}"` });
		} else {
			const vr = restricoesAdministrativas[p.campo];
			const resposta = p.r[vr];
			if (resposta && resposta.bloqueante && resposta.textoEntrada) {
				erros.push({ campo: p.campo, message: resposta.textoEntrada });
				// console.log(p.campo, resposta, vr);
			}
			if (resposta && resposta.perguntas) {
				resposta.perguntas.forEach(dfs);
			}
		}
	};
	perguntas.forEach(dfs);
	return erros;
};

export const termoRestricoes = async (restricoesAdministrativas = {}, idMetadata) => {
	const restricoesMeta = await fetchMetadataRestricoesAdministrativas(idMetadata);
	const { perguntas = [] } = restricoesMeta;
	// const pReduced = perguntas.reduce(redutorPergunta, {});
	// const redutorPergunta = (acc, p) => ({ ...acc, [p.campo]: p });

	const avalia = (perguntas, pReduced) =>
		perguntas.reduce((acc, p) => {
			acc[p.campo] = p;
			if (p.respostas) {
				p.respostas.forEach(r => {
					if (r.perguntas) {
						avalia(r.perguntas, pReduced);
					}
				});
			}
			return acc;
		}, pReduced);
	const pReduced = avalia(perguntas, {});

	//console.log('preduced: ', pReduced);

	let textoTermo = '';
	Object.keys(pReduced).forEach(key => {
		const resposta = get(restricoesAdministrativas, `${key}`);
		const respostas = get(pReduced, `${key}.respostas`, []);
		const respostaMeta = respostas.find(r => r.codigo === resposta);

		if (!isNil(resposta) && get(respostaMeta, 'textoSumario')) {
			const { textoSumario = '' } = respostaMeta;
			let firstReplaceDone = false; // Indicador para verificar se o primeiro "DECLARO QUE" já foi substituído
			const updatedTextoSumario = textoSumario.replace(/DECLARO QUE/g, match => {
				if (!firstReplaceDone) {
					firstReplaceDone = true; // Marca que o primeiro "DECLARO QUE" foi substituído
					return `<br><strong>${match}</strong>`; // Formata os subsequentes sem adicionar quebras de linha
				} else {
					return `<br><br><strong>${match}</strong>`;
				}
			});
			textoTermo = `${textoTermo}<p style="margin-bottom:5px" >${
				isDebug && size(updatedTextoSumario.trim()) > 0 ? `(${key})<br>` : ''
			}${updatedTextoSumario}</p>`;
		}
	});

	//console.log('✌️textoTermo --->', textoTermo);

	return textoTermo;
};

export const fetchMetadataRestricoesAdministrativas = async idMetadata => {
	try {
		const url = `collections/metadata/${idMetadata}`;
		const retorno = (await accessApi(url))?.data;
		return retorno || {};
	} catch (e) {
		console.error(`Erro no acesso aos metadados de id "${idMetadata}"`, e);
	}
};
const flatPerguntas = (perguntas = []) =>
	perguntas.reduce((pv, cv) => {
		const { respostas = [], ...p } = cv;
		pv[cv.campo] = { ...p };
		if (respostas && respostas.length) {
			respostas.forEach(curr => {
				if (curr.perguntas) {
					const rr = flatPerguntas(curr.perguntas);
					Object.assign(pv, rr);
				}
			});
		}
		return pv;
	}, {});
const redutorRespostas = (acc, r) => ({ ...acc, [r.codigo]: r });
//const redutorPergunta = (acc, p) => ({ ...acc, [p.campo]: p });
