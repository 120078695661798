import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import { REGEX_TELEFONE } from 'utils/constants';
import { formataTelefone } from 'utils/tools';

const CLASS_NAME_FORM_CONTROL = 'form-control';
const CLASS_NAME_FORM_CONTROL_ERROR = 'form-control-error';

const PhoneField = ({ label, required, placeHolder, name, value, readOnly, disabled, onChangeHandler, isInvalid }) => {
	const onChangeText = e => {
		let { value } = e.target;
		value = formataTelefone(value);
		onChangeHandler([
			{ name, value },
			{ name: 'errors', value: { [name]: validate(value, required, label) } }
		]);
	};

	return (
		<>
			<label className="control-label">
				{label}
				{required && <span className="required">*</span>}
			</label>
			<input
				className={
					isInvalid === true
						? `${CLASS_NAME_FORM_CONTROL} ${CLASS_NAME_FORM_CONTROL_ERROR}`
						: `${CLASS_NAME_FORM_CONTROL}`
				}
				type="text"
				placeholder={placeHolder}
				name={name}
				value={value}
				onChange={onChangeText}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</>
	);
};
PhoneField.displayName = 'TextField';

PhoneField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool
};

export default PhoneField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		} else if (size(value) === 0 || !REGEX_TELEFONE.test(value)) {
			errors.push('Insira um telefone válido');
		}
	}
	return errors;
};
