import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { get, omit, size, trim } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import ShowDebug from 'components/ShowDebug';

import { getValueCutDigits, isDebug } from 'utils/tools';

import PlanilhaIndicesConstrutivosLinha from './PlanilhaIndicesConstrutivosLinha';
import ResumoIndicesConstrutivosAP from './ResumoIndicesConstruticos';

export const metadata = [
	{ label: 'Solo criado adensável (m²) ', property: 'soloCriadoAdensavel', decimals: 2 },
	{ label: 'Solo criado não adensável (m²) ', property: 'soloCriadoNaoAdensavel', decimals: 2 },
	{ label: 'Potencial construtivo oriundo de TPC local (m²) ', property: 'potencialConstrutivoTpcLocal', decimals: 4 },
	{
		label: 'Potencial construtivo oriundo de TPC externa (m²) ',
		property: 'potencialConstrutivoTpcRealizada',
		decimals: 4
	},
	{
		label: 'Potencial construtivo c/ origem no Leilão SMF (m²) ',
		property: 'potencialConstrutivoTpcLeilaoSmf',
		decimals: 4
	},
	{
		label: 'Potencial construtivo de imóvel inventariado (Lei nº 12.585/2019) (m²) ',
		property: 'potencialConstrutivoImovelInventariado',
		decimals: 4
	}
];

export const NOVA_AREA_UDRI = { informada: 0.0, obs: null };
export const PLANILHA_PROPERTY = 'planilhaIndicesConstrutivos';

let i = 0;
const debugLog = (...args) => isDebug && console.debug('(PIC)', ++i, ...args);
function PlanilhaIndicesConstrutivos({
	planilha,
	planoDiretor,
	possuiAreaAtingida,
	possuiImovelInventariado,
	areaAtingidaMatricula,
	areaAtingidaMenorPoligonal,
	totalIaPermitido,
	totalIaMaxTotalPermitido,
	onChangeHandler,
	readOnly,
	errors: errorsProps,
	propertyName = PLANILHA_PROPERTY
}) {
	const [localErrors, setLocalErrors] = useState({});
	const primeiraVez = useRef(true);

	const updatePlanilha = useCallback(
		(novaPlanilha, force) => {
			const { totalAdensavel, totalNaoAdensavel } = obtainTotalAreaCalculada(novaPlanilha);
			let changed = false;

			if (novaPlanilha.totalAreaAdensavelCalculada !== totalAdensavel) {
				novaPlanilha.totalAreaAdensavelCalculada = totalAdensavel;
				changed = true;
			}
			if (novaPlanilha.totalAreaNaoAdensavelCalculada !== totalNaoAdensavel) {
				novaPlanilha.totalAreaNaoAdensavelCalculada = totalNaoAdensavel;
				changed = true;
			}
			if (novaPlanilha.totalIaPermitido !== totalIaPermitido) {
				novaPlanilha.totalIaPermitido = totalIaPermitido;
				changed = true;
			}
			if (novaPlanilha.totalIaPermitidaMaisAdensavel !== totalIaPermitido + totalAdensavel) {
				novaPlanilha.totalIaPermitidaMaisAdensavel = totalIaPermitido + totalAdensavel;
				changed = true;
			}
			if (novaPlanilha.totalIaPermitidaMaisNaoAdensavel !== totalIaPermitido / 2 + totalNaoAdensavel) {
				novaPlanilha.totalIaPermitidaMaisNaoAdensavel = totalIaPermitido / 2 + totalNaoAdensavel;
				changed = true;
			}
			if (novaPlanilha.totalIaMaxTotalPermitido !== totalIaMaxTotalPermitido) {
				novaPlanilha.totalIaMaxTotalPermitido = totalIaMaxTotalPermitido;
				changed = true;
			}

			if (novaPlanilha.areaAtingidaMatricula !== areaAtingidaMatricula) {
				novaPlanilha.areaAtingidaMatricula = areaAtingidaMatricula;
				changed = true;
			}
			if (novaPlanilha.areaAtingidaMenorPoligonal !== areaAtingidaMenorPoligonal) {
				novaPlanilha.areaAtingidaMenorPoligonal = areaAtingidaMenorPoligonal;
				changed = true;
			}
			if (novaPlanilha.possuiAreaAtingida !== possuiAreaAtingida) {
				novaPlanilha.possuiAreaAtingida = possuiAreaAtingida;
				changed = true;
			}

			if (changed || force) {
				const [errorsAux, warningsAux] = validaPlanilhaIndicesConstrutivos(novaPlanilha, planoDiretor.dadosSubunidades);
				if (size(warningsAux) > 0) {
					for (const prop in warningsAux) {
						for (const message of warningsAux[prop]) {
							const warningAux = { type: 'warning', message };
							if (errorsAux[prop]) {
								errorsAux[prop].push(warningAux);
							} else {
								errorsAux[prop] = [warningAux];
							}
						}
					}
				}
				setLocalErrors(errorsAux);
				onChangeHandler(novaPlanilha, propertyName);
			}
		},
		[
			areaAtingidaMatricula,
			areaAtingidaMenorPoligonal,
			onChangeHandler,
			planoDiretor.dadosSubunidades,
			possuiAreaAtingida,
			propertyName,
			totalIaMaxTotalPermitido,
			totalIaPermitido
		]
	);

	const handleChange = (value, name) => {
		let novaPlanilha = { ...planilha, [name]: value };
		updatePlanilha(novaPlanilha, true);
	};

	const readOnlyAreas = useMemo(() => {
		if (!planoDiretor.dadosSubunidades) {
			return null;
		}

		/* --------------------------------- regras --------------------------------- */
		// ex: Paula Soares, 1000 - subunidade 04 -> código 01
		// Removido na história #62525 - Item 5
		const codigo01ou03 = false;
		// const codigo01ou03 = planoDiretor.dadosSubunidades.reduce(
		// 	(acc, s) => acc && ['01', '03'].includes(s?.indiceAproveitamentoMaximoAnexo?.codigo),
		// 	true
		// );

		// ex: Estr Extrema, 3384 - subunidade 01 -> código 31
		// const codigo31ou33ou35ou37 = planoDiretor.dadosSubunidades.reduce(
		// 	(acc, s) => acc && ['31', '33', '35', '37'].includes(s?.indiceAproveitamentoMaximoAnexo?.codigo),
		// 	true
		// );

		// ex: (Borges de Medeiros, 2255)
		// const regimeUrbanisticoProprio = ['25', '39'];
		// const regimeUrbanisticoProprioLeiEspecifica = ['41'];
		// const regimeUrbanisticoProprioSMGP = ['23'];
		// const regimeUrbanisticoProprio = planoDiretor.dadosSubunidades.reduce(
		// 	(acc, s) =>
		// 		acc &&
		// 		[
		// 			...regimeUrbanisticoProprio,
		// 			...regimeUrbanisticoProprioLeiEspecifica,
		// 			...regimeUrbanisticoProprioSMGP
		// 		].includes(s?.indiceAproveitamentoMaximoAnexo?.codigo),
		// 	true
		// );
		/* -------------------------------------------------------------------------- */

		const output = {
			soloCriadoAdensavel: codigo01ou03,
			soloCriadoNaoAdensavel: codigo01ou03,
			potencialConstrutivoTpcRealizada: codigo01ou03,
			potencialConstrutivoTpcLeilaoSmf: codigo01ou03,
			potencialConstrutivoImovelInventariado: codigo01ou03
		};

		planoDiretor.dadosSubunidades
			.map(s => `potencialConstrutivoTpcLocal_${s.subunidade.subunidade}`)
			.forEach(k => (output[k] = !possuiAreaAtingida));

		debugLog('------------------------------------\n');
		planoDiretor.dadosSubunidades.forEach(s => {
			debugLog(` ---> subunidade ${s.subunidade.subunidade}:\n`, s.indiceAproveitamentoMaximoAnexo);
		});
		debugLog('readOnlyAreas', output);

		return output;
	}, [planoDiretor.dadosSubunidades, possuiAreaAtingida]);

	useEffect(() => {
		if (readOnlyAreas) {
			const validKeys = Object.keys(readOnlyAreas);
			debugLog('validKeys:', validKeys);
			const invalidKeys = Object.keys(planilha).filter(
				key => !validKeys.includes(key) && !key.startsWith('total') && !key.toLowerCase().includes('areaatingida')
			);
			debugLog('invalidKeys:', invalidKeys);
			const removedKeys = validKeys.filter(key => readOnlyAreas[key]).concat(invalidKeys);

			if (size(removedKeys) > 0) {
				debugLog('removedKeys:', removedKeys);
				const novaPlanilha = omit(planilha, removedKeys);
				updatePlanilha(novaPlanilha, true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readOnlyAreas]);

	useEffect(() => {
		if (totalIaPermitido) {
			updatePlanilha(planilha, primeiraVez.current);
			primeiraVez.current = false;
		}
	}, [planilha, totalIaPermitido, updatePlanilha]);

	const errors = Object.assign(
		{},
		errorsProps.reduce((acc, errorAux) => ({ ...acc, ...{ [errorAux.campo]: [errorAux.message] } }), {}) || {},
		localErrors || {}
	);

	return (
		<>
			<div className={`planilha-indices-construtivos${readOnly ? ' read-only' : ''}`}>
				{metadata
					.reduce((acc, area) => {
						if (area.property === 'potencialConstrutivoTpcLocal') {
							return acc.concat(
								(planoDiretor?.dadosSubunidades || []).map(s => ({
									...area,
									label: `${area.label} (subunidade ${get(s, 'subunidade.subunidade')})`,
									property: `${area.property}_${get(s, 'subunidade.subunidade')}`,
									iaSubunidade: parseFloat(get(s, 'iaRegimeUrbanisticoAnexo.codigo') || 1.0),
									iaReadonly: true
								}))
							);
						}
						return acc.concat(area);
					}, [])
					.filter(({ property }) =>
						property !== 'potencialConstrutivoImovelInventariado' ? true : possuiImovelInventariado
					)
					.map(({ label, property, decimals }, index) => {
						let areas = [NOVA_AREA_UDRI];
						const auxValue = planilha[property] || { areas };
						const lockedMessage = readOnlyAreas?.[property]
							? property.startsWith('potencialConstrutivoTpcLocal')
								? 'Índice não permitido por que não possui área atingida'
								: 'Índice não permitido por restrição do Plano Diretor Anexo 6 (códigos 01 e 03)'
							: null;
						return (
							<PlanilhaIndicesConstrutivosLinha
								key={index}
								label={label}
								name={property}
								value={auxValue}
								decimals={decimals}
								onChangeHandler={handleChange}
								readOnly={readOnly}
								locked={readOnlyAreas?.[property]}
								lockedMessage={lockedMessage}
								errors={errors?.[property]}
							/>
						);
					})}
			</div>
			{size(errors?.planilha) > 0 && <ErrorMessages errorList={errors.planilha} />}
			<ResumoIndicesConstrutivosAP
				planilha={planilha}
				planoDiretor={planoDiretor}
				possuiImovelInventariado={possuiImovelInventariado}
			/>
			<ShowDebug
				data={{
					planilha,
					planoDiretor,
					possuiAreaAtingida,
					possuiImovelInventariado,
					areaAtingidaMatricula,
					areaAtingidaMenorPoligonal,
					totalIaPermitido,
					totalIaMaxTotalPermitido
				}}
				console
			/>
		</>
	);
}
PlanilhaIndicesConstrutivos.displayName = 'PlanilhaIndicesConstrutivos';
PlanilhaIndicesConstrutivos.propTypes = {
	planilha: PropTypes.object,
	planoDiretor: PropTypes.object,
	possuiAreaAtingida: PropTypes.bool,
	possuiImovelInventariado: PropTypes.bool,
	areaAtingidaMatricula: PropTypes.number,
	areaAtingidaMenorPoligonal: PropTypes.number,
	totalIaPermitido: PropTypes.number,
	totalIaMaxTotalPermitido: PropTypes.number,
	readOnly: PropTypes.bool,
	errors: PropTypes.any,
	onChangeHandler: PropTypes.func,
	propertyName: PropTypes.string
};

export default PlanilhaIndicesConstrutivos;

function obtainTotalAreaCalculada(planilha) {
	const novoValorAdensavel =
		(planilha?.soloCriadoAdensavel?.subtotalAreaCalculada || 0) +
		Object.keys(planilha)
			.filter(key => key.startsWith('potencialConstrutivoTpcLocal'))
			.reduce((acc, key) => {
				const area = planilha[key];
				return acc + (area.subtotalAreaCalculada || 0);
			}, 0) +
		(planilha?.potencialConstrutivoTpcRealizada?.subtotalAreaCalculada || 0) +
		(planilha?.potencialConstrutivoTpcLeilaoSmf?.subtotalAreaCalculada || 0) +
		(planilha?.potencialConstrutivoImovelInventariado?.subtotalAreaCalculada || 0);

	const novoValorNaoAdensavel = novoValorAdensavel / 2 + (planilha?.soloCriadoNaoAdensavel?.subtotalAreaCalculada || 0);

	const output = {
		totalAdensavel: getValueCutDigits(novoValorAdensavel, 4),
		totalNaoAdensavel: getValueCutDigits(novoValorNaoAdensavel, 4)
	};
	return output;
}

export function validaPlanilhaIndicesConstrutivos(planilha, dadosSubunidades) {
	let errors = {};
	let warnings = {};
	metadata.forEach(m => {
		const property = m.property;
		if (((planilha[property] || {}).areas || []).find(a => !a.informada && size(trim(a.obs)) > 0)) {
			errors[property] = ['Não é possível informar observações em linhas sem área informada'];
		}
	});

	const fnTotalArea = prop => fnTotalAreaPlanilha(planilha, prop);

	const naInformado = fnTotalArea('soloCriadoNaoAdensavel');
	const adInformado = fnTotalArea('soloCriadoAdensavel');

	const totalAreas = naInformado + adInformado;

	const totalTpc = Object.keys(planilha)
		.filter(key => key.startsWith('potencialConstrutivoTpc'))
		.reduce((acc, prop) => acc + fnTotalArea(prop), 0);

	const total = totalAreas + totalTpc;

	if (total === 0) {
		errors.planilha = ['Pelo menos uma área deve ser informada na planilha'];
	}

	Object.keys(planilha)
		.filter(key => key.startsWith('potencialConstrutivoTpcLocal'))
		.forEach(prop => {
			const [, subunidade] = prop.split('_');
			const sub = dadosSubunidades.find(s => s.subunidade?.subunidade === parseInt(subunidade));
			const ia = parseFloat(sub?.iaRegimeUrbanisticoAnexo?.codigo || 1.0);
			const valorConsiderado = planilha?.areaAtingidaMenorPoligonal || planilha?.areaAtingidaMatricula || 0;
			const valorMaximo = valorConsiderado * ia;
			debugLog('Para cálculo do valor máximo de TPC Local:');
			debugLog(
				`valorConsiderado (${
					planilha?.areaAtingidaMenorPoligonal ? 'areaAtingidaMenorPoligonal' : 'areaAtingidaMatricula'
				}): ${valorConsiderado}`
			);
			debugLog(`valorCo: ${ia}`);
			debugLog(`valorMaximo: ${valorMaximo}`);
			if (fnTotalArea(prop) > valorMaximo) {
				debugLog(`valor informado (${fnTotalArea(prop)}) é maior que o permitido`);
				warnings[prop] = [
					`O valor informado é maior que o permitido (área atingida * Índice de Aproveitamento da subunidade)${
						isDebug ? `(debug - valor máximo: ${valorMaximo})` : ''
					}`
				];
			}
			debugLog('------------------------------------------');
		});

	return [errors, warnings];
}

export function fnTotalAreaPlanilha(planilha, prop) {
	return (planilha?.[prop]?.areas || []).reduce((acc, a) => acc + a.informada, 0);
}
