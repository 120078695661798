import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { size, trim, get, find } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import WarningMessage from 'components/WarningMessage';

import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE';

import useMutableState from 'custom-hooks/useMutableState';
import useQueryStore from 'custom-hooks/useQueryStore';

import { API_URL } from 'environments';

import { isDebug, removeDiacritics } from 'utils/tools';

import PlanoDiretorAnexosSelecao from './PlanoDiretorAnexosSelecao';

let i = 0;

export const MSG_ERROR_LEI_ESPECIFICA =
	'Devido a este projeto não possuir legislação definindo o regime urbanístico do terreno, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';

export const MSG_ERROR_EVU_DEFININDO_RU =
	'Devido a este projeto não possuir Estudo de Viabilidade Urbanístico aprovado e válido definindo o regime urbanístico do terreno, este projeto não se enquadra na modalidade de Aprovação de Projeto Licenciamento Expresso. Deverá ser solicitada a Aprovação de Projeto Arquitetônico no Portal de Licenciamento (Serviços Urbanísticos e Ambientais > Novo Processo > Aprovação de Projeto Arquitetônico).';

export const MSG_BASE_DESATIVADA = 'Para este regime urbanístico não há previsão de altura da base (m)';

const CODIGO_ATIVIDADE_LEI_PROPRIA = '99.99';
const debugLog = (...parms) =>
	false && isDebug && console.debug('(trk-PLANO_DIRETOR_REGIME_URBANISTICO):', ++i, ...parms);

/* eslint-disable no-mixed-operators */
const PlanoDiretorRegimeUrbanistico = ({
	data: dadosSubunidade = {},
	dataAnterior: dadosSubunidadeAnterior = {},
	readOnly,
	setDataHandler,
	errosSubmissao,
	showIndices,
	usuarioInterno = false
}) => {
	/* CUSTOM HOOKS */
	const zonaUsoAnexo51Store = useMutableState(['licenciamento', 'zonaUsoAnexo51']);
	const gaRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'gaRegimeUrbanisticoAnexo']);
	const iaRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'iaRegimeUrbanisticoAnexo']);
	const alturaRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'alturaRegimeUrbanisticoAnexo']);
	const divisaRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'divisaRegimeUrbanisticoAnexo']);
	const baseRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'baseRegimeUrbanisticoAnexo']);
	const toRegimeUrbanisticoAnexoStore = useMutableState(['licenciamento', 'toRegimeUrbanisticoAnexo']);
	const [hasAllStores, setHasAllStores] = useState(false);
	const [auto, setAuto] = useState({});
	const [carregado, setCarregado] = useState({});
	const [showRadioRuDefinidoPorLei, setShowRadioRuDefinidoPorLei] = useState(false);
	const [showRadioPossuiEvuDefinindoRu, setShowRadioPossuiEvuDefinindoRu] = useState(false);
	const [hideAlturaBase, setHideAlturaBase] = useState(false);
	const isLicencasExpressas = useMutableState(['licenciamento', 'isLicencasExpressas']);
	const { data: dadosGA, changeUrl: changeUrlGA } = useQueryStore(null, 'gaRegimeUrbanisticoAnexo');
	const [formularioPossuiAnexo72, setFormularioPossuiAnexo72] = useState(false);

	// acessa demais tabelas necessárias
	const { data: anexo6IAStore } = useQueryStore(`${API_URL}/collections/anexo6/`, 'anexo6IA');
	const { data: anexo71Store } = useQueryStore(`${API_URL}/collections/anexo71/`, 'anexo71');
	const { data: relacaoLogradourosAnexo72Store } = useQueryStore(
		`${API_URL}/collections/anexo72relacaologradouros/`,
		'relacaoLogradourosAnexo72'
	);

	// carregamento de dados do formulário
	const formulario = useMutableState(['licenciamento', 'formulario']);

	/**
	 * adiciona a isenção na quota ideal
	 * com base nas regras da história #61347
	 *
	 * esse método muta o objeto passado por parâmetro
	 *
	 * Se RAREFEITA códigos 31 a 41 do Anexo 6
	 * Se INTENSIVA código 01 Anexo 7.1
	 *
	 * @param {*} valueQuota
	 * @returns void
	 */
	const mapQuotaIdealAnexo6 = useCallback(
		valueQuota => {
			if (!valueQuota) return;

			const codigoNumero = parseInt(valueQuota.codigo, 10);
			// #61443 - codigos RAREFEITA
			if ([31, 33, 35, 37, 39].includes(codigoNumero)) {
				valueQuota.isenta = true;
				valueQuota.tipoQuota = 'RAREFEITA';
			} else if (anexo71Store) {
				const anexo71Capturado = (anexo71Store || []).find(
					item => item.codigo === codigoNumero || parseFloat(item.codigo) === codigoNumero
				);

				if (anexo71Capturado?.codigo && parseInt(anexo71Capturado?.codigo, 10) === 1) {
					valueQuota.isenta = true;
					valueQuota.tipoQuota = 'INTENSIVA';
				}
			} else {
				delete valueQuota.isenta;
				delete valueQuota.tipoIsencao;
			}
		},
		[anexo71Store]
	);

	const setLocalDataHandler = useCallback(
		(value, name) => {
			if (name === 'errors') {
				return;
			}

			if (name === 'possuiAnexo72') {
				// zera para redefinir valores de divisa, base e to se alterar possui anexo 72
				setCarregado(old => ({
					...old,
					divisaRegimeUrbanisticoAnexo: false,
					baseRegimeUrbanisticoAnexo: false,
					toRegimeUrbanisticoAnexo: false
				}));
			}

			debugLog('<setLocalDataHandler>', name, value);

			if (name === 'quotaRegimeUrbanisticoAnexo') {
				mapQuotaIdealAnexo6(value);
			}

			setDataHandler(value, name);
		},
		[mapQuotaIdealAnexo6, setDataHandler]
	);

	useEffect(() => {
		if (isLicencasExpressas && !dadosGA) {
			changeUrlGA(`${API_URL}/collections/anexoregimeurbanisticoga/`);
		}
	}, [changeUrlGA, dadosGA, isLicencasExpressas]);

	useEffect(() => {
		if (
			zonaUsoAnexo51Store &&
			gaRegimeUrbanisticoAnexoStore &&
			iaRegimeUrbanisticoAnexoStore &&
			alturaRegimeUrbanisticoAnexoStore &&
			divisaRegimeUrbanisticoAnexoStore &&
			baseRegimeUrbanisticoAnexoStore &&
			toRegimeUrbanisticoAnexoStore &&
			anexo6IAStore &&
			anexo71Store &&
			relacaoLogradourosAnexo72Store
		) {
			setHasAllStores(true);
		}
	}, [
		alturaRegimeUrbanisticoAnexoStore,
		anexo6IAStore,
		anexo71Store,
		baseRegimeUrbanisticoAnexoStore,
		divisaRegimeUrbanisticoAnexoStore,
		gaRegimeUrbanisticoAnexoStore,
		iaRegimeUrbanisticoAnexoStore,
		relacaoLogradourosAnexo72Store,
		toRegimeUrbanisticoAnexoStore,
		zonaUsoAnexo51Store
	]);

	// useEffect alternativo ao próximo, quando todas as stores
	// já estariam carregadas. Nesse caso, quando preparado para
	// licencasExpressas, carrega apenas o que precisa.
	useEffect(() => {
		if (isLicencasExpressas) {
			let preSelected = {};

			if (zonaUsoAnexo51Store && !carregado.zonaUsoAnexo51) {
				const value =
					(zonaUsoAnexo51Store || []).find(item => item.codigo === dadosSubunidade.subunidade?.atividade) || null;
				if (!dadosSubunidade.zonaUsoAnexo51) {
					preSelected.zonaUsoAnexo51 = value;
				}
				setAuto(old => ({ ...old, zonaUsoAnexo51: !!value }));
				setCarregado(old => ({ ...old, zonaUsoAnexo51: true }));
			}
			if (gaRegimeUrbanisticoAnexoStore && !carregado.gaRegimeUrbanisticoAnexo) {
				const value =
					(gaRegimeUrbanisticoAnexoStore || []).find(
						item =>
							item.codigo === dadosSubunidade.subunidade?.atividade ||
							parseFloat(item.codigo) === parseFloat(dadosSubunidade.subunidade?.atividade)
					) || null;
				if (!dadosSubunidade.gaRegimeUrbanisticoAnexo) {
					preSelected.gaRegimeUrbanisticoAnexo = value;
				}
				setAuto(old => ({ ...old, gaRegimeUrbanisticoAnexo: !!value }));
				setCarregado(old => ({ ...old, gaRegimeUrbanisticoAnexo: true }));
			}
			if (size(preSelected) > 0) {
				// asynchronicity is a bitch
				setLocalDataHandler(Object.assign(dadosSubunidade, preSelected), 'dadosSubunidade');
			}
		}
	}, [
		carregado,
		dadosSubunidade,
		gaRegimeUrbanisticoAnexoStore,
		isLicencasExpressas,
		setLocalDataHandler,
		zonaUsoAnexo51Store
	]);

	// auto preencher os valores
	useEffect(() => {
		if (dadosSubunidade && hasAllStores) {
			debugLog('subunidade', dadosSubunidade);

			let deveSetarAutos = false;
			let deveSetarPreSelected = false;
			let deveSetarCarregados = false;

			if (dadosSubunidade?.subunidade?.atividade === CODIGO_ATIVIDADE_LEI_PROPRIA && !showRadioRuDefinidoPorLei) {
				setShowRadioRuDefinidoPorLei(true);
			}

			let preSelectedDadosSubunidade = {};
			let autos = {};
			let carregados = {};

			if (!carregado.zonaUsoAnexo51) {
				const value =
					(zonaUsoAnexo51Store || []).find(
						item => item.codigo === dadosSubunidade.subunidade?.atividade
						// item => item.descricao.toLowerCase() === dadosSubunidade.subunidade?.zonaUso?.toLowerCase()
					) || null;
				if (!dadosSubunidade.zonaUsoAnexo51) {
					// setLocalDataHandler(value, 'zonaUsoAnexo51');
					deveSetarPreSelected = true;
					preSelectedDadosSubunidade.zonaUsoAnexo51 = value;
				}
				deveSetarAutos = true;
				Object.assign(autos, { zonaUsoAnexo51: !!value });
				deveSetarCarregados = true;
				Object.assign(carregados, { zonaUsoAnexo51: true });
			}
			if (!carregado.gaRegimeUrbanisticoAnexo) {
				const value =
					(gaRegimeUrbanisticoAnexoStore || []).find(
						item =>
							item.codigo === dadosSubunidade.subunidade?.atividade ||
							parseFloat(item.codigo) === parseFloat(dadosSubunidade.subunidade?.atividade)
					) || null;
				if (!dadosSubunidade.gaRegimeUrbanisticoAnexo) {
					// setLocalDataHandler(value, 'gaRegimeUrbanisticoAnexo');
					deveSetarPreSelected = true;
					preSelectedDadosSubunidade.gaRegimeUrbanisticoAnexo = value;
				}
				deveSetarAutos = true;
				Object.assign(autos, { gaRegimeUrbanisticoAnexo: !!value });
				deveSetarCarregados = true;
				Object.assign(carregados, { gaRegimeUrbanisticoAnexo: true });
			}

			// referente ao anexo 6
			const anexo6Capturado = find(
				anexo6IAStore,
				item =>
					item.codigo === dadosSubunidade.subunidade?.indiceAproveitamento ||
					parseFloat(item.codigo) === parseFloat(dadosSubunidade.subunidade?.indiceAproveitamento)
			);
			if (anexo6Capturado) {
				// aproveitamento
				if (!carregado.iaRegimeUrbanisticoAnexo) {
					// permite preenchimento manual somente se usuario responder sobre lei especifica
					if (anexo6Capturado?.codigo === '41' && !showRadioRuDefinidoPorLei) {
						setShowRadioRuDefinidoPorLei(true);
					}
					if (
						(anexo6Capturado?.codigo === '25' || anexo6Capturado?.codigo === '39') &&
						!showRadioPossuiEvuDefinindoRu
					) {
						setShowRadioPossuiEvuDefinindoRu(true);
					}

					let valueIa =
						(iaRegimeUrbanisticoAnexoStore || []).find(
							item =>
								anexo6Capturado &&
								(item.codigo === anexo6Capturado.ia ||
									item.descricao === anexo6Capturado.ia ||
									(anexo6Capturado.codigo === '25' && item.descricao === 'RU próprio') ||
									(anexo6Capturado.codigo === '23' && item.descricao === 'RU a critério do SMGP') ||
									(anexo6Capturado.codigo === '39' && item.descricao === 'RU próprio') ||
									(anexo6Capturado.codigo === '41' && item.descricao === 'RU próprio definido por Lei Específica'))
						) || null;

					// libera preenchimento manual se for codigo 25 (RU proprio)
					if (
						anexo6Capturado?.codigo === '25' ||
						anexo6Capturado?.codigo === '23' ||
						anexo6Capturado?.codigo === '39' ||
						anexo6Capturado?.codigo === '41'
					) {
						valueIa = null;
					}
					if (!dadosSubunidade.iaRegimeUrbanisticoAnexo) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.iaRegimeUrbanisticoAnexo = valueIa;
					}
					deveSetarAutos = true;
					Object.assign(autos, { iaRegimeUrbanisticoAnexo: !!valueIa });
					deveSetarCarregados = true;
					Object.assign(carregados, { iaRegimeUrbanisticoAnexo: true });
				}
				// Quota Ideal
				if (!carregado.quotaRegimeUrbanisticoAnexo) {
					let valueQuota = null;
					if (trim(anexo6Capturado.quotaideal) !== '-' && !!anexo6Capturado.quotaideal) {
						valueQuota = { ...anexo6Capturado };
						mapQuotaIdealAnexo6(valueQuota);
					}

					if (!dadosSubunidade.quotaRegimeUrbanisticoAnexo) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.quotaRegimeUrbanisticoAnexo = valueQuota;
					}
					deveSetarAutos = true;
					Object.assign(autos, { quotaRegimeUrbanisticoAnexo: !!valueQuota });
					deveSetarCarregados = true;
					Object.assign(carregados, { quotaRegimeUrbanisticoAnexo: true });
				}
				// solo criado
				if (!carregado.soloCriadoAnexo) {
					let valueSC = null;
					if (trim(anexo6Capturado.sc) !== '-' && !!anexo6Capturado.sc) {
						valueSC = { ...anexo6Capturado };
					}

					if (!dadosSubunidade.soloCriadoAnexo) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.soloCriadoAnexo = valueSC;
					}
					deveSetarAutos = true;
					Object.assign(autos, { soloCriadoAnexo: !!valueSC });
					deveSetarCarregados = true;
					Object.assign(carregados, { soloCriadoAnexo: true });
				}
				// potencial construtivo
				if (!carregado.transferenciaPotencialConstrutivoAnexo) {
					let valueTPC = null;
					if (
						trim(anexo6Capturado.transferenciaPotencialConstrutivoAnexo) !== '-' &&
						!!anexo6Capturado.transferenciaPotencialConstrutivoAnexo
					) {
						valueTPC = { ...anexo6Capturado };
						mapQuotaIdealAnexo6(valueTPC);
					}

					if (!dadosSubunidade.transferenciaPotencialConstrutivoAnexo) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.transferenciaPotencialConstrutivoAnexo = valueTPC;
					}
					deveSetarAutos = true;
					Object.assign(autos, { transferenciaPotencialConstrutivoAnexo: !!valueTPC });
					deveSetarCarregados = true;
					Object.assign(carregados, { transferenciaPotencialConstrutivoAnexo: true });
				}
				// aproveitamento maximo
				if (!carregado.indiceAproveitamentoMaximoAnexo) {
					let valueIAMax = null;
					if (trim(anexo6Capturado.iamaximo) !== '-' && !!anexo6Capturado.iamaximo) {
						valueIAMax = { ...anexo6Capturado };
					}
					if (!dadosSubunidade.indiceAproveitamentoMaximoAnexo) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.indiceAproveitamentoMaximoAnexo = valueIAMax;
					}
					deveSetarAutos = true;
					Object.assign(autos, { indiceAproveitamentoMaximoAnexo: !!valueIAMax });
					deveSetarCarregados = true;
					Object.assign(carregados, { indiceAproveitamentoMaximoAnexo: true });
				}
				// observacao
				const novoValor = dadosSubunidade.observacaoAnexo6 || anexo6Capturado.observacao || null;
				if (novoValor !== dadosSubunidade.observacaoAnexo6) {
					deveSetarPreSelected = true;
					preSelectedDadosSubunidade.observacaoAnexo6 = novoValor;
				}
			}

			if (anexo71Store) {
				const isVolumetriaInexistente = dadosSubunidade?.subunidade?.volumetria === '999';

				const anexo71Capturado =
					(anexo71Store || []).find(
						item =>
							item.codigo === dadosSubunidade?.subunidade?.volumetria ||
							parseFloat(item.codigo) === parseFloat(dadosSubunidade?.subunidade?.volumetria)
					) || null;
				// ==============================

				if (anexo71Capturado?.alturabase?.trim() === '-') {
					if (!hideAlturaBase) {
						setHideAlturaBase(true);
					}
					if (!preSelectedDadosSubunidade.hideAlturaBase) {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.hideAlturaBase = true;
					}
				}

				if (alturaRegimeUrbanisticoAnexoStore && !carregado.alturaRegimeUrbanisticoAnexo) {
					// debugLog('alturaRegimeUrbanisticoAnexoStore', alturaRegimeUrbanisticoAnexoStore);
					// debugLog('anexo71Capturado', anexo71Capturado);
					let value =
						(alturaRegimeUrbanisticoAnexoStore || []).find(
							item =>
								item?.codigo === anexo71Capturado?.alturamaxima?.trim() ||
								item?.descricao === anexo71Capturado?.alturamaxima?.trim() ||
								(anexo71Capturado?.codigo === '25' && item.descricao === 'RU próprio')
						) || null;

					// libera preenchimento manual se for codigo 25 (RU proprio)
					if (
						anexo71Capturado?.codigo === '25' ||
						anexo71Capturado?.codigo === '23' ||
						anexo71Capturado?.codigo === '39' ||
						anexo71Capturado?.codigo === '41'
					) {
						value = null;
					}

					if (isVolumetriaInexistente) {
						// para digitável, aproveita o valor anterior, caso exista
						if (!dadosSubunidade?.alturaRegimeUrbanisticoAnexo) {
							deveSetarPreSelected = true;
							preSelectedDadosSubunidade.alturaRegimeUrbanisticoAnexo = value;
						}
						deveSetarAutos = true;
						Object.assign(autos, { alturaRegimeUrbanisticoAnexo: false });
					} else {
						if (!dadosSubunidade?.alturaRegimeUrbanisticoAnexo) {
							deveSetarPreSelected = true;
							preSelectedDadosSubunidade.alturaRegimeUrbanisticoAnexo = value;
						}
						deveSetarAutos = true;
						Object.assign(autos, { alturaRegimeUrbanisticoAnexo: !!value });
					}
					deveSetarCarregados = true;
					Object.assign(carregados, { alturaRegimeUrbanisticoAnexo: true });
				}
				// ==============================
				if (divisaRegimeUrbanisticoAnexoStore && !carregado.divisaRegimeUrbanisticoAnexo) {
					if (isVolumetriaInexistente && dadosSubunidade?.divisaRegimeUrbanisticoAnexo) {
						if (!dadosSubunidade?.divisaRegimeUrbanisticoAnexo) {
							deveSetarPreSelected = true;
							preSelectedDadosSubunidade.divisaRegimeUrbanisticoAnexo = dadosSubunidade?.divisaRegimeUrbanisticoAnexo;
						}
						deveSetarAutos = true;
						Object.assign(autos, { divisaRegimeUrbanisticoAnexo: false });
						deveSetarCarregados = true;
						Object.assign(carregados, { divisaRegimeUrbanisticoAnexo: true });
					} else {
						let selecionado =
							(divisaRegimeUrbanisticoAnexoStore || []).find(
								item =>
									item?.codigo === anexo71Capturado?.alturadivisa?.trim() ||
									item?.descricao === anexo71Capturado?.alturadivisa?.trim() ||
									(anexo71Capturado?.codigo === '25' && item.descricao === 'RU próprio')
							) || null;

						// libera preenchimento manual se for codigo 25 (RU proprio)
						if (
							anexo71Capturado?.codigo === '25' ||
							anexo71Capturado?.codigo === '23' ||
							anexo71Capturado?.codigo === '39' ||
							anexo71Capturado?.codigo === '41'
						) {
							selecionado = null;
						}
						const doisValoresPossiveis = selecionado?.descricao?.includes(' e '); //&& size(trim(dadosSubunidade.possuiAnexo72)) > 0;
						if (doisValoresPossiveis) {
							const partes = selecionado.descricao.split(' e ');
							const parcialDescricao = (
								dadosSubunidade.possuiAnexo72 === 'sim'
									? partes[1] // se sim, usa o valor maior, senão, o valor menor
									: partes[0]
							).trim();
							const value =
								(divisaRegimeUrbanisticoAnexoStore || []).find(
									item => item.codigo === parcialDescricao || item.descricao === parcialDescricao
								) || null;
							if (dadosSubunidade?.divisaRegimeUrbanisticoAnexo !== value) {
								deveSetarPreSelected = true;
								preSelectedDadosSubunidade.divisaRegimeUrbanisticoAnexo = value;
							}
							deveSetarAutos = true;
							Object.assign(autos, { divisaRegimeUrbanisticoAnexo: !!value });
							if (!dadosSubunidade.possuiAnexo72) {
								Object.assign(carregados, { divisaRegimeUrbanisticoAnexo: false });
								deveSetarCarregados = false;
							} else {
								deveSetarCarregados = true;
								Object.assign(carregados, { divisaRegimeUrbanisticoAnexo: true });
							}
						} else {
							if (!dadosSubunidade?.divisaRegimeUrbanisticoAnexo) {
								deveSetarPreSelected = true;
								preSelectedDadosSubunidade.divisaRegimeUrbanisticoAnexo = selecionado;
							}
							deveSetarAutos = true;
							Object.assign(autos, { divisaRegimeUrbanisticoAnexo: !!selecionado });
							deveSetarCarregados = true;
							Object.assign(carregados, { divisaRegimeUrbanisticoAnexo: true });
						}
					}
				}
				// ==============================
				if (baseRegimeUrbanisticoAnexoStore && !carregado.baseRegimeUrbanisticoAnexo) {
					if (isVolumetriaInexistente && dadosSubunidade?.baseRegimeUrbanisticoAnexo) {
						if (!dadosSubunidade?.baseRegimeUrbanisticoAnexo) {
							deveSetarPreSelected = true;
							preSelectedDadosSubunidade.baseRegimeUrbanisticoAnexo = dadosSubunidade?.baseRegimeUrbanisticoAnexo;
						}
						deveSetarAutos = true;
						Object.assign(autos, { baseRegimeUrbanisticoAnexo: false });
						deveSetarCarregados = true;
						Object.assign(carregados, { baseRegimeUrbanisticoAnexo: true });
					} else {
						let selecionado =
							(baseRegimeUrbanisticoAnexoStore || []).find(
								item =>
									item?.codigo === anexo71Capturado?.alturabase?.trim() ||
									item?.descricao === anexo71Capturado?.alturabase?.trim() ||
									(anexo71Capturado?.codigo === '25' && item.descricao === 'RU próprio')
							) || null;
						// libera preenchimento manual se for codigo 25 (RU proprio)
						if (
							anexo71Capturado?.codigo === '25' ||
							anexo71Capturado?.codigo === '23' ||
							anexo71Capturado?.codigo === '39' ||
							anexo71Capturado?.codigo === '41'
						) {
							selecionado = null;
						}
						const doisValoresPossiveis = selecionado?.descricao?.includes(' e '); //&& size(trim(dadosSubunidade.possuiAnexo72)) > 0;
						if (doisValoresPossiveis) {
							const partes = selecionado.descricao.split(' e ');
							const parcialDescricao = (
								dadosSubunidade.possuiAnexo72 === 'sim'
									? partes[1] // se sim, usa o valor maior, senão, o valor menor
									: partes[0]
							).trim();
							const value =
								(baseRegimeUrbanisticoAnexoStore || []).find(
									item => item.codigo === parcialDescricao || item.descricao === parcialDescricao
								) || null;
							if (dadosSubunidade?.baseRegimeUrbanisticoAnexo !== value) {
								deveSetarPreSelected = true;
								preSelectedDadosSubunidade.baseRegimeUrbanisticoAnexo = value;
							}
							deveSetarAutos = true;
							Object.assign(autos, { baseRegimeUrbanisticoAnexo: !!value });
							if (!size(dadosSubunidade.possuiAnexo72)) {
								Object.assign(carregados, { baseRegimeUrbanisticoAnexo: false });
								deveSetarCarregados = false;
							} else {
								deveSetarCarregados = true;
								Object.assign(carregados, { baseRegimeUrbanisticoAnexo: true });
							}
						} else {
							if (!dadosSubunidade?.baseRegimeUrbanisticoAnexo) {
								deveSetarPreSelected = true;
								preSelectedDadosSubunidade.baseRegimeUrbanisticoAnexo = selecionado;
							}
							deveSetarAutos = true;
							Object.assign(autos, { baseRegimeUrbanisticoAnexo: !!selecionado });
							deveSetarCarregados = true;
							Object.assign(carregados, { baseRegimeUrbanisticoAnexo: true });
						}
					}
				}
				// ==============================
				if (toRegimeUrbanisticoAnexoStore && !carregado.toRegimeUrbanisticoAnexo) {
					const selecionado =
						(toRegimeUrbanisticoAnexoStore || []).find(
							item =>
								item?.codigo.replace('%', '').replace('%', '') === anexo71Capturado?.taxaocupacao?.trim() ||
								item?.descricao.replace('%', '').replace('%', '') === anexo71Capturado?.taxaocupacao?.trim()
						) ||
						dadosSubunidade.toRegimeUrbanisticoAnexo ||
						null;

					const doisValoresPossiveis = selecionado?.descricao?.includes(' e '); // && size(trim(dadosSubunidade.possuiAnexo72)) > 0;

					if (doisValoresPossiveis) {
						const partes = selecionado.descricao.split(' e ');

						const parcialDescricao = (
							dadosSubunidade.possuiAnexo72 === 'sim'
								? partes[1] // se sim, usa o valor maior, senão, o valor menor
								: partes[0]
						).trim();

						const value =
							(toRegimeUrbanisticoAnexoStore || []).find(
								item => item.codigo === parcialDescricao || item.descricao === parcialDescricao
							) || null;

						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.toRegimeUrbanisticoAnexo = value;
						deveSetarAutos = true;
						Object.assign(autos, { toRegimeUrbanisticoAnexo: !!value });
						if (!size(dadosSubunidade.possuiAnexo72)) {
							deveSetarCarregados = false;
							Object.assign(carregados, { toRegimeUrbanisticoAnexo: false });
						} else {
							deveSetarCarregados = true;
							Object.assign(carregados, { toRegimeUrbanisticoAnexo: true });
						}
					} else {
						deveSetarPreSelected = true;
						preSelectedDadosSubunidade.toRegimeUrbanisticoAnexo = selecionado;
						deveSetarAutos = true;
						Object.assign(autos, { toRegimeUrbanisticoAnexo: !!selecionado });
						deveSetarCarregados = true;
						Object.assign(carregados, { toRegimeUrbanisticoAnexo: true });
					}
				}
				// ==============================
			}

			if (relacaoLogradourosAnexo72Store) {
				let enderecosFormulario = get(formulario, 'formData.data.enderecoCdlList');
				let possuiAnexo72 = false;
				if (size(enderecosFormulario) > 0) {
					possuiAnexo72 = enderecosFormulario.reduce((acc, endereco) => {
						const numeroPar = parseInt(endereco.numero, 10) % 2 === 0;
						const logradouro1 = removeDiacritics(endereco.nomeLogradouro.toUpperCase().trim());
						const enderecoStore = (relacaoLogradourosAnexo72Store || []).find(
							item => logradouro1 === removeDiacritics(item.logradouro.toUpperCase().trim())
						);
						if (enderecoStore) {
							const numeroParStore = enderecoStore.numInicial % 2 === 0 || enderecoStore.numFinal % 2 === 0;
							return acc || numeroPar === numeroParStore;
						}
						return acc;
					}, false);
				}
				if (formularioPossuiAnexo72 !== possuiAnexo72) {
					setFormularioPossuiAnexo72(possuiAnexo72);
				}
				if (!size(dadosSubunidade.possuiAnexo72)) {
					if (possuiAnexo72) {
						setLocalDataHandler('sim', 'possuiAnexo72');
					} else {
						setLocalDataHandler('nao', 'possuiAnexo72');
					}
				}
			}

			if (deveSetarAutos) {
				setAuto(old => ({ ...old, ...autos }));
			}
			if (deveSetarCarregados) {
				setCarregado(old => ({ ...old, ...carregados }));
			}

			if (size(preSelectedDadosSubunidade) > 0 && deveSetarPreSelected) {
				// asynchronicity is a bitch
				setLocalDataHandler(Object.assign(dadosSubunidade, preSelectedDadosSubunidade), 'dadosSubunidade');
			}
		}
	}, [
		alturaRegimeUrbanisticoAnexoStore,
		anexo6IAStore,
		anexo71Store,
		baseRegimeUrbanisticoAnexoStore,
		carregado,
		dadosSubunidade,
		divisaRegimeUrbanisticoAnexoStore,
		gaRegimeUrbanisticoAnexoStore,
		hasAllStores,
		iaRegimeUrbanisticoAnexoStore,
		setLocalDataHandler,
		toRegimeUrbanisticoAnexoStore,
		zonaUsoAnexo51Store,
		relacaoLogradourosAnexo72Store,
		formulario,
		setDataHandler,
		formularioPossuiAnexo72,
		mapQuotaIdealAnexo6,
		hideAlturaBase,
		showRadioRuDefinidoPorLei,
		showRadioPossuiEvuDefinindoRu
	]);

	const filtroValoresDuplos = useCallback(
		(lista, termo) => item => {
			let incluir = true;
			// remove itens com mais de um valor (tipo "1 e 2")
			if (item?.descricao?.indexOf(' e ') > -1) {
				incluir = false;
			}
			return (
				incluir &&
				JSON.stringify(item)
					.toLowerCase()
					.indexOf((termo || '').toLowerCase()) > -1
			);
		},
		[]
	);

	const filtroOpcaoSC = useCallback(
		() => item => {
			const val = item?.sc;
			if (!val) return false;
			if ('-' === trim(val)) return false;
			if (/n.o/i.test(trim(val))) return false;
			return true;
		},
		[]
	);

	const filtroOpcaoTPC = useCallback(
		() => item => {
			const val = item?.tpc;
			if (!val) return false;
			if ('-' === trim(val)) return false;
			return true;
		},
		[]
	);

	const filtroOpcaoIAMax = useCallback(
		() => item => {
			const val = item?.iamaximo;
			if (!val) return false;
			if ('-' === trim(val)) return false;
			return ['1,5', '2,0', '3,0'].includes(trim(val));
		},
		[]
	);

	return (
		<>
			{dadosSubunidade ? (
				<div className={'grupoBpmTasks grupoEV0200 subunidadeLE'}>
					<div style={{ flex: '1' }}>
						<h3>Informações do Regime Urbanístico</h3>
					</div>

					{!isLicencasExpressas && showRadioRuDefinidoPorLei && (
						<div className={'pergunta'}>
							<RadioFieldLE
								name={'possuiRuDefinidoPorLegislacao'}
								label={'Este projeto possui regime urbanístico definido no terreno por legislação?'}
								required={true}
								value={dadosSubunidade.possuiRuDefinidoPorLegislacao}
								onChangeHandler={e => setLocalDataHandler(e.value, e.name)}
								options={[
									{ codigo: 'sim', descricao: 'Sim' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
								readOnly={readOnly}
							/>
							{dadosSubunidade?.possuiRuDefinidoPorLegislacao === 'nao' && (
								<ErrorMessages errorList={[MSG_ERROR_LEI_ESPECIFICA]} />
							)}
							{!dadosSubunidade?.possuiRuDefinidoPorLegislacao && (
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(
										e => dadosSubunidade.id === e.idSub && e.campo === 'possuiRuDefinidoPorLegislacao'
									)}
								/>
							)}
						</div>
					)}

					{!isLicencasExpressas && showRadioPossuiEvuDefinindoRu && (
						<div className={'pergunta'}>
							<RadioFieldLE
								name={'possuiEvuDefinindoRu'}
								label={
									'Este projeto possui Estudo de Viabilidade Urbanística aprovado e válido definindo o regime urbanístico do terreno?'
								}
								required={true}
								value={dadosSubunidade.possuiEvuDefinindoRu}
								onChangeHandler={e => setLocalDataHandler(e.value, e.name)}
								options={[
									{ codigo: 'sim', descricao: 'Sim' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
								readOnly={readOnly}
							/>
							{dadosSubunidade?.possuiEvuDefinindoRu === 'nao' && (
								<ErrorMessages errorList={[MSG_ERROR_EVU_DEFININDO_RU]} />
							)}
							{!dadosSubunidade?.possuiEvuDefinindoRu && (
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(
										e => dadosSubunidade.id === e.idSub && e.campo === 'possuiEvuDefinindoRu'
									)}
								/>
							)}
						</div>
					)}

					{(dadosSubunidade?.possuiEvuDefinindoRu === 'sim' ||
						dadosSubunidade?.possuiRuDefinidoPorLegislacao === 'sim' ||
						(!showRadioPossuiEvuDefinindoRu && !showRadioRuDefinidoPorLei) ||
						isLicencasExpressas) && (
						<>
							<PlanoDiretorAnexosSelecao
								data={dadosSubunidade}
								property="zonaUsoAnexo51"
								label="Grupamento de Atividade e Zona de Uso (Anexo 5.1)"
								url={`${API_URL}/collections/anexo51zonauso/`}
								containerClass="pergunta selecao2"
								tagged={auto.zonaUsoAnexo51}
								readOnly={readOnly}
								setDataHandler={setLocalDataHandler}
								showTooltipAutoFill={true}
							>
								<ErrorMessages
									errorList={(errosSubmissao || []).filter(
										e => dadosSubunidade.id === e.idSub && e.campo === 'zonaUsoAnexo51'
									)}
								/>
							</PlanoDiretorAnexosSelecao>
							{!isLicencasExpressas && (
								<>
									<div className="pergunta">
										<div className="metragem anexo72">
											<RadioFieldLE
												name="possuiAnexo72"
												label="O terreno está listado no Anexo 7.2 do Plano Diretor?"
												options={[
													{
														codigo: 'sim',
														descricao: 'Sim' /*, defaultValue: isDebug*/
													},
													{ codigo: 'nao', descricao: 'Não' }
												]}
												required={true}
												onChangeHandler={e => {
													setLocalDataHandler(e.value, e.name);
												}}
												value={dadosSubunidade.possuiAnexo72}
												readOnly={readOnly}
												disabled={false}
												hasExternalLink={true}
												externalLink={'https://lproweb.procempa.com.br/pmpa/prefpoa/spm/usu_doc/anexo_7revisao.pdf'}
												externalLinkText={'Anexo 7.2'}
											/>
											{formularioPossuiAnexo72 && (
												<ErrorMessages
													type={'warning'}
													errorList={['O(s) endereço(s) informado(s) consta(m) no Anexo 7.2 do PDDUA']}
												/>
											)}
											<HistoricoRespostaAnterior
												dataAnterior={dadosSubunidadeAnterior?.possuiAnexo72}
												dataAtual={dadosSubunidade.possuiAnexo72}
												usuarioInterno={usuarioInterno}
											/>
											<ErrorMessages
												errorList={(errosSubmissao || []).filter(
													e => dadosSubunidade.id === e.idSub && e.campo === 'possuiAnexo72'
												)}
											/>
										</div>
									</div>
									<PlanoDiretorAnexosSelecao
										data={dadosSubunidade}
										property="gaRegimeUrbanisticoAnexo"
										label="Grupamento"
										url={`${API_URL}/collections/anexoregimeurbanisticoga/`}
										containerClass="pergunta selecao2"
										tagged={auto.gaRegimeUrbanisticoAnexo}
										readOnly={readOnly}
										setDataHandler={setLocalDataHandler}
										detailCodigo={null}
										showTooltipAutoFill={true}
									>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'gaRegimeUrbanisticoAnexo'
											)}
										/>
									</PlanoDiretorAnexosSelecao>
									<div
										className={
											auto.iaRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
										}
									>
										<PlanoDiretorAnexosSelecao
											data={dadosSubunidade}
											property="iaRegimeUrbanisticoAnexo"
											label="Índice de Aproveitamento (IA)"
											url={`${API_URL}/collections/anexoregimeurbanisticoia/`}
											containerClass="d-flex"
											tagged={auto.iaRegimeUrbanisticoAnexo}
											readOnly={readOnly}
											setDataHandler={setLocalDataHandler}
											detailCodigo={null}
											showTooltipAutoFill={true}
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'iaRegimeUrbanisticoAnexo'
											)}
										/>
									</div>
									{showIndices && dadosSubunidade.soloCriadoAnexo && (
										<>
											<div
												className={auto.soloCriadoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'}
											>
												<PlanoDiretorAnexosSelecao
													data={dadosSubunidade}
													property="soloCriadoAnexo"
													label="Solo Criado"
													url={`${API_URL}/collections/anexo6/`}
													containerClass="d-flex"
													tagged={auto.soloCriadoAnexo}
													readOnly={readOnly}
													setDataHandler={setLocalDataHandler}
													detailCodigo={null}
													detailDescricao={'sc'}
													showTooltipAutoFill={true}
													filter={filtroOpcaoSC}
													uniqueBy={'sc'}
												/>
												<ErrorMessages
													errorList={(errosSubmissao || []).filter(
														e => dadosSubunidade.id === e.idSub && e.campo === 'soloCriadoAnexo'
													)}
												/>
											</div>
											{dadosSubunidade.transferenciaPotencialConstrutivoAnexo && (
												<div
													className={
														auto.transferenciaPotencialConstrutivoAnexo || readOnly
															? 'pergunta metragem'
															: 'pergunta selecao2 small'
													}
												>
													<PlanoDiretorAnexosSelecao
														data={dadosSubunidade}
														property="transferenciaPotencialConstrutivoAnexo"
														label="Transferência de Potencial Construtivo (TPC)"
														url={`${API_URL}/collections/anexo6/`}
														containerClass="d-flex"
														tagged={auto.transferenciaPotencialConstrutivoAnexo}
														readOnly={readOnly}
														setDataHandler={setLocalDataHandler}
														detailCodigo={null}
														detailDescricao="tpc"
														showTooltipAutoFill={true}
														filter={filtroOpcaoTPC}
														uniqueBy={'tpc'}
													/>
													<ErrorMessages
														errorList={(errosSubmissao || []).filter(
															e =>
																dadosSubunidade.id === e.idSub && e.campo === 'transferenciaPotencialConstrutivoAnexo'
														)}
													/>
												</div>
											)}
											<div
												className={
													auto.indiceAproveitamentoMaximoAnexo || readOnly
														? 'pergunta metragem'
														: 'pergunta selecao2 small'
												}
											>
												<PlanoDiretorAnexosSelecao
													data={dadosSubunidade}
													property="indiceAproveitamentoMaximoAnexo"
													label="Índice de Aproveitamento (IA) Máximo"
													url={`${API_URL}/collections/anexo6/`}
													containerClass="d-flex"
													tagged={auto.indiceAproveitamentoMaximoAnexo}
													readOnly={readOnly}
													setDataHandler={setLocalDataHandler}
													detailCodigo={null}
													detailDescricao="iamaximo"
													showTooltipAutoFill={true}
													filter={filtroOpcaoIAMax}
													uniqueBy={'iamaximo'}
												/>
												<ErrorMessages
													errorList={(errosSubmissao || []).filter(
														e => dadosSubunidade.id === e.idSub && e.campo === 'indiceAproveitamentoMaximoAnexo'
													)}
												/>
											</div>
											<div hidden={!dadosSubunidade.observacaoAnexo6} className="pergunta metragem">
												<div className="d-flex">
													<label className=" ">Observação</label>
													<span>{dadosSubunidade.observacaoAnexo6}</span>
												</div>
											</div>
										</>
									)}
									<div
										className={
											auto.quotaRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
										}
									>
										<PlanoDiretorAnexosSelecao
											data={dadosSubunidade}
											property="quotaRegimeUrbanisticoAnexo"
											label="Quota Ideal (m²)"
											url={`${API_URL}/collections/anexo6/`}
											containerClass="d-flex"
											tagged={auto.quotaRegimeUrbanisticoAnexo}
											readOnly={readOnly}
											setDataHandler={setLocalDataHandler}
											detailCodigo={null}
											detailDescricao="quotaideal"
											showTooltipAutoFill={true}
											uniqueBy="quotaideal"
											ignoredValues={[' - ', '-']}
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'quotaRegimeUrbanisticoAnexo'
											)}
										/>
									</div>
									{isDebug && (
										<div className="pergunta metragem">
											<div className="d-flex">
												<label>
													<div className="debug-message">
														<i
															className="fa fa-exclamation-triangle"
															style={{ color: '#b39d5b' }}
															aria-hidden="true"
															title="debug"
														></i>
														Volumetria: {dadosSubunidade?.subunidade?.volumetria}
													</div>
												</label>
											</div>
										</div>
									)}
									<div
										className={
											auto.alturaRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
										}
									>
										<PlanoDiretorAnexosSelecao
											data={dadosSubunidade}
											digitavel={dadosSubunidade?.subunidade?.volumetria === '999'}
											property="alturaRegimeUrbanisticoAnexo"
											label="Altura Máxima (m)"
											url={`${API_URL}/collections/anexoregimeurbanisticoaltura/`}
											containerClass="d-flex"
											tagged={auto.alturaRegimeUrbanisticoAnexo}
											readOnly={readOnly}
											setDataHandler={setLocalDataHandler}
											detailCodigo={dadosSubunidade?.subunidade?.volumetria === '999' ? 'codigo' : null}
											showTooltipAutoFill={true}
											filter={auto.alturaRegimeUrbanisticoAnexo ? null : filtroValoresDuplos}
											tooltip="Preenchido automaticamente pelo sistema. Pode ser maior se possuir Certificação em Sustentabilidade Ambiental"
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'alturaRegimeUrbanisticoAnexo'
											)}
										/>
									</div>
									<div
										className={
											auto.divisaRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
										}
									>
										<PlanoDiretorAnexosSelecao
											data={dadosSubunidade}
											digitavel={dadosSubunidade?.subunidade?.volumetria === '999'}
											property="divisaRegimeUrbanisticoAnexo"
											label="Altura da Divisa (m)"
											url={`${API_URL}/collections/anexoregimeurbanisticodivisa/`}
											containerClass="d-flex"
											tagged={auto.divisaRegimeUrbanisticoAnexo}
											readOnly={readOnly}
											setDataHandler={setLocalDataHandler}
											detailCodigo={dadosSubunidade?.subunidade?.volumetria === '999' ? 'codigo' : null}
											showTooltipAutoFill={true}
											filter={auto.divisaRegimeUrbanisticoAnexo ? null : filtroValoresDuplos}
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'divisaRegimeUrbanisticoAnexo'
											)}
										/>
									</div>
									{hideAlturaBase ? (
										<div className="pergunta metragem">
											<WarningMessage>{MSG_BASE_DESATIVADA}</WarningMessage>
										</div>
									) : (
										<div
											className={
												auto.baseRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
											}
										>
											<PlanoDiretorAnexosSelecao
												data={dadosSubunidade}
												digitavel={dadosSubunidade?.subunidade?.volumetria === '999'}
												property="baseRegimeUrbanisticoAnexo"
												label="Altura da Base (m)"
												url={`${API_URL}/collections/anexoregimeurbanisticobase/`}
												containerClass="d-flex"
												tagged={auto.baseRegimeUrbanisticoAnexo}
												readOnly={readOnly}
												setDataHandler={setLocalDataHandler}
												detailCodigo={dadosSubunidade?.subunidade?.volumetria === '999' ? 'codigo' : null}
												showTooltipAutoFill={true}
												filter={auto.baseRegimeUrbanisticoAnexo ? null : filtroValoresDuplos}
											/>
											<ErrorMessages
												errorList={(errosSubmissao || []).filter(
													e => dadosSubunidade.id === e.idSub && e.campo === 'baseRegimeUrbanisticoAnexo'
												)}
											/>
										</div>
									)}
									<div
										className={
											auto.toRegimeUrbanisticoAnexo || readOnly ? 'pergunta metragem' : 'pergunta selecao2 small'
										}
									>
										<PlanoDiretorAnexosSelecao
											data={dadosSubunidade}
											property="toRegimeUrbanisticoAnexo"
											label="Taxa de Ocupação (TO)"
											url={`${API_URL}/collections/anexoregimeurbanisticoto/`}
											containerClass="d-flex"
											tagged={auto.toRegimeUrbanisticoAnexo}
											readOnly={readOnly}
											setDataHandler={setLocalDataHandler}
											detailCodigo={null}
											showTooltipAutoFill={true}
											filter={auto.toRegimeUrbanisticoAnexo ? null : filtroValoresDuplos}
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(
												e => dadosSubunidade.id === e.idSub && e.campo === 'toRegimeUrbanisticoAnexo'
											)}
										/>
									</div>
								</>
							)}
						</>
					)}
				</div>
			) : (
				<h2>Aguardando dados...</h2>
			)}
		</>
	);
};
PlanoDiretorRegimeUrbanistico.displayName = 'Plano Diretor Regims Urbanístico';

PlanoDiretorRegimeUrbanistico.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	subunidade: PropTypes.object,
	readOnly: PropTypes.bool,
	setDataHandler: PropTypes.func,
	errosSubmissao: PropTypes.array,
	showIndices: PropTypes.bool,
	usuarioInterno: PropTypes.bool
};

export default PlanoDiretorRegimeUrbanistico;
