import React from 'react';

import PropTypes from 'prop-types';

import { size } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

const RadioField = ({
	label,
	required,
	name,
	value,
	options,
	containerClass,
	readOnly,
	disabled,
	errorList,
	onChangeHandler
}) => {
	const onChangeRadio = e => {
		const { value: codigo } = e.target;
		onChangeHandler({ name, value: codigo });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo, required, label) } });
	};
	return (
		<div className={`${containerClass}`}>
			<div className="col-md-12">
				<label className="control-label">
					{label}
					{required && <span className="required">*</span>}
				</label>
			</div>
			{options.map((o, i) => {
				const codigo = typeof o === 'object' ? o.codigo : o;
				const descricao = typeof o === 'object' ? o.descricao : o;
				return (
					<div className="col-md-3" key={i}>
						<label>
							<input
								type="radio"
								value={codigo}
								name={name}
								onClick={onChangeRadio}
								readOnly={readOnly}
								disabled={disabled || readOnly}
								onChange={() => false}
								checked={value === codigo}
							/>{' '}
							{descricao}
						</label>
					</div>
				);
			})}
			{errorList && size(errorList) > 0 && (
				<div className="col-md-12">
					<ErrorMessages errorList={errorList} />
				</div>
			)}
		</div>
	);
};
RadioField.displayName = 'RadioField';

RadioField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	containerClass: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	errorList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func
};

export default RadioField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
