import React from 'react';

import { ReactReduxContext } from 'react-redux';

import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from 'config/reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) =>
	WrappedComponent => {
		class ReducerInjector extends React.Component {
			static WrappedComponent = WrappedComponent;

			static contextType = ReactReduxContext;

			static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

			static getDerivedStateFromProps(nextProps, prevState) {
				prevState.injectors.injectReducer(key, reducer);
				return prevState;
			}

			state = {
				injectors: getInjectors(this.context.store)
			};

			render() {
				return <WrappedComponent {...this.props} />;
			}
		}

		return hoistNonReactStatics(ReducerInjector, WrappedComponent);
	};
