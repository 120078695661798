import React from 'react';

import PropTypes from 'prop-types';

import { capitalize } from 'lodash';

function RegiaoBadge({ regiao }) {
	if (!regiao) {
		return null;
	}
	let regiaoAux = regiao.toLowerCase().split('_').map(capitalize).join(' ');
	regiaoAux = regiaoAux.replace('torico', 'tórico');
	return <span className="badge badge-info">{regiaoAux}</span>;
}
RegiaoBadge.displayName = 'RegiaoBadge';
RegiaoBadge.propTypes = {
	regiao: PropTypes.string
};

export default RegiaoBadge;
