import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import {
	CdlField,
	NumberField,
	TypeAheadField,
	RadioField,
	TextAreaField
} from 'containers/Form/metadata-template/fields';

import { EXPEDIENTES_API_URL } from 'environments';

import { METAVISTORIA } from '.';
import { ListaEconomias } from './ListaEconomias';

export function EnderecoObraVistoria({ data = {}, onChange, readOnly }) {
	const { informacoesObra = [{}] } = data;
	const { tipoVistoria } = data;

	const disabled = readOnly;
	const isSolProjeto = data[METAVISTORIA.tipoSolicitacao.name] === METAVISTORIA.TIPOS_SOLICITACAO.projeto.codigo;

	const isTipoVistoriaParcial =
		tipoVistoria &&
		(tipoVistoria === 'parcial' || tipoVistoria === 'parcial_final' || tipoVistoria === 'parcial final');

	const localChange = useCallback(
		changes => {
			let { name, value, pos } = changes;
			// handle array de informacoes
			if (name === METAVISTORIA.informacoesObra.name) {
				switch (value) {
					case 'add':
						informacoesObra.push({});
						break;
					case 'remove':
						informacoesObra.splice(pos, 1);
						break;
					default:
						break;
				}
			} else {
				// handle fields
				if (name === 'errors') {
					return;
				}
				const oldControl = informacoesObra[pos];
				informacoesObra.splice(pos, 1, { ...oldControl, [name]: value });
			}
			const newvalue = [...informacoesObra];
			onChange && onChange({ name: METAVISTORIA.informacoesObra.name, value: newvalue });
		},
		[informacoesObra, onChange]
	);

	return (
		<>
			{informacoesObra.map((io, index) => (
				<div key={index} className="endereco-obra-vistoria">
					<div className="pergunta">
						<CdlField
							name={METAVISTORIA.enderecoObra.name}
							required={METAVISTORIA.enderecoObra.required}
							readOnly={disabled}
							disabled={disabled}
							value={io[METAVISTORIA.enderecoObra.name]}
							onChangeHandler={([valid]) => localChange({ name: valid.name, pos: index, value: valid.value })}
						/>
					</div>
					<div className="pergunta">
						<TypeAheadField
							name={METAVISTORIA.atividade.name}
							required={isSolProjeto && METAVISTORIA.atividade.required}
							label={METAVISTORIA.atividade.label}
							value={io[METAVISTORIA.atividade.name]}
							fullObject={true}
							attrCodigo="_id"
							attrDescricao="descricao"
							sortProperty="descricao"
							externalApiUrl={`${EXPEDIENTES_API_URL}/cadastros/atividades`}
							onChangeHandler={({ name, value }) => localChange({ name, value, pos: index })}
							filter={[
								'10100000000',
								'10302000000',
								'10300000000',
								'10102000000',
								'10103000000',
								'20000000000',
								'29999000001',
								'30000000000',
								'39999010001',
								'40000000000',
								'40405030101',
								'60500000000'
							]}
						/>
					</div>
					<div className="pergunta metragem">
						<div className="d-flex">
							<NumberField
								label={METAVISTORIA.qtdEconomias.label}
								name={METAVISTORIA.qtdEconomias.name}
								required={true}
								value={io[METAVISTORIA.qtdEconomias.name]}
								onChangeHandler={([valid]) => localChange({ name: valid.name, pos: index, value: valid.value })}
							/>
						</div>
					</div>
					<div className="pergunta">
						<TextAreaField
							name={METAVISTORIA.infoObraObservacoes.name}
							label={METAVISTORIA.infoObraObservacoes.label}
							required={METAVISTORIA.infoObraObservacoes.required}
							onChangeHandler={valid => localChange({ name: valid.name, pos: index, value: valid.value })}
							value={io[METAVISTORIA.infoObraObservacoes.name]}
						/>
					</div>
					{isTipoVistoriaParcial && (
						<div className="form-group col-md-12">
							<RadioField
								name={METAVISTORIA.economiaUnica.name + index}
								required={METAVISTORIA.economiaUnica.required}
								label={METAVISTORIA.economiaUnica.label}
								options={[
									{ codigo: 'sim', descricao: 'Sim' },
									{ codigo: 'nao', descricao: 'Não' }
								]}
								onChangeHandler={valid =>
									localChange({
										name: valid.name,
										pos: index,
										value: valid.value
									})
								}
								value={io[METAVISTORIA.economiaUnica.name + index]}
							/>
						</div>
					)}
					{isTipoVistoriaParcial &&
						io[METAVISTORIA.economiaUnica.name + index] &&
						io[METAVISTORIA.economiaUnica.name + index] === 'nao' && (
							<div className="col-md-12">
								<ListaEconomias
									data={io}
									lista={io[METAVISTORIA.listaEconomias.name]}
									readOnly={readOnly || disabled}
									onChange={({ name, value }) => localChange({ name, value, pos: index })}
								/>
							</div>
						)}
					{informacoesObra.length > 1 && index !== 0 && !readOnly && (
						<div className="col-md-12">
							<button
								type="button"
								className="btn btn-danger btn float-right m-1"
								onClick={() => localChange({ name: METAVISTORIA.informacoesObra.name, pos: index, value: 'remove' })}
								disabled={false}
							>
								Remover este endereço <i className="fa fa-trash" aria-hidden="true" />
							</button>
						</div>
					)}
				</div>
			))}
			{/* fim map info obra */}
			{informacoesObra.length >= 1 && !readOnly && (
				<button
					className="btn btn-secondary mt-3"
					type="button"
					onClick={() => localChange({ name: METAVISTORIA.informacoesObra.name, value: 'add' })}
					disabled={disabled}
				>
					Adicionar outro endereço <i style={{ marginLeft: '5px' }} className="fa fa-plus" aria-hidden="true" />
				</button>
			)}
		</>
	);
}
EnderecoObraVistoria.displayName = 'EnderecoObraVistoria';
EnderecoObraVistoria.propTypes = {
	data: PropTypes.object,
	readOnly: PropTypes.bool,
	tipoSolicitacao: PropTypes.string,
	onChange: PropTypes.func
};
