export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://licenciamento-api-des.procempa.com.br';
export const SSO_URL = process.env.REACT_APP_SSO_URL || 'https://acessopoa-des.procempa.com.br/auth';
export const EXPEDIENTES_API_URL =
	process.env.REACT_APP_EXPEDIENTES_API_URL || 'https://expedientes-api-des.procempa.com.br/api';

export const GRAPHQL_URL = `${SERVER_URL}/graphql`;
export const API_URL = `${SERVER_URL}/api`;
export const PLANO_DIRETOR_API_URL = `${API_URL}/planodiretor`;

export const RECAPTCHA_SITE_KEY =
	process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6Lcv7T4pAAAAAOWqHW39c8pQXY5G2jmxVrmWYmdM';

console.debug('usando SERVER_URL', SERVER_URL);
console.debug('usando SSO_URL', SSO_URL);
console.debug('usando EXPEDIENTES_API_URL', EXPEDIENTES_API_URL);
