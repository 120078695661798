import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { get } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';
import IntlNumberInput from 'components/react-intl-number-input';
import Selecao from 'components/Selecao';
import TooltipIcon from 'components/TooltipIcon';

import { sortCadastros } from 'utils/tools';

import { debugLog } from '.';
import { TIPOS_CONSTRUCAO, TIPOS_MATERIAL } from './constants';

const Aba_B_Atividade = ({ planilha, onChange }) => {
	const [abaB, setAbaB] = useState(planilha.b || {});

	const [tipoConstrucaoList, setTipoConstrucaoList] = useState(TIPOS_CONSTRUCAO);
	const [tiposConstrucaoSelected, setTiposConstrucaoSelected] = useState(get(planilha, 'b.tiposConstrucao') || []);
	const [tipoMaterialList, setTipoMaterialList] = useState(TIPOS_MATERIAL);
	const [tipoMaterialSelected, setTipoMaterialSelected] = useState(get(planilha, 'b.tipoMaterial') || []);

	useEffect(() => {
		if (abaB) {
			// cálculs boxes e vagas estacionamento
			const bps = abaB.boxPrivativosSimples || 0;
			const bpd = abaB.boxPrivativosDuplos || 0;
			const cond = abaB.vagasCondominiais || 0;
			abaB.boxTotais = bps + bpd;
			abaB.vagasTotais = bps + bpd * 2 + cond;
		}

		debugLog('ABA_B', '[abaB]', abaB);
		onChange({ b: abaB });
	}, [abaB, onChange, planilha]);

	const handleChange = e => {
		const { value, name } = e.target;
		if (name === 'tiposConstrucao') {
			let aux = tiposConstrucaoSelected;
			aux.push(value);
			setTiposConstrucaoSelected(aux);
			setTipoConstrucaoList(tipoConstrucaoList.filter(item => item.codigo !== value.codigo));
			setAbaB(oldState => ({ ...oldState, [name]: aux }));
		} else if (name === 'tipoMaterial') {
			let aux = tipoMaterialSelected;
			aux.push(value);
			setTipoMaterialSelected(aux);
			setTipoMaterialList(tipoMaterialList.filter(item => item.codigo !== value.codigo));
			setAbaB(oldState => ({ ...oldState, [name]: aux }));
		} else {
			setAbaB(oldState => ({ ...oldState, [name]: value }));
		}
	};

	const handleUnselect = e => {
		const { value, name } = e.target;
		if (name === 'tiposConstrucao') {
			let aux = tiposConstrucaoSelected;
			aux = aux.filter(item => item.codigo !== value.codigo);
			setTiposConstrucaoSelected(aux);
			let auxListaSelecionaveis = tipoConstrucaoList;
			auxListaSelecionaveis.push(value);
			setTipoConstrucaoList(sortCadastros(auxListaSelecionaveis, 'codigo'));
			setAbaB(oldState => ({ ...oldState, [name]: aux }));
		} else if (name === 'tipoMaterial') {
			let aux = tipoMaterialSelected;
			aux = aux.filter(item => item.codigo !== value.codigo);
			setTipoMaterialSelected(aux);
			let auxListaSelecionaveis = tipoMaterialList;
			auxListaSelecionaveis.push(value);
			setTipoMaterialList(sortCadastros(auxListaSelecionaveis, 'codigo'));
			setAbaB(oldState => ({ ...oldState, [name]: aux }));
		}
	};

	return abaB ? (
		<>
			{abaB.errors && <ErrorMessages errorList={abaB.errors} />}
			<div className="row">
				<div className="form-group col">
					<label>
						<TooltipIcon label="Tipo de construção">
							Indicar, dentre as opções apresentadas, o tipo de construção: nova, existente (há mais de 20 anos ou com
							habite-se), regularização (há menos de 20 anos e sem habite-se) ou existente a reciclar (com habite-se) e
							as características construtivas (se alvenaria, mista, madeira ou outra a especificar)
						</TooltipIcon>
					</label>
					<Selecao
						className={'form-control'}
						multiple={true}
						selected={tiposConstrucaoSelected}
						label={'Tipo de construção'}
						detailCodigo={''}
						detailDescricao={'descricao'}
						autoShowList={true}
						searchTerm={''}
						searchList={tipoConstrucaoList}
						searchTermMinLength={1}
						errorList={false}
						onChangeSearchTerm={() => false}
						onBlurSearchTerm={() => false}
						onSelectItem={item => () => handleChange({ target: { name: 'tiposConstrucao', value: item } })}
						onUnselect={item => () => handleUnselect({ target: { name: 'tiposConstrucao', value: item } })}
						loading={false}
						required={true}
						placeholder="tipos de construção"
					/>
				</div>
				<div className="form-group col">
					<label>Tipo de material</label>
					<Selecao
						className={'form-control'}
						multiple={true}
						selected={tipoMaterialSelected}
						label={'Tipo de construção'}
						detailCodigo={''}
						detailDescricao={'descricao'}
						autoShowList={true}
						searchTerm={''}
						searchList={tipoMaterialList}
						searchTermMinLength={1}
						errorList={false}
						onChangeSearchTerm={() => false}
						onBlurSearchTerm={() => false}
						onSelectItem={item => () => handleChange({ target: { name: 'tipoMaterial', value: item } })}
						onUnselect={item => () => handleUnselect({ target: { name: 'tipoMaterial', value: item } })}
						loading={false}
						required={true}
						placeholder="tipos de material"
					/>
					{(abaB.tipoMaterial || []).find(tm => tm.codigo === 'outro') && (
						<div style={{ marginTop: '8px' }}>
							<label>
								<TooltipIcon label="Especificar outro tipo *">
									É necessário informar o outro tipo de material selecionado acima
								</TooltipIcon>
								<input
									className="form-control"
									placeholder="especificar material"
									name="tipoMaterialOutro"
									type="text"
									value={abaB.tipoMaterialOutro || ''}
									onChange={handleChange}
								/>
							</label>
						</div>
					)}
				</div>
			</div>
			<div>
				<div className="row">
					<div className="form-group col-sm-6">
						<label>
							<TooltipIcon label="Apartamento zelador *">
								O apartamento do Zelador não deve ser computado no nº total de economias por ser classificado como área
								condominial.
							</TooltipIcon>
						</label>
						<div className="inline-input-radio-checkbox">
							<input
								type="radio"
								name="zelador"
								value="sim"
								checked={abaB.zelador === 'sim'}
								onClick={handleChange}
								onChange={() => false}
							/>
							<label
								htmlFor="zelador-sim"
								onClick={() =>
									handleChange({
										target: {
											value: 'sim',
											name: 'zelador'
										}
									})
								}
							>
								Sim
							</label>
						</div>
						<div className="inline-input-radio-checkbox">
							<input
								type="radio"
								name="zelador"
								value="nao"
								checked={abaB.zelador === 'nao'}
								onClick={handleChange}
								onChange={() => false}
							/>
							<label
								htmlFor="zelador-nao"
								onClick={() =>
									handleChange({
										target: {
											value: 'nao',
											name: 'zelador'
										}
									})
								}
							>
								Não
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-6">
						<label htmlFor="pavimentos">
							<TooltipIcon label="Nº Pavimentos">
								Informar o número total de pavimentos do projeto, contabilizados todos os pavimentos (Subsolo, Pav.
								Térreo, Pavimentos Tipo, Cobertura). Volume superior não contabiliza como pavimento (é área de
								reservatório e/ou casa de máquinas)
							</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.pavimentos}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, pavimentos: value }))}
						/>
					</div>
					<div className="form-group col-sm-6">
						<label htmlFor="dormitorios">
							<TooltipIcon label="Nº Dormitórios">
								Informar o número total de dormitórios do projeto, inclusive o de zelador.
							</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.dormitorios}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, dormitorios: value }))}
						/>
					</div>
				</div>
			</div>
			<>
				<h4
					style={{
						textAlign: 'center',
						textTransform: 'uppercase',
						padding: '10px',
						backgroundColor: '#e9ecef'
					}}
				>
					{
						<TooltipIcon label="Vagas de estacionamento">
							Informar o número total de boxes. Box é o espaço reservado para a vaga, podendo ser simples (uma vaga) ou
							duplo (duas vagas).
						</TooltipIcon>
					}
				</h4>
				<div className="row">
					<div className="form-group col-sm-4">
						<label htmlFor="vagasObrigatorias">
							<TooltipIcon label="Obrigatórias (anexo 10.1)">
								informar o número total de vagas de estacionamento do projeto. Obrigatórias: deverá ser informado o
								número de vagas obrigatórias de acordo com os padrões do Anexo 10.1 do PDDUA.
							</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.vagasObrigatorias}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, vagasObrigatorias: value }))}
						/>
					</div>
					<div className="form-group col-sm-4">
						<label htmlFor="vagasPne">
							<TooltipIcon label="Para pessoas com deficiência">
								Informar o número de vagas para Pessoas com Deficiência (PcD, quando houver, observando os padrões do
								Art. 124 § 4º do PDDUA, da NBR- 9050/15, conforme o exposto no Art. 9º do Decreto 18.623/2014.
							</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.vagasPne}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, vagasPne: value }))}
						/>
						<label style={{ marginTop: '8px' }}>(Art 124 §4 PDDUA)</label>
					</div>
				</div>
			</>
			<div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label htmlFor="boxPrivativosSimples">
							<TooltipIcon label="Boxes privativos simples">Informar quantos boxes são privativos simples.</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.boxPrivativosSimples}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, boxPrivativosSimples: value }))}
						/>
					</div>
					<div className="form-group col-sm-4">
						<label htmlFor="boxPrivativosDuplos">
							<TooltipIcon label="Boxes privativos duplos">Informar quantos boxes são privativos duplos.</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.boxPrivativosDuplos}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, boxPrivativosDuplos: value }))}
						/>
					</div>
					<div className="form-group col-sm-4">
						<label htmlFor="boxTotais">Boxes totais</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.boxTotais}
							disabled={true}
							keepZero={true}
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label htmlFor="vagasCondominiais">
							<TooltipIcon label="Vagas condominiais">
								Prever no projeto área de acumulação para garagens comerciais ou condominiais: 5% do número total de
								vagas. Vide Anexo 10.1 do PDDUA.
							</TooltipIcon>
						</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.vagasCondominiais}
							onChange={(event, value) => setAbaB(oldState => ({ ...oldState, vagasCondominiais: value }))}
						/>
					</div>
					<div className="form-group col-sm-4">
						<label htmlFor="vagasTotais">Vagas totais *</label>
						<IntlNumberInput
							type="text"
							className="form-control"
							locale="pt-BR"
							precision={0}
							value={abaB.vagasTotais}
							disabled={true}
							keepZero={true}
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-sm-4">
						<label htmlFor="vagasObrigatorias">
							<TooltipIcon label="Vagas de acumulação">5% das vagas totais</TooltipIcon>
						</label>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="vagasAcumulacao"
									value="sim"
									checked={abaB.vagasAcumulacao === 'sim'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Sim
							</label>
						</div>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="vagasAcumulacao"
									value="nao"
									checked={abaB.vagasAcumulacao === 'nao'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Não
							</label>
						</div>
						{abaB.vagasAcumulacao === 'sim' && (
							<div style={{ marginTop: '8px' }}>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									precision={0}
									value={abaB.qtdVagasAcumulacao === 0 ? '' : abaB.qtdVagasAcumulacao}
									onChange={(event, value) => setAbaB(oldState => ({ ...oldState, qtdVagasAcumulacao: value }))}
									placeholder="Quantas de acumulação?"
								/>
							</div>
						)}
					</div>
					<div className="form-group col-sm-4">
						<label htmlFor="vagasCargaDescarga">Vagas de carga e descarga</label>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="vagasCargaDescarga"
									value="sim"
									checked={abaB.vagasCargaDescarga === 'sim'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Sim
							</label>
						</div>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="vagasCargaDescarga"
									value="nao"
									checked={abaB.vagasCargaDescarga === 'nao'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Não
							</label>
						</div>
						{abaB.vagasCargaDescarga === 'sim' && (
							<div style={{ marginTop: '8px' }}>
								<IntlNumberInput
									type="text"
									className="form-control"
									locale="pt-BR"
									precision={0}
									value={abaB.qtdVagasCargaDescarga === 0 ? '' : abaB.qtdVagasCargaDescarga}
									onChange={(event, value) => setAbaB(oldState => ({ ...oldState, qtdVagasCargaDescarga: value }))}
									placeholder="Quantas de carga/descarga?"
								/>
							</div>
						)}
					</div>
					<div className="form-group col-sm-4">
						<label>
							<TooltipIcon label="Bicicletário *">
								Informar “SIM” se obrigatório e “NÃO” se isento, conforme Art. 124 § 5º do PDDUA e LC 626/09.
							</TooltipIcon>
						</label>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="bicicletario"
									value="sim"
									checked={abaB.bicicletario === 'sim'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Sim
							</label>
						</div>
						<div className="inline-input-radio-checkbox">
							<label>
								<input
									type="radio"
									name="bicicletario"
									value="nao"
									checked={abaB.bicicletario === 'nao'}
									onClick={handleChange}
									onChange={() => false}
								/>
								Não
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	) : null;
};
Aba_B_Atividade.displayName = 'Aba_B_Atividade';
Aba_B_Atividade.propTypes = {
	planilha: PropTypes.object,
	formulario: PropTypes.object,
	onChange: PropTypes.func
};
export default Aba_B_Atividade;
